import React, { useState } from "react";
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { Link } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import { useAlert } from "react-alert";
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      letterSpacing: "0.3em",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "20px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

function PaymentForm({ amount, months, handleHideNbCollab, nbUsers }) {
  console.log(amount);
  const alert = useAlert();
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setloading] = useState(false);

  /**
   * It creates a payment method with Stripe.
   * @param e - The event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    handleHideNbCollab();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        const response = await axios.post(process.env.REACT_APP_API_BASE_URL_V1 + "payment", {
          amount: Math.round(parseFloat(amount)) * 100,
          id,
        });
        console.log(response);

        if (response.data.success) {
          console.log("Paiement réussi");
          alert.success(`Paiement de ${amount} réussi`);
          setSuccess(true);
        }
      } catch (error) {
        console.log("Error", error);
        alert.error(error);
      }
    } else {
      console.log(error.message);
      alert.error(error.message);
    }
  };
  return (
    <>
      {!success ? (
        <form>
          <div className="FormRow mt-4 mb-4">
            <div className="row">
              <div className="col-12 mt-4">
                <label>Numéro de la carte</label>
                <CardNumberElement options={CARD_OPTIONS} />
              </div>
              <div className="col-12 mt-4">
                <label>Date d'expiration</label>
                <CardExpiryElement options={CARD_OPTIONS} />
              </div>
              <div className="col-12 mt-4">
                <label>CVC</label>
                <CardCvcElement options={CARD_OPTIONS} />
              </div>
            </div>
          </div>

          {loading ? (
            <button className="btn btn-info btn-loading btn-block">Button text</button>
          ) : (
            <Link to="#" className="btn btn-lg btn-primary mt-4" onClick={handleSubmit}>
              Payé
            </Link>
          )}
        </form>
      ) : (
        <div>
          <div className="alert alert-success" role="alert">
            Paiement effectué avec succès!
          </div>
          <div className="bg-danger-transparent-2 text-primary px-4 py-2 br-3 mb-4" role="alert">
            Veuillez remplir avec des détails valides *
          </div>
          <RegisterForm months={months} nbUsers={nbUsers}></RegisterForm>
        </div>
      )}
    </>
  );
}

export default PaymentForm;
