import React, { useEffect, useState } from "react";
import { getProjetMemberByProjetId } from "../../Services/Pointeuse/ProjetMemberApi";

function MembersPic({ projectId }) {
  const [members, setmembers] = useState(null);

  useEffect(() => {
    getProjetMemberByProjetId(projectId).then((res) => {
      console.log(res.data);
      setmembers(res.data);
    });
  }, [projectId]);

  return (
    <>
      {members && (
        <div className="avatar-list avatar-list-stacked">
          {members.map((el, idx) => (
            <span
              key={idx}
              className="avatar avatar brround"
              style={{
                backgroundImage: `url(${localStorage.getItem("baseUrl") + el.imguser})`,
              }}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default MembersPic;
