import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { getUsersPersonnelByCode } from "../../Services/Pointeuse/UsersApi";
import { compareDate, getMonthName, getUpcomingBirthdays } from "../../Utils/DateUtils";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";

function ComingBirthDayList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [dataLength, setdataLength] = useState(null);
  const [bg] = useState(["pink", "pink", "pink", "orange", "orange", "orange", "warning", "warning", "warning", "success", "success", "success"]);

  /**
   * It gets the data from the API and then sorts it by month and then by name.
   */
  const getData = () => {
    setLoading(true);
    getUsersPersonnelByCode()
      .then((res) => {
        //elemenate null value
        const filterNUllBirthday = res.data.filter((el) => {
          return el.birth_date;
        });

        const currentMonth = new Date().getMonth() + 1;
        const nextMonth = new Date().getMonth() + 2;

        //incomming birthday
        const CommingBirth = filterNUllBirthday.filter((el) => {
          return parseInt(el.birth_date.split("-")[1]) > parseInt(currentMonth);
        });

        const result = CommingBirth.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.birth_date.split("-")[1]) - new Date(b.birth_date.split("-")[1]);
        });
        setdata(result);

        if (result.length > 4) {
          setdataLength(5);
        } else {
          setdataLength(result.length);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="card overflow-hidden">
      <div className="card-header border-0">
        <h4 className="card-title">{t("Anniversaire des collaborateurs")}</h4>
      </div>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="pt-2">
          <div className="list-group">
            {data.slice(0, dataLength).map((el, idx) => (
              <div key={idx} className="list-group-item d-flex pt-3 pb-3 align-items-center border-0">
                <div className="mr-3 mr-xs-0">
                  <div className="calendar-icon icons">
                    <div className="">
                      {/* <img src="assets/images/users/4.jpg" alt="img" className="avatar avatar-md bradius mr-3" /> */}
                      <ImageComponent atr={"avatar avatar-md bradius mr-3"} picture={el.img}></ImageComponent>
                    </div>
                  </div>
                </div>
                <div className="ml-1">
                  <div className="h5 fs-14 mb-1">
                    {el.nom} {el.prenom}
                  </div>
                  <small className="text-muted">{el.email}</small>
                </div>
                <div className="calendar-icon icons ml-auto">
                  <div className={`date_time bg-${bg[Number(el.birth_date?.split("-")[1])]}-transparent`}>
                    <span className="date">{el.birth_date?.split("-")[2]}</span>
                    <span className="month">{getMonthName(el.birth_date?.split("-")[1])}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ComingBirthDayList;
