import { t } from 'i18next'
import React, { Fragment, useEffect,   useState } from 'react'
import { Link } from "react-router-dom";
import {   getAllClientsFactureAbonnement, getClientById, getFacturationClientsByCode, } from '../../../Services/Facturation/FacturationClientApi';
 import moment from 'moment';
 import CopieContent from '../../Exports/CopieContent';
import ExportCsv from '../../Exports/ExportCsv';
import ExportPdf from '../../Exports/ExportPdf';
 import { getEncaissementByCode } from '../../../Services/Facturation/FacturationEncaissementApi'; 
import { getAllAbonnementByCodeAndEntete } from '../../../Services/Facturation/FacturationAbonnementApi';
import "./listefactures.css"
import { error_message } from '../../alerte/AlerteMessage';
import { filterFactureByWordAbonnement } from '../../../Utils/SortObject';
function ListFactureClients() {
  const [users, setUsers] = useState([]); 
  const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche
  const [allInitialData, setallInitialData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [isCollapsed, setisCollapsed] = useState(false); 
   const [selectedUserData, setSelectedUserData] = useState('');
  const [selectedUser, setSelectedUser] = useState({}); 
   const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [encaissements, setEncaissements] = useState([]);
  const [isFactureClicked, setIsFactureClicked] = useState(false);
  const [filteredAbonnements, setFilteredAbonnements] = useState([]);
  const [nombreTotalDeFactures, setNombreTotalDeFactures] = useState(null);
  const [listAbonnement,setListAbonnement]=useState([]);


  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  // Fonction pour mettre à jour le terme de recherche
    // const handleSearch = (event) => {
    //   setSearchTerm(event.target.value);
    // };
   
useEffect (() => {
  setLoading(true);
      getEncaissementByCode().then((resEncaissement) => {
          setEncaissements(resEncaissement.data);
      })
    .catch((error) => setError(error))
    .finally(() => setLoading(false));
  },[]);
 
// Afficher la date du dernier versement
const dernierEncaissementAvecDate = encaissements.length > 0
  ? encaissements.reduce((maxEncaissement, encaissement) => {
       const dateVersement = new Date(encaissement.date_versement);
       return dateVersement > maxEncaissement.dateVersement ? { encaissement, dateVersement } : maxEncaissement;
    }, { encaissement: encaissements[0], dateVersement: new Date(encaissements[0].date_versement) })
  : null;

// Récupérer la date et le montant du dernier encaissement
const dernierDateVersement = dernierEncaissementAvecDate ? dernierEncaissementAvecDate.dateVersement : null;
const dernierMontantEncaissement = dernierEncaissementAvecDate ? dernierEncaissementAvecDate.encaissement.montant : null;

// Formater la date du dernier versement
const formattedDerniereDateVersement = dernierDateVersement
  ? `${dernierDateVersement.getDate()}/${dernierDateVersement.getMonth() + 1}/${dernierDateVersement.getFullYear()}`
  : "Aucun versement";
 
   useEffect(() => {
    getAllClientsFactureAbonnement()
      .then((response) => {
        setUsers(response.data);
        setallInitialData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  
 
 
const onChangeDate = () => {
 
  const filteredDate = listAbonnement.filter((listed) => { 

     const momentDate = moment(listed.date_debut) ;
     if(startDate > endDate){ 
      error_message(t("Attention La date début doit être inférieur à la date fin !!"))
    }else{
    return (
      momentDate.isSameOrAfter(moment(startDate)) &&
      momentDate.isSameOrBefore(moment(endDate))
    )};
  });
  setListAbonnement(filteredDate);
  setFilteredAbonnements(filteredDate); // Mettez à jour filteredAbonnements
};




const handleCollapse = () => {
  setisCollapsed(!isCollapsed);
};
const handleUserSelection = (e) => {
  const selectedUserId = e.target.value;

  
  // Appeler getClientById avec l'ID du client sélectionné
  getClientById(selectedUserId)
    .then((response) => {
       const userData = response.data;
      // Assurez-vous que les factures sont présentes dans userData (userData.factures)
      setSelectedUser(userData);
       setSelectedClient(userData);
      setSelectedUserData(userData);
     })
    .catch((error) => {
      console.error(error);
    });
};
 
useEffect(() => {
  console.log("selected factures après : ", selectedClient);
}, [selectedClient]); // Cette dépendance fait en sorte que le useEffect soit appelé à chaque fois que selectedClient change
  
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllAbonnementByCodeAndEntete();
         setListAbonnement(response.data);
  
        // Filtrer les données initiales par date de début et date de fin
        const filteredData = response.data.filter((el) => {
          const momentDateDebut = moment(el.date_debut);
          return momentDateDebut.isSameOrAfter(moment(startDate)) && momentDateDebut.isSameOrBefore(moment(endDate));
        });
  
        setFilteredAbonnements(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [startDate, endDate]);
  
  const handleFactureClick = () => {
    setIsFactureClicked(true);
  };
  const handleSearch = (e) => {
    const dataSearch = filterFactureByWordAbonnement(allInitialData, e.target.value);
    setallInitialData(dataSearch);
  };
 

useEffect(() => {
  const dateFinThreshold = moment().add(1, 'month').format("YYYY-MM-DD");
   const filteredData = listAbonnement.filter((el) => {
    const dateFin = moment(el.date_fin, 'YYYY-MM-DD');
    return dateFin.isBefore(dateFinThreshold, 'month') && dateFin.isSameOrAfter(moment(), 'month')  ;
  });

  setFilteredAbonnements(filteredData);
  setIsFactureClicked(false); // Réinitialiser le state après le filtrage
}, [listAbonnement]);

 
  
  return (
    <Fragment> 
    <div className="flexrow">
        {/* <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
      <div className="card-header " style={{fontSize:"meduim",fontWeight:"600"}}>{t("Nombre des factures")}</div>
      <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
        <h5 className="card-title text-success">{nombreTotalDeFactures}</h5>
        <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
          <i className="fa fa-money" />
        </div>
      </div>
    </div> */}
         {nombreTotalDeFactures !== null && (
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header " style={{ fontSize: "medium", fontWeight: "600" }}>{t("Nombre des factures")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-success">{nombreTotalDeFactures}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
      )}
       <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
      <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Nombre des Clients")}</div>
      <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
        <h5 className="card-title text-primary">{users.length}</h5>
        <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
          <i className="fa fa-money" />
        </div>
      </div>
    </div>
       <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
      <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Date Dernière Encaissement")}</div>
      <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
        <h5 className="card-title text-danger">{formattedDerniereDateVersement}</h5>
        <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
          <i className="fa fa-money" />
        </div>
      </div>
    </div>
       <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
      <div className="card-header "style={{fontSize:"meduim",fontWeight:"600"}}>{t(" Dernière Solde")}</div>
      <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
        <h5 className="card-title text-secondary">{dernierMontantEncaissement}</h5>
        <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
          <i className="fa fa-money" />
        </div>
      </div>
    </div>
    </div>


    <div className="row">
      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-8 col-md-12">
                <div className="row">
                  <div className="col-md-4 ">
                    <div className="form-group">
                      <label className="form-label"  >{t("Date Debut")}</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="feather feather-calendar" />
                          </div>
                        </div>
                        <input className="form-control floating" type="date" 
                        value={startDate}
                         onChange={handleStartDateChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">{t("Date Fin")}</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="feather feather-calendar" />
                          </div>
                        </div>
                        <input className="form-control floating" type="date"
                        onChange={handleEndDateChange}
                        value={endDate}
                         />
                        <div className="input-group-append">
                        <button
                    onClick={onChangeDate}
                    className="btn btn-success input-group-text"
                    id="basic-addon2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </button>
                   </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">{t("Clients")}</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                        </div>
                        <div className="input-group-append">
                        <span>
                      <select
                        //value={selectedUser.nom}
                        value={selectedUser.id} // Utilisez selectedUser.id ou une autre propriété pertinente
                        onChange={handleUserSelection}
                        className="form-control"
                        style={{marginLeft:"19px",width:"202px"}}
                      >
                        <option value="">{t("Sélectionnez un Clients")}</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.nom} {user.prenom}
                          </option>
                        ))}
                      </select>
                    </span>
                    <button  className='buttonFacture'  onClick={handleFactureClick}>
                          {t("A Facturée")}
                        </button>
                         </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
          <div className="card-header  border-0">
            <h4 className="card-title">{t("Liste de factures par chaque client")}</h4>

            <div className="card-options">
              <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                <i className="fe fe-maximize" />
              </Link>
            </div>
          </div>
          <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("payé")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("échu")}
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> {t("partial")}
                  </span> 
                </div>
              </div>
                <div className='card-body'>
                <div className="table-responsive" style={{backgroundColor:"white"}}>
                <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">

                  <div className='row'>
                  <div className="col-sm-12 col-md-6">
                      <div className="dataTables_length" id="hr-table_length">
                       
                      </div>
                 </div>
                      <div className="col-sm-12 col-md-6">
                      <div id="hr-table_filter" className="dataTables_filter">
                        <label className="mr-2">
                        <input
                          type="search"
                          className="form-control"
                          placeholder={t("Rechercher par nom de client...")}
                          aria-controls="hr-table"
                          onChange={handleSearch}
                          //value={searchTerm}
                        />
                        </label>

                        <ExportCsv data={listAbonnement} name={"Liste_Des_Taches"} />
                        <ExportPdf
                          data={listAbonnement}
                          name={"Liste_Des_Factures"}
                          columns={[
                            "nom",
                            "produit_code",
                            "date_debut",
                            "date_fin",
                            "nb_mois",
                            "nb_users",
                            "facture_code"
                          ]}
                        />
                        <CopieContent /> 
                      </div>
                  </div>   

                </div>
                </div>
                {/* {isFactureClicked && ( */}
                  <div className="row">
                  <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >  <thead>
                        <tr>
                          <th>{t("Client")}</th>
                          <th>{t("Produit")}</th>
                          <th>{t("date debut")}</th>
                          <th>{t("date fin")}</th>
                          <th>{t("nombre de mois")}</th>
                          <th>{t("nombre de user")}</th>
                          <th>{t("Code Facture")}</th>
                          <th>{t("Facturée")}</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                    {isFactureClicked
                      ? filteredAbonnements.map((el, index) => (
                          <tr key={index}>
                             <Link to={"/admin/clientactivity/" + el.client_code}>{el.nom}</Link>
                            <td>{el.libelle}</td>
                            <td>{el.date_debut}</td>
                            <td>{el.date_fin}</td>
                            <td>{el.nb_mois}</td>
                            <td>{el.nb_users}</td>
                            <td>{el.facture_code}</td>
                            <td></td>
                          </tr>
                        ))
                      : (selectedUser.id
                          ? listAbonnement
                              .filter((el) => el.client_id === selectedUser.id)
                              .map((el, index) => (
                                <tr key={index}>
                                  <Link to={"/admin/clientactivity/" + el.client_code}>{el.nom}</Link>
                                  <td>{el.libelle}</td>
                                  <td>{el.date_debut}</td>
                                  <td>{el.date_fin}</td>
                                  <td>{el.nb_mois}</td>
                                  <td>{el.nb_users}</td>
                                  <td>{el.facture_code}</td>
                                  <td></td>
                                </tr>
                              ))
                          : listAbonnement
                              .filter((el) => !selectedUser || el.client_id !== selectedUser.id)
                              .map((el, index) => (
                                <tr key={index}>
                                   <Link to={"/admin/clientactivity/" + el.client_code}>{el.nom}</Link>
                                  <td>{el.libelle}</td>
                                  <td>{el.date_debut}</td>
                                  <td>{el.date_fin}</td>
                                  <td>{el.nb_mois}</td>
                                  <td>{el.nb_users}</td>
                                  <td>{el.facture_code}</td>
                                  <td></td>
                                </tr>
                              ))
                        )
                    }
                  </tbody> 
                        </table>
                      </div>
         </div>
       {/* )} */}
                  
                 

    </div>
    </div>
    </div>
    </div>
    </div>
  </Fragment>
  );


}

export default ListFactureClients;
