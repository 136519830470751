import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { addDepartement, deleteDepartementByCode, getDepartementByCode } from "../../Services/Pointeuse/DepartementApi";
import { addDepartementMembre, deleteDepartementMemberByCode, getAllDepartementMembers } from "../../Services/Pointeuse/DepartementMemberApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { filterUserByWord } from "../../Utils/SortObject";
import { useHistory } from "react-router-dom";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";
import { t } from "i18next";

function EditDeparement(props) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [nom, setnom] = useState(null);
  const history = useHistory();

  /**
   * It fetches all the users from the database and then fetches all the members of the departement.
   */
  const fetchAllUser = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        const newArr = res.data.map((v) => ({ ...v, isSelected: false }));
        setdata(newArr);
        setallInitialData(newArr);

        getDepartementByCode(props.match.params.id).then((res) => {
          setnom(res.data.nom);
          getAllDepartementMembers(props.match.params.id).then((members) => {
            const resultSelected = newArr.filter(({ id: id1 }) => members.data.some(({ user_id: id2 }) => id1 === id2));
            const newSelected = resultSelected.map((v) => ({ ...v, isSelected: true }));
            const resultNotSelected = newArr.filter(({ id: id1 }) => !members.data.some(({ user_id: id2 }) => id1 === id2));
            const mergedObje = newSelected.concat(resultNotSelected);
            console.log(mergedObje);
            //setdata(resultSelected.concat(resultNotSelected));
            setdata(mergedObje);
            setallInitialData(mergedObje);
          });
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterUserByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * *This function takes in an id and a boolean value and returns a new array with the isSelected
   * property of the object with the matching id set to the opposite of the boolean value.*
   * @param id - The id of the object you want to select.
   * @param isSelected - This is the current state of the checkbox.
   */
  const handleSelect = (id, isSelected) => {
    const objIndex = data.findIndex((obj) => obj.id == id);
    const list = [...data];
    list[objIndex]["isSelected"] = !isSelected;
    setdata(list);
    //setdata((data[objIndex].isSelected = !isSelected));
  };

  /**
   * It adds a new departement with the given name and the users selected.
   */
  const handleAdd = () => {
    console.log("rrr");
    deleteDepartementByCode(props.match.params.id).then(() => {
      deleteDepartementMemberByCode(props.match.params.id).then(() => {
        let code = parseInt(Date.now() * Math.random());
        const userSelected = data.filter((el) => {
          return el.isSelected == true;
        });
        addDepartement(code, nom).then((res) => {
          userSelected.forEach((element) => {
            addDepartementMembre(element.id, code);
          });
          history.push("/admin/departements");
        });
      });
    });
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Editer département")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <a href="hr-addpayroll.html" className="btn btn-primary mr-3">
                Ajouter département
              </a>
              <button className="btn btn-secondary mr-3" data-toggle="modal" data-target="#excelmodal">
                <i className="las la-file-excel" /> Download Monthly Excel Report
              </button> */}
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">Deduction</h4> */}
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12 col-lg-2">
                    <label className="form-label mb-0 mt-2">{t("Nom département")}</label>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <input
                      onChange={(e) => setnom(e.target.value)}
                      value={nom}
                      type="text"
                      className="form-control"
                      placeholder={0}
                      defaultValue={0.0}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">{t("Collaborateurs")}</h4>
              <div className="card-body table-responsive p-0">
                {loading && <CustomSyncLoader></CustomSyncLoader>}
                {!loading && error && <ErrorCustum></ErrorCustum>}
                {!loading && !error && data && (
                  <div className=" mt-5">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            <th></th>
                            <th>{t("Nom/Prénom")}</th>
                            <th>{t("Rôle")}</th>
                            <th>{t("Statut")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((el) => (
                            <tr key={el.id}>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  onChange={() => {
                                    handleSelect(el.id, el.isSelected);
                                  }}
                                  checked={el.isSelected}
                                />
                              </td>
                              <td>
                                <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                              </td>
                              <td>
                                <Link to={"/admin/users/" + el.id}>
                                  {el.nom} <span>{el.prenom}</span>
                                </Link>
                              </td>

                              <td>{el.fonction}</td>
                              <td>
                                {el.isactif == 0 ? (
                                  <span className="badge badge-warning">{t("Inactif")}</span>
                                ) : (
                                  <span className="badge badge-success">{t("actif")}</span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              {/* /.card-body */}
              <div className="card-footer clearfix">
                <Pagination items={data} onChangePage={onChangePage} pageSize={5} />
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 col-lg-2"></div>
                <div className="col-md-12 col-lg-8">
                  <Link to="#" className="btn btn-primary btn-lg" onClick={handleAdd}>
                    {t("Enregistrer")}
                  </Link>
                  {/* <a href="#" className="btn btn-danger btn-lg">
                    Cancle
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditDeparement;
