// import { CSVLink } from 'react-csv';

import { useRef } from "react";
import { CSVLink } from "react-csv";
import {Link} from "react-router-dom"
function ExportCsvFactureVente({ data, name }) {
  const csvLinkEl = useRef(null);

  const columnsToExport = data.map(({ facture_code,client_nom,date, montant_total_ht, montant_total_tva, montant_total_ttc,montant_encaissement,solde,statut,date_prevu_livraison }) => ({
    facture_code,
    client_nom,
    date,
    montant_total_ht,
    montant_total_tva,
    montant_total_ttc,
    montant_encaissement,
    solde,
    statut,
    date_prevu_livraison
  }));

  const headers = [
    { label: 'N° Facture', key: 'facture_code' },
    { label: 'Client', key: 'client_nom' },
    { label: 'Date', key: 'date' },
    { label: 'Montant HT', key: 'montant_total_ht' },
    { label: 'Montant TVA', key: 'montant_total_tva' },
    { label: 'Montant TTC', key: 'montant_total_ttc' },
    { label: 'Encaissement', key: 'montant_encaissement' },
    { label: 'Solde', key: 'solde' },
    { label: 'Statut', key: 'statut' },
    { label: `Date d'échéance`, key: 'date_prevu_livraison' },
  ];

  /**
   * Download a report as a CSV file
   */
  const downloadReport = async () => {
    setTimeout(() => {
      csvLinkEl?.current.link.click();
    });
  };

  return (
    <>
      <Link to="#" className="action-btns" onClick={downloadReport} data-toggle="tooltip" data-placement="top" title="csv">
        <i className="fa fa-file-excel-o text-success" />
      </Link>
      <CSVLink
        data={columnsToExport}
        headers={headers}
        filename={`${name}.csv`}
        ref={csvLinkEl}
      />
    </>
  );
}

export default ExportCsvFactureVente;
