import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It adds a new mouvement to the database.
 * @param produit_code - The code of the product
 * @param quantite - The quantity of the product that is being moved.
 * @param prix_ht - The price of the product without taxes
 * @param prix_ttc - The total price of the product, including taxes.
 * @param taux_tva - taux de TVA
 * @param type - type of mouvement (1: achat, 2: vente, 3: transfert)
 * @param numero - The number of the mouvement
 * @returns The response is an object that contains the data that was returned by the server.
 */
export async function addMouvement(produit_code, quantite, prix_ht, prix_ttc, taux_tva, type, numero) {
  const credentiel = {
    magasinier_code: getSession("id"),
    depot_code: getSession("id"),
    commercial_code: getSession("id"),
    caisse_code: "",
    produit_code: produit_code,
    prix_achat_ht: "0",
    prix_achat_ttc: "0",
    quantite: quantite,
    qte_demande: "1",
    prix_ht: prix_ht,
    prix_ttc: prix_ttc,
    p_tva: "1",
    taux_tva: taux_tva,
    type: type,
    num_serie: "",
    numero: numero,
    configuration: "",
    etat: "",
    isSync: "0",
    wavesoft: "",
    deleted_at: "2020-01-01",
    created_at: "",
    updated_at: "2020-01-01",
    date: "2020-01-01",
    date_accep: "",
  };

  try {
    const response = await axios.post(api_url + "facturation/mouvement/", credentiel, config);
    console.log(response);
    toast.success(t("Mouvement ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a mouvement by its code.
 * @param code - The code of the mouvement to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "status": "success",
 *   "data": {
 *     "id": "5e9b8f9b9d0b9b0017c6e5b7",
 *     "produit": {
 *       "code": "PRODUIT_CODE",
 */
export async function deleteMouvementByProduitCode(code) {
  try {
    const response = await axios.delete(api_url + "facturation/mouvement/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
