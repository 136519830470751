import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  getEnteteCommercialAchatAvoir,
  getEnteteCommercialAchatCommande,
  getEnteteCommercialVenteAvoir,
  getEnteteCommercialVenteCommande,
  getlistAnneeVente,
} from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit } from "../../../../../Utils/NumberUtils";
import { sumMontantHT, sumMontantTTC, sumMontantTVA } from "../../../../../Utils/ObjectOperation";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function ListAvoirVente() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [size, setsize] = useState(40);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t } = useTranslation();
  const [listAnnnees, setListAnnnes] = useState([]);
  const [selectedAnnee, setselectedAnnee] = useState();


  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData(selectedAnnee);
  };


  useEffect(() => {
    getlistAnneeVente()
      .then((res) => {
        setListAnnnes(res.data)

        setselectedAnnee(res.data[0].year)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (listAnnnees.length !== 0) {
      getData(listAnnnees[0].year);

    } else {

      getData(new Date().getFullYear());

    }
  }, [listAnnnees]);

  const getData = (d) => {
    setLoading(true);
    getEnteteCommercialVenteAvoir(d)
      .then((res) => {
        const currentYearArray = res.data;

        setdata(currentYearArray);
        setallInitialData(currentYearArray);

      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    console.log('ppppp',pageOfItems)
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterFactureByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  // In your parent component
  const updateCurrentData = (newData) => {
    setcurrentData(newData);
  };



  const handleSelectDate = (e) => {
    const val = e.target.value;
    setselectedAnnee(val)
    getData(val);
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <select className="btn btn-primary mr-3 " onChange={handleSelectDate} >
                {listAnnnees.map((item, index) => (
                  <option key={index} value={item.year}>Avoir de vente {item.year}</option>

                ))}

              </select>
              <Link to="ajoutavoirvente" className="btn btn-primary mr-3">
                <i className="fa fa-plus" />
              </Link>

              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem', margin: "auto" }}>
            <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant HT")}</div>
            <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
              <h5 className="card-title text-success">{sumMontantHT(data)}</h5>
              <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
                <i className="fa fa-money" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem', margin: "auto" }}>
            <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TVA")}</div>
            <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
              <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
              <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
                <i className="fa fa-money" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem', margin: "auto" }}>
            <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TTC")}</div>
            <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
              <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
              <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
                <i className="fa fa-money" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                {t(" List Commande d'achat")}{" "}
                <span title="3 New Messages" className="badge badge-warning">
                  {data.length}
                </span>
              </h3>
              <div className="card-tools">
                <div className="row">
                  <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                    <input
                      type="text"
                      name="table_search"
                      className="form-control floating"
                      placeholder={t("Rechercher...")}
                      onChange={handleSearch}
                    />
                  </div>


                </div>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                
                <div className="table-responsive-sm">
                  
                  <table className="table table-striped custom-table m-b-0">
                    <thead>
                      <tr>
                        <th>{t("N° Facture")}</th>
                        <th>{t("Client")} </th>
                        <th>
                          {t("Montant HT")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                        </th>
                        <th>
                          {t("Montant TVA")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                        </th>
                        <th>
                          {t("Montant TTC")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                        </th>
                        <th>{t("Statut")}</th>
                        <th>{t("Date")}</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((el) => (
                        <tr key={el.id}>
                          <td>
                            <div className="d-flex">
                              <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                              <div className="mt-1 mr-4">
                                <Link to={"/admin/editeravoirvente/" + el.code} >
                                  {el.facture_code}
                                </Link>
                              </div>
                            </div>
                            {/* <Link to={"/admin/editercommandeachat/" + el.code} >
                          {el.facture_code}
                            </Link> */}

                          </td>
                          {/* <td>{el.client_nom}</td> */}
                          <Link to={"/admin/clientactivity/" + el.client_code}>{el.client_nom}</Link>
                          <td>{convertToDigit(el.montant_total_ht)}</td>
                          <td>{convertToDigit(el.montant_total_tva)}</td>
                          <td>{convertToDigit(el.montant_total_ttc)}</td>
                          <td>{el.status}</td>
                          <td>{el.date.split(" ")[0]}</td>

                          <td className="project-actions text-right">
                            <Link to={"/admin/editeravoirvente/" + el.code} className="btn btn-default btn-xs">
                              <i className="fas fa-pencil-alt fa-xs"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                  {t("Affichage")} {t("de")} {size} {t("sur")} {data.length} {t("entrées")}
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
              </div>
            </div>
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
}

export default ListAvoirVente;
