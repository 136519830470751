import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addProjetMedia, deleteProjetMediaByProjetId, getProjetMediaByProjetId } from "../../Services/Pointeuse/ProjetMediaApi.js";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import * as FileSaver from "file-saver";
import swal from "sweetalert";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FileImageComponent from "../Styles/FileImageComponent";
import ImageComponent from "../Styles/ImageComponent.js";
import { getSession, getToken } from "../../Utils/SessionUtils.js";
import { t } from "i18next";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function FilesProject({ projetId }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  const getData = () => {
    setLoading(true);
    getProjetMediaByProjetId(projetId)
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handelUpdate = () => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i].file;
      addProjetMedia(file, projetId).then(() => {
        setFiles(null);
        getData();
      });
    }
  };

  const handleDeleteFile = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProjetMediaByProjetId(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };

  return (
    <div className="card-body">
      <h5 className="mb-4 mt-5 font-weight-semibold">Attachments</h5>
      <div className="attachments-doc">
        {loading && <CustomSyncLoader></CustomSyncLoader>}
        {!loading && error && <ErrorCustum></ErrorCustum>}
        {!loading && !error && data && (
          <div className="row">
            {data.map((el, idx) => (
              <div key={el.idx} className="col-md-12 col-xl-4 mb-2">
                <div className="list-group-item  align-items-center">
                  <div className="d-md-flex">
                    {["png", "jpg", "jpeg"].some((element) => el.file.split(".").pop().includes(element)) && (
                      <ImageComponent atr={"avatar bg-transparent avatar-xl mr-2"} picture={el.file}></ImageComponent>
                    )}
                    {el.file.split(".").pop() === "pdf" && (
                      <img src="assets/images/files/attachments/pdf.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "css" && (
                      <img src="assets/images/files/attachments/css.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "html" && (
                      <img src="assets/images/files/attachments/html.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "zip" && (
                      <img src="assets/images/files/attachments/zip.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "docx" && (
                      <img src="assets/images/files/attachments/docx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "txt" && (
                      <img src="assets/images/files/attachments/txt.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}

                    {el.file.split(".").pop() === "js" && (
                      <img src="assets/images/files/attachments/js.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "apk" && (
                      <img src="assets/images/files/attachments/apk.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "svg" && (
                      <img src="assets/images/files/attachments/svg.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "pptx" && (
                      <img src="assets/images/files/attachments/pptx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "xlsx" && (
                      <img src="assets/images/files/attachments/xlsx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "rar" && (
                      <img src="assets/images/files/attachments/rar.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}
                    {el.file.split(".").pop() === "sql" && (
                      <img src="assets/images/files/attachments/sql.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
                    )}

                    <Link
                      to="#"
                      className="font-weight-semibold fs-14 mt-5"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={el.file.split("Z")[1].split(".")[0]}
                    >
                      {el.file.split("Z")[1].split(".")[0].substring(0, 12)}
                      <span className="text-muted ml-2">(.{el.file.split("Z")[1].split(".")[1]})</span>
                    </Link>
                    <div className="ml-auto d-flex mt-4 text-right">
                      <Link to="#" className="action-btns1">
                        <i className="feather feather-download-cloud text-primary" onClick={() => saveFile(el.file)} />
                      </Link>
                      <Link to="#" className="action-btns1  mr-0">
                        <i className="feather feather-trash-2 text-danger" onClick={() => handleDeleteFile(el.id)} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="row mt-5">
          <div className="col-md-12 col-xl-12">
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={true}
              maxFiles={10}
              name="file"
              dropOnPage
              server={{
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                  // fieldName is the name of the input field
                  // file is the actual file object to send
                  const api_url = process.env.REACT_APP_API_BASE_URL_V1 + "projetmedia";
                  const frmData = new FormData();
                  frmData.append("code_generated", getSession("code_generated"));
                  frmData.append("projet_id", projetId);
                  frmData.append("user_id", getSession("id"));
                  frmData.append("file", file);

                  const request = new XMLHttpRequest();
                  request.open("POST", api_url);
                  request.setRequestHeader("Authorization", "Bearer " + getToken());

                  // Should call the progress method to update the progress to 100% before calling load
                  // Setting computable to false switches the loading indicator to infinite mode
                  request.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                  };

                  // Should call the load method when done and pass the returned server file id
                  // this server file id is then used later on when reverting or restoring a file
                  // so your server knows which file to return without exposing that info to the client
                  request.onload = function () {
                    if (request.status >= 200 && request.status < 300) {
                      // the load method accepts either a string (id) or an object
                      load(request.responseText);
                      //alert("ccccccccc");
                      setTimeout(() => {
                        getData();
                        setFiles(null);
                      }, 1000);
                    } else {
                      // Can call the error method if something is wrong, should exit after
                      error("oh no");
                    }
                  };

                  request.send(frmData);
                  //axios.post(api_url, frmData, cc);

                  // Should expose an abort method so the request can be cancelled
                  return {
                    abort: () => {
                      // This function is entered if the user has tapped the cancel button
                      request.abort();
                      // Let FilePond know the request has been cancelled
                      abort();
                    },
                  };
                },
              }}
              dropValidation
              labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
            />
          </div>
        </div>
      </div>
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="text-right">
            <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handelUpdate}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilesProject;
