import React from "react";
import HeroSection from "../../HeroSection";
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from "./Data";
import Pricing from "../../Pricing";
import SectionOne from "../../section/SectionOne";
import SectionTwo from "../../section/SectionTwo";
import SectionThree from "../../section/SectionThree";
import SectionFour from "../../section/SectionFour";
import Fonctionnallity from "../Fonctionnality/Fonctionnallity";
import Services from "../Services/Services";
import SectionFive from "../../section/SectionFive";
import Products from "../Products/Products";

function Home() {
  return (
    <>
      <SectionOne></SectionOne>
      <SectionTwo></SectionTwo>
      <SectionFive></SectionFive>
      <Fonctionnallity></Fonctionnallity>
      <SectionThree></SectionThree>
      <Services></Services>
      <SectionFour></SectionFour>
      <Products></Products>
    </>
  );
}

export default Home;
