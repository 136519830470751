import React from "react";
import "./Products.css";

function Products() {
  return (
    <div className="section3">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 module come-in">
            <div className="img3">
              <img src="assets/images/welcome/mock_1.png" alt="img1" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 module come-in">
            <div className="con1">
              <span>Caractéristiques</span>
              <h2>Optez de la meilleure solution pour digitaliser votre entreprise</h2>
              <div className="tags">
                <div className="tg">
                  <div className="tgimg">
                    <img
                      src="http://kalanidhithemes.com/live-preview/landing-page/unique-software/images/i1.png"
                      alt="icon1"
                    />
                  </div>
                  <div className="tgcon">
                    <span>Suivez les heures de travail avec précision</span>
                    <p>Une solution tres pratique pour les employées et qui répond réellement à tous vos besoins RH</p>
                    <p />
                  </div>
                  <div className="clear" />
                </div>
                <div className="tg">
                  <div className="tgimg">
                    <img
                      src="http://kalanidhithemes.com/live-preview/landing-page/unique-software/images/i2.png"
                      alt="icon2"
                    />
                  </div>
                  <div className="tgcon">
                    <span>Conserver vos temps de travail</span>
                    <p>
                      Tous vos pointages, congés, tâches de vos collaborateurs se trouvent dans une seul application
                    </p>
                    <p />
                  </div>
                  <div className="clear" />
                </div>
                <div className="tg">
                  <div className="tgimg">
                    <img
                      src="http://kalanidhithemes.com/live-preview/landing-page/unique-software/images/i3.png"
                      alt="icon3"
                    />
                  </div>
                  <div className="tgcon">
                    <span>Design simple et claire</span>
                    <p>
                      Les pages sont non seulement codées par couleur pour une distinction facile a l'oeil, mais la
                      conception des petites icônes et du text clair.
                    </p>
                    <p />
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
