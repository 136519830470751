import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CopieContent from "../Exports/CopieContent";
 import ExportPdf from "../Exports/ExportPdf"; 
import {  getAllPointageHistorydate, getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import {   exportDataPointage } from "../../Utils/DateUtils";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import {   getUsersPointage } from "../../Services/Pointeuse/UsersApi";
 import { CSVLink } from "react-csv";

import moment from "moment";
var dateObj = new Date();
var result = dateObj.toISOString().split("T")[0];
var month = result.split("-")[1];
var year = result.split("-")[0];
var newdate = year + "-" + month;
function PointageStaticMonth() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(100);
  const [date, setdate] = useState(new Date(newdate));
  const [users, setusers] = useState([]);
  const [settings, setsettings] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [thisMonth, setthisMonth] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  const [selectedUser, setSelectedUser] = useState('');
  const [userSelectionChanged, setUserSelectionChanged] = useState(false);
  const [allRows, setAllRows] = useState([])

  useEffect(() => {
    getUsersPointage()
      .then((response) => {   
        setusers(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  
  useEffect(() => {
    getData();
  }, [thisMonth, selectedUser, userSelectionChanged]);
  useEffect(() => {
    getAllSettings().then((res) => {
      setsettings(res.data);
    });
  }, []);
 
const getData = () => {
  setLoading(true);

  // Utilisez getAllPointageHistorydate pour récupérer les données pour le mois sélectionné
  getAllPointageHistorydate(thisMonth)
    .then((res) => {
      const newFilteredData = handleFilterData(thisMonth, res.data);
      // Filtrer les données en fonction de l'utilisateur sélectionné
      const userSpecificData = selectedUser
        ? newFilteredData.filter((el) => el.user_code_id === selectedUser)
        : newFilteredData;

      setallInitialData(newFilteredData);
      setdata(userSpecificData);
      setcurrentData(userSpecificData);
    })
    .catch((error) => setError(error))
    .finally(() => setLoading(false));
};
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
 

  const handleFilterData = (currentMonth, values) => { 
    return values.filter((el) => {
      const pointageDates = el.pointages[0].split(",");
        // Filtrer les dates en fonction du mois
      const filteredDates = pointageDates.filter(date => date.startsWith(currentMonth));
  
   
      return filteredDates.length > 0;
    });
  };
  
  useEffect(() => {
    getData();
  }, [thisMonth, selectedUser, userSelectionChanged]);
 
  

const handleChangeDate = async (event) => {
  const selectedDate = new Date(event.target.value);
  const selectedMonth = selectedDate.getMonth() + 1; // Mois (1-12)
  const selectedYear = selectedDate.getFullYear();

  // Mettez à jour thisMonth uniquement avec le mois et l'année
  const formattedMonth = `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}`;

  // Mettez à jour l'état thisMonth
  setthisMonth(formattedMonth);

  try {
    // Utilisez getAllPointageHistorydate pour récupérer les données pour le mois sélectionné
    const response = await getAllPointageHistorydate(formattedMonth);

    // Processus des résultats de l'API et mettez à jour l'état data
    const newFilteredData = handleFilterData(formattedMonth, response.data);

    // Filtrer les données en fonction de l'utilisateur sélectionné
    const userSpecificData = selectedUser
      ? newFilteredData.filter((el) => el.user_code_id === selectedUser)
      : newFilteredData; 
    setallInitialData(newFilteredData);
    setdata(userSpecificData);
    setcurrentData(userSpecificData);
  } catch (error) {
    console.error("Erreur lors de la récupération des données de pointage :", error);
    // Gérez l'erreur en conséquence (par exemple, affichez un message à l'utilisateur)
  }
};
 
 

const handleUserSelection = async (e) => {
  const selectedUserId = e.target.value;

  setSelectedUser(selectedUserId);

  // Réinitialisez l'état thisMonth si aucun utilisateur n'est sélectionné
  if (!selectedUserId) {
    setthisMonth(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  }

  // Obtenez la date actuellement sélectionnée
  const selectedDate = new Date(thisMonth);
  const selectedMonth = selectedDate.getMonth() + 1; // Mois (1-12)
  const selectedYear = selectedDate.getFullYear();

  // Mettez à jour thisMonth uniquement avec le mois et l'année
  const formattedMonth = `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}`;

  // Mettez à jour l'état thisMonth
  setthisMonth(formattedMonth);

  // Remettez à zéro l'état userSelectionChanged
  setUserSelectionChanged(true);
};



const prepareExportData = (data) => {
  const exportData = [];

  data.forEach((el) => {
    el.pointages.forEach((date) => {
      const dates = date.split(",");
      
      dates.forEach((singleDate, pointageIndex) => {
        let singleJourney = {};
        let formattedIndexDate = new Date(singleDate).getDate();
        let previousIndexDate;
        let nextIndexDate;

        if (pointageIndex !== 0) {
          previousIndexDate = new Date(dates[pointageIndex - 1]).getDate();
        }

        if (dates.length !== 1) {
          nextIndexDate = new Date(dates[pointageIndex + 1]).getDate();

          if (pointageIndex !== 0 && formattedIndexDate === previousIndexDate) {
            singleJourney = {
              checkin: dates[pointageIndex - 1],
              checkout: singleDate,
            };
          } else if (
            pointageIndex === dates.length - 1 &&
            formattedIndexDate !== previousIndexDate
          ) {
            singleJourney = {
              checkin: singleDate,
              checkout: "00:00:00 17:30:00",
            };
          } else if (
            pointageIndex === 0 &&
            formattedIndexDate !== nextIndexDate
          ) {
            singleJourney = {
              checkin: singleDate,
              checkout: "00:00:00 17:30:00",
            };
          }
        } else {
          singleJourney = {
            checkin: singleDate,
            checkout: "00:00:00 17:30:00",
          };
        }

        var difference;
        var differenceWithBreak;
        if (
          singleJourney.checkin !== undefined &&
          singleJourney.checkout !== undefined
        ) {
          const entryMoment = moment(
            singleJourney.checkin.split(" ")[1],
            "HH:mm:ss"
          );
          const exitMoment = moment(
            singleJourney.checkout.split(" ")[1],
            "HH:mm:ss"
          );
          difference =
            exitMoment.diff(entryMoment, "hours") +
            ":" +
            (exitMoment.diff(entryMoment, "minutes") % 60);

          differenceWithBreak = moment
            .duration(difference)
            .subtract(1, "hours")
            .format("HH:mm", { trim: false });
        }

        singleJourney = {
          ...singleJourney,
          prenom: el.prenom,
          nom: el.nom,
          difference: difference,
          differenceWithBreak: differenceWithBreak,
        };

        if (singleJourney.checkin !== undefined) {
          exportData.push({
            Nom_Prenom: `${el.nom} ${el.prenom}`,
            Date: singleDate.split(" ")[0],
            Pointage_Entree: singleJourney.checkin.split(" ")[1],
            Pointage_Sortie: singleJourney.checkout.split(" ")[1],
            Heures_Travail: singleJourney.difference,
            Heures_Travail_Avec_Pause: singleJourney.differenceWithBreak,
          });
        }
      });
    });
  });

  return exportData;
};


 


 // Function to prepare export headers
 const prepareExportHeaders = () => {
  return [
    { label: "Nom_Prenom", key: "Nom_Prenom" },
    { label: "Date", key: "Date" },
    { label: "Pointage_Entree", key: "Pointage_Entree" },
    { label: "Pointage_Sortie", key: "Pointage_Sortie" },
    { label: "Heures_Travail", key: "Heures_Travail" },
    { label: "Heures_Travail_Avec_Pause", key: "Heures_Travail_Avec_Pause" },
  ];
};
  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage du mois")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")} : </label>
                </div>
                <div style={{display:'flex'}}>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("Pointage d'entrée")}
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="feather feather-x-circle text-warning" /> {t("Pointage de sortie")}
                  </span>
                  <span className="badge badge-primary-light mr-2">
                    <i className="feather feather-x-circle text-primary" /> {t("Total")}
                  </span>
                  <span>  
                          <select
                      value={selectedUser}
                      onChange={handleUserSelection}
                      className="form-control"
                    >
                      <option value="">{t("Sélectionnez un utilisateur")}</option>
                      {/* <option value="allUsersForYear">Tous les utilisateurs de l'année</option> */}
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.nom} {user.prenom}
                        </option>
                      ))}
                    </select>


                  </span>
                </div>
              </div>
              <div className="table-responsive">
                <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_length" id="hr-table_length">
                        <label>
                          {t("Afficher")}{" "}
                          <select value={size} className="form-control" id="exampleFormControlSelect1" 
                          //onChange={handleChangeSize}
                          >
                            <option value={8}>8</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={60}>60</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          {t("entrées")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div id="hr-table_filter" className="dataTables_filter">
                        <label className="mr-2">
                          <input
                            type="month"
                            className="form-control"
                            placeholder="Recherche..."
                            aria-controls="hr-table"
                            value={thisMonth}
                             onChange={handleChangeDate} 
                           />
                        </label>   
                        <CSVLink
                          data={prepareExportData(currentData)}
                          headers={prepareExportHeaders()}
                          filename={`Pointage_du_mois_${thisMonth}.csv`}
                          className="action-btns"
                        >
                           <i className="fa fa-file-excel-o text-success" />
                        </CSVLink>


                        <ExportPdf
                          data={exportDataPointage(currentData, settings?.breakTime)}
                          name={`Pointage du mois ${thisMonth}}`}
                          columns={["Nom","Prénom","date", "pointage d'entrée", "pointage de sortie", "heure de travail", "heure de travail avec pause"]}
                        />
                        <CopieContent />
                      </div>
                    </div>
                  </div>
                   {/* {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data.length > 0 && settings && ( */}
                    <>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                            id="hr-table"
                            role="grid"
                            aria-describedby="hr-table_info"
                          >
                            <thead>
                              <tr>
                              <th>{t("Nom/Prénom")}</th>
                                <th>{t("Date")}</th>
                                <th>{t("Pointage d'entrée")}</th>
                                <th>{t("Pointage de sortie")}</th>
                                <th>
                                  {t("Heures de travail")}
                                   {/* <span className="badge badge-pill badge-danger">{sumTime(data)}</span> */}
                                </th>
                                <th>
                                  {t("Heures de travail avec pause")}{" "}
                                  {/* <span className="badge badge-pill badge-danger">{sumTimeWithBreak(data, settings?.breakTime)}</span> */}
                                </th>
                              </tr>
                            </thead> 
                            
                            <tbody>
                             {currentData.map((el) =>  (
                              <React.Fragment key={el.id}>
                                {el.pointages.map((date) => {
 
                                   const dates = date.split(",");
                                   return dates.map((singleDate, pointageIndex) => {
                                    let singleJourney = {}
                                     let formattedIndexDate = new Date(singleDate).getDate();
                                     let previousIndexDate;
                                     let nextIndexDate;
                                    if (pointageIndex != 0 ) {
                                      previousIndexDate = new Date(dates[pointageIndex-1]).getDate();
                                    }
                                    if (dates.length !== 1) {
                                      nextIndexDate = new Date(dates[pointageIndex+1]).getDate();

                                      if (pointageIndex != 0 && formattedIndexDate === previousIndexDate) {
                                        singleJourney = {
                                          checkin: dates[pointageIndex-1],
                                          checkout: singleDate
                                        }
                                       } 
                                      else if ((pointageIndex == dates.length -1) && (formattedIndexDate !== previousIndexDate)) {
                                        singleJourney = {
                                          checkin: singleDate,
                                          checkout: "00:00:00 17:30:00"
                                        }
                                       } 
                                      else if (pointageIndex == 0 && formattedIndexDate != nextIndexDate) {
                                        singleJourney = {
                                          checkin: singleDate,
                                          checkout: "00:00:00 17:30:00"
                                        }
                                      }
                                    } else {
                                      singleJourney = {
                                        checkin : singleDate,
                                        checkout : "00:00:00 17:30:00"
                                      }
                                     }
                                    var difference;
                                    var differenceWithBreak;
                                    if (singleJourney.checkin !== undefined && singleJourney.checkout !== undefined) {
                                      const entryMoment = moment(singleJourney.checkin.split(' ')[1], 'HH:mm:ss');
                                      const exitMoment = moment(singleJourney.checkout.split(' ')[1], 'HH:mm:ss');
                                      difference = exitMoment.diff(entryMoment, 'hours') + ':' + exitMoment.diff(entryMoment, 'minutes') % 60;

                                      differenceWithBreak = moment.duration(difference).subtract(1, 'hours').format("HH:mm", { trim: false });

                                    }
                                    
                                    singleJourney = {
                                      ...singleJourney,
                                      prenom: el.prenom,
                                      nom: el.nom,
                                      difference: difference,
                                      differenceWithBreak: differenceWithBreak
                                    } 

                                    if (singleJourney.checkin !== undefined) {
                                      return (
                                        <tr key={`${el.id}-${pointageIndex}`}>
                                           <td>
                                            <span className="badge">{el.nom} {el.prenom}</span>
                                          </td>
                                          <td>
                                            <span className="badge badge-default">{singleDate.split(" ")[0]}</span>
                                          </td>
                                          <td>
                                            <span className="badge badge-success">{singleJourney.checkin.split(' ')[1]}</span>
                                          </td>
                                          <td>
                                            <span className="badge badge-warning">{singleJourney.checkout.split(' ')[1]}</span>
                                          </td>
                                          <td>
                                            <span className="badge badge-info">{difference}</span>
                                          </td>
                                          <td>
                                            <span className="badge badge-primary">{differenceWithBreak}</span>
                                          </td>
                                        </tr>
                                      );
                                    }
                                    
                                  });
                                })}
                              </React.Fragment>
                            ))} 
                        </tbody>

                          </table>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                            {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                        </div>
                      </div> */}
                    </>
                     {/* )}   */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* leflet */}
      </div>
    </>
  );
}

export default PointageStaticMonth;
