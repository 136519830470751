import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PaymentForm from "./PaymentForm";
import PaymentRequestForm from "./PaymentRequestForm";
import RegisterForm from "./RegisterForm";
import StripeContainerGoogle from "./StripeContainerGoogle";
import StripeContainerCard from "./StripeContainerCard";
import { convertToDigit } from "../../Utils/NumberUtils";
import moment from "moment";

function LandingPrice() {
  const myRef = useRef(null);
  const [isClicked, setisClicked] = useState(false);
  const [amount, setamount] = useState(0);
  const [initialAmount, setinitialAmount] = useState(0);
  const [months, setmonths] = useState(null);
  const [nbUsers, setusers] = useState(1);
  const [showNbCollab, setshowNbCollab] = useState(true);
  const [finContrat, setfinContrat] = useState(null);

  /**
   * This function is used to set the amount, the number of months and the expire date of the contract
   * @param am - Amount of the subscription
   * @param month - The number of months you want to extend the contract for.
   */
  const handleSelectPriceMonth = (am, month) => {
    setisClicked(true);
    setamount(am);
    setinitialAmount(am);
    setmonths(month);
    setusers(1);

    var myDate = new Date();
    myDate.setMonth(myDate.getMonth() + month);
    var newExpireDate = myDate.setDate(myDate.getDate() - 1);
    var datefin = moment(newExpireDate).format("YYYY-MM-DD");
    setfinContrat(datefin);
  };

  useEffect(() => {
    myRef?.current?.scrollIntoView();
  }, [isClicked]);

  /**
   * * Add users to the database
   * @param value - The value of the slider.
   */
  const handleAddUsers = (value) => {
    if (value > 0) {
      setusers(value);
      setamount(value * initialAmount);
    }
  };

  /**
   * *This function is used to hide the number of collaborators from the user.*
   */
  const handleHideNbCollab = () => {
    setshowNbCollab(false);
  };

  return (
    <div>
      <section>
        <div className="bannerimg " data-image-src="assets/images/photos/banner1.jpg">
          <div className="header-text mb-0">
            <div className="container">
              <div className="row text-white">
                <div className="col">
                  <h1 className="mb-0">Pointeuse</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section */}
      {/*Section*/}
      <section>
        {showNbCollab && (
          <div className=" sptb">
            <div className="container">
              <div className="section-title center-block text-center">
                <h2 className="wow fadeInDown" data-wow-delay="0.1s">
                  CONSULTEZ VOTRE PLAN
                </h2>
                <p className="wow fadeInDown" data-wow-delay="0.15s">
                  Vous pouvez choisir le nombre des collaborateurs avant le paiement
                </p>
              </div>
              <div className="row pricing-tabs">
                <div className="col-md-12">
                  {/* <div className="pri-tabs-heading pri-tabs-heading3 text-center">
                        <ul className="nav nav-price">
                          <li>
                            <a className="active" data-toggle="tab" href="#month">
                              Monthly
                            </a>
                          </li>
                          <li>
                            <a className data-toggle="tab" href="#year">
                              Yearly
                            </a>
                          </li>
                        </ul>
                      </div> */}
                  <div className="tab-content">
                    <div className="tab-pane pb-0 active show" id="month">
                      <div className="row">
                        <div className="col-md-12 col-lg-4">
                          <div className="pricing-table card">
                            <div className="price-header">
                              <h3 className="title">1 mois </h3>
                              <div className="price">
                                <span className="dollar">$</span>1
                              </div>
                              <span className="permonth">License mensuelle</span>
                            </div>
                            <div className="price-body">
                              <ul>
                                <li>
                                  <b>1</b> collaborateur
                                </li>
                                {/* <li>
                                      <b>Forum</b> Support
                                    </li>
                                    <li>
                                      <b>1 Year</b> Support &amp; Updates
                                    </li> */}
                                <li>
                                  <b>24/7</b> Support
                                </li>
                              </ul>
                            </div>
                            <div className="price-footer">
                              <Link
                                to="#"
                                className="order-btn btn btn-primary btn-pill btn-lg btn-block"
                                onClick={() => handleSelectPriceMonth(1, 1)}
                              >
                                Commencer
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="pricing-table card">
                            <div className="price-header">
                              <h3 className="title">3 mois</h3>
                              <div className="price">
                                <span className="dollar">$</span>2.80
                              </div>
                              <span className="permonth">Licence trimestrielle</span>
                            </div>
                            <div className="price-body">
                              <ul>
                                <li>
                                  <b>1</b> collaborateur
                                </li>
                                {/* <li>
                                      <b>Forum</b> Support
                                    </li>
                                    <li>
                                      <b>3 Year</b> Support &amp; Updates
                                    </li> */}
                                <li>
                                  <b>24/7</b> Support
                                </li>
                              </ul>
                            </div>
                            <div className="price-footer">
                              <Link
                                to="#"
                                className="order-btn btn btn-primary btn-pill btn-lg btn-block"
                                onClick={() => handleSelectPriceMonth(2.8, 3)}
                              >
                                Commencer
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="pricing-table card">
                            <div className="price-header">
                              <h3 className="title">1 Année</h3>
                              <div className="price">
                                <span className="dollar">$</span>10.90
                              </div>
                              <span className="permonth">License annuelle</span>
                            </div>
                            <div className="price-body">
                              <ul>
                                <li>
                                  <b>1</b> collaborateur
                                </li>
                                {/* <li>
                                      <b>Forum</b> Support
                                    </li>
                                    <li>
                                      <b>Lifetime</b> Support &amp; Updates
                                    </li> */}
                                <li>
                                  <b>24/7</b> Support
                                </li>
                              </ul>
                            </div>
                            <div className="price-footer">
                              <Link
                                to="#"
                                className="order-btn btn btn-primary btn-pill btn-lg btn-block"
                                onClick={() => handleSelectPriceMonth(10.9, 12)}
                              >
                                Commencer
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      {isClicked && amount > 0 && (
        <>
          <section ref={myRef}>
            <div className=" sptb">
              <div className="container">
                <div className="section-title center-block text-center">
                  <h2 className="wow fadeInDown text-danger" data-wow-delay="0.1s">
                    Votre compte sera expiré le {finContrat}
                  </h2>

                  <p className="wow fadeInDown" data-wow-delay="0.15s">
                    Après le paiement, nous vous enverrons un formulaire d'inscription pour terminer l'inscription.
                  </p>
                </div>
                <div className="row pricing-tabs">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      {showNbCollab && (
                        <div className="card-header border-bottom-0">
                          <h3 className="card-title">
                            Paiement de <strong style={{ fontSize: 30 }}>{convertToDigit(amount)}</strong> $
                          </h3>
                        </div>
                      )}

                      <div className="card-body">
                        <div className="card-pay">
                          {showNbCollab && (
                            <ul className="tabs-menu nav">
                              <li className>
                                <a href="#tab20" className="active" data-toggle="tab">
                                  <i className="fa fa-credit-card" /> Carte de crédit
                                </a>
                              </li>
                              {/* <li>
                            <a href="#tab21" data-toggle="tab" className>
                              <i className="fa fa-paypal" /> Paypal
                            </a>
                          </li>

                          <li>
                            <a href="#tab22" data-toggle="tab" className>
                              <i className="fa fa-university" /> Bank Transfer
                            </a>
                          </li>
                          <li>
                            <a href="#tab23" data-toggle="tab" className>
                              <i className="fa fa-google" /> Google pay
                            </a>
                          </li> */}
                            </ul>
                          )}

                          <div className="tab-content">
                            <div className="tab-pane active show" id="tab20">
                              {showNbCollab && (
                                <>
                                  <div className="form-group col-md-6">
                                    <label htmlFor="inputEmail4">Nombre des collaborateurs :</label>
                                    <input
                                      type="number"
                                      value={nbUsers}
                                      className="form-control"
                                      onChange={(e) => handleAddUsers(e.target.value)}
                                    />
                                  </div>
                                  <div className="bg-danger-transparent-2 text-danger px-4 py-2 br-3 mb-4" role="alert">
                                    Veuillez choisir le nombre des collaborateurs.
                                  </div>
                                </>
                              )}

                              <div className="row">
                                <div className="col-12">
                                  <StripeContainerCard
                                    amount={amount}
                                    months={months}
                                    nbUsers={nbUsers}
                                    handleHideNbCollab={handleHideNbCollab}
                                  ></StripeContainerCard>
                                </div>
                              </div>
                              {/* <RegisterForm></RegisterForm> */}
                            </div>
                            <div className="tab-pane" id="tab21">
                              <p>Paypal is easiest way to pay online</p>
                              <p>
                                <a href="#" className="btn btn-primary">
                                  <i className="fa fa-paypal" /> Log in my Paypal
                                </a>
                              </p>
                              <p className="mb-0">
                                <strong>Note:</strong> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                                aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                                nesciunt.
                              </p>
                            </div>
                            <div className="tab-pane" id="tab22">
                              <p>Bank account details</p>
                              <dl className="card-text">
                                <dt>BANK: </dt>
                                <dd> THE UNION BANK 0456</dd>
                              </dl>
                              <dl className="card-text">
                                <dt>Accaunt number: </dt>
                                <dd> 67542897653214</dd>
                              </dl>
                              <dl className="card-text">
                                <dt>IBAN: </dt>
                                <dd>543218769</dd>
                              </dl>
                              <p className="mb-0">
                                <strong>Note:</strong> Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                                aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi
                                nesciunt.
                              </p>
                            </div>
                            {/* <div className="tab-pane" id="tab23">
                              <p>google pay</p>
                              <StripeContainerGoogle amount={amount}></StripeContainerGoogle>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {/*Section*/}

      {/* Section */}
      {/* <section className="sptb bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-md-12">
              <div className="sub-newsletter">
                <h3 className="mb-2 fs-20">
                  <i className="fa fa-paper-plane-o mr-2" /> Subscribe To Our Onlinesletter
                </h3>
                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              </div>
            </div>
            <div className="col-lg-5 col-xl-6 col-md-12">
              <div className="input-group sub-input mt-1">
                <input className="form-control input-lg" placeholder="Enter your Email" type="text" />
                <div className="input-group-append">
                  <button className="btn ripple btn-primary btn-lg br-tr-3 br-br-3">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Section */}
      {/*Section*/}
      <section className="spfooter">
        <footer className="text-white footer-support">
          <div className="footer-main">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  <h6>Nos services</h6>
                  {/* <ul className="list-unstyled mb-0">
                    <li>
                      <a href="#">Our Team</a>
                    </li>
                    <li>
                      <a href="#">Contact US</a>
                    </li>
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                  </ul> */}
                </div>
                <div className="col-lg-2 col-md-12">
                  <h6 className="mt-5 mt-md-0">Support</h6>
                  {/* <ul className="list-unstyled mb-0">
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                    <li>
                      <a href="#">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Help</a>
                    </li>
                  </ul> */}
                </div>
                <div className="col-lg-4 col-md-12">
                  <h6 className="mt-5 mt-md-0">Entrer en contact</h6>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="#">
                        <i className="fa fa-home mr-3" /> Résidence Nahli 5 App A304 Riadh Andalous 2058 2058 Ariana,
                        Tunisie
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-envelope mr-3 fs-12" /> contact@nomdis.online
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-phone mr-3" /> +216 28 300 123
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="fa fa-print mr-3" /> + 01 234 567 89, + 01 234 567 89
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="col-lg-4 col-md-12">
                  {/* <h6 className="mt-5 mt-md-0">Subscribe</h6>
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Email" />
                    <div className="input-group-append ">
                      <button className="btn btn-primary">Subscribe</button>
                    </div>
                  </div> */}
                  <h6 className="mb-2 mt-5">Social Icons</h6>
                  <ul className="social-icons mb-0 mt-3">
                    <li>
                      <a className="social-icon" href="https://www.facebook.com/Societe.CLEDISS">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    {/* <li>
                      <a className="social-icon" href="#">
                        <i className="fa fa-whatsup" />
                      </a>
                    </li> */}
                    {/* <li>
                      <a className="social-icon" href="#">
                        <i className="fa fa-rss" />
                      </a>
                    </li> */}
                    {/* <li>
                      <a className="social-icon" href="#">
                        <i className="fa fa-youtube" />
                      </a>
                    </li> */}
                    <li>
                      <a className="social-icon" href="https://www.linkedin.com/company/clediss">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    {/* <li>
                      <a className="social-icon" href="#">
                        <i className="fa fa-google" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-footer">
            <div className="container">
              <div className="row d-flex">
                <div className="col-lg-12 col-sm-12 pt-5 pb-5 text-center">
                  <p className="mb-0">
                    Copyright © {new Date().getFullYear()}
                    <a href="http://nomadis.online/" className="fs-14 text-primary">
                      Clediss
                    </a>
                    All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default LandingPrice;
