import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function addTicketMedia(ticketId, files) {
  console.log(files);
  const frmData = new FormData();
  for (let i = 0; i < files.length; i++) {
    console.log(files[i].file);
    //newArr.push(files[i]);
    frmData.append("ticketId", ticketId);
    frmData.append("file", files[i].file);
    frmData.append("userId", getSession("id"));
  }

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getSession("token")}`,
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url_v2 + "ticketmedia", frmData, options);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getTicketMediaByTicketId(id) {
  try {
    const response = await axios.get(api_url_v2 + "ticketmedia/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteTicketMediaById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "ticketmedia/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
