import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGammeById, editGamme } from "../../../Services/Facturation/FacturationGammeApi";
import { t } from "i18next";

function EditerGamme({ tableId, getData }) {
  const hideRef = useRef();
  const [color, setColor] = useState("");
  const [libelle, setLibelle] = useState("");

  useEffect(() => {
    getGammeById(tableId).then((res) => {
      setLibelle(res.data.libelle);
      setColor(res.data.color);
    });
  }, [tableId]);

  /**
   * It sends a request to the API to edit a gamme.
   * @param e - The event object that contains the data we want to use.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("fff");
    if (libelle == "") {
      toast.error(t("nom vide"));
    } else if (color == "") {
      toast.error(t("coleur vide"));
    } else {
      editGamme(tableId, libelle, color).then((res) => {
        // history.push("/facturation/gamme")
        hideRef.current.click();
        getData();
      });
    }
  };

  /**
   * It sets the color variable to the value of the color picker.
   * @param event - The event that triggered the change.
   */
  const handleChangeComplete = (event) => {
    setColor(event.target.value);
  };

  return (
    <div
      key={tableId}
      className="modal fade"
      id="editGammeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {t("éditer gamme")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={hideRef}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <ToastContainer></ToastContainer>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  {t("Nom gamme")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Taper ...")}
                  onChange={(e) => setLibelle(e.target.value)}
                  value={libelle || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  {t("Coleur")}<span className="text-danger">*</span>
                </label>
                <input
                  type="color"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder={t("Password")}
                  onChange={handleChangeComplete}
                  value={color || ""}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                {t("Enregistrer")}
              </button>
            </form>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EditerGamme;
