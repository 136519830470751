import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTasksById } from "../../Services/Pointeuse/TasksApi";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import CommentTask from "./CommentTask";
import DetailTask from "./DetailTask";
import { t } from "i18next";

function CreeTask(props) {
  const role =localStorage.getItem('role_code');
  const [assigned_to,setassigned_to] =useState({
    id:localStorage.getItem('id'),
    nom:localStorage.getItem('nom'),
    prenom:localStorage.getItem('prenom'),
    email:localStorage.getItem('email'),
    role:localStorage.getItem('role_code'),
  })
  // ve


  return (
    <>
      
        <>
          <div className="page-header d-xl-flex d-block">
            <div className="page-leftheader">
              <h4 className="page-title">
                <span className="font-weight-normal text-muted mr-2">{t("Tâche")} </span>
              </h4>
            </div>
            <div className="page-rightheader ml-md-auto">
              <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
                <div className="btn-list">
                  {/* <Link to="#" className="btn btn-primary " data-toggle="modal" data-target="#newtaskmodal">
                    <i className="feather feather-plus fs-15 my-auto mr-2" />
                    Create New Task
                  </Link> */}
                  <Link  to={localStorage.getItem('role_code') === 'admin' ? '/admin/task' : '/user/task' } className="btn btn-warning ">
                    {t("List tâches")}
                  </Link>

                  <ButtonHeaderComponent></ButtonHeaderComponent>
                </div>
              </div>
            </div>
          </div>
          {/*End Page header*/}
          {/* Row */}
          <div className="row">
            <div className="col-xl-3 col-md-12 col-lg-12">
              <div className="card">
                <div className="card-header  border-0">
                  <div className="card-title">{t("Assigné à")}</div>
                  <div className="ml-auto">
                   {/* <Link to="#" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#recurringmodal">
                      {data?.etat}
                    </Link>*/}
                  </div>
                </div>
                <div className="card-body">
                  <div className="text-center">
                    <div className="widget-user-image mx-auto text-center">
                      <ImageComponent atr={"avatar avatar-xxl brround rounded-circle"} ></ImageComponent>
                    </div>
                    <div className="pro-user mt-3">
                      <h5 className="pro-user-username text-dark mb-1 fs-16">
                        {assigned_to.nom} {assigned_to.prenom}
                      </h5>
                      <h6 className="pro-user-desc text-muted fs-12">{assigned_to.email}</h6>
                    </div>
                  </div>
                  <div className="table-responsive mt-5">
                
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-5">
                    <h6>{t("Progression")}</h6>
                    <h6 className="font-weight-bold mb-1">100%</h6>
                  </div>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-success w-100" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-md-12 col-lg-12">
              <div className="tab-menu-heading hremp-tabs p-0 ">
                <div className="tabs-menu1">
                  {/* Tabs */}
                  <ul className="nav panel-tabs">
                    <li className="ml-4">
                      <a href="#tab5" className="active" data-toggle="tab">
                        {t("Details")}
                      </a>
                    </li>
                    {/* <li>
                  <a href="#tab6" data-toggle="tab">
                    Sub Task
                  </a>
                </li> */}
                    
                    {/* <li>
                  <a href="#tab8" data-toggle="tab">
                    Note
                  </a>
                </li> */}
                    {/* <li>
                      <a href="#tab9" data-toggle="tab">
                        Attachment
                      </a>
                    </li> */}
                    {/* <li>
                  <a href="#tab10" data-toggle="tab">
                    FAQS
                  </a>
                </li> */}
                  </ul>
                </div>
              </div>
              <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab5">
                    <DetailTask 
                    assigned_to={assigned_to} 
                    setassigned_to={setassigned_to}
                    role={role} />
                  </div>

                  {/*<div className="tab-pane" id="tab7">
                    <CommentTask taskId={props.match.params.id} />
                  </div>*}

                  {/* <div className="tab-pane" id="tab10">
                <div className="card-body">
                  <div aria-multiselectable="true" className="accordion" id="accordion" role="tablist">
                    <div className="row">
                      <div className="col-md-12 col-xl-11">
                        <div className="acc-card">
                          <div className="acc-header" id="headingOne" role="tab">
                            <h5 className="mb-0">
                              <a
                                aria-controls="collapseOne"
                                aria-expanded="true"
                                data-toggle="collapse"
                                href="#collapseOne"
                              >
                                Have you insert form validations Page?{" "}
                                <span className="float-right acc-angle">
                                  <i className="fe fe-chevron-right" />
                                </span>
                              </a>
                            </h5>
                          </div>
                          <div
                            aria-labelledby="headingOne"
                            className="collapse"
                            data-parent="#accordion"
                            id="collapseOne"
                            role="tabpanel"
                          >
                            <div className="acc-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                              squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                              nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                              single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                              beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                              vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-1">
                        <div className="d-flex mt-2 text-right">
                          <Link to ="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-edit-2 text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1 mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xl-11">
                        <div className="acc-card mt-2">
                          <div className="acc-header" id="headingTwo" role="tab">
                            <h5 className="mb-0">
                              <a
                                aria-controls="collapseOne"
                                aria-expanded="true"
                                data-toggle="collapse"
                                href="#collapseTwo"
                              >
                                Have you made Responsiveness?{" "}
                                <span className="float-right acc-angle">
                                  <i className="fe fe-chevron-right" />
                                </span>
                              </a>
                            </h5>
                          </div>
                          <div
                            aria-labelledby="headingTwo"
                            className="collapse"
                            data-parent="#accordion"
                            id="collapseTwo"
                            role="tabpanel"
                          >
                            <div className="acc-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                              squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                              nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                              single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                              beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                              vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-1">
                        <div className="d-flex mt-2 text-right">
                          <Link to ="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-edit-2 text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1 mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xl-11">
                        <div className="acc-card mt-2">
                          <div className="acc-header" id="headingThree" role="tab">
                            <h5 className="mb-0">
                              <a
                                aria-controls="collapseOne"
                                aria-expanded="true"
                                data-toggle="collapse"
                                href="#collapseThree"
                              >
                                Have you using Bootstrap?
                                <span className="float-right acc-angle">
                                  <i className="fe fe-chevron-right" />
                                </span>
                              </a>
                            </h5>
                          </div>
                          <div
                            aria-labelledby="headingThree"
                            className="collapse"
                            data-parent="#accordion"
                            id="collapseThree"
                            role="tabpanel"
                          >
                            <div className="acc-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                              squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                              nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                              single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                              beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                              vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-1">
                        <div className="d-flex mt-2 text-right">
                          <Link to ="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-edit-2 text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1 mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xl-11">
                        <div className="acc-card mt-2">
                          <div className="acc-header" id="headingfour" role="tab">
                            <h5 className="mb-0">
                              <a
                                aria-controls="collapseOne"
                                aria-expanded="true"
                                data-toggle="collapse"
                                href="#collapsefour"
                              >
                                Have you insert live chat?{" "}
                                <span className="float-right acc-angle">
                                  <i className="fe fe-chevron-right" />
                                </span>
                              </a>
                            </h5>
                          </div>
                          <div
                            aria-labelledby="headingfour"
                            className="collapse"
                            data-parent="#accordion"
                            id="collapsefour"
                            role="tabpanel"
                          >
                            <div className="acc-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                              squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                              nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                              single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                              beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                              vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-1">
                        <div className="d-flex mt-2 text-right">
                          <Link to ="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                            <i className="feather feather-edit-2 text-primary" />
                          </Link>
                          <a
                            href="#"
                            className="action-btns1 mr-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                          >
                            <i className="feather feather-trash-2 text-danger" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <Link to ="#" className="btn btn-primary" data-toggle="modal" data-target="#addfaqmodal">
                      <i className="feather feather-plus fs-15 my-auto mr-2" />
                      Add Faq
                    </Link>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
    
    </>
  );
}

export default CreeTask;
