export const yearlist =()=>{
    const years = [];
    for (let i = 1970; i < 3000; i++) {
      years.push(i);
    }
    const options = years.map(item => ({
        value: item,
        label: item
      }));
    return options
}