import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ImageComponent from '../Styles/ImageComponent';
import { getPersonnelById } from '../../Services/Pointeuse/PersonnelApi';

export default function ValidationPaie({ show, setshow, userlist, total  }) {
  const [salaire, setsalaire] = useState([]);

  useEffect(() => {
    const listsalaire = []
    userlist.forEach(user => {
        getPersonnelById(user.id)
            .then((res) => {
             
                const salaire = parseFloat(res.data.salaire_base) || 0
                //const salairej = (salaire* 12)/365
                const salairej = salaire/21
                const datasalaire = { user_code_id: user.id, salaire :salaire ,salairejourne:salairej }

                listsalaire.push(datasalaire)
            })
            .catch((error) =>console.log(error))
            .finally(() => {
                setsalaire(listsalaire)

            }
            
            );

    });

}, [userlist]);
  return (
    <div
    >
      <Modal        size="lg"
 show={show} onHide={() => setshow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Paie</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="table-responsive hr-attlist">
            <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
              <thead>
                <tr>
                  <th></th>
                  <th>total jour travaillé</th>
                  <th>salaire par mois</th>
                  <th>le salaire journalier</th>
                  <th> salaire de ce mois</th>

                </tr>


              </thead>
              {userlist && (
                <tbody>
                  {userlist.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex">
                          <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={user.img}></ImageComponent>
                          <div className="mr-3 mt-0 mt-sm-2 d-block">
                            <h6 className="mb-1 fs-14">
                              {user.nom} {user.prenom}
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>
                       {total?.find(item=>item.user === user.id)?.total}
                      </td>
                      <td>{salaire?.find(item => item.user_code_id === user.id)?.salaire || 0}</td>
                      <td>{salaire?.find(item => item.user_code_id === user.id)?.salairejourne.toFixed(2) || 0}</td>
                      <td>{((salaire?.find(item => item.user_code_id === user.id)?.salairejourne.toFixed(2) || 0)*(total?.find(item=>item.user === user.id)?.total || 0)).toFixed(2)}</td>

                    </tr>
                  ))}
                </tbody>
              )}


            </table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </div>

  )
}
