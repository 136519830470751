import React, { useEffect, useState } from 'react'
import { handleKeyPress_number } from '../../../Utils/InputUtils'
import moment from "moment";
import { toast } from "react-toastify";
import { float_decimale, formatNChiffres } from '../../../Utils/helper';

export default function Avantage_fiscaux({ setlist_av_fiscaux, list_av_fiscaux, add_av_fiscaux, setadd_av_fiscaux, alllist_av_fiscaux, userId, param_defaut, exercices_defaut, t }) {



  const handleAddClick = () => {
    setlist_av_fiscaux(prev => ([
      ...prev,
      {
        user_code: userId,
        code_generated: localStorage.getItem('code_generated'),
        rub_code: '',
        permanent: 0,
        date_debut: moment(new Date()).format("YYYY-MM-DD"),
        date_fin: moment(new Date(`12-31-${exercices_defaut?.code_exercice}`)).format("YYYY-MM-DD"),
        exercice: param_defaut.exercice,
        valeur_annuel: 0,
        valeur_mensuel: 0

      }
    ]

    ))
  }
  const handleRemoveClick = (index) => {
    const list = [...list_av_fiscaux];
    list.splice(index, 1);
    setlist_av_fiscaux(list);
  }

  const handlechangligne = (e, index) => {
    const { name, value } = e.target;

    const list = [...list_av_fiscaux];

    if (name === 'permanent') {
      list[index].permanent = list[index].permanent === 1 ? 0 : 1
    }
    else if (name === 'rub_code') {
      const find_same = list_av_fiscaux.find(item => item.rub_code === value)
      if (find_same) {
        toast.warning(t('cette avantage deja selectionné '))
      } else {
        const findrub = alllist_av_fiscaux.find(item => item.code === value);
        if (findrub) {

          list[index].valeur_mensuel = findrub.plafond === 1 ? findrub.montant_plafond : 0
          list[index].valeur_annuel = findrub.plafond === 1 ? findrub.montant_plafond : 0
          list[index].permanent = findrub.type === 'avantage_fiscaux' ? 0 : list[index].permanent
          list[index].type = findrub.type
          list[index].presence = findrub.presence

        }
        list[index][name] = value

      }
    }

    else {
      if(name === 'valeur_mensuel' || name === 'valeur_annuel' ){
        list[index][name] = float_decimale(value)||0

      }
     
      list[index][name] =  value

    }
    setlist_av_fiscaux(list)



  }

  const checkbox = () => {
    if (add_av_fiscaux) {
      setlist_av_fiscaux([])
    } else {
      handleAddClick()
    }
    setadd_av_fiscaux(!add_av_fiscaux)
  }
  const preventTyping = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <div className="row gutters mt-3">
        <input type='checkbox' className='col-' checked={add_av_fiscaux} onClick={checkbox} />
        <h3 className='col-4'>{t("Avantage fiscaux")}</h3>

      </div>


      {add_av_fiscaux &&
        <div>
          <div className="row mt-5">
            <strong className="col-4">
              {t('Rubrique')}
            </strong>
            <strong className='col-1 ml-5'>
              {t('Valeur mensuel')}
            </strong>
            <strong className='col-1 ml-5'>
              {t('Valeur annuel')}
            </strong>
            <strong className='col-1 ml-5'>
              {t('Permanent')}
            </strong>




          </div>
          {list_av_fiscaux.map((item, i) => (
            <div className="row mt-5">
              <select className="form-control col-4" name='rub_code' value={item.rub_code} onChange={(e) => handlechangligne(e, i)}>
                <option value=''>{t('choisir ')}</option>
                {alllist_av_fiscaux.map(item => (
                  <option key={item.id} value={item.code}>
                    {item.libelle} - {item.type}
                  </option>
                ))}
              </select>
              <input type="text" onKeyPress={handleKeyPress_number} placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_mensuel' value={item.valeur_mensuel} onChange={(e) => handlechangligne(e, i)} />
              <input type="text" onKeyPress={handleKeyPress_number} placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_annuel' value={item.valeur_annuel} onChange={(e) => handlechangligne(e, i)} />


              <input type="checkbox" className='col-1 ml-2' name='permanent' checked={item.permanent} onClick={(e) => handlechangligne(e, i)} />

              {item.permanent === 0 &&
                <>
                  <input type="date" className='col- ml-2' name='date_debut' value={moment(new Date(item.date_debut)).format("YYYY-MM-DD")} onChange={(e) => { handlechangligne(e, i) }}
                    onKeyDown={preventTyping}
                    onInput={preventTyping}
                    onPaste={preventTyping}
                  />

                  <input type="date"
                   onKeyDown={preventTyping}
                    onInput={preventTyping}
                    onPaste={preventTyping} className='col- ml-2' name='date_fin' value={moment(new Date(item.date_fin)).format("YYYY-MM-DD")} onChange={(e) => handlechangligne(e, i)} />

                </>}
              <div className=" col-1">

                {list_av_fiscaux.length - 1 === i && (
                  <a to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                    <i className="fa fa-plus" />
                  </a>
                )}

                <a to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                  <i className="fa fa-minus" />
                </a>

              </div>
            </div>
          ))}
        </div>
      }

    </div>
  )
}
