import React, { useEffect, useState } from 'react'
import { facturerabonnement, getLigneAFacturer } from '../../../../../Services/abonnement/abonnement';
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import moment from "moment";
import _ from 'lodash';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { getFacturationClientByCode } from '../../../../../Services/Facturation/FacturationClientApi';
import Select from "react-select";
import { Spinner } from 'react-bootstrap';

function ListLigneFacturer() {
  const [list_ligne, setlist_ligne] = useState([])
  const [list_lignefiltrer, setlist_lignefiltrer] = useState([])
  const [loading, setloading] = useState(false)
  const [checked_echeances, setchecked_echeances] = useState([])
  const [date, setdate] = useState()
  const [datedebut, setdatedebut] = useState()
  const [dateFin, setdateFin] = useState()
  const [dataFournisseur, setDataFournisseur] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    var dateFin = new Date();
    var dateDebut = new Date(dateFin);
    dateDebut.setDate(dateFin.getDate() - 30);
    var dateDebutSQL = dateDebut.toISOString().slice(0, 10); // Utilisation de 10 pour obtenir seulement la date
    var dateFinSQL = dateFin.toISOString().slice(0, 10);
    setdatedebut(dateDebutSQL)
    setdateFin(dateFinSQL)
    setloading(true)
    getLigneAFacturer(dateDebutSQL, dateFinSQL).then((res) => {
      if (res.data) {

        setlist_ligne(res.data.data);
        setlist_lignefiltrer(res.data.data)
      }
      setloading(false)
    });

  }, []);

  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        code: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
      }));
      const listoption = [...options]
      listoption.unshift({
        code: -1,
        label: "aucun client",
        secondValue: -1,
      })
      setDataFournisseur(listoption);
    });
  }, [])

  const handleChecked = (item) => {
    // Check if the item is already checked
    const checked_index = checked_echeances.findIndex(echeance => parseInt(echeance.id) === parseInt(item.idad));

    if (checked_index !== -1) {
      // If already checked, remove it
      const newlistchecked = checked_echeances.filter(echeance => parseInt(echeance.id) !== parseInt(item.idad));
      // console.log(newlistchecked);
      setchecked_echeances(newlistchecked);
    } else {
      // If not checked, add it
      let cal_quantite
      if (Number(item.frequence_facturation) === 1) {
        cal_quantite = item.quantite;

      } else {
        cal_quantite = item.nbr_users * item.frequence_facturation;


      }
      setchecked_echeances(prev => [...prev, { echeance: item.echeance, nbr_mois: item.nbr_mois, id: item.idad, qte: cal_quantite, client_code: item.client_code }]);
    }
  }

  const handleChange = (IDitem, value) => {
    const index = checked_echeances.findIndex(item => parseInt(item.id) === parseInt(IDitem));

    if (index !== -1) {
      checked_echeances[index].qte = value;
      setchecked_echeances([...checked_echeances]);
    }
  }

  const handleChangedate = () => {
    setloading(true)
    setchecked_echeances([])
    getLigneAFacturer(datedebut, dateFin).then((res) => {
      if (res.data) {

        setlist_ligne(res.data.data);
        setlist_lignefiltrer(res.data.data)
      } setloading(false)
    });


  }
  const filtreclient = (event) => {
    const code_client = event.code

    if (code_client === -1) {
      setlist_lignefiltrer(list_ligne)

    }
    else {
      const new_list = list_ligne.filter(item => item.client_code === code_client)
      setlist_lignefiltrer(new_list)

    }
  }




  const verif_factureligne = () => {
    return new Promise((resolve, reject) => {
      const currentDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

      const dateInput = document.createElement('input');
      dateInput.setAttribute('type', 'date');
      dateInput.setAttribute('id', 'dateInput');
      dateInput.setAttribute('value', currentDate); // Set default value to today's date
      dateInput.classList.add('swal2-input');

      const label = document.createElement('label');
      label.setAttribute('for', 'dateInput');
      label.textContent = 'Select a date:';

      const container = document.createElement('div');
      container.appendChild(label);
      container.appendChild(dateInput);
      container.style.textAlign = 'center'; // Center-align the content

      Swal.fire({
        html: container,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Non"),
        cancelButtonText: t("GROUPER DANS UNE SEULE FACTURE"),
      }).then((result) => {
        if (result.isConfirmed) {
          const selectedDate = document.getElementById('dateInput').value;
          resolve({ confirmed: true, date: selectedDate });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          const selectedDate = document.getElementById('dateInput').value;

          resolve({ confirmed: false, date: selectedDate });
        } else {
          resolve({ confirmed: null, date: null });
        }
      });




    });
  }

  const facturer = async () => {
    let exitFunction = false;

    checked_echeances.forEach(element => {
      const found = list_ligne.find(item => item.idad === element.id);
      const qte_rst = found.quantite - found.quantite_facture;
      if (Number(found.frequence_facturation) === 1) {
        exitFunction = false;

      } else {
        if (element.qte > qte_rst) {
          toast.warning("Vérifiez les quantités");
          exitFunction = true;
        }
      }

    });

    if (exitFunction) {
      return;
    }

    let verif = await verif_factureligne();
    if (verif.confirmed !== null) {
      const grouper = _.groupBy(checked_echeances, 'client_code')
      console.log(grouper)
      facturerabonnement(grouper, verif.confirmed, verif.date).then((res) => {
        if (res.status === 200) {
          //    window.location.reload();
        }
      });
    }

  }



  return (

    <>


      <div className="col-xl-12 col-md-12 col-lg-12 mt-8">

        <div className="card">

          <div className="card-body">

            <div className="page-header d-xl-flex d-block">
              <div className="page-leftheader">
                <h4 className="page-title">{t("Liste des échéances de ce mois")}</h4>
              </div>

            </div>



            <div className="card-body">
              <div className="row no-print mb-3 ">

                <div className="col-2">
                  <label htmlFor="startDate"> {t('Date Debut')}:</label>
                  <input
                    id="startDate"
                    placeholder="DD-MM-YYYY"
                    type="date"
                    value={datedebut}
                    onChange={(e) => { setdatedebut(e.target.value) }}

                  />
                </div>
                <div className="col-2">
                  <label htmlFor="endDate">{t('Date Fin')} :</label>
                  <input
                    id="endDate"
                    placeholder="DD-MM-YYYY"
                    type="date"
                    value={dateFin}
                    onChange={(e) => { setdateFin(e.target.value) }}
                  />
                </div>
                <div className="col-2 mt-5">
                  {loading ? (<Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  ) : <button className='btn btn-primary' onClick={handleChangedate}>{t('Liste à facturer')}</button>}
                </div>

                <div className="col-4">
                  <label htmlFor="customDate">{t('Client')}:</label>
                  <Select options={dataFournisseur} onChange={filtreclient} />

                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                    id="hr-table"
                    role="grid"
                    aria-describedby="hr-table_info"
                  >
                    <thead>
                      <tr>
                        <th colSpan={2}></th>

                        <th>{t('code_Abonnement')}</th>
                        <th>{t("Client")} </th>
                        <th>{t('Produit')}</th>
                        <th>{t("Date Début")} </th>
                        <th>{t("Date Fin")} </th>
                        <th>{t('quantite')}</th>
                        <th>{t('quantite facturé')}</th>
                        <th>{t('quantite restante')}</th>

                        <th>{t("Date prochaine echeance")} </th>

                      </tr>
                    </thead>
                    <tbody>
                      {list_lignefiltrer && list_lignefiltrer.length !== 0 && list_lignefiltrer.map((el, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type='checkbox'
                              value={checked_echeances.find(item => item.id === el.idad)?.id}
                              onClick={() => handleChecked(el)}
                            />
                          </td>

                          {checked_echeances.findIndex(item => item.id === el.idad) !== -1 ?
                            <td>
                              <input
                                type='number'
                                value={checked_echeances.find(item => item.id === el.idad)?.qte}
                                onChange={(e) => handleChange(el.idad, parseInt(e.target.value))}
                              />
                            </td>
                            : <td
                            ></td>
                          }

                          {/*<td>{el.idad}</td>*/}

                          <td>{el.code_abonnement}</td>
                          <td>{el.nom}{' '}{el.prenom}</td>
                          <td>{el.libelleProduit}</td>

                          <td>{moment(el.dateDebut).format("YYYY-MM-DD")}</td>
                          <td>{moment(el.dateFin).format("YYYY-MM-DD")}</td>
                          <td>{el.quantite}</td>

                          <td>{el.quantite_facture}</td>
                          <td>{el.quantite - el.quantite_facture}</td>

                          <td>{moment(el.echeance).format("YYYY-MM-DD")}</td>

                        </tr>
                      ))}
                    </tbody>


                  </table>
                  <div className="row no-print mt-4">
                    <div className="col-12">

                      <button
                        type="button"
                        className="btn btn-info float-right"
                        style={{ marginRight: 5 }}
                        onClick={facturer}
                      >
                        <i className="fas fa-file-invoice" /> {t("Facturer")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
        </div>
      </div></>

  );
}

export default ListLigneFacturer;
