import { sumSalaire } from "./ObjectOperation";

/**
 * Given an array of objects, group the objects by the value of one of their properties (the `key`),
 * and return an array of objects where each object has two properties: `nom` (the value of the `key`)
 * and `projects` (an array of the objects that have the same `key` value)
 * @param data - The data to be grouped.
 * @returns An array of objects with the following structure:
 * ```
 * [
 *   {
 *     nom: "projet1",
 *     projects: [
 *       {
 *         "id": 1,
 *         "nom": "nom1",
 *         "projet": "projet1",
 *         "date": "2019-01-01T00:00:00.000
 */
var _ = require("lodash");
export function groupByProject(data) {
  return (
    _.chain(data)
      // Group the elements of Array based on `color` property
      .groupBy("projet")
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ nom: key, projects: value }))
      .value()
  );
}

/**
 * Group the elements of an array based on a property
 * @param data - the data to be grouped
 * @returns An array of objects with the following structure:
 * ```
 * {
 *   code: "code",
 *   virements: [{...}, {...}, ...],
 *   mois: "mois",
 *   annee: "annee"
 * }
 * ```
 */
export function groupByCodeSalaire(data) {
  return (
    _.chain(data)
      // Group the elements of Array based on `code` property
      .groupBy("code")
      // `key` is group's name (code), `value` is the array of objects
      .map((value, key) => ({
        code: key,
        virements: value,
        mois: value[0].mois,
        annee: value[0].annee,
        valide: value[0].valide,
        num_banque: value[0].num_banque,
      }))
      .value()
  );
}
