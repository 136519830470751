// import { CSVLink } from 'react-csv';

import { useRef } from "react";
import { CSVLink } from "react-csv";
import {Link} from "react-router-dom"
function ExportCsv({ data, name }) {
  const csvLinkEl = useRef(null);

  const columnsToExport = data.map(({ nom,prenom,date_debut, date_fin, depart, destination }) => ({
    nom,
    prenom,
    date_debut,
    date_fin,
    depart,
    destination,
  }));

  const headers = [
    { label: 'Nom', key: 'nom' },
    { label: 'Prenom', key: 'prenom' },
    { label: 'Date de Début', key: 'date_debut' },
    { label: 'Date de Fin', key: 'date_fin' },
    { label: 'Départ', key: 'depart' },
    { label: 'Destination', key: 'destination' },
  ];

  /**
   * Download a report as a CSV file
   */
  const downloadReport = async () => {
    setTimeout(() => {
      csvLinkEl?.current.link.click();
    });
  };

  return (
    <>
      <Link to="#" className="action-btns" onClick={downloadReport} data-toggle="tooltip" data-placement="top" title="csv">
        <i className="fa fa-file-excel-o text-success" />
      </Link>
      <CSVLink
        data={columnsToExport}
        headers={headers}
        filename={`${name}.csv`}
        ref={csvLinkEl}
      />
    </>
  );
}

export default ExportCsv;
