import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function getTaskMediaCommentByCommentId(commentId) {
  try {
    const response = await axios.get(api_url + "taskcommentmedia/" + commentId, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a document to the database
 * @param file - The file that you want to upload.
 * @param type - The type of document.
 * @param description - The description of the document
 * @returns The response from the server.
 */
export async function addTaskMediaComment(task_id, file, taskCommentId) {
  const frmData = new FormData();
  frmData.append("code_generated", getSession(t("code_generated")));
  frmData.append("task_id", task_id);
  frmData.append("task_comment_id", taskCommentId);
  frmData.append("file", file);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "taskcommentmedia", frmData, options);
    toast.success(t("Fichier ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteTaskCommentMediaById(id) {
  try {
    const response = await axios.delete(api_url + "taskcommentmedia/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
