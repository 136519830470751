import React, { useEffect, useState } from "react";
import {
  getClientFournisseurByid,
  updateClientById,
  updateImageClientById,
} from "../../../Services/Facturation/FacturationClientApi";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { t } from "i18next";
import { getAllDevise } from "../../../Services/devise/devise";
import { getRStaxes } from "../../../Services/Facturation/FacturationTvaApi";
import ReactSelect from "react-select";
import {useHistory} from "react-router-dom"
import { code_CAT, codetva, getLettreAssociee } from "../../../Utils/Data/Matriculefiscale";

function EditerFournisseur(props) {
  const history = useHistory();

  const [stat, setStat] = React.useState("Client");
  const [tarif, setTarif] = React.useState("tarif");
  const [selectedDate, setSelectedDate] = React.useState();
  const [code, setCode] = useState("");
  const [codeBare, setCodeBare] = useState("");
  const [codeActivity, setCodeActivity] = useState("");
  const [magasin, setMagasin] = useState("");
  const [tva, setTva] = useState("");
  const [potentiel, setPotentiel] = useState("");
  const [potentielSoc, setPotentielSoc] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [fixe, setFixe] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [rib, setRib] = useState("");
  const [soldeReleve, setSoldeReleve] = useState("");
  const [codeErp, setCodeErp] = useState("");
  const [tax, setTax] = useState("");
  const [adrFacturation, setAdrFacturation] = useState("");
  const [localite, setLocalite] = useState("");
  const [region, setRegion] = useState("");
  const [gouvernorat, setGouvernorat] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [categorie, setCategorie] = useState("");
  const [codeCommercialCategorie, setCodeCommercialCategorie] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [regimeFiscal, setRegimeFiscal] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [image, setImage] = useState("/dist/img/user/user.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [remise, setRemise] = useState("");
  const [delaiPaiement, setDelaiPaiement] = useState("");
  const [plafondCredit, setPlafondCredit] = useState("");
  const [rc, setRc] = useState("");
  const [deviseselected, setdevise] = useState("");
  const [exonere, setexonere] = useState(0);
  const [rs, setrs] = useState('');
  const [deviseState, setdeviseState] = useState([])
  const [rsState, setrsState] = useState([])
  const [matriculeFiscal, setMatriculeFiscal] = useState({num_MF:'', cle_MF:'' , codetva:'' , codecat:'' ,codeetab:'000'});

  const image_url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getAllDevise().then((res) => {
      if (res.status === 200) {
        setdeviseState(res.data.data);
      }
    });
    getRStaxes().then((reslt) => {
      if (reslt?.status === 200) {
        setrsState(reslt.data.data);
      }
    });
  }, []);
  useEffect(() => {
    getClientFournisseurByid(props.match.params.id).then((res) => {
      console.log(res)
      setStat(res.data.statut);
      setTarif(res.data.tarification);
      setSelectedDate(moment(res.data.anniversaire).format("YYYY-MM-DD"));
      setCode(res.data.code);
      setCodeActivity(res.data.activite_code);
      setMagasin(res.data.magasin);
      setNom(res.data.nom);
      setPrenom(res.data.prenom);
      setEmail(res.data.email);
      setFixe(res.data.fixe);
      setMobile(res.data.mobile);
      setDescription(res.data.description);
      setRib(res.data.rib);
      setCodeErp(res.data.code_erp);
      setAdrFacturation(res.data.adresse_facturation);
      setLocalite(res.data.localite);
      setRegion(res.data.region);
      setGouvernorat(res.data.gouvernorat);
      setSecteur(res.data.secteur);
      setZone(res.data.zone);
      setCategorie(res.data.categorie);
      setSpeciality(res.data.specialite);
      setRegimeFiscal(res.data.regime_fiscale);
      
      setMatriculeFiscal( {num_MF:res.data.matricule_fiscale.split('/')[0], cle_MF:res.data.matricule_fiscale.split('/')[1], codetva:res.data.matricule_fiscale.split('/')[2], codecat:res.data.matricule_fiscale.split('/')[3] ,codeetab:res.data.matricule_fiscale.split('/')[4]});
      setCommentaire(res.data.commentaire);
      setRemise(res.data.remise_client);
      setDelaiPaiement(res.data.delai_paiement);
      setPlafondCredit(res.data.plafond_credit);
      setRc(res.data.rc);
      if (res.data.image) {
        setImage(localStorage.getItem("baseUrl") + res.data.image);
      }
      setexonere(res.data.exonere)
      setdevise(res.data.devise_id)
      setrs(res.data.rs_id)
    });
  }, []);

  const handleChangeTarification = (event) => {
    setTarif(event.target.value);
  };

  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
    updateImageClientById(event.target.files[0], props.match.params.id);
  };
  const changeNumMf=(name ,value)=>{
    if(name==='num_MF'){
      if(value.length <= 7){
        setMatriculeFiscal((prev)=>({...prev , num_MF : value}))
      }
    }else {
     
      setMatriculeFiscal((prev)=>({...prev , [name] : value}))

    }
  

  }
  const handleMatriculeFiscalePart1Blur = () => {
    const value = matriculeFiscal.num_MF.padStart(7, "0");
    const lettreAssociee = getLettreAssociee(value);
    setMatriculeFiscal((prev) => ({
      ...prev,
      num_MF: value,
      cle_MF:lettreAssociee

    }));
    
  };


  const handleSubmit = () => {
    if (code === "") {
      toast.error("code vide");
    } else if (nom === "") {
      toast.error("nom vide");
    } else if( deviseselected === ''){
      toast.error(t("choisir devise"));
    }
    else {
      const matricule=matriculeFiscal.num_MF+'/'+matriculeFiscal.cle_MF+'/'+matriculeFiscal.codetva+'/'+matriculeFiscal.codecat+'/'+matriculeFiscal.codeetab

    updateClientById(
      code,
      deviseselected,
      exonere,
      rs,
      codeActivity,
      "Fournisseur",
      tarif,
      magasin,
      tva,
      potentiel,
      potentielSoc,
      nom,
      prenom,
      cin,
      email,
      selectedDate,
      fixe,
      mobile,
      description,
      rib,
      soldeReleve,
      codeErp,
      tax,
      adrFacturation,
      localite,
      region,
      gouvernorat,
      secteur,
      zone,
      categorie,
      codeCommercialCategorie,
      speciality,
      regimeFiscal,
      matricule,
      commentaire,
      remise,
      delaiPaiement,
      plafondCredit,
      rc,
      props.match.params.id
    ).then((res) => {
      if(res.status ===200){
       history.push('/admin/fournisseur')

      }
    });
  }
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Détail Fournisseur")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Code")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCode(e.target.value)}
                  required
                  value={code || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Code Activité")} 
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeActivity(e.target.value)}
                  
                  value={codeActivity || ""}
                />
              </div>
              {/*
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">{t("Tarification")}</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={handleChangeTarification}
                  value={tarif || ""}
                >
                  <option value="tarif">{t("tarif")}</option>
                  <option value="tarif1">{t("tarif")}1</option>
                  <option value="tarif2">{t("tarif")}2</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  {t("Magasin")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMagasin(e.target.value)}
                  required
                  value={magasin || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Code ERP")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeErp(e.target.value)}
                  required
                  value={codeErp || ""}
                />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Adresse de facturation")} <span className="text-danger">*</span>
                  <small> {t("maximum 40 caractères")}</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  value={adrFacturation || ""}
                  maxLength="40"
                />
              </div>
              <div className="form-group">
                <label>{t("Devise")}</label><span className="text-danger">*</span>
                <ReactSelect
                  value={deviseState && deviseState.find(item => item.id === deviseselected)}
                  options={deviseState}
                  onChange={(e) => setdevise(e.id)}
                  formatOptionLabel={devise => (
                    <div className="country-option">
                      <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                      <span>{devise.libelle}</span>
                    </div>
                  )}
                />
              </div>
              <div className="form-group">
                <label>{t("exonéré")}</label>
                <select className="form-control"
                  value={exonere}
                  onChange={(e) => setexonere(parseInt(e.target.value))}>
                  <option value={0}>Non</option>
                  <option value={1}>Oui</option>
                </select>
              </div>
              <div className="form-group">
                <label>{t("RS")}</label>
                <select className="form-control"
                  value={rs}

                  onChange={(e) => setrs(parseInt(e.target.value))}>

                  <option value=''>choisir rs</option>
                  {rsState.map(item => (
                    <option value={item.id}>{item.libelle} - {item.taux}</option>

                  ))}
                </select>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Information de contact")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
              <label>{t("Raison social Nom/Prénom ")}</label>
              <span className="text-danger">*</span>

                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setNom(e.target.value)}
                  required
                  value={nom || ""}
                />
              </div>
             {/* <div className="form-group">
                <label>
                  {t("Prénom")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                  value={prenom || ""}
                />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Email")}
                </label>
                <input
                  className="form-control"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  
                  value={email || ""}
                />
              </div>
            {/*  <div className="form-group">
                <label>
                  {t("Anniversaire")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                  value={selectedDate || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Fixe")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="number" onChange={(e) => setFixe(e.target.value)} value={fixe} />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Mobile")} 
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Description")} 
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Commentaire")} 
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCommentaire(e.target.value)}
                  
                  value={commentaire || ""}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
          <div>
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">{t("Image client")}</h3>
                <div className="card-tools">
                  {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="form-group">
                  <div className="d-flex justify-content-center">
                    <img alt="image produit" src={image} className="avatar" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        accept="image/*"
                        type="file"
                        id="exampleInputFile"
                        onChange={handleImgClient}
                      />
                      <label className="custom-file-label" htmlFor="exampleInputFile">
                        {imageName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Information complémentaire")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              {/*<div className="form-group">
                <label>
                  {t("Catégorie")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCategorie(e.target.value)}
                  required
                  value={categorie || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Spécialité")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setSpeciality(e.target.value)}
                  required
                  value={speciality || ""}
                />
              </div>*/}
              <div className="row">
              <div className="form-group col-6">
                <label>
                  {t("Régime fiscal")} 
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRegimeFiscal(e.target.value)}
                  value={regimeFiscal || ""}
                />
              </div>
              <div className="form-group col-6">
                <label>
                  {t("RIB")} 
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRib(e.target.value)}
                  
                  value={rib || ""}
                />
              </div>
              </div>
             
              <div className="form-group row">
                <div className="col-3">
                  <label>{t("Num MF")}</label>
                  <input
                    className="form-control"
                    type="text"
                    onBlur={handleMatriculeFiscalePart1Blur}
                    onChange={(e)=> changeNumMf('num_MF',e.target.value)}
                    value={matriculeFiscal.num_MF}
                    required
                  />
                </div>
                <div className="col-2">
                  <label>{t("Cle MF")}</label>
                  <input
                    className="form-control"
                    type="text"
                    value={matriculeFiscal.cle_MF}
                    readOnly
                    required
                  />
                </div>
                <div className="col-2">
                  <label>{t("Code  TVA")}</label>
                  <select className="form-control"   value={matriculeFiscal.codetva} onChange={(e)=> changeNumMf('codetva',e.target.value)}>
                    {codetva.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-2">
                  <label>{t("Cle MF")}</label>
                  <select className="form-control"   value={matriculeFiscal.codecat}  onChange={(e)=> changeNumMf('codecat',e.target.value)}>
                    {code_CAT
                    .map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="col-2">
                  <label>{t("N° Etab")}</label>
                  <input className="form-control" value={matriculeFiscal.codeetab} readOnly />


                </div>


              </div>
              <div className="row">
                  <div className="form-group col-4">
                    <label>
                      {t("Remise fournisseur")} 
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => setRemise(e.target.value)}
                      
                      value={remise || ""}
                    />
                  </div>
                  <div className="form-group col-4">
                <label>
                  {t("Delai de paiement")} 
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDelaiPaiement(e.target.value)}
                  
                  value={delaiPaiement || ""}
                />
              </div>
              <div className="form-group col-4">
                <label>
                  {t("Plafond crédit")} 
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setPlafondCredit(e.target.value)}
                  
                  value={plafondCredit || ""}
                />
              </div>
              </div>
            
             
             
              {/*<div className="form-group">
                <label>
                  {t("Registre de commerce")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRc(e.target.value)}
                  required
                  value={rc || ""}
                />
              </div>*/}
             
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
         {/* <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Adresse")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> 
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Localité")}<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={localite}
                  onChange={(e) => setLocalite(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Région")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>
                  {t("Gouvernorat")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={gouvernorat}
                  onChange={(e) => setGouvernorat(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Secteur")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={secteur}
                  onChange={(e) => setSecteur(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Zone")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="card-footer">
               Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. 
            </div>
          </div>*/}
          
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> {t("Annuler")}
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> {t("Enregistrer")}
          </button>
        </div>
      </div>
    </>
  );
}

export default EditerFournisseur;
