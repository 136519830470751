 import { calculateCroissance } from "../ObjectifUtils";
  
export function getObjectData(result) {
   //realisation n-1 2000  24->35
  //realisation n-2  2021  12->25
  // return [
  //   {
  //     mois: "janvier",
  //     realisation1: result[24]?.realise,
  //     realisation2: result[12]?.realise,
  //     objectif: result[36]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[36]?.objectif ?? "0", result[24]?.realise),
  //   },
  //   {
  //     mois: "février",
  //     realisation1: result[25]?.realise,
  //     realisation2: result[13]?.realise,
  //     objectif: result[37]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[37]?.objectif ?? "0", result[25]?.realise),
  //   },
  //   {
  //     mois: "mars",
  //     realisation1: result[26]?.realise,
  //     realisation2: result[14]?.realise,
  //     objectif: result[38]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[38]?.objectif ?? "0", result[26]?.realise),
  //   },
  //   {
  //     mois: "avril",
  //     realisation1: result[27]?.realise,
  //     realisation2: result[15]?.realise,
  //     objectif: result[39]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[39]?.objectif ?? "0", result[27]?.realise),
  //   },
  //   {
  //     mois: "mai",
  //     realisation1: result[28]?.realise,
  //     realisation2: result[16]?.realise,
  //     objectif: result[40]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[40]?.objectif ?? "0", result[28]?.realise),
  //   },
  //   {
  //     mois: "juin",
  //     realisation1: result[29]?.realise,
  //     realisation2: result[17]?.realise,
  //     objectif: result[41]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[41]?.objectif ?? "0", result[29]?.realise),
  //   },
  //   {
  //     mois: "juillet",
  //     realisation1: result[30]?.realise,
  //     realisation2: result[18]?.realise,
  //     objectif: result[42]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[42]?.objectif ?? "0", result[30]?.realise),
  //   },
  //   {
  //     mois: "aout",
  //     realisation1: result[31]?.realise,
  //     realisation2: result[19]?.realise,
  //     objectif: result[43]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[43]?.objectif ?? "0", result[31]?.realise),
  //   },
  //   {
  //     mois: "septembre",
  //     realisation1: result[32]?.realise,
  //     realisation2: result[20]?.realise,
  //     objectif: result[44]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[44]?.objectif ?? "0", result[32]?.realise),
  //   },
  //   {
  //     mois: "octobre",
  //     realisation1: result[33]?.realise,
  //     realisation2: result[21]?.realise,
  //     objectif: result[45]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[45]?.objectif ?? "0", result[33]?.realise),
  //   },
  //   {
  //     mois: "novembre",
  //     realisation1: result[34]?.realise,
  //     realisation2: result[22]?.realise,
  //     objectif: result[46]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[46]?.objectif ?? "0", result[34]?.realise),
  //   },
  //   {
  //     mois: "décembre",
  //     realisation1: result[35]?.realise,
  //     realisation2: result[23]?.realise,
  //     objectif: result[47]?.objectif ?? "0",
  //     croissance: calculateCroissance(result[47]?.objectif ?? "0", result[35]?.realise),
  //   },
  // ];
  

    
  return [
    {
      mois: "janvier",
      realisation1: result[48]?.realise,
      realisation2: result[36]?.realise,
      realisation3: result[60]?.realise,
      //objectif: result[48]?.objectif ?? "0",
      objectif: result[60]?.objectif ?? "0",
      croissance: calculateCroissance(result[60]?.objectif ?? "0", result[36]?.realise),
      },
    {
      mois: "février",
      realisation1: result[49]?.realise,
      realisation2: result[37]?.realise,
      realisation3: result[61]?.realise,
      //objectif: result[49]?.objectif ?? "0",
      objectif: result[61]?.objectif ?? "0",
      croissance: calculateCroissance(result[61]?.objectif ?? "0", result[37]?.realise),
     },
    {
      mois: "mars",
      realisation1: result[50]?.realise,
      realisation2: result[38]?.realise,
      realisation3: result[62]?.realise,
      //objectif: result[50]?.objectif ?? "0",
      objectif: result[62]?.objectif ?? "0",
      croissance: calculateCroissance(result[62]?.objectif ?? "0", result[38]?.realise),
    },
    {
      mois: "avril",
      realisation1: result[51]?.realise,
      realisation2: result[39]?.realise,
      realisation3: result[63]?.realise,
      //objectif: result[51]?.objectif ?? "0",
      objectif: result[63]?.objectif ?? "0",
      croissance: calculateCroissance(result[63]?.objectif ?? "0", result[39]?.realise),
    },
    {
      mois: "mai",
      realisation1: result[52]?.realise,
      realisation2: result[40]?.realise,
      realisation3: result[64]?.realise,
      //objectif: result[52]?.objectif ?? "0",
      objectif: result[64]?.objectif ?? "0",
      croissance: calculateCroissance(result[64]?.objectif ?? "0", result[40]?.realise),
    },
    {
      mois: "juin",
      realisation1: result[53]?.realise,
      realisation2: result[41]?.realise,
      realisation3: result[65]?.realise,
      //objectif: result[53]?.objectif ?? "0",
      objectif: result[65]?.objectif ?? "0",
      croissance: calculateCroissance(result[65]?.objectif ?? "0", result[41]?.realise),
    },
    {
      mois: "juillet",
      realisation1: result[54]?.realise,
      realisation2: result[42]?.realise,
      realisation3: result[66]?.realise,
      //objectif: result[54]?.objectif ?? "0",
      objectif: result[66]?.objectif ?? "0",
      croissance: calculateCroissance(result[66]?.objectif ?? "0", result[42]?.realise),
    },
    {
      mois: "aout",
      realisation1: result[55]?.realise,
      realisation2: result[43]?.realise,
      realisation3: result[67]?.realise,
      //objectif: result[55]?.objectif ?? "0",
      objectif: result[67]?.objectif ?? "0",
      croissance: calculateCroissance(result[67]?.objectif ?? "0", result[43]?.realise),
    },
    {
      mois: "septembre",
      realisation1: result[56]?.realise,
      realisation2: result[44]?.realise,
      realisation3: result[68]?.realise,
      //objectif: result[56]?.objectif ?? "0",
      objectif: result[68]?.objectif ?? "0",
      croissance: calculateCroissance(result[71]?.objectif ?? "0", result[44]?.realise),
    },
    {
      mois: "octobre",
      realisation1: result[57]?.realise,
      realisation2: result[45]?.realise,
      realisation3: result[69]?.realise,
      //objectif: result[57]?.objectif ?? "0",
      objectif: result[69]?.objectif ?? "0",
      croissance: calculateCroissance(result[72]?.objectif ?? "0", result[45]?.realise),
    },
    {
      mois: "novembre",
      realisation1: result[58]?.realise,
      realisation2: result[46]?.realise,
      realisation3: result[70]?.realise,
      //objectif: result[58]?.objectif ?? "0",
      objectif: result[70]?.objectif ?? "0",
      croissance: calculateCroissance(result[73]?.objectif ?? "0", result[46]?.realise),
    },
    {
      mois: "décembre",
      realisation1: result[59]?.realise,
      realisation2: result[47]?.realise,
      realisation3: result[71]?.realise,
      //objectif: result[59]?.objectif ?? "0",
      objectif: result[71]?.objectif ?? "0",
      croissance: calculateCroissance(result[62]?.objectif ?? "0", result[47]?.realise),
    }
  ];
}
