import axios from "axios";
import { type } from "os";
import { getCurrentDateTime } from "../../Utils/DateUtils";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import moment from "moment";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the tasks that have the same code as the one generated by the previous function.
 * @returns The response is an array of objects.
 */
export async function getTasksByCode() {
  try {
    //console.log('mmm', getSession(t("code_generated")))   
     const response = await axios.get(api_url + "taches/codetacheimg/" + getSession(t("code_generated")), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets all the tasks that have the same code as the one generated by the previous function.
 * @returns The response is an array of objects.
 */
export async function getTacheByCodeclient(clientcode) {
  try {
    //console.log('mmm', getSession(t("code_generated")))   
     const response = await axios.get(api_url + "taches/getTacheByCodeclient/" + getSession(t("code_generated"))+'/'+clientcode, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * It gets all the tasks that have the same code as the one generated by the previous function.
 * @returns The response is an array of objects.
 */
export async function getTasksByUsers(id) {
  try {
    //console.log('mmm', getSession(t("code_generated")))   
     const response = await axios.get(api_url + "taches/user/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets all the tasks for a specific user.
 * @param id - The id of the user.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": [
 *     {
 *       "id": 1,
 *       "title": "My first task",
 *       "description": "This is my first task",
 *       "status": "todo",
 *       "created_at": "2020-04-01T00:00
 */
export async function getTasksById(id) {
  try {
    const response = await axios.get(api_url + "taches/iduser/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function updateTache(data) {
  try {
    const response = await axios.put(api_url + "taches/update/" , data ,config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Update the status of a task by id
 * @param etat - the new state of the task
 * @param id - The id of the task to update.
 * @returns The response is an object with the following properties:
 */
export async function updateEtatTaskById(etat, id) {
  const credentiel = {
    etat: etat,
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "taches/etat", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function addTaskToUser(object) {
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    object: object,
    etat: "afaire",
    priority: "0",
    type: "task",
    date_debut: moment(new Date()).format("YYYY-MM-DD"),
    date_fin: moment(new Date()).format("YYYY-MM-DD"),
    createdBy: getSession("id"),
  };

  try {
    const response = await axios.post(api_url + "taches", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteTasById(id) {
  try {
    const response = await axios.delete(api_url + "taches/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function upadateTaskById(
  userId,
  object,
  description,
  etat,
  commentaire,
  dateDebut,
  dateFin,
  color,
  projectId,
  type,
  priority,
  id
) {
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    user_code_id: userId,
    object: object,
    description: description || "",
    etat: etat,
    commentaire: commentaire || "",
    paragraph: "",
    date_debut: dateDebut,
    type: type,
    priority: priority,
    date_fin: dateFin,
    date: getCurrentDateTime(),
    color: color || "#3366FF",
    tag: "",
    createdBy: getSession("id"),
    projet_id: projectId,
    projet: "",
    id: id,
  };

  try {
    const response = await axios.patch(api_url + "taches", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function senFcmTask(text, receiver) {
  const notification = {
    data: {
      title: t("Nouvelle Tâche"),
      message: text,
    },
    to: "/topics/" + localStorage.getItem(t("code_generated")) + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js

      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
