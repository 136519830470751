/**
 * Given an array of objects, return only the objects that have a pointage property that matches
 * today's date
 * @param arrayObject - the array of objects that you want to filter
 * @returns An array of objects.
 */
export const filterTodayPointage = (arrayObject) => {
  const yourDate = new Date();
  var todayFormated = yourDate.toISOString().split("T")[0];
  return arrayObject.filter((el) => el.pointage.split(" ")[0] == todayFormated);
};

/**
 * Filter the array of objects by the year of the date property
 * @param arrayObject - The array of objects that you want to filter.
 * @returns An array of objects.
 */
export const filterYearAutorisation = (arrayObject) => {
  const yourDate = new Date();
  var yearFormated = yourDate.toISOString().split("T")[0].split("-")[0];
  return arrayObject.filter((el) => el.date.split("-")[0] == yearFormated);
};

/**
 * This function filters the array of objects by the year of the createdAt property
 * @param arrayObject - the array of objects that you want to filter
 * @returns an array of objects filtered by the year of the createdAt property.
 */
export const filterYearDeplacement = (arrayObject) => {
  const yourDate = new Date();
  var yearFormated = yourDate.toISOString().split("T")[0].split("-")[0];
  return arrayObject.filter((el) => el.createdAt.split("T")[0].split("-")[0] == yearFormated);
};

/**
 * This function filters the array of objects by the year of the createdAt property
 * @param arrayObject - The array of objects that you want to filter.
 * @returns An array of objects.
 */
export const filterYearQuestionnaire = (arrayObject) => {
  const yourDate = new Date();
  var yearFormated = yourDate.toISOString().split("T")[0].split("-")[0];
  return arrayObject.filter((el) => el.createdAt.split("-")[0] == yearFormated);
};

/**
 * Filter an array of objects by a given year
 * @param arrayObject - the array of objects that you want to filter
 * @param year - The year you want to filter by.
 * @returns An array of objects.
 */
export const filterCongeByYear = (arrayObject, year) => {
  return arrayObject.filter((el) => el.date.split("-")[0] == year);
};
