import React, { useEffect, useState } from "react";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { getAllPosts } from "../../Services/Blog/BlogApi";

function MyPosts() {
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  useEffect(() => {
    setLoading(true);
    getAllPosts()
      .then((res) => {
        setdata(res.data.filter((el) => el.user_id == localStorage.getItem("id")));
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <ul className="list-group mb-0">
          {data.map((el) => (
            <li className="list-group-item d-flex border-left-0 border-right-0">
              <div className="w-3 h-3 bg-success mr-3 mt-1 brround" />
              <div>
                <label className="fs-12">
                  <span className="font-weight-semibold">{el.title} </span>
                </label>
                <h6>{el.tit}</h6>
                <p className="mb-0 text-muted fs-12">
                  <strong>{el.createdAt}</strong>
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default MyPosts;
