import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all maintenance records for the current user
 * @returns The response is an array of objects.
 */
export async function getAllMaintenance() {
  try {
    const response = await axios.get(api_url + "maintenance/code/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
