import React, { useState, useEffect } from "react";
import { deleteAnnonceById, getAllAnnonces } from "../../Services/Pointeuse/AnnonceApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";
import { filterAnnoncesByWord } from "../../Utils/SortObject";
import { Link } from "react-router-dom";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import swal from "sweetalert";
import AddAnnonce from "./AddAnnonce";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function AnnonceList() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets all the annonces from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getAllAnnonces()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterAnnoncesByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It deletes an annonce from the database.
   * @param id - The id of the record to delete.
   */
  const handleDelete = (id) => {
    swal({
      title: "Ëtes-vous sûr ?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!",
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAnnonceById(id).then(() => {
          swal("Opération effectuée avec succès!", {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  return (
    <>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Note de service")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <a
              href="hr-addemployee.html"
              className="btn btn-primary mr-3"
              data-toggle="modal"
              data-target="#newAnnoncemodal"
            >
              {t("Ajouter Note")}
            </a>
            <ButtonHeaderComponent />
            {/* <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button> */}
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="row">
          {data.map((el, idx) => (
            <div key={idx} className="col-md-6 col-xl-2 d-flex align-items-stretch flex-row">
              <div className="card overflow-hidden">
                <div className="item-image">
                  <Link to="#" className="action-btns m-2" data-tip="supprimer" onClick={() => handleDelete(el.id)}>
                    <i className="feather feather-trash text-danger" />
                  </Link>
                  <Link to={"/admin/annoncedetail/" + el.id}>
                    {/* <img className="card-img-top" src="assets/images/photos/8.jpg" alt="img" /> */}
                    <img
                      className="card-img-top"
                      style={{ height: 150 }}
                      src={el.img ? localStorage.getItem("baseUrl") + el.img : "assets/images/photos/annonce.svg"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "assets/images/photos/annonce.svg";
                      }}
                      loading="lazy"
                      alt="img"
                    />
                  </Link>
                  <div className="card-body d-flex flex-column">
                    <h4>
                      <Link to={"/admin/annoncedetail/" + el.id}>{el.title}</Link>
                    </h4>
                    <div className="text-muted mb-4">
                      {el.description.length > 50 ? el.description.substring(1, 50) + "..." : el.description}
                    </div>
                    <Link
                      to={"/admin/annoncedetail/" + el.id}
                      className="align-self-center mt-4 btn btn-lg btn-primary mt-auto"
                    >
                      {t("Lire plus")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <AddAnnonce getData={getData}></AddAnnonce>
    </>
  );
}

export default AnnonceList;
