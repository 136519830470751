import React from 'react'
import moment from "moment";
import { Modal } from 'react-bootstrap';

export default function ListUserAvantage_rub({ t, show, setshow, list }) {
    const handleClose = () => {
        setshow(false)
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('List users')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-vcenter text-nowrap table-bordered border-bottom  no-footer">
                    <thead>
                        <th>{t('Nom')}</th>
                        <th>{t('Prenom')}</th>
                        <th>{t('Valeur')}</th>
                        <th colSpan={3}></th>
                    </thead>
                    <tbody>
                        {list.map(item => (
                            <tr>
                                <td>{item.nom}</td>
                                <td>{item.prenom}</td>
                                <td>{item.valeur}</td>
                                {item.permanent === 1 ?(
                                    <><td>{t('permanent')}</td></>
                                ):
                                <>
                                <td>{moment(new Date(item.date_debut)).format("YYYY-MM-DD")}</td>
                                <td>{moment(new Date(item.date_fin)).format("YYYY-MM-DD")}</td>
                                </>
                                }
                            </tr>

                        ))}
                    </tbody>
                </table>
            </Modal.Body>

        </Modal>
    )
}
