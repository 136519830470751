import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};


/**
 * Accept a leave request
 * @param code_generated
 * @param id - id of the conge
 * @param etat - true or false
 * @param date_debut
 * @param date_fin
 * @param destination
 * @param depart
 * @param user_code_id
 * @returns The response from the server.
 */
export async function acceptDeplacement( 
  code_generated,
  id,
  etat,
  date_debut,
  date_fin,
  destination,
  depart,
  user_code_id) {
  const credentiel = {
    code_generated,
    id,
    etat,
    destination,
    depart,
    date_debut,
    date_fin,
    user_code_id,
    type: "deplacement",
  };
  try {
    const response = await axios.put(api_url + "deplacement/updatedeplacement/", credentiel, config);
     toast.success("Déplacement accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Get all the deplacement for a user
 * @param userid - The user's ID.
 * @returns The response is an array of objects.
 */
export async function getDepalacementByUserId(userid) {
  try {
    const response = await axios.get(api_url + "deplacement/usercode/" + userid, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the deplacement for a user
 * @returns The response is an array of objects.
 */
export async function getDepalacementByCode() {
  try {
    const response = await axios.get(api_url + "deplacement/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getAllDeplacementsByCode() {
  try {
    const response = await axios.get(api_url + "deplacement/codeimg/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * It adds a new conge to the database.
 * @param userId - The id of the user who is requesting a leave
 * @param date_debut - Date of the leave
 * @param date_fin - Date of the leave
 * @param destination - destination
 * @param depart - depart
 * @returns The response is an object with the following structure:
 */
export async function ajouterDeplacement(userId, date_debut, date_fin, destination,depart) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_code_id: userId,
    type: "deplacement",
    etat: "1",
    date_debut: date_debut,
    date_fin: date_fin,
    destination:destination,
    depart:depart
  };
  try {
    const response = await axios.post(api_url + "deplacement/", credentiel, config);
    console.log(response);
    toast.success(t("deplacement ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

