import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCongeByUserCodeId } from "../../../Services/Pointeuse/CongeApi";
import { filterCongeByYear, filterYearAutorisation } from "../../../Utils/userDetaiUtils";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";

function AreaApexConge({ id }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const x = [];
    const y = [];

    getCongeByUserCodeId(id)
      .then((res) => {
        var currentYear = filterCongeByYear(res.data, new Date().getFullYear());
        var lastYear = filterCongeByYear(res.data, new Date().getFullYear() - 1);
        lastYear.map((item) => {
          y.push(item.nbr_jour);
          x.push(item.date);
        });
        let chartOptions = {
          series: [
            {
              name: "Congé",
              data: y,
            },
          ],
          options: {
            chart: {
              type: "bar",
              height: 230,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: "top",
                },
              },
            },
            colors: ["#43DC80"],
            legend: {
              show: false,
              position: "top",
              horizontalAlign: "left",
            },
            dataLabels: {
              enabled: false,
              offsetX: -6,
              style: {
                fontSize: "12px",
                // colors: ["#fff"],
              },
            },
            stroke: {
              show: false,
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            xaxis: {
              show: false,
              categories: x,
            },
          },
        };
        setData(chartOptions);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <ReactApexChart options={data.options} series={data.series} type="bar" height={350} />
      )}
    </>
  );
  //return <p>kjj</p>;
}

export default AreaApexConge;
