import React from "react";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";

function FileImageComponent({ file }) {
  const saveFile = (doc) => {
    FileSaver.saveAs(process.env.REACT_APP_API + doc, doc.split("Z")[doc.length - 1]);
  };

  return (
    <div className="list-group-item  align-items-center">
      {file && (
        <div className="d-md-flex">
          {["png", "jpg", "jpeg"].some((element) => file.split(".").pop().includes(element)) && (
            <img
              src={localStorage.getItem("baseUrl") + file}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "assets/images/users/avatar.png";
              }}
              loading="lazy"
              alt="img"
              className="avatar bg-transparent avatar-xl mr-2"
            />
          )}
          {file.split(".").pop() === "pdf" && (
            <img src="assets/images/files/attachments/pdf.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "css" && (
            <img src="assets/images/files/attachments/css.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "html" && (
            <img src="assets/images/files/attachments/html.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "zip" && (
            <img src="assets/images/files/attachments/zip.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "docx" && (
            <img src="assets/images/files/attachments/docx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "txt" && (
            <img src="assets/images/files/attachments/txt.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}

          {file.split(".").pop() === "js" && (
            <img src="assets/images/files/attachments/js.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "apk" && (
            <img src="assets/images/files/attachments/apk.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "svg" && (
            <img src="assets/images/files/attachments/svg.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "pptx" && (
            <img src="assets/images/files/attachments/pptx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "xlsx" && (
            <img src="assets/images/files/attachments/xlsx.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "rar" && (
            <img src="assets/images/files/attachments/rar.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}
          {file.split(".").pop() === "sql" && (
            <img src="assets/images/files/attachments/sql.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
          )}

          <Link
            to="#"
            className="font-weight-semibold fs-14 mt-5"
            data-toggle="tooltip"
            data-placement="top"
            title={file.split("Z")[1].split(".")[0]}
          >
            {file.split("Z")[1].split(".")[0].substring(0, 12)}
            <span className="text-muted ml-2">(.{file.split("Z")[1].split(".")[1]})</span>
          </Link>

          {/* <Link to="#" className="font-weight-semibold fs-14 mt-5" data-toggle="tooltip" data-placement="top" title={file}>
          {file}
          <span className="text-muted ml-2">(.{file})</span>
        </Link> */}
          <div className="ml-auto d-flex mt-4 text-right">
            <Link to="#" className="action-btns1" onClick={() => saveFile(file)}>
              <i className="feather feather-download-cloud text-primary" />
            </Link>
            {/* <Link to="#" className="action-btns1  mr-0">
              <i className="feather feather-trash-2 text-danger" />
            </Link> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default FileImageComponent;
