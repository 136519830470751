import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { t } from "i18next";
import { getToken } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/****getlistbanques  by code societe*/
export async function getbanques() {
 

    try {
      const response = await axios.get(api_url + "banques/getbanques/"+localStorage.getItem('code_generated'), config);
      console.log(response);
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast.error(t("error server"));
        console.log(error.response.data);
        console.log(error.response.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        toast.error(t("error request"));
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(t("Error"), error.message);
      }
      console.log(error.config);
      return error;
    }
  }


/**********
 save banque
 */

 export async function savebanque(data) {
 

    try {
      const response = await axios.post(api_url + "banques/savebanque",data, config);
      return response;
    } 
    catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast.error(t("error server"));
        
      } 
      else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        toast.error(t("error request"));
      }
      return error;
    }
  }


  /**********
 delete banque
 */

 export async function deletebanque(id) {
 

  try {
    const response = await axios.delete(api_url + "banques/deletebanque/"+id, config);
    return response;
  } 
  catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      
    } 
    else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
    }
    return error;
  }
}

  /**********
 update banque
 */

 export async function updatebanque(data) {
 

  try {
    const response = await axios.put(api_url + "banques/updatebanque",data, config);
    return response;
  } 
  catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      
    } 
    else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
    }
    return error;
  }
}



/***par defaut */
  /**********
 update banque
 */

 export async function updatepardefaut(data) {
 

  try {
    const response = await axios.put(api_url + "banques/updatepardefaut",data, config);
    return response;
  } 
  catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      
    } 
    else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
    }
    return error;
  }
}