import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEnteteCommercialVenteAbonnement, getEnteteCommercialVenteBl } from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit } from "../../../../../Utils/NumberUtils";
import { sumMontantHT, sumMontantHTAbonnement, sumMontantTTC, sumMontantTVA } from "../../../../../Utils/ObjectOperation";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import CopieContent from "../../../../Exports/CopieContent";
import ExportPdf from "../../../../Exports/ExportPdf";
import ExportCsv from "../../../../Exports/ExportCsv";
import { toast, ToastContainer } from "react-toastify";
import { success_message,error_message } from "../../../../alerte/AlerteMessage";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import { getAllAbonnementByCodeAbonnement, getAllAbonnementByCodeAndEntete } from "../../../../../Services/Facturation/FacturationAbonnementApi";
import { facturerabonnement, getListAbonnement } from "../../../../../Services/abonnement/abonnement";
import { useHistory } from "react-router-dom";

function ListAbonnement() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [size, setsize] = useState(40);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const history = useHistory();

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const getData = () => {
    setLoading(true);
    getListAbonnement()
      .then((res) => {
        setdata(res.data.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
   useEffect(() => {
    getData();
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value.length != "") {
      const dataSearch = filterFactureByWord(currentData, e.target.value);
      setcurrentData(dataSearch);
    } else {
      setcurrentData(allInitialData);
    }
  };

  const handleSelectDate = (e) => {
    // const expr = e.target.value;
    // if (expr == 1) {
    //   getData(new Date().getFullYear());
    // }
    // if (expr == 2) {
    //   getData(new Date().getFullYear() - 1);
    // }
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const filterByDate = (value) => {
    const filterd = allInitialData.filter((el) => {
      if (el.date) {
        return el.date.split(" ")[0] == value;
      }
    });

    if (filterd.length === 0) {
      setdata(allInitialData);
    } else {
      setdata(filterd);
    }
  };

  const handleSelectStaus = (e) => {
    // switch (e.target.value) {
    //   case "1":
    //     setdata(allInitialData.filter((el) => !checkMontanPaiement(el?.montant_encaissement)));
    //     break;
    //   case "2":
    //     setdata(allInitialData.filter((el) => checkMontanPaiement(el?.montant_encaissement) == el.net_a_payer));
    //     break;
    //   case "3":
    //     setdata(
    //       allInitialData.filter(
    //         (el) => checkMontanPaiement(el?.montant_encaissement) < el.net_a_payer && checkMontanPaiement(el?.montant_encaissement) > 0
    //       )
    //     );
    //     break;
    //   default:
    //     setdata(allInitialData);
    // }
  };
  const onChangeDate = () => {
 
    const filteredDate = currentData.filter((listed) => {
      const momentDate = moment(listed.date_debut);
      if(startDate > endDate){
        error_message("Attention La date début doit être inférieur à la date fin !!")
      }else{
      return (
        momentDate.isSameOrAfter(moment(startDate)) &&
        momentDate.isSameOrBefore(moment(endDate))
      )};
    });
    console.log('filteredDate',filteredDate)
    setcurrentData(filteredDate);
  };
  const facturerAbonnement=(abonnement )=>{
    
    facturerabonnement(abonnement).then((res) => {
      if(res.status === 200){
        history.push("/admin/listabonnement");

      }
    })
  }

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Liste Abonnement de vente")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/abonnementvente" className="btn btn-primary mr-3">
                {t("Ajouter Abonnement")}
              </Link>
              <Link to="/admin/addclient" className="btn btn-warning mr-3">
                {t("Ajouter client")}
              </Link>
              <Link to="/admin/addproduct" className="btn btn-warning mr-3">
                {t("Ajouter produit")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-12">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
           <div className="card text-bg-light mb-3" style={{maxWidth: '25rem',margin:"auto"}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Montant HT")}</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-success">{sumMontantHTAbonnement(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
             <div className="card text-bg-light mb-3" style={{maxWidth: '25rem',margin:"auto"}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Montant TVA")}</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="card text-bg-light mb-3" style={{maxWidth: '25rem',margin:"auto"}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Montant TTC")}</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                         
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                         
                          onChange={(e) => setEndDate(e.target.value)}
                         
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      //onClick={filterDatesByDateRange}
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Année</option>
                          <option value={1}>{new Date().getFullYear()}</option>
                          <option value={2}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir Statut:</label>

                        <select className="form-control custom-select select2" onChange={handleSelectStaus}>
                          <option value={0}>Tous</option>
                          <option value={1}>En cours</option>
                          <option value={2}>Payée</option>
                          <option value={3}>Partiel</option>
                          <option value={4}>échu</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List Abonnement")}</h4>

              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
            
            <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("payé")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("échu")}
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> {t("partial")}
                  </span>
                  {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            {/* <select className="custom-select m-2 p-2" onChange={handleSelectStaus}>
                              <option value={0}>Tous</option>
                              <option value={1}>En cours</option>
                              <option value={2}>Payée</option>
                              <option value={3}>Partiel</option>
                            </select>

                            <select className="custom-select" onChange={handleSelectDate}>
                              <option value={0}>Année</option>
                              <option value={1}>{new Date().getFullYear()}</option>
                              <option value={2}>{new Date().getFullYear() - 1}</option>
                            </select> */}

                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                            {/* <input
                              className="form-control floating"
                              type="date"
                              onChange={(e) => filterByDate(e.target.value)}
                            /> */}
                          </label>

                          <ExportCsv data={data} name={"List_bons_livraison"} />
                          <ExportPdf
                            data={data}
                            name={"List_bons_livraison"}
                            columns={[
                              "facture_code",
                              "client_nom",
                              "montant_total_ht",
                              "montant_total_tva",
                              "montant_total_ttc",
                              "montant_encaissement",
                              "net_a_payer",
                            ]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                         <thead>
                         <tr>
                              <th>{t("N° Pièce")}</th>
                              <th>{t('code_commande')}</th>
                              <th>{t("Client")} </th>
                              <th>{t("Date Début")} </th>
                              <th>{t("Date Fin")} </th>
                              <th>{t("Nombre de mois")} </th>
                              <th>{t('frequence_facturation')}</th>
                              {/* <th>{t("Produit")} </th> */}
                              {/* <th>
                                {t("Frequance")} <br></br>
                              </th> */}
                               <th>
                                {t("Sous total HT")} 
                                {/*<span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>*/}
                              </th>
                              <th>
                                {t("total Ht")} 
                                {/*<span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>*/}
                              </th>
                           
                              {/* <th>Statut</th> */}
                               
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td>                                
                                  <div className="d-flex">
                                    <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                                    <div className="mt-1 mr-4">
                                      <Link to={"/admin/abonnementvente/" + el.code_abonnement} >{el.code_abonnement}</Link>
                                    </div>
                                  </div>
                                  </td>
                                  <td>{el.code_commande}</td>
                                  <td>
                                  <Link to={"/admin/clientactivity/" + el.client_code}>{el.nom}{el.prenom}</Link>
                                </td>
                                <td>{moment(el.date_debut).format("YYYY-MM-DD")}</td>
                                <td>{moment(el.date_fin).format("YYYY-MM-DD")}</td>
                                <td>{el.nb_mois}</td>
                                <td>{el.frequence_facturation}</td>
                                <td>{el.sous_total_ht}</td>
                                <td>{el.totalHt}</td>

                              </tr>
                            ))}
                          </tbody>
                         {/* <tfoot> 
                          <tr>
                              <th>{t("N° Pièce")}</th>
                              <th>{t("Client")} </th>
                              <th>{t("Date Début")} </th>
                              <th>{t("Date Fin")} </th>
                              <th>
                                {t("Montant HT")} <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                              </th>
                              <th>
                                {t("Montant TVA")} <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                              </th>
                              <th>
                                {t("Montant TTC")} <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                              </th>
                              {/* <th>Statut</th> 

                               <th>{t("Net à payer")}</th> 
                            </tr>
                          </tfoot>
                          </div>*/}
                          
                        </table>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th>N° Pièce</th>
                              <th>Client </th>
                              <th>
                                Montant HT <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                              </th>
                              <th>
                                Montant TVA <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                              </th>
                              <th>
                                Montant TTC <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                              </th>
                              <th>Net à payer</th>
                              <th>Catégorie</th>
                              <th>Statut</th>
                              <th>Date</th>
                              <th className="text-right"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td>{el.facture_code}</td>
                                <td>{el.client_nom}</td>
                                <td>{convertToDigit(el.montant_total_ht)}</td>
                                <td>{convertToDigit(el.montant_total_tva)}</td>
                                <td>{convertToDigit(el.montant_total_ttc)}</td>
                                <td>{el.net_a_payer}</td>
                                <td></td>
                                <td>{el.status}</td>
                                <td>{el.date.split(" ")[0]}</td>

                                <td className="project-actions text-conter">
                                  <Link to={"/admin/editerblvente/" + el.code} className="action-btns">
                                    <i className="feather feather-edit text-info" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>N° Pièce</th>
                              <th>Client </th>
                              <th>
                                Montant HT <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                              </th>
                              <th>
                                Montant TVA <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                              </th>
                              <th>
                                Montant TTC <br></br>
                                <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                              </th>
                              <th>Net à payer</th>
                              <th>Catégorie</th>
                              <th>Statut</th>
                              <th>Date</th>
                              <th className="text-right"></th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListAbonnement;
