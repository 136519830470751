import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { soustractAdressFirstLine, soustractAdressSecondLine } from "../../../Utils/StringLengthUtil";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: 40,
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
    marginLeft: 30,
  },

  label: {
    width: 120,
  },
  ToStyle: {
    marginLeft: 30,
    justifyContent: "center",
    alignItems: "center",
  },
});

const BillTo = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.ToStyle}>
        {invoice.receiver_name} {invoice.receiver_prenom}
      </Text>
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.ToStyle}>{soustractAdressFirstLine(invoice.receiver_adr)}</Text>
    </View>
    {invoice.receiver_adr.length > 20 && (
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.ToStyle}>{soustractAdressSecondLine(invoice.receiver_adr)}</Text>
      </View>
    )}
    <View style={styles.invoiceNoContainer}>
      {/* <Text style={styles.ToStyle}>{invoice.client.gouvernerat}</Text> */}
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.ToStyle}>{invoice.matricule_fiscal}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.ToStyle}>{invoice.receiver_mobile}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.ToStyle}>{invoice.receiver_email}</Text>
    </View>
  </Fragment>
);

export default BillTo;
