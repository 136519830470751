import React, { useEffect, useState } from 'react'
import { getAllPointageHistory } from '../../Services/Pointeuse/PointageApi';
import { getUsersByCode } from '../../Services/Pointeuse/UsersApi';
import { t } from "i18next";
import CalculMonthPointage from './CalculMonthPointage';
import { getCongeByUserCodeId } from '../../Services/Pointeuse/CongeApi';
import ValidationPaie from './ValidationPaie';
import { getPersonnelById } from '../../Services/Pointeuse/PersonnelApi';

export default function CalculJourTravail() {

    const [dataPointages, setDataPointages] = useState(null);
    const [data, setdata] = useState([]);
    const [date, setdate] = useState('');
    const [error, setError] = useState(null);
    const [pointagenonsortie, setpointage_nonsortie] = useState([]);
    const [pointageavance, setpointage_avance] = useState([]);
    const [listtravaille, setlisttravaille] = useState([]);
    const [listconge, setlistconge] = useState([]);
    const [showModal, setshowModal] = useState(false);

  /*  useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Notez que getMonth() retourne un index de 0 à 11, donc on ajoute 1 pour avoir le mois actuel
        const formattedMonth = '01' // Ajout d'un zéro devant les mois < 10 pour avoir un format mm
        const formattedDate = `${year}-${formattedMonth}`;

        setdate(formattedDate);
    }, []);
    useEffect(() => {
        getAllPointageHistory().then((res) => {
            setDataPointages(res.data);
        });
    }, []);
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        //setLoading(true);
        getUsersByCode()
            .then((res) => {
                setdata(res.data);
                console.log(res.data);

            })
            .catch((error) => setError(error))
            .finally();
    };
    useEffect(() => {
        const listconge = []
        data.forEach(user => {
            getCongeByUserCodeId(user.id)
                .then((res) => {
                 
                    let liste_obj_filtered = res.data.filter(obj => {
                        return new Date(obj.date).getFullYear() === 2024 && new Date(obj.date).getMonth() === 1-1; // 1 pour février (les mois sont indexés à partir de 0)
                    });

                    const dataconge = { user_code_id: user.id, liste_obj_filtered }

                    console.log('dataconge',dataconge)
                   listconge.push(dataconge)
                })
                .catch((error) => setError(error))
                .finally(() => {
                    setlistconge(listconge)
    
                }
                
                );

        });

    }, [data]);
*/
    return (
    
                <div></div>
    )
}
  /*  <div className="row">
            <div className="col-xl-12 col-md-12 col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 col-lg-3">
                                <div className="form-group">
                                    <label className="form-label">{t("Mois")}:</label>
                                    <div className="input-group" >
                                        <div className="input-group-prepend">

                                        </div>
                                        {date}
                                    </div>

          
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex mb-6 mt-5">
                           
                           
                        </div>
                        <div className="table-responsive hr-attlist">
                            <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>nombre de jour de travail</th>
                                        <th>nombre travaillé</th>
                                        <th>nombre conge</th>
                                       <th>pointé avant 17h</th> 
                                    <th>pointagenonsortie</th> 
                                    </tr>


                                </thead>
                                {listconge && dataPointages && (
                                    <tbody>
                                        {data.map((el, idx) => (
                                            <CalculMonthPointage
                                                user={el}
                                                currentDate={date}
                                                setpointage_avance={setpointage_avance}
                                                setpointage_nonsortie={setpointage_nonsortie}
                                                pointagenonsortie={pointagenonsortie}
                                                pointageavance={pointageavance}
                                                setlisttravaille={setlisttravaille}
                                                listtravaille={listtravaille}
                                                listconge={listconge}
                                                setlistconge={setlistconge}
                                            />
                                        ))}
                                    </tbody>
                                )}


                            </table>
                        </div>
                        {data &&
                        <ValidationPaie 
                        show={showModal}
                        setshow={setshowModal}
                        data={data} 
                        listtravailler={listtravaille}
                        listconge={listconge}
                        />
                        }
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="text-right">
            {/* <button type="button" id="submit" name="submit" className="btn btn-secondary">
                      Cancel
                    </button> */
           /* <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={()=>setshowModal(true)}>
              {t("Valider")}
            </button>
          </div>
        </div>
      </div>
                    </div>
                </div>
            </div>
                </div>*/