

  import { useEffect, useState } from "react";
  import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
  import {Link } from "react-router-dom"
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import AddModalDeplacement from "./AddModalDeplacement";
import { getAllDeplacementsByCode } from "../../Services/Pointeuse/DeplacementApi";
  function AjoutDeplacement(props) {
    const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
    const [deplacement, setdeplacement] = useState([]);
  
  useEffect(()=>{
    getAllDeplacementsByCode().then((result)=>{
        setdeplacement(result?.data)
    })
  },[])
  const filteredDeplacementsValid = deplacement.filter(deplacement => deplacement?.etat === 1);
    const filteredDeplacementsNonValid = deplacement.filter(el => el?.etat === 0);
    return (
      <>
        <div className="page-header d-xl-flex d-block">
          <div className="page-leftheader">
            <h4 className="page-title">{t("Déplacement")}</h4>
          </div>
          <div className="page-rightheader ml-md-auto">
            <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
              <div className="btn-list">
                <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#addDeplacementModal">
                  {t("Ajouter Déplacement")}
                </Link>
                <ButtonHeaderComponent />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-0 pb-0 d-flex justify-content-center">
                  <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">
                      {deplacement?.length}
                    </span>
                    <h5 className="mb-0 mt-3">{t("Total Déplacement")}</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 bg-success-transparent">{filteredDeplacementsValid?.length}</span>
                    <h5 className="mb-0 mt-3">{t("Total validés")}</h5>
                  </div>
                  <div className="col-md-6 col-lg-2 text-center py-5 ">
                    <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">{filteredDeplacementsNonValid?.length}</span>
                    <h5 className="mb-0 mt-3">{t("Total non validés")}</h5>
                  </div>
                  {/* <div className="col-md-6 col-lg-2 text-center py-5">
                    <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">{demandes?.length}</span>
                    <h5 className="mb-0 mt-3">{t("Total demandes")}</h5>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        <AddModalDeplacement getData={props.getData} />
      </>
    );
  }
  
  export default AjoutDeplacement;
