import React, { useEffect, useState } from "react";
import { t } from "i18next";
import ImageComponent from "../Styles/ImageComponent";
import { getClientSupportByCode, getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import moment from "moment";
import { toast } from "react-toastify";
import { creeReunion, getReunionbyid, updateReunionbyid } from "../../Services/Pointeuse/Reunionclient";
import { Link, useHistory } from "react-router-dom";
import Select from 'react-select';
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";


function ReunionClient(props) {
  const history = useHistory();

  const role = localStorage.getItem('role_code');
  const [assigned_to, setassigned_to] = useState({
    id: localStorage.getItem('id'),
    nom: localStorage.getItem('nom'),
    prenom: localStorage.getItem('prenom'),
    email: localStorage.getItem('email'),
    role: localStorage.getItem('role_code'),
  })

  const [data, setdata] = useState({
    id: '',
    user_id: localStorage.getItem('id'),
    client_code: '',
    date: '',
    heure_depart: '',
    heure_final: '',
    duree: 0,
    objet: '',
    description: '',
    lieu: '',
    participants: '',
    code_generated: localStorage.getItem('code_generated')
  })

  const [clients, setclients] = useState([])
  const [users, setusers] = useState(null);

  useEffect(() => {
    if (props.match.params.id) {
      getReunionbyid(props.match.params.id).then((res) => {
        const result = res.data[0];
        setdata(prev => ({
          ...prev,
          id: result.id,
          user_id: result.user_id,
          client_code: result.client_code,
          date: result.date.split('T')[0],
          heure_depart: result.heure_depart,
          heure_final: result.heure_final,
          duree: result.duree,
          objet: result.objet,
          participants: result.participants,
          description: result.description,
          code_generated: result.code_generated,
          lieu: result.lieu

        }));
        const founduser = users?.find(item => item.id === parseInt(result.user_id))
        if (founduser) {
          setassigned_to({
            id: founduser.id,
            nom: founduser.nom,
            prenom: founduser.prenom,
            email: founduser.email,
            role: founduser.role_code,
          })}
      });
    }
  }, [users])
  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);
  useEffect(() => {
    getClientSupportByCode().then((res) => {
      const result = res?.data;

      setdata(prev => ({
        ...prev,
        client_code: res?.data[0].code,

      }));

      if (result) {
        const options = result.map((d) => ({
          value: d.code_client,
          label: d.nom,
          secondValue: d.code,
        }));
        setclients(options);
      }

    });
  }, [])

  const handelchange = (field, value) => {
    if(field==="user_id"){
      const founduser = users.find(item => item.id === parseInt(value))
      if (founduser) {
        setassigned_to({
          id: founduser.id,
          nom: founduser.nom,
          prenom: founduser.prenom,
          email: founduser.email,
          role: founduser.role_code,
        })
       
      }
    }
    setdata(prev => ({
      ...prev,
      [field]: value

    }));
  }
  const handleClientChange = (selectedOption) => {
  
    setdata(prev => ({
      ...prev,
      client_code: selectedOption.secondValue,

    }));
  };
  const calculerDuree = (heureDepart, heureFin) => {
    const depart = new Date('1970-01-01T' + heureDepart);
    const fin = new Date('1970-01-01T' + heureFin);
    const differenceMs = fin.getTime() - depart.getTime();
    const differenceMin = Math.round(differenceMs / 60000);
    return differenceMin;
  }

  const handelsave = () => {
    if (
      data.objet === '' ||
      data.client_code === '' ||
      data.date === '' ||
      data.heure_final === '' ||
      data.heure_depart === ''

    ) {
      // Show an error message indicating required fields
      toast.error('Veuillez remplir tous les champs requis');
      return; // Stop execution if any required field is missing
    }
    const duree = calculerDuree(data.heure_depart, data.heure_final);
    const newdata = { ...data }
    newdata.duree = duree

    if (props.match.params.id) {
      updateReunionbyid(newdata).then((res) => {
        console.log(res)
        if (res.status === 200) {
          toast.success(t("  updated "));
          if (localStorage.getItem('role_code') === 'admin') {
            history.push("/admin/reunion");
          } else {
            history.push("/reunion");

          }

        } else {
          toast.error(t("un problème "));

        }
      });

    } else {
      delete newdata.id;

      creeReunion(newdata).then((res) => {
        console.log(res)
        if (res.status === 200) {
          toast.success(t("  cree "));
          if (localStorage.getItem('role_code') === 'admin') {
            history.push("/admin/reunion");
          } else {
            history.push("/reunion");

          }

        } else {
          toast.error(t("un problème "));

        }
      });
    }
  }
  return (
    < >
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">
            <span className="font-weight-normal text-muted mr-2">{t("Reunion  avec Client")} </span>
          </h4>
        </div>

      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">{t("USER")}</div>
              <div className="ml-auto">

              </div>
            </div>
            <div className="card-body">
              <div className="text-center">
                <div className="widget-user-image mx-auto text-center">
                  <ImageComponent atr={"avatar avatar-xxl brround rounded-circle"} ></ImageComponent>
                </div>
                <div className="pro-user mt-3">
                  <h5 className="pro-user-username text-dark mb-1 fs-16">
                    {assigned_to.nom} {assigned_to.prenom}
                  </h5>
                  <h6 className="pro-user-desc text-muted fs-12">{assigned_to.email}</h6>
                </div>
              </div>
              <div className="table-responsive mt-5">
              {role === 'admin' ? (
            <div className="col-md-12">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    {t("Collaborateur")}
                  </label>

                  <select onChange={(e) => handelchange('user_id', e.target.value)} className="selectpicker form-control" value={data.user_id}>
                    <option value="0">{t("Choisir un collaborateur")}</option>
                    {users &&
                      users.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom} {el.prenom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}

              </div>


            </div>
          </div>
        </div>
        <div className="col-9 panel-body tabs-menu-body hremp-tabs1 p-0">
          <div className="tab-content">
            <div className="tab-pane active" id="tab5">
              <div className="card-body">

                <div className="main-profile-bio mb-0">
                  
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label>
                            {t("Client")}<span className="text-danger">*</span>
                          </label>

                          <Select
                            onChange={handleClientChange}
                            value={clients.find(item => item.secondValue === data.client_code)}
                            options={clients}
                            placeholder={t("Choisir un client")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">{t("Objet")}<span className="text-danger">*</span></label>
                        <input required className="form-control" placeholder={t("Text")} value={data.objet} onChange={(e) => handelchange('objet', e.target.value)} />
                      </div>
                    </div>

                  </div>


                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date début")}:<span className="text-danger">*</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            placeholder="DD-MM-YYY"
                            type="date"
                            onChange={(e) => handelchange('date', moment(e.target.value).format("YYYY-MM-DD"))}
                            value={moment(data.date).format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("l'heure de depart")}:<span className="text-danger">*</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-clock" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            type="time"
                            onChange={(e) => handelchange('heure_depart', e.target.value)}
                            value={data.heure_depart}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("l'heure de fin")}:<span className="text-danger">*</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-clock" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            type="time"
                            onChange={(e) => handelchange('heure_final', e.target.value)}
                            value={data.heure_final}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">{t("Lieu de reunion")}</label>
                  <select className="form-control" value={data.lieu} onChange={(e) => handelchange('lieu', e.target.value)}>
                    <option value='chez client'>Chez Client</option>
                    <option value="clediss">Clediss</option>
                    <option value='meet'>Meet</option>

                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">{t("participants")}</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={2}
                    value={data.participants}
                    onChange={(e) => handelchange('participants', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">{t("Description")}</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    value={data.description}
                    onChange={(e) => handelchange('description', e.target.value)}
                  />
                </div>






                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="text-right">
                      {/* <button type="button" id="submit" name="submit" className="btn btn-secondary">
                Cancel
              </button> */}
                      <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handelsave}>
                        {t("Enregistrer")}
                      </button>
                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>
        </div>
      </div>
    </>

  );
}

export default ReunionClient;
