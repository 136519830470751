import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { getFacturationFournisseurByCode } from "../../../Services/Facturation/FacturationClientApi";

import { getSession } from "../../../Utils/SessionUtils";
import {
  editSoldeEnteteByCode,
  editSoldeEnteteByCodeFacture,
  getEnteteByClientCode,
  getEnteteCommercialAchat,
} from "../../../Services/Facturation/FacturationEnteteApi";
import { addPaiement } from "../../../Services/Facturation/FacturationPaiementApi";
import { getEntrepriseByCode } from "../../../Services/Pointeuse/EntrepriseApi";
import { getPercent, soustractPercent } from "../../../Utils/PaiementUtils";
import { t } from "i18next";
import { getbanques } from "../../../Services/banque";
import moment from "moment";
import { getYear } from "../../../Utils/DateUtils";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { handleKeyPress_number } from "../../../Utils/InputUtils";
import { getRStaxes } from "../../../Services/Facturation/FacturationTvaApi";
import ModalRS from "../invoiceReports/ModalRS";
import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function AddPaiement(props) {
  const history = useHistory();

  const [isChecked, setisChecked] = useState(false);
  const { net_a_payer, facture_code, client_code, solde } = props.location.state?.el || {};
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [dataFacturation, setDataFacturation] = useState([]);
  const [listbanque, setlistbanques] = useState([])
  const [avance, setavance] = useState(false);
  const [list_rs, setList_rs] = useState([]);
  const [addrs, setaddrs] = useState(false);
  const [societe, setSocoiete] = useState()
  const [showResults, setshowResults] = useState(false)
  const [fournisseur, setfournisseur] = useState()

  const [state, setState] = useState({
    societe_code: getSession("code_generated"),
    montant_total: net_a_payer || 0,
    date_reception: moment(new Date()).format("YYYY-MM-DD"),
    exercice: "",
    fournisseur: client_code,
    num_piece: facture_code,
    commentaire: "",
    emplacement: "",
    status: "",
    compte: "",
    sous_compte: "",
    montant_saisie: 0,
    montant_saisie_rs: 0,
    solde: solde,

  });
  const [listpaiement, setListpaiement] = useState([{
    banque: "",
    mode_paiement: "",
    numero_paiement: "",
    reference_banque: "",
    montant: 0,
    date_echeance: "",
    b: 0,
  }]);
  const [listRs, setlistRs] = useState([{
    id: "",
    taux: 0,
    libelle: '',
    montant: 0,
    montant_base: 0,
  }])

  useEffect(() => {
    getFacturationFournisseurByCode().then((res) => {
      setDataFournisseur(res.data);

    });
  }, []);
  useEffect(() => {
    getRStaxes().then((res) => {
      setList_rs(res.data.data);

    });
  }, []);



  useEffect(() => {
    if (client_code) {
      const findfr = dataFournisseur.find(item => item.code === client_code);

      if (findfr) {
        setfournisseur(findfr)
        const findrs = list_rs.find(item => item.code === findfr.rs_id);
        if (findrs) {
          const copieRs = [...listRs];
          copieRs[0] = {
            id: findrs.id,
            taux: findrs.taux,
            libelle: findrs.libelle,
            montant: 0,
            montant_base: state.montant_saisie

          }
          setlistRs(copieRs)
        }

      }


    }
  }, [client_code, dataFournisseur]);

  useEffect(() => {
    getbanques().then((res) => {

      setlistbanques(res.data)
    });


  }, [])

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      if (res.data) {

        setSocoiete(res.data);
      }
    });

  }, []);
  useEffect(() => {
    if (client_code) {
      getEnteteByClientCode(client_code).then((res) => {
        const result = res.data.filter((el) => {
          return el.type == "facture" && el.solde < el.net_a_payer;
        });
        const options = result.map((d) => ({
          value: d.facture_code,
          label: d.facture_code,
          dateValue: d.date.split("-")[0],
          ttcValue: d.montant_total_ttc,
          numfactureValue: d.facture_code,
          net_a_payer: d.net_a_payer,
          solde: d.solde
        }));

        setDataFacturation(options);


      });
    }

  }, []);


  const EntetChange = (name, value) => {
    if (name === 'num_piece') {

      if (value === 'avance') {
        setavance(true)
        setState(prev => ({
          ...prev,
          num_piece: null,
          exercice: getYear(new Date()),
          montant_total: 0,
          solde: 0,


        }))
      } else {
        const findfacture = dataFacturation.find(item => item.value === value);

        if (findfacture) {
          setState(prev => ({
            ...prev,
            montant_total: findfacture.net_a_payer || 0,
            exercice: getYear(findfacture.dateValue),
            num_piece: value,
            solde: findfacture.solde


          })

          )
        }
      }

    }
    if (name === 'client') {
      getEnteteByClientCode(value).then((res) => {
        const result = res.data.filter((el) => {
          return el.type == "facture" && el.solde < el.net_a_payer;
        });
        const options = result.map((d) => ({
          value: d.facture_code,
          label: d.facture_code,
          dateValue: d.date.split("-")[0],
          ttcValue: d.montant_total_ttc,
          numfactureValue: d.facture_code,
          solde: d.solde,
          net_a_payer: d.net_a_payer
        }));
        const findfr = dataFournisseur.find(item => item.code === value);
        if (findfr) {
          setfournisseur(findfr)
          const findrs = list_rs.find(item => item.id === parseInt(findfr.rs_id));

          if (findrs) {
            const copieRs = [...listRs];
            copieRs[0] = {
              id: findrs.id,
              taux: findrs.taux,
              libelle: findrs.libelle,
              montant: 0,
              montant_base: state.montant_saisie


            }
            setlistRs(copieRs)
          }


        }
        setState(prev => ({
          ...prev,
          fournisseur: value,
          solde: 0,
          montant_total: 0,
          num_piece: '',


        }));
        setDataFacturation(options);

      });
    }

    else {

      setState(prev => ({
        ...prev,
        [name]: value,



      }));
    }

  }

  const Rschange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listRs];

    if (index === 0) {
      if (name === 'id') {
        // recalcul le montant 
        //set new rs 
        const findrs = list_rs.find(item => item.id === parseInt(value));
        if (findrs) {
          const m = state.montant_saisie * (findrs.taux / 100)
          list[index] = {
            id: value,
            taux: findrs.taux,
            libelle: findrs.libelle,
            montant: m,
            montant_base: state.montant_saisie

          }
        }
      }


    }
    setlistRs(list)
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listpaiement];
    if (name === 'montant') {
      list[index][name] = value !== '' ? value : 0

    }
    else if (name === 'date_echeance') {
      if (new Date(value) < new Date()) {
        toast.warning("la date d'echeance est incorrecte")
      } else {
        list[index][name] = value

      }
    }


    else {
      list[index][name] = value

    }
    setListpaiement(list)


  }

  /* useEffect(() => {
     getEntrepriseByCode().then((res) => {
       const list = { ...state };
       list["banque"] = res.data.banque;
       setState(list);
     });
   }, []);*/

  const handleVerif = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const montant_a_payer = parseFloat(state.montant_total) - parseFloat(state.solde);
    if ((!avance && (parseFloat(state.montant_saisie) > montant_a_payer))) {
      swal({
        title: t("Le montant saisi dépasse le montant  à payer"),
        icon: "warning",
        buttons: {
          cancel: t("Annuler"),
        },
      }).then((ls) => {
        if (ls !== null) {
          handleSubmitPaiement();
        }
      });
    } else {
      swal({
        title: t("Êtes-vous sûr ?"),
        text: t("Voulez-vous vraiment enregistrer ce paiement ?"),
        icon: "warning",
        buttons: {
          cancel: t("Annuler"),
          confirm: {
            text: t("OK"),
          },
        },
      }).then((ls) => {
        if (ls !== null) {
          handleSubmitPaiement();
        }
      });
    }
  };

  const handleSubmitPaiement = (e) => {
    //e.preventDefault();
    const newentet = { ...state }
    const s = state.montant_saisie + listRs[0].montant
    delete newentet.montant_saisie;
    delete newentet.montant_saisie_rs;
    delete newentet.rs;
    delete newentet.montant_total;
    delete newentet.solde;

    const updatedInputList = listpaiement.map(item => {
      const updatedItem = { ...item };
      updatedItem.montant = parseFloat(updatedItem.montant);
      delete updatedItem.b;
      return updatedItem;
    });

    const data = { dataenete: newentet, lignes: updatedInputList, solde: s, listrs: listRs }
    addPaiement(data).then((res) => {
      if(res.status===200){
        if (client_code) {
          history.push("/admin/factureachat");
  
        } else {
          history.push("/admin/paiement");
  
        }
      }
      

    });
  };
  useEffect(() => {
    const m_saisi = listpaiement.reduce((acc, val) => val.mode_paiement !== 'rs' ? acc + parseFloat(val.montant) : acc, 0);

    setState(prev => ({
      ...prev,
      montant_saisie: m_saisi,
    }))
  }, [listpaiement])
  useEffect(() => {
    // find rs 

    if (addrs) {

      const list = [...listRs]
      if (listRs[0].taux !== 0) {
        const montant_rs = parseFloat(state.montant_saisie) * (listRs[0].taux / 100)
        list[0].montant = montant_rs;
        list[0].montant_base = state.montant_saisie
        setlistRs(list)
      }



    }






  }, [state.montant_saisie])


  const handleCheck = (event) => {
    if (isChecked === true) {
      const list = { ...state };
      // const oldRs = list.rs;
      // console.log(oldRs);
      list["montant"] = Number(list["rs"]) + Number(list["montant"]);
      list["rs"] = 0;
      setState(list);
    } else {
      const list = { ...state };
      list["rs"] = getPercent(list.montant);
      list["montant"] = soustractPercent(list.montant);
      setState(list);
    }
    setisChecked(!isChecked);
  };
  const handleAddClick = () => {
    setListpaiement([
      ...listpaiement,
      {
        banque: "",
        mode_paiement: "",
        numero_paiement: "",
        reference_banque: "",
        montant: 0,
        date_echeance: "",
      }
    ]);
  }
  const handleAddClickrs = () => {
    setlistRs([
      ...listRs,
      {
        id: "",
        taux: 0,
        libelle: '',
        montant: 0,
        montant_base: 0,
      }
    ]);
  }
  const handleRemoveClick = (index) => {
    const list = [...listpaiement];
    list.splice(index, 1);
    setListpaiement(list);
  };
  const handleRemoveClickrs = (index) => {
    const list = [...listRs];
    list.splice(index, 1);
    setlistRs(list);
  };
  const openrs = () => {
    if (!addrs) {
      const findfr = dataFournisseur.find(item => item.code === state.fournisseur);

      const findrs = list_rs.find(item => item.id === findfr?.rs_id);

      const m = state.montant_saisie * (findrs?.taux / 100)
      setlistRs([{
        id: findrs ? findrs.id : "",
        taux: findrs ? findrs.taux : 0,
        libelle: findrs ? findrs.libelle : '',
        montant: findrs ? m : 0,
        montant_base: state.montant_saisie

      }])
    }
    setaddrs(!addrs)

  }
  const downloadPDF = () => {
    const input = document.getElementById('content'); // ID du div dont vous souhaitez télécharger le contenu
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('content.pdf');
      });
  };
  return (
    <div className="container-fluid mt-4">

      <div class="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Ajouter paiement")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <form style={{ marginTop: 50 }} onSubmit={handleVerif}>
                <div className="row">
                  <div className="col-2">
                    <div className="form-group">
                      <label>{t("Fournisseur")}</label>
                      {client_code ? (
                        <>
                          {dataFournisseur && (
                            <select
                              name="fournisseur"
                              className="form-control"
                              id="exampleFormControlSelect0"
                              onChange={(e) => EntetChange('client', e.target.value)}
                              value={state.fournisseur}
                              disabled
                            >
                              <option>{t("Choisir ...")}</option>
                              {dataFournisseur.map((option) => (
                                <option value={option.code}>
                                  {option.nom} {option.prenom}
                                </option>
                              ))}
                            </select>
                          )}
                        </>
                      ) :
                        <>
                          {dataFournisseur && (
                            <select
                              name="fournisseur"
                              className="form-control"
                              id="exampleFormControlSelect0"
                              onChange={(e) => EntetChange('client', e.target.value)}
                              value={state.fournisseur}
                              required
                            >
                              <option>{t("Choisir ...")}</option>
                              {dataFournisseur.map((option) => (
                                <option value={option.code}>
                                  {option.nom} {option.prenom}
                                </option>
                              ))}
                            </select>
                          )}
                        </>}

                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-group">
                      <label>{t("Facture")}</label>{avance && t('  Avance')}
                      {dataFacturation && (
                        <select
                          name="num_piece"
                          className="form-control"
                          id="exampleFormControlSelect1"
                          onChange={(e) => EntetChange('num_piece', e.target.value)}
                          value={state.num_piece}
                          required
                        >

                          <option value={"default"}>{t("Choisir Facture")}</option>
                          <option value='avance'>{t("avance")}</option>
                          {dataFacturation.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">{t("Date")}</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        name="date_reception"
                        onChange={(e) => EntetChange('date_reception', e.target.value)}
                        value={state.date_reception}
                      />
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="form-group">
                      <strong className="ml-3" >{t("Montant")} : {state.montant_total}</strong>

                    </div>


                  </div>
                  <div className="mt-5">
                    <div className="form-group">
                      <strong className="ml-3" >{t("Montant saisie")} : {state.montant_saisie}</strong>

                    </div>


                  </div>

                  <div className="mt-5">
                    <div className="form-group">
                      <strong className="ml-3" >{t("Solde")} : {state.solde}</strong>

                    </div>


                  </div>
                </div>
                {(avance || (parseFloat(state.montant_total) !== parseFloat(state.solde))) &&
                  <>


                    <div className="row">
                      <div className="col-2">
                        <div className="form-group">
                          <label>{t("Mode de paiement")}</label>

                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group">
                          <label>{t("Banque")}</label>

                        </div>
                      </div>



                      <div className="col-2">
                        <div className="form-group">
                          <label>{t("Numéro de paiement")}</label>

                        </div>
                      </div>


                      <div className="col-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">{t("Montant")}</label>

                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">{t("Date_écheance")}</label>

                        </div>



                      </div>



                    </div>
                    {listpaiement.map((item, i) => (
                      <div className="row">
                        <div className="col-2">
                          <div className="form-group">
                            <select className="form-control" name="mode_paiement" required value={item.mode_paiement} onChange={(e) => handleInputChange(e, i)}>
                              <option>{t("Choisir ...")}</option>
                              <option value="cheque">{t("Chèque")}</option>
                              <option value="virement">{t("Virement")}</option>
                              <option value="traite">{t("Traite")}</option>
                              <option value="espece">{t("Espèce")}</option>
                              <option value="autre">{t("Autre")}</option>
                            </select>{" "}
                          </div>
                        </div>
                        {true ? (<div className="col-2">
                          <div className="form-group">
                            <select className="form-control" name='banque' value={item.banque} onChange={(e) => handleInputChange(e, i)}>
                              <option value={''} > choisir</option>
                              {listbanque.map((item, index) => (

                                <option key={item.id} value={item.id}>{item.banque}-{item.num_compte}</option>
                              ))}
                              <option value={'tiroire'}>Tiroire</option>
                            </select>
                          </div>
                        </div>) : <div className="col-2">
                          <div className="form-group">
                            <select className="form-control" name='banque' readOnly >
                            </select>
                          </div>
                        </div>}




                        <div className="col-2">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              required
                              name="numero_paiement"
                              value={item.numero_paiement}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                        </div>


                        <div className="col-2">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              required
                              onKeyPress={handleKeyPress_number}
                              readOnly={listpaiement[i].mode_paiement === 'rs'}
                              name="montant"
                              value={item.montant}
                              onChange={(e) => handleInputChange(e, i)}

                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control"
                              required
                              name="date_echeance"
                              value={item.date_echeance}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>


                        </div>
                        <div className=" col-1">

                          {listpaiement.length - 1 === i && (
                            <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                              <i className="fa fa-plus" />
                            </Link>
                          )}
                          {listpaiement.length !== 1 && (
                            <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                              <i className="fa fa-minus" />
                            </Link>
                          )}
                        </div>



                      </div>
                    ))}
                  </>
                }
                <div className="row">
                  <label className="col-1">RS</label>
                  <div className=" col-1">

                    <input className="form-control" type='checkbox' onClick={() => { openrs() }} />
                  </div>
                  {addrs &&
                    <><div className="col-2">
                      <label>Montant</label>
                    </div><div className="col-2">
                        <label>Taux</label>
                      </div><div className="col-2">
                        <label>Montant de base</label>
                      </div></>
                  }
                </div>

                {addrs &&
                  listRs.map((item, i) => (
                    <div className="row ">

                      <div className="col-2">
                        <select
                          name="id"
                          className="form-control"
                          id="exampleFormControlSelect0"
                          onChange={(e) => Rschange(e, i)}
                          value={item.id}
                          required
                        >
                          <option>{t("Choisir RS")}</option>
                          {list_rs?.map((option) => (
                            <option value={option.id}>
                              {option.libelle}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-2">
                        <input className="form-control" readOnly type='text' value={item.montant} />
                      </div>
                      <div className="col-2">
                      <input className="form-control" readOnly type='text' value={item.taux} />
                      </div>
                      <div className="col-2">
                      <input className="form-control" readOnly type='text' value={item.montant_base} />

                      </div>
                      <div className=" col-1">

                        {listRs.length - 1 === i && (
                          <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClickrs}>
                            <i className="fa fa-plus" />
                          </Link>
                        )}
                        {listRs.length !== 1 && (
                          <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClickrs(i)}>
                            <i className="fa fa-minus" />
                          </Link>
                        )}
                      </div>
                    </div>
                  ))
                }


                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-9 col-xl-9">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">{t("Commentaire")}</label>
                      <textarea

                        name="commentaire"
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        value={state.commentaire}
                        onChange={(e) => EntetChange('commentaire', e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                {(avance || state.num_piece) && (
                  <><button type='submit' className="btn btn-primary float-right">
                    {t("Enregistrer")}
                  </button></>
                )}

              </form>
              <button onClick={() => setshowResults(!showResults)} className="btn btn-primary float-right">
                {t("pdf")}
              </button>

              <div className="row mt-4">

                <div className="col-12">
                  {showResults ? (
                    <>
                      <div className=" col-12 center"><button className="btn btn-primary" onClick={downloadPDF}>      <i className="fa fa-file-pdf-o " />
                      </button></div>
                      <div style={{ border: '1px solid black', padding: '100px' }}>
                        <ModalRS societe={societe} fournisseur={fournisseur} listrs={listRs} state={state} />
                      </div>
                    </>
                  ) : null}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPaiement;
/*   
{listpaiement.map(item => (
                  <div className="row">
                    <div className="col-2">
                      <div className="form-group">
                        <label>{t("Mode de paiement")}</label>
                        <select className="form-control" name="mode_paiement" required onChange={handleInputChange}>
                          <option>{t("Choisir ...")}</option>
                          <option value="cheque">{t("Chèque")}</option>
                          <option value="virement">{t("Virement")}</option>
                          <option value="traite">{t("Traite")}</option>
                          <option value="espece">{t("Espèce")}</option>
                          <option value="rs">{t("RS")}</option>
                          <option value="autre">{t("Autre")}</option>
                        </select>{" "}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="form-group">
                        <label>{t("Banque")}</label>
                        <select className="form-control" name='banque' >
                          <option value={''} > choisir</option>
                          {listbanque.map((item, index) => (

                            <option key={item.id} value={item.id}>{item.banque}-{item.num_compte}</option>
                          ))}
                          <option value={'tiroire'}>Tiroire</option>
                        </select>
                      </div>
                    </div>



                    <div className="col-2">
                      <div className="form-group">
                        <label>{t("Numéro de paiement")}</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="numero_paiement"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>


                    <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Montant")}</label>
                        <input
                          type="number"
                          className="form-control"
                          required
                          name="montant"
                          onChange={handleInputChange}
                          value={state.montant}
                        />
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Date_écheance")}</label>
                        <input
                          type="date"
                          className="form-control"
                          required
                          name="date_echeance"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>



                  </div>
                ))}*/