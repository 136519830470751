import React, { Component, useState } from "react";
import { ToastContainer } from "react-toastify";
import { addBaremeIrpp } from "./../../Services/Pointeuse/BaremeIrppApi";
import { t } from "i18next";

function AddBaremeIrpp() {
  const [code, setcode] = useState("");
  const [max, setmax] = useState("");
  const [min, setmin] = useState("");
  const [taux, settaux] = useState("");
  const [taux_sup, settaux_sup] = useState("");

  /**
   * It adds a new bareme to the database.
   */
  const handelSave = () => {
    addBaremeIrpp(localStorage.getItem("code_generated"), code, max, min, taux, taux_sup).then((res) => {
      console.log(res);
    });
  };
  return (
    <div
      className="modal fade"
      id="baremIrppModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="baremIrppModalTitle"
      aria-hidden="true"
    >
      <ToastContainer />
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {t("Ajouter barème IRPP")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="form-group">
                <label htmlFor="inputAddress">{t("Code")}</label>
                <input type="text" className="form-control" onChange={(e) => setcode(e.target.value)} />
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{t("max")}</label>
                  <input type="text" className="form-control" onChange={(e) => setmax(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPassword4">{t("min")}</label>
                  <input type="text" className="form-control" onChange={(e) => setmin(e.target.value)} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{t("taux")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    onChange={(e) => settaux(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputPassword4">{t("taux sup")}</label>
                  <input type="text" className="form-control" onChange={(e) => settaux_sup(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {t("Annuler")}
            </button>
            <button type="button" className="btn btn-primary" onClick={handelSave}>
              {t("Enregistrer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBaremeIrpp;
