import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get all retard by user id
 */
export async function getQuestionnaireByUserId(id) {
  try {
    const response = await axios.get(api_url + "questionnaire/userid/" + id, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getQuestionnaireById(id) {
  try {
    const response = await axios.get(api_url + "questionnaire/" + id, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getQuestionnaireByCode() {
  try {
    const response = await axios.get(api_url + "questionnaire/code/" + getSession("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
