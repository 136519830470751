import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getEnteteCommercialVente, getEnteteCommercialVenteDevis, getlistAnneeVente } from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit } from "../../../../../Utils/NumberUtils";
import { sumMontantHT, sumMontantTTC, sumMontantTVA } from "../../../../../Utils/ObjectOperation";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import ExportCsv from "../../../../Exports/ExportCsv";
import ExportPdf from "../../../../Exports/ExportPdf";
import CopieContent from "../../../../Exports/CopieContent";
import { success_message, error_message } from "../../../../../Components/alerte/AlerteMessage";
import moment from "moment";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
function ListDevisVente() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [size, setsize] = useState(40);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [listAnnnees, setListAnnnes] = useState([]);
  const [selectedAnnee, setselectedAnnee] = useState();


  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData(selectedAnnee);
  };


  useEffect(() => {
    getlistAnneeVente()
      .then((res) => {
        setListAnnnes(res.data)

        setselectedAnnee(res.data[0].year)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (listAnnnees.length !== 0) {
      getData(listAnnnees[0].year);

    } else {

      getData(new Date().getFullYear());

    }
  }, [listAnnnees]);

  const getData = (d) => {
    setLoading(true);
    getEnteteCommercialVenteDevis(d)
      .then((res) => {
        const currentYearArray = res.data;

        setdata(currentYearArray);
        setallInitialData(currentYearArray);

      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    console.log('pageOfItems', pageOfItems)
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterFactureByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };





  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };



  // In your parent component
  const updateCurrentData = (newData) => {
    setcurrentData(newData);
  };
  const onChangeDate = () => {
    const filteredData = filterByDate(allInitialData, startDate, endDate);
    updateCurrentData(filteredData);
  };

  const filterByDate = (allInitialData, startDate, endDate) => {
    if (startDate === '' || endDate === '') {
      // Si l'une des dates est vide, ne filtre pas et affiche toutes les données.
      return allInitialData;
    } else {
      const filteredDate = allInitialData.filter((listed) => {
        const momentDate = moment(listed.date.split(" ")[0]);
        if (moment(startDate).isAfter(endDate)) {
          error_message(t("Attention, la date de début doit être antérieure à la date de fin !"));
        } else {
          return (
            momentDate.isSameOrAfter(moment(startDate)) &&
            momentDate.isSameOrBefore(moment(endDate))
          );
        }
      });
      return filteredDate;
    }
  };

  const handleSelectDate = (e) => {
    const val = e.target.value;
    setselectedAnnee(val)
    getData(val);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <select className="btn btn-primary mr-3 " onChange={handleSelectDate} >
                {listAnnnees.map((item, index) => (
                  <option key={index} value={item.year}>devis de vente {item.year}</option>

                ))}

              </select>
              <Link to="/admin/ajoutdevisvente" className="btn btn-primary mr-3">
                <i className="fa fa-plus" />
              </Link>

              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem', margin: "auto" }}>
            <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant HT")}</div>
            <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
              <h5 className="card-title text-success">{sumMontantHT(data)}</h5>
              <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
                <i className="fa fa-money" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem', margin: "auto" }}>
            <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TVA")}</div>
            <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
              <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
              <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
                <i className="fa fa-money" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem', margin: "auto" }}>
            <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TTC")}</div>
            <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
              <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
              <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
                <i className="fa fa-money" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            {/* /.card-header */}

            <div className="row">
              <div className="col-xl-12 col-md-12 col-lg-12">
                <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
                  <div className="card-header  border-0">
                    <h4 className="card-title">{t("Liste des  devis ")}</h4>

                    <div className="card-options">
                      <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                        <i className="fe fe-maximize" />
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="d-flex mb-6 mt-5">
                      <div className="mr-3">
                        <label className="form-label">{t("Note")}:</label>
                      </div>
                      <div>
                        <span className="badge badge-success-light mr-2">
                          <i className="feather feather-check-circle text-success" /> {t("payé")}
                        </span>
                        <span className="badge badge-danger-light mr-2">
                          <i className="feather feather-x-circle text-danger" /> {t("échu")}
                        </span>
                        <span className="badge badge-warning-light mr-2">
                          <i className="fa fa-star text-warning" /> {t("partial")}
                        </span>
                        {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                      </div>
                    </div>

                    {loading && <CustomSyncLoader></CustomSyncLoader>}
                    {!loading && error && <ErrorCustum></ErrorCustum>}
                    {!loading && !error && data && (
                      <div className="table-responsive">
                        <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div className="dataTables_length" id="hr-table_length">
                                <label>
                                  {t("Afficher")}{" "}
                                  <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                    <option value={8}>8</option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={60}>60</option>
                                    <option value={100}>100</option>
                                  </select>{" "}
                                  {t("entrées")}
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div id="hr-table_filter" className="dataTables_filter">
                                <label className="mr-2">
                                  {/* <select className="custom-select m-2 p-2" onChange={handleSelectStaus}>
                              <option value={0}>Tous</option>
                              <option value={1}>En cours</option>
                              <option value={2}>Payée</option>
                              <option value={3}>Partiel</option>
                            </select>

                            <select className="custom-select" onChange={handleSelectDate}>
                              <option value={0}>Année</option>
                              <option value={1}>{new Date().getFullYear()}</option>
                              <option value={2}>{new Date().getFullYear() - 1}</option>
                            </select> */}

                                  <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Recherche..."
                                    aria-controls="hr-table"
                                    onChange={handleSearch}
                                  />
                                  {/* <input
                              className="form-control floating"
                              type="date"
                              onChange={(e) => filterByDate(e.target.value)}
                            /> */}
                                </label>

                                <ExportCsv data={data} name={"List_devis_ventes"} />
                                <ExportPdf
                                  data={data}
                                  name={"List_devis_ventes"}
                                  columns={[
                                    "facture_code",
                                    "client_nom",
                                    "montant_total_ht",
                                    "montant_total_tva",
                                    "montant_total_ttc",
                                    "montant_encaissement",
                                    "net_a_payer",
                                  ]}
                                />
                                <CopieContent />

                                {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                                id="hr-table"
                                role="grid"
                                aria-describedby="hr-table_info"
                              >
                                <thead>
                                  <tr>
                                    <th>{t("N° Facture")}</th>
                                    <th>{t("Client")} </th>
                                    <th>{t("Date")}</th>
                                    <th>
                                      {t("Montant HT")} <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                                    </th>
                                    <th>
                                      {t("Montant TVA")} <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                                    </th>
                                    <th>
                                      {t("Montant TTC")} <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                                    </th>
                                    {/* <th>Statut</th> */}
                                    <th>{t("Date d'échéance")}</th>
                                    <th>{t("Net à payer")}</th>
                                    <th className="text-right">{t("Catégorie")}</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {currentData.map((el) => (
                                    <tr key={el.id}>
                                      <div className="d-flex" >
                                        <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                                        <div className="mt-1 mr-4">
                                          <Link to={"/admin/editerdevisvente/" + el.code}>{el.facture_code}</Link>
                                        </div>
                                      </div>
                                      <td>
                                        <Link to={"/admin/clientactivity/" + el.client_code}>{el.client_nom}</Link>
                                      </td>
                                      <td>{el.date.split(" ")[0] === "2030-01-01" ? "" : el.date.split(" ")[0]}</td>
                                      <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.net_a_payer_ht)}</td>
                                      <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.montant_total_tva)}</td>
                                      <td style={{ textAlign: "right" }}>{el.montant_total_ttc == "0" ? "" : convertToDigit(el.net_a_payer)}</td>
                                      {/* <td>{el.status}</td> */}
                                      {/*<td>{el.date_prevu_livraison.split(" ")[0] === "2030-01-01" ? "" : el.date_prevu_livraison.split(" ")[0]}</td>*/}
                                      <td>{el.date_echeance !== null ? el.date_echeance.split('T')[0] : el.date_prevu_livraison ? el.date_prevu_livraison.split(" ")[0] : el.date_echeance}</td>
                                      <td>{el.net_a_payer}</td>

                                      {/* <td className="project-actions text-center">
                                        <Link to={"/admin/editerdevisvente/" + el.code} className="btn btn-default btn-xs">
                                          <i className="feather feather-edit text-info" />
                                        </Link>
                                      </td> */}
                                      <td className="project-actions text-center">
                                        {true && (
                                          <div className="btn-group">
                                            <button
                                              style={{ textAlign: "center", padding: 2, width: 40 }}
                                              type="button"
                                              className="btn btn-secondary dropdown-toggle dropdown-icon"
                                              data-toggle="dropdown"
                                            ></button>
                                            <div className="dropdown-menu" role="menu">


                                              {/* <div className="dropdown-divider" /> */}
                                              <Link to={"/admin/editerdevisvente/" + el.code} className="dropdown-item">
                                                {t("Editer document")}
                                              </Link>

                                              {el.image ? (
                                                <>
                                                  <Link to={"/admin/facturation/uploaddecharge/" + el.code} className="dropdown-item">
                                                    {t("Voir décharge")}
                                                  </Link>
                                                  {/* <Link to="#" className="dropdown-item" onClick={() => handleRemoveDecharge(el.id)}>
                                              Supprimer décharge
                                            </Link> */}
                                                </>
                                              ) : (
                                                <Link to={"/admin/facturation/uploaddecharge/" + el.code} className="dropdown-item">
                                                  {t("Ajouter décharge")}
                                                </Link>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th>{t("N° Facture")}</th>
                                    <th>{t("Client")} </th>
                                    <th>{t("Date")} </th>
                                    <th>
                                      {t("Montant HT")} <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                                    </th>
                                    <th>
                                      {t("Montant TVA")} <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                                    </th>
                                    <th>
                                      {t("Montant TTC")} <br></br>
                                      <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                                    </th>
                                    {/* <th>Statut</th> */}
                                    <th>{t("Date d'échéance")}</th>
                                    <th>{t("Net à payer")}</th>
                                    <th>{t("Catégorie")}</th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5">
                              <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                                {t("Affichage")} {t("de")} {size} {t("sur")} {data.length} {t("entrées")}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
}

export default ListDevisVente;
