import React, { useEffect, useState } from "react";
import { addFacturationClient } from "../../../Services/Facturation/FacturationClientApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { useHistory } from "react-router-dom"
import { handleKeyPress_number } from "../../../Utils/InputUtils";
import { getAllDevise } from "../../../Services/devise/devise";
import ReactSelect from "react-select";
import { Modal } from "react-bootstrap";
function AddClientModal({show , setShow}) {
  const [stat, setStat] = React.useState("Client");
  const [tarif, setTarif] = React.useState("tarif");
  const [selectedDate, setSelectedDate] = React.useState();

  const [code, setCode] = useState("");
  const [codeBare, setCodeBare] = useState("");
  const [codeActivity, setCodeActivity] = useState("");
  const [magasin, setMagasin] = useState("");
  const [tva, setTva] = useState("");
  const [potentiel, setPotentiel] = useState("");
  const [potentielSoc, setPotentielSoc] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [fixe, setFixe] = useState("");
  const [Mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [rib, setRib] = useState("");
  const [soldeReleve, setSoldeReleve] = useState("");
  const [codeErp, setCodeErp] = useState("");
  const [tax, setTax] = useState("");
  const [adrFacturation, setAdrFacturation] = useState("");
  const [deviseselected, setdevise] = useState("");
  const [exonere, setexonere] = useState(0);

  const [localite, setLocalite] = useState("");
  const [region, setRegion] = useState("");
  const [gouvernorat, setGouvernorat] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [categorie, setCategorie] = useState("");
  const [codeCommercialCategorie, setCodeCommercialCategorie] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [regimeFiscal, setRegimeFiscal] = useState("Réel");
  const [statut, setStatut] = useState("");
  const [matriculeFiscal, setMatriculeFiscal] = useState("");
  const [isReel, setisReel] = useState(false);
  const [commentaire, setCommentaire] = useState("");
  const [image, setImage] = useState("/dist/img/user/user.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [reel, setReel] = useState(0);
  const [client, setClient] = useState("");
  const [fournisseur, setFournisseur] = useState("");
  const [prospect, setProspect] = useState("");
  const [forfitaire, setForfitaire] = useState(0);
  const [remise, setRemise] = useState("");
  const [delaiPaiement, setDelaiPaiement] = useState("");
  const [plafontCredit, setPlafontCredit] = useState("");
  const [plafondCredit, setPlafondCredit] = useState("");
  const [rc, setRc] = useState("");
  const [deviseState, setdeviseState] = useState([])
  const history = useHistory();
  const image_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getAllDevise().then((res) => {
      if (res.status === 200) {
        setdeviseState(res.data.data);
      }
    });
  }, []);
 
  const handleImgClient = (event) => {
    console.log(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  const handleSubmit = () => {
    if (code === '' || codeActivity === '' || adrFacturation === '' || nom === '' || Mobile === '' || matriculeFiscal === '') {
      toast.warning('Entrez toutes les informations requises *')
    } else {
      addFacturationClient(
        code,
        deviseselected,
        exonere,
        codeActivity,
        plafontCredit,
        "Client",
        tarif,
        magasin,
        tva,
        potentiel,
        potentielSoc,
        nom,
        prenom,
        cin,
        email,
        selectedDate,
        fixe,
        Mobile,
        description,
        rib,
        soldeReleve,
        codeErp,
        tax,
        adrFacturation,
        localite,
        region,
        gouvernorat,
        secteur,
        zone,
        categorie,
        codeCommercialCategorie,
        speciality,
        regimeFiscal,
        matriculeFiscal,
        commentaire,
        imageUrl,
        remise,
        delaiPaiement,
        plafondCredit,
        rc, statut
      ).then((res) => {
        if(res.status ===200){
          history.push("/admin/client");

        }

      });
    }
  };

  
  const handleClose = () => setShow(false);

  return (
    <>
     <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajout client</Modal.Title>
        </Modal.Header>
        <Modal.Body> <div className="row " >
        <div className="col-md-12"  >
          <div className="card card-primary">
         
            {/* /.card-header */}
            <div className="card-body">
            <div className="form-group">
                <label>
                  {t("Nom")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setNom(e.target.value)} required />
              </div>
              <div className="row">
              <div className="form-group col-6">
                <label>
                  {t("Email")}
                </label>
                <input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className="form-group col-6">
                <label>
                  {t("Mobile")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onKeyPress={handleKeyPress_number} onChange={(e) => setMobile(e.target.value)} required />
              </div>
              </div>
            
              <div className="form-group">
                <label>
                  {t("Code")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setCode(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  {t("Code Activité")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeActivity(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Matricule fiscal")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMatriculeFiscal(e.target.value)}
                  required
                />
              </div>
              
              <div className="form-group">
                <label>
                  {t("Adresse de facturation")} <span className="text-danger">*</span>
                  <small> {t("maximum 40 caractères")}</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  maxLength="40"
                />
              </div>
              <div className="form-group">
                <label>{t("Devise")}</label><span className="text-danger">*</span>
                <ReactSelect
                  value={deviseState && deviseState.find(item => item.id === deviseselected)}
                  options={deviseState}
                  onChange={(e) => setdevise(e.id)}
                  formatOptionLabel={devise => (
                    <div className="country-option">
                      <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                      <span>{devise.libelle}</span>
                    </div>
                  )}
                />
              </div>
            
            </div>
            {/* /.card-body */}
           
          </div>
          
        </div>
    
       

    
      </div></Modal.Body>
        <Modal.Footer>
        <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2" onClick={()=>setShow(false)}> 
            <i className="fas fa-trash-alt" /> {t("Annuler")}
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> {t("Enregistrer")}
          </button>
        </div>
      </div>
        </Modal.Footer>
      </Modal>
     

    
    </>
  );
}

export default AddClientModal;
