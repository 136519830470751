import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets the document by code.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "string",
 *     "code": "string",
 *     "name": "string",
 *     "description": "string",
 *     "created": "string",
 *     "updated": "string",
 *     "status": "string",
 */
export async function getDocumentByCode() {
  try {
    const response = await axios.get(api_url + "document/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get a document by its ID
 * @param id - The id of the document you want to retrieve.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": "string",
 *   "title": "string",
 *   "content": "string",
 *   "createdAt": "string",
 *   "updatedAt": "string",
 *   "author": {
 *     "id": "string",
 *     "username": "string",
 */
export async function getDocumentById(id) {
  try {
    const response = await axios.get(api_url + "document/id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets all the documents from the collection where the userid is equal to the userid passed in.
 * @param userid - The userid of the user you want to get the documents for.
 * @returns The response is an array of objects. Each object contains the following properties:
 */
export async function getDocumentByUserId(userid) {
  try {
    const response = await axios.get(api_url + "annonce/userid/" + userid, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a document to the database
 * @param file - The file that you want to upload.
 * @param type - The type of document.
 * @param description - The description of the document
 * @returns The response from the server.
 */
export async function addDocument(file, type, description) {
  const frmData = new FormData();

  frmData.append("user_id", getSession("id"));
  frmData.append("code_generated", getSession("code_generated"));
  frmData.append("file", file);
  frmData.append("type", type);
  frmData.append("description", description);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "document", frmData, options);
    toast.success(t("Fichier ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteDocumentById` deletes a document from the database
 * @param id - The id of the document to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteDocumentById(id) {
  try {
    const response = await axios.delete(api_url + "document/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
