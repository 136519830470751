import { Howl } from "howler";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { getSession, isLogin } from "../../Utils/SessionUtils";
import ImageComponent from "../Styles/ImageComponent";
import socket from "../../Connection/ws";
import toast from "react-hot-toast";

/* This is the code to play the notification sound. */
var notifSound = new Howl({
  src: ["./assets/sounds/notif.mp3"],
  loop: false,
  preload: true,
});

/**
 * * When a user is late, the server sends a message to the client.
 * * The client then displays a notification to the user
 */
export function RetardNotification() {
  socket.on("retard", (result) => {
    if (isLogin() && result.code_generated == getSession("code_generated")) {
      notifSound.play();
      getUserById(result.user_id).then((res) => {
        toast.custom(
          (t) => (
            <div className="" style={{ width: 500, borderRadius: "60px", backgroundColor: "#333" }}>
              <div className="p-3">
                <div className="d-flex align-items-center mt-auto" style={{ float: "left" }}>
                  <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={res.data.img}></ImageComponent>
                  <div>
                    <a href="#" className="font-weight-semibold">
                      {res.data.nom} {res.data.prenom}
                    </a>
                    <small className="d-block text-muted fa-12">Retard de {result.time}</small>
                  </div>
                </div>
                <div className="border-l border-gray-200 mb-4" style={{ float: "right" }}>
                  <a href="#" onClick={() => toast.dismiss(t.id)} class="btn btn-pill btn-light">
                    voir
                  </a>
                </div>
              </div>
            </div>
          ),
          { duration: 5000 }
        );
      });
    }
  });
}
