import React, { useEffect, useState } from 'react'
import { getEntrepriseByCode } from '../../../Services/Pointeuse/EntrepriseApi'
import moment from "moment";
import { calcul_total_rub, formatFloat, nombreDeMoisEntreDeuxDates } from '../../../Utils/helper';

export default function FichepaieModel2({ t, employeeData,societe ,prime}) {


    const styles = {
        
        page: {
            flexDirection: 'column',

            border: '2px solid black',
            fontSize: "30px",
            fontColor: 'black'

        },
        fontSizetext : {
            fontSize:'100px !important'
        },
        
        cadre_row: {
            borderBottom: '2px solid black',
            padding: '10px 50px 10px 50px'

        },
        cadre_row_top: {
            width: '100%',
            borderTop: '2px solid black',

        },
        cadre_bottom: {
            width: '100%',

            borderBottom: '2px solid black',

        },
        cadre: {
            border: '2px solid black',
            borderRadius: '10px',
            padding: '0px 50px 10px'

        },
        underline: {
            textDecoration: 'underline',
        },
        thead_border: {
            border: "2px solid black !important"
        }, 
        row :{
            marginLeft: '0px !important'
        }

    };

    return (
        <div  className="  mt-5  " style={{ backgroundColor: 'white',padding: '10px' ,fontSizeAdjust:'10px' }} id='content'>
            <div  className='row' style={styles.cadre_bottom} >
                <div className='col-9'>
                    <strong> {t('Bulletin de Paie')}</strong>
                </div>
                <div  style={{float:'right'}}>
                {employeeData?.mois+'-'+employeeData?.annee}
                </div>


            </div>
            <div style={styles.page} className='mt-5'>
                <div style={styles.cadre_row} >
                    <div className='row' >
                        <div className='col-5' style={styles.cadre}>
                            <strong className='row center mt-2' style={styles.underline}>
                                {t('Employeur')}
                            </strong>
                            <strong className='row mt-2'>
                                {societe?.nom}
                            </strong>
                            <span className='row mt-2'>
                                {societe?.adresse}
                            </span>
                            <span className='row mt-2'>
                                <strong >{t('N° Employeur')} :  {societe?.numero_cnss} </strong> 
                            </span>
                        </div>
                        <div className='col-7' style={{ paddingLeft: '20px' }}>
                            <div className='row'>
                                <strong style={styles.underline}> {t('Employé')}</strong> <strong style={{ marginLeft: '10px' }}> {employeeData?.matricule_user &&employeeData?.matricule_user !== null ? employeeData?.matricule_user?.toString().padStart(4, "0") :employeeData?.user_id?.toString().padStart(4, "0")+'-'}{' '+employeeData?.nom + ' ' + employeeData?.prenom}</strong>
                            </div>
                            <div className='row mt-2'>{employeeData?.adresse}</div>
                            <div className='row mt-2'>
                                <div className='col-4'>
                                    <strong> {t('Categorie')}</strong>
                                </div>
                                <div className='col-4'>
                                    <strong> {t('Echelon')}</strong>
                                </div>
                                <div className='col-4'>
                                    <strong> {t('Echelle')}</strong>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <strong style={styles.underline}> {t('RIB')}</strong> <strong style={{ marginLeft: '10px' }}>{employeeData?.rib} </strong>

                            </div>
                            <div className='row mt-2'>
                                <strong style={styles.underline}> {t('N° CNSS')}</strong> <strong style={{ marginLeft: '10px' }}> {employeeData?.cnss}</strong>
                            </div>
                            <div className='row mt-2 ' style={{ float: 'right' }}>
                                <strong > {t('Date')}</strong> <p style={{ marginLeft: '10px' }}> {moment(new Date(employeeData?.date_embauche)).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className='row mt-2 ' >
                                <strong > {t("Date d'embauche")}</strong> <p style={{ marginLeft: '10px' }}> {moment(new Date(employeeData?.date_embauche)).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className='row mt-2 ' >
                                <strong > {t("Date 1ére Déclaration CNSS")}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <table className="table table-vcenter " >
                        <thead style={{ backgroundColor: "#D3D6D9", borderBottom: '1px solide black' }}>
                            <th><strong style={{ color: 'black' ,fontSize:'20px' }}>{t('Qualification')}</strong></th>
                            <th><strong style={{ color: 'black' ,fontSize:'20px' }}>{t('Profil')}</strong></th>
                            <th><strong style={{ color: 'black' ,fontSize:'20px' }}>{t('Situation Familiale')}</strong></th>
                            <th><strong style={{ color: 'black',fontSize:'20px'  }}>{t('Chef de famille')}</strong></th>
                            <th><strong style={{ color: 'black' ,fontSize:'20px' }}>{t(' Parent')}</strong></th>
                            <th><strong style={{ color: 'black' ,fontSize:'20px' }}>{t('enf charge')}</strong></th>
                            <th><strong style={{ color: 'black',fontSize:'20px'  }}>{t('Nb Jrs Ouv')}</strong></th>
                            <th><strong style={{ color: 'black',fontSize:'20px'  }}>{t('Nb Jrs Trav')}</strong></th>
                            <th><strong style={{ color: 'black',fontSize:'20px'  }}>{t('S.BASE')}</strong></th>

                        </thead>

                        <tbody>
                            <tr>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.Qualification}</p></td>
                                <td><p style={{ color: 'black' ,fontSize:'20px' }}>-</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.situation_famille}</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.chef_famille === 1 ? t('Oui') : t('Non')}</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.Parent_charge === 1 ? t('Oui') : t('Non')}</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.totalenfant}</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.JT}</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{employeeData?.total}</p></td>
                                <td ><p style={{ color: 'black' ,fontSize:'20px' }}>{formatFloat(employeeData?.salaire_base)}</p></td>


                            </tr>
                        </tbody>
                    </table>

                </div>
                <div  >
                    <table className="table table-color_black table-vcenter text-nowrap" id="hr-attendance">


                        <tbody style={{minHeight:'1000px'}}>

                            <tr>
                                <th  ><strong >{t('Désignation')}</strong></th>
                                <th colSpan="2"><strong>{t('Nombre ou Base')}</strong></th>
                                <th colSpan="2"><strong>{t('Part Salariale')}</strong></th>
                                <th colSpan="2"><strong>{t('Part Patronale')}</strong></th>

                            </tr>


                            <tr>
                                <th></th>
                                <th>{t('Nbr')}</th>
                                <th>{t('Montant')}</th>
                                <th>{t('Montant')}</th>
                                <th>{t('Ret.Sal')}</th>
                                <th>{t('Taux Pat')}</th>
                                <th>{t('Ret.Pat')}</th>
                            </tr>
                            <tr >
                                <td >{(prime !== null && !prime?.moisplus) ? (t('Prime')):t('Salaire de Base')}</td>
                                <td >{formatFloat(employeeData?.total)}</td>
                                <td >{formatFloat(employeeData?.salaire_journee)}</td>
                                <td >{formatFloat(employeeData?.salaire_base_calculer)} </td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>

                            {employeeData?.rubs_indemnite && employeeData.rubs_indemnite.length !== 0 && employeeData.rubs_indemnite.map((item, index) => (
                                <tr key={index}>

                                    <td>{item?.libelle}</td>
                                    <td>{item?.presence === 1 ? employeeData.total : null}</td>
                                    <td></td>
                                    <td>{item?.presence === 1 ?formatFloat(formatFloat(item.valeur_mensuel) * employeeData.total ) : formatFloat(item.valeur_mensuel)}</td>
                                    <td></td>
                                    <td></td>

                                    <td></td>
                                </tr>
                            ))}
                            {employeeData?.avantages && employeeData.avantages.length !== 0 && employeeData.avantages.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.libelle}</td>
                                    <td>{item.presence === 1 ? formatFloat(employeeData.total ): null}</td>
                                    <td></td>
                                    <td>{item?.presence === 1 ? formatFloat(item.valeur_mensuel * employeeData.total) : formatFloat(item.valeur_mensuel)}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            ))}
                         
                            <tr >

                                <td><strong>{t('Total Brut')}</strong></td>
                                <td></td>
                                <td></td>
                                <td><strong>{formatFloat(employeeData?.total_brut)}</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>


                            </tr>
                            <tr >

                                <td>{t('CNSS')}</td>
                                <td></td>
                                <td></td>
                                <td>{formatFloat(employeeData?.brut_cnss)}</td>
                                <td>{formatFloat(employeeData?.rs_cnss)}</td>
                                <td>{formatFloat(employeeData?.CP)}</td>
                                <td>{formatFloat((employeeData?.CP * employeeData?.brut_cnss)/100)}</td>

                            </tr>
                            <tr >

                                <td>{t('salaire imposable')}</td>
                                <td></td>
                                <td></td>
                                <td>{formatFloat(employeeData?.salaire_imposable)}</td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr >

                                <td>{t('IRPP')}</td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td>{formatFloat(employeeData?.calcul_irpp_mois)}</td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr >

                                <td>{t('Retenue à la source (CSS) ')}</td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td>{formatFloat(employeeData?.unp)}</td>
                                <td></td>
                                <td></td>

                            </tr>
                            {employeeData?.rubs_retenue && employeeData.rubs_retenue.length !== 0 && employeeData.rubs_retenue.map((item, index) => (
                                <tr key={index}>

                                    <td>{item.libelle}</td>
                                    <td>{item.presence === 1 ?formatFloat(employeeData.total)  : null}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{formatFloat(calcul_total_rub(item.valeur_mensuel , item?.presence , employeeData.total ,item.valeur_annuel  ,nombreDeMoisEntreDeuxDates(item?.date_debut, item?.date_fin)))}</td>


                                    <td></td>
                                    <td></td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div >
                    <div className='row '  style={{paddingLeft:'10px'}}>
                        <div className='col-8 ' style={styles.cadre_row_top} >
                      
                            <div  style={{borderBottom:'2px solide black'}}>
                                <strong>{t('Net à payer')}{' '}{formatFloat(employeeData?.net_a_payer)}</strong>
                            </div>
                            <div  style={styles.cadre_row_top} className='mt-8'>
                                <strong>{t('Signature')}</strong>
                            </div>
                        </div>
                        <div className='col-4'>
                            <table className="table table-color_black table-vcenter text-nowrap" id="hr-attendance">
                                <tbody>
                                    <th>
                                        <strong >{t('Congé')}</strong>
                                    </th>
                                    <th>
                                        <strong>{employeeData?.date?.split('-')[0]}</strong>
                                    </th>
                                    <tr>
                                        <td> <strong>Acquis</strong></td>
                                        <td> <strong>{employeeData?.acquisconge <0 ?  '' :employeeData?.acquisconge }</strong></td>
                                    </tr>
                                    <tr>
                                        <td> <strong>{t('Ordinaire')}</strong></td>
                                        <td> <strong></strong></td>
                                    </tr>
                                    <tr>
                                        <td> <strong>{t('Spécial')}</strong></td>
                                        <td> <strong></strong></td>
                                    </tr>
                                    <tr>
                                        <td> <strong>{t('Solde')}</strong></td>
                                        <td> <strong>{employeeData?.soldeconge <0 ? '' :employeeData?.soldeconge}</strong></td>
                                    </tr>
                                </tbody>
                                </table>

                                </div>
                        </div>

                    </div>

                </div>
            </div>
            )
}
