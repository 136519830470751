import React, { useEffect, useState } from 'react'
import { getExercice } from '../../../Services/Pointeuse/EntrepriseApi';

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import Form from 'react-bootstrap/Form';
import ReactSelect from "react-select";
import { LAYER } from 'bizcharts';
import { handleKeyPress_number } from '../../../Utils/InputUtils';
import { addParametragePaie, addparam_rub_paie, getparam_paie, getsouche_rub, updateparam_paie, updateparam_rub_paie } from '../../../Services/paie/parametrage_paie';
import { toast } from 'react-toastify';
import { generateUniqueCode } from '../../../Utils/helper';
import { IoRefresh } from "react-icons/io5";

export default function ParamRubriquePaie({ objdata, getData }) {
    const [modif, setmodif] = useState(false)
    const [data, setdata] = useState(

        {
            code: '',
            code_societe: localStorage.getItem('code_generated'),
            libelle: '',
            presence: 0,
            cnss: 0,
            irpp: 0,
            plafond: 0,
            montant_plafond: '',
            compte_comptable: '',
            type: 'indemnite'
        }


    )

    useEffect(() => {

        if (objdata && objdata?.id) {
            setmodif(true)
            setdata({
                id: objdata.id,
                code: objdata.code,
                code_societe: localStorage.getItem('code_generated'),
                libelle: objdata.libelle,
                presence: objdata.presence,
                cnss: objdata.cnss,
                irpp: objdata.irpp,
                plafond: objdata.plafond,
                montant_plafond: objdata.montant_plafond,
                compte_comptable: objdata.compte_comptable,
                type: objdata.type
            })
        } else {
            souche()

        }
    }, [objdata])

    const souche = () => {
        getsouche_rub().then((res) => {

            if (res?.data) {
                if (res.data.last_code) {
                    const lastsouche = res.data.last_code.split(res.data.seq).pop();

                    setdata(prev => ({
                        ...prev,
                        code: `${res.data.seq}${parseInt(lastsouche) + 1}`,

                    }));
                }
                else {
                    setdata(prev => ({
                        ...prev,
                        code: `${res.data.seq}1`,

                    }));
                }
            }
        })
    }

    const handelchange = (name, value) => {
        const copiedata = { ...data }
        if (name === 'plafond') {
            if (value === 0) {
                copiedata[name] = value;
                copiedata.montant_plafond = null;

            } else {
                copiedata[name] = value;

            }
        }
        else {
            if (name === 'type') {
                if (value !== 'indemnite') {
                    copiedata.cnss = 0;
                    copiedata.irpp = 0;
                    copiedata.presence = value === 'avantage_fiscaux' ? 0 : copiedata.presence
                }
            }
            copiedata[name] = value

        }
        setdata(copiedata)

    }
    const handelsave = () => {
        const newdata = { ...data };
        newdata.montant_plafond = parseFloat(data.montant_plafond)
        if (data && data?.id) {

            updateparam_rub_paie(newdata).then((res) => {
                if (res.status === 200) {
                    toast.success(t("parametrage est modifié"))

                    setdata({

                        code_societe: localStorage.getItem('code_generated'),
                        libelle: '',
                        presence:0,
                        cnss: 0,
                        irpp: 0,
                        plafond: 0,
                        montant_plafond: '',
                        compte_comptable: '',
                        type: 'indemnite'

                    });
                    souche();

                    getData();
                }
            });
        } else {
            addparam_rub_paie(newdata).then((res) => {
                if (res.status === 201) {
                    toast.success(t("parametrage est enregistrée"))

                    setdata({
                        code_societe: localStorage.getItem('code_generated'),
                        libelle: '',
                        presence: 0,
                        cnss: 0,
                        irpp: 0,
                        plafond: 0,
                        montant_plafond: '',
                        compte_comptable: '',

                    });
                    souche()
                    getData();
                }
            });
        }


    }
    const viderobj = () => {
        souche()
        setdata((prev)=>({
           ...prev,
            code_societe: localStorage.getItem('code_generated'),
            libelle: '',
            presence: 0,
            cnss: 0,
            irpp: 0,
            plafond: 0,
            montant_plafond: '',
            compte_comptable: '',
            type: 'indemnite'
        })
        )
    }
    return (
        <div>


            <div className="row">
                <div className="col-xl-12">
                    <div className="tab-content adminsetting-content" id="setting-tabContent">
                        <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
                            <div className="card">

                                <div className="card-body">
                                    <div className="btn btn-primary float-md-right" onClick={viderobj}><IoRefresh /></div>
                                    <div className='row mt-5'>
                                        <div className='col-4'>
                                            <label>{t('Code')}</label>
                                            <input type='text' className="form-control" value={data.code} readOnly />
                                        </div>

                                        <div className='col-4'>
                                            <label>{t('Libelle')}</label>
                                            <input type='text' readOnly={modif} className="form-control" value={data.libelle} onChange={(e) => handelchange('libelle', e.target.value)} />
                                        </div>
                                        <div className='col-4'>
                                            <label>{t('Compte comptable')}</label>
                                            <input type='text' readOnly={modif} className="form-control" value={data.compte_comptable} onChange={(e) => handelchange('compte_comptable', e.target.value)} />
                                        </div>

                                    </div>
                                    <div className='row ml-5 mt-5'>



                                        <div className='col-6' >
                                            <div className='row'>
                                                <label className='col-6'>{t('CNSS')}</label>
                                                <input className='col-1' disabled={ modif || data.type !== 'indemnite'} type='checkbox' checked={data.cnss} onClick={(e) => handelchange('cnss', data.cnss === 0 ? 1 : 0)} />
                                            </div>
                                            <div className='row mt-3'>
                                                <label className='col-6'>{t('IRPP')}</label>
                                                <input className='col-1' disabled={modif || data.type !== 'indemnite'} type='checkbox' checked={data.irpp} onClick={(e) => handelchange('irpp', data.irpp === 0 ? 1 : 0)} />
                                            </div>
                                            <div className='row mt-3'>
                                                <label className='col-6'>{t('TAUX DE PRESENCE')}</label>
                                                <input className='col-1' disabled={modif || data.type === 'avantage_fiscaux'} type='checkbox' checked={data.presence} onClick={(e) => handelchange('presence', data.presence === 0 ? 1 : 0)} />
                                            </div>




                                        </div>
                                        <div className='col-6 '>
                                            <div className='row'>
                                                <label className='col-6'>{t('PLAFOND')}</label>
                                                <input className='col-1' readOnly={modif} type='checkbox' checked={data.plafond} onClick={(e) => handelchange('plafond', data.plafond === 0 ? 1 : 0)} />
                                            </div>
                                            {data.plafond === 1 &&
                                                <div className='row mt-3'>
                                                    <label className='col-6'>{t('Montant Plafond')}</label>
                                                    <input className="form-control col-6" readOnly={modif} onKeyPress={handleKeyPress_number} type='text' value={data.montant_plafond} onChange={(e) => handelchange('montant_plafond', e.target.value)} />
                                                </div>
                                            }
                                            <div className='row mt-3'>
                                                <label className='col-4'>{t('TYPE')}</label>
                                                <select className="form-control col-7" readOnly={modif} value={data.type} onChange={(e) => handelchange('type', e.target.value)}>
                                                    <option value='indemnite'>
                                                        {t('indemnite')}
                                                    </option>
                                                    <option value='retenue'>
                                                        {t('retenue')}
                                                    </option>
                                                    <option value='avantage_fiscaux'>
                                                        {t('avantage_fiscaux')}
                                                    </option>
                                                </select>

                                            </div>

                                        </div>
                                    </div>




                                </div>
                                <div className="card-footer float-md-right">
                                    {modif ? (
                                        <Link to="#" onClick={() => setmodif(false)} className="btn btn-primary float-md-right">
                                            {t("Modifier")}
                                        </Link>
                                    ) :
                                        <Link to="#" onClick={handelsave} className="btn btn-primary float-md-right">
                                            {t("Enregistrer")}
                                        </Link>}

                                    {/* <a href="#" className="btn btn-danger">
                  Cancel
                </a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
