import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './DepartementSupport'
import { getAllClientsparType } from '../../Services/Facturation/FacturationClientApi';
import ClientModal from './ClientModal';
import AjoutDeplacement from '../Deplacement/AjoutDeplacement';
import AjouterClient from './AjouterClient';
function Table({ data, onDataChange, onAddRow, onSaveRow, title }) {
    const savedRows = {}; // Assuming you have the logic to populate savedRows 
    const [clients, setClients] = useState([]);
    const [activeRowIndex, setActiveRowIndex] = useState(null);
    const [clientModalOpen, setClientModalOpen] = useState(false);
  
    // const handleClientSelect = (selectedClient) => {
    //   console.log("jjjjjjjj",selectedClient)
    //   // Set the selected client in the active row
    //   onDataChange('client', selectedClient.nom, activeRowIndex);
    //   // Close the client modal
    //   setClientModalOpen(false);
    // };
    const handleClientSelect = (selectedClient, rowIndex) => {
      console.log("Selected client:", selectedClient);
    
      // Check if rowIndex is valid
      if (rowIndex !== null && rowIndex >= 0 && rowIndex < data.length) {
        console.log("Selected row index:", rowIndex);
        // Set the selected client in the active row
        onDataChange('client', selectedClient.nom, rowIndex);
        // Close the client modal
        setClientModalOpen(false);
      } else {
        console.error("Invalid rowIndex:", rowIndex);
      }
    };
    
    
    const handleClientInputClick = (rowIndex) => {
      setActiveRowIndex(rowIndex);
      // Open the client modal
      setClientModalOpen(true);
      console.log("Active row index set to:", rowIndex);
    };
    
    
    
    useEffect(() => {
      // Fetch the list of clients when the component mounts
      async function fetchClients() {
        try {
          const response = await getAllClientsparType();
          setClients(response.data);
         } catch (error) {
          console.error('Erreur lors de la récupération des clients :', error);
        }
      }
  
      fetchClients();
    }, []);
    
    return (
      <div className="table-responsive1">
        <table
          className="table"
          id="invoice-tables"
        >
          <thead>
            <tr>
              <th>Client</th>
              <th>Nombre d'utilisateurs</th>
              <th>Projet</th>
              <th>Janvier</th>
              <th>Février</th>
              <th>Mars</th>
              <th>Avril</th>
              <th>Mai</th>
              <th>Juin</th>
              <th>Juillet</th>
              <th>Août</th>
              <th>Septembre</th>
              <th>Octobre</th>
              <th>Novembre</th>
              <th>Décembre</th>
              <th>Année</th>
            </tr>
          </thead>
          <tbody>
            {data.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object.entries(rowData).map(([field, value], index) => {
                  if (field !== 'id') {
                    return (
                      <td key={index}>
                        {field === 'client' && (
                          <>
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                            onClick={() => handleClientInputClick(rowIndex)}
                          />
                          {/* <ClientModal onClose={handleCloseClientModal}   /> */}
                          {/* <AjouterClient onSelect={(selectedClient) => handleClientSelect(selectedClient, activeRowIndex)} onDataChange={onDataChange} setClientModalOpen={setClientModalOpen}/> */}
                          <AjouterClient onSelect={(selectedClient) => handleClientSelect(selectedClient, rowIndex)} />
                          </>
                        )}
                       {/* {field === 'client' && (
                      <>
                        <input
                          type="text"
                          value={selectedClients[rowIndex] || ''} // Use selected client or empty string
                          onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                          disabled={savedRows[title]?.includes(rowData)}
                          onClick={() => openClientModal(rowIndex)} // Open modal on input click
                        />
                      </>
                    )} */}
                        {field === 'nombre_user' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'projet' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'janvier' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'fevrier' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'mars' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'avril' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'mai' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'juin' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'juillet' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'aout' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'septembre' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'octobre' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'novembre' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'decembre' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {field === 'annee' && (
                          <input
                            type="text"
                            value={value}
                            onChange={(e) => onDataChange(field, e.target.value, rowIndex)}
                            disabled={savedRows[title]?.includes(rowData)}
                          />
                        )}
                        {/* Continue this pattern for other fields */}
                      </td>
                    );
                  }
                  return null; // Exclude 'id' field
                })}
                  <td>
                  {rowIndex === data.length - 1 &&  (  
                      <>
                    <Link
                      to="#"
                      className="text-success font-18 mr-2"
                      title="Add"
                      onClick={() => onAddRow(rowIndex)}
                    >
                      <i className="fa fa-plus" />
                    </Link>
                    <Link
                      to="#"
                      className="text-primary font-18 mr-2"
                      title="Save"
                      onClick={() => onSaveRow(title, rowIndex)}
                    >
                      <i className="fa fa-save" />
                    </Link>
                    </>
                  )}
                </td>
              </tr>
            ))}
            </tbody> 
        </table>
        
      </div>
    );
  }
  
export default Table;