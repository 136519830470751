import { toast } from "react-toastify"

export const success_message = (message) =>{
    return toast(message,{type:"success",position:"top-right"})
}
export const error_message = (message) =>{
    return toast(message,{type:"error",position:"top-right"})
}
export const warning_message = (message) =>{
    return toast(message,{type:"warning",position:"top-right"})
}