import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";

function SectionTwo() {
  return (
    <>
      <div className="home__hero-section">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">À PROPOS CHECKIN</div>
                <h1 className="heading dark">
                  Choisissez la solution de pointeuse mobile la plus pratique, la plus facile à utiliser et la plus
                  simple à paramétrer.
                </h1>
                <p className="home__hero-subtitle dark">
                  Elle facilite à la fois le quotidien des employés et des ressources humaines. Convient parfaitement
                  aux entreprises ayant des impératifs de mobilité ou de personnel itinérant. L’application envoie
                  automatiquement les heures d’entrée, de pause et de sortie à vos feuilles de temps. Très pratique,
                  plus précis,La manière de suivre les heures de travail a évolué.
                </p>
                {/* <Link to="/sign-up">
                  <Button buttonSize="btn--wide" buttonColor="blue">
                    kooo
                  </Button>
                </Link> */}
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src="assets/images/welcome/svg-7.svg" alt="about" className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionTwo;
