/**
 * Substracts a percentage from a value
 * @param value - The value to be processed.
 * @returns the value of the input value minus the value of the input value divided by 100 multiplied
 * by 1.
 */
export function soustractPercent(value) {
  if (value) {
    let result = (Number(value) / 100) * 1;
    return Number(value - result);
  } else {
    return 0;
  }
}

/**
 * Given a value, return a number that is the value divided by 100
 * @param value - The value of the slider.
 * @returns a number that is the result of the division of the value by 100.
 */
export function getPercent(value) {
  if (value) {
    return (Number(value) / 100) * 1;
  } else {
    return 0;
  }
}
