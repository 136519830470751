/**
 * Validate that the value is a number less than 23
 * @param value - The value of the input field.
 * @returns Nothing.
 */
export function validateHoure(value) {
  return Number(value) < 24;
}

/**
 * *The function takes a value and returns a boolean.*
 * @param value - The value of the input.
 * @returns Nothing.
 */
export function validateDay(value) {
  return Number(value) < 8;
}

/**
 * Validate that the time is in the correct format
 * @param time - The time to validate.
 * @returns a boolean value.
 */
export function validateTime(time) {
  const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(time);
  return timeReg;
}
