import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSearchData } from "../Utils/Data/SearchData";

function Search(props) {
  const [searchText, setsearchText] = useState(props.location.state);
  const [data, setdata] = useState(null);
  useEffect(() => {
    const filtred = getSearchData();
    const getFilter = filtred.filter((el) => {
      return el.keyword.toLowerCase().includes(props.location.state.toLowerCase());
    });
    setdata(getFilter);
  }, [props.location.state]);

  /**
   * This function is used to filter the data based on the search text
   * @param value - The value of the search input.
   */
  const handleChangeSearch = (value) => {
    setsearchText(value);
    const filtred = getSearchData();
    const getFilter = filtred.filter((el) => {
      return el.keyword.toLowerCase().includes(value.toLowerCase());
    });
    setdata(getFilter);
  };

  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Recherche</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body pb-0">
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Searching....."
                  value={searchText}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
                {/* <span className="input-group-append">
                  <button className="btn ripple btn-primary">Search</button>
                </span> */}
              </div>
              <div className="tabs-menu1">
                <ul className="nav panel-tabs">
                  <li>
                    <a href="#tab5" className="active" data-toggle="tab">
                      Tous
                    </a>
                  </li>
                  {/* <li>
                    <a href="#tab6" data-toggle="tab" className="text-dark">
                      Images
                    </a>
                  </li>
                  <li>
                    <a href="#tab7" data-toggle="tab" className="text-dark">
                      Books
                    </a>
                  </li>
                  <li>
                    <a href="#tab8" data-toggle="tab" className="text-dark">
                      News
                    </a>
                  </li>
                  <li>
                    <a href="#tab9" data-toggle="tab" className="text-dark">
                      Videos
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="card-body p-3">
              <p className="text-muted mb-0 pl-3">Environ {data?.length} résultats (0.56 Secondes)</p>
            </div>
          </div>

          {data &&
            data.map((el, idx) => (
              <div key={idx} className="card custom-card">
                <div className="card-body">
                  <div className="mb-2">
                    <Link to={el.path} className="h4 text-dark" target="_blank" rel="noopener noreferrer">
                      {el.keyword}
                    </Link>
                  </div>
                  <Link to={el.path} className="text-primary" target="_blank" rel="noopener noreferrer">
                    {el.path}
                  </Link>
                  <p className="text-muted mt-2 mb-2">
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
                    magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                    quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                  {/* <div>
                    <Link to="#">
                      <i className="fa fa-star text-yellow fs-16" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-star text-yellow fs-16" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-star text-yellow fs-16" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-star text-yellow fs-16" />
                    </Link>
                    <Link to="#">
                      <i className="fa fa-star-o text-yellow fs-16" />
                    </Link>
                    <Link to="#" className="mr-4">
                      {" "}
                      (48) Reviews
                    </Link>
                    <Link to="#" className="font-weight-semibold">
                      USD-$24
                    </Link>
                  </div> */}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Search;
