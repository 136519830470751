import React, { useEffect, useRef, useState } from "react";
import { ajouterConge, getsoldecongeuser, getTypeCongeByCode } from "../../Services/Pointeuse/CongeApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { t } from "i18next";
import swal from "sweetalert";

function AddConge(props) {
  const [libelle, setlibelle] = useState("");
  const [date, setdate] = useState("");
  const [nbjour, setnbjour] = useState("");
  const [type, settype] = useState("CP");
  const myRefDelete = useRef(null);
  const [users, setusers] = useState(null);
  const [userId, setuserId] = useState(null);
  const [motif, setmotif] = useState();
  const [affect_solde, setaffect_solde] = useState();
  const [code_motif, setcode_motif] = useState();
  const [exercice, setExercice] = useState(props?.exercice);
  const [solde, setSolde] = useState([]);

  const [listType_conge, setlistType_conge] = useState([]);


  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res?.data);
    });
  }, []);

  useEffect(() => {
    getTypeCongeByCode().then((res) => {
      setlistType_conge(res.data);
      setmotif(res.data[0].libelle)
      setaffect_solde(res.data[0].affect_solde)
      setcode_motif(res.data[0].code_conge)
    });
  }, []);
  const changeCongeType = (codeConge) => {
    const findcongetype = listType_conge.find(item => item.code_conge === codeConge)
    if (findcongetype) {
      setmotif(findcongetype.libelle)
      setaffect_solde(findcongetype.affect_solde)
      setcode_motif(findcongetype.code_conge)
    }

  }


  /**
   * It adds a new conge to the database.
   */

  const handelSave = () => {

    swal({
      title: t("Voulez vous vraiment cree ce congé ?"),
      text: (Number(nbjour) > Number(solde)) ? `le solde de ce collaborateur est ${solde} ` : t(""),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ajouterConge(userId, nbjour, date, motif, code_motif, affect_solde).then((res) => {
          //console.log(res);
          setuserId("0")
          setnbjour('')
          setdate()

          myRefDelete.current.click();
          props.getData();
        });
      }
    });

  };
  useEffect(() => {
    getSoldeCongeByuser()
  }, [userId])
  const getSoldeCongeByuser = () => {
    getsoldecongeuser(exercice, userId).then(res => {

      if (res.data.length !== 0) {

        setSolde(res.data[0].solde)

      }
    })
  }
  return (
    <div
      className="modal fade"
      id="addCongeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addCongeModalolidayModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {t("Ajouter Congé")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>
                  {t("Collaborateur")}<span className="text-danger">*</span>
                </label>

                <select onChange={(e) => setuserId(e.target.value)} className="selectpicker form-control">
                  <option value="0">{t("Choisir un collaborateur")}</option>
                  {users &&
                    users.map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.nom} {el.prenom}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">{t("Date")}</label>
                <input type="date" className="form-control" onChange={(e) => setdate(e.target.value)} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputPassword4">{t("Nombre des jours")}</label>
                <input type="number" className="form-control" onChange={(e) => setnbjour(e.target.value)} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>
                  {t("Motif")}<span className="text-danger">*</span>
                </label>
                {listType_conge && listType_conge.length !== 0 && (
                  <select onChange={(e) => changeCongeType(e.target.value)} className="selectpicker form-control">
                    {listType_conge.map((item, index) => (
                      <option key={index} value={item.code_conge}>{item.libelle}</option>

                    ))}
                  </select>
                )}

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
              {t("Annuler")}
            </button>
            <button type="button" className="btn btn-primary" onClick={handelSave}>
              {t("Ajouter")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConge;
/*     <select onChange={(e) => setmotif(e.target.value)} className="selectpicker form-control">
                  <option value={"CONGÉ PAYÉ"}>{t("CONGÉ PAYÉ")}</option>
                  <option value={"CONGÉ SANS SOLDE"}>{t("CONGÉ SANS SOLDE")}</option>
                  <option value={"CONGÉ ANNUEL"}>{t("CONGÉ ANNUEL")}</option>
                  <option value={"CONGÉ D'EXAMEN"}>{t("CONGÉ D'EXAMEN")}</option>
                  <option value={"CONGÉ INDIVIDUEL DE FORMATION"}>{t("CONGÉ INDIVIDUEL DE FORMATION")}</option>
                  <option value={"CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE"}>
                    {t("CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE")}
                  </option>
                  <option value={"CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE"}>{t("CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE")}</option>
                  <option value={"CONGÉ MALADIE"}>{t("CONGÉ MALADIE")}</option>
                  <option value={"CONGÉ MATERNITÉ"}>{t("CONGÉ MATERNITÉ")}</option>
                  <option value={"CONGÉ PATERNITÉ"}>{t("CONGÉ PATERNITÉ")}</option>
                  <option value={"CONGÉ POUR CATASTROPHE NATURELLE"}>{t("CONGÉ POUR CATASTROPHE NATURELLE")}</option>
                  <option value={"CONGÉ SABBATIQUE"}>{t("CONGÉ SABBATIQUE")}</option>
                  <option value={"CONGÉ POUR RAISON FAMILIALE"}>{t("CONGÉ POUR RAISON FAMILIALE")}</option>
                  <option value={"Autre"}>{t("Autre")}</option>
                </select>*/