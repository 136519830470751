import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addContrat, deleteContratByUserId, getContratByUserId } from "../../Services/Pointeuse/ContratApi";
import { useAlert } from "react-alert";
import { t } from "i18next";

function AddContrat({ idUser }) {
  const alert = useAlert();
  const [inputList, setInputList] = useState([{ type: "", debut: "", fin: "" }]);

  useEffect(() => {
    getContratByUserId(idUser).then((res) => {
      const options = res.data.map((d) => ({
        type: d.type,
        debut: d.date_embauche,
        fin: d.date_fin_contrat,
      }));
      console.log(options);
      console.log(inputList);
      console.log(inputList.length);
      if (options.length > 0) {
        setInputList(options);
      } else {
        setInputList([{ type: "", debut: "", fin: "" }]);
      }
    });
  }, [idUser]);

  /**
   * It takes in an event and an index and sets the name and value of the input at that index to the
   * value of the event's target.
   * @param e - The event object.
   * @param index - The index of the input in the list.
   */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    console.log(list);
    setInputList(list);
  };

  /**
   * It changes the type of the input in the input list.
   * @param e - The event object.
   * @param index - The index of the input in the inputList array.
   */
  const handleSelectChange = (e, index) => {
    const { value } = e.target;
    let position = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[position].text;
    console.log(value);
    const list = [...inputList];
    console.log(list);
    list[index]["type"] = value;
    setInputList(list);
  };

  /**
   * *Remove the item at the given index from the input list.*
   * @param index - The index of the item to remove.
   */
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  /**
   * Add a new input to the list of inputs
   */
  const handleAddClick = () => {
    setInputList([...inputList, { type: "", debut: "", fin: "" }]);
  };

  /**
   * It deletes the user from the database and then adds the new contracts.
   */
  const handleSave = () => {
    deleteContratByUserId(idUser).then(() => {
      alert.success("Contrat ajouté");
      inputList.forEach((element) => {
        //console.log(element);
        addContrat(idUser, element.type, element.debut, element.fin);
      });
    });
  };
  return (
    <>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table nowrap text-nowrap border mt-5">
            <thead>
              <tr>
                <th>{t("Type Contrat")}</th>
                <th className="w-40">{t("Date d'embauche")}</th>
                <th>{t("Date fin contrat")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inputList.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {/* {" "}
                      <input
                        className="form-control"
                        name="type"
                        placeholder="Enter First Name"
                        value={x.type}
                        onChange={(e) => handleInputChange(e, i)}
                        type="text"
                      /> */}

                      <select
                        className="form-control custom-select select2"
                        aria-label="Default select example"
                        value={x.type}
                        onChange={(e) => handleSelectChange(e, i)}
                      >
                        <option selected>{t("Choisir type contrat")}</option>
                        <option value={"gerant"}>{t("Gérant")}</option>
                        <option value={"sivp"}>{t("SIVP")}</option>
                        <option value={"karama"}>{t("Karama")}</option>
                        <option value={"cdi"}>{t("CDI")}</option>
                        <option value={"cdd"}>{t("CDD")}</option>
                      </select>
                    </td>
                    <td>
                      {" "}
                      <input
                        className="form-control"
                        name="debut"
                        placeholder="Enter Last Name"
                        value={moment(x.debut).format("YYYY-MM-DD")}
                        onChange={(e) => handleInputChange(e, i)}
                        type="date"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        className="form-control"
                        name="fin"
                        placeholder={t("Enter Last Name")}
                        value={moment(x.fin).format("YYYY-MM-DD")}
                        onChange={(e) => handleInputChange(e, i)}
                        type="date"
                      />
                    </td>

                    <td>
                      {inputList.length !== 1 && (
                        <Link className="btn btn-danger mr-2" to="#" onClick={() => handleRemoveClick(i)}>
                          <i className="fe fe-minus" />
                        </Link>
                      )}
                      {inputList.length - 1 === i && (
                        <Link className="btn btn-success" to="#" onClick={handleAddClick}>
                          <i className="fe fe-plus" />
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td>
                  <input className="form-control" placeholder type="text" />
                </td>
                <td>
                  <input className="form-control" placeholder type="date" />
                </td>
                <td>
                  <input className="form-control" placeholder type="date" />
                </td>

                <td>
                  <Link className="btn btn-success mr-2" to="#">
                    <i className="fe fe-plus" />
                  </Link>
                  <Link className="btn btn-danger" to="#">
                    <i className="fe fe-minus" />
                  </Link>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col">
            {inputList[0]?.type && inputList[0]?.debut && inputList[0]?.fin && (
              <Link to="#" className="btn btn-success" onClick={handleSave}>
                <i className="fe fe-plus" /> {t("Enregistrer")}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddContrat;
