import React from "react";
import ImageComponent from "../Styles/ImageComponent";
import { Link, useHistory } from "react-router-dom";
import * as FileSaver from "file-saver";
import swal from "sweetalert";

function ImageTicket({ file }) {
  return (
    <>
      {["png", "jpg", "jpeg"].some((element) => file.split(".").pop().includes(element)) && (
        <ImageComponent atr={"avatar bg-transparent avatar-xl mr-2"} picture={file}></ImageComponent>
      )}
      {file.split(".").pop() === "pdf" && (
        <img src="assets/images/files/attachments/pdf.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "css" && (
        <img src="assets/images/files/attachments/css.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "html" && (
        <img
          src="assets/images/files/attachments/html.png"
          alt="img"
          className="avatar bg-transparent avatar-xl mr-2"
        />
      )}
      {file.split(".").pop() === "zip" && (
        <img src="assets/images/files/attachments/zip.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "docx" && (
        <img
          src="assets/images/files/attachments/docx.png"
          alt="img"
          className="avatar bg-transparent avatar-xl mr-2"
        />
      )}
      {file.split(".").pop() === "txt" && (
        <img src="assets/images/files/attachments/txt.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}

      {file.split(".").pop() === "js" && (
        <img src="assets/images/files/attachments/js.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "apk" && (
        <img src="assets/images/files/attachments/apk.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "svg" && (
        <img src="assets/images/files/attachments/svg.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "pptx" && (
        <img
          src="assets/images/files/attachments/pptx.png"
          alt="img"
          className="avatar bg-transparent avatar-xl mr-2"
        />
      )}
      {file.split(".").pop() === "xlsx" && (
        <img
          src="assets/images/files/attachments/xlsx.png"
          alt="img"
          className="avatar bg-transparent avatar-xl mr-2"
        />
      )}
      {file.split(".").pop() === "rar" && (
        <img src="assets/images/files/attachments/rar.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
      {file.split(".").pop() === "sql" && (
        <img src="assets/images/files/attachments/sql.png" alt="img" className="avatar bg-transparent avatar-xl mr-2" />
      )}
    </>
  );
}

export default ImageTicket;
