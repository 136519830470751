/**
 * Given an array of objects, return a filtered array of objects where the value of the property
 * `value1` is equal to `value2`
 * @param arrayList - the array you want to filter
 * @param value1 - the name of the column to filter on
 * @param value2 - The value of the column that you want to filter by.
 * @returns An array of objects.
 */
export function getfilter(arrayList, value1, value2) {
  return arrayList.filter((el) => el[value1] == value2);
}

/**
 * Given an array of objects, return the sum of the values of a given key
 * @param arrayObject - The array of objects that you want to sum up.
 * @param value - The value to sum.
 * @returns The sum of all the values in the array.
 */
export const getSommeNumber = (arrayObject, value) => {
  return arrayObject
    .map((o) => o[value])
    .reduce((a, c) => {
      return Number(a) + (Number(c) || 0);
    }, 0);
};

/**
 * It sums up the values of an array of objects.
 * @param arrayObject - The array of objects that you want to sum.
 * @param value - The value to sum.
 * @returns The sum of the values in the array.
 */
export const getSomme = (arrayObject, value) => {
  let sum = arrayObject
    .map((o) => o[value])
    .reduce((a, c) => {
      return Number(a) + (Number(c) || 0);
    }, 0);
  return Number(sum).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 3 }
  );
};

/**
 * Given an array of objects, return a new array of objects that match a specific date
 * @param arrayList - the array of objects that you want to filter
 * @param value - The name of the column that you want to filter by.
 * @returns An array of objects.
 */
export function getfilterToday(arrayList, value) {
  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return arrayList.filter(
    (el) =>
      el[value].split("-")[0] == date.getFullYear() &&
      el[value].split("-")[1] == month &&
      el[value].split(" ")[0].split("-")[2] == day
  );
}
/**
 * Given an array of objects, return a filtered array of objects where the value of the property
 * specified by `value` is equal to the current date - 1
 * @param arrayList - the array of objects that you want to filter
 * @param value - The name of the column that contains the date.
 * @returns An array of objects.
 */
export function getfilterYesterday(arrayList, value) {
  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return arrayList.filter(
    (el) =>
      el[value].split("-")[0] == date.getFullYear() &&
      el[value].split("-")[1] == month &&
      el[value].split(" ")[0].split("-")[2] == day - 1
  );
}
/**
 * Given an array of objects, return a filtered array of objects where the value of the property
 * `value` is equal to the current month
 * @param arrayList - the array of objects that you want to filter
 * @param value - The name of the column that you want to filter by.
 * @returns An array of objects.
 */
export function getfilterMonth(arrayList, value) {
  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return arrayList.filter((el) => el[value].split("-")[0] == date.getFullYear() && el[value].split("-")[1] == month);
}
/**
 * Given an array of objects, return a new array of objects that match a particular property value
 * @param arrayList - The array of objects that you want to filter.
 * @param value - The name of the column that you want to filter by.
 * @returns An array of objects.
 */
export function getfilterYear(arrayList, value) {
  const date = new Date();
  return arrayList.filter((el) => el[value].split("-")[0] == date.getFullYear());
}

/**
 * Given an array of objects, return a filtered array of objects where the value of the property is
 * equal to the current month
 * @param arrayList - the array of objects that you want to filter
 * @param value - The name of the column that you want to filter by.
 * @returns An array of objects.
 */
export function getfilterLastMonth(arrayList, value) {
  const date = new Date();
  const month = date.getMonth().toString().padStart(2, "0");
  return arrayList.filter((el) => el[value].split("-")[0] == date.getFullYear() && el[value].split("-")[1] == month);
}
/**
 * It filters the arrayList by the year of the date.
 * @param arrayList - The array of objects that you want to filter.
 * @param value - The name of the column that you want to filter by.
 * @returns The filtered array of objects.
 */
export function getfilterLastYear(arrayList, value) {
  const date = new Date();
  return arrayList.filter((el) => el[value].split("-")[0] == date.getFullYear() - 1);
}
