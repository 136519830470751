import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getEnteteByCode, uploadDechargeFacuteByCode } from "../../../Services/Facturation/FacturationEnteteApi";
import {
  addEnteteFacture,
  addPiedFacture,
  getEnteteMediaFacture,
} from "../../../Services/Facturation/FacturationEnteteMediaApi";
import { editLigneFacture, getEntrepriseByCode } from "../../../Services/Pointeuse/EntrepriseApi";
import "../Document/UploadDecharge.css";
import { t } from "i18next";

function BillParams(props) {
  const [imageUrlEntete, setimageUrlEntete] = useState("");
  const [imageNameEntete, setimageNameEntete] = useState("");
  const [imageObjectEntete, setimageObjectEntete] = useState("");

  const [imageUrlPied, setimageUrlPied] = useState("");
  const [imageName1, setimageNamePied] = useState("");
  const [imageObjectPied, setimageObjectPied] = useState("");
  const [LigneFacture1, setLigneFacture1] = useState("");
  const [LigneFacture2, setLigneFacture2] = useState("");
  const [LigneFacture3, setLigneFacture3] = useState("");
  const [LigneFacture4, setLigneFacture4] = useState("");

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      if (res?.data) {
        setLigneFacture1(res.data?.ligne_facture_1);
        setLigneFacture2(res.data?.ligne_facture_2);
        setLigneFacture3(res.data?.ligne_facture_3);
        setLigneFacture4(res.data?.ligne_facture_4);

      }

    });
  }, []);

  useEffect(() => {
    getEnteteMediaFacture().then((res) => {
      if (res?.data?.length > 0) {
        setimageObjectEntete(localStorage.getItem("baseUrl") + res.data[0].image);
        setimageObjectPied(localStorage.getItem("baseUrl") + res.data[1].image);
      } else {
        setimageObjectEntete("assets/images/icons/add.png");
        setimageObjectPied("assets/images/icons/add.png");
      }
    });
  }, []);

  const handleChangeImageEntete = (e) => {
    setimageNameEntete(e.target.files[0].name);
    setimageUrlEntete(e.target.files[0]);
    setimageObjectEntete(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeImagePied = (e) => {
    setimageNamePied(e.target.files[0].name);
    setimageUrlPied(e.target.files[0]);
    setimageObjectPied(URL.createObjectURL(e.target.files[0]));
  };

  const uploadImageEntete = () => {
    // uploadDechargeFacuteByCode(imageUrlEntete, props.match.params.id);
    addEnteteFacture(imageUrlEntete).then((res) => {
      console.log(res);
    });
  };
  const uploadImagePied = () => {
    addPiedFacture(imageUrlPied).then((res) => {
      console.log(res);
    });
  };

  const updateLigneFacture = () => {
    editLigneFacture(LigneFacture1, LigneFacture2, LigneFacture3, LigneFacture4).then((res) => {
      console.log(res);
    });
  };
  return (
    <div className="m-4">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              {/* <h4>Image entête facture</h4> */}

              <div className="mt-4 border">
                <img src="assets/images/facture/facture_img_1.png" alt="entete_img" style={{ height: 250 }}></img>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              <h4>{t("Sélectionnez image entête facture")}</h4>
              <form method="post" encType="multipart/form-data" id="js-upload-form"></form>
              {/* Drop Zone */}

              <div className="form-group files">
                <label>{t("Téléchargez votre fichier")} </label>
                <input accept="image/*" type="file" className="form-control" onChange={handleChangeImageEntete} />
              </div>

              <div className="form-inline">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  id="js-upload-submit"
                  onClick={uploadImageEntete}
                >
                  {t("Télécharger image")}
                </button>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              <h4>{t("Image")} </h4>

              <div className="mt-4 border">
                <img src={imageObjectEntete} style={{ height: 250 }} alt="entete_img"></img>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-sm-6 col-md-4">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              {/* <h4>Image entête facture</h4> */}

              <div className="mt-4 border">
                <img src="assets/images/facture/facture_img_2.png" style={{ height: 250 }} alt="entete_img"></img>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              <h4>{t("Sélectionnez image pied facture")}</h4>
              <form method="post" encType="multipart/form-data" id="js-upload-form"></form>
              {/* Drop Zone */}

              <div className="form-group files">
                <label>{t("Téléchargez votre fichier")} </label>
                <input accept="image/*" type="file" className="form-control" onChange={handleChangeImagePied} />
              </div>

              <div className="form-inline">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  id="js-upload-submit"
                  onClick={uploadImagePied}
                >
                  {t("Télécharger image")}
                </button>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              <h4>{t("Image")} </h4>

              <div className="mt-4 border">
                <img src={imageObjectPied} style={{ height: 250 }} alt="entete_img"></img>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-sm-6 col-md-6">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              {/* <h4>Image entête facture</h4> */}

              <div className="mt-4 border">
                <img src="assets/images/facture/facture_img_3.png" style={{ height: 250 }} alt="entete_img"></img>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="panel panel-default">
            <div className="panel-body">
              {/* Standar Form */}
              <h4>{t("Sélectionnez image pied facture")}</h4>
              <form method="post" encType="multipart/form-data" id="js-upload-form"></form>
              {/* Drop Zone */}

              <div className="form-group">
                <label>{t("Ligne")} 1 </label> <small>({t("maximum 100 caractères")})</small>
                <input
                  onChange={(e) => setLigneFacture1(e.target.value)}
                  value={LigneFacture1}
                  type="text"
                  className="form-control"
                  maxLength="100"
                />
              </div>
              <div className="form-group">
                <label>{t("Ligne")} 2 </label>
                <small> ({t("maximum 100 caractères")})</small>
                <input
                  onChange={(e) => setLigneFacture2(e.target.value)}
                  value={LigneFacture2}
                  type="text"
                  className="form-control"
                  maxLength="100"
                />
              </div>
              <div className="form-group">
                <label>{t("Ligne")} 3 </label>
                <small> ({t("maximum 100 caractères")})</small>
                <input
                  value={LigneFacture3}
                  onChange={(e) => setLigneFacture3(e.target.value)}
                  type="text"
                  className="form-control"
                  maxLength="100"
                />
              </div>
              <div className="form-group">
                <label>{t("Ligne")} 4 </label>
                <small> ({t("maximum 100 caractères")})</small>
                <input
                  value={LigneFacture4}
                  onChange={(e) => setLigneFacture4(e.target.value)}
                  type="text"
                  className="form-control"
                  maxLength="100"
                />
              </div>

              <div className="form-inline">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  id="js-upload-submit"
                  onClick={updateLigneFacture}
                >
                  {t("Enregistrer")}
                </button>
              </div>

              {/* Upload Finished */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillParams;
