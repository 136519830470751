import React, { useEffect, useState } from 'react';
import { addSatisfaction, updatesatisfaction } from '../../Services/Pointeuse/SatisfactionApi';
import { Link, useHistory } from "react-router-dom";

import { t } from "i18next";
import { getClientSupportByCode, getFacturationClientByCode } from '../../Services/Facturation/FacturationClientApi';
import { getUserByfonction, getUsersupport } from '../../Services/Pointeuse/UsersApi';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import moment from "moment";
import Select from 'react-select';

function AjouterSatisfaction(props) {
  const history = useHistory();


  const [clients, setclients] = useState([])
  const [user_support, setuser_support] = useState([])
  const listfreqcontact = ['Quotidiennement', 'Hebdomadairement', 'Mensuellement', 'Autre'];
  const satisfactionlist = ['Pas de tous satisfait(e)', 'Moyen', 'Bien', 'Trés Bien', 'Trés satisfait(e)']

  const [supprotdata, setdatasupport] = useState({
    code_generated: localStorage.getItem('code_generated'),
    client: '',
    nom_client: '',
    support: '',
    methode_contact: '',
    destinaire_num_tel: '',
    remarque: '',
    date: moment(new Date()).format("YYYY-MM-DD"),
    vis_a_vis: '',
    satisfait: '',
    anomalie_difficulte: '',
    proposition: '',
    temps_reclamation: '',
    temps_demande: '',
    frequance_contact: '',
    accueil_tel: '',
    formations_admin: '',
    objectifs: '',
    MAJ_continues: '',
    commentaire: '',
    note: 0

  })
  useEffect(() => {
    getUsersupport().then(res => {

      setuser_support(res.data)

    })
  }, [])
  useEffect(() => {

    if (props.location.state?.el.id) {
      setdatasupport(props.location.state?.el)
    }
  }, [props.location.state?.el])

  useEffect(() => {
    getClientSupportByCode().then((res) => {
      const result = res?.data;

      if (result) {
        const options = result.map((d) => ({
          value: d.code,
          label: d.nom,
          secondValue: d.code_client,
        }));
        setclients(options);
      }

    });
  }, [])

  const handelchange = (field, value) => {
    setdatasupport(prev => ({
      ...prev,
      [field]: value

    }));
  }
  const handleSubmit = () => {
  
    if ( supprotdata.support === ''
      || supprotdata.methode_contact === '' || supprotdata.destinaire_num_tel === '' || supprotdata.satisfait === '' ||
      supprotdata.temps_reclamation === '' || supprotdata.temps_demande === '' || supprotdata.accueil_tel === '') {
      toast.error('Veuillez remplir tous les champs requis');

    } else {

      if (supprotdata.id) {

        delete supprotdata.created_at;
        delete supprotdata.updated_at;
        delete supprotdata.usernom;
        delete supprotdata.userprenom;
        delete supprotdata.supportuser;
        delete supprotdata.clientnom;
        delete supprotdata.clientprenom;
        delete supprotdata.clientnomf;
        delete supprotdata.clientprenomf;
        supprotdata.date =    supprotdata.date.split('T')[0]
        updatesatisfaction(supprotdata).then((res) => {
          if (res.status === 200) {
            toast.success("modifié avec succée");
            if (localStorage.getItem('role_code') === 'admin') {
              history.push("/admin/satisfaction");
            } else {
              history.push("/user/satisfaction");

            }
          } else {
            console.lo("err")
          }

        });
      } else {
        addSatisfaction(supprotdata).then((res) => {
          if (res.status === 200) {
            toast.success("ajouté avec succée");
            if (localStorage.getItem('role_code') === 'admin') {
              history.push("/admin/satisfaction");
            } else {
              history.push("/user/satisfaction");

            }
          } else {
            console.lo("err")
          }

        });
      }

    }

  };
  const handleClientChange = (selectedOption) => {
    setdatasupport(prev => ({
      ...prev,
      client: selectedOption.value

    }));
  };

  return (
    <>
      <div className=" mt-4">
        <div className=" row ">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Satisfaction")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className='card-body'>
              <div className='row '>
                <div className="form-group col-3">
                  <label>
                    {t("Nom Client")} <span className="text-danger">*</span>
                  </label>
                  <Select
                    onChange={handleClientChange}
                    value={clients.find(item => item.value === supprotdata.client) ||clients.find(item => item.secondValue === supprotdata.client) }
                    options={clients}
                    placeholder={t("Choisir un client")}
                  />
                </div>
                <div className="form-group col-3">
                  <label>
                    {t("Nom Répondre")} <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" value={supprotdata.nom_client} onChange={(e) => handelchange('nom_client', e.target.value)} required />
                </div>
                <div className="form-group col-3">
                  <label>
                    {t("Date")} <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <input
                      className="form-control"
                      type="date"
                      format="yyyy-MM-dd"
                      value={supprotdata.date}
                      onChange={(e) => handelchange('date', e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-3">
                  <label>
                    {t("Destination et N° téléphone")} <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={supprotdata.destinaire_num_tel}
                    onChange={(e) => handelchange('destinaire_num_tel', e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className='row'>
                <div className="form-group col-3">
                  <label>
                    {t("Vis a Vis")} <span className="text-danger">*</span>
                  </label>
                  <select required onChange={(e) => handelchange('vis_a_vis', e.target.value)} value={supprotdata.vis_a_vis} className="selectpicker form-control" >
                    <option value="0">{t("Choisir user")}</option>
                    {user_support &&
                      user_support.map((el, idx) => (
                        <option key={idx} value={el.code}>
                          {el.prenom + ' ' + el.nom}
                        </option>
                      ))}
                  </select>
                </div>
                {/* <div className="form-group">
                  <label>
                    {t("Méthode de Contact")} <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" onChange={handleChangemethodecontact} required />
                </div> */}
                <div className="form-group col-3">
                  <label>
                    {t("Méthode de Contact")} <span className="text-danger">*</span>
                  </label>
                  <select className="form-control" value={supprotdata.methode_contact} onChange={(e) => handelchange('methode_contact', e.target.value)} required>
                    <option value="">Select Method</option>
                    <option value="telephone">Telephone</option>
                    <option value="whatsapp">WhatsApp</option>
                    <option value="email">Email</option>
                    <option value="sms">SMS</option>
                  </select>
                </div>
                <div className="form-group col-3">
                  <label>
                    {t("support")} <span className="text-danger">*</span>
                  </label>
                  <select required onChange={(e) => handelchange('support', e.target.value)} value={supprotdata.support} className="selectpicker form-control" >
                    <option value="0">{t("Choisir user")}</option>
                    {user_support &&
                      user_support.map((el, idx) => (
                        <option key={idx} value={el.code}>
                          {el.prenom + " " + el.nom}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-3">
                  <label>
                    {t("Remarque")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={supprotdata.remarque}
                    onChange={(e) => handelchange('remarque', e.target.value)}
                    required
                  />
                </div>
              </div>


            </div>
          </div>
        </div>


        <div className=" row ">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Question")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className='row'>
                <div className="form-group col-6">
                  <label>
                    {t("Satisfaction")} <span className="text-danger">*</span>
                  </label>

                  <select required onChange={(e) => handelchange('satisfait', e.target.value)} value={supprotdata.satisfait} className="selectpicker form-control" >
                    <option value="0">{t("Choisir une réponse")}</option>

                    {satisfactionlist.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-6">
                  <label>
                    {t("Anomalie ou difficulté")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={supprotdata.anomalie_difficulte}
                    onChange={(e) => handelchange('anomalie_difficulte', e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className='row '>
                <div className="form-group col-6">
                  <label>
                    {t("Temp de traitement et reclamation")} <span className="text-danger">*</span>
                  </label>
                  <select className="form-control"
                    value={supprotdata.temps_reclamation}
                    onChange={(e) => handelchange('temps_reclamation', e.target.value)}
                    required>
                    <option value="">Select Temp reclamation</option>
                    <option value="rapide">Rapide</option>
                    <option value="lente">Lente</option>
                    <option value="modere">Modéré</option>
                  </select>
                </div>
                <div className="form-group col-6">
                  <label>
                    {t("Proposition")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={supprotdata.proposition}
                    onChange={(e) => handelchange('proposition', e.target.value)}
                    required
                  />
                </div>

              </div>
              <div className='row'>
                <div className="form-group col-6">
                  <label>
                    {t("Temp de traitement de demande dev")} <span className="text-danger">*</span>
                  </label>
                  <select className="form-control"
                    value={supprotdata.temps_demande}
                    onChange={(e) => handelchange('temps_demande', e.target.value)}
                    required>
                    <option value="">Select Temp dev</option>
                    <option value="rapide">Rapide</option>
                    <option value="lent">Lent</option>
                    <option value="modere">Modéré</option>
                  </select>
                </div>
                <div className="form-group col-6 ">
                  <label>
                    {t(" A quelle fréquence contactez-vous notre équipe support ,")}
                  </label>
                  <select required onChange={(e) => handelchange('frequance_contact', e.target.value)} value={supprotdata.frequance_contact} className="selectpicker form-control" >
                    <option value="0">{t("Choisir une réponse")}</option>

                    {listfreqcontact.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row'>

                <div className="form-group  col-6">
                  <label>
                    {t(" Concernant notre accueil téléphonique vous êtes plutôt ?")}
                  </label> <span className="text-danger">*</span>
                  <select required onChange={(e) => handelchange('accueil_tel', e.target.value)} value={supprotdata.accueil_tel} className="selectpicker form-control" >
                    <option value="0">{t("Choisir une réponse")}</option>

                    {satisfactionlist.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group  col-6 ">
                  <label>
                    {t(" Concernant le temps consacré pour les formations administratives ?  ")}
                  </label>
                  <select required onChange={(e) => handelchange('formations_admin', e.target.value)} value={supprotdata.formations_admin} className="selectpicker form-control" >
                    <option value="0">{t("Choisir une réponse")}</option>

                    {satisfactionlist.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row'>
                <div className="form-group col-6 ">
                  <label>
                    {t("  Nos solutions vous aide-t-elles à atteindre vos objectifs ?   ")}
                  </label> <span className="text-danger">*</span>
                  <select required onChange={(e) => handelchange('objectifs', e.target.value)} value={supprotdata.objectifs} className="selectpicker form-control" >
                    <option value="0">{t("Choisir une réponse")}</option>

                    <option value={'oui'}>
                      OUI
                    </option>
                    <option value={'Non'}>
                      NON
                    </option>
                    <option value={'Autre'}>
                      Autre
                    </option>
                  </select>
                </div>

                <div className="form-group col-6">
                  <label>
                    {t("   Qu'elle est votre opinion concernant les M.A.J continues ?  ")}
                  </label>
                  <select required onChange={(e) => handelchange('MAJ_continues', e.target.value)} value={supprotdata.MAJ_continues} className="selectpicker form-control" >
                    <option value="0">{t("Choisir une réponse")}</option>

                    {satisfactionlist.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row'>
                <div className="form-group col-6 ">
                  <label>
                    {t(" commentaire   ")}
                  </label>
                  <textarea className="form-control"
                    onChange={(e) => handelchange('commentaire', e.target.value)} value={supprotdata.commentaire} />


                </div>

                <div className="form-group col-6">
                  <label>
                    {t(" Note support  ")}
                  </label>
                  <select className="form-control"
                    onChange={(e) => handelchange('note', e.target.value)} value={supprotdata.note}>
                    {[...Array(11).keys()].map(item => (
                      <option value={item} key={item}>{item}</option>
                    ))}
                  </select>

                </div>
              </div>


            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
      </div>




      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            {/* <i className="far fa-credit-card" /> */}
            {t("Enregistrer")}
          </button>
        </div>
      </div>
    </>
  );
}

export default AjouterSatisfaction;
