import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteTasById, getTasksByCode, getTasksById, getTasksByUsers } from "../../Services/Pointeuse/TasksApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { groupByProject } from "../../Utils/GroupeUtils";
import { reduceObject, reduceObjectTache } from "../../Utils/ObjectOperation";
import { filterTasksByWord } from "../../Utils/SortObject";
import PieChartJsTaskStatus from "../Charts/CustumChartJs/PieChartJsTaskStatus";
import PieChartJsTaskType from "../Charts/CustumChartJs/PieChartJsTaskType";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import { t } from "i18next";
import { toast } from "react-toastify";
import { RetourDemandeClient, ValideDemandeClient, addTache, getDemandeClient, getsuividemandeclient, updateDemandeClient } from "../../Services/taches/TachesAPI";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom";


function ViewClientTaches() {
    const [currentData, setcurrentData] = useState([]);
    const [allInitialData, setallInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setdata] = useState([]);
    const [users, setusers] = useState([]);
    const [projetsTask, setprojetsTask] = useState([]);
    const [size, setsize] = useState(14);
    const [isOpened, setIsOpened] = useState(false);
    const [tasks, settasks] = useState(null);
    const history = useHistory();
    const roleuser = localStorage.getItem('role_code')
    const api_url = process.env.REACT_APP_API_URL;
    let { clientCode } = useParams();
    const [date_debut, setdate_debut] = useState()
    const [date_fin, setdate_fin] = useState()
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        getsuividemandeclient(clientCode).then((res) => {

         

         const  list = res.data?.map(item => {
                const element = { ...item }
               if(element.etat_traitement !== null){
                element.etat_traitement = (element.etat_traitement !== 'revoir' && element.etat_traitement !== "terminee") ? 'encour' : element.etat_traitement

               }
                return element
            })
         
            setdata(list.reverse());
            setallInitialData(list.reverse());
            setprojetsTask(groupByProject(list));
            settasks(reduceObjectTache(list));
            console.log(reduceObjectTache(list))
        })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));


    };



    /**
     * The function takes in a page of items and sets the currentData state to that page of items
     * @param pageOfItems - an array of items on the current page.
     */
    const onChangePage = (pageOfItems) => {
        // update state with new page of items
        setcurrentData(pageOfItems);
    };
    /**
     * It filters the data by the search term.
     * @param e - The event object that contains the target element.
     */
    const handleSearch = (string) => {
        const filtered = allInitialData.filter(
            (el) =>
                el?.societe?.toLowerCase().includes(string.toLowerCase()) ||
                el.titre?.toLowerCase().includes(string.toLowerCase()) ||
                el.demande?.toLowerCase().includes(string.toLowerCase())

        );
        if (filtered.length === 0) {
            setdata(allInitialData);


        } else {
            setdata(filtered);

            return filtered;
        }
    };
    /**
     * The function sets the size of the data to be retrieved from the API.
     * @param e - The event object that contains the data we want to use.
     */
    const handleChangeSize = (e) => {
        setsize(e.target.value);
        getData();
    };

    const handlePriority = (value) => setdata(value !== '' ? allInitialData.filter((el) => el.priorite == value) : allInitialData);
    const handleCreate = (datedebut, datefin) => {

        const filteredData = allInitialData.filter((el) => {
            const createdAtDate = new Date(el.date_creation_demande.split("T")[0]); // Convertir la date de création en objet Date
            const startDate = new Date(datedebut); // Convertir date_debut en objet Date
            const endDate = new Date(datefin); // Convertir date_fin en objet Date

            // Filtrer les éléments dont la date de création est entre date_debut et date_fin
            return createdAtDate >= startDate && createdAtDate <= endDate;
        });

        setcurrentData(filteredData); // Mettre à jour les données filtrées
    };



    const open_file = (Nomfile) => {
        const newTab = window.open(`${api_url}${Nomfile}`, '_blank');
        if (newTab) {
            // If the new tab was successfully opened, you can focus on it (optional)
            newTab.focus();
        } else {
            toast.error('Popup blocker prevented opening a new tab.');
        }
    };

   const validerTache = (id,index)=>{
    const data = {idDemande:id}
    ValideDemandeClient(data).then((res) => {
        if(res.status === 200){
            toast.success('demande validé')
            const listcopie = [...allInitialData]
            listcopie[index].valider = 1 
            setallInitialData(listcopie)
            

        }
       })
   }

   const retourTache = (id,index)=>{
    const data = {idTask:id}
    RetourDemandeClient(data).then((res) => {
        if(res.status === 200){
            toast.success('demande a revoir')
            const listcopie = [...allInitialData]
            listcopie[index].etat_traitement = 'revoir'
            setallInitialData(listcopie)
            

        }
       })
   }
    return (
        <>

            <div className="page-header d-xl-flex d-block">
                <div className="page-leftheader">
                    <h4 className="page-title">{t("Vos demandes")}</h4>
                </div>
                <div className="page-rightheader ml-md-auto">
                    <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                        <div className="btn-list">
                            <Link to={`/CreeTache/${clientCode}`} className="btn btn-primary mr-3" data-toggle="modal" data-target="#newtaskmodal">
                                {t("Ajouter Demande")}
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            {tasks && (


                                <div className="row mb-0 pb-0 ">

                                    <div className="col-md-6 col-lg-2 text-center py-5">
                                        <span className="badge " style={{ padding: '20px', backgroundColor: "#83B9EF", color: "white" }}>{tasks?.crée || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("crée")}</h5>
                                    </div>
                                    <div className="col-md-6 col-lg-2 text-center py-5">
                                        <span className="badge " style={{ padding: '20px', backgroundColor: "#2B9FB6", color: "white" }}>{tasks?.[''] || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("Tache")}</h5>
                                    </div>

                                    <div className="col-md-6 col-lg-2 text-center py-5">
                                        <span className="badge badge-warning" style={{ padding: '20px' }}>{tasks?.encour || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("en cours")}</h5>
                                    </div>

                                    <div className="col-md-6 col-lg-2 text-center py-5">
                                        <span className="badge badge-secondary" style={{ padding: '20px' }}>{tasks?.terminee || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("terminé")}</h5>
                                    </div>
                                    <div className="col-md-6 col-lg-2 text-center py-5">
                                        <span className="badge badge-default" style={{ padding: '20px' }}>{tasks?.revoir || 0}</span>
                                        <h5 className="mb-0 mt-3">{t("à revoir")}</h5>
                                    </div>
                                </div>

                            )}


                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header  border-0">
                            <h4 className="card-title">{t("Vos demandes ")}</h4>
                        </div>

                        <div className="card-body">


                            {loading && <CustomSyncLoader></CustomSyncLoader>}
                            {!loading && error && <ErrorCustum></ErrorCustum>}
                            {!loading && !error && data && (
                                <div className="table-responsive">
                                    <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-1">
                                                <div className="dataTables_length" id="hr-table_length">
                                                    <label>
                                                        {t("Afficher")}{" "}
                                                        <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                                            <option value={8}>8</option>
                                                            <option value={20}>20</option>
                                                            <option value={40}>40</option>
                                                            <option value={60}>60</option>
                                                            <option value={100}>100</option>
                                                        </select>{" "}

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text " >
                                                            <i className="feather feather-calendar" /> {t('Date Debut')}
                                                        </div>
                                                    </div>
                                                    <input
                                                        onChange={(e) => {
                                                            setdate_debut(e.target.value);
                                                            handleCreate(e.target.value, date_fin); // Appeler handleCreate après avoir mis à jour date_debut
                                                        }}
                                                        className="form-control fc-datepicker"
                                                        placeholder="DD-MM-YYYY"
                                                        type="date"
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text " >
                                                            <i className="feather feather-calendar" /> {t('Date Fin')}
                                                        </div>
                                                    </div>
                                                    <input
                                                        onChange={(e) => {
                                                            setdate_fin(e.target.value);
                                                            handleCreate(date_debut, e.target.value); // Appeler handleCreate après avoir mis à jour date_fin
                                                        }}
                                                        className="form-control fc-datepicker"
                                                        placeholder="DD-MM-YYYY"
                                                        type="date"
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-sm-12 col-md-2">
                                                <div className="form-group">

                                                    <select
                                                        name="attendance"
                                                        className="form-control custom-select select2"
                                                        data-placeholder={t("Choisir priorité")}
                                                        onChange={(e) => handlePriority(e.target.value)}
                                                    >
                                                        <option value="" label={t("Choisir priorité")} />
                                                        <option value={0}>0-Aucune priorité</option>
                                                        <option value={1}>1-Priorité la plus élevée</option>
                                                        <option value={2}>2-Priorité élevée</option>
                                                        <option value={3}>3-Priorité moyenne</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-1">
                                                <Link to="#" className="btn btn-primary btn-block" onClick={getData}>
                                                    {t("Réinitialiser")}
                                                </Link>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div id="hr-table_filter" className="dataTables_filter">
                                                    <label className="mr-2">
                                                        <input
                                                            type="search"
                                                            className="form-control"
                                                            placeholder={t("Recherche...")}
                                                            aria-controls="hr-table"
                                                            onChange={(e) => handleSearch(e.target.value)}
                                                        />
                                                    </label>

                                                    <ExportCsv data={data} name={"List_tâches"} />
                                                    <ExportPdf data={data} name={"List_tâches"} columns={["societe", "titre", "priorite", "demande", "datecree", "etat"]} />
                                                    <CopieContent />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table className="table  table-vcenter text-nowrap table-bordered border-bottom" id="task-list">
                                                    <thead>
                                                        <tr>
                                                           <th className="border-bottom-0">{t("Task")}</th>
                                                            <th className="border-bottom-0">{t("titre")}</th>
                                                            <th className="border-bottom-0">{t("Priorité")}</th>
                                                            <th className="border-bottom-0">{t("demande")}</th>
                                                            <th className="border-bottom-0">{t("Date")}</th>
                                                            <th className="border-bottom-0">{t("fichier")}</th>
                                                            <th className="border-bottom-0">{t("Statut")}</th>
                                                            <th className="border-bottom-0"></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentData.map((el, idx) => (
                                                            <tr key={idx}>
                                                                <td>{el.Taskid}</td>
                                                               
                                                                <td>

                                                                    {el.titre}

                                                                </td>
                                                                <td>
                                                                    {el.priorite == 0 && <span className="badge badge-success-light">{el.priorite}</span>}
                                                                    {el.priorite == 1 && <span className="badge badge-warning-light">{el.priorite}</span>}
                                                                    {el.priorite == 2 && <span className="badge badge-orange-light">{el.priorite}</span>}
                                                                    {el.priorite == 3 && <span className="badge badge-danger-light">{el.priorite}</span>}
                                                                </td>
                                                                <td>
                                                                    {el.demande}
                                                                </td>
                                                                <td>
                                                                    {moment(el.date_creation_demande).format("YYYY-MM-DD")}
                                                                </td>
                                                                {el.file ? (<td onClick={() => open_file(el.file)}>voir fichier</td>) : <td></td>}
                                                                <td>
                                                                    {(el.etat_traitement !== null && el.etat_traitement !== '') ?
                                                                        <>
                                                                           
                                                                            {el.etat_traitement === "encour" && (
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-warning">{el.etat_traitement}</span>

                                                                                </div>
                                                                            )}

                                                                            {el.etat_traitement === "terminee" && (
                                                                                <div className="d-flex">
                                                                                    <span className="badge badge-secondary">{el.etat_traitement}</span>

                                                                                </div>
                                                                            )}
                                                                             {el.etat_traitement === "revoir" && (
                                                                                <div className="d-flex">
                                                                                    <span  className="badge badge-default" >{el.etat_traitement}</span>

                                                                                </div>
                                                                            )}

                                                                        </>

                                                                        : el.etat_tache === 'converted' ? (
                                                                            <div className="d-flex">
                                                                                <span className="badge " style={{ backgroundColor: "#2B9FB6", color: "white" }}>{('Tache')}</span>

                                                                            </div>
                                                                        ) : <div className="d-flex">
                                                                            <span className="badge " style={{ backgroundColor: "#83B9EF", color: "white" }}>{el.etat_tache}</span>

                                                                        </div>
                                                                    }</td>
                                                                <td>{(el.etat_traitement === 'terminee' && el.valider === 0 )?(

                                                                    <>
                                                                        <span onClick={()=>validerTache(el.demande_id,idx)} className="badge " style={{ backgroundColor: "#83B9EF", color: "white" }}>Valider</span>

                                                                        <span className="badge "  onClick={()=>retourTache(el.Taskid,idx)} style={{ backgroundColor: "#83B9EF", color: "white" }}>retour</span></>

                                                                ): el.valider === 1 && 

                                                                        <span className="badge "  style={{ backgroundColor: "green", color: "white" }}>Validé</span>




                                                                }</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                                                    {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default ViewClientTaches;
