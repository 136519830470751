import React, { useEffect, useRef, useState } from "react";
import { addPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { t } from "i18next";

function AddPointage(props) {
  const [date, setdate] = useState();
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [device, setdevice] = useState(null);
  const [network, setnetwork] = useState("");
  const [users, setusers] = useState(null);
  const myRefDelete = useRef(null);
  const [userId, setuserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        if (res?.data?.success != "0") {
          setusers(res.data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    //setdevice(getUA());
    setdevice(getUA() + "/" + getBrowser());

    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setlatitude(position.coords.latitude);
          setlongitude(position.coords.longitude);
        },
        (error) => {
          setlatitude(-1);
          setlongitude(-1);
        }
      );
    }
  }, []);

  /**
   * It saves the pointage to the database.
   */
  const handelSave = () => {
    addPointageByUserId(userId, date, latitude, longitude, device, network, date).then((res) => {
      console.log(res);
      myRefDelete.current.click();
      props.getData();
    });
  };

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = "unkown";
    // Detect browser name
    browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
    browser = /edg/i.test(userAgent) ? "Edge" : browser;
    browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
    browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
    browser = /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? "Firefox" : browser;
    browser = /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent) ? "IE" : browser;
    browser =
      /chrome|crios/i.test(userAgent) && !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
        ? "Chrome"
        : browser;
    browser =
      /safari/i.test(userAgent) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
        ? "Safari"
        : browser;
    browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;

    // detect browser version
    switch (browser) {
      case "UCBrowser":
        return `${browser}/${browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
      case "Edge":
        return `${browser}/${browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
      case "GoogleBot":
        return `${browser}/${browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
      case "Chromium":
        return `${browser}/${browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
      case "Firefox":
        return `${browser}/${browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
      case "Chrome":
        return `${browser}/${browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
      case "Safari":
        return `${browser}/${browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
      case "Opera":
        return `${browser}/${browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
      case "IE":
        const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
        // IE version is mapped using trident version
        // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
        return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
      default:
        return `unknown/0.0.0.0`;
    }
  };

  const browserVersion = (userAgent, regex) => {
    return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
  };

  const getUA = () => {
    let device = "Unknown";
    const ua = {
      "Generic Linux": /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      "Chrome OS": /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
  };

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && users && (
        <div
          className="modal fade"
          id="addPointageModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addPointageModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {t("Ajouter Pointage")}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("Collaborateur")}<span className="text-danger">*</span>
                      </label>

                      <select onChange={(e) => setuserId(e.target.value)} className="selectpicker form-control">
                        <option value="0">{t("Choisir un collaborateur")}</option>
                        {users &&
                          users.map((el, idx) => (
                            <option key={idx} value={el.id}>
                              {el.nom} {el.prenom}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputPassword4">{t("Date")}</label>
                      <input
                        type="datetime-local"
                        value={date}
                        className="form-control"
                        onChange={(e) => setdate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail4">{t("Latitude")}</label>
                      <input disabled value={latitude} type="number" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">{t("Longitude")}</label>
                      <input disabled type="number" value={longitude} className="form-control" />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail4">{t("Périphérique")}</label>
                      <input disabled value={device} type="text" className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">{t("Nom Réseau")}</label>
                      <input disabled type="number" value={network} className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                  {t("Annuler")}
                </button>
                <button type="button" className="btn btn-primary" onClick={handelSave}>
                  {t("Ajouter")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddPointage;
