import React from "react";
import {
  Sparklines,
  SparklinesBars,
  SparklinesReferenceLine,
  SparklinesNormalBand,
  SparklinesLine,
} from "react-sparklines";
import { convertTimeToSecond } from "../../../Utils/GlobalTableUtils";

function BarSparklineRetard({ retards }) {
  let listValues = [];
  retards.forEach((element) => {
    listValues.push(convertTimeToSecond(element.time));
  });
  // listValues.sort(function (a, b) {
  //   return b - a;
  // });

  return (
    <Sparklines
      data={listValues}
      style={{
        strokeWidth: 1,
        stroke: "#43DC80",
        fill: "none",
      }}
    >
      <SparklinesLine
        size={1}
        style={{
          stroke: "#43DC80",
          strokeWidth: 2,
          fill: "#43DC89",
        }}
      />
    </Sparklines>
  );
}

export default BarSparklineRetard;
