import React, { useEffect, useState } from "react";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import EventRetard from "./EventRetard";
import LastRetardCalendar from "./LastRetardCalendar";
import { useHistory } from "react-router-dom";
import { t } from "i18next";

function RetardCalendar(props) {
  const history = useHistory();
  const [users, setusers] = useState(null);
  const [userId, setuserId] = useState(props.match.params.id);

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  const handleChangeUserId = (value) => {
    setuserId(value);
    history.push("/admin/retardcalendar/" + value);
  };
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Retard")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="mr-4">
              <select
                name="attendance"
                className="form-control custom-select select2"
                data-placeholder="Choisir Collaborateur"
                /* A function that takes an event as an argument and calls `handleChangeUserId` with the event's target. */
                onChange={(e) => handleChangeUserId(e.target.value)}
              >
                <option value="0">{t("Choisir un collaborateur")}</option>
                {users &&
                  users.map((el, idx) => (
                    <option key={idx} value={el.id}>
                      {el.nom} {el.prenom}
                    </option>
                  ))}
              </select>
            </div>
            <div className="btn-list">
              {/* <a href="#" data-toggle="modal" data-target="#eventmodal" className="btn btn-primary mr-3">
                Add New Events
              </a> */}

              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-md-12 col-xl-4">
          <LastRetardCalendar userId={userId} />
        </div>
        <div className="col-md-12 col-xl-8">
          <EventRetard userId={props.match.params.id} />
        </div>
      </div>
    </div>
  );
}

export default RetardCalendar;
