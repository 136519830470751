import React, { useEffect, useState } from "react";
import { getEtatStock } from '../../../../../Services/Facturation/FacturationStockApi';
import { Link } from "react-router-dom";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import { t } from "i18next";

function Etatstock() {
    const [currentData, setCurrentData] = useState([]);
    const [allInitialData, setAllInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getEtatStock()
            .then((res) => {
                setData(res.data);
                setAllInitialData(res.data);
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, []);

    const onChangePage = (pageOfItems) => {
        setCurrentData(pageOfItems);
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.trim();
        if (searchTerm !== "") {
            const dataSearch = filterFactureByWord(allInitialData, searchTerm);
            setData(dataSearch);
        } else {
            setData(allInitialData);
        }
    };

    return (
        <>
            <div className="page-header d-xl-flex d-block">
                <div className="page-leftheader"></div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("Etat De Stock")}{" "}
                                <span title="3 New Messages" className="badge badge-warning">
                                    {data.length}
                                </span>
                            </h3>
                            <div className="card-tools">
                                <div className="row">
                                    <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                                        <input
                                            type="text"
                                            name="table_search"
                                            className="form-control floating"
                                            placeholder={t("Rechercher...")}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0 ml-5 mr-5">
                            {loading && <CustomSyncLoader />}
                            {!loading && error && <ErrorCustum />}
                            {!loading && !error && data && (
                                <div className="table-responsive-sm">
                                    <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                            <tr> 
                                                <th>{t(" Produit")}</th>
                                                <th>{t("Code Produit")}</th>
                                                <th>{t("Quantite")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((el) => (
                                                <tr key={el.id}>
                                                    <td>
                                                        <Link to={"/admin/produit/" + el.code_produit}>{el.libelle}</Link>
                                                    </td>
                                                    <td>
                                                        <Link to={"/admin/produit/" + el.code_produit}>{el.code_produit}</Link>
                                                    </td>
                                                    <td>{el.quantite}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="card-footer clearfix">
                            <Pagination items={data} onChangePage={onChangePage} pageSize={20} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Etatstock;
