import { t } from "i18next";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import { getAllValideCongeToday } from "../../Services/Pointeuse/CongeApi";
import { getPersonnelByCode } from "../../Services/Pointeuse/PersonnelApi";
import { getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { compareTwoTime, soustractDateFromToday } from "../../Utils/DateUtils";

class MainDashboardCardSecond extends Component {
  state = {
    collaborateurs: "",
    absences: "",
    pointages: [],
    startHoure: "",
    retardNumber: "",
    todayConge: "",
    countMan: "",
    countWoman: "",
    ageAverge: "",
    countSivp: "",
    countCDI: "",
    countCDD: "",
    countKarama: "",
  };
  /**
   * get all users
   * get all pointages
   */
  componentDidMount() {
    getUsersByCode().then((res) => {
      //get all users
      this.setState({ collaborateurs: res.data });

      //get gender
      let manCounter = 0;
      let womanCounter = 0;
      let ageList = [];
      for (let i = 0; i < res.data.length; i++) {
        // calculate age
        ageList.push(soustractDateFromToday(res.data[i].birth_date));
        if (res.data[i].gender === "male") {
          manCounter++;
        } else {
          womanCounter++;
        }
      }

      // get the average of list

      try {
        let average = (array) => array.reduce((a, b) => a + b) / array.length;
        this.setState({ ageAverge: Math.round(average(ageList)) });
      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }

      // set man count
      this.setState({ countMan: manCounter });
      // set woman count
      this.setState({ countWoman: womanCounter });
    });

    getAllSettings().then((res) => {
      this.setState({ startHoure: res.data.startHoure });
      /**
       * get pointage by code
       * count absence number
       * get pointage by date
       */
      getPointageByCode().then((res) => {
        let number_absence = this.state.collaborateurs.length - res.data.length;
        this.setState({ absences: number_absence });
        this.setState({ pointages: res.data });

        // counte retard
        let pointagesData = this.state.pointages;
        let counterRetard = 0;
        for (let i = 0; i < pointagesData.length; i++) {
          if (compareTwoTime(pointagesData[i].pointage.split(" ")[1], this.state.startHoure)) {
            counterRetard++;
          }
        }
        this.setState({ retardNumber: counterRetard });
      });
    });

    getAllValideCongeToday().then((res) => {
      this.setState({ todayConge: res.data.length });
      //console.log(res.data.length);
    });
    /**
     * get all personnels
     */
    getPersonnelByCode().then((res) => {
      //this.setState({ todayConge: res.data.length });
      let number_cdi = 0;
      let number_cdd = 0;
      let number_sivp = 0;
      let number_karama = 0;
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].type_contrat === "sivp") {
          number_sivp++;
        } else if (res.data[i].type_contrat === "cdi") {
          number_cdi++;
        } else if (res.data[i].type_contrat === "cdd") {
          number_cdd++;
        } else if (res.data[i].type_contrat === "karama") {
          number_karama++;
        } else {
          console.log("gerant");
        }
      }
      this.setState({ countCDD: number_cdd });
      this.setState({ countCDI: number_cdi });
      this.setState({ countKarama: number_karama });
      this.setState({ countSivp: number_sivp });
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <div className="mt-0 text-left">
                      <span className="fs-14 font-weight-semibold">{t("Total collaborateur")}</span>
                      <h3 className="mb-0 mt-1 mb-2">{this.state.collaborateurs.length}</h3>
                      {/* <span className="text-muted">
                        <span className="text-success fs-12 mt-2 mr-1">
                          <i className="feather feather-arrow-up-right mr-1 bg-success-transparent p-1 brround" />
                          124
                        </span>
                        for last month
                      </span> */}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="icon1 bg-success my-auto  float-right">
                      <i className="feather feather-users" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <div className="mt-0 text-left">
                      <span className="fs-14 font-weight-semibold">{t("Male")}</span>
                      <h3 className="mb-0 mt-1 mb-2">{this.state.countMan}</h3>
                      {/* <span className="text-muted">
                        <span className="text-danger fs-12 mt-2 mr-1">
                          <i className="feather feather-arrow-down-left mr-1 bg-danger-transparent p-1 brround" />
                          13
                        </span>
                        for last month
                      </span> */}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="icon1 bg-primary my-auto  float-right">
                      <i className="las la-male" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <div className="mt-0 text-left">
                      <span className="fs-14 font-weight-semibold">{t("Femelle")}</span>
                      <h3 className="mb-0 mt-1  mb-2">{this.state.countWoman}</h3>
                    </div>
                    {/* <span className="text-muted">
                      <span className="text-danger fs-12 mt-2 mr-1">
                        <i className="feather feather-arrow-up-right mr-1 bg-danger-transparent p-1 brround" />
                        21.1%
                      </span>
                      for last month
                    </span> */}
                  </div>
                  <div className="col-4">
                    <div className="icon1 bg-secondary brround my-auto  float-right">
                      <i className="las la-female" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header border-0 responsive-header">
                <h4 className="card-title">Overview</h4>
                <div className="card-options">
                  <div className="btn-list">
                    <Link to="#" className="btn  btn-outline-light text-dark float-left d-flex my-auto">
                      <span className="dot-label bg-light4 mr-2 my-auto" />
                      Employees
                    </Link>
                    <Link to="#" className="btn  btn-outline-light text-dark float-left d-flex my-auto">
                      <span className="dot-label bg-primary mr-2 my-auto" />
                      Budget
                    </Link>
                    <Link to="#" className="btn btn-outline-light" data-toggle="dropdown" aria-expanded="false">
                      
                      Year <i className="feather feather-chevron-down" />
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-right" role="menu">
                      <li>
                        <Link to="#">Monthly</Link>
                      </li>
                      <li>
                        <Link to="#">Yearly</Link>
                      </li>
                      <li>
                        <Link to="#">Weekly</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <canvas id="chartLine" />
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default MainDashboardCardSecond;
