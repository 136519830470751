// import { CSVLink } from 'react-csv';

import { useRef } from "react";
import { CSVLink } from "react-csv";
import {Link} from "react-router-dom"
function ExportCsvSeries({ data, name }) {
  const csvLinkEl = useRef(null);

  const columnsToExport = data.map(({ numero_serie,produit,type, fournisseur  }) => ({
    numero_serie,
    produit,
    type,
    fournisseur, 
  }));

  const headers = [
    { label: 'Numero de Serie', key: 'numero_serie' },
    { label: 'Produit', key: 'produit' },
    { label: 'Type', key: 'type' },
    { label: 'Fournisseur', key: 'fournisseur' }, 
  ];

  /**
   * Download a report as a CSV file
   */
  const downloadReport = async () => {
    setTimeout(() => {
      csvLinkEl?.current.link.click();
    });
  };

  return (
    <>
      <Link to="#" className="action-btns" onClick={downloadReport} data-toggle="tooltip" data-placement="top" title="csv">
        <i className="fa fa-file-excel-o text-success" />
      </Link>
      <CSVLink
        data={columnsToExport}
        headers={headers}
        filename={`${name}.csv`}
        ref={csvLinkEl}
      />
    </>
  );
}

export default ExportCsvSeries;
