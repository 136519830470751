import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEnteteCommercialAchat, getlistAnneeAchat, insertfile } from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit, soustractSolde } from "../../../../../Utils/NumberUtils";
import {
  sumMontantHT,
  sumMontantTTC,
  sumMontantTVA,
  sumPaiement,
  sumRS,
  sumRs,
  sumSolde,
  sumSoldePaiement,
} from "../../../../../Utils/ObjectOperation";
import { compareDate } from "../../../../../Utils/DateUtils";
import { getPaiementByCode } from "../../../../../Services/Facturation/FacturationPaiementApi";
import { checkMontanPaiement, groupByNumPiece } from "../../../../../Utils/FactureUtils";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import { t } from "i18next";
import { toast } from "react-toastify";

function ListFactureAchat() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const api_url = process.env.REACT_APP_API_URL;
  const [listAnnnees, setListAnnnes] = useState([]);

  const liststatut = ["payée", "partiel", "échu", "vide"]

  useEffect(() => {
    getlistAnneeAchat()
      .then((res) => {
        setListAnnnes(res.data)


      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    const year = listAnnnees.length !== 0 ? listAnnnees[0].year : new Date().getFullYear();
    setLoading(true);
    getEnteteCommercialAchat(year)
    
      .then((res) => {
      
        getPaiementByCode().then((resPaiement) => {
          const goupedPaiement = groupByNumPiece(resPaiement.data);

          const mergeById = (a1, a2) =>
            a1.map((itm) => ({
              ...a2.find((item) => item.num_piece === itm.facture_code && item),
              ...itm,
            }));
          
          const mergedList = mergeById(res.data, goupedPaiement);
           setdata(mergedList);
          setallInitialData(mergedList);
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [listAnnnees]);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value.length != "") {
      const dataSearch = filterFactureByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };


  const handleSelectDate = (e) => {
    const val = e.target.value;
    getEnteteCommercialAchat(val)
      .then((res) => {
        getPaiementByCode().then((resPaiement) => {
          const goupedPaiement = groupByNumPiece(resPaiement.data);

          const mergeById = (a1, a2) =>
            a1.map((itm) => ({
              ...a2.find((item) => item.num_piece === itm.facture_code && item),
              ...itm,
            }));
          const mergedList = mergeById(res.data, goupedPaiement);

          setdata(mergedList);
          setallInitialData(mergedList);
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  const handleSearchStatut = (e) => {

    //checkMontanPaiement(el?.montant_paiement) == el.net_a_payer && el.net_a_payer != 0 
    //checkMontanPaiement(el?.montant_paiement)  el.net_a_payer
    if (e.target.value === 'payée') {
      const filtered = allInitialData.filter(
        (el) => el.net_a_payer != 0 && checkMontanPaiement(el?.montant_paiement) >= el.net_a_payer
      );
      if (filtered.length === 0) {
        setdata(allInitialData);
      } else {
        setdata(filtered);

      }
    } //{checkMontanPaiement(el?.montant_paiement) == 0 && el.montant_total_ttc == 0
    else if (e.target.value === 'vide') {
      const filtered = allInitialData.filter(
        (el) => checkMontanPaiement(el?.montant_paiement) == 0 && el.montant_total_ttc == 0
      );
      if (filtered.length === 0) {
        setdata(allInitialData);
      } else {
        setdata(filtered);

      }
    }//checkMontanPaiement(el?.montant_paiement) == 0 && compareDate(el.date_prevu_livraison)
    // || checkMontanPaiement(el?.montant_paiement) < el.net_a_payer &&  checkMontanPaiement(el?.montant_paiement) > 0 && compareDate(el.date_prevu_livraison)
    else if (e.target.value === 'échu') {
      const filtered = allInitialData.filter(
        (el) => (checkMontanPaiement(el?.montant_paiement) == 0 && compareDate(el.date_prevu_livraison)) || (checkMontanPaiement(el?.montant_paiement) < el.net_a_payer && checkMontanPaiement(el?.montant_paiement) > 0 && compareDate(el.date_prevu_livraison))
      );
      if (filtered.length === 0) {
        setdata(allInitialData);
      } else {
        setdata(filtered);

      }
    }
    else if (e.target.value === 'partiel') {
      const filtered = allInitialData.filter(
        (el) => checkMontanPaiement(el?.montant_paiement) < el.net_a_payer && checkMontanPaiement(el?.montant_paiement) > 0
      );
      if (filtered.length === 0) {
        setdata(allInitialData);
      } else {
        setdata(filtered);

      }
    }
    else {
      setdata(allInitialData);
    }

  };

  const getfile = (item) => {
    if (item.pathDoc === null || item.pathDoc === '') {
      toast.warning(t("Il n'y a aucun fichier joint"))
    } else {
      window.open(api_url + item.pathDoc, '_blank')

    }
  }
  return (
    <>
      <div className="page-header d-xl-flex d-block">

        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <select className="btn btn-primary mr-3 " onChange={handleSelectDate} >
                {listAnnnees.map((item, index) => (
                  <option key={index} value={item.year}>Facture d'achat {item.year}</option>

                ))}

              </select>

              <Link to="/admin/ajoutfactureachat" className="btn btn-primary mr-3">
                <i className="fa fa-plus" />
              </Link>

              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flexrow">
        {/* <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header " style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant HT")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-success">{sumMontantHT(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TVA")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Montant TTC")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Encaissement</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumEncaissement(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Retenu")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-danger">{sumRs(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>



        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Soldé")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-danger">{sumPaiement(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Solde</span>
                    <h3 className="mb-0 mt-1 text-danger">{sumSolde(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card text-bg-light mb-3" style={{ maxWidth: '18rem' }}>
          <div className="card-header" style={{ fontSize: "meduim", fontWeight: "600" }}>{t("Reste a payer")}</div>
          <div className="card-body" style={{ display: "flex", gap: "20", alignItems: "center", justifyContent: "center" }}>
            <h5 className="card-title text-danger">{sumSoldePaiement(data)}</h5>
            <div className="icon1 bg-success-transparent " style={{ marginLeft: "30px", marginBottom: "13px" }}>
              <i className="fa fa-money" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="flexrow">
        
           <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header " style={{fontSize:"meduim",fontWeight:"600"}}>Montant HT</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-success">{sumMontantHT(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="">
     
           <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header " style={{fontSize:"meduim",fontWeight:"600"}}>Montant TVA</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="">
          
            <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header " style={{fontSize:"meduim",fontWeight:"600"}}>Montant TTC</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="">
          
          <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header " style={{fontSize:"meduim",fontWeight:"600"}}>Encaissement</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-danger">{sumPaiement(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        
          <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header " style={{fontSize:"meduim",fontWeight:"600"}}>Solde</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-danger">{sumSolde(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                {t("Facture d'achat")}{" "}
                <span title="3 New Messages" className="badge badge-warning">
                  {data.length}
                </span>
              </h3>
              <div className="card-tools">
                <div className="row">
                  <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                    <input
                      type="text"
                      name="table_search"
                      className="form-control floating"
                      placeholder={t("Rechercher...")}
                      onChange={handleSearch}
                    />
                  </div>


                </div>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive-sm">
                  <table className="table table-striped custom-table m-b-0">
                    <thead>
                      <tr>
                        <th >{t("N° Facture")}</th>
                        <th>{t("Fournisseur")} </th>
                        <th>
                          {t("Montant HT")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                        </th>
                        <th>
                          {t("Montant TVA")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                        </th>
                        <th>
                          {t("Montant TTC")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                        </th>
                        <th>
                          {t("RS")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumRs(data)}</span>
                        </th>
                        <th>
                          {t("Paiement")}<br></br>
                          <span className="badge badge-pill badge-danger">{sumPaiement(data)}</span>
                        </th>
                        <th>
                          {t("Reste a payer")}<br></br>
                          <span className="badge badge-pill badge-danger">{sumSoldePaiement(data)}</span>
                        </th>
                        <th>{t("Statut")}</th>
                        <th>{t("Date")}</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((el, i) => (
                        <tr key={el.id}>
                          <td>
                            <Link to={"/admin/editerfactureachat/" + el.code}>{el.facture_code}</Link>
                          </td>
                          <td>
                            <Link to={"/admin/clientactivity/" + el.client_code}>{el.client_nom}</Link>
                          </td>
                          <td>{convertToDigit(el.net_a_payer_ht)}</td>
                          <td>{convertToDigit(el.montant_total_tva)}</td>
                          <td>{convertToDigit(el.montant_total_ttc)}</td>
                          <td>{convertToDigit(checkMontanPaiement(el.rs))}</td>

                          <td>{convertToDigit(checkMontanPaiement(el.montant_paiement))}</td>
                          <td>
                            {el.net_a_payer == "0"
                              ? ""
                              : soustractSolde(el.net_a_payer, el.montant_paiement || 0)}
                          </td>
                          <td>
                            {checkMontanPaiement(el?.montant_paiement) == el.net_a_payer && el.net_a_payer != 0 && (
                              <span className="badge badge-success">{t("payée")}</span>
                            )}
                            {checkMontanPaiement(el?.montant_paiement) > el.net_a_payer && (
                              <span className="badge badge-success">{t("payée")}</span>
                            )}
                            {checkMontanPaiement(el?.montant_paiement) < el.net_a_payer &&
                              checkMontanPaiement(el?.montant_paiement) > 0 && (
                                <>
                                  <span className="badge badge-warning">{t("partiel")}</span>
                                  {compareDate(el.date_prevu_livraison) && (
                                    <span className="badge badge-danger ml-1">{t("échu")}</span>
                                  )}
                                </>
                              )}
                            {checkMontanPaiement(el?.montant_paiement) == 0 && (
                              <>
                                {compareDate(el.date_prevu_livraison) && (
                                  <span className="badge badge-danger ml-1">{t("échu")}</span>
                                )}
                              </>
                            )}
                            {checkMontanPaiement(el?.montant_paiement) == 0 && el.montant_total_ttc == 0 && (
                              <span className="badge badge-primary ml-1">{t("vide")}</span>
                            )}
                          </td>
                          <td>{el.date.split(" ")[0]}</td>

                          <td className="project-actions text-right">
                            {/* <Link to={"/admin/editerfactureachat/" + el.code} className="btn btn-default btn-xs" href="#">
                            <i className="fas fa-pencil-alt fa-xs"></i>
                          </Link> */}

                            <div className="btn-group">
                              <button
                                style={{ textAlign: "center", padding: 2, width: 40 }}
                                type="button"
                                className="btn btn-secondary dropdown-toggle dropdown-icon"
                                data-toggle="dropdown"
                              ></button>
                              <div className="dropdown-menu" role="menu">
                                <Link
                                  // to={{
                                  //   pathname: "/admin/addpaiement",
                                  //   state: { message: "hello, im a passed message!" },
                                  // }}

                                  to={{ pathname: `/admin/addpaiement/`, state: { el } }}
                                  key={i}
                                  className="dropdown-item"
                                >
                                  {t("Ajouter paiement")}
                                </Link>

                                {/* <div className="dropdown-divider" /> */}
                                <Link to={"/admin/editerfactureachat/" + el.code} className="dropdown-item">
                                  {t("Editer document")}
                                </Link>
                                <Link to={"/admin/editeravoirachat/" + el.code} className="dropdown-item">
                                  {t("Editer Avoir")}
                                </Link>
                               
                                <Link to={"/admin/uploadfacture/" + el.facture_code} className="dropdown-item">
                                  {t("Joindre fichier")}
                                </Link>

                                <Link to="#"   onClick={() => getfile(el)} className="dropdown-item">
                                  {t("Ouvrir Fichier ")}
                                </Link>
                               

                                {/* <Link to="#" className="dropdown-item">
                                Supprimer facture
                              </Link> */}

                                <div className="dropdown-divider" />

                                {/* <Link
                                    to="#"
                                    onClick={() => handleActionId(el.code, el.facture_code)}
                                    className="dropdown-item"
                                  >
                                    Supprimer facture
                                  </Link> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
            <div className="card-footer clearfix">
              <Pagination items={data} onChangePage={onChangePage} pageSize={40} />
            </div>
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
}

export default ListFactureAchat;
