import React, { useEffect, useState } from "react";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { getQuestionnaireById } from "../../Services/Pointeuse/QuestionnaireApi";
import ImageComponent from "../Styles/ImageComponent";
import { t } from "i18next";

function ViewQuestionnaire(props) {
  const [data, setdata] = useState(null);
  const [company, setcompany] = useState(null);
  useEffect(() => {
    getQuestionnaireById(props.match.params.id).then((res) => {
      console.log(res.data);
      setdata(res.data);
    });
    getEntrepriseByCode().then((res) => {
      console.log(res);
      setcompany(res.data);
    });
  }, []);

  return (
    <div>
      {/*Page header*/}
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">Imprimer questionnaire</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              {" "}
              <i className="feather feather-mail" />{" "}
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              {" "}
              <i className="feather feather-phone-call" />{" "}
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              {" "}
              <i className="feather feather-info" />{" "}
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row*/}
      <div className="row">
        <div className="col-md-12">
          <div className="card overflow-hidden">
            <div className="card-body">
              <h2 className="text-muted font-weight-bold">{t("Questionnaire")}</h2>

              {data && company && (
                <div className="invoice-box">
                  <table cellPadding={0} cellSpacing={0}>
                    <tbody>
                      <tr className="top">
                        <td colSpan={2}>
                          <table>
                            <tbody>
                              <tr>
                                <td className="title">
                                  <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={data?.img}></ImageComponent>
                                </td>
                                <td>
                                  <p>{data?.createdAt}</p>
                                  <h3>
                                    {t("Questionnaire")}
                                    <br />
                                  </h3>
                                  <small>#{"-"}</small>
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr className="information">
                        <td colSpan={2}>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  {company?.adresse}
                                  <br />
                                  {company?.mobile}
                                  <br />
                                </td>
                                <td>
                                  {company?.website_url}
                                  <br />
                                  {company?.email}
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr className="heading">
                        <td>{t("Retard")}</td>
                        <td>{data?.retardTime}</td>
                      </tr>
                      <tr className="details">
                        <td>{t("Heure de pointage")}</td>
                        <td>{data?.createdAt}</td>
                      </tr>
                      <tr className="heading">
                        <td>{t("Détail questionnaire")}</td>
                        <td />
                      </tr>
                      <tr className="item">
                        <td>{t("Nom et prénom")}</td>
                        <td>
                          {data?.nom} {data?.prenom}
                        </td>
                      </tr>
                      <tr className="item">
                        <td>{t("Email")}</td>
                        <td>{data?.email}</td>
                      </tr>
                      <tr className="item">
                        <td>{t("Objet")}</td>
                        <td>{data?.object}</td>
                      </tr>
                      <tr className="item">
                        <td>{t("Motif")}</td>
                        <td>{data?.motif}</td>
                      </tr>
                      <tr className="item last">
                        <td>{t("Commentaire")}</td>
                        <td>{data?.commentaire}</td>
                      </tr>
                      <tr className="total">
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <div className="table-responsive push">
                <table className="table table-bordered table-hover text-nowrap">
                  <tbody>
                    <tr>
                      <td colSpan={5} className="text-right">
                        <button className="btn btn-info" onClick={() => window.print()}>
                          <i className="si si-printer" /> {t("Imprimer")}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/*  <p className="text-muted text-center">Thank you very much for doing business with us. We look forward to working with you again!</p> */}
            </div>
          </div>
        </div>
      </div>
      {/* End row*/}
    </div>
  );
}

export default ViewQuestionnaire;
