import React from "react";
import Navbar from "./Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/HomePage/Home";
import { homeObjFour } from "./pages/HomePage/Data";
import Footer from "./pages/Footer.js/Footer";

function Welcome() {
  return (
    <>
      <Navbar />
      <Home></Home>
      <Footer></Footer>
    </>
  );
}

export default Welcome;
