import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getQuestionnaireByCode } from "../../Services/Pointeuse/QuestionnaireApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";

function LastQuestionnaire() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [dataLength, setdataLength] = useState(null);

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getQuestionnaireByCode()
      .then((res) => {
        setdata(res.data);
        if (res.data.length > 3) {
          setdataLength(4);
        } else {
          setdata(setdataLength(res.data.length));
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div>
          <div className="card-header pt-0 pl-0 ml-0 mb-4 mt-4 border-bottom-0 responsive-header">
            <h4 className="card-title">{t("Derniers questionnaires")}</h4>
            <div className="card-options">
              <div className="btn-list mr-2">
                {/* <Link to ="#" className="btn btn-primary">
              Add Job
            </Link> */}
                <Link to="/admin/questionnaire" className="btn btn-light">
                  {t("Voir plus")}
                </Link>
                {/* <a
              href="#"
              className="btn btn-light dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {" "}
              Latest <i className="feather feather-chevron-down" />{" "}
            </a> */}
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">{t("Monthly")}</Link>
                  </li>
                  <li>
                    <Link to="#">{t("Yearly")}</Link>
                  </li>
                  <li>
                    <Link to="#">{t("Weekly")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          {!loading && error && <ErrorCustum></ErrorCustum>}
          {!loading && !error && data && (
            <div className="row">
              {data.slice(0, dataLength).map((el, idx) => (
                <div key={idx} className="col-xl-3 col-lg-6 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="task-img bg-light my-auto float-right">
                          <ImageComponent atr={""} picture={el.img}></ImageComponent>
                          {/* <img src="assets/images/png/task1.png" alt="img" /> */}
                        </div>
                        <div className="ml-auto mt-1">
                          <div
                            className="task-btn bg-danger-transparent border-0 mr-0 font-weight-semibold"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Job Type"
                          >
                            {el.retardTime}
                          </div>
                        </div>
                      </div>
                      <div className="mt-0 text-left">
                        <Link to="#" className="mb-0 mt-2 mb-1 font-weight-semibold fs-20">
                          {el.objet}
                        </Link>
                        <p className="text-muted mb-0">
                          {el.nom} {el.prenom}
                        </p>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="d-md-flex">
                        <div className="mb-md-0">
                          <h6 className="mb-1 text-muted fs-16">
                            {t("Date")} :{" "}
                            <span className="font-weight-semibold fs-18 text-default">
                              {el.createdAt.split(" ")[0]}
                            </span>
                            <sup className="badge badge-md badge-primary-light ml-2 fs-10">
                              {el.createdAt.split(" ")[1]}
                            </sup>
                          </h6>
                          <p className="fs-12 text-muted mb-0">{el.motif}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LastQuestionnaire;
