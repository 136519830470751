// import { CSVLink } from 'react-csv';

import { useRef } from "react";
import { CSVLink } from "react-csv";
import {Link} from "react-router-dom"
function ExportCsvSatisfaction({ data, name,filteredData }) {
  const csvLinkEl = useRef(null);
   const columnsToExport = (filteredData || data).map(({ client,support,nom_client, date, methode_contact, destinaire_num_tel,remarque,vis_a_vis,satisfait,anomalie_difficulte,proposition,temps_reclamation,temps_demande }) => ({
    client,
    support,
    nom_client,
    date,
    methode_contact,
    destinaire_num_tel,
    remarque,
    vis_a_vis,
    satisfait,
    anomalie_difficulte,
    proposition,
    temps_reclamation,
    temps_demande
  }));

  const headers = [
    { label: 'Clients', key: 'client' },
    { label: 'Meriam vs koubaib', key: 'support' },
    { label: 'Nom de Client', key: 'nom_client' },
    { label: 'Date', key: 'date' },
    { label: 'Méthode de Contact', key: 'methode_contact' },
    { label: 'Destinataire', key: 'destinaire_num_tel' },
    { label: 'Remarque', key: 'remarque' },
    { label: 'vis a vis', key: 'vis_a_vis' },
    { label: 'Satisfaction', key: 'satisfait' },
    { label: 'Anomalie ou difficultés', key: 'anomalie_difficulte' },
    { label: 'Proposition', key: 'proposition' },
    { label: 'Temp traitement de reclamation', key: 'temps_reclamation' },
    { label: 'Temp traitement de demande de dev', key: 'temps_demande' },
  ];

  /**
   * Download a report as a CSV file
   */
  const downloadReport = async () => {
    setTimeout(() => {
      csvLinkEl?.current.link.click();
    });
  };

  return (
    <>
      <Link to="#" className="action-btns" onClick={downloadReport} data-toggle="tooltip" data-placement="top" title="csv">
        <i className="fa fa-file-excel-o text-success" />
      </Link>
      <CSVLink
        data={columnsToExport}
        headers={headers}
        filename={`${name}.csv`}
        ref={csvLinkEl}
      />
    </>
  );
}

export default ExportCsvSatisfaction;
