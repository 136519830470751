import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { toWords, toWordsOrdinal } from 'number-to-words';
import { Tafgeet } from "tafgeet-arabic";
import Tabletva from '../TableTVA/Tabletva';
import { NumberToLetter } from 'convertir-nombre-lettre';

import fontkit from '@react-pdf/fontkit';
import { date_fr } from '../../../Utils/DateUtils';

// Register the custom font
Font.register({
    family: 'Cairo',
    src: 'https://fonts.googleapis.com/css2?family=Cairo&display=swap',
});

export default function ModalRS({ societe, fournisseur, listrs ,state }) {
 

    return (
        <div className="container mt-5"  id='content'>
            <div className='row'>
                <div className='col-4'>
                    <div className='row center'>
                        <p>REPUBLIQUE TUNISIENNE</p>
                    </div>
                    <div className='row center'>
                        <p>MINISTERE DU PLAN ET DES FINANCES</p>
                    </div>
                    <div className='row center'>
                        <strong>DIRECTION GENERALE</strong>
                    </div>
                    <div className='row center'>
                        <strong>DU CONTROLE FISCAL</strong>
                    </div>
                </div>
                <div style={{ paddingLeft: '200px' }}>
                    <div>
                        <strong className='row center ' style={{ fontFamily: 'calibrer', fontSize: "30px" }}>CERTIFICAT DE RETENUE D'IMPOT </strong>
                        <strong className='row center' style={{ fontFamily: 'calibrer', fontSize: "30px" }}>SUR LE REVENU </strong>
                        <strong className='row center' style={{ fontFamily: 'calibrer', fontSize: "30px" }}> D'IMPOT SUR LES SOCIETES</strong>
                        <p className='row mt-5'>retenue effectuée le {date_fr(state.date_reception)}</p>
                    </div>
                </div>
            </div>
            <div style={{ border: '3px solid black' }}>

                <div className="row">
                    <div class="col-6">
                        <strong style={{ fontFamily: 'calibrer' }}> A-PERSONE OU ORGANISME PAYEUR</strong>
                    </div>
                    <div class="col-6">
                        <div class="row center">
                            <p>IDENTIFIANT</p>
                        </div>
                        <div className='row center'>


                            <table style={{ borderCollapse: 'separate', borderColor: 'black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>Matricule fiscal</th>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>Code T.V.A</th>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>Code catég(2)</th>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>N°Etab Second</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{societe.matricule_fiscal.split('/')[0]}</strong></td>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{societe.matricule_fiscal.split('/')[1]}</strong></td>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{societe.matricule_fiscal.split('/')[2]}</strong></td>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{societe.matricule_fiscal.split('/')[3]}</strong></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-3'>
                        <p style={{ fontFamily: 'calibrer' }}>Nomination de la personne ou l'organisme payeur</p>
                    </div>
                    <div className='col-3'>
                        <strong>{societe.nom.toUpperCase()}</strong>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1'>
                        <p style={{ fontFamily: 'calibrer' }}>Adresse</p>
                    </div>
                    <div className='col-9'>
                        <strong>{societe.adresse.toUpperCase()}</strong>
                    </div>
                </div>

            </div>

            <table style={{ borderCollapse: 'separate', borderColor: 'black' }}>
                <thead>
                    <tr>
                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer', width: '1000px' }}> B-PERSONE OU ORGANISME PAYEUR</th>
                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer', width: '200px' }}>MONTANT BRUT</th>
                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer', width: '200px' }}>RETENUE</th>
                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer', width: '200px' }}>MONTANT NET</th>
                    </tr>
                </thead>
                <tbody>
                    {listrs.map(item => (
                        <tr>
                            <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{item.libelle}</strong></td>
                            <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{item.montant_base}</strong></td>
                            <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{item.montant}</strong></td>
                            <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{item.montant_base + item.montant || 0}</strong></td>
                        </tr>
                    ))}
                    <tr>
                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>Total Général</strong></td>
                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{listrs.reduce((acc, val) => acc + parseFloat(val.montant_base), 0)}</strong></td>
                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{listrs.reduce((acc, val) => acc + parseFloat(val.montant), 0)}</strong></td>
                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{listrs.reduce((acc, val) => acc + parseFloat(val.montant_base + val.montant), 0)}</strong></td>
                    </tr>

                </tbody>
            </table>

            <div style={{ border: '3px solid black' }}>

                <div className="row">
                    <div class="col-6">
                        <strong style={{ fontFamily: 'calibrer' }}> C-BENEFICAIRE</strong>
                        <p className='col-4'>N° de la carte d'identité   ou de séjour pour les étrangers</p>
                    </div>
                    <div class="col-6">
                        <div class="row center">
                            <p>IDENTIFIANT</p>
                        </div>
                        <div className='row center'>


                            <table style={{ borderCollapse: 'separate', borderColor: 'black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>Matricule fiscal</th>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>Code T.V.A</th>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>Code catég(2)</th>
                                        <th style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}>N°Etab Second</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{fournisseur?.matricule_fiscale?.split('/')[0]}</strong></td>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{fournisseur?.matricule_fiscale?.split('/')[1]}</strong></td>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{fournisseur?.matricule_fiscale?.split('/')[2]}</strong></td>
                                        <td style={{ border: '3px solid black', padding: '10px 15px', fontFamily: 'calibrer' }}><strong className='center'>{fournisseur?.matricule_fiscale?.split('/')[3]}</strong></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-3'>
                        <p style={{ fontFamily: 'calibrer' }}>Nom, Prénom ou raison sociale </p>
                    </div>
                    <div className='col-3'>
                        <strong>{fournisseur?.nom?.toUpperCase()}{fournisseur?.prenom?.toUpperCase()}</strong>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <p style={{ fontFamily: 'calibrer' }}>Adresse professionnelle</p>
                    </div>
                    <div className='col-9'>
                        <strong>{fournisseur?.adresse_facturation?.toUpperCase()}</strong>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <p style={{ fontFamily: 'calibrer' }}>Adresse de résidence</p>
                    </div>
                    <div className='col-9'>
                        <strong></strong>
                    </div>
                </div>
            </div>
            <div style={{ borderBottom: '3px solid black' ,borderLeft: '3px solid black'  ,borderRight: '3px solid black'}}>

                <div className="row  center">
                  Je soussigné, certifie exacts les renseignements figurant sur le présent 
                </div>
                <div className="row  center">
                  certificat et m'expose aux sanctions prévues par la loi pour tout inexacitude
                </div>
                <div className="row  mt-8 center">
               <strong>A Tunis,le {date_fr(state.date_reception)}</strong> 
                </div>
                <div className="row  center mb-10">
               <strong>Cachet et signature du payeur</strong> 
                </div>
               
              
            </div>
            <div>
                <span style={{fontSize:'10px'}}>(1) le certificat est délivré a l'occasion de chaque paiement , Toutefois, pour les operations répétitives, le certificat peut étre delivré trimestrielement</span><br/>
                <span style={{fontSize:'10px'}}>(2) Code catégorie : M.personnes morales --C , personnes physique "industrie et commerce" --P,professions liberales --N , employeurs non soumis a l'ipmots sur le revenu ou sur les sociétés(administration et établissement publics) --E établisssements secondaire,</span>

            </div>
        </div>



    )
}
/*<Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.colone}>
                        <View style={styles.row}>
                            <Text style={styles.row}>REPUPLIQUE TUNISIENNE</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.row}>MINSTRE DU PLAN ET DES FINANCE</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.strong}>DIRECTION GENERALE</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.strong}>DU CONTROLE FISCAL</Text>
                        </View>
                    </View>
                    <View style={styles.colone}>
                        <Text style={styles.strong}>CERTIFICAT DE RETENUE D'IMPOT </Text>
                        <Text style={styles.strong}>SUR LE REVENU </Text>
                        <Text style={styles.strong}> D'IMPOT SUR LES SOCIETES</Text>
                        <Text style={styles.row}>retenue effectuée le  15/04/2024</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.border}>

                        <View style={styles.colone}>
                        <Text style={styles.strong}>A-PERSONE OU ORGANISME PAYEUR</Text>

                        </View>
                        <View style={styles.colone}>
                            <View style={styles.section}>

                                <View style={styles.colone_info}>
                                    <Text style={styles.row}>matricule fiscal</Text>
                                </View>
                                <View style={styles.coloneTab}>
                                    <Text style={styles.row}>Code T.V.A</Text>
                                </View>
                                <View style={styles.coloneTab}>
                                    <Text style={styles.row}>Code catég(2)</Text>
                                </View>
                                <View style={styles.coloneTab}>
                                    <Text style={styles.row}>N°Etab Second</Text>
                                </View>

                                
                            </View>
                            <View style={styles.horizontalLine} />
                            <View style={styles.section}>

                                <View style={styles.colone_info}>
                                    <Text style={styles.row}>matricule fiscal</Text>
                                </View>
                                <View style={styles.coloneTab}>
                                    <Text style={styles.row}>Code T.V.A</Text>
                                </View>
                                <View style={styles.coloneTab}>
                                    <Text style={styles.row}>Code catég(2)</Text>
                                </View>
                                <View style={styles.coloneTab}>
                                    <Text style={styles.row}>N°Etab Second</Text>
                                </View>

                                
                            </View>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>*/