import React from "react";

/**
 * It returns a div with an image inside.
 * @returns The JSX element.
 */
function Preloader() {
  return (
    <div id="global-loader">
      <img src="assets/images/svgs/loader.svg" alt="loader" />
    </div>
  );
}

export default Preloader;
