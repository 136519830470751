import React from "react";
import {
  Sparklines,
  SparklinesBars,
  SparklinesReferenceLine,
  SparklinesNormalBand,
  SparklinesSpots,
  SparklinesLine,
} from "react-sparklines";
import { convertTimeToSecond } from "../../../Utils/GlobalTableUtils";

function SpotsSparklineEncaissement({ encaissement }) {
  let listValues = [];
  encaissement.forEach((element) => {
    listValues.push(element.montant_total_ttc);
  });
  // listValues.sort(function (a, b) {
  //   return b - a;
  // });

  return (
    <Sparklines
      data={listValues}
      style={{
        strokeWidth: 3,
        stroke: "#43DC80",
        fill: "none",
      }}
    >
      <SparklinesLine
        size={4}
        style={{
          stroke: "#43DC80",
          strokeWidth: 3,
          fill: "white",
        }}
      />
    </Sparklines>
  );
}

export default SpotsSparklineEncaissement;
