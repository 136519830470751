export const codetva = ['','A','B','P','F','N'];
export const code_CAT = ['','M','C','P','N'];
export const getLettreAssociee = (chiffres) => {
    const CorrespondanceLettre = [
      "",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "J",
      "K",
      "L",
      "M",
      "N",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    if (!/^\d+$/.test(chiffres)) {
      return "";
    }
    const somme = chiffres
      .split("")
      .map((chiffre, index) => parseInt(chiffre) * (7 - index))
      .reduce((acc, val) => acc + val, 0);
    const reste = (somme % 23) + 1;
    if (reste >= 1 && reste <= 23) {
      return CorrespondanceLettre[reste];
    } else {
      return "Numéro hors de la plage valide.";
    }
  };
  export const verifierLettreAssociee = (codeAvecLettre) => {
    if (codeAvecLettre.length !== 8) {
      return false;
    }
    const chiffres = codeAvecLettre.slice(0, 7);
    if (!/^\d+$/.test(chiffres)) {
      return false;
    }
    const lettreAssociee = getLettreAssociee(chiffres);
    return lettreAssociee === codeAvecLettre[7];
  };
  export const MatriculFiscalValidate = (input) => {
    if (input.length !== 13) {
      return false;
    }
    const chiffres = input.slice(0, 7);
    if (!/^\d+$/.test(chiffres)) {
      return false;
    }
    const lettreAssociee = getLettreAssociee(chiffres);
    if (lettreAssociee !== input[7]) {
      return false;
    }
    const characterBeforeZeros = input[9];
    const lastThreeCharacters = input.slice(10);
    if (lastThreeCharacters !== "000") {
      return false;
    }
    if (characterBeforeZeros === "E") {
      return false;
    }
    return true;
  };