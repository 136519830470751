import React, { useEffect, useState } from "react";
import { getRetardByUserId } from "../../Services/Pointeuse/RetardApi";
import { getMonthName } from "../../Utils/DateUtils";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { Link } from "react-router-dom";
import { t } from "i18next";

function LastRetardCalendar({ userId }) {
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  const getData = () => {
    setLoading(true);
    getRetardByUserId(userId)
      .then((res) => {
        const result = res.data;
        if (result.length > 9) {
          setdata(result.slice(0, 9));
        } else {
          setdata(result);
        }
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, [userId]);
  return (
    <div key={userId} className="card">
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <>
          <div className="card-header border-bottom-0">
            <h4 className="card-title">{t("Dernières Retard")}</h4>
          </div>
          <div className="card-body mt-2">
            {data.map((el, idx) => (
              <div key={idx} className="mb-5">
                <div className="d-flex comming_holidays calendar-icon icons">
                  <span className="date_time bg-purple-transparent bradius mr-3">
                    <span className="date fs-20">{el.createdAt.split("-")[2].split(" ")[0]}</span>
                    <span className="month fs-13">{getMonthName(el.createdAt.split("-")[1])}</span>
                  </span>
                  <div className="mr-3 mt-0 mt-sm-2 d-block">
                    <h6 className="mb-1 font-weight-semibold">
                      {el.nom} {el.prenom}
                    </h6>
                    <span className="clearfix" />
                    <small>{el.time}</small>
                  </div>
                  <p className="float-right mb-0  ml-auto  my-auto">
                    <Link to="#" className="btn btn-outline-warning mt-1">
                      <i className="fa fa-hourglass mr-2" />
                      {el.createdAt.split(" ")[1]}
                    </Link>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default LastRetardCalendar;
