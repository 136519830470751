import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CustomSyncLoader from "../../Components/Others/CustomSyncLoader";
import ErrorCustum from "../../Components/Others/ErrorCustum";
import Pagination from "../../Components/Others/Pagination";
import ButtonHeaderComponent from "../../Components/Styles/ButtonHeaderComponent";
import ImageComponent from "../../Components/Styles/ImageComponent";
import { getAllAutorisation } from "../../Services/Pointeuse/AutorisationApi";
import { getAllCongeByCode } from "../../Services/Pointeuse/CongeApi";
import { getAllDeplacementsByCode } from "../../Services/Pointeuse/DeplacementApi";

import { getHolidayByCode } from "../../Services/Pointeuse/HolidayApi";
import { getPersonnelByCode } from "../../Services/Pointeuse/PersonnelApi";
import { getAllPointageHistory, getAllPointages, getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { getQuestionnaireByCode } from "../../Services/Pointeuse/QuestionnaireApi";
import { getRetardByCode } from "../../Services/Pointeuse/RetardApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { calcBusinessDays, filterPointageByMonth, getDaysInMonth } from "../../Utils/GlobalTableUtils";
import SingleTable from "./SingleTable";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

var dateObj = new Date();
var result = dateObj.toISOString().split("T")[0];
var month = result.split("-")[1];
var year = result.split("-")[0];
var newdate = year + "-" + month;

function GlobalTable() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [date, setdate] = useState(newdate);
  const [totalConge, settotalConge] = useState(null);
  const [cp, setcp] = useState(null);
  const [monthRetard, setmonthRetard] = useState(null);
  const [workDay, setworkDay] = useState(null);
  const [breakTime, setbreakTime] = useState(null);
  const [size, setsize] = useState(20);
  const [isCollapsed, setisCollapsed] = useState(false);

  //
  const [dataRetards, setDataRetards] = useState(null);
  const [dataPointages, setDataPointages] = useState(null);
  const [dataConges, setdataConges] = useState(null);
  const [dataDeplacements, setdataDeplacements] = useState(null);
  const [dataPersonnels, setDataPersonnels] = useState(null);
  const [dataAutorisation, setdataAutorisation] = useState(null);
  const [dataQuestionnaires, setdataQuestionnaires] = useState(null);

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const getData = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        setdata(res.data);
        getAllSettings().then((resConge) => {
          console.log("44444444444",resConge)
          settotalConge(resConge.data.totalConge);
          setworkDay(resConge.data.workHoure);
          setmonthRetard(resConge.data.monthRetard);
          setbreakTime(resConge.data.breakTime);

          getHolidayByCode().then((resCp) => {
            const result = resCp.data.filter((el) => {
              return el.date.split("-").slice(0, 2).join("-") == date;
            });
            setcp(result.length);
          });
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  const handleChangeDate = (date) => {
    setLoading(true);
    setdate(date);
    setLoading(false);
  };
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  //getall retard
  useEffect(() => {
    getRetardByCode().then((res) => {
      setDataRetards(res.data);
    });
  }, []);
  useEffect(() => {
    getAllPointageHistory().then((res) => {
      setDataPointages(res.data);
    });
  }, []);

  useEffect(() => {
    getAllCongeByCode().then((res) => {
      setdataConges(res.data);
    });
  }, []);
  useEffect(() => {
    getAllDeplacementsByCode().then((res) => {
      setdataDeplacements(res.data);
    });
  }, []);

  //personnel
  useEffect(() => {
    getPersonnelByCode().then((res) => {
      setDataPersonnels(res.data);
    });
  }, []);

  //autorisation
  useEffect(() => {
    getAllAutorisation().then((res) => {
      setdataAutorisation(res.data);
    });
  }, []);

  useEffect(() => {
    getQuestionnaireByCode().then((res) => {
      setdataQuestionnaires(res.data);
    });
  }, []);

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Collaborateurs")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <Link to="hr-addemployee.html" className="btn btn-primary mr-3">
              Add New Employee
            </Link> */}
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t('Jour de travail en cours')}</span>
                    <h3 className="mb-0 mt-1 text-success">{calcBusinessDays(date)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="las la-calendar" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Jour de travail du mois")}</span>
                    <h3 className="mb-0 mt-1 text-primary">{getDaysInMonth(date)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="las la-calendar" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Reste jour du mois")}</span>
                    <h3 className="mb-0 mt-1 text-secondary">{getDaysInMonth(date) - calcBusinessDays(date)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="las la-calendar" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Heure par mois")}</span>
                    <h3 className="mb-0 mt-1 text-danger">{workDay * getDaysInMonth(date)}:00:00</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="las la-calendar" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Statistique collaborateurs par mois")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("Présence")}
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="feather feather-x-circle text-warning" /> {t("Retard")}
                  </span>
                  <span className="badge badge-primary-light mr-2">
                    <i className="feather feather-x-circle text-primary" /> {t("Non pointage sortie")}
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                  <i className="fa fa-star text-warning" /> ---&gt; Holiday
                </span>
                <span className="badge badge-orange-light mr-2">
                  <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input className="form-control floating" type="month" onChange={(e) => handleChangeDate(e.target.value)} />
                          </label>

                          {/* <Link to="#" className="action-btns" data-tip="télécharger">
                            <i className="feather feather-download  text-secondary" />
                          </Link>

                          <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th>{t("Nom/Prénom")}</th>
                              <th>{t("Fonction")}</th>
                              {/* <th>Vaccin</th> */}
                              <th>{t("Présence")}</th>
                              <th>{t("Absent")}</th>
                              <th>{t("Retard")}</th>
                              <th>{t("Congé")}</th>
                              <th>{t("Télétravail")}</th>
                              <th>{t("Questionnaire")}</th>
                              <th>{t("P.sortie")} </th>
                              {/* <th>cumul heure</th> */}
                              <th data-toggle="tooltip" data-placement="top" title="Cummul des heures travaillé du mois en cours">
                                {t("c.heure")}
                              </th>
                              <th data-toggle="tooltip" data-placement="top" title="Cummul des heures travaillé du mois en cours">
                                {t("T.h")}
                              </th>
                              <th data-toggle="tooltip" data-placement="top" title="Cummul des heures supplémentaire travaillé du mois en cours">
                                {t("h.supp")}
                              </th>
                              {/* <th>heure par mois</th> */}
                              <th data-toggle="tooltip" data-placement="top" title="Moyenne heure par jour">
                                {t("Moyenne")}
                              </th>
                              {/* <th>heure d'absence</th> */}

                              <th data-toggle="tooltip" data-placement="top" title="Cummul retard">
                                {t("c.retard")}
                              </th>
                              {/* <th>non pointage entré</th> */}
                              <th data-toggle="tooltip" data-placement="top" title="Consommé congé">
                                {t("c.congé")}
                              </th>
                              <th data-toggle="tooltip" data-placement="top" title="Reste congé">
                                {t("r.congé")}
                              </th>
                              <th data-toggle="tooltip" data-placement="top" title="Autorisation de sortie">
                                {t("a.sortie")}
                              </th>
                              <th>{t("Déplacement")}</th>
                              {/* <th>Status</th> */}
                            </tr>
                          </thead>
                          {dataRetards &&
                            dataPointages &&
                            dataConges &&
                            dataDeplacements &&
                            dataAutorisation &&
                            dataPersonnels &&
                            dataQuestionnaires && (
                              <tbody>
                                {currentData.map((el) => (
                                  <SingleTable
                                    user={el}
                                    currentDate={date}
                                    totalConge={totalConge}
                                    cp={cp}
                                    workDay={workDay}
                                    monthRetard={monthRetard}
                                    breakTime={breakTime}
                                    dataRetards={dataRetards}
                                    dataPointages={dataPointages}
                                    dataDeplacements={dataDeplacements}
                                    dataConges={dataConges}
                                    dataPersonnels={dataPersonnels}
                                    dataAutorisation={dataAutorisation}
                                    dataQuestionnaires={dataQuestionnaires}
                                  ></SingleTable>
                                ))}
                              </tbody>
                            )}

                          <tfoot>
                            <tr>
                              <th></th>
                              <th></th>
                              <th>{t("Nom/Prénom")}</th>
                              <th>{t("Fonction")}</th>
                              {/* <th>Vaccin</th> */}
                              <th>{t("Présence")}</th>
                              <th>{t("Absent")}</th>
                              <th>{t("Retard")}</th>
                              <th>{t("Congé")}</th>
                              <th>{t("Télétravail")}</th>
                              <th>{t("Questionnaire")}</th>
                              <th>{t("P.sortie")} </th>
                              {/* <th>cumul heure</th> */}
                              <th data-toggle="tooltip" data-placement="top" title="Cummul des heures travaillé du mois en cours">
                                {t("c.heure")}
                              </th>
                              {/* <th>heure par mois</th> */}
                              <th data-toggle="tooltip" data-placement="top" title="Moyenne heure par jour">
                                {t("Moyenne")}
                              </th>
                              {/* <th>heure d'absence</th> */}

                              <th data-toggle="tooltip" data-placement="top" title="Cummul retard">
                                {t("c.retard")}
                              </th>
                              {/* <th>non pointage entré</th> */}
                              <th data-toggle="tooltip" data-placement="top" title="Consommé congé">
                                {t("c.congé")}
                              </th>
                              <th data-toggle="tooltip" data-placement="top" title="Reste congé">
                                {t("r.congé")}
                              </th>
                              <th data-toggle="tooltip" data-placement="top" title="Autorisation de sortie">
                                {t("a.sortie")}
                              </th>
                              <th>{t("Déplacement")}</th>
                              {/* <th>Status</th> */}
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GlobalTable;
