import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CopieContent from "../Exports/CopieContent";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { differenceBetweenTwoTime, differenceBetweenTwoTimeWithBreak, exportDataPointage, sumTime, sumTimeWithBreak } from "../../Utils/DateUtils";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";

function PointageTableMonth({ user }) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(100);
  const [date, setDate] = useState(new Date());
  const [users, setusers] = useState(null);
  const [settings, setsettings] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [conges, setConges] = useState(null);
  const [isShowing, setisShowing] = useState(true);
  const [thisMonth, setthisMonth] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getAllSettings().then((res) => {
      setsettings(res.data);
    });
  }, []);

  const getData = () => {
    setLoading(true);
    getPointageByUserId(user.id)
      .then((res) => {
        setallInitialData(res.data);

        // const currentMonth = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
        // console.log(currentMonth);
        const newFilteredData = handleFilterData(thisMonth, res.data);
        setdata(newFilteredData);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const handleFilterData = (currentMonth, values) => {
    return values.filter((el) => {
      const firstSplit = el.pointage.split(" ")[0];
      const splitArray = firstSplit.split("-");
      const splitedMonth = splitArray[0] + "-" + splitArray[1];
      return splitedMonth == currentMonth;
    });
  };

  /**
   * It gets all the pointages for a given date.
   * @param event - The event object that contains the target.
   */
  const handleChangeDate = (event) => {
    setthisMonth(event.target.value);
    const newFilteredData = handleFilterData(event.target.value, allInitialData);
    setdata(newFilteredData);
  };

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  /**
   * It splits the string by the comma and returns the first element.
   * @param word - The word to be checked
   * @returns The time of checkin.
   */
  const getTimeCheckin = (word) => {
    let timeCheckin = word.split(",");
    return timeCheckin[0].split(" ")[1];
  };
  /**
   * It takes a string and splits it into an array. Then it takes the last element of the array and
   * splits it into another array. Then it takes the second element of that array and returns it.
   * @param word - The word to be checked.
   * @returns The last word of the string, split by a comma.
   */
  const getTimeCheckout = (word) => {
    let timeCheckout = word.split(",");
    if (timeCheckout.length === 1) {
      return "-";
    } else {
      return timeCheckout[timeCheckout.length - 1].split(" ")[1];
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage du mois")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")} : </label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("Pointage d'entrée")}
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="feather feather-x-circle text-warning" /> {t("Pointage de sortie")}
                  </span>
                  <span className="badge badge-primary-light mr-2">
                    <i className="feather feather-x-circle text-primary" /> {t("Total")}
                  </span>
                </div>
              </div>

              <div className="table-responsive">
                <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_length" id="hr-table_length">
                        <label>
                          {t("Afficher")}{" "}
                          <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                            <option value={8}>8</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={60}>60</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          {t("entrées")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div id="hr-table_filter" className="dataTables_filter">
                        <label className="mr-2">
                          <input
                            type="month"
                            className="form-control"
                            placeholder="Recherche..."
                            aria-controls="hr-table"
                            value={thisMonth}
                            onChange={handleChangeDate}
                          />
                        </label>
                        {settings && user && (
                          <ExportCsv
                            data={exportDataPointage(data, settings?.breakTime, user)}
                            name={`Pointage du mois ${thisMonth}_${user?.nom} ${user?.prenom}`}
                          />
                        )}
                        <ExportPdf
                          data={exportDataPointage(data, settings?.breakTime)}
                          name={`Pointage du mois ${thisMonth}_${user?.nom} ${user?.prenom}`}
                          columns={["date", "pointage d'entrée", "pointage de sortie", "heure de travail", "heure de travail avec pause"]}
                        />
                        <CopieContent />
                      </div>
                    </div>
                  </div>

                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data.length > 0 && settings && (
                    <>
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                            id="hr-table"
                            role="grid"
                            aria-describedby="hr-table_info"
                          >
                            <thead>
                              <tr>
                                <th>{t("Date")}</th>
                                <th>{t("Pointage d'entrée")}</th>
                                <th>{t("Pointage de sortie")}</th>
                                <th>
                                  {t("Heures de travail")} <span className="badge badge-pill badge-danger">{sumTime(data)}</span>
                                </th>
                                <th>
                                  {t("Heures de travail avec pause")}{" "}
                                  <span className="badge badge-pill badge-danger">{sumTimeWithBreak(data, settings?.breakTime)}</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentData.map((el) => (
                                <tr key={el.id}>
                                  {console.log("current data :",currentData)}
                                  <td>
                                    <span className="badge badge-default">{el.pointage.split(" ")[0]}</span>
                                  </td>
                                  <td>
                                    <span className="badge badge-success">{getTimeCheckin(el.pointage)}</span>
                                  </td>
                                  <td>
                                    <span className="badge badge-warning">{getTimeCheckout(el.pointage)}</span>
                                  </td>
                                  <td>
                                    <span className="badge badge-info">
                                      {differenceBetweenTwoTime(getTimeCheckin(el.pointage), getTimeCheckout(el.pointage))}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="badge badge-info">
                                      {differenceBetweenTwoTimeWithBreak(
                                        getTimeCheckin(el.pointage),
                                        getTimeCheckout(el.pointage),
                                        settings?.breakTime
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                            {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* leflet */}
      </div>
    </>
  );
}

export default PointageTableMonth;
