import { t } from "i18next";
import React from "react";

/**
 * The Footer function returns a footer element with a container element that has a row element with a
 * col-md-12 element with a text-center element that has a link element with a href attribute that
 * points to the Nomadis website and a link element with a href attribute that points to the top of the
 * page
 * @returns The Footer component is returning a footer element with a class of footer.
 */
function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
            {t("Copyright")} © {new Date().getFullYear()} <a href="http://www.nomadis.online">Clediss</a>.<a href="#"></a> 
            {t("All rights reserved")}.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
