import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};
/**
 * Accept a leave request
 * @param id - id of the conge
 * @param valid - true or false
 * @param user_id
 * @param code_generated
 * @returns The response from the server.
 */
export async function acceptAttestation(id, valid,user_id,code_generated) {
  const credentiel = {
    valid: valid,
    user_id : user_id ,
    code_generated : code_generated,
    type: "attestation",
    id: id,
  };
  try {
    const response = await axios.put(api_url + "attestationtravail/", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
/**
 * It adds a comment to an annonce.
 * @param attestation_id - the id of the attestation you want to comment
 * @param code_generated - the id of the annonce you want to comment
 * @param user_id - the commentaire to add
 * @param valid - the commentaire to add
 * * @param type - the commentaire to add
 * @returns The response from the server.
 */
export async function addAttestation(attestation_id,user_id,code_generated, type,valid) {
  const credentiel = {
    user_id: user_id,
    attestation_id: attestation_id,
    code_generated: code_generated,
    type: type,
    valid:valid
  };
  try {
    const response = await axios.post(api_url + "attestationTravail/", credentiel, config);
    console.log(response);
    toast.success(t("ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}


/**
 * Send an email to a user with a link to confirm the creation of a new attestation
 * @param receiver - the email address of the receiver
 * @param nom - the name of the person who sent the email
 * @param prenom - the first name of the user
 * @param logo - the logo of the company
 * @param id - id of the attestation
 * @param type - type of attestation (ex: demande, attestation.)
 * @param date - date of the request attestation
 * @param valid - valid of the attestation
 * @returns The response is an object that contains the response from the server.
 */
export async function sentAttestationEmail(receiver, nom, prenom,logo, id, type, date, valid) {
  const credentiel = {
    receiver: receiver,
    nom: nom,
    prenom: prenom,
    img: logo,
    id: id,
    type: type,
    date: date,
    valid: valid,
  };
  try {
    const response = await axios.post(api_url + "sendattestation", credentiel, config);
    console.log(response);
    toast.success(t("Attestation de Travail envoyé par email"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Get all demande conge by code
 * @returns The response is an array of objects.
 */
export async function getAllAttestationByCode() {
  try {
    const response = await axios.get(api_url + "attestationtravail/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * It gets the conge of a user by his id.
 * @param id - The id of the user.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "status": 200,
 *   "data": {
 *     "id": 1,
 *     "iduser": 1,
 *     "start": "2020-04-01T00:00:00.000Z",
 *     "end": "2020-04-10T00:00
 */
export async function getAttestationById(id) {
  try {
    const response = await axios.get(api_url + "attestationtravail/demande_attestation/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Send a notification to a specific user
 * @param type - The message you want to send.
 * @param receiver - The receiver of the notification.
 * @returns The response from the server.
 */
export async function senFcmAttestation(type, receiver) {
  const notification = {
    data: {
      title: "Attestation de Travail",
      message: type,
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);
    toast.success(t("Notification envoyée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}