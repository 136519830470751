import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the vaccin by code
 * @returns The response is an array of objects.
 */
export async function getTacheByCode() {
    try {
      const response = await axios.get(api_url + "taches/codetache/" + localStorage.getItem("code_generated"), config);
  
      return response;
    } catch (error) {
      console.error(error);
    }
  }

/**
 * ajouter tache
 * @returns The response is an array of objects.
 */
export async function addTache(data) {
  try {
    const response = await axios.post(api_url + "taches/poste/" , data,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * ajouter demande client
 * @returns The response is an array of objects.
 */
export async function addDemandeClient(data) {
  try {
    const response = await axios.post(api_url + "taches/client/tache" , data,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * list demande client
 * @returns The response is an array of objects.
 */
export async function getDemandeClient() {
  try {
    const response = await axios.get(api_url + "taches/client/tache/"+localStorage.getItem('code_generated') ,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}




/**
 * list suivi demande client
 * @returns The response is an array of objects.
 */
export async function getsuividemandeclient(codeclient) {
  try {
    const response = await axios.get(api_url + "taches/client/suivitache/"+codeclient ,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * update demande client
 * @returns The response is an array of objects.
 */
export async function updateDemandeClient(data) {
  try {
    const response = await axios.put(api_url + "taches/client/tache",data ,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * valide demande client
 * @returns The response is an array of objects.
 */
export async function ValideDemandeClient(data) {
  try {
    const response = await axios.put(api_url + "taches/client/validetache",data ,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * retour demande client
 * @returns The response is an array of objects.
 */
export async function RetourDemandeClient(data) {
  try {
    const response = await axios.put(api_url + "taches/client/retourtache",data ,config);

    return response;
  } catch (error) {
    console.error(error);
  }
}