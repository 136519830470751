import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { MdFingerprint } from "react-icons/md";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
    return {
      //window.removeEventListener('resize', showButton);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar" style={{ backgroundColor: "#1c2237" }}>
          <div className="navbar-container container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              {/* <MdFingerprint className="navbar-icon" /> */}
              <img className="navbar-icon" style={{ width: 50, height: 50 }} src="assets/images/brand/logo.png"></img>
              Pointeuse
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/welcome" className="nav-links" onClick={closeMobileMenu}>
                  Accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="nav-links" onClick={closeMobileMenu}>
                  À propos
                </Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="nav-links" onClick={closeMobileMenu}>
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/price" className="nav-links">
                  Prix
                </Link>
              </li>
              <li className="nav-btn">
                {button ? (
                  <Link to="/login" className="btn-link">
                    <Button buttonStyle="btn--outline">Se connecter</Button>
                  </Link>
                ) : (
                  <Link to="/login" className="btn-link">
                    <Button buttonStyle="btn--outline" buttonSize="btn--mobile" onClick={closeMobileMenu}>
                      SIGN UP
                    </Button>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
