import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get annonces by code
 */
export async function getAllAnnonces() {
  try {
    const response = await axios.get(api_url + "annonce/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * get annonces by code
 */
export async function getAnnonceById(id) {
  try {
    const response = await axios.get(api_url + "annonce/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get annonces by code
 */
export async function getCommantaireAnnonceById(id) {
  try {
    const response = await axios.get(api_url + "annonce/comment/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It deletes an annonce by id.
 * @param id - The id of the annonce to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteAnnonceById(id) {
  try {
    const response = await axios.delete(api_url + "annonce/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a comment to an annonce.
 * @param annonce_id - the id of the annonce you want to comment
 * @param commentaire - the commentaire to add
 * @returns The response from the server.
 */
export async function addAnnonceComment(annonce_id, commentaire) {
  const credentiel = {
    user_id: localStorage.getItem("id"),
    annonce_id: annonce_id,
    code_generated: localStorage.getItem(t("code_generated")),
    commentaire: commentaire,
    date: moment(new Date()).format("YYYY-MM-DD"),
  };
  try {
    const response = await axios.post(api_url + "annonce/comment/", credentiel, config);
    console.log(response);
    toast.success(t("ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It adds an annonce to the database.
 * @param title - The title of the ad
 * @param description - The description of the annonce.
 * @param debut - the date of the beginning of the event
 * @param fin - The date when the job will end.
 * @param img - the image file
 * @returns The response from the server.
 */
export async function ajouterAnnonce(title, description, debut, fin, img) {
  const frmData = new FormData();

  frmData.append("code_generated", getSession(t("code_generated")));
  frmData.append("title", title);
  frmData.append("description", description);
  frmData.append("date_debut", debut);
  frmData.append("date_fin", fin);
  frmData.append("img", img);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "annonce", frmData, options);
    toast.success(t("Annonce ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Send a notification to a specific topic
 * @param text - The title of the notification.
 * @param desc - The description of the notification.
 * @returns The response from the server.
 */
export async function senFcmAnnonce(text, desc) {
  const notification = {
    data: {
      title: text,
      message: desc,
    },
    to: "/topics/" + localStorage.getItem(t("code_generated")) + "user",
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);
    toast.success(t("Notification envoyée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
