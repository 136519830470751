import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { postSettings } from "../../Services/Pointeuse/SettingsApi";
import { addCode } from "../../Services/Pointeuse/UsersApi";
import { useAlert } from "react-alert";
import { hasNumber, hasSymbole, hasUperCase } from "../../Utils/CodeGeneratedUtils";
import moment from "moment";
import { validateDay, validateHoure, validateTime } from "../../Utils/ConfigUtils";
import { addEntreprise } from "../../Services/Pointeuse/EntrepriseApi";

function WizardConfig() {
  const history = useHistory();
  const alert = useAlert();
  const [code, setcode] = useState(null);
  const [nbHoureWork, setnbHoureWork] = useState(null);
  const [nbJourWorkWeek, setnbJourWorkWeek] = useState(null);
  const [totalConge, settotalConge] = useState(null);
  const [startTime, setstartTime] = useState(null);
  const [endTime, setendTime] = useState(null);
  const [breakTime, setbreakTime] = useState(null);
  const [retardDay, setretardDay] = useState(null);
  const [retardMonth, setretardMonth] = useState(null);
  const [nom, setNom] = useState(null);
  const [isLength, setisLength] = useState(true);
  const [isUpercase, setisUpercase] = useState(true);
  const [isNumber, setisNumber] = useState(true);
  const [isSymbol, setisSymbol] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    localStorage.setItem("isLoginPointeuse", false);
  }, []);

  /**
   * * If the length of the code is greater than 7, set isLength to false.
   * * If the code has a uppercase letter, set isUpercase to false.
   * * If the code has a number, set isNumber to false.
   * * If the code has a symbol, set isSymbol to true.
   * * Set code to the value of the input
   * @param value - The value of the input.
   */
  const handleChangeCode = (value) => {
    if (value.length > 7) {
      setisLength(false);
    } else {
      setisLength(true);
    }

    if (hasUperCase(value)) {
      setisUpercase(false);
    } else {
      setisUpercase(true);
    }

    if (hasNumber(value)) {
      setisNumber(false);
    } else {
      setisNumber(true);
    }

    if (hasSymbole(value)) {
      setisSymbol(true);
    } else {
      setisSymbol(false);
    }

    setcode(value);
  };

  /**
   * It saves the configuration of the company.
   * @param e - The event object that contains the data of the event that triggered the function.
   */
  const handleSaveConfig = (e) => {
    setloading(true);
    e.preventDefault();
    if (
      code == null ||
      nbHoureWork == null ||
      startTime == null ||
      endTime == null ||
      nbJourWorkWeek == null ||
      breakTime == null ||
      totalConge == null ||
      retardMonth == null ||
      retardDay == null ||
      nom == null
    ) {
      //toast.error("vérifié les champs vide");
      alert.error("vérifié les champs vides");
    } else {
      if (
        validateTime(startTime) &&
        validateTime(endTime) &&
        validateTime(breakTime) &&
        validateTime(retardDay) &&
        validateTime(retardMonth) &&
        validateHoure(nbHoureWork) &&
        validateDay(nbJourWorkWeek)
      ) {
        //add settings
        postSettings(code, nbHoureWork, startTime, endTime, nbJourWorkWeek, breakTime, totalConge, retardMonth, retardDay).then(() => {
          //add code in table user
          addCode(code).then((res) => {
            //add entreprise
            addEntreprise(code, "36.806644", "10.167727", nom).then(() => {
              console.log(res);
              alert.success("configuration ajouté");
              history.push("/");
            });
          });
        });
      } else {
        alert.error("vérifié les champs non valides");
        setloading(false);
      }
    }
  };

  // const handleSetStartTime = (value) => {
  //   console.log(validateTime(value));
  //   setstartTime(value);
  // };

  return (
    <div className="page login-bg mt-4">
      <div className="page-single">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header border-bottom-0">
                  <div className="card-title">Configuration de session administrateur</div>
                </div>
                <div className="card-body">
                  <div id="wizard1">
                    <section>
                      <div className="control-group form-group">
                        <label className="form-label">
                          Nom de l'entreprise<span className="text-danger"> *</span>
                        </label>

                        <input
                          type="text"
                          className="form-control required"
                          placeholder="nom"
                          required
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                        />

                        <label className="form-label mt-4">
                          Code<span className="text-danger"> *</span>
                        </label>

                        <input
                          type="text"
                          className="form-control required"
                          placeholder="code"
                          required
                          value={code}
                          onChange={(e) => handleChangeCode(e.target.value)}
                        />
                        <small className="form-text text-muted  mb-5">
                          Ce code est obligatoire il sera utilisé ensuite pour ajouter des collaborateurs.
                        </small>

                        {isLength && (
                          <div className="alert alert-warning" role="alert">
                            Le code doit contenir au mois 8 caractères
                          </div>
                        )}
                        {isUpercase && (
                          <div className="alert alert-warning" role="alert">
                            Le code doit contenir majuscule
                          </div>
                        )}
                        {isNumber && (
                          <div className="alert alert-warning" role="alert">
                            Le code doit contenir chiffre
                          </div>
                        )}

                        {isSymbol && (
                          <div className="alert alert-danger" role="alert">
                            Pas de symboles
                          </div>
                        )}
                      </div>
                    </section>

                    <h3>Paramètres générales</h3>
                    <form onSubmit={handleSaveConfig}>
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label className="form-label">
                              Nombre d'heures de travail par jour<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="name1"
                              placeholder="ex : 8"
                              required
                              value={nbHoureWork}
                              onChange={(e) => setnbHoureWork(e.target.value)}
                              style={validateHoure(nbHoureWork) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label className="form-label">
                              Nombre des jours de travail par semaine<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="name1"
                              placeholder="ex : 5"
                              required
                              value={nbJourWorkWeek}
                              style={validateDay(nbJourWorkWeek) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                              onChange={(e) => setnbJourWorkWeek(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label className="form-label">
                              Total Congé<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="name1"
                              placeholder="ex : 18"
                              required
                              value={totalConge}
                              onChange={(e) => settotalConge(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label className="form-label">
                              Heure début<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="08:00:00 (hh:mm:ss)"
                              required
                              value={startTime}
                              onChange={(e) => setstartTime(e.target.value)}
                              style={validateTime(startTime) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                            />
                            <small className="form-text text-muted  mb-5">note : pour 8h choisir 08:00:00</small>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label className="form-label">
                              Heure fin<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="17:00:00 (hh:mm:ss)"
                              required
                              value={endTime}
                              onChange={(e) => setendTime(e.target.value)}
                              style={validateTime(endTime) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                            />
                            <small className="form-text text-muted  mb-5">note : pour 17h choisir 17:00:00</small>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label className="form-label">
                              Durée de pause<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="01:00:00 (hh:mm:ss)"
                              required
                              value={breakTime}
                              onChange={(e) => setbreakTime(e.target.value)}
                              style={validateTime(breakTime) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                            />
                            <small className="form-text text-muted  mb-5">note : pour une heure choisir 01:00:00</small>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">
                              Retard par jour<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="00:15:00 (hh:mm:ss)"
                              required
                              value={retardDay}
                              onChange={(e) => setretardDay(e.target.value)}
                              style={validateTime(retardDay) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                            />
                            <small className="form-text text-muted  mb-5">note : pour une demi-heure choisir 00:30:00</small>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label className="form-label">
                              Retard par mois<span className="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name1"
                              placeholder="03:00:00 (hh:mm:ss)"
                              required
                              value={retardMonth}
                              onChange={(e) => setretardMonth(e.target.value)}
                              style={validateTime(retardMonth) ? { backgroundColor: "white" } : { backgroundColor: "red", color: "white" }}
                            />
                            <small className="form-text text-muted  mb-5">note : pour 3 heures choisir 03:00:00</small>
                          </div>
                        </div>
                      </div>
                      {hasNumber(code) && hasUperCase(code) && code.length > 7 && !hasSymbole(code) && (
                        <div className="card-footer text-right">
                          {loading ? (
                            <button className="btn btn-info btn-loading btn-block">Enregistrer</button>
                          ) : (
                            <button type="submit" className="btn btn-primary btn-block">
                              Enregistrer
                            </button>
                          )}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WizardConfig;
