import React, { useEffect, useRef, useState } from "react";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { Link } from "react-router-dom";
import { addPost, getAllPosts } from "../../Services/Blog/BlogApi";
import ProfileCard from "./ProfileCard";
import MyPosts from "./MyPosts";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ImageComponent from "../Styles/ImageComponent";
import { getSession } from "../../Utils/SessionUtils";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon, WhatsappIcon } from "react-share";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function Blog() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t

  const myImage = useRef(null);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Ajouter une image");

  const [description, setdescription] = useState("");

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets all the posts from the database and stores them in the data variable.
   */
  const getData = () => {
    setLoading(true);
    getAllPosts()
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      // const dataSearch = filterCongeByWord(allInitialData, e.target.value);
      // setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * * Set the image to the image file that was uploaded.
   * * Set the image url to the image file that was uploaded.
   * * Set the image name to the image file that was uploaded
   * @param event - The event that triggered the function.
   */
  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };
  /**
   * Add a new post to the database
   */

  const handleSubmit = () => {
    addPost("", description, imageUrl).then(() => {
      setdescription("");
      setImage(null);
      getData();
    });
  };

  /**
   * It opens a file dialog box.
   */
  const openFile = () => {
    myImage.current.click();
  };
  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Blog")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              <i className="feather feather-mail" />
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              <i className="feather feather-phone-call" />
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              <i className="feather feather-info" />
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row row-sm">
        <div className="col-lg-8">
          <div className="card">
            <div className="tab-menu-heading p-0 border-0">
              <div className="tabs-menu1 px-3">
                <ul className="nav">
                  <li>
                    <a href="#tab-7" className="active" data-toggle="tab">
                      {t("Post")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="#tab-8" data-toggle="tab" className>
                      Albums
                    </a>
                  </li>
                  <li>
                    <a href="#tab-9" data-toggle="tab" className>
                      Videos
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="card-body d-flex border-top">
              {/* <div className="mr-4">
                <img alt className="rounded-circle avatar avatar-md" src={image} />
              </div> */}
              <div className="profile-edit w-100">
                <textarea
                  className="form-control"
                  placeholder={t("Quoi de neuf ?")}
                  rows={5}
                  onChange={(e) => setdescription(e.target.value)}
                  value={description || ""}
                />
                {image ? <img className="w-40 m-1" alt="image produit" src={image} /> : <></>}
                <div className="form-group" style={{ display: "none" }}>
                  <div className="custom-file">
                    <input
                      ref={myImage}
                      accept="image/*"
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={handleImgClient}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {imageName}
                    </label>
                  </div>
                </div>
                <div className="profile-share border-top-0">
                  <div className="mt-2">
                    {/* <a
                      href="#"
                      className="mr-2"
                      title
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Audio"
                    >
                      <span className="feather feather-mic fs-16 text-muted" />
                    </a>
                    <a
                      href="#"
                      className="mr-2"
                      title
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Video"
                    >
                      <span className="feather feather-video fs-16 text-muted" />
                    </a> */}
                    <Link
                      to="#"
                      className="mr-2"
                      title
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Picture"
                      onClick={openFile}
                    >
                      <span className="feather feather-image fs-16 text-muted" />
                    </Link>
                  </div>
                  <button className="btn btn-sm btn-success ml-auto" onClick={handleSubmit}>
                    <i className="fa fa-share ml-1" /> {t("Partager")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          {!loading && error && <ErrorCustum></ErrorCustum>}
          {!loading && !error && data && (
            <>
              {data.map((el, idx) => (
                <div key={idx} className="card overflow-hidden">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="media mt-0">
                        <div className="media-user mr-2">
                          <div className>
                            <img
                              alt
                              className="rounded-circle avatar avatar-md"
                              src={el.img ? localStorage.getItem("baseUrl") + el.img : "assets/images/users/avatar.png"}
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <h6 className="mb-0 mt-1">
                            @{el.nom} {el.prenom}
                          </h6>
                          <small className="text-muted">{el.createdAt}</small>
                        </div>
                      </div>
                      <div className="ml-auto">
                        <div className="dropdown show">
                          <a className="new option-dots" href="JavaScript:void(0);" data-toggle="dropdown">
                            <span className="feather feather-more-vertical" />
                          </a>
                          {el.user_id == getSession("id") && (
                            <div className="dropdown-menu dropdown-menu-right">
                              <a className="dropdown-item" href="#">
                                {("Editer")}
                              </a>
                              <a className="dropdown-item" href="#">
                                {t("Supprimer")}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="mb-0">{el.title}</p>
                      <p className="mb-0">{el.description}</p>
                      <div className="d-flex">
                        {/* <img src="assets/images/photos/2.jpg" alt="img" className="w-40 m-1" /> */}

                        {el.image ? (
                          <img
                            className="w-40 m-1"
                            src={localStorage.getItem("baseUrl") + el.image}
                            alt="post img"
                          ></img>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="media mt-0">
                      {/* <div className="media-user mr-2">
                        <div className="avatar-list avatar-list-stacked">
                          <span
                            className="avatar brround"
                            style={{ backgroundImage: "url(assets/images/users/12.jpg)" }}
                          />
                          <span
                            className="avatar brround"
                            style={{ backgroundImage: "url(assets/images/users/2.jpg)" }}
                          />
                          <span
                            className="avatar brround"
                            style={{ backgroundImage: "url(assets/images/users/9.jpg)" }}
                          />
                          <span
                            className="avatar brround"
                            style={{ backgroundImage: "url(assets/images/users/2.jpg)" }}
                          />
                          <span
                            className="avatar brround"
                            style={{ backgroundImage: "url(assets/images/users/4.jpg)" }}
                          />
                          <span className="avatar brround">+28</span>
                        </div>
                      </div>
                      <div className="media-body">
                        <h6 className="mb-0 mt-2 ml-2">28 people like your photo</h6>
                      </div> */}
                      <div className="ml-auto">
                        <div className="d-flex mt-1">
                          {/* <a className="new mr-2 text-muted fs-18" href="JavaScript:void(0);">
                            <span className="feather feather-heart" />
                          </a>
                          <a className="new mr-2 text-muted fs-18" href="JavaScript:void(0);">
                            <span className="feather feather-message-square" />
                          </a> */}
                          {/* <a className="new text-muted fs-18" href="JavaScript:void(0);">
                            <span className="feather feather-share-2" />
                          </a> */}
                          <FacebookShareButton
                            url={"https://demo.pointeuse.clediss.ovh/"}
                            quote={el.title + ""}
                            hashtag={"#pointeuse"}
                            description={el.description + ""}
                            className="Demo__some-network__share-button mr-2"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={"https://demo.pointeuse.clediss.ovh/"}
                            quote={el.title + ""}
                            hashtag={"#pointeuse"}
                            description={el.description + ""}
                            className="Demo__some-network__share-button mr-2"
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={"https://demo.pointeuse.clediss.ovh/"}
                            quote={el.title + ""}
                            hashtag={"#pointeuse"}
                            description={el.description + ""}
                            className="Demo__some-network__share-button mr-2"
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                          <EmailShareButton
                            url={"https://demo.pointeuse.clediss.ovh/"}
                            quote={el.title + ""}
                            hashtag={"#pointeuse"}
                            description={el.description + ""}
                            className="Demo__some-network__share-button mr-2"
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                          <WhatsappShareButton
                            url={"https://demo.pointeuse.clediss.ovh/"}
                            quote={el.title + ""}
                            hashtag={"#pointeuse"}
                            description={el.description + ""}
                            className="Demo__some-network__share-button mr-2"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="col-lg-4">
          <div className="card mg-b-20 card--events">
            <div className="card-header border-bottom-0">
              <div className="card-title">{t("Mes postes")}</div>
            </div>
            <div className="card-body p-0">
              <div className>
                <MyPosts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
