import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllMaterielById } from "../../Services/Pointeuse/MaterielApi";
import { getIdVideoYoutube } from "../../Utils/mediaUtils";

function DetailsMateriel(props) {
  const [data, setdata] = useState(null);

  useEffect(() => {
    getAllMaterielById(props.id).then((res) => {
      console.log(res);
      setdata(res.data);
    });
  }, []);

  return (
    <div className="row no-gutters">
      <>
        {data && (
          <div className="p-4">
            <div className="row">
              <div className="col-md-6">
                <div className="myIframe">
                  <iframe
                    src={"https://www.youtube.com/embed/" + getIdVideoYoutube(data.video_url)}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <div className="pro-img-list"></div>
              </div>
              <div className="col-md-6">
                <h4 className="pro-d-title ml-4" style={{ fontSize: 28 }}>
                  <a href="#">{data.titre}</a>
                </h4>
                <img
                  className="m-2"
                  src={
                    data.image == null || data.image == ""
                      ? "dist/img/user/avatar.png"
                      : localStorage.getItem("baseUrl") + data.image
                  }
                  alt=""
                />
                <p>{data.description}</p>
                <div className="product_meta">
                  <span className="posted_in">
                    <a href={data.url}>{data.url}</a>
                  </span>
                  <span className="posted_in">
                    <small>Date : </small>
                    <b>{data.created_at}</b>
                  </span>
                  <span className="tagged_as">
                    <small>Collaborateur : </small>
                    <b>
                      {data.nom} {data.prenom}
                    </b>
                  </span>
                  {/* <span className="badge badge-warning">{data.type}</span> */}
                </div>
                <div className="mb-4">
                  <strong>Price : </strong>
                  <span className="pro-price">{data.prix} DT</span>
                </div>

                {data.type === "demande" && (
                  <p>
                    <button className="btn btn-round btn-success" type="button">
                      Accepter
                    </button>
                    <button className="btn btn-round btn-danger ml-2" type="button">
                      Refuser
                    </button>
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default DetailsMateriel;
