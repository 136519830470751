import React, { useEffect, useState } from 'react';
import { getAllDevise } from '../../Services/devise/devise';
import { useTranslation } from 'react-i18next';
import { savebanque, updatebanque } from '../../Services/banque';
import { toast } from 'react-toastify';
import { addExercice, updateExercice } from '../../Services/Pointeuse/EntrepriseApi';
import moment from "moment";
import { yearlist } from '../../Utils/Data/yearList';
import Select from 'react-select';

export default function AjoutExercice({ list, data, getData }) {


    const { t } = useTranslation();

    const [modif, setmodif] = useState(false)

    const [formData, setFormData] = useState({

        code_exercice: '',
        date_debut: '',
        date_fin: ''
    });
    useEffect(() => {
        if (data && data?.id) {
            setmodif(true)
            setFormData({
                id: data.id,
                code_exercice: data.code_exercice,
                date_debut: data.date_debut,
                date_fin: data.date_fin
            })
        }
    }, [data])



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'code_exercice') {

            const date_debut = new Date(`${value}-01-01`);
            const date_fin = new Date(`${value}-12-31`);


            setFormData({
                ...formData,
                code_exercice: value,
                date_debut: date_debut.toISOString().split('T')[0],
                date_fin: date_fin.toISOString().split('T')[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: (name === 'date_debut' || name === 'date_fin') ? new Date(value).toISOString().split('T')[0] : value
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newdata = { ...formData }
        newdata.code_societe = localStorage.getItem('code_generated');
        const findsame_code = list.find(item => item.code_exercice === formData.code_exercice)

        const findsame_periode = list.find(item => new Date(item.date_debut).toISOString() === new Date(formData.date_debut).toISOString() && new Date(item.date_fin).toISOString() === new Date(formData.date_fin).toISOString());


        if (findsame_code || findsame_periode) {
            toast.warning(t("l'exercice deja existe"))
        }
        else {
            if (formData && formData?.id) {
                newdata.id = data?.id
                updateExercice(newdata).then((res) => {
                    if (res.status === 200) {
                        toast.success(t("l'exercice est modifié"))
                        getData()
                        setFormData({
                            code_exercice: '',
                            date_debut: '',
                            date_fin: ''
                        })
                    }
                });
            } else {
                addExercice(newdata).then((res) => {
                    if (res.status === 201) {
                        toast.success(t("L'exercice est enregistrée"))
                        getData()
                        setFormData({

                            code_exercice: '',
                            date_debut: '',
                            date_fin: ''
                        })

                    }
                });
            }
        }


    };
    const handleSelectChange = selectedOption => {
        handleInputChange({
            target: {
                name: 'code_exercice',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>

                <div className='row mt-2'>
                    <label>{t("code exercice")}</label>

                    <Select
                        name='code_exercice'
                        className='form-control'
                        value={{
                            value: formData.code_exercice,
                            label: formData.code_exercice
                        }}
                        onChange={handleSelectChange}
                        options={yearlist()}
                        isDisabled={modif}
                        isClearable
                        required
                    />
                </div>
                <div className='row mt-2'>
                    <label>{t('Date debut')}</label>
                    <input type='date' readOnly={modif} name='date_debut' className='form-control' value={moment(new Date(formData.date_debut)).format("YYYY-MM-DD")} onChange={handleInputChange} required />

                </div>
                <div className='row mt-2'>
                    <label>{t('Date fin')}</label>
                    <input type='date' readOnly={modif} name='date_fin' className='form-control' value={moment(new Date(formData.date_fin)).format("YYYY-MM-DD")} onChange={handleInputChange} required />

                </div>
                <div className='row mt-3'>
                    {modif ? (<a onClick={() => setmodif(false)} className="btn btn-primary float-right">
                        <i className="far fa-credit-card" /> {t("Modifier")}
                    </a>
                    ) :
                        <button type="submit" className="btn btn-primary float-right">
                            <i className="far fa-credit-card" /> {t("Enregistrer")}
                        </button>
                    }

                </div>
            </form>
        </div>
    );
}
