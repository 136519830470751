import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Marque by code
 */
export async function getAllMarques() {
  try {
    const response = await axios.get(api_url + "facturation/marque/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get a marque by id
 * @param id - The id of the marque you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": 1,
 *     "name": "Marque 1"
 *   }
 * }
 * ```
 */
export async function getMarqueById(id) {
  try {
    const response = await axios.get(api_url + "facturation/marque/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get the marque by code id
 * @param code - The code of the marque you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "marque": {
 *       "code": "1",
 *       "libelle": "A"
 *     }
 *   }
 * }
 * ```
 */
export async function getMarqueByCodeId(code) {
  try {
    const response = await axios.get(api_url + "facturation/marque/codeid/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a new marque to the database.
 * @param libelle - The name of the brand.
 * @param plafond_credit - The maximum amount of credit that the client can have.
 * @returns The response is an object that contains the data, status, and other information returned by
 * the server.
 */
export async function ajouterMarque(libelle, plafond_credit) {
  const credentiel = {
    soussociete_code: getSession("code_generated"),
    libelle: libelle,
    plafond_credit: plafond_credit,
  };

  try {
    const response = await axios.post(api_url + "facturation/marque/", credentiel, config);
    console.log(response);
    toast.success(t("Marque ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
/**
 * delete Marque by code
 */
export async function deleteMarqueById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/marque/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It edits a marque.
 * @param id - id of the marque to edit
 * @param libelle - the name of the marque
 * @param plafond_credit - The maximum amount of credit that the client can have.
 * @returns The response is an object that contains the data from the server.
 */
export async function editMarque(id, libelle, plafond_credit) {
  const credentiel = {
    libelle: libelle,
    plafond_credit: plafond_credit,
    id: id,
  };

  try {
    const response = await axios.patch(api_url + "facturation/marque/", credentiel, config);
    console.log(response);
    toast.success("Marque modifié");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
