import React, { useEffect, useState } from 'react'
import { getAllSatisfactionbyclient } from '../../../Services/Pointeuse/SatisfactionApi';
import { t } from "i18next";
import { Link } from "react-router-dom"; 
 import { filterUser, filterUserSatisfaction } from "../../../Utils/SortObject"; 
import CopieContent from "../../Exports/CopieContent";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent"; 
import moment from "moment"; 
import 'moment-duration-format'; // Importez le module de formatage pour moment
import ExportCsvSatisfaction from "../../Exports/ExportCsvSatisfaction";
import { error_message } from "../../alerte/AlerteMessage";
export default function Listsatisfactionclient({code_client}) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]); 
  const [size, setsize] = useState(8); 
  const [isCollapsed, setisCollapsed] = useState(false); 
  // -------- DATE DEBUT / DATE FIN ----------- 
 const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
 const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
   var dateObj = new Date();
    var result = dateObj.toISOString().split("T")[0];
    var month = result.split("-")[1];
    var year = result.split("-")[0];
    var newdate = year + "-" + month;
  const [date, setdate] = useState(newdate);
  const [cumulativeTimeTotal, setCumulativeTimeTotal] = useState({});

  
  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * This function is used to get the data from the API and then set the data to the state
   */
  const getData = () => {
    setLoading(true);
   
    getAllSatisfactionbyclient( code_client)
      .then((res) => {
         setdata(res.data); 
        setallInitialData(res.data); 
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
 
  useEffect(() => {
    getData();
  }, []);
 

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data based on the search input.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    const inputValue = e.target.value || ''; // Ensure a default value if e.target.value is undefined
    const dataSearch = filterUserSatisfaction(allInitialData, inputValue);
    setdata(dataSearch);
  };

 
 const updateCurrentData = (newData) => {
  setcurrentData(newData);
};
const onChangeDate = () => {
  const filteredData = filterByDate(allInitialData, startDate, endDate);
  updateCurrentData(filteredData);
};

const filterByDate = (allInitialData, startDate, endDate) => {
   if (startDate === '' || endDate === '') {
    // Si l'une des dates est vide, ne filtre pas et affiche toutes les données.
    return allInitialData;
  } else {
    const filteredDate = allInitialData.filter((listed) => {
      const momentDate = moment(listed.date.split(" ")[0]);
       if (moment(startDate).isAfter(endDate)) {
        error_message(t("Attention, la date de début doit être antérieure à la date de fin !"));
      } else {
        return (
          momentDate.isSameOrAfter(moment(startDate)) &&
          momentDate.isSameOrBefore(moment(endDate))
        );
      }
    });
    return filteredDate;
  }
}; 

 
  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };


useEffect(() => {
  // Calculer le cumul total du temps de retard pour chaque utilisateur
  const calculateCumulativeTimeTotal = (data) => {
    const cumulativeTimeTotal = {};
  
    data.forEach((el) => {
      const monthKey = moment(el.createdAt).format('YYYY-MM');
      const cumulativeTime = cumulativeTimeTotal[el.user_id] || {};
      cumulativeTime[monthKey] = (cumulativeTime[monthKey] || moment.duration(0)).add(moment.duration(el.retardTime));
      cumulativeTimeTotal[el.user_id] = cumulativeTime;
    });
  
    return cumulativeTimeTotal;
  };
  
  
   // Mettre à jour le state cumulativeTimeTotal
  setCumulativeTimeTotal(calculateCumulativeTimeTotal(currentData));
}, [currentData]);
 
 
  return ( 
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Satisfaction")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <Link to="hr-addemployee.html" className="btn btn-primary mr-3">
            Add New Employee
          </Link> */}
          <Link to={localStorage.getItem('role_code') === 'admin' ? '/admin/ajoutsatisfaction' : '/user/ajoutsatisfaction' }  className="btn btn-primary mr-3">
                {t("Ajouter Satisfaction")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>    
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List des Satisfaction")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>
            <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                         
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                         <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                         
                          onChange={(e) => setEndDate(e.target.value)}
                         
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button 
                      onClick={() => onChangeDate()}
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>
                     
              </div>
            </div>
          </div>
        </div>
      </div>
            
          </div>
          </div>
            <div className="card-body"> 
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsvSatisfaction data={data} name={"List_satisfaction"} filteredData={currentData}/>
                          <ExportPdf data={data} name={"List_satisfaction"} columns={["client", "support", 
                          "nom_client","date","methode_contact"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">#{t("ID")}</th>
                              <th className="border-bottom-0">{t("Clients")}</th>
                              <th className="border-bottom-0">{t("Meriam vs koubaib")}</th>
                              <th className="border-bottom-0">{t("Nom de Client")}</th>
                              <th className="border-bottom-0">{t("Date")}</th>
                              <th className="border-bottom-0">{t("Méthode de Contact")}</th>
                              <th className="border-bottom-0">{t("Destinataire")}</th>
                             
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx} role="row" className="odd">
                                    <td>
                                  <Link
                                    to={{
                                      pathname: localStorage.getItem('role_code') === 'admin' ? `/admin/editsatisfaction/` : `/user/editsatisfaction`,
                                      state: { el }
                                    }}
                                    key={idx}
                                    className="dropdown-item"
                                  >
                                    #{t(el.id)}
                                  </Link>
                                </td>
                                <td>
                                  <div className="d-flex">
                                     <div className="mr-8 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom_client}  
                                      </h6>
                                     </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="badge badge-info">{el.support}</span>
                                </td> 
                              <td className="text-left" style={{ fontWeight: "bold" }}>
                                <span className="badge badge-primary">{ el.nom_client}
                                </span>
                              </td>
                                <td className="text-left" >
                                  {moment(el.date).format("YYYY-MM-DD")}
                                </td> 
                               <td className="text-left" >
                                  {el.methode_contact}
                                </td>
                               <td className="text-left" >
                                  {el.destinaire_num_tel}
                                </td>
                                
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
