import $ from "jquery";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import {  editCongeType, senFcmConge,  } from "../../Services/Pointeuse/CongeApi";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import ImageComponent from "../Styles/ImageComponent";
import firebase from "../../firebase";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import { acceptAttestation, sentAttestationEmail } from "../../Services/Pointeuse/AttestationApi";
import moment from "moment";
import { toast } from "react-toastify";
import { acceptDeplacement } from "../../Services/Pointeuse/DeplacementApi";

function TableDeplacement({ onUpdate, arrayObject, valid, onRemoveobject }) {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const history = useHistory();
  const [logo, setlogo] = useState(null);
  const [data, setdata] = useState(arrayObject);

  useEffect(() => {
    setdata(arrayObject);
  }, [arrayObject]);
  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setlogo(res.data.logo);
    });
  }, []);
  /**
   * Accept a leave request
   * @param code_generated
    * @param id - id of the conge
    * @param etat - true or false
    * @param date_debut
    * @param date_fin
    * @param destination
    * @param depart
    * @param user_code_id
   */
  const handleAccept = (code_generated,
    id,
    etat,
    date_debut,
    date_fin,
    destination,
    depart,
    user_code_id,
    type) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté le déplacement !"),
      icon: "warning",
      // buttons: true,
      buttons: {
      cancel: t("Cancel"), // Traduisez le bouton "Cancel"
      confirm: {
        text: t("OK"), // Traduisez le bouton "OK"
        value: true,
      },
    },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptDeplacement(code_generated,id,"1",
        date_debut,
        date_fin,
        destination,
        depart,
        user_code_id,
        type).then(() => {
          toast.success("deplacement accepté");
            const updatedData = arrayObject.filter((item) => item.id != id);
            setdata(updatedData);
            onRemoveobject(id);

            sendFirebaseNotification(etat, user_code_id);
          });
      }
    });
  };

  /**
   * It sends a notification to the user.
   * @param type - The type of notification to send.
   * @param receiver - The user's token.
   */
  const sendFirebaseNotification = (type, receiver) => {
    const messaging = firebase.messaging();
    
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return messaging.getToken();
      } else {
        throw new Error("Permission not granted");
      }
    })
    .then(() => {
      senFcmConge(type, receiver);
    })
    .catch((err) => {
      console.log(err);
    });
  };
  
  // const sendFirebaseNotification = (type, receiver) => {
  //   const messaging = firebase.messaging();
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       return messaging.getToken();
  //     })
  //     .then(() => {
  //       senFcmConge(type, receiver);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

 /**
   * Accept a leave request
   * @param code_generated
    * @param id - id of the conge
    * @param etat - true or false
    * @param date_debut
    * @param date_fin
    * @param destination
    * @param depart
    * @param user_code_id
   */
  const handleReject = (code_generated,
    id,
    etat,
    date_debut,
    date_fin,
    destination,
    depart,
    user_code_id,
    type) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé cette déplacement !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptDeplacement(code_generated,id,"0",
        date_debut,
        date_fin,
        destination,
        depart,
        user_code_id,
        type).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          const updatedData = arrayObject.filter((item) => item.id !== id);
          setdata(updatedData);
          sendFirebaseNotification(t("refusé"), etat);
        });
      }
    });
  };




  
  /**
   * It navigates to the congeprint page with the id of the conge as a parameter.
   * @param id - The id of the conge to print.
   * @param obj - The object that you want to pass to the next page.
   */
  // const navigateToDecharge = (id, obj) => {
  //   //history.push("/admin/congeprint/" + id, { state: obj });
  //   history.push({ pathname: "/admin/congeprint/" + id, obj });
  // };

  const calculateDays = (startDate, endDate) => {
    // Vérifiez si les dates sont valides
    if (!startDate || !endDate) {
      return 'Dates invalides';
    }
  
    const startDateObj = moment(startDate, 'YYYY-MM-DD');
    const endDateObj = moment(endDate, 'YYYY-MM-DD');
  
    if (!startDateObj.isValid() || !endDateObj.isValid()) {
      return 'Dates invalides';
    }
  
    const daysDifference = endDateObj.diff(startDateObj, 'days');
  
  
    return `${daysDifference}`;
  };
  



  return (
    <Fragment>
      <table className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer">
        <thead>
          <tr>
            <th className="border-bottom-0 w-5"></th>
            {/* <th>Collaborateur</th> */}
            <th className="border-bottom-0 w-5">{t("destination")}</th>
            <th className="border-bottom-0 w-5">{t("date_debut")}</th>
            <th className="border-bottom-0 w-5">{t("date_fin")}</th>
            <th className="border-bottom-0 w-5">{t("depart")}</th>
            <th className="border-bottom-0 w-5">{t("NB jours")}</th>
            <th className="border-bottom-0 w-5">{t("type")}</th>
            <th className="border-bottom-0 w-5">{t("Statut")}</th>
            <th className="border-bottom-0 w-5"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <tr key={el.id}>
              <td>
                <div className="d-flex">
                  <ImageComponent picture={el.imguser} atr={"avatar avatar-md brround mr-3"}></ImageComponent>
                  <div className="mr-8 mt-0 mt-sm-1 d-block">
                    <h6 className="mb-1 fs-14">
                      {el.nom} {el.prenom}
                    </h6>
                    <p className="text-muted mb-0 fs-12">{el.email}</p>
                  </div>
                </div>
              </td>
              <td>{el.destination}</td>
              <td>{el.date_debut}</td>
              <td>
              {el.date_fin}
              </td>
              <td>
              {t(el.depart)}
              </td>
              <td>{calculateDays(el.date_debut, el.date_fin)} {t("jours")}</td>
              <td>
              {t(el.type)}
              </td>
              <td className="text-center">
                {el.etat == 0 ? (
                  <span className="badge badge-danger">{t("refusé")}</span>
                // ) : el.valid == 1 ? (
                //   <span className="badge badge-success">{t("accepté")}</span>
                ) : (
                  <span className="badge badge-success">{t("accepté")}</span>
                )}
              </td>

              {/* file */}
              <td className="text-center">
                {el.etat === "deplacement" && el.etat === 1 && (
                  <Link to="#" data-toggle="tooltip" data-placement="top" title="titre attestation" 
                  //onClick={() => navigateToDecharge(el.id, el)}
                  >
                    <i className="feather feather-file text-info"></i>
                  </Link>
                )}
              </td>
              {/* action */}
              <td className="text-left">
                {el.etat === 1 ? (
                  <>
                    <Link
                      to="#"
                      onClick={() =>
                        handleAccept(
                          el.code_generated,
                          el.id,
                          el.etat,
                          el.date_debut,
                          el.date_fin,
                          el.destination,
                          el.depart,
                          el.user_code_id,
                          "accepté")
                      }
                      className="action-btns"
                      data-tip="editer"
                    >
                      {/* <i className="feather feather-edit text-info" /> */}
                      <i className="fa fa-check-circle text-success"></i>
                    </Link>
                    <Link to="#" onClick={() => handleReject(el.code_generated,
                          el.id,
                          el.etat,
                          el.date_debut,
                          el.date_fin,
                          el.destination,
                          el.depart,
                          el.user_code_id)} className="action-btns" data-tip="editer">
                      {/* <i className="feather feather-edit text-info" /> */}
                      <i className="fa fa-ban text-danger"></i>
                    </Link>
                  </>
                ):(
                  <>
                  <Link
                    to="#"
                    onClick={() =>
                      handleAccept(el.code_generated,
                        el.id,
                        el.etat,
                        el.date_debut,
                        el.date_fin,
                        el.destination,
                        el.depart,
                        el.user_code_id,
                        "accepté")
                    }
                    className="action-btns"
                    data-tip="editer"
                  >
                    {/* <i className="feather feather-edit text-info" /> */}
                    <i className="fa fa-check-circle text-success"></i>
                  </Link>
                  <Link to="#" onClick={() => handleReject(el.code_generated,
                          el.id,
                          el.etat,
                          el.date_debut,
                          el.date_fin,
                          el.destination,
                          el.depart,
                          el.user_code_id)} className="action-btns" data-tip="editer">
                    {/* <i className="feather feather-edit text-info" /> */}
                    <i className="fa fa-ban text-danger"></i>
                  </Link>
                </>
                )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal */}
  
    </Fragment>
  );
}

export default TableDeplacement;
