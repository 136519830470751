import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllProduct, deleteProduitById } from "../../../Services/Facturation/FacturationProduitApi";
// import CustomSyncLoader from "../Others/CustomSyncLoader";
// import ErrorCustum from "../Others/ErrorCustum";
// import Pagination from "../../../containers/Pagination/Pagination";
// import { Link } from "react-router-dom";
// import BareCode from "../../../containers/CodeBare/BareCode";
import { filterProductByWord } from "../../../Utils/SortObject";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import swal from "sweetalert";
import { getAllFamille } from "../../../Services/Facturation/FacturationFamille";
import { getAllSousFamille } from "../../../Services/Facturation/FacturationSousFamilleApi";
import { getAllGamme } from "../../../Services/Facturation/FacturationGammeApi";
import { getAllMarques } from "../../../Services/Facturation/FacturationMarqueApi";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { t } from "i18next";

function ProductList() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(12);
  const [famille, setFamille] = useState(null);
  const [sousFamille, setSousFamille] = useState(null);
  const [marque, setMarque] = useState(null);
  const [gamme, setGamme] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getAllProduct()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllFamille().then((res) => {
      setFamille(res.data);
    });
  }, []);
  useEffect(() => {
    getAllSousFamille().then((res) => {
      setSousFamille(res.data);
    });
  }, []);

  useEffect(() => {
    getAllGamme().then((res) => {
      setGamme(res.data);
    });
  }, []);

  useEffect(() => {
    getAllMarques().then((res) => {
      setMarque(res.data);
    });
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterProductByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProduitById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const handleChangeFamille = (e) => {
    const arrayObj = allInitialData.filter((el) => {
      return el.famille_code == e.target.value;
    });
    setdata(arrayObj);
  };
  const handleSousFamille = (e) => {
    const arrayObj = allInitialData.filter((el) => {
      return el.sousfamille_code == e.target.value;
    });
    setdata(arrayObj);
  };
  const handleChangeMarque = (e) => {
    const arrayObj = allInitialData.filter((el) => {
      return el.marque_code == e.target.value;
    });
    setdata(arrayObj);
  };
  const handleChangeGamme = (e) => {
    const arrayObj = allInitialData.filter((el) => {
      return el.gamme_code == e.target.value;
    });
    setdata(arrayObj);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Produits")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link
                to="/admin/addproduct"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#newprojectmodal"
              >
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                {t("Ajouter produit")}
              </Link>
              {/* <Link to="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </Link>
              <Link to="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </Link>
              <Link to="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="input-group">
                <input type="text" className="form-control" placeholder={t("Recherche...")} onChange={handleSearch} />
                {/* <span className="input-group-append">
                  <button className="btn ripple btn-primary">Search</button>
                </span> */}
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">{t("Categories")}</h4>
              <div className="mt-3">
                <div className="form-group">
                  <label className="form-label">{t("Famille")}</label>
                  <select
                    className="form-control custom-select select2"
                    data-placeholder={t("Choisir famille")}
                    onChange={handleChangeFamille}
                  >
                    <option label={t("Choisir famille")} />
                    {famille &&
                      famille.map((el, idx) => (
                        <option key={idx} value={el.code}>
                          {el.libelle}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">{t("Gamme")}</label>
                  <select
                    className="form-control custom-select select2"
                    onChange={handleChangeGamme}
                    data-placeholder={t("Choisir gamme")}
                  >
                    <option label={t("Choisir gamme")} />
                    {gamme &&
                      gamme.map((el, idx) => (
                        <option key={idx} value={el.code}>
                          {el.libelle}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">{t("Marque")}</label>
                  <select
                    className="form-control custom-select select2"
                    onChange={handleChangeMarque}
                    data-placeholder={t("Choisir marque")}
                  >
                    <option label={t("Choisir marque")} />
                    {marque &&
                      marque.map((el, idx) => (
                        <option key={idx} value={el.code}>
                          {el.libelle}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label">{t("Sous famille")}</label>
                  <select
                    className="form-control custom-select select2"
                    onChange={handleSousFamille}
                    data-placeholder={t("Choisir Sous famille")}
                  >
                    <option label={t("Choisir Sous famille")} />
                    {sousFamille &&
                      sousFamille.map((el, idx) => (
                        <option key={idx} value={el.code}>
                          {el.libelle}
                        </option>
                      ))}
                  </select>
                </div>
                {/* <div className="form-group">
                  <label className="form-label">Color</label>
                  <select className="form-control custom-select select2" data-placeholder="Select Color">
                    <option label="Select Color" />
                    <option value={1}>White</option>
                    <option value={2}>Black</option>
                    <option value={3}>Red</option>
                    <option value={4}>Green</option>
                    <option value={5}>Blue</option>
                    <option value={6}>Yellow</option>
                  </select>
                </div> */}
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">{t("Suivi Stock")}</h4>
              <div className="mt-3">
                <div className="custom-checkbox custom-control mb-3">
                  <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id="checkbox-1" />
                  <label htmlFor="checkbox-1" className="custom-control-label">
                    <span className="fs-14 ml-2 my-auto">{t("Oui")}</span>
                  </label>
                </div>
                <div className="custom-checkbox custom-control mb-3">
                  <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id="checkbox-2" />
                  <label htmlFor="checkbox-1" className="custom-control-label">
                    <span className="fs-14 ml-2 my-auto">{t("Non")}</span>
                  </label>
                </div>
                {/* <div className="custom-checkbox custom-control mb-3">
                  <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id="checkbox-3" />
                  <label htmlFor="checkbox-1" className="custom-control-label">
                    <span className="fs-14 ml-2 my-auto">
                      <i className="fa fa-star  text-warning" />
                      <i className="fa fa-star text-warning" />
                      <i className="fa fa-star text-warning" />
                    </span>
                  </label>
                </div>
                <div className="custom-checkbox custom-control mb-3">
                  <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id="checkbox-4" />
                  <label htmlFor="checkbox-1" className="custom-control-label">
                    <span className="fs-14 ml-2 my-auto">
                      <i className="fa fa-star  text-warning" />
                      <i className="fa fa-star text-warning" />
                    </span>
                  </label>
                </div>
                <div className="custom-checkbox custom-control">
                  <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id="checkbox-5" />
                  <label htmlFor="checkbox-1" className="custom-control-label">
                    <span className="fs-14 ml-2 my-auto">
                      <i className="fa fa-star  text-warning" />
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">{t("Prix")}</h4>
              <div className="mt-3">
                <div className="custom-controls-stacked">
                  <Nouislider range={{ min: 0, max: 100 }} start={[0, 100]} connect />
                </div>
                {/* <div className="custom-controls-stacked">
                  <label className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="example-radios1"
                      defaultValue="option2"
                    />
                    <span className="custom-control-label">$25 to $50</span>
                  </label>
                </div>
                <div className="custom-controls-stacked">
                  <label className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="example-radios1"
                      defaultValue="option3"
                    />
                    <span className="custom-control-label">$50 to $100</span>
                  </label>
                </div>
                <div className="custom-controls-stacked">
                  <label className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="example-radios1"
                      defaultValue="option4"
                    />
                    <span className="custom-control-label">$100 to $500</span>
                  </label>
                </div>
                <div className="custom-controls-stacked">
                  <label className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="example-radios1"
                      defaultValue="option5"
                    />
                    <span className="custom-control-label">Other (Specify)</span>
                  </label>
                </div> */}
              </div>
            </div>
            <div className="card-footer">
              <Link to="#" onClick={() => getData()} className="btn btn-primary btn-block">
                {t("Afficher tous les produits")}
              </Link>
            </div>
          </div>
        </div>
        {loading && <CustomSyncLoader></CustomSyncLoader>}
        {!loading && error && <ErrorCustum></ErrorCustum>}
        {!loading && !error && data && (
          <div className="col-xl-9">
            <div className="row">
              {currentData.map((el, idx) => (
                <div key={idx} className="col-xl-3 col-md-6 col-lg-12">
                  <div className="card item-card overflow-hidden">
                    <div className="item-image">
                      <Link to="#" className="action-btns m-2" data-tip="editer" onClick={handleDelete}>
                        <i className="feather feather-trash text-danger" />
                      </Link>
                      <Link to="#" className="image">
                        <img
                          src={el.image ? localStorage.getItem("baseUrl") + el.image : "assets/images/products/7.jpg"}
                          alt="img"
                          className="img-fluid w-100"
                        />
                      </Link>
                      <ul className="product-links">
                        {/* <li>
                      <a className="product-links-icon" href="#">
                        <i className="fa fa-heart text-danger" />
                      </a>
                    </li>
                    <li>
                      <a className="product-links-icon" href="#">
                        <i className="fa fa-shopping-cart" />
                      </a>
                    </li> */}
                      </ul>
                      <div className="product-overly">
                        <Link to={"/admin/produit/" + el.id} className="text-white">
                          <i className="fa fa-eye mr-1" />
                          <span>{t("Voir plus")}</span>
                        </Link>
                      </div>
                    </div>
                    <div className="card-body text-center">
                      <a className="shop-title">{el.libelle}</a>
                      <div className="mb-1 fs-13 mt-2">
                        <Link to="#">
                          <i className="fa fa-star text-yellow" />
                        </Link>
                        <Link to="#">
                          <i className="fa fa-star text-yellow" />
                        </Link>
                        <Link to="#">
                          <i className="fa fa-star text-yellow" />
                        </Link>
                        <Link to="#">
                          <i className="fa fa-star-half-o text-yellow" />
                        </Link>
                        <Link to="#">
                          <i className="fa fa-star-o text-yellow" />
                        </Link>
                        <Link to="#" className="text-muted fs-13">
                          {" "}
                          ({el.unite})
                        </Link>
                      </div>
                      <div className="cardprice">
                        <small className="">{t("Prix achat TTC")} </small>
                        <span>{el.prix_achat_ttc}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end">
              <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default ProductList;
