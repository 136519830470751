import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AbsenceCard from "../Containers/Card/AbsenceCard";
import MainDashboardCard from "../Containers/Card/MainDashboardCard";
import MainDashboardCardSecond from "../Containers/Card/MainDashboardCardSecond";
import PointageCard from "../Containers/Card/PointageCard";
import RetardCard from "../Containers/Card/RetardCard";
import BarApexAgeGender from "./Charts/CustumApex/BarApexAgeGender";
import BarApexMaxAge from "./Charts/CustumApex/BarApexMaxAge";
import PieApexGender from "./Charts/CustumApex/PieApexGender";
import AddPointage from "./Pointages/AddPointage";
import LastQuestionnaire from "./Questionnaire/LastQuestionnaire";
import ButtonHeaderComponent from "./Styles/ButtonHeaderComponent";
import ComingBirthDayList from "./Users/ComingBirthDayList";
import { useTranslation } from "react-i18next";
import UpcommingHoliday from "../Containers/Card/UpcommingHoliday";
import PieChartDevicePointage from "./Charts/CustumChartJs/PieChartDevicePointage";
import PieChartNetworkPointage from "./Charts/CustumChartJs/PieChartNetworkPointage";

function Projet() {
  const [date, setDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  /**
   * It sets the date to the current date.
   */
  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  const getData = () => {};
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">
            <span className="font-weight-normal text-muted ml-2">{t("Accueil")}</span>
          </h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="d-flex"></div>
            <div className="d-lg-flex d-block">
              <div className="btn-list">
                <button className="btn btn-primary" data-toggle="modal" data-target="#addPointageModal">
                  {t("Pointage")}
                </button>

                <ButtonHeaderComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}

      {/*Row*/}
      <MainDashboardCard></MainDashboardCard>

      <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <LastQuestionnaire />
          <MainDashboardCardSecond />
        </div>
        <div className="col-xl-3 col-md-12 col-lg-12">
          <ComingBirthDayList />
        </div>

        <div className="col-xl-4 col-md-12 col-lg-6">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Genre par collaborateurs")}</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <PieApexGender />
              </div>
              <div className="sales-chart mt-4 row text-center"></div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-12 col-lg-6">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Moyenne age collaborateurs par genre")}</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <BarApexAgeGender />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-12 col-lg-12">
          <div className="card chart-donut1">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Age Maximum par collaborateurs")}</h4>
            </div>
            <div className="card-body">
              <div id="employees" className="mx-auto apex-dount" />
              <BarApexMaxAge />
              {/* <div className="sales-chart pt-5 pb-3 d-flex mx-auto text-center justify-content-center ">
                <div className="d-flex mr-5">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  Male
                </div>
                <div className="d-flex">
                  <span className="dot-label bg-secondary  mr-2 my-auto" />
                  Female
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-md-12 col-lg-12">
          <div className="card chart-donut1">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage par network")}</h4>
            </div>
            <div className="card-body">
              <div id="employees" className="mx-auto apex-dount" />
              <PieChartNetworkPointage />
            </div>
          </div>
          <AbsenceCard />
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12">
          <PointageCard />
          <UpcommingHoliday />
        </div>
        <div className="col-xl-4 col-md-12 col-lg-12">
          <div className="card chart-donut1">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage par phériphérique")}</h4>
            </div>
            <div className="card-body">
              <div id="employees" className="mx-auto apex-dount" />
              <PieChartDevicePointage />
            </div>
          </div>
          <RetardCard />
        </div>
      </div>

      <AddPointage getData={getData} />
    </>
  );
}

export default Projet;
