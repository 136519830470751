import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import swal from "sweetalert";
import CustomSyncLoader from "../../Components/Others/CustomSyncLoader";
import ErrorCustum from "../../Components/Others/ErrorCustum";
import ImageComponent from "../../Components/Styles/ImageComponent";
import { addPointageByUserId, getPointageByCode, sendFcmPointage } from "../../Services/Pointeuse/PointageApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { addNumberToDate, getCompareTodayBetweenTwoDate, getDateTime } from "../../Utils/DateUtils";
import { getAllValideCongeByCode } from "../../Services/Pointeuse/CongeApi";

function AbsenceCard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [device, setdevice] = useState(null);
  const [network, setnetwork] = useState("");

  //elemeninate pointag
  //eleminate congé

  const getData = () => {
    setLoading(true);
    getPointageByCode()
      .then((res) => {
        getUsersByCode()
          .then((resUsers) => {
            const results = resUsers.data.filter(({ id: id1 }) => !res.data.some(({ user_code_id: id2 }) => id2 == id1));

            getAllValideCongeByCode().then((res) => {
              const result = res.data.filter((el) => {
                //ajouter date fin
                return el.date.split("-")[0] == new Date().getFullYear();
              });
              const addDateFin = result.map((el) => {
                el["dateFin"] = addNumberToDate(el.nbr_jour, el.date);
                return el;
              });

              //conge today
              const todayValidConge = addDateFin.filter((el) => {
                return getCompareTodayBetweenTwoDate(el.date, el.dateFin);
              });

              //soustract two list
              const array1 = results.filter((item) => todayValidConge.every((item2) => item2.user_code_id != item.id));
              setdata(array1);
            });

            //
          })
          .catch((error) => setError(error));
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const getUA = () => {
    let device = "Unknown";
    const ua = {
      "Generic Linux": /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      "Chrome OS": /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
  };

  useEffect(() => {
    setdevice(getUA());

    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setlatitude(position.coords.latitude);
          setlongitude(position.coords.longitude);
        },
        (error) => {
          setlatitude(-1);
          setlongitude(-1);
        }
      );
    }
  }, []);

  const handlePointage = (userId) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Vous voulez vraiment ajouté pointage!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        console.log(getDateTime());
        addPointageByUserId(userId, getDateTime(), latitude, longitude, device, network, getDateTime()).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const handleNotificationPointage = (receiver) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment envoyé une notification de pointage !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const messaging = firebase.messaging();
        messaging
          .requestPermission()
          .then(() => {
            return messaging.getToken();
          })
          .then(() => {
            sendFcmPointage(receiver).then((res) => {
              swal(t("Opération effectuée avec succès!"), {
                icon: "success",
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="card">
          <div className="card-header border-bottom-0">
            <h4 className="card-title">{t("Absence")}</h4>
            {/* <div className="card-options">
          <a href="#" className="btn btn-outline-light">
            View All
          </a>
        </div> */}
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table text-nowrap mb-0 text-nowrap">
                <tbody>
                  {data.map((el, idx) => (
                    <tr key={idx}>
                      <td className="d-flex">
                        <ImageComponent atr={"w-8 h-8 bradius mr-3"} picture={el.img}></ImageComponent>
                        {/* <img className="w-8 h-8 bradius mr-3" src="assets/images/users/4.jpg" alt="media1" /> */}
                        <div className="my-auto">
                          <a href="#" className="mb-1 font-weight-semibold fs-16">
                            {el.nom} {el.prenom}
                          </a>
                          <p className="text-muted fs-13 mb-0">{el.email}</p>
                        </div>
                      </td>
                      <td className="text-right">
                        {/* <a className="text-success d-block fs-16" href="#">
                          Today
                        </a> */}
                        <Link
                          to="#"
                          className="btn btn-outline-primary mt-1"
                          onClick={() => handlePointage(el.id)}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pointage rapide"
                        >
                          <i className="fa fa-bolt mr-2" />
                          {t("Pointage")}
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-outline-warning mt-1 ml-1"
                          onClick={() => handleNotificationPointage(el.id)}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Envoyer une notification de pointage"
                        >
                          <i className="fa fa-paper-plane mr-2" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AbsenceCard;
