import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";

function SectionFour() {
  const handleDownload = () => {
    window.open(process.env.REACT_APP_API + "app", "_blank");
  };

  // const handleDownload = async () => {
  //   console.log(process.env.REACT_APP_API + "app");
  //   const res = await fetch(process.env.REACT_APP_API + "app");
  //   const blob = await res.blob();
  //   download(blob, "clediss.apk");
  // };
  return (
    <>
      <div className="home__hero-section darkBg">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">Téléchargez l'appli</div>
                <h1 className="heading">Nomateuse</h1>
                <p className="home__hero-subtitle">
                  L'application la plus simple, Cliquez sur un bouton pour pointer en entrée et commencer à suivre les
                  heures effectuées.
                </p>
                <Link to="#" onClick={handleDownload}>
                  <Button buttonSize="btn--wide" buttonColor="blue">
                    Télecharger
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src="assets/images/welcome/svg-2.svg" alt="google play" className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFour;
