import React from "react";
import "../HeroSection.css";
import { Button } from "../Button";
import { Link } from "react-router-dom";

function SectionOne() {
  return (
    <>
      <div className="home__hero-section darkBg">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">Pointeuse</div>
                <h1 className="heading">Commencer à enregistrer votre temps de travail.</h1>
                <p className="home__hero-subtitle">
                  Pointeuse est une solution moderme pour la gestion du temps de travail, tâches et congés des
                  collaborateurs en ligne.
                </p>
                <Link to="/login">
                  <Button buttonSize="btn--wide" buttonColor="blue">
                    Commencer
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src="assets/images/welcome/svg-1.svg" alt="Pointeuse" className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionOne;
