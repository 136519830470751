import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  getEnteteCommercialAchatAvoir,
  getEnteteCommercialAchatCommande,
  getEnteteCommercialVenteCommande,
} from "../../../../../Services/Facturation/FacturationEnteteApi";
import { filterFactureByWord } from "../../../../../Utils/SortObject";
import { convertToDigit } from "../../../../../Utils/NumberUtils";
import { sumMontantHT, sumMontantTTC, sumMontantTVA } from "../../../../../Utils/ObjectOperation";
import CustomSyncLoader from "../../../../Others/CustomSyncLoader";
import ErrorCustum from "../../../../Others/ErrorCustum";
import Pagination from "../../../../Others/Pagination";
import ButtonHeaderComponent from "../../../../Styles/ButtonHeaderComponent";
import { t } from "i18next";

function ListAvoirAchat() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    setLoading(true);
    getEnteteCommercialAchatAvoir()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value.length != "") {
      const dataSearch = filterFactureByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Avoir d'achat")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
            <Link to="ajoutavoirachat"  className="btn btn-primary mr-3">
                      {t("Ajouter Avoir")}
                    </Link>
              <Link to="/admin/addfournisseur" className="btn btn-primary mr-3">
                {t("Ajouter fournisseur")}
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                {t("Ajouter produit")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant HT</span>
                    <h3 className="mb-0 mt-1 text-success">{sumMontantHT(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card text-bg-light mb-3" style={{maxWidth: '18rem',margin:"auto"}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Montant HT")}</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-success">{sumMontantHT(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TVA</span>
                    <h3 className="mb-0 mt-1 text-primary">{sumMontantTVA(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
           <div className="card text-bg-light mb-3" style={{maxWidth: '18rem',margin:"auto"}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Montant TVA")}</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-primary">{sumMontantTVA(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
        <div className="col-4">
          {/* <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Montant TTC</span>
                    <h3 className="mb-0 mt-1 text-secondary">{sumMontantTTC(data)}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="fa fa-money" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
           <div className="card text-bg-light mb-3" style={{maxWidth: '18rem',margin:"auto"}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>{t("Montant TTC")}</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-secondary">{sumMontantTTC(data)}</h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                {t(" List Commande d'achat")}{" "}
                <span title="3 New Messages" className="badge badge-warning">
                  {data.length}
                </span>
              </h3>
              <div className="card-tools">
                <div className="row">
                  <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                    <input
                      type="text"
                      name="table_search"
                      className="form-control floating"
                      placeholder={t("Rechercher...")}
                      onChange={handleSearch}
                    />
                  </div>

                  
                </div>
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body table-responsive p-0">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive-sm">
                  <table className="table table-striped custom-table m-b-0">
                    <thead>
                      <tr>
                        <th>{t("N° Facture")}</th>
                        <th>{t("Client")} </th>
                        <th>
                          {t("Montant HT")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantHT(data)}</span>
                        </th>
                        <th>
                          {t("Montant TVA")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTVA(data)}</span>
                        </th>
                        <th>
                          {t("Montant TTC")} <br></br>
                          <span className="badge badge-pill badge-danger">{sumMontantTTC(data)}</span>
                        </th>
                        <th>{t("Statut")}</th>
                        <th>{t("Date")}</th>
                        <th className="text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((el) => (
                        <tr key={el.id}>
                          <td>
                          <div className="d-flex">
                                    <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                                    <div className="mt-1 mr-4">
                                    <Link to={"/admin/editeravoirachat/" + el.code} >
                                      {el.facture_code}
                                  </Link>          
                                  </div>
                                  </div>
                          {/* <Link to={"/admin/editercommandeachat/" + el.code} >
                          {el.facture_code}
                            </Link> */}
                            
                            </td>
                          {/* <td>{el.client_nom}</td> */}
                          <Link to={"/admin/clientactivity/" + el.client_code}>{el.client_nom}</Link>
                          <td>{convertToDigit(el.montant_total_ht)}</td>
                          <td>{convertToDigit(el.montant_total_tva)}</td>
                          <td>{convertToDigit(el.montant_total_ttc)}</td>
                          <td>{el.status}</td>
                          <td>{el.date.split(" ")[0]}</td>

                          <td className="project-actions text-right">
                            <Link to={"/admin/editeravoirachat/" + el.code} className="btn btn-default btn-xs">
                              <i className="fas fa-pencil-alt fa-xs"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
            <div className="card-footer clearfix">
              <Pagination items={data} onChangePage={onChangePage} pageSize={20} />
            </div>
          </div>
          {/* /.card */}
        </div>
      </div>
    </>
  );
}

export default ListAvoirAchat;
