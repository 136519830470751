import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import { getHolidayById, updateHoliday } from "../../Services/Pointeuse/HolidayApi";

function EditerHoliday(props) {
  const history = useHistory();
  const [libelle, setlibelle] = useState("");
  const [date, setdate] = useState("");
  const [nbjour, setnbjour] = useState("");
  const [type, settype] = useState("");
  useEffect(() => {
    getHolidayById(props.match.params.id).then((res) => {
      setlibelle(res.data.libelle);
      setdate(res.data.date);
      setnbjour(res.data.nbJour);
      settype(res.data.type);
    });
  }, []);

  /**
   * It updates the holiday in the database.
   * @param e - The event object.
   */
  const handelSave = (e) => {
    e.preventDefault();
    updateHoliday(libelle, date, nbjour, type, props.match.params.id).then(() => {
      history.push("/admin/holiday");
    });
  };

  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Modifé jour férié")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <a href="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </a>
              <a href="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </a>
              <a href="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="form-group">
                  <label htmlFor="inputAddress">{t("Libelle")}</label>
                  <input type="text" className="form-control" value={libelle || ""} onChange={(e) => setlibelle(e.target.value)} />
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">{t("Date")}</label>
                    <input type="date" className="form-control" value={date || ""} onChange={(e) => setdate(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">{t("Nombre des jours")}</label>
                    <input type="number" className="form-control" value={nbjour || ""} onChange={(e) => setnbjour(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>
                      {t("Type")}
                      <span className="text-danger">*</span>
                    </label>

                    <select value={type} onChange={(e) => settype(e.target.value)} className="selectpicker form-control">
                      <option value="CP">{t("chomé payé")}</option>
                      <option value="CNP">{t("Chomé non payé")}</option>
                    </select>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary">
                  Modifier
                </button> */}
              </form>
            </div>
            <div className="card-footer text-right">
              <a href="#" className="btn btn-danger btn-lg mr-2">
                {t("Annuler")}
              </a>
              <a href="#" className="btn btn-primary btn-lg" onClick={handelSave}>
                {t("Modifier")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditerHoliday;
