import React, { useEffect, useState } from 'react'
import { getExercice } from '../../../Services/Pointeuse/EntrepriseApi';

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import Form from 'react-bootstrap/Form';
import ReactSelect from "react-select";
import { LAYER } from 'bizcharts';
import { handleKeyPress_number } from '../../../Utils/InputUtils';
import { addParametragePaie, getparam_paie, updateparam_paie } from '../../../Services/paie/parametrage_paie';
import { toast } from 'react-toastify';
export default function ParametragePaie({ refresh }) {
    const [data, setdata] = useState(

        {
            code_societe: localStorage.getItem('code_generated'),
            code_convention: null,
            exercice: "",
            cnss: 0,
            val_cnss: 0,
            CP: 0,
            val_CP: 0,
            cnss_sivp: 0,
            val_cnss_sivp: 0,
            assurance_vie: 0,
            val_assurance_vie: 0,
            chef_famille: 0,
            val_chef_famille: 0,
            enfant_1er: 0,
            val_enfant_1er: 0,
            enfant_2eme: 0,
            val_enfant_2eme: 0,
            enfant_3eme: 0,
            val_enfant_3eme: 0,
            enfant_4eme: 0,
            val_enfant_4eme: 0,
            accd_travail: 0,
            val_accd_travail: 0,
            G_cnss: 0,
            val_G_cnss: 0,
            Parent_charge: 0,
            val_Parent_charge: 0,
            Enfant_handicape: 0,
            val_Enfant_handicape: 0,
            etudiant_N_B: 0,
            val_etudiant_N_B: 0,
            contribution: 0,
            val_contribution: 0,
            redevance: 0,
            val_redevance: 0,
            smig: 0,
            val_smig: 0,
            karama: 0,
            val_karama: 0,
            assurance_G: 0,
            val_assurance_G: 0,
            heure_sup: 0,
            taux_heure_sup_t1: 0,
            taux_heure_sup_t2: 0,
            taux_css: 0,
            nb_salaire: 0,
            dix_p: 0,
            val_dix_p: 0,
            montant_dix_p: 0,
            cavis: 0,
            val_cavis: 0,
            nbr_heure_mois: 0,
            nb_jr_mois: 0,
            calandrier: {
                Janvier: 0,
                février: 0,
                mars: 0,
                avril: 0,
                mai: 0,
                juin: 0,
                juillet: 0,
                août: 0,
                septembre: 0,
                octobre: 0,
                novembre: 0,
                décembre: 0
            }

        }

    )
    const [exercice, setexercice] = useState([])
    const [id_exercice, setid_exercice] = useState()

    useEffect(() => {

        getdata()
    }, [refresh, id_exercice])
    const getdata = () => {
        getparam_paie(id_exercice).then((res) => {

            if (res.data?.data?.length === 0) {
                setdata({

                    code_societe: localStorage.getItem('code_generated'),
                    code_convention: null,
                    exercice: id_exercice,
                    cnss: 0,
                    val_cnss: 0,
                    CP: 0,
                    val_CP: 0,
                    cnss_sivp: 0,
                    val_cnss_sivp: 0,
                    assurance_vie: 0,
                    val_assurance_vie: 0,
                    chef_famille: 0,
                    val_chef_famille: 0,
                    enfant_1er: 0,
                    val_enfant_1er: 0,
                    enfant_2eme: 0,
                    val_enfant_2eme: 0,
                    enfant_3eme: 0,
                    val_enfant_3eme: 0,
                    enfant_4eme: 0,
                    val_enfant_4eme: 0,
                    accd_travail: 0,
                    val_accd_travail: 0,
                    G_cnss: 0,
                    val_G_cnss: 0,
                    Parent_charge: 0,
                    val_Parent_charge: 0,
                    Enfant_handicape: 0,
                    val_Enfant_handicape: 0,
                    etudiant_N_B: 0,
                    val_etudiant_N_B: 0,
                    contribution: 0,
                    val_contribution: 0,
                    redevance: 0,
                    val_redevance: 0,
                    smig: 0,
                    val_smig: 0,
                    karama: 0,
                    dix_p: 0,
                    val_dix_p: 0,
                    montant_dix_p: 0,
                    val_karama: 0,
                    assurance_G: 0,
                    val_assurance_G: 0,
                    heure_sup: 0,
                    taux_heure_sup_t1: 0,
                    taux_heure_sup_t2: 0,
                    taux_css: 0,
                    nb_salaire: 0,
                    cavis: 0,
                    val_cavis: 0,
                    nbr_heure_mois: 0,
                    nb_jr_mois: 0,
                    arondi_net:0,
                    calandrier: {
                        Janvier: 0,
                        février: 0,
                        mars: 0,
                        avril: 0,
                        mai: 0,
                        juin: 0,
                        juillet: 0,
                        août: 0,
                        septembre: 0,
                        octobre: 0,
                        novembre: 0,
                        décembre: 0
                    }

                })
            } else {
                const parampdata =res.data?.data[0]
                delete parampdata.created_at
                setdata(parampdata);
                if(res.data?.data[0]?.calandrier){
                    const jsonObject = JSON.parse(res.data?.data[0]?.calandrier);

                    const convertedObject = {};
                    for (const key in jsonObject) {
                        const newKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Remove diacritics
                        convertedObject[newKey] = jsonObject[key];
                    }
    
                    setdata((prev) => ({
                        ...prev, calandrier: jsonObject
                    }));
                }
              
            }
        });
    }
    useEffect(() => {

        getExercice().then((res) => {
            if( Array.isArray(res?.data)){
                setexercice(res?.data);
            
                setid_exercice(res?.data[0]?.id)
    
            }
          
        });
    }, [refresh])

    const handelchange = (name, value) => {
        const copiedata = { ...data }
        copiedata[name] = value ;
        if (name === 'val_cnss' || name === 'val_CP') {
            copiedata.val_G_cnss = (parseFloat(copiedata.val_cnss) || 0) + (parseFloat(copiedata.val_CP) || 0)
        }
        if (name === 'exercice') {
            setid_exercice(value)
        }
        setdata(copiedata)

    }
    const handelsave = () => {
        const keysToCheck = ['cnss', 'CP', 'cnss_sivp', 'assurance_vie', 'chef_famille'
            , 'enfant_1er', 'enfant_2eme', 'enfant_3eme', 'enfant_4eme'
            , 'accd_travail', 'G_cnss', 'Parent_charge', 'Enfant_handicape'
            , 'etudiant_N_B', 'contribution', 'redevance', 'smig', 'karama'
            , 'assurance_G', 'cavis', 'dix_p'];
        const newdata = { ...data }
        keysToCheck.forEach((key) => {
            if (keysToCheck.includes(key)) {
                const name = `val_${key}`

                newdata[name] = newdata[key] === 1 ? parseFloat(newdata[name]) : null

            }

        });
        newdata.taux_heure_sup_t1 = newdata.heure_sup === 1 ? parseFloat(newdata.taux_heure_sup_t1) : null;
        newdata.taux_heure_sup_t2 = newdata.heure_sup === 1 ? parseFloat(newdata.taux_heure_sup_t2) : null;
        newdata.nb_jr_mois = parseFloat(newdata.nb_jr_mois);
        newdata.nbr_heure_mois = parseFloat(newdata.nbr_heure_mois);
        newdata.calandrier = JSON.stringify(data.calandrier);

        if (newdata?.id) {
            updateparam_paie(newdata).then((res) => {
                if (res.status === 200) {
                    toast.success(t("parametrage est enregistrée"))
                    getdata()
                }
            });
        } else {
            addParametragePaie(newdata).then((res) => {
                if (res.status === 201) {
                    toast.success(t("parametrage est enregistrée"))
                    getdata()
                }
            });
        }


    }
    const handelchange_calandrier = (key, value) => {
        setdata(prev => ({
            ...prev,
            calandrier: {
                ...prev.calandrier,
                [key]: value
            }
        }));

    }
    return (
        <div>
            <div className="page-header d-xl-flex d-block">
                <div className="page-leftheader">
                    <h4 className="page-title">{t(" Paramétrages Paie")}</h4>
                </div>


            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="tab-content adminsetting-content" id="setting-tabContent">
                        <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
                            <div className="card">
                                <div className="card-header  border-0">
                                    <h4 className="card-title">{t(" Paramétrages Paie")}</h4>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label>{t('Code Exercice')}</label>

                                            <select className="form-control" value={data?.exercice} onChange={(e) => handelchange('exercice', e.target.value)}>
                                                {exercice && exercice?.map((item, index) => (
                                                    <option key={index} value={item.id}>
                                                        {item.code_exercice}
                                                    </option>
                                                ))}

                                            </select>
                                        </div>
                                        <div className='col-3'>
                                            <label>{t('Code Convention')}</label>
                                            <input type='text' className="form-control" value={data?.code_convention} onChange={(e) => handelchange('code_convention', e.target.value)} />

                                        </div>
                                        <div className='col-3'>
                                            <label>{t('Nombre des salaires par ans ')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.nb_salaire} onChange={(e) => handelchange('nb_salaire', e.target.value)} />

                                        </div>
                                        <div className='col-2'>
                                            <label>{t('Taux CSS')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.taux_css} onChange={(e) => handelchange('taux_css', e.target.value)} />

                                        </div>
                                        <div className='col-1'>
                                            <label>{t('Arrondi net a payer')}</label>
                                            <input type='checkbox' checked={data?.arondi_net} onClick={(e) => handelchange('arondi_net', data.arondi_net === 0 ? 1 : 0)} />
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-1'>
                                            <label>{t('Chef de famille')}</label>
                                            <input type='checkbox' checked={data?.chef_famille} onClick={(e) => handelchange('chef_famille', data?.chef_famille === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.chef_famille === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant chef de famille')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_chef_famille} onChange={(e) => handelchange('val_chef_famille', e.target.value)} />
                                            </div>
                                        }

                                        <div className='col-1'>
                                            <label>{t('1er Enfant')}</label>
                                            <input type='checkbox' checked={data?.enfant_1er} onChange={(e) => handelchange('enfant_1er', data?.enfant_1er === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.enfant_1er === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant 1er enfant')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_enfant_1er} onChange={(e) => handelchange('val_enfant_1er', e.target.value)} />
                                            </div>}

                                        <div className='col-1'>
                                            <label>{t('2éme Enfant')}</label>
                                            <input type='checkbox' checked={data?.enfant_2eme} onChange={(e) => handelchange('enfant_2eme', data?.enfant_2eme === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.enfant_2eme === 1 && <div className='col-2'>
                                            <label>{t('montant 2éme enfant')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_enfant_2eme} onChange={(e) => handelchange('val_enfant_2eme', e.target.value)} />
                                        </div>}

                                        <div className='col-1'>
                                            <label>{t('3éme Enfant')}</label>
                                            <input type='checkbox' checked={data?.enfant_3eme} onChange={(e) => handelchange('enfant_3eme', data?.enfant_3eme === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.enfant_3eme === 1 && <div className='col-2'>
                                            <label>{t('montant 3éme enfant')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_enfant_3eme} onChange={(e) => handelchange('val_enfant_3eme', e.target.value)} />
                                        </div>}


                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-1'>
                                            <label>{t('4eme Enfant')} </label>
                                            <input type='checkbox' checked={data?.enfant_4eme} onChange={(e) => handelchange('enfant_4eme', data?.enfant_4eme === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.enfant_4eme === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant 4éme enfant')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_enfant_4eme} onChange={(e) => handelchange('val_enfant_4eme', e.target.value)} />
                                            </div>}

                                        <div className='col-1'>
                                            <label>{t('Etudiant non boursier')}</label>
                                            <input type='checkbox' checked={data?.etudiant_N_B} onClick={(e) => handelchange('etudiant_N_B', data?.etudiant_N_B === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.etudiant_N_B === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant etudiant non boursier')} </label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_etudiant_N_B} onChange={(e) => handelchange('val_etudiant_N_B', e.target.value)} />
                                            </div>
                                        }

                                        <div className='col-1'>
                                            <label>{t('Enfant handicape')}</label>
                                            <input type='checkbox' checked={data?.Enfant_handicape} onClick={(e) => handelchange('Enfant_handicape', data?.Enfant_handicape === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.Enfant_handicape === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant enfant handicape')} </label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_Enfant_handicape} onChange={(e) => handelchange('val_Enfant_handicape', e.target.value)} />
                                            </div>
                                        }
                                        <div className='col-1'>
                                            <label>{t('Parent a charge')}</label>
                                            <input type='checkbox' checked={data?.Parent_charge} onChange={(e) => handelchange('Parent_charge', data?.Parent_charge === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.Parent_charge === 1 && <div className='col-2'>
                                            <label>{t('montant parent a charge')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_Parent_charge} onChange={(e) => handelchange('val_Parent_charge', e.target.value)} />
                                        </div>}

                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-1'>
                                            <label>{t('CNSS')}</label>
                                            <input type='checkbox' checked={data?.cnss} onClick={(e) => handelchange('cnss', data?.cnss === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.cnss === 1 &&
                                            <div className='col-2'>
                                                <label>{t('taux CNSS')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_cnss} onChange={(e) => handelchange('val_cnss', e.target.value)} />
                                            </div>
                                        }

                                      

                                        <div className='col-1'>
                                            <label> {t('Cotisation  Patronale')}</label>
                                            <input type='checkbox' checked={data?.CP} onChange={(e) => handelchange('CP', data?.CP === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.CP === 1 && <div className='col-2'>
                                            <label>taux Cotisation Patronale</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_CP} onChange={(e) => handelchange('val_CP', e.target.value)} />
                                        </div>}
                                        <div className='col-1'>
                                            <label>{t('CNSS Global')}</label>
                                            <input type='checkbox' checked={data?.G_cnss} onChange={(e) => handelchange('G_cnss', data?.G_cnss === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.G_cnss === 1 &&
                                            <div className='col-2'>
                                                <label>taux_G_cnss</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} readOnly className="form-control" value={data?.val_G_cnss} onChange={(e) => handelchange('val_G_cnss', e.target.value)} />
                                            </div>}
                                            <div className='col-1'>
                                            <label>{t('cnss_sivp')}</label>
                                            <input type='checkbox' checked={data?.cnss_sivp} onChange={(e) => handelchange('cnss_sivp', data?.cnss_sivp === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.cnss_sivp === 1 &&
                                            <div className='col-2'>
                                                <label>{t('taux cnss_sivp')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_cnss_sivp} onChange={(e) => handelchange('val_cnss_sivp', e.target.value)} />
                                            </div>}



                                    </div>




                                    <div className='row mt-5'>
                                        <div className='col-1'>
                                            <label>{t('accident de travail')}</label>
                                            <input type='checkbox' checked={data?.accd_travail} onClick={(e) => handelchange('accd_travail', data?.accd_travail === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.accd_travail === 1 &&
                                            <div className='col-2'>
                                                <label>{t('taux accident de travail')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_accd_travail} onChange={(e) => handelchange('val_accd_travail', e.target.value)} />
                                            </div>
                                        }

                                        <div className='col-1'>
                                            <label>{t('Assurance de vie')}</label>
                                            <input type='checkbox' checked={data?.assurance_vie} onChange={(e) => handelchange('assurance_vie', data?.assurance_vie === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.assurance_vie === 1 && <div className='col-2'>
                                            <label>{t('plafond assurance')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_assurance_vie} onChange={(e) => handelchange('val_assurance_vie', e.target.value)} />
                                        </div>}

                                        <div className='col-1'>
                                            <label>{t('karama')}</label>
                                            <input type='checkbox' checked={data?.karama} onChange={(e) => handelchange('karama', data?.karama === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.karama === 1 && <div className='col-2'>
                                            <label>{t('montant plafond karama')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_karama} onChange={(e) => handelchange('val_karama', e.target.value)} />
                                        </div>}

                                        <div className='col-1'>
                                            <label>{t('Assurance Group')}</label>
                                            <input type='checkbox' checked={data?.assurance_G} onChange={(e) => handelchange('assurance_G', data?.assurance_G === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.assurance_G === 1 && <div className='col-2'>
                                            <label>{t('taux assurance Groupe')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_assurance_G} onChange={(e) => handelchange('val_assurance_G', e.target.value)} />
                                        </div>}




                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-1'>
                                            <label>{t('Redevance')}</label>
                                            <input type='checkbox' checked={data?.redevance} onClick={(e) => handelchange('redevance', data?.redevance === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.redevance === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant redevance')} </label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_redevance} onChange={(e) => handelchange('val_redevance', e.target.value)} />
                                            </div>
                                        }

                                        <div className='col-1'>
                                            <label>{t('smig')}</label>
                                            <input type='checkbox' checked={data?.smig} onChange={(e) => handelchange('smig', data?.smig === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.smig === 1 &&
                                            <div className='col-2'>
                                                <label>{t('montant smig')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_smig} onChange={(e) => handelchange('val_smig', e.target.value)} />
                                            </div>}

                                        <div className='col-1'>
                                            <label>{t('contribution')}</label>
                                            <input type='checkbox' checked={data?.contribution} onChange={(e) => handelchange('contribution', data?.contribution === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.contribution === 1 && <div className='col-2'>
                                            <label>{t('taux contribution')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_contribution} onChange={(e) => handelchange('val_contribution', e.target.value)} />
                                        </div>}

                                        <div className='col-1'>
                                            <label>{t('cavis')}</label>
                                            <input type='checkbox' checked={data?.cavis} onChange={(e) => handelchange('cavis', data?.cavis === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.cavis === 1 && <div className='col-2'>
                                            <label>{t('taux cavis')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_cavis} onChange={(e) => handelchange('val_cavis', e.target.value)} />
                                        </div>}

                                        <div className='col-1'>
                                            <label>{t('dix_p')}</label>
                                            <input type='checkbox' checked={data?.dix_p} onChange={(e) => handelchange('dix_p', data?.dix_p === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.dix_p === 1 &&
                                            <><div className='col-2'>
                                                <label>{t('taux dix_p')}</label>
                                                <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.val_dix_p} onChange={(e) => handelchange('val_dix_p', e.target.value)} />


                                            </div><div className='col-2'>
                                                    <label>{t('Montant dix_p')}</label>
                                                    <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.montant_dix_p} onChange={(e) => handelchange('montant_dix_p', e.target.value)} /></div></>}


                                    </div>




                                    <div className='row mt-5'>


                                        <div className='col-1'>
                                            <label>{t('heure supplémentaire')}</label>
                                            <input type='checkbox' checked={data?.heure_sup} onChange={(e) => handelchange('heure_sup', data?.heure_sup === 0 ? 1 : 0)} />
                                        </div>
                                        {data?.heure_sup === 1 && <div className='col-2'>
                                            <label>{t('taux tranche 1 (heure sup)')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.taux_heure_sup_t1} onChange={(e) => handelchange('taux_heure_sup_t1', e.target.value)} />
                                        </div>}
                                        {data?.heure_sup === 1 && <div className='col-2'>
                                            <label>{t('taux tranche 2(heure sup)')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.taux_heure_sup_t2} onChange={(e) => handelchange('taux_heure_sup_t2', e.target.value)} />
                                        </div>}

                                        <div className='col-2'>
                                            <label>{t('Nombre jours par mois')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.nb_jr_mois} onChange={(e) => handelchange('nb_jr_mois', e.target.value)} />
                                        </div>
                                        <div className='col-2'>
                                            <label>{t('Nombre heures par mois')}</label>
                                            <input type='text' onKeyPress={handleKeyPress_number} className="form-control" value={data?.nbr_heure_mois} onChange={(e) => handelchange('nbr_heure_mois', e.target.value)} />
                                        </div>
                                    </div>


                                </div>
                                <div className="card-body">
                                    <div className="card-header  border-0">
                                        <h4 className="card-title">{t("calandrier")}</h4>
                                    </div>
                                    <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                                        <thead>
                                            {data?.calandrier&&Object?.keys(data?.calandrier)?.map(key => (
                                                <th key={key}>
                                                    {key}

                                                </th>

                                            ))}

                                        </thead>
                                        <tbody>
                                            <tr>
                                                {data?.calandrier&&data?.calandrier&&Object.keys(data?.calandrier).map(key => (
                                                    <td key={key}>
                                                        <input type='text' value={data?.calandrier[key]} onChange={(e) => handelchange_calandrier(key, parseFloat(e.target.value))} />

                                                    </td>

                                                ))}
                                            </tr>
                                        </tbody>

                                    </table>

                                </div>
                                <div className="card-footer float-md-right">
                                    <Link to="#" onClick={handelsave} className="btn btn-primary float-md-right">
                                        {t("Enregistrer")}
                                    </Link>
                                    {/* <a href="#" className="btn btn-danger">
                  Cancel
                </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tab-2" role="tabpanel">
                            <div className="card">
                                <div className="card-header  border-0">
                                    <h4 className="card-title">{t("Modification paramètre facture")}</h4>
                                </div>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
