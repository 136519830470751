import React, { useEffect, useState } from 'react'
import ImageComponent from '../../Styles/ImageComponent'
import { filterUserByWord } from '../../../Utils/SortObject'

export default function List_user({ data, ajout, setajout, t, user_selected, setuser_selected, calcul_paie }) {
    const [user_choisi, setuser_choisi] = useState([])
    const [listuser, setlistuser] = useState([])

    useEffect(() => {
        if (ajout) {
            setuser_choisi(user_selected)
        }
    }, [ajout])

    useEffect(() => {
        setlistuser(data)
    }, [data])
    

    const selectionall = () => {

        const alldata = data.map(item => item.user_id)
        setlistuser(data)

        setuser_choisi(alldata)
    }


    const select_user = (userid) => {
        const findindexuser = user_choisi.findIndex(itemid => itemid === userid);

        if (findindexuser !== -1) {
            const list = [...user_choisi];
            list.splice(findindexuser, 1);
            setuser_choisi(list);
        } else {

            setuser_choisi((prev) => [...prev, userid])

        }
    }
    const add = async () => {
        setuser_selected(user_choisi)
        setajout(false)

    }
    const handleSearch = (e) => {
        const dataSearch = filterUserByWord(data, e.target.value);
        setlistuser(dataSearch);
      };
    return (
        <div>
            {ajout && (
                <>

                    <div className='row '>
                        <div className='col-1'>
                            {user_choisi.length === 0 ? (

                                <p className="pointer link" onClick={selectionall}>
                                    {t('Sélection tout')}
                                </p>
                            ) : (
                                <p className="pointer link" onClick={() => setuser_choisi([])}>
                                    {t('Désélectionné')}
                                </p>
                            )}
                        </div>
                        <div className='col-3'>
                            <input
                                type="search"
                                className="form-control"
                                placeholder={t("Recherche...")}
                                aria-controls="hr-table"
                                onChange={handleSearch}
                            />
                        </div>
                        <div className='col-3'>
                        <button className="btn btn-primary mr-2" onClick={add}>
                            {t("Ajouter")}
                        </button>
                        </div>
                    </div>


                    {listuser.map((item) => (
                        <div className="d-flex" key={item.id}>
                            <input
                                type="checkbox"
                                onClick={() => select_user(item.user_id)}
                                checked={user_choisi.includes(item.user_id)}
                            />
                            <ImageComponent atr="avatar avatar-md brround mr-3" picture={item.img} />
                            <div className="mr-3 mt-0 mt-sm-2 d-block">
                                <h6 className="mb-1 fs-14">
                                    {item.nom} {item.prenom}
                                </h6>
                            </div>
                        </div>
                    ))}
                   
                </>
            )}
        </div>

    )
}
