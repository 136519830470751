import { t } from "i18next";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import { getAllValideCongeToday } from "../../Services/Pointeuse/CongeApi";
import { getPersonnelByCode } from "../../Services/Pointeuse/PersonnelApi";
import { getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { compareTwoTime, soustractDateFromToday } from "../../Utils/DateUtils";

class MainDashboardCard extends Component {
  state = {
    collaborateurs: "",
    absences: "",
    pointages: [],
    startHoure: "",
    retardNumber: "",
    todayConge: "",
    countMan: "",
    countWoman: "",
    ageAverge: "",
    countSivp: "",
    countCDI: "",
    countCDD: "",
    countKarama: "",
  };
  /**
   * get all users
   * get all pointages
   */
  componentDidMount() {
    getUsersByCode().then((res) => {
      //get all users
      this.setState({ collaborateurs: res.data });

      //get gender
      let manCounter = 0;
      let womanCounter = 0;
      let ageList = [];
      for (let i = 0; i < res.data.length; i++) {
        // calculate age
        ageList.push(soustractDateFromToday(res.data[i].birth_date));
        if (res.data[i].gender === "male") {
          manCounter++;
        } else {
          womanCounter++;
        }
      }

      // get the average of list

      try {
        let average = (array) => array.reduce((a, b) => a + b) / array.length;
        this.setState({ ageAverge: Math.round(average(ageList)) });
      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }

      // set man count
      this.setState({ countMan: manCounter });
      // set woman count
      this.setState({ countWoman: womanCounter });
    });

    getAllSettings().then((res) => {
      this.setState({ startHoure: res.data.startHoure });
      /**
       * get pointage by code
       * count absence number
       * get pointage by date
       */
      getPointageByCode().then((res) => {
        let number_absence = this.state.collaborateurs.length - res.data.length;
        this.setState({ absences: number_absence });
        this.setState({ pointages: res.data });

        // counte retard
        let pointagesData = this.state.pointages;
        let counterRetard = 0;
        for (let i = 0; i < pointagesData.length; i++) {
          if (compareTwoTime(pointagesData[i].pointage.split(" ")[1], this.state.startHoure)) {
            counterRetard++;
          }
        }
        this.setState({ retardNumber: counterRetard });
      });
    });

    getAllValideCongeToday().then((res) => {
      this.setState({ todayConge: res.data.length });
      //console.log(res.data.length);
    });
    /**
     * get all personnels
     */
    getPersonnelByCode().then((res) => {
      //this.setState({ todayConge: res.data.length });
      let number_cdi = 0;
      let number_cdd = 0;
      let number_sivp = 0;
      let number_karama = 0;
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].type_contrat === "sivp") {
          number_sivp++;
        } else if (res.data[i].type_contrat === "cdi") {
          number_cdi++;
        } else if (res.data[i].type_contrat === "cdd") {
          number_cdd++;
        } else if (res.data[i].type_contrat === "stage") {
          number_karama++;
        } else {
          console.log("gerant");
        }
      }
      this.setState({ countCDD: number_cdd });
      this.setState({ countCDI: number_cdi });
      this.setState({ countKarama: number_karama });
      this.setState({ countSivp: number_sivp });
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <Link to={"/admin/jourpointage"} className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold">{t("Pointage")}</span>
                        <h3 className="mb-0 mt-1 text-primary  fs-25">{this.state.pointages.length}</h3>
                      </Link>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-primary my-auto  float-right">
                        <i className="feather feather-users" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <div className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold">{t("Absence")}</span>
                        <h3 className="mb-0 mt-1 text-secondary fs-25">{this.state.absences}</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-secondary my-auto  float-right">
                        <i className="feather feather-users" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <Link to={"/admin/retard"} className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold">{t("Retard")}</span>
                        <h3 className="mb-0 mt-1 text-success fs-25">{this.state.retardNumber}</h3>
                      </Link>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-success my-auto  float-right">
                        <i className="feather feather-users" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <Link to={"/admin/congevalide"} className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold">{t("Congés")}</span>
                        <h3 className="mb-0 mt-1 text-danger fs-25">{this.state.todayConge}</h3>
                      </Link>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-danger my-auto  float-right">
                        <i className="feather feather-users" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <div className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold">{t("CDI")}</span>
                        <h3 className="mb-0 mt-1 text-warning  fs-25">{this.state.countCDI}</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-warning my-auto  float-right">
                        <i className="feather feather-briefcase" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <div className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold"> {t("Stage")}</span>
                        <h3 className="mb-0 mt-1 text-warning fs-25">{this.state.countKarama}</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-warning my-auto  float-right">
                        <i className="feather feather-briefcase" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <div className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold"> {t("CIVP")}</span>
                        <h3 className="mb-0 mt-1 text-warning fs-25">{this.state.countSivp}</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-warning my-auto  float-right">
                        <i className="feather feather-briefcase" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12">
            <div className="card">
              <Link to="#">
                <div className="card-body">
                  <div className="row">
                    <div className="col-7">
                      <div className="mt-0 text-left">
                        <span className="fs-16 font-weight-semibold">{t("CDD")}</span>
                        <h3 className="mb-0 mt-1 text-warning fs-25">{this.state.countCDD}</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="icon1 bg-warning my-auto  float-right">
                        <i className="feather feather-briefcase" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MainDashboardCard;
