import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateFamille } from "../../../Services/Facturation/FacturationFamille";
import { getAllGamme } from "../../../Services/Facturation/FacturationGammeApi";
import { getFamilleById, updateImageFamille } from "../../../Services/Facturation/FacturationFamille";
import { t } from "i18next";

function EditerFamille({ tableId, getData }) {
  const hideRef = useRef();
  const [data, setData] = useState([]);
  const [familleCode, setFamilleCode] = useState("");
  const [familleLibelle, setFamilleLibelle] = useState("");
  const [color, setColor] = useState("");
  const [libelle, setLibelle] = useState("");
  // const [gammeCode, setGammeCode] = useState("");
  const [horsBox, setHorsBox] = useState("");
  const [image, setImage] = useState("/dist/img/product/p1.png");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [gammeLibelle, setGammeLibelle] = useState("");
  const [gammeCode, setgammeCode] = useState("");

  useEffect(() => {
    getFamilleById(tableId).then((res) => {
      console.log(res);
      setColor(res.data.color);
      setLibelle(res.data.libelle);
      setHorsBox(res.data.hors_box);
      setGammeLibelle(res.data.gamme_libelle);
      setgammeCode(res.data.gamme_code);
      if (res.data.image) {
        setImage(localStorage.getItem("baseUrl") + res.data.image);
      }
    });
  }, [tableId]);
  useEffect(() => {
    getAllGamme().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
      }));
      setData(options);
    });
  }, [tableId]);

  /**
   * * Update a famille in the database
   * @param e - The event object.
   */
  const handleSubmit = (e) => {
    if (libelle == "") {
      toast.error(t("nom vide"));
    } else if (color == "") {
      toast.error(t("coleur vide"));
    } else if (horsBox == "") {
      toast.error(t("plafond vide"));
    } else if (horsBox !== "" && isNaN(horsBox)) {
      toast.error(t("hors doit être un nombre"));
    } else if (gammeCode == "") {
      toast.error(t("veuillez choisir une gamme"));
    } else {
      e.preventDefault();

      updateFamille(libelle, gammeCode, color, horsBox, tableId).then((res) => {
        //history.push("/facturation/famille");
        hideRef.current.click();
        getData();
      });
    }
  };

  /**
   * It sets the color variable to the value of the color picker.
   * @param event - The event that triggered the callback.
   */
  const handleChangeComplete = (event) => {
    setColor(event.target.value);
  };

  /**
   * * This function is used to upload an image to the server
   * @param event - The event object that contains the file.
   */
  const handleImgClient = (event) => {
    console.log(event.target.files);
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
    updateImageFamille(event.target.files[0], tableId);
  };
  /**
   * It sets the familleCode and familleLibelle variables to the value and label of the selected option.
   * @param event - The event object that contains the data for the dropdown.
   */
  const handleChange = (event) => {
    setFamilleCode(event.value);
    setFamilleLibelle(event.label);
  };

  return (
    <div
      key={tableId}
      className="modal fade"
      id="editFamilleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {t("éditer famille")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={hideRef}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <ToastContainer></ToastContainer>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  {t("Nom Famille")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Taper ...")}
                  onChange={(e) => setLibelle(e.target.value)}
                  value={libelle || ""}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  {t("Hors box")}<span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="..."
                  onChange={(e) => setHorsBox(e.target.value)}
                  value={horsBox || ""}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  {t("Coleur")}<span className="text-danger">*</span>
                </label>
                <input
                  type="color"
                  className="form-control"
                  onChange={handleChangeComplete}
                  value={color || ""}
                  required
                />
              </div>
              <div className="form-group">
                <p>
                  {t("Choisir gamme")} :{" "}
                  <label>
                    {gammeLibelle}
                    <span className="text-danger">*</span>
                  </label>
                </p>
                <Select onChange={handleChange} options={data} />
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-center mb-2">
                  <img alt="image famille" src={image} className="avatar" />
                </div>
                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      accept="image/*"
                      type="file"
                      id="inputGroupFile02"
                      onChange={handleImgClient}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile02">
                      {imageName}
                    </label>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                {t("Enregistrer")}
              </button>
            </form>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EditerFamille;
