import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { getChequeEmisLigneById } from "../../Services/Pointeuse/ChequeEmisLigne";
import CopieContent from "../Exports/CopieContent";
import ExportCsv from "../Exports/ExportCsv";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import { t } from "i18next";

function ListChequeLigne({ idCheque }) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [size, setsize] = useState(8);

  useEffect(() => {
    getData();
  }, [idCheque]);

  const getData = () => {
    setLoading(true);
    getChequeEmisLigneById(idCheque)
      .then((res) => {
        console.log(res.data);
        setallInitialData(res.data);
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const handleSearch = (e) => {
    const dataSearch = allInitialData.filter((el) => {
      return el.banque.toLowerCase() === e.target.value.toLowerCase();
    });
    setdata(dataSearch);
  };

  return (
    <div>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="table-responsive">
          <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="dataTables_length" id="hr-table_length">
                  <label>
                    {t("Afficher")}{" "}
                    <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                      <option value={8}>8</option>
                      <option value={20}>20</option>
                      <option value={40}>40</option>
                      <option value={60}>60</option>
                      <option value={100}>100</option>
                    </select>{" "}
                    {t("entrées")}
                  </label>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div id="hr-table_filter" className="dataTables_filter">
                  <label className="mr-2">
                    <input type="search" className="form-control" placeholder={t("Recherche...")} aria-controls="hr-table" onChange={handleSearch} />
                  </label>

                  <ExportCsv
                    data={data}
                    name={"List_chèque émis"}
                    columns={["banque", "montant", "date", "echeance", "sode", "mois", "fournisseur_code"]}
                  />
                  <CopieContent />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <table
                  className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                  id="hr-table"
                  role="grid"
                  aria-describedby="hr-table_info"
                >
                  <thead>
                    <tr>
                      <th className="border-bottom-0">{t("Banque")}</th>
                      <th className="border-bottom-0">{t("Montant")}</th>
                      <th className="border-bottom-0">{t("Fournisseur")}</th>
                      <th className="border-bottom-0">{t("Date")}</th>
                      <th className="border-bottom-0">{t("Echeance")}</th>
                      <th className="border-bottom-0">{t("Solde")}</th>
                      <th className="border-bottom-0">{t("Mois")}</th>
                      <th className="border-bottom-0">{t("Facture")}</th>
                      <th className="border-bottom-0">{t("Objet")}</th>
                      <th className="border-bottom-0">{t("Projet")}</th>
                      <th className="border-bottom-0">{t("Tva")}</th>
                      <th className="border-bottom-0">{t("Annee")}</th>
                      <th className="border-bottom-0" style={{ width: 60 }}>
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentData.map((el) => (
                      <tr key={idCheque}>
                        <td>{el.banque}</td>
                        <td>{el.montant}</td>
                        <td>{el.fournisseur_code}</td>
                        <td>{el.date}</td>
                        <td>{el.echeance}</td>
                        <td>{el.solde}</td>
                        <td>{el.mois}</td>
                        <td>{el.facture}</td>
                        <td>{el.objet}</td>
                        <td>{el.projet}</td>
                        <td>{el.tva}</td>
                        <td>{el.annee}</td>

                        {/* <td>
                          <span className="badge badge-success-light">{el.montant_entrant}</span>
                        </td>
                        <td>
                          {" "}
                          <span className="badge badge-danger-light">{el.montant_sortant}</span>
                        </td>

                        <td>{el.type}</td>
                        <td>{el.createdAt}</td> */}
                        <td>
                          {true && (
                            <div className="btn-group">
                              <button
                                style={{ textAlign: "center", padding: 2, width: 40 }}
                                type="button"
                                className="btn btn-secondary dropdown-toggle dropdown-icon"
                                data-toggle="dropdown"
                              ></button>
                              <div className="dropdown-menu" role="menu">
                                <Link to={"/admin/caisse/" + el.id} className="dropdown-item">
                                  Editer
                                </Link>
                                <div className="dropdown-divider" />
                                {/* <Link to="#" onClick={() => handleDeleteCaisse(el.id)} className="dropdown-item">
                                  Supprimer
                                </Link> */}
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="border-bottom-0">{t("Banque")}</th>
                      <th className="border-bottom-0">{t("Montant")}</th>
                      <th className="border-bottom-0">{t("Fournisseur")}</th>
                      <th className="border-bottom-0">{t("Date")}</th>
                      <th className="border-bottom-0">{t("Echeance")}</th>
                      <th className="border-bottom-0">{t("Solde")}</th>
                      <th className="border-bottom-0">{t("Mois")}</th>
                      <th className="border-bottom-0">{t("Facture")}</th>
                      <th className="border-bottom-0">{t("Objet")}</th>
                      <th className="border-bottom-0">{t("Projet")}</th>
                      <th className="border-bottom-0">{t("Tva")}</th>
                      <th className="border-bottom-0">{t("Annee")}</th>
                      <th className="border-bottom-0" style={{ width: 60 }}>
                        {t("Actions")}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                  {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListChequeLigne;
