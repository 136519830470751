/**
 * Given a YouTube URL, return the video ID
 * @param url - The URL of the YouTube video.
 * @returns The video id.
 */
export function getIdVideoYoutube(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}
