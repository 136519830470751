import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import { addBanque, getBanqueById, updateBanque } from "../../Services/Pointeuse/BanqueApi";
import { t } from "i18next";
function EditBanque(props) {
  const history = useHistory();
  const alert = useAlert();

  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");

  const [reference, setreference] = useState(null);
  const [abr, setabr] = useState(null);
  const [dateValeur, setdateValeur] = useState(null);
  const [dateOperation, setdateOperation] = useState(null);
  const [libelle, setlibelle] = useState(null);
  const [cout, setcout] = useState(null);
  const [operation, setoperation] = useState("debit");
  const [debit, setdebit] = useState(0);
  const [credit, setcredit] = useState(0);
  const [isDebit, setisDebit] = useState(false);

  useEffect(() => {
    getBanqueById(props.match.params.id).then((res) => {
      setreference(res.data.reference);
      setoperation(res.data.operation);
      setabr(res.data.abr);
      setdateValeur(res.data.date_valeur);
      setdateOperation(res.data.date_operation);
      setlibelle(res.data.libelle);
      setcout(res.data.centre_cout);
      setdebit(res.data.debit);
      setcredit(res.data.credit);
      setFournisseurCode(res.data.beneficiaire);

      if (res.data.operation === "debit") {
        setisDebit(false);
      } else {
        setisDebit(true);
      }
    });
  }, []);

  const handleSubmitEncaissement = (e) => {
    e.preventDefault();
    updateBanque(dateOperation, abr, reference, libelle, dateValeur, fournisseurCode, operation, cout, debit, credit, props.match.params.id).then(
      (res) => {
        alert.success("Ajouté");
        history.push("/admin/banque");
      }
    );
  };

  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.target.value);
  };

  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      setDataFournisseur(res.data);
      console.log(res.data);
    });
  }, []);

  const handleChangeOperation = (e) => {
    const value = e.target.value;
    setoperation(value);

    if (value === "debit") {
      setdebit(0);
      setisDebit(false);
    } else {
      setcredit(0);
      setisDebit(true);
    }
  };

  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">{t("Editer opération bancaire")} # {props.match.params.id}</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
              <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
            </div> */}
              <div class="col-sm-12 col-lg-12">
                <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>{t("Bénéficiaire")}</label>
                        {dataFournisseur && (
                          <select className="form-control" value={fournisseurCode} id="exampleFormControlSelect0" onChange={handleChangeFournisseur}>
                            <option>{t("Choisir Client")}</option>
                            {dataFournisseur.map((option) => (
                              <option value={option.code}>
                                {option.nom} {option.prenom}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Date valeur")}</label>
                        <input
                          type="date"
                          className="form-control"
                          required
                          placeholder="ex : 100.100"
                          value={dateValeur}
                          onChange={(e) => setdateValeur(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Date opération")}</label>
                        <input
                          value={dateOperation}
                          type="date"
                          className="form-control"
                          required
                          placeholder="ex : 100.100"
                          onChange={(e) => setdateOperation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Libelle")}</label>
                        <input type="text" className="form-control" value={libelle} onChange={(e) => setlibelle(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Centre cout")}</label>
                        <input type="text" className="form-control" value={cout} onChange={(e) => setcout(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Abr")}</label>
                        <input type="text" className="form-control" value={abr} onChange={(e) => setabr(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Référence")}</label>
                        <input type="text" className="form-control" value={reference} onChange={(e) => setreference(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Opération")}</label>
                        <select className="form-control" value={operation} required onChange={handleChangeOperation}>
                          <option value="debit">{t("Débit")}</option>
                          <option value="credit">{t("Crédit")}</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Type</label>
                        <select className="form-control" value={type} required onChange={(e) => settype(e.target.value)}>
                          <option value="cheque">Chèque</option>
                          <option value="virement">Virement</option>
                          <option value="espece">Espèce</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Débit")}</label>
                        <input
                          disabled={isDebit}
                          type="number"
                          className="form-control"
                          value={debit}
                          required
                          onChange={(e) => setdebit(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Crédit")}</label>
                        <input
                          disabled={!isDebit}
                          type="number"
                          className="form-control"
                          required
                          value={credit}
                          onChange={(e) => setcredit(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* {files && <img src={URL.createObjectURL(files[0].file)} alt=""></img>} */}

                  <button type="submit" className="btn btn-primary">
                    {t("Enregistrer")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBanque;
