import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllPointages, getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { getDistanceBetweenTwoCoordinates } from "../../Utils/DistanceUtils";
import { filterPointageByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import AddPointage from "./AddPointage";
import CustumMapLeaflet from "./CustumMapLeaflet";
import { getSession } from "../../Utils/SessionUtils";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import AbsenceCard from "../../Containers/Card/AbsenceCard";
import { t } from "i18next";
import {
  addNumberToCongeDate,
  addNumberToDate,
  differenceBetweenTwoTime,
  getCompareTodayBetweenTwoDate,
  soustractNumberToDate,
} from "../../Utils/DateUtils";
import { getAllValideCongeByCode } from "../../Services/Pointeuse/CongeApi";
import moment from "moment";

function PointageToday({ socket }) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(20);
  const [date, setDate] = useState(new Date());
  const [users, setusers] = useState(null);
  const [settings, setsettings] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [conges, setConges] = useState(null);
  const [isShowing, setisShowing] = useState(true);

  useEffect(() => {
    socket.on("pointage", (result) => {
      if (result.code_generated == getSession("code_generated")) {
        getData();
      }
    });
  }, []);

  /**
   * It sets the date to the current date.
   */
  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (object1.user_code_id = !object2.user_code_id);
      });
    });
  }

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getPointageByCode()
      .then((res) => {
        const array1 = res.data;
        /* get yesterday date */
        const todayDate = new Date();
        const newDate = moment(todayDate).subtract(1, "days").format("YYYY-MM-DD");

        getAllPointages(newDate).then((res) => {
          const array2 = res.data;
          const difference = [...getDifference(array1, array2), ...getDifference(array2, array1)];
          //map

          const newData = difference.map((o, i) => {
            return { ...o, pointage_n: array2[i]?.pointage };
          });

          if (newData[0]?.pointage_n === undefined) {
            setisShowing(false);
          }
          setallInitialData(newData);
          setdata(newData);
        });

        setallInitialData(res.data);
        setdata(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /* fetch entreprise */
  useEffect(() => {
    setLoading(true);
    getEntrepriseByCode()
      .then((res) => {
        setsettings(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getAllSettings().then((res) => {
      fetchConge(res.data.dayPerWeeks);
    });
  }, []);

  const fetchConge = (days) => {
    getAllValideCongeByCode().then((res) => {
      const result = res.data.filter((el) => {
        //ajouter date fin
        return el.date.split("-")[0] == new Date().getFullYear();
      });
      const addDateFin = result.map((el) => {
        el["dateFin"] = addNumberToCongeDate(el.nbr_jour, el.date, days);
        return el;
      });
      //conge today
      const todayValidConge = addDateFin.filter((el) => {
        return getCompareTodayBetweenTwoDate(el.date, el.dateFin);
      });
      setConges(todayValidConge);
    });
  };

  /**
   * It splits the string by the comma and returns the first element.
   * @param word - The word to be checked
   * @returns The time of checkin.
   */
  const getTimeCheckin = (word) => {
    let timeCheckin = word.split(",");
    return timeCheckin[0].split(" ")[1];
  };
  /**
   * It takes a string and splits it into an array. Then it takes the last element of the array and
   * splits it into another array. Then it takes the second element of that array and returns it.
   * @param word - The word to be checked.
   * @returns The last word of the string, split by a comma.
   */
  const getTimeCheckout = (word) => {
    let timeCheckout = word.split(",");
    if (timeCheckout.length === 1) {
      return "-";
    } else {
      return timeCheckout[timeCheckout.length - 1].split(" ")[1];
    }
  };

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search word.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    const dataSearch = filterPointageByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  /**
   * It gets all the pointages for a given date.
   * @param event - The event object that contains the target.
   */
  const handleChangeDate = (event) => {
    setisShowing(false);
    const todayDate = event.target.value;
    // console.log(event.target.value);
    // const newDate = moment(todayDate).subtract(1, "days").format("YYYY-MM-DD");
    // console.log(newDate);
    getAllPointages(todayDate).then((res) => {
      setdata(res.data);
    });
  };
  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Pointage")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            {/* <div className="d-flex">
              <div className="header-datepicker mr-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="feather feather-calendar" />
                    </div>
                  </div>
                  <input className="form-control fc-datepicker" type="time" />
                </div>
              </div>
            </div> */}
            <div className="d-lg-flex">
              <div className="btn-list">
                {/* <button className="btn btn-primary mr-4" data-toggle="modal" data-target="#clockinmodal">
                  pointage
                </button> */}
                <ButtonHeaderComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="countdowntimer mt-0">
                <span className="border-0" id="clocktimer2" style={{ fontSize: 28 }}>
                  {date.toLocaleTimeString()}
                </span>
                <label className="form-label">{t("Heure actuelle")}</label>
              </div>
              <div className="btn-list text-center mt-5">
                <Link to="#" className="btn ripple btn-primary" data-toggle="modal" data-target="#addPointageModal">
                  {t("Pointage d'entrée")}
                </Link>
                {/* <Link to="#" className="btn ripple btn-primary">
                  Pointage de sortie
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title"> {t("Aperçu pointage de jour")}</h4>
            </div>
            <div className="card-body pt-0 pb-3">
              <div className="row mb-0 pb-0 d-flex justify-content-center">
                <div className="col-md-6 col-xl-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">{users?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total collaborateur")}</h5>
                </div>
                <div className="col-md-6 col-xl-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">{data.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total pointage")}</h5>
                </div>
                <div className="col-md-6 col-xl-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">{Number(users?.length) - Number(data.length)}</span>
                  <h5 className="mb-0 mt-3">{t("Tolal absent")}</h5>
                </div>
                <div className="col-md-6 col-xl-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">0</span>
                  <h5 className="mb-0 mt-3">{t("Total Retard")}</h5>
                </div>
                {/* <div className="col-md-6 col-xl-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-orange-transparent">2</span>
                  <h5 className="mb-0 mt-3">Late Days</h5>
                </div>
                <div className="col-md-6 col-xl-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-pink-transparent">5</span>
                  <h5 className="mb-0 mt-3">Holidays</h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-8 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage du jour")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")} : </label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("Pointage d'entrée")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("Pointage de sortie")}
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> ---&gt; Holiday
                  </span>
                  <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && settings && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Recherche..."
                              aria-controls="hr-table"
                              onChange={handleChangeDate}
                            />
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_Pointage_"} />
                          <ExportPdf data={data} name={"List_Pointage_"} columns={["nom", "prenom", "pointage", "device", "network"]} />
                          <CopieContent />
                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              {isShowing && <th>{t("Pointage hier")}</th>}

                              <th>{t("Pointage d'entrée")}</th>
                              <th>{t("Pointage de sortie")}</th>
                              <th>{t("Heures de travail")}</th>
                              <th>{t("Distance (m)")}</th>
                              <th>{t("map")}</th>
                              <th>{t("Appareil")}</th>
                              <th>{t("Internet")}</th>
                              <th>{t("Périphérique")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td>
                                  <div className="d-flex">
                                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                                    <div className="mr-8 ml-2 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom} {el.prenom}
                                      </h6>
                                      <p className="text-muted mb-0 fs-12">{el.email}</p>
                                    </div>
                                  </div>
                                </td>
                                {el.pointage_n && (
                                  <td>
                                    <span className="badge badge-warning">{el?.pointage_n.split(",").pop()}</span>
                                  </td>
                                )}

                                <td>
                                  <span className="badge badge-success">{getTimeCheckin(el.pointage)}</span>
                                </td>
                                <td>
                                  <span className="badge badge-danger">{getTimeCheckout(el.pointage)}</span>
                                </td>
                                <td>{differenceBetweenTwoTime(getTimeCheckin(el.pointage), getTimeCheckout(el.pointage))}</td>

                                <td>
                                  <div className="d-flex">
                                    <span className="badge badge-default">
                                      {getDistanceBetweenTwoCoordinates(el.latitude_arrive, el.longitude_arrive, settings)} m
                                    </span>
                                    {getDistanceBetweenTwoCoordinates(el.latitude_arrive, el.longitude_arrive, settings) > 100 && (
                                      <div className="mt-1 ml-1">
                                        <span
                                          className="feather feather-info text-danger"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Télétravail ?"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={`https://www.google.es/maps/dir/'${el.latitude_arrive},${el.longitude_arrive}'/'${settings.latitude},${settings.longitude}'`}
                                    target="_blank"
                                  >
                                    <i className="feather feather-map" data-toggle="tooltip" data-placement="top" title="ouvrir sur google map"></i>
                                  </a>
                                </td>
                                <td>{el.device}</td>
                                <td>
                                  {el.network == "<unknown ssid>" ? (
                                    <i className="feather feather-wifi"> Données cellulaires</i>
                                  ) : (
                                    <i className="feather feather-cell"> {el.network} </i>
                                  )}
                                </td>
                                <td>
                                  <span className="badge badge-primary mr-2">{el.web == 0 ? "via mobile" : "via web"}</span>
                                  {el.web == 0 ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-desktop"></i>}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <AbsenceCard />
        </div>
        {/* leflet */}
        <div className="col-xl-4 col-md-12 col-lg-12">
          <div className="card chart-donut1">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Pointage sur map")}</h4>
            </div>
            <div className="card-body">
              <div id="employees" className="mx-auto apex-dount" />
              <CustumMapLeaflet />
            </div>
          </div>

          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header  border-0">
                <h4 className="card-title">{t("Congé")} </h4>
                <div className="card-options mr-1">
                  <Link to="/admin/congevalide" className="btn ripple btn-outline-light">
                    {" "}
                    {t("Voir plus")}
                  </Link>
                </div>
              </div>
              <div className="card-body p-0 pt-2">
                <div className="table-responsive mb-0 table-regcompany">
                  <table className="table table-vcenter text-nowrap  border-top mb-0" id="admin-inregtable">
                    <thead>
                      <tr>
                        <th className="border-bottom-0">{t("Nom/Prénom")} </th>
                        <th className="border-bottom-0">{t("Nb jours")} </th>
                        <th className="border-bottom-0">{t("Actions")} </th>
                      </tr>
                    </thead>
                    <tbody>
                      {conges && (
                        <>
                          {conges.map((el) => (
                            <tr key={el.id}>
                              <td>
                                <a href="#" className="d-flex">
                                  <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.imguser}></ImageComponent>
                                  <div className="mr-3 mt-0 mt-sm-2 d-block">
                                    <h6 className="mb-1 fs-14">
                                      {el.nom} {el.prenom}
                                    </h6>
                                    <p className="text-muted mb-0 fs-12">{el.date.split(" ")[0]}</p>
                                  </div>
                                </a>
                              </td>
                              <td>
                                <a href="#" className="fs-13">
                                  {el.nbr_jour}
                                </a>
                                <p className="mb-0">
                                  <span className="badge badge-md badge-success-light fs-10">{el.motif}</span>
                                </p>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Link to="/admin/congevalide" className="action-btns1" data-toggle="tooltip" data-placement="top" title="View">
                                    <i className="feather feather-eye text-primary" />
                                  </Link>
                                  {/* <a href="#" className="action-btns1" data-toggle="tooltip" data-placement="top" title="Delete">
                                    <i className="feather feather-trash-2 text-danger" />
                                  </a> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPointage getData={getData} />
    </>
  );
}

export default PointageToday;
