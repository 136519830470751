import React from "react";
import BaremeIrpp from "../BaremeIrpp/BaremeIrpp";
import LignePaie from "../LignePaie/LignePaie";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function Baremes() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Barèmes")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
           
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="tab-menu-heading hremp-tabs p-0 ">
            <div className="tabs-menu1">
              {/* Tabs */}
              <ul className="nav panel-tabs">
                <li className="ml-4">
                  <a href="#tab5" className="active" data-toggle="tab">
                    {t("Barème IRP")}
                  </a>
                </li>
                <li>
                  <a href="#tab6" data-toggle="tab">
                    {t("Ligne de paie")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
            <div className="tab-content">
              <div className="tab-pane active" id="tab5">
                <div className="card-body">
                  <BaremeIrpp></BaremeIrpp>
                </div>
              </div>
              <div className="tab-pane" id="tab6">
                <div className="card-body">
                  <LignePaie></LignePaie>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Baremes;
