import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets the bareme irpp by code.
 * @returns The bareme IRPP
 */
export async function getBaremeIrppByCode() {
  try {
    const response = await axios.get(api_url + "baremeirpp/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets the bareme irpp by id.
 * @param id - the id of the bareme irpp
 * @returns The bareme IRPP
 */
export async function getBaremeIrppById(id) {
  try {
    const response = await axios.get(api_url + "baremeirpp/id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It edits a bareme irpp.
 * @param code - the code of the bareme
 * @param min - minimum value of the bareme
 * @param max - the maximum value of the bareme
 * @param taux - the percentage of the base that is taxed
 * @param taux_sup - the percentage of the amount exceeding the maximum
 * @param id - id of the bareme
 * @returns The response is an object with the following properties:
 */
export async function editBaremeIrpp(code, min, max, taux, taux_sup, id) {
  const credentiel = {
    code: code,
    min: min,
    max: max,
    taux: taux,
    taux_sup: taux_sup,
    id: id,
  };

  try {
    const response = await axios.put(api_url + "baremeirpp/id/", credentiel, config);
    console.log(response);
    toast.success(t("mise à jour"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Add a bareme irpp
 * @param code_generated - the code generated by the API
 * @param code - the code of the bareme
 * @param min - the minimum value of the barème
 * @param max - the maximum amount of the salary
 * @param taux - the percentage of the base that is taxed
 * @param taux_sup - the percentage of the amount over the max that is taxed at the taux_sup rate.
 * @param id - id of the bareme irpp
 * @returns The response is an object with the following properties:
 */
export async function addBaremeIrpp(code_generated, code, min, max, taux, taux_sup, id) {
  const credentiel = {
    code_generated: code_generated,
    code: code,
    min: min,
    max: max,
    taux: taux,
    taux_sup: taux_sup,
    id: id,
  };

  try {
    const response = await axios.post(api_url + "baremeirpp/", credentiel, config);
    console.log(response);
    toast.success(t("Barème irpp ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
