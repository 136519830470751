import React, { useEffect, useState } from "react";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
 import "./attestation.css";
import { t } from "i18next";
import { getAllAttestationByCode, getAttestationById } from "../../Services/Pointeuse/AttestationApi";
import moment from "moment";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { getPersonnelById } from "../../Services/Pointeuse/PersonnelApi";
import { useLocation } from 'react-router-dom';

function PrintAttestation(props) {
  const location = useLocation();
  const id_user = location.state ? location.state.obj : null; 
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [entreprise, setentreprise] = useState(null);
  const [logoCompany, setLogoCompany] = useState("");
  const [personel, setPersonel] = useState([]);
  const [responsable, setresponsable] = useState(null);
  const [entrepriseAttestatuin, setentrepriseAttestatuin] = useState("");
  const currentDate = moment().format('DD/MM/YYYY');

   useEffect(() => {
    getUserById(localStorage.getItem("id")).then((res) => {
      setresponsable(res.data);
    });
     getAllAttestationByCode().then((res) => {
       setentreprise(res.data);
    });
  }, []);


  useEffect(()=>{
    getPersonnelById(id_user).then((result)=>{ 
      setPersonel(result.data)
    }).catch((error) => setError(error))
    .finally(() => setLoading(false));
  },[])

  
 

  useEffect(() => {
    getAttestationById(props.match.params.id)
      .then((res) => {
        setdata(res.data);
        })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

    useEffect(()=>{
      getEntrepriseByCode().then((res)=>{
         setentrepriseAttestatuin(res.data)
      })
    },[])
  /**
   * Prints the current page
   */
  const handlePrint = () => {
    window.print();
  };

   return (
    <div className="print-attestation-container">
 {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="row">
          <div className="col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body">
                <div className="logo_entreprise">
                  <img 
                  src={setentrepriseAttestatuin.logo} 
                  //src="https://nomadis.online/images/nomadis_svg/LOGO.svg"
                  alt="Logo de l'entreprise" 
                  className="logo-img"/>
                </div>
                <div className="nomadis-info">
                <p style={{color:"green"}}>{t("SOCIETE CLEDISS SARL")}</p>
                <p><strong>{entrepriseAttestatuin.matricule_fiscal}</strong></p>
                <p><a href="http://www.nomadis.online" target="_blank" rel="noopener noreferrer">{entrepriseAttestatuin.website_url}</a></p>
                <p><a href="mailto:contact@nomadis.online">{entrepriseAttestatuin.email}</a></p>
                <p>+216{entrepriseAttestatuin.mobile}</p>
              </div>
              <div className="adresse">
                <p>{entrepriseAttestatuin.adresse}</p>
                {/* <p>Ariana, Riadh EL Andalous 2058 Tunisia</p> */}
              </div>
              <div className="headerr">
                <h1>{t("Attestation de travail")}</h1>
              </div>
               <div className="paragraphe">
                <p>Je soussigné, Monsieur <strong>Ahmed Salah</strong> agissant en qualité de Gérant de la Société <strong>{entrepriseAttestatuin.nom} </strong> , 
                  dont le siège social se trouve à Résidence Ennahli 5- Appt A304 . Riadh EL Andalous -Ariana, identifiée 
                  sous le numéro {entrepriseAttestatuin.matricule_fiscal}
                  </p>
               </div>
               <div>
               <p>
                Certifie et atteste par la présente que {data.gender==="male"?"Monsieur":"Madame"}{" "}
                <strong>{data.nom} {data.prenom}</strong> né le <strong>{moment(data.birth_date).format("DD-MM-YYYY")}</strong> et titulaire de la CIN N°: {personel.cin}. 
                Occupe le poste de <strong>{data.fonction}</strong> au sein de notre entreprise et ce depuis le{" "}
                {moment(data.debut_contrat).format("YYYY-MM-DD")}{" "}
                {data.isactif === 1 ? "jusqu’à maintenant" : `jusqu'au ${moment(data.fin_contrat).format("YYYY-MM-DD")}`}
              </p>

               </div>
               {/* <div>
                  {data && (
                    <div className="paragraphe">
                      <p>
                        Certifie et atteste par la présente que Monsieur (Madame){" "}
                        <strong>{data.nom} {data.prenom}</strong> né le <strong>{moment(data.birth_date).format("YYYY-MM-DD")}</strong> et titulaire de la CIN N° ………………. 
                        Occupe le poste de <strong>{data.fonction}</strong> au sein de notre entreprise et ce depuis le{" "}
                        {moment(data.debut_contrat).format("YYYY-MM-DD")}{" "}
                        {data.isactif === 1 ? "jusqu’à maintenant" : `jusqu'au ${moment(data.fin_contrat).format("YYYY-MM-DD")}`}
                      </p>
                    </div>
                  )}
                </div> */}

                <div >
                  <p>Cette attestation est délivrée à la demande du Salarié pour servir et faire valoir ce que de droit</p>
                </div>
                  <div className="date">
                  <p>
                    Fait à Tunis le : {currentDate}
                  </p>
                  </div>

                <div className="card-body pl-0 pr-0">
                  <div className="row mr-4 ml-4" style={{ marginBottom: 100 }}>
                    <div className="col-sm-6">
                      {/* <span>{t("Signature de l'employé")}</span> */}
                      
                      <br />
                    </div>
                    <div className="col-sm-6" style={{marginLeft:"80%",marginTop:"-20px"}}>
                      <span>{t("La Direction")}</span>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="card pl-0 pr-0">
                  <div className="row">
                    <div className="col-sm-6">
                      <br />
                    </div>
                    <div className="col-sm-6 text-right">
                      <button className="btn btn-primary" onClick={handlePrint}>
                        <i className="si si-printer" /> {t("Imprimer")}
                      </button>
                     
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        )}
    </div>
  );
}

export default PrintAttestation;
