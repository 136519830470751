import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the members of a departement
 * @param code - The code of the departement you want to get the members of.
 * @returns The response is an array of objects.
 */
export async function getAllDepartementMembers(code) {
  try {
    const response = await axios.get(api_url + "departementmember/code/" + code, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Add a member to a departement
 * @param user_id - the id of the user you want to add to the departement
 * @param departement_code - the code of the departement
 * @returns The response from the server.
 */
export async function addDepartementMembre(user_id, departement_code) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_id: user_id,
    departement_code: departement_code,
  };

  try {
    const response = await axios.post(api_url + "departementmember", credentiel, config);
    console.log(response);
    toast.success(t("membre ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Delete a departement member by code
 * @param code - The code of the departement member to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "string",
 *     "code": "string",
 *     "name": "string",
 *     "description": "string",
 *     "createdAt": "string",
 *     "updatedAt": "string"
 *   }
 * }
 * ```
 */
export async function deleteDepartementMemberByCode(code) {
  try {
    const response = await axios.delete(api_url + "departementmember/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
