import React, { useState, useEffect } from "react";
import { getSequenceByCode, editerSequence } from "../../../Services/Facturation/FacturationSequenceApi";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function Prefix() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [seqFacture, setseqFacture] = useState("");
  const [seqDevis, setseqDevis] = useState("");
  const [seqCommande, setseqCommande] = useState("");
  const [seqBl, setseqBl] = useState("");

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getSequenceByCode().then((res) => {
      setseqFacture(res.data.seq_facture);
      setseqDevis(res.data.seq_devis);
      setseqCommande(res.data.seq_commande);
      setseqBl(res.data.seq_bl);
    });
  }, []);

  const handleSaveSequence = (event) => {
    event.preventDefault();
    setSubmitting(true);
    editerSequence(seqFacture, seqDevis, seqCommande, seqBl).then((res) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 3000);
    });
  };
  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">{t("Aperçu Tax")}</h4>
        </div>
        <div className="card-body">
          <div className="container">
            <form onSubmit={handleSaveSequence}>
              <div className="form-group">
                <label htmlFor="exampleInputFacture">{t("Préfixe Facture")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputFacture"
                  aria-describedby="emailHelp"
                  value={seqFacture || ""}
                  onChange={(e) => setseqFacture(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputDevis">{t("Préfixe Devis")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputDevis"
                  aria-describedby="emailHelp"
                  value={seqDevis || ""}
                  onChange={(e) => setseqDevis(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputCommande">{t("Préfixe Commande")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputCommande"
                  aria-describedby="emailHelp"
                  value={seqCommande || ""}
                  onChange={(e) => setseqCommande(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputBl">{t("Préfixe Bon de livraison")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputBl"
                  value={seqBl || ""}
                  onChange={(e) => setseqBl(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                {t("Enregistrer")}
              </button>
            </form>
            {submitting && <CustomSyncLoader></CustomSyncLoader>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prefix;
