import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../../Components/Others/CustomSyncLoader";
import ErrorCustum from "../../Components/Others/ErrorCustum";
import ImageComponent from "../../Components/Styles/ImageComponent";
import { getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";

function PointageCard() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [size, setsize] = useState(8);
  const [date, setDate] = useState(new Date());
  const [users, setusers] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  const getData = () => {
    setLoading(true);
    getPointageByCode()
      .then((res) => {
        console.log(res.data);
        if (res?.data?.success != "0") {
          setallInitialData(res.data);
          setdata(res.data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const getTimeCheckin = (word) => {
    let timeCheckin = word.split(",");
    return timeCheckin[0].split(" ")[1];
  };
  const getTimeCheckout = (word) => {
    let timeCheckout = word.split(",");
    if (timeCheckout.length === 1) {
      return "-";
    } else {
      return timeCheckout[timeCheckout.length - 1].split(" ")[1];
    }
  };

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="card">
          <div className="card-header border-0">
            <h3 className="card-title">{t("Pointage")}</h3>
            <div className="card-options ">
              <Link to="/admin/jourpointage" className="btn btn-outline-light mr-3">
                {t("Voir plus")}
              </Link>
              {/* <div className="dropdown">
                {" "}
                <a
                  href="#"
                  className="btn btn-outline-light dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {" "}
                  Date <i className="feather feather-chevron-down" />{" "}
                </a>
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">Monthly</Link>
                  </li>
                  <li>
                    <Link to="#">Yearly</Link>
                  </li>
                  <li>
                    <Link to="#">Weekly</Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="table-responsive attendance_table mt-4 border-top">
            <table className="table mb-0 text-nowrap">
              <thead>
                <tr>
                  <th className="text-center"></th>
                  <th className="text-left">{t("Collaborateur")}</th>
                  <th className="text-center">{t("Statut")}</th>
                  <th className="text-center">{t("Pointage E/")}</th>
                  <th className="text-center">{t("Pointage /S")}</th>
                  <th className="text-left">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((el, idx) => (
                  <tr key={idx} className="border-bottom">
                    <td className="text-center">
                      <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                    </td>
                    <td className="font-weight-semibold fs-14">
                      {el.nom} {el.prenom}
                    </td>
                    <td className="text-center">
                      <span className="badge bg-success-transparent">Présent</span>
                    </td>
                    <td className="text-center">{getTimeCheckin(el.pointage)}</td>
                    <td className="text-center">{getTimeCheckout(el.pointage)}</td>
                    <td className="text-center">
                      {/* <Link to="#" className="action-btns" data-toggle="tooltip" data-placement="top" title="Contact">
                        <i className="feather feather-phone-call text-primary" />
                      </Link> */}
                      <Link
                        to={"/admin/chat/" + el.user_code_id}
                        className="action-btns"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Chat"
                      >
                        <i className="feather-message-circle  text-success" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default PointageCard;
