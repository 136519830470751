import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { ajouterDeplacement } from "../../Services/Pointeuse/DeplacementApi";
import { getAllClientsparType } from "../../Services/Facturation/FacturationClientApi";

function ClientModal({ onSelect }) { 
  const myRefDelete = useRef(null);
  const [userId, setUserId] = useState(null);
  const [clients, setClients] = useState([]);

  // Add state to store the selected client
  const [selectedClient, setSelectedClient] = useState({}); // Initialize as an empty object

  useEffect(() => {
    getAllClientsparType().then((res)=>{
      setClients(res.data)
    });
  }, []);
  console.log("user id :",userId)
  console.log("selectedClient:",selectedClient)
    return (
      <div
        className="modal fade"
        id="addDeplacementModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addDeplacementModalolidayModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
               
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>
              {t("Clients")}<span className="text-danger">*</span>
            </label>

            <select onChange={(e) => {
              setUserId(e.target.value);
              // Find and set the selected client
              const selected = clients.find(client => client.id === parseInt(e.target.value));
              setSelectedClient(selected);
            }} className="selectpicker form-control">
              <option value="0">{t("Choisir un collaborateur")}</option>
              {clients &&
                clients.map((el, idx) => (
                  <option key={idx} value={el.id}>
                    {el.nom} 
                  </option>
                ))}
            </select>
          </div>
        </div>  
      </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                {t("Annuler")}
              </button>
                <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // Check if selectedClient is available
                  if (selectedClient) {
                    // Pass the selected client back to the Table component
                    onSelect(selectedClient);
                    // Close the modal
                    document.getElementById('addDeplacementModal').click();
                  } else {
                    console.error("No client selected");
                  }
                }}
              >
                {t("Ajouter")}
              </button>

            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ClientModal;