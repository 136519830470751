import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { getAllDepartementMembers } from "../../../Services/Pointeuse/DepartementMemberApi";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import { getAllDepartements } from "../../../Services/Pointeuse/DepartementApi";
import { getAllValideCongeByCode } from "../../../Services/Pointeuse/CongeApi";
import { getTasksByCode } from "../../../Services/Pointeuse/TasksApi";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartJsTaskType = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options] = useState({
    responsive: true,
    legend: false,
    maintainAspectRatio: false,
  });

  function random_rgba(value) {
    let backColor = [];
    var o = Math.round,
      r = Math.random,
      s = 255;
    for (var i = 0; i < value; i++) {
      backColor.push("rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + "," + r().toFixed(1) + ")");
    }
    return backColor;
  }

  useEffect(() => {
    setLoading(true);
    let names = [];
    let lengths = [];
    let motifs = [];
    var colorsList = null;
    getTasksByCode()
      .then((res) => {
        colorsList = random_rgba(res.data.length);

        res.data.map((item) => {
          motifs.push(item.type);
        });

        const obj = motifs.reduce((prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev), {});
        console.log(obj);
        names = Object.keys(obj);
        lengths = Object.values(obj);

        const data = {
          labels: names,
          datasets: [
            {
              label: "# of Votes",
              data: lengths,
              backgroundColor: ["rgba(31,122,252,0.9)", "rgb(247,40,74)"],
              borderWidth: 1,
            },
          ],
        };

        setData(data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  //console.log(random_rgba(6));

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && <Pie data={data} height={400} options={options} />}
    </>
  );
};

export default PieChartJsTaskType;
