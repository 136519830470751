import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination";
import { deletePaiementById, getPaiementByCode } from "../../../Services/Facturation/FacturationPaiementApi";
import { convertToDigit } from "../../../Utils/NumberUtils";
import { sumMontant } from "../../../Utils/ObjectOperation";
import { filterEncaissement } from "../../../Utils/SortObject";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import swal from "sweetalert";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CopieContent from "../../Exports/CopieContent";
import { success_message,error_message } from "../../../Components/alerte/AlerteMessage";
import moment from "moment";
import { t } from "i18next";
function ListPaiement() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [idAction, setidAction] = useState(null);
  const myRefDelete = useRef(null);
  const [size, setsize] = useState(20);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getPaiementByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    const dataSearch = filterEncaissement(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deletePaiementById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  const addIdToAction = (id) => {
    setidAction(id);
  };
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  const onChangeDate = () => {
 
    const filteredDate = currentData.filter((listed) => {
      const momentDate = moment(listed.date_echeance);
      if(startDate > endDate){
        error_message(t("Attention La date début doit être inférieur à la date fin !!"))
      }else{
      return (
        momentDate.isSameOrAfter(moment(startDate)) &&
        momentDate.isSameOrBefore(moment(endDate))
      )};
    });
    setcurrentData(filteredDate);
  };
  
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Paiement")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addpaiement" className="btn btn-primary mr-3">
                {t("Ajouter paiement")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>
                
     
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Liste des paiements")}</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  {/* <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> activé
                  </span> */}
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("Total")}
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                  <i className="fa fa-star text-warning" /> ---&gt; Holiday
                </span>
                <span className="badge badge-orange-light mr-2">
                  <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_paiements"} />
                          <ExportPdf
                            data={data}
                            name={"List_paiements"}
                            columns={["num_piece", "idClient", "nom", "prenom", "montant", "date_echeance"]}
                          />
                          <CopieContent />
                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">{t("Facture")}</th>
                              <th className="border-bottom-0">{t("Ref Facture")}</th>
                              <th className="border-bottom-0">{t("Nom du client")}</th>
                              <th className="border-bottom-0">{t("Référence")}</th>
                              {/* <th>Méthode</th> */}
                              <th className="border-bottom-0">{t("Source")}</th>
                              <th>
                                <span className="badge badge-pill badge-danger">{sumMontant(data)}</span>
                                <br></br>
                                {t("Montant")}
                              </th>
                              {/* <th className="text-center">Status</th> */}
                              <th className="text-right">{t("Date d'échéance")}</th>
                              <th className="text-right">{t("Date de réception")}</th>
                              <th>{t("Actions")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                <td>
                                  {el.type === "facture" && (
                                    <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                  {el.type === "devis" && (
                                    <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                  {el.type === "bl" && (
                                    <Link to={"/admin/editerblvente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                  {el.type === "commande" && (
                                    <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.code_facture}</Link>
                                  )}
                                </td>

                                <td>{el.num_piece}</td>
                                <td>
                                  <Link to={"/admin/client/" + el.idClient}>
                                    {el.nom}
                                    {el.prenom}
                                  </Link>
                                </td>
                                <td>{el.reference_banque}</td>
                                {/* <td>{el.mode_paiement}</td> */}
                                <td>{el.banque}</td>
                                <td>{convertToDigit(el.montant)}</td>
                                {/* <td className="text-center">
                            {el.status === "payer" && <span className="badge badge-success">Payé</span>}
                            {el.status === "partiel" && <span className="badge badge-warning">Partiel</span>}
                            {el.status === "" && <span className="badge badge-danger"></span>}
                          </td> */}
                                <td className="text-right">{el.date_echeance}</td>
                                <td className="text-right">{el.date_reception}</td>
                                <td className="text-left">
                                  <Link
                                    to={"/admin/facturation/editpaiement/" + el.id}
                                    className="action-btns"
                                    data-tip="editer"
                                  >
                                    <i className="feather feather-edit text-info" />
                                  </Link>

                                  <Link
                                    to="#"
                                    className="action-btns"
                                    data-tip="supprimer"
                                    onClick={() => handleDelete(el.id)}
                                  >
                                    <i className="feather feather-x text-danger" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                        {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* modal enc */}
      <div
        className="modal fade"
        id="modalDeleteEncaissement"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modalDeleteEncaissementTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Suppression
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Voulez vous vraiment supprimé ce encaissement ?</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                Annuler
              </button>
              <button type="button" className="btn btn-primary" onClick={() => handleDelete(idAction)}>
                Supprimé
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListPaiement;
