import React from "react";

/**
 * This function returns a div element that contains a Tawk.to chat widget
 * @returns The `Support` component is returning a div element with a class of `page-wrapper`. Inside
 * of the div is another div element with a class of `content m-4`. This `content` div contains a
 * `container` div. The `container` div contains the `row` div. The `row` div contains an iframe
 * element.
 */
function Support() {
  return (
    <div className="page-wrapper">
      <div className="content m-4">
        <div className="container">
          {/* Card */}
          <h4 className="page-title">Support</h4>
          <div className="row">
            <object
              type="text/html"
              data="https://tawk.to/chat/5f296b929b7cf26e73c1f35e/default"
              width="100%"
              height="700px"
              style={{ overflow: "auto" }}
            ></object>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
