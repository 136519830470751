import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#fff";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#142328",
    borderBottomWidth: 1,
    alignItems: "center",
    
    fontStyle: "bold",
  },
  rowBl: {
    flexDirection: "row",
    borderBottomColor: "#142328",
    //borderBottomWidth: 1,
    alignItems: "center",
    height: 40,
    fontStyle: "bold",
  },
  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    fontStyle: "bold",
    marginTop:-5
  },
  description: {
    width: "70%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 0,
    fontSize: 10,
  },

  pu: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 1,
    fontSize: 10,
  },
  qte: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 1,
    fontSize: 10,
  },
  remise: {
    width: "15%",
    textAlign: "center",
    paddingRight: 1,
    fontSize: 10,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 1,
    fontSize: 10,
  },
  label: {
    fontWeight: 'bold', // You can customize the font weight
    marginRight: 5, // You can adjust the margin
  },
});

const InvoiceTableRow = ({ items, isremise, invoice }) => { 
  const rows = items.map((item, idx) => (
    <View key={idx}>
       <View style={styles.row2}>
        <Text style={styles.description}>{item.libelleProduit} </Text>
         <Text style={styles.qte}>{item.prixHt}</Text>
         <Text style={styles.qte}>{item.qte}</Text>
       
         {invoice.title === "facture" && (
      <View style={styles.row}>
        {item.remise !== "0" ? (
          <Text style={styles.remise}>
            {item.remise}
            {item.actionRemise === "2" ? " TND" : " %"}
          </Text>
        ) : isremise === true ? (
          <Text style={styles.remise}></Text>
        ) : (
          <></>
        )}
      </View>
    )}
        {invoice.title === "Bon de livraison" ?(
        <Text style={styles.total}>{invoice.totalHt} TND</Text>):(<Text style={styles.total}>{item.totalHt} TND</Text>)}
      </View>
      {/* Additional row for details */}
      {invoice.title === "Bon de livraison" ? (
        <> 
          <View style={styles.row}> 
            <Text style={styles.additionalInfo}>
            {item.libelleProduct}       {`Numéro de série: ${item.num_serie}`}
            </Text>
          </View>
        </>
      ) : invoice.title === "Facture" ?(
        <View style={styles.row}>
          <Text style={styles.additionalInfo}>
            {`Du ${moment(item.date_debut).format("DD-MM-YYYY")} Au ${moment(item.date_fin).format("DD-MM-YYYY")} - ${item.nbr_mois} mois - ${item.nbr_users} utilisateurs` }
          </Text>
        </View>
      ):(<></>)}
    </View>
  ));

  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;





// const InvoiceTableRow = ({ items, isremise,invoice }) => {
//   console.log("invoiceTableRow",items,isremise,invoice);
//   const rows = items.map((item, idx) => (
//     <View key={idx} style={styles.row}>
//       <Text style={styles.description}>{item.libelleProduit}</Text>
//       <Text style={styles.pu}>{item.prixHt}</Text>
//       <Text style={styles.qte}>{item.qte}</Text>
//       {item.remise != "0" ? (
//         <Text style={styles.remise}>
//           {item.remise}
//           {item.actionRemise === "2" ? " TND" : " %"}
//         </Text>
//       ) : //egal to 0
//       // <Text style={styles.remise}></Text>
//       isremise == true ? (
//         <Text style={styles.remise}></Text>
//       ) : (
//         <></>
//       )}
//       <Text style={styles.total}>{item.totalHt} TND</Text>
//     </View>
//   ));
//   return <Fragment>{rows}</Fragment>;
// };

// export default InvoiceTableRow;
