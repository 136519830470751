import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getProjetByCode } from "../../Services/Pointeuse/ProjetApi";
import { addTaskMedia, deleteTaskMediaByTaskId, getTaskMediaByTaskId } from "../../Services/Pointeuse/TaskMediaApi";
import { addTaskToUser, getTasksById, senFcmTask, upadateTaskById, updateTache } from "../../Services/Pointeuse/TasksApi";
import { getUserById, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import * as FileSaver from "file-saver";
import swal from "sweetalert";
import moment from "moment";
import firebase from "../../firebase";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ImageComponent from "../Styles/ImageComponent";
import { getSession, getToken } from "../../Utils/SessionUtils";
import axios from "axios";
import { t } from "i18next";
import { getClientSupportByCode, getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import { nombreJoursEntreDates } from "../../Utils/DateUtils";
import { handleKeyPress, handleKeyPress_number } from "../../Utils/InputUtils";
import { addTache } from "../../Services/taches/TachesAPI";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

function DetailTask({ datatask, role, assigned_to, setassigned_to }) {
  const history = useHistory();
  const [object, setobject] = useState();
  const myRefFile = useRef();
  const [description, setdescription] = useState(null);
  const [etat, setetat] = useState(null);
  const [commentaire, setcommentaire] = useState(null);
  const [dateDebut, setdateDebut] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [dateFin, setdateFin] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const myRefDelete = useRef(null);
  const [userId, setuserId] = useState(null);
  const [projects, setprojects] = useState(null);
  const [projectId, setprojectId] = useState(null);
  const [color, setcolor] = useState(null);
  const [project, setproject] = useState("");
  const [users, setusers] = useState(null);
  const [type, settype] = useState("task");
  const [data, setdata] = useState(null);
  const [priority, setpriority] = useState("0");
  const [filesTask, setfilesTask] = useState(null);
  const [files, setFiles] = useState([]);
  const [clients, setclients] = useState([])
  const [client_list, setclient_list] = useState([])

  const listType = ['courtoisie', 'développement', 'réclamation', 'demande', 'formation', 'information', 'autre']
  const canaltype = ['tel', 'Email', 'whatsapp', 'autre']
  const [Task_data, setTask_data] = useState({
    id: datatask?.id || '',
    object: datatask?.object || '',
    description: datatask?.description || '',
    projet_id: datatask?.projet_id || '',
    user_code_id: datatask?.user_code_id || localStorage.getItem('id'),
    priority: datatask?.priority || 0,
    type: datatask?.type || '',
    commentaire: datatask?.commentaire || '',
    date_debut: datatask?.date_debut || null,
    date_fin: datatask?.date_fin || null,
    created_at: datatask?.created_at || '',
    etat: datatask?.etat || null,
    nmbr_jour: datatask?.nmbr_jour || 0,
    nmbr_heure: datatask?.nmbr_heure || 0,
    createdBy: datatask?.createdBy | localStorage.getItem('id'),
    code_generated: datatask?.code_generated || localStorage.getItem('code_generated'),
    canal: datatask?.canal || '',
    lien: datatask?.lien || '',
    assignedto: datatask?.assignedto || '',
    client_id:datatask?.client_id || ''

  })
  useEffect(() => {
    getClientSupportByCode().then((res) => {
      const result = res?.data;
  
      if(result){
        const options = result.map((d) => ({
          value: d.code,
          label: d.nom ,
          secondValue: d.id,
          code_client:d.code_client
        }));
        setclients(options);
      }
  
    });
  }, [])
  useEffect(()=>{
    
      if(datatask?.client_id && clients.length !==0){
        const findclient = clients.findIndex(item=>item.code ===datatask.client_id)

        if(findclient === -1){

          const listclient = clients.filter(item=>item.code_client=== datatask.client_id)
         

          if(listclient.length !== 0){
            const options = listclient.map((d) => ({
              value: d.value,
              label: d.label ,
              secondValue: d.secondValue,
              code_client:d.code_client
            }));
            setclient_list(options);
          }
        }
      }
  },[datatask,clients])
  const handelchange = (name, value) => {
    if (name === 'date_debut') {
      const numbrjour = nombreJoursEntreDates(value, Task_data.date_fin)
      setTask_data(prev => ({
        ...prev,
        nmbr_jour: numbrjour + 1 || 0

      }));
    }
    else if (name === 'date_fin') {
      const numbrjour = nombreJoursEntreDates(Task_data.date_debut, value)
      setTask_data(prev => ({
        ...prev,
        nmbr_jour: numbrjour + 1 || 0

      }));
    } else if (name === 'user_code_id') {
      // get user by id
      const founduser = users.find(item => item.id === parseInt(value))
      if (founduser) {
        setassigned_to({
          id: founduser.id,
          nom: founduser.nom,
          prenom: founduser.prenom,
          email: founduser.email,
          role: founduser.role_code,
        })
        setTask_data(prev => ({
          ...prev,
          [name]: founduser.code

        }));
      }

    }
    setTask_data(prev => ({
      ...prev,
      [name]: value

    }));
  }



  /*const getData = () => {
    getTaskMediaByTaskId(taskId).then((res) => {
      setfilesTask(res.data);
    });
  };**/

  useEffect(() => {
    getProjetByCode().then((res) => {
      setproject(res.data);
    });
  }, []);

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  /*const handleUploadFiles = () => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i].file;
      addTaskMedia(file, taskId).then((res) => {
        console.log(res);
        setFiles(null);
       // getData();
      });
    }
  };*/

  const handleDeleteFile = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteTaskMediaByTaskId(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          // getData();
        });
      }
    });
  };

  /**
   * It saves the file to the local machine.
   * @param file - The file to download.
   */
  const saveFile = (file) => {
    FileSaver.saveAs(process.env.REACT_APP_API + file, file.split("Z")[file.length - 1]);
  };

  const sendFirebaseNotification = (text, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmTask(text, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It sends the data to the API.
   */
  /* const handelUpdate = () => {
     console.log(commentaire);
 
     upadateTaskById(userId, object, description, etat, commentaire, dateDebut, dateFin, color, projectId, type, priority, taskId).then((res) => {
       //console.log(res.data);
       //history.push("/admin/task");
       sendFirebaseNotification(object, userId);
       handleUploadFiles();
     });
   };*/
  const handelsave = () => {
    // Check if required names are filled in
    if (
      Task_data.object.trim() === '' ||
      Task_data.client_id === '' ||
      Task_data.projet_id === '' ||
      Task_data.priority === '' ||
      Task_data.type === '' ||
      Task_data.date_debut === '' ||
      Task_data.etat === null
    ) {
      // Show an error message indicating required names
      toast.error('Veuillez remplir tous les champs requis');
      return; // Stop execution if any required name is missing
    }

    // Proceed with save operation
    if (Task_data.id !== '') {
      updateTache(Task_data).then((res) => {
        if (res.status === 200) {
          toast.success(t("tache modifiée "));
          console.log(res);
          if (localStorage.getItem('role_code') === 'admin') {
            history.push("/admin/task");
          } else {
            history.push("/task");

          }
        }
      });
    } else {
      delete Task_data.id
      addTache(Task_data).then((res) => {
        if (res?.status === 200) {
          toast.success(t("tache ajoutée "));
          if (localStorage.getItem('role_code') === 'admin') {
            history.push("/admin/task");
          } else {
            history.push("/task");

          }
        } else {
          toast.error(t("un problème "));

        }
      });
    }
  };

  const handleClientChange = (selectedOption) => {
    setTask_data(prev => ({
      ...prev,
     client_id: selectedOption.value

    }));
  };

  return (
    <div className="card-body">

      <div className="main-profile-bio mb-0">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("Titre")}<span className="text-danger">*</span></label>
              <input required className="form-control" placeholder={t("Text")} value={Task_data.object} onChange={(e) => handelchange('object', e.target.value)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>
                  {t("Client")}
                </label>

             
             {client_list.length!== 0 ? (
              <Select
                  onChange={handleClientChange}
                  value={client_list.find(item => item.value === Task_data.client_id)}
                  options={client_list}
                  placeholder={t("Choisir un client")}
                />
             ):
             <Select
                  onChange={handleClientChange}
                  value={clients.find(item => item.value === Task_data.client_id)}
                  options={clients}
                  placeholder={t("Choisir un client")}
                />}
                
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>
                  {t("Projet")}<span className="text-danger">*</span>
                </label>

                <select onChange={(e) => handelchange('projet_id', e.target.value)} className="selectpicker form-control" value={Task_data.projet_id}>
                  <option value="0">{t("Choisir un projet")}</option>
                  {project &&
                    project.map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.nom}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          {role === 'admin' ? (
            <div className="col-md-6">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    {t("Collaborateur")}<span className="text-danger">*</span>
                  </label>

                  <select onChange={(e) => handelchange('user_code_id', e.target.value)} className="selectpicker form-control" value={Task_data.user_code_id}>
                    <option value="0">{t("Choisir un collaborateur")}</option>
                    {users &&
                      users.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom} {el.prenom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}

        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("Priorité")}:<span className="text-danger">*</span></label>
              <select
                name="attendance"
                className="form-control custom-select select2"
                data-placeholder={t("Choisir priorité")}
                onChange={(e) => handelchange('priority', e.target.value)}
                value={Task_data.priority}
              >
                <option value={0}>0-Aucune priorité</option>
                <option value={1}>1-Priorité la plus élevée</option>
                <option value={2}>2-Priorité élevée</option>
                <option value={3}>3-Priorité moyenne</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("Type")}:<span className="text-danger">*</span></label>
              <select
                name="attendance"
                className="form-control custom-select select2"
                data-placeholder={t("Choisir type de tâche")}
                onChange={(e) => handelchange('type', e.target.value)}
                value={Task_data.type}
              >
                <option value=''>choisir un type</option>
                {listType.map(item => (
                  <option key={item} value={item}>
                    {t(item)}
                  </option>
                ))}
              </select>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">{t("Date début")}:<span className="text-danger">*</span></label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="feather feather-calendar" />
                  </div>
                </div>
                <input
                  className="form-control fc-datepicker"
                  placeholder="DD-MM-YYY"
                  type="date"
                  onChange={(e) => handelchange('date_debut', moment(e.target.value).format("YYYY-MM-DD"))}
                  value={Task_data.date_debut}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-label">{t("Date fin")}:</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="feather feather-calendar" />
                  </div>
                </div>
                <input
                  className="form-control fc-datepicker"
                  placeholder="DD-MM-YYY"
                  type="date"
                  onChange={(e) => handelchange('date_fin', moment(e.target.value).format("YYYY-MM-DD"))}
                  value={Task_data.date_fin}
                />
              </div>
            </div>
          </div>
          {Task_data.date_fin !== null &&
           <div className="col-md-4">
           <div className="form-group">
             <label className="form-label">{t("nombre de jour")}:</label>
             <div className="input-group">

               {Task_data.nmbr_jour}
             </div>
           </div>
         </div>}
         
        </div>

        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">{t("Description")}</label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            value={Task_data.description}
            onChange={(e) => handelchange('description', e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">{t("Commentaire")}</label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            value={Task_data.commentaire}
            onChange={(e) => handelchange('commentaire', e.target.value)}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("lien trello")}:</label>
              <input
                type='text'
                className="form-control"
                value={Task_data.lien}
                onChange={(e) => handelchange('lien', e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("Choisir Canal")}:</label>
              <select
                name="attendance"
                className="form-control custom-select select2"
                data-placeholder={t("Choisir Statut")}
                onChange={(e) => handelchange('canal', e.target.value)}
                value={Task_data.canal}

              >
                <option label={t("Choisir canal")} />
                {canaltype.map(item => (
                  <option key={item} value={item}>
                    {t(item)}
                  </option>
                ))}


              </select>
            </div>
          </div>


        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("Choisir Statut")}:<span className="text-danger">*</span></label>
              <select
                name="attendance"
                className="form-control custom-select select2"
                data-placeholder={t("Choisir Statut")}
                onChange={(e) => handelchange('etat', e.target.value)}
                value={Task_data.etat}

              >
                <option label={t("Choisir Statut")} />
                <option value="afaire">{t("à faire")}</option>
                <option value="encours">{t("en cours")}</option>
                <option value="dev">{t("développement")}</option>
                <option value="tester">{t("à tester")}</option>
                <option value="terminee">{t("terminé")}</option>
                <option value="revoir">{t("à revoir")}</option>

              </select>
            </div>
          </div>

          {Task_data.etat === 'terminee' ? (
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("Nombre d'heure")}:</label>
                <input
                  className="form-control "
                  type="text"
                  onChange={(e) => handelchange('nmbr_heure', parseFloat(e.target.value))}
                  onKeyPress={handleKeyPress}
                  value={Task_data.nmbr_heure || 0}
                />
              </div>
            </div>
          ) : Task_data.etat === 'dev' ? (
            <div className="col-md-6">
              <div className="form-group">
                <div className="form-group col-md-12">
                  <label>
                    {t(" autre Collaborateur")}
                  </label>

                  <select onChange={(e) => handelchange('assignedto', e.target.value)} className="selectpicker form-control" value={Task_data.assignedto}>
                    <option value="0">{t("Choisir un collaborateur")}</option>
                    {users &&
                      users.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom} {el.prenom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}

        </div>


        {/* <div className="custom-controls-stacked d-md-flex">
            <label className="form-label mt-1 mr-5">Statut :</label>
            <label className="custom-control custom-radio success mr-4">
              <input type="radio" className="custom-control-input" name="example-radios1" checked onClick={() => setetat("afaire")} />
              <span className="custom-control-label">à faire</span>
            </label>
            <label className="custom-control custom-radio success mr-4">
              <input type="radio" className="custom-control-input" name="example-radios1" defaultValue="option2" onClick={() => setetat("encours")} />
              <span className="custom-control-label">en cours</span>
            </label>
     
          </div> */}
      </div>

      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="text-right">
            {/* <button type="button" id="submit" name="submit" className="btn btn-secondary">
                      Cancel
                    </button> */}
            <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handelsave}>
              {t("Enregistrer")}
            </button>
          </div>
        </div>
      </div>


    </div>
  );
}

export default DetailTask;
