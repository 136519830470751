import { getDistance, convertDistance } from "geolib";

/**
 * Given two coordinates, calculate the distance between them
 * @param c1 - The first coordinate.
 * @param c2 - The coordinates of the location you want to find the distance to.
 * @param settings - The settings object that contains the latitude and longitude of the location you
 * want to calculate the distance from.
 * @returns The distance between the two coordinates in meters.
 */
export function getDistanceBetweenTwoCoordinates(c1, c2, settings) {
  const distance = getDistance(
    { latitude: c1, longitude: c2 },
    { latitude: settings.latitude, longitude: settings.longitude }
  );
  return convertDistance(distance, "m");
}
