import React, { useEffect, useState } from "react";
import { PDFReader } from "reactjs-pdf-reader";

/**
 * This function takes in a fileUrl and returns a div with an image or pdf
 * @returns The return value is a function that returns a React component.
 */
function ShowDecharge({ fileUrl }) {
  const [url, seturl] = useState(null);
  useEffect(() => {
    seturl(fileUrl);
  }, [fileUrl]);
  return (
    <div key={fileUrl}>
      {url && ["png", "jpg", "jpeg"].some((element) => url.split(".").pop().includes(element)) && (
        <img src={localStorage.getItem("baseUrl") + url} alt="" />
      )}
      {url && url.split(".").pop() === "pdf" && <PDFReader url={localStorage.getItem("baseUrl") + url} />}
    </div>
  );
}
export default ShowDecharge;
