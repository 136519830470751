
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import { getTasksByCode } from "../../../Services/Pointeuse/TasksApi";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

var _ = require("lodash");

function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000;
}

export const options = {
  gantt: {
    criticalPathEnabled: true,
    innerGridHorizLine: {
      stroke: "#ffe0b2",
      strokeWidth: 2,
    },
    innerGridTrack: { fill: "#fff3e0" },
    innerGridDarkTrack: { fill: "#ffcc80" },
    arrow: {
      angle: 100,
      width: 5,
      color: "green",
      radius: 0,
    },
    trackHeight: 60,
    criticalPathStyle: {
      stroke: "#e64a19",
      strokeWidth: 5,
    },
  },
};

export function GantChart() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [values, setvalues] = useState(null);

  useEffect(() => {
    setLoading(true);
    getTasksByCode()
      .then((res) => {
        setdata(res.data);
        // console.log(res.data);

        const taskProject = res.data.filter((el) => {
          return el.projet_id;
        });

        const groupedbyProject = _.chain(taskProject)
          // Group the elements of Array based on `color` property
          .groupBy("projet")
          // `key` is group's name (color), `value` is the array of objects
          .map((value, key) => ({
            nom: key,
            debut: value[0].projet_date_debut,
            fin: value[0].projet_date_fin,
            tasks: value,
          }))
          .value();
        // console.log(groupedbyProject);

        var list = [];
        for (let i = 0; i < groupedbyProject.length; i++) {
          var list1 = [];
          list1.push(groupedbyProject[i].nom);
          list1.push(groupedbyProject[i].nom + "_new");
          const d_debut = groupedbyProject[i].debut.split("-")[2].split(" ")[0];
          const m_debut = groupedbyProject[i].debut.split("-")[1];
          const y_debut = groupedbyProject[i].debut.split("-")[0];
          const d_fin = groupedbyProject[i].fin.split("-")[2].split(" ")[0];
          const m_fin = groupedbyProject[i].fin.split("-")[1];
          const y_fin = groupedbyProject[i].fin.split("-")[0];
          list1.push(new Date(y_debut, m_debut, d_debut));
          list1.push(new Date(y_fin, m_fin, d_fin));
          list1.push(null);
          list1.push(null);
          list1.push(null);
          list.push(list1);
          const tasks = groupedbyProject[i].tasks;
          for (let j = 0; j < tasks.length; j++) {
            var list2 = [];
            list2.push(tasks[j].id + "");
            list2.push(tasks[j].object);
            const d_debut = tasks[j].date_debut.split("-")[2].split(" ")[0];
            const m_debut = tasks[j].date_debut.split("-")[1];
            const y_debut = tasks[j].date_debut.split("-")[0];
            const d_fin = tasks[j].date_fin.split("-")[2].split(" ")[0];
            const m_fin = tasks[j].date_fin.split("-")[1];
            const y_fin = tasks[j].date_fin.split("-")[0];
            list2.push(new Date(y_debut, m_debut, d_debut));
            list2.push(new Date(y_fin, m_fin, d_fin));
            list2.push(null);
            list2.push(null);
            list2.push(groupedbyProject[i].nom);
            list.push(list2);
          }
        }

        const columns = [
          { type: "string", label: "Task ID" },
          { type: "string", label: "Task Name" },
          { type: "date", label: "Start Date" },
          { type: "date", label: "End Date" },
          { type: "number", label: "Duration" },
          { type: "number", label: "Percent Complete" },
          { type: "string", label: "Dependencies" },
        ];

        const rows = [
          ["12", "Find sourcesz", new Date(2015, 0, 5), new Date(2015, 0, 6), null, null, null],
          ["14", "Create bibliéography", new Date(2015, 0, 5), new Date(2015, 0, 7), null, null, "12"],
          // ["15", "Outline paper", new Date(2015, 0, 6), new Date(2015, 0, 8), null, null, "12"],
          // ["16", "Write paper", new Date(2015, 0, 8), new Date(2015, 0, 14), null, null, "12"],
          // ["17", "Hand in paper", new Date(2015, 0, 10), new Date(2015, 0, 11), null, null, "12"],
          // ["18", "ff", new Date(2015, 1, 1), new Date(2015, 1, 5), null, null, null],
          // ["19", "fe", new Date(2015, 1, 4), new Date(2015, 1, 7), null, null, "18"],
          // ["20", "Outef", new Date(2015, 1, 4), new Date(2015, 1, 6), null, null, "18"],
          // ["21", "Wefe", new Date(2015, 1, 4), new Date(2015, 1, 9), null, null, "18"],
          // ["22", "Haeef", new Date(2015, 1, 4), new Date(2015, 1, 10), null, null, "18"],
        ];
        console.log(list.slice(0, 2));
        console.log(rows);

        setvalues([columns, ...list]);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Tableau des tâches")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <Link to="/admin/addvaccin" className="btn btn-primary mr-3">
              </Link> */}
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Diagramme de Gantt")}</h3>
              <div className="card-tools"></div>
            </div>
            <div>
              <Chart
                chartType="Gantt"
                width="100%"
                height="700px"
                data={values}
                rootProps={{ "data-testid": "1" }}
                loader={<CustomSyncLoader></CustomSyncLoader>}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
