import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";

function SectionFive() {
  return (
    <>
      <div className="home__hero-section darkBg">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line"></div>
                <h1 className="heading">Pointez facilement et rapidement depuis votre smartphone!</h1>
                <p className="home__hero-subtitle">
                  Nomateuse pointeuse est la meilleure solution digitale de pointage mobile, Application mobile
                  intelligente pour optimiser et automatiser la gestion administrative des absences et congés de votre
                  équipe.
                </p>
                <ul>
                  <li>Gestion des différents types de congés</li>
                  <li>Suivi du nombre d’heures travaillées</li>
                  <li>Indicateurs clés de suivi précis : Taux de retard, heures supplémentaires</li>
                  <li>Fonctionne sur ordinateur, mobile, tablette</li>
                  <li>Accessible 24h/24 et 7j/7</li>
                  <li>Application ergonomique et intuitive</li>
                </ul>
                {/* <Link to="//play.google.com/store/apps/details?id=com.pointeuse.chechin" target="_blank">
                  <Button buttonSize="btn--wide" buttonColor="blue">
                    Télecharger
                  </Button>
                </Link> */}
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src="assets/images/welcome/svg-8.svg" alt="google play" className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFive;
