import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get entete by code
 */
export async function getLigneCommercial() {
  try {
    const response = await axios.get(api_url + "facturation/ligne/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * get entete by code
 */
export async function getLigneCommercialFacture() {
  try {
    const response = await axios.get(api_url + "facturation/ligne/facture/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets the ligne by code.
 * @param code - The code of the ligne to get.
 * @returns The response is an object with a data property that contains an array of objects.
 */
export async function getLigneByCode(code) {
  try {
    const response = await axios.get(api_url + "facturation/ligne/entetecode/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// /**
//  * Add a new line to the invoice
//  * @param entetecommercial_code - The code of the header.
//  * @param produit_code - The product code
//  * @param quantite - The quantity of the product
//  * @param num_serie - The num_serie of the product
//  * @param pu_ht - unit price without taxes
//  * @param p_tva - the value of the product's tva
//  * @param taux_tva - The value of the taux_tva field.
//  * @param total_ht - total_ht,
//  * @param remise - remise,
//  * @param remise_client - remise_client,
//  * @param libelleProduit - The name of the product
//  * @param actionRemise - "",
//  * @param remise_valeur - The value of the discount.
//  * @param suivieStock - "",
//  * @returns The response is an object that contains the data that was returned by the API.
//  */
// export async function addLigne(
//   entetecommercial_code,
//   produit_code,
//   quantite,
//   num_serie,
//   pu_ht,
//   p_tva,
//   taux_tva,
//   total_ht,
//   remise,
//   remise_client,
//   libelleProduit,
//   actionRemise,
//   remise_valeur,
//   suivieStock
// ) {
//   const credentiel = {
//     entetecommercial_code: entetecommercial_code,
//     societe_code: getSession("code_generated"),
//     num_doc: "",
//     produit_code: produit_code,
//     num_config: "",
//     quantite: quantite,
//     num_serie:num_serie,
//     pu_ht: pu_ht,
//     pu_ttc: "0",
//     p_tva: p_tva,
//     prix_achat_ht: "0",
//     prix_achat_ttc: "0",
//     taux_tva: taux_tva,
//     total: "0",
//     total_net: "0",
//     total_ht: total_ht,
//     total_net_ht: "0",
//     configuration: "",
//     date_creation: "",
//     date_modification: "",
//     remise: remise,
//     remise_client: remise_client,
//     type: "",
//     presente: "",
//     email: "",
//     libelleProduit: libelleProduit,
//     actionRemise: actionRemise,
//     remise_valeur: remise_valeur,
//     suivieStock: suivieStock,
//     deleted_at: "2021-01-01",
//     created_at: "",
//     updated_a: "",
//   };

//   try {
//     const response = await axios.post(api_url + "facturation/ligne/", credentiel, config);
    
//     toast.success(t("ligne ajouter"));
//     return response;
//   } catch (error) {
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       toast.error(t("error server"));
//       console.log(error.response.data);
//       console.log(error.response.message);
//       console.log(error.response.status);
//       console.log(error.response.headers);
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the
//       // browser and an instance of
//       // http.ClientRequest in node.js
//       toast.error(t("error request"));
//       console.log(error.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       console.log(t("Error"), error.message);
//     }
//     console.log(error.config);
//     return error;
//   }
// }


/**
 * Add a new line to the invoice
 * @param entetecommercial_code - The code of the header.
 * @param produit_code - The product code
 * @param quantite - The quantity of the product
 * @param pu_ht - unit price without taxes
 * @param p_tva - the value of the product's tva
 * @param taux_tva - The value of the taux_tva field.
 * @param total_ht - total_ht,
 * @param remise - remise,
 * @param remise_client - remise_client,
 * @param libelleProduit - The name of the product
 * @param actionRemise - "",
 * @param remise_valeur - The value of the discount.
 * @param suivieStock - "", 
 * @returns The response is an object that contains the data that was returned by the API.
 */
export async function addLigne(
  entetecommercial_code,
  produit_code,
  quantite,
  pu_ht,
  p_tva,
  taux_tva,
  total_ht,
  remise,
  remise_client,
  libelleProduit,
  actionRemise,
  remise_valeur,
  suivieStock,
  num_serie,
) {
  console.log("data of ligne ",entetecommercial_code,
  produit_code,
  quantite,
  num_serie,
  pu_ht,
  p_tva,
  taux_tva,
  total_ht,
  remise,
  remise_client,
  libelleProduit,
  actionRemise,
  remise_valeur,
  suivieStock,
  num_serie,)

  const credentiel = {
    entetecommercial_code: entetecommercial_code,
    societe_code: getSession("code_generated"),
    num_doc: "",
    produit_code: produit_code,
    num_serie: num_serie,
    num_config: "",
    quantite: quantite,
    pu_ht: pu_ht,
    pu_ttc: "0",
    p_tva: p_tva,
    prix_achat_ht: "0",
    prix_achat_ttc: "0",
    taux_tva: taux_tva,
    total: "0",
    total_net: "0",
    total_ht: total_ht,
    total_net_ht: "0",
    configuration: "",
    date_creation: "",
    date_modification: "",
    remise: remise,
    remise_client: remise_client,
    type: "",
    presente: "",
    email: "",
    libelleProduit: libelleProduit,
    actionRemise: actionRemise,
    remise_valeur: remise_valeur,
    suivieStock: suivieStock, 
    deleted_at: "2021-01-01",
    created_at: "",
    updated_a: "",
  };
   try {
    const response = await axios.post(api_url + "facturation/ligne/", credentiel, config);
    toast.success(t("ligne ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    return error;
  }
}






export async function updateLigneCommercialFacture(
  entetecommercial_code,
  produit_code,
  quantite,
  pu_ht,
  p_tva,
  taux_tva,
  total_ht,
  remise,
  remise_client,
  libelleProduit,
  actionRemise,
  remise_valeur,
  suivieStock, 
) {
  const credentiel = {
    entetecommercial_code: entetecommercial_code,
    societe_code: getSession("code_generated"),
    num_doc: "",
    produit_code: produit_code,
    num_config: "",
    quantite: quantite,
    pu_ht: pu_ht,
    pu_ttc: "0",
    p_tva: p_tva,
    prix_achat_ht: "0",
    prix_achat_ttc: "0",
    taux_tva: taux_tva,
    total: "0",
    total_net: "0",
    total_ht: total_ht,
    total_net_ht: "0",
    num_serie: "",
    configuration: "",
    date_creation: "",
    date_modification: "",
    remise: remise,
    remise_client: remise_client,
    type: "",
    presente: "",
    email: "",
    libelleProduit: libelleProduit,
    actionRemise: actionRemise,
    remise_valeur: remise_valeur,
    suivieStock: suivieStock, 
    deleted_at: "2021-01-01",
    created_at: "",
    updated_a: "",
  };
   try {
    const response = await axios.put(api_url + "facturation/ligne/updated", credentiel, config);
    toast.success(t("ligne ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    return error;
  }
}



// /**
//  * Add a new line to the invoice
//  * @param entetecommercial_code - The code of the header.
//  * @param produit_code - The product code
//  * @param quantite - The quantity of the product
//  * @param list_num_serie - The quantity of the product
//  * @param num_serie - The num_serie of the product
//  * @param pu_ht - unit price without taxes
//  * @param p_tva - the value of the product's tva
//  * @param taux_tva - The value of the taux_tva field.
//  * @param total_ht - total_ht,
//  * @param remise - remise,
//  * @param remise_client - remise_client,
//  * @param libelleProduit - The name of the product
//  * @param actionRemise - "",
//  * @param remise_valeur - The value of the discount.
//  * @param suivieStock - "",
//  * @returns The response is an object that contains the data that was returned by the API.
//  */

// export async function addLigneAther(
//   entetecommercial_code,
//   produit_code,
//   quantite,
//   list_num_serie,
//   num_serie,
//   pu_ht,
//   p_tva,
//   taux_tva,
//   total_ht,
//   remise,
//   remise_client,
//   libelleProduit,
//   actionRemise,
//   remise_valeur,
//   suivieStock
// ) {
//   try {
//     // Ajoutez la vérification du numéro de série ici
//     const existingSeries = await checkNumSerieInAbonnement(list_num_serie);

//     if (existingSeries && existingSeries.length > 0) {
//       // Le numéro de série existe déjà dans la table facturation_abonnement
//       throw new Error("L'une des séries existe déjà dans la table facturation_abonnement");
//     }

//     // Le numéro de série n'existe pas, procéder à l'insertion dans facturation_serie
//     const credentiel = {
//       entetecommercial_code: entetecommercial_code,
//       societe_code: getSession("code_generated"),
//       num_doc: "",
//       produit_code: produit_code,
//       num_config: "",
//       quantite: quantite,
//       // Include list_num_serie only if num_serie is equal to 1
//       ...(num_serie === "1" && { list_num_serie: list_num_serie }),
//       num_serie: num_serie,
//       pu_ht: pu_ht,
//       pu_ttc: "0",
//       p_tva: p_tva,
//       prix_achat_ht: "0",
//       prix_achat_ttc: "0",
//       taux_tva: taux_tva,
//       total: "0",
//       total_net: "0",
//       total_ht: total_ht,
//       total_net_ht: "0",
//       configuration: "",
//       date_creation: "",
//       date_modification: "",
//       remise: remise,
//       remise_client: remise_client,
//       type: "",
//       presente: "",
//       email: "",
//       libelleProduit: libelleProduit,
//       actionRemise: actionRemise,
//       remise_valeur: remise_valeur,
//       suivieStock: suivieStock,
//       deleted_at: "2021-01-01",
//       created_at: "",
//       updated_a: "",
//     };

//     const response = await axios.post(api_url + "facturation/ligne/add", credentiel, config);
    
//     toast.success(t("ligne ajouter"));
//     return response;
//   } catch (error) {
//     // Gérez les erreurs comme vous le faites déjà
//     console.log(error);
//     return error;
//   }
// }
// const checkNumSerieInAbonnement = async (listNumSerie) => {
//   try {
//     const response = await axios.post(
//       'http://localhost:3001/api/v1/facturation/facturationSerie/checked',
//       { list_num_serie: listNumSerie },  // Assurez-vous que c'est bien un objet avec la clé 'list_num_serie'
//       { headers: { 'Content-Type': 'application/json' } }
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Erreur lors de la vérification du numéro de série :', error);
//     throw error;
//   }
// };


/**
 * Add a new line to the invoice
 * @param entetecommercial_code - The code of the header.
 * @param produit_code - The product code
 * @param quantite - The quantity of the product
 * @param list_num_serie - The quantity of the product
 * @param num_serie - The num_serie of the product
 * @param pu_ht - unit price without taxes
 * @param p_tva - the value of the product's tva
 * @param taux_tva - The value of the taux_tva field.
 * @param total_ht - total_ht,
 * @param remise - remise,
 * @param remise_client - remise_client,
 * @param libelleProduit - The name of the product
 * @param actionRemise - "",
 * @param remise_valeur - The value of the discount.
 * @param suivieStock - "",
 * @returns The response is an object that contains the data that was returned by the API.
 */
export async function addLigneAther(
  entetecommercial_code,
  produit_code,
  quantite,
  list_num_serie,
  num_serie,
  pu_ht,
  p_tva,
  taux_tva,
  total_ht,
  remise,
  remise_client,
  libelleProduit,
  actionRemise,
  remise_valeur,
  suivieStock
) {
  const credentiel = {
    entetecommercial_code: entetecommercial_code,
    societe_code: getSession("code_generated"),
    num_doc: "",
    produit_code: produit_code,
    num_config: "",
    quantite: quantite,
    // Include list_num_serie only if num_serie is equal to 1
    ...(num_serie === "1" && { list_num_serie: list_num_serie }),
    num_serie: num_serie,
    pu_ht: pu_ht,
    pu_ttc: "0",
    p_tva: p_tva,
    prix_achat_ht: "0",
    prix_achat_ttc: "0",
    taux_tva: taux_tva,
    total: "0",
    total_net: "0",
    total_ht: total_ht,
    total_net_ht: "0",
    configuration: "",
    date_creation: "",
    date_modification: "",
    remise: remise,
    remise_client: remise_client,
    type: "",
    presente: "",
    email: "",
    libelleProduit: libelleProduit,
    actionRemise: actionRemise,
    remise_valeur: remise_valeur,
    suivieStock: suivieStock,
    deleted_at: "2021-01-01",
    created_at: "",
    updated_a: "",
  };
  try {

    
    const response = await axios.post(api_url + "facturation/ligne/add", credentiel, config);
    
    toast.success(t("ligne ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}





// /**
//  * Add a new line to the invoice
//  * @param entetecommercial_code - The code of the header.
//  * @param produit_code - The product code
//  * @param quantite - The quantity of the product
//  * @param list_num_serie - The quantity of the product
//  * @param num_serie - The num_serie of the product
//  * @param pu_ht - unit price without taxes
//  * @param p_tva - the value of the product's tva
//  * @param taux_tva - The value of the taux_tva field.
//  * @param total_ht - total_ht,
//  * @param remise - remise,
//  * @param remise_client - remise_client,
//  * @param libelleProduit - The name of the product
//  * @param actionRemise - "",
//  * @param remise_valeur - The value of the discount.
//  * @param suivieStock - "",
//  * @returns The response is an object that contains the data that was returned by the API.
//  */
// export async function addLigneAther(
//   entetecommercial_code,
//   produit_code,
//   quantite,
//   list_num_serie,
//   num_serie,
//   pu_ht,
//   p_tva,
//   taux_tva,
//   total_ht,
//   remise,
//   remise_client,
//   libelleProduit,
//   actionRemise,
//   remise_valeur,
//   suivieStock
// ) {
//    // Ajoutez la vérification du numéro de série
//    if (list_num_serie && list_num_serie.length > 0) {
//     try {
//       const numSerieCheckResult = await checkNumSerieInAbonnement(list_num_serie);
      
//       if (!numSerieCheckResult.success) {
//         // Affichez un message d'erreur ou faites le traitement approprié pour les numéros de série invalides
//         toast.error(t("Les numéros de série ne sont pas valides."));
//         return { success: false, message: "Les numéros de série ne sont pas valides." };
//       }
//     } catch (error) {
//       console.error("Erreur lors de la vérification des numéros de série :", error);
//       toast.error(t("Erreur lors de la vérification des numéros de série."));
//       return { success: false, message: "Erreur lors de la vérification des numéros de série." };
//     }
//   }
//   const credentiel = {
//     entetecommercial_code: entetecommercial_code,
//     societe_code: getSession("code_generated"),
//     num_doc: "",
//     produit_code: produit_code,
//     num_config: "",
//     quantite: quantite,
//     // Include list_num_serie only if num_serie is equal to 1
//     ...(num_serie === "1" && { list_num_serie: list_num_serie }),
//     num_serie: num_serie,
//     pu_ht: pu_ht,
//     pu_ttc: "0",
//     p_tva: p_tva,
//     prix_achat_ht: "0",
//     prix_achat_ttc: "0",
//     taux_tva: taux_tva,
//     total: "0",
//     total_net: "0",
//     total_ht: total_ht,
//     total_net_ht: "0",
//     configuration: "",
//     date_creation: "",
//     date_modification: "",
//     remise: remise,
//     remise_client: remise_client,
//     type: "",
//     presente: "",
//     email: "",
//     libelleProduit: libelleProduit,
//     actionRemise: actionRemise,
//     remise_valeur: remise_valeur,
//     suivieStock: suivieStock,
//     deleted_at: "2021-01-01",
//     created_at: "",
//     updated_a: "",
//   };
//   try {

    
//     const response = await axios.post(api_url + "facturation/ligne/add", credentiel, config);
    
//     toast.success(t("ligne ajouter"));
//     return response;
//   } catch (error) {
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       toast.error(t("error server"));
//       console.log(error.response.data);
//       console.log(error.response.message);
//       console.log(error.response.status);
//       console.log(error.response.headers);
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the
//       // browser and an instance of
//       // http.ClientRequest in node.js
//       toast.error(t("error request"));
//       console.log(error.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       console.log(t("Error"), error.message);
//     }
//     console.log(error.config);
//     return error;
//   }
// }








// Modifiez la fonction pour accepter un tableau
export async function checkNumSerieInAbonnement(list_num_serie) {
  try {
    console.log("Liste des numéros de série à vérifier :", list_num_serie);
    // Assurez-vous que list_num_serie est un tableau avant de l'envoyer
    if (!Array.isArray(list_num_serie)) {
      throw new Error("list_num_serie doit être un tableau");
    }

    const response = await axios.post(api_url + "facturation/facturationSerie/checked", { list_num_serie }, config);
    console.log("Réponse de la vérification des numéros de série :", response.data);
    return response.data;
  } catch (error) {
    console.log("Erreur lors de la vérification du numéro de série dans la table facturation_abonnement :", error);
    throw error;
  }
}

// // Ajoutez cette fonction pour vérifier le numéro de série dans la table facturation_abonnement
// export async function checkNumSerieInAbonnement(list_num_serie) {
//   try {
//     const response = await axios.post(api_url + "facturation/facturationSerie/checked/", { list_num_serie }, config);
//     return response.data;
//   } catch (error) {
//     console.log("Erreur lors de la vérification du numéro de série dans la table facturation_abonnement :", error);
//     throw error;
//   }
// }


/**
 * It deletes a ligne by code entete.
 * @param codeEntete - The code of the invoice header to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "statusCode": 200,
 *   "statusMessage": "OK",
 *   "data": {
 *     "codeEntete": "ENT000001",
 *     "codeLigne": "LIG000001",
 *     "codeArticle": "ART000001",
 *     "quantite
 */
export async function deleteLigneByCodeEntete(codeEntete) {
  try {
    const response = await axios.delete(api_url + "facturation/ligne/" + codeEntete, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
