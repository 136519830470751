import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from "recharts";
import { convertToDigit } from "../../Utils/NumberUtils";
import { t } from "i18next";

/**
 * It creates a line chart with 3 lines.
 * @returns The `ObjectifChart1` component is returning a `LineChart` component.
 */
function ObjectifChart1({ objectifs, factures }) {
  const [data, setdata] = useState([]);
  useEffect(() => {
    setdata(factures.map((item, i) => Object.assign({}, item, objectifs[i])));
  }, [objectifs, factures]);
  return (
    <LineChart
      width={650}
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 50,
      }}
    >
      <defs>
        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#8884d8" stopOpacity={0.9} />
          <stop offset="75%" stopColor="#8884d8" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.9} />
          <stop offset="75%" stopColor="#82ca9d" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#ffc658" stopOpacity={0.9} />
          <stop offset="75%" stopColor="#ffc658" stopOpacity={0.1} />
        </linearGradient>
      </defs>

      <CartesianGrid opacity={0.1} vertical={false} />
      <XAxis dataKey="mois" angle={-90} textAnchor="end" axisLine={false} tickLine={false} tickCount={data.length} />
      <YAxis orientation="right" axisLine={false} tickLine={false} tickCount={data.length} />
      <Tooltip content={<CustomTooltip />} />

      <Line type="monotone" dataKey="realisation1" stackId="1" stroke="#8884d8" fill="#8884d8" fill="url(#color1)" />

      <Line
        type="monotone"
        dataKey="montant_total_ht"
        stackId="1"
        stroke="#82ca9d"
        fill="#82ca9d"
        fill="url(#color2)"
      />

      <Line type="monotone" dataKey="objectif" stackId="1" stroke="#ffc658" fill="#ffc658" fill="url(#color3)" />
    </LineChart>
  );
}
/**
 * It returns a custom tooltip for each data point.
 * @returns The CustomTooltip component is being returned.
 */
function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="">
        {/* <h4>{format(parseISO(label), "eeee, d MMM, yyyy")}</h4> */}
        <p>{t("Objectif")} 2022 : {convertToDigit(payload[2]?.value)} TND</p>
        <p>{t("Réalisation")} 2022 : {convertToDigit(payload[1]?.value)} TND</p>
        <p>{("Réalisation")} 2021 : {convertToDigit(payload[0]?.value)} TND</p>
      </div>
    );
  }
  return null;
}
export default ObjectifChart1;
