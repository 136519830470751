import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { getEnteteCommercialAchat, getEnteteCommercialVente, getEnteteCommercialVenteSommeNetPayer, getEnteteCommercialVenteSommeNetPayerChaqueAnnee } from "../../Services/Facturation/FacturationEnteteApi";
import { addObjectif, deleteObjectifByYear, getAllObjectif } from "../../Services/Pointeuse/ObjectifApi";
import { getObjectData } from "../../Utils/Data/ObjectData";
import { convertToDigit, convertToDigitt } from "../../Utils/NumberUtils";
import {
  calculateCroissance,
  calculateRealisationCroissance,
  calculateRealisationVSObjectif,
  calculateTotalCroissance,
  groupeByMonth,
  sortByMonth,
  sumO,
  sumObjectif,
} from "../../Utils/ObjectifUtils";
import ObjectifChart1 from "./ObjectifChart1";
import ObjectifChart2 from "./ObjectifChart2";
import PieApexGender from "../Charts/CustumApex/PieApexGender";
import ReactTooltip from 'react-tooltip';
import { t } from "i18next";

function Objectif() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [totalObjectif, settotalObjectif] = useState(0);
  const [totalCroissance, settotalCroissance] = useState(0);
  const [currentYear] = useState(new Date().getFullYear());
  const [inputList, setInputList] = useState([]);
  const [factures, setfactures] = useState([]);
  const [sumnet, setsumnet] = useState([]);
  const [totalRealisation, settotalRealisation] = useState(null);
  const [vsCurrentYear, setvsCurrentYear] = useState(null);
  const [vsLastYear, setvsLastYear] = useState(null);
  const [chiffreAffaire, setchiffreAffaire] = useState([]);
 
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    getEnteteCommercialVente().then((res) => {
      const groupedMonth = groupeByMonth(res.data, currentYear);
      setfactures(groupedMonth);
      settotalRealisation(groupedMonth.reduce((a, b) => +a + +b.montant_total_ht, 0));
    });
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  

  const currentMonth = new Date().getMonth() + 1; // Obtient le numéro du mois actuel (ajoutez 1 car les mois sont indexés de 0 à 11)

   /**
   * It gets all the data from the API and then groups the data by month.
   */

useEffect(()=>{
  getEnteteCommercialVenteSommeNetPayer().then((res)=>{
     setsumnet(res.data)
   })
},[]) 

  const getAllData = () => {
    setLoading(true);

    getEnteteCommercialVente().then((resEntete) => {
      console.log("result entete :",resEntete)
      const groupedMonth = groupeByMonth(resEntete.data, currentYear);
      console.log("groupedMonth :",groupedMonth)
      //console.log(groupedMonth);
      setfactures(groupedMonth); 
      const totalOfRealisation = groupedMonth.reduce((a, b) => +a + +b.montant_total_ht, 0); 
      settotalRealisation(totalOfRealisation);
      console.log("totalOfRealisation :",totalOfRealisation)
         // Ajout du code pour récupérer les nouvelles croissances
         getEnteteCommercialVenteSommeNetPayerChaqueAnnee().then((nouvellesCroissances) => {
      // Utilisez les nouvelles croissances comme bon vous semble
         getAllObjectif()
        .then((res) => {
          setdata(res.data);
         
          const result = res.data;
          console.log("888888888888888",result  )
          var objectData = getObjectData(sortByMonth(result));
         
           settotalObjectif(sumObjectif(objectData, "objectif"));
          settotalCroissance(
            calculateTotalCroissance(sumObjectif(objectData, "objectif"), sumObjectif(objectData, "realisation1"))
          );
          setvsCurrentYear(calculateRealisationVSObjectif(totalOfRealisation, sumObjectif(objectData, "objectif")));
          setvsLastYear(calculateRealisationCroissance(totalOfRealisation, sumObjectif(objectData, "realisation1")));
          setInputList(objectData);
          
          setchiffreAffaire(nouvellesCroissances.data)
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    });
  })
  };

 

  // handle input change
  /**
   * It updates the inputList with the new values.
   * @param e - The event object that contains the data of the event that triggered the function.
   * @param index - The index of the input list that we want to change.
   */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    const resultSumObjectif = sumObjectif(list, "objectif");
    settotalObjectif(resultSumObjectif);
    const totalCroissance = calculateCroissance(list[index]["objectif"], list[index]["realisation1"]);
    list[index]["croissance"] = totalCroissance;
    const totalOfRealisation = sumObjectif(list, "realisation1");
    settotalCroissance(calculateTotalCroissance(resultSumObjectif, totalOfRealisation));
    setInputList(list);
  };

  /**
   * It adds the objectifs to the database.
   */
  const handleSubmitObjectif = () => {
    const newList = inputList.filter((el) => {
      return el.objectif != "0";
    });
    //delete 2021
    deleteObjectifByYear(currentYear).then(() => {
      newList.forEach((el) => {
        addObjectif(el.objectif, "0", el.mois, currentYear);
      });
    });
    //add 2021
  };  
  const calculatePercentage = (totalNetAPayer, objectif) => { 
    if (!isNaN(totalNetAPayer) && !isNaN(objectif) && objectif !== 0) {
      return ((totalNetAPayer / objectif) * 100).toFixed(2) + "%";
    }
    return "";
  }; 
  
   return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Objectif")}</h4>
        </div> 
      </div>
       <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Objectif")} / {t("Réalisation")}</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <div className="card-body table-responsive p-0">
                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center", fontSize: 12 }}>{t("Mois")}</th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>
                            {t("Réalisation")} {new Date().getFullYear() - 2}
                          </th>
                          <th style={{ textAlign: "center", fontSize: 12 }}>
                            {t("Réalisation")} {new Date().getFullYear() - 1}
                          </th>
                        
                          <th style={{ textAlign: "center", fontSize: 12 }}>{t("Objectif")} {currentYear}</th>
                          <th
                            style={{ textAlign: 'center', fontSize: 12 }}
                            title={`(CA mois actuelle / objectif mois actuelle) * 100`}
                            data-type="dark"
                            data-place="top"
                          >
                            {t("Réalisation")} %
                          </th>
                           <th style={{ textAlign: "center", fontSize: 12 }}>{t("Réalisation")} {currentYear}</th>

                          <th
                            style={{ textAlign: "center", fontSize: 12 }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Réalisation vs objectif ${currentYear}`}
                          >
                            vs {currentYear -1}
                          </th>
                          <th
                            style={{ textAlign: "center", fontSize: 12 }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Croissance vs ${new Date().getFullYear() - 1}`}
                          >
                            vs {new Date().getFullYear() - 2}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputList.map((x, i) => {   

                          const monthNameToNumber = (monthName) => {
                            const months = {
                              janvier: 1,
                              février: 2,
                              mars: 3,
                              avril: 4,
                              mai: 5,
                              juin: 6,
                              juillet: 7,
                              aout: 8,
                              septembre: 9,
                              octobre: 10,
                              novembre: 11,
                              décembre: 12,
                            };
                            return months[monthName.toLowerCase()] || 0;
                          };
                          
                          const totalNetAPayerOfMonth = sumnet.find((item) => {
                            const xMonthNumber = monthNameToNumber(x.mois);
                            return item.month === xMonthNumber;
                          })?.totalNetAPayer || 0;  
                            return (
                            <tr key={i} style={{ padding: 0 }}>
                              <td>
                                 <input
                                  style={{ minWidth: 90 }}
                                  className="form-control"
                                  name="mois"
                                  value={x.mois}
                                   onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  name="realisation2"
                                  value={convertToDigit(x.realisation2)}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  name="realisation1"
                                  value={convertToDigit(x.realisation1)}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                             
                              <td>
                                <input
                                  style={{ textAlign: "right",width:"130px" }}
                                  className="form-control"
                                  type="number"
                                  name="objectif"
                                  placeholder={t("Objectif en TND")}
                                  value={x.objectif}
                                  onChange={(e) => handleInputChange(e, i)}

                                />
                              </td>
                              
                                  <td>  
                               <input
                                style={{ textAlign: "center" }}
                                className="form-control"  
                                value={calculatePercentage(x.realisation3, x.objectif)}
                                 onChange={(e) => handleInputChange(e, i)}
                                disabled
                              />
                               </td>
                                <td>
                                  <input
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    name="realisation3"
                                    value={convertToDigit(x.realisation3)}
                                    disabled
                                  />
                                </td> 
                              {/* <td style={{ textAlign: "center" }}>
                                {factures[i]?.montant_total_ht && (
                                  <>
                                    {calculateRealisationVSObjectif(factures[i]?.montant_total_ht, x.objectif) > 100 ? (
                                      <i style={{ color: "green" }} className="feather feather-arrow-up">
                                        {calculateRealisationVSObjectif(factures[i]?.montant_total_ht, x.objectif)}
                                      </i>
                                    ) : (
                                      <i style={{ color: "red" }} className="feather feather-arrow-down">
                                        {calculateRealisationVSObjectif(factures[i]?.montant_total_ht, x.objectif)}
                                      </i>
                                    )}
                                  </>
                                )}
                              </td> */}
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  value={calculatePercentage((x.realisation3 - x.realisation1), x.realisation1)}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  style={{ textAlign: "right" }}
                                  className="form-control"
                                  value={calculatePercentage((x.realisation3 - x.realisation2), x.realisation2)}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </td>
                              
                              {/* <td style={{ textAlign: "center" }}>
                                {factures[i]?.montant_total_ht && (
                                  <>
                                    {calculateRealisationCroissance(factures[i]?.montant_total_ht, x.realisation1) >
                                    0 ? (
                                      <i style={{ color: "green" }} className="feather feather-arrow-up">
                                        {calculateRealisationCroissance(factures[i]?.montant_total_ht, x.realisation1)}
                                      </i>
                                    ) : (
                                      <i style={{ color: "red" }} className="feather feather-arrow-down">
                                        {calculateRealisationCroissance(factures[i]?.montant_total_ht, x.realisation1)}
                                      </i>
                                    )}
                                  </>
                                )}
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                      <thead>
                        <tr>
                          {/* total */}
                          <th>{t("Total")}</th>
                          {/* realisation 2019 */}
                          <th>
                            <span className="badge badge-pill badge-warning">
                              {convertToDigit(sumObjectif(inputList, "realisation2"))} {t("TND")}
                            </span>
                          </th>
                          {/* realisation 2020 */}
                          <th>
                            <span className="badge badge-pill badge-warning">
                              {convertToDigit(sumObjectif(inputList, "realisation1"))} {t("TND")}
                            </span>
                          </th>
                          <th>
                            <span className="badge badge-pill badge-warning">
                            {sumObjectif(inputList, "objectif")} {t("TND")}
                            </span>
                            
                          </th>
                          {/* objectif 2021 */}
                          <th>
                            <span className="badge badge-pill badge-warning"> 
                            {/* {convertToDigit(totalObjectif)} {t("TND")} */}
                            {sumObjectif(inputList, "objectif")} {t("TND")}
                            </span>
                          </th>
                          {/* croissance */}
                          <th style={{ textAlign: "center" }}>
                          <span className="badge badge-pill badge-warning">
                          {convertToDigit(sumObjectif(inputList, "realisation3"))} {t("TND")}
                          </span>
                          </th>
                          {/* realisation 2021 */}
                          <th>
                            {/* <span className="badge badge-pill badge-warning">
                              {" "}
                              {convertToDigit(totalRealisation)} {t("TND")}
                              
                            </span> */}
                          </th>
                          {/* r vs 2021  */}
                          <th style={{ textAlign: "center" }}>
                            {/* {vsCurrentYear && (
                              <>
                                {vsCurrentYear > 100 ? (
                                  <i style={{ color: "green" }} className="feather feather-arrow-up">
                                    {vsCurrentYear}
                                  </i>
                                ) : (
                                  <i style={{ color: "red" }} className="feather feather-arrow-down">
                                    {vsCurrentYear}
                                  </i>
                                )}
                              </>
                            )} */}
                          </th>
                          {/* croissance vs 2020 */}
                          <th style={{ textAlign: "center" }}>
                            {/* {vsLastYear && (
                              <>
                                {vsLastYear > 0 ? (
                                  <i style={{ color: "green" }} className="feather feather-arrow-up">
                                    {vsLastYear}
                                  </i>
                                ) : (
                                  <i style={{ color: "red" }} className="feather feather-arrow-down">
                                    {vsLastYear}
                                  </i>
                                )}
                              </>
                            )} */}
                          </th>
                        </tr>
                      </thead>
                    </table>
                    
                  )}
                </div>

                {/* /.card-body */}
                <div className="card-footer clearfix">
                  <button type="submit" className="btn btn-primary float-right" onClick={handleSubmitObjectif}>
                    {t("Enregistrer")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* /.card */}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          {/* <ObjectifChart1 objectifs={inputList} factures={factures}></ObjectifChart1>
          <ObjectifChart2 objectifs={inputList} factures={factures}></ObjectifChart2> */}

          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Objectif par mois")}</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <ObjectifChart1 objectifs={inputList} factures={factures}></ObjectifChart1>
              </div>
              <div className="sales-chart mt-4 row text-center">
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-success mr-2 my-auto" />
                  {t("Realisation")} {new Date().getFullYear() - 1}
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  {t("Realisation")} {new Date().getFullYear()}
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-warning  mr-2 my-auto" />
                  {t("Objectif")} {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Objectif par mois")}</h4>
            </div>
            <div className="card-body">
              <div className="mt-5">
                <ObjectifChart2 objectifs={inputList} factures={factures}></ObjectifChart2>
              </div>
              <div className="sales-chart mt-4 row text-center">
                {/* <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-success mr-2 my-auto" />
                  Realisation 2020
                </div> */}
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-success mr-2 my-auto" />
                  {t("Realisation")} {new Date().getFullYear()}
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-warning  mr-2 my-auto" />
                  {t("Objectif")} {new Date().getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Objectif;
