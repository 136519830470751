import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { getProjetById, upadateProjetById } from "../../Services/Pointeuse/ProjetApi";
import { getProjetMemberByProjetId } from "../../Services/Pointeuse/ProjetMemberApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";

function ViewProject({ projetId }) {
  const [object, setobject] = useState();
  const [description, setdescription] = useState(null);
  const [etat, setetat] = useState(null);
  const [commentaire, setcommentaire] = useState(null);
  const [dateDebut, setdateDebut] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [dateFin, setdateFin] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [userId, setuserId] = useState(null);
  const [users, setusers] = useState(null);
  const [data, setdata] = useState(null);
  const [priority, setpriority] = useState("0");
  const [budget, setbudget] = useState(null);
  const [members, setmembers] = useState(null);

  useEffect(() => {
    getProjetById(projetId).then((res) => {
      console.log(res.data);
      setbudget(res.data.budget);
      setuserId(res.data.responsable_id);
      setetat(res.data.etat);
      setcommentaire(res.data.commentaire);
      setdateDebut(res.data.date_debut);
      setdateFin(res.data.date_fin);
      setdescription(res.data.description);
      setobject(res.data.nom);
      setdata(res.data);
      setpriority(res.data.priority);
    });
  }, []);

  const handelUpdate = () => {
    upadateProjetById(object, description, etat, commentaire, dateDebut, dateFin, userId, priority, budget, projetId).then((res) => {
      console.log(res);
    });
  };

  useEffect(() => {
    getProjetMemberByProjetId(projetId).then((res) => {
      console.log(res);
      setmembers(res.data);
    });
  }, [projetId]);

  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);

  return (
    <div className="card-body">
      {data && (
        <div className="main-profile-bio mb-0">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-label">Titre</label>
                <input className="form-control" placeholder="Text" value={object} onChange={(e) => setobject(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Budget</label>
                <input className="form-control" placeholder="Text" value={budget} onChange={(e) => setbudget(e.target.value)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    Responsable<span className="text-danger">*</span>
                  </label>

                  <select onChange={(e) => setuserId(e.target.value)} className="selectpicker form-control" value={userId}>
                    <option value="0">Choisir un collaborateur</option>
                    {users &&
                      users.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom} {el.prenom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Priorité:</label>
                <select
                  name="attendance"
                  className="form-control custom-select select2"
                  data-placeholder="Choisir priorité"
                  onChange={(e) => setpriority(e.target.value)}
                  value={priority}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Choisir Status:</label>
                <select
                  name="attendance"
                  className="form-control custom-select select2"
                  data-placeholder="Choisir Status"
                  onChange={(e) => setetat(e.target.value)}
                  value={etat}
                >
                  <option label="Choisir Status" />
                  <option value="afaire">à faire</option>
                  <option value="encours">en cours</option>
                  <option value="terminer">terminé</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Date début:</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="feather feather-calendar" />
                    </div>
                  </div>
                  <input
                    className="form-control fc-datepicker"
                    placeholder="DD-MM-YYY"
                    type="date"
                    onChange={(e) => setdateDebut(e.target.value)}
                    value={dateDebut}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Date fin:</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="feather feather-calendar" />
                    </div>
                  </div>
                  <input
                    className="form-control fc-datepicker"
                    placeholder="DD-MM-YYY"
                    type="date"
                    onChange={(e) => setdateFin(e.target.value)}
                    value={dateFin}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Description</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              value={description}
              onChange={(e) => setdescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Commentaire</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              value={commentaire}
              onChange={(e) => setcommentaire(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="text-right">
            <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handelUpdate}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProject;
