import React from "react";

function LoginLoyout(props) {
  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  ) 
}

export default LoginLoyout;
