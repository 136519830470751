import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { getClientById, getClientsByCodeS, getFacturationClientByCode, getFacturationFournisseurByCode } from "../../../../../Services/Facturation/FacturationClientApi";
import { getAllProduct, getAllProductNumseries, getAllProductVente } from "../../../../../Services/Facturation/FacturationProduitApi";
import { getEntrepriseByCode } from "../../../../../Services/Pointeuse/EntrepriseApi";
import { calculateHt, calculateRemise, MultiplicateNumber, calulateTva } from "../../../../../Utils/NumberUtils";
import { getGoupedValue } from "../../../../../Utils/ObjectUtils";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "../../../invoiceReports/MyDocument";
import { AjoutEntete_ligne, GetEntete_ligne, UpdateEntete_ligne, addEntete, addEnteteOther, getEnteteCommercialVenteBl } from "../../../../../Services/Facturation/FacturationEnteteApi";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addLigne, addLigneAther, checkNumSerieInAbonnement } from "../../../../../Services/Facturation/FacturationLigneApi";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";
import { Link, useHistory } from "react-router-dom";
import { checkEmptyPriceQuantity, checkEmptyPrice_Quantity } from "../../../../../Utils/ObjectOperation";
import { getEnteteMediaFacture } from "../../../../../Services/Facturation/FacturationEnteteMediaApi";
import axios from "axios";
import { getToken, getSession } from "../../../../../Utils/SessionUtils";
import { t } from "i18next";
import { getAllSeriesByProductName, updateBLOfSerieById } from "../../../../../Services/Facturation/FacturationSerieApi";
import "./BL.css"
import { getAllDevise } from "../../../../../Services/devise/devise";
import Tabletva from "../../../TableTVA/Tabletva";
import ReactSelect from "react-select";
import { generateUniqueCode } from "../../../../../Utils/helper";
import { BLToFactureVente, devisToCommandeVente } from "../../../../../Services/Facturation/FacturatuinConvertApi";
import { getLastBLVenteID, getLastDevisBLVenteID } from "../../../../../Services/sequances/sequances";
import { getAllTva } from "../../../../../Services/Facturation/FacturationTvaApi";
import Facture from "../../../invoiceReports/Facture";

function AjoutBlVente(props) {
  const history = useHistory();
  const image_url = process.env.REACT_APP_API_URL;

  const api_url = process.env.REACT_APP_API_BASE_URL_V1;
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  //show facture
  const [showResults, setShowResults] = React.useState(false);
  const [series, setSeries] = useState([]);
  const [, forceUpdate] = React.useState();

  const [listTva, setlistTva] = useState([]);
  const [fournisseur, setDataFournisseur] = useState()
  const [societe, setSociete] = useState()
  const [devises, setlistdevise] = useState([])
  const [deviseclient, setdeviseclient] = useState()
  const [ProductList, setProducts] = useState([])
  const [idtoremove, setlisttoremove] = useState([])
  const [listTva_societe, setlistTvaSociete] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [client, setclient] = useState()
  const [datadevis, setdatadevis] = useState({
    type: 'bl',
    document: 'vente',
    code_commande: '',
    code_fournisseur: '',
    facture_code: '',
    societe_code: localStorage.getItem('code_generated'),
    user_code: localStorage.getItem('code'),
    commercial_code: localStorage.getItem('code'),
    client_code: '',
    client_nom: '',
    date: moment(new Date()).format("YYYY-MM-DD"),
    timbre: 0,
    montant_total_ht: 0,
    montant_total_ttc: 0,
    montant_total_tva: 0,
    retenue_source: 0,
    net_a_payer: 0,
    net_a_payer_ht: 0,
    remise_total: 0,
    adresse: '',
    remise_pied: 0,
    remise_action: '1',
    date_echeance: null,
    commentaire: '',
    retenue_source: '',
    code_piece_pre: '',

  })
  // dynamic list product
  const [inputList, setInputList] = useState([
    {
      id: '',
      produit_code: "",
      quantite: 0,
      pu_ht: 0,
      total_brut_ht: 0,
      total_ht: 0,
      total_net_ht: 0,
      montant_tva: 0,
      pu_ttc: 0,
      taux_tva: 0,
      prix_achat_ht: 0,
      prix_achat_ttc: 0,
      taux_tva: 0,
      num_serie: [],
      remise: 0,
      remise_client: 0,
      sended_email: '',
      libelleProduit: '',
      actionRemise: '1',
      suivieStock: '',
      code_abonnement: '',
      exonere: ''
    },
  ]);

  useEffect(() => {
    getAllTva().then((res) => {
      if (res.data) {
        setlistTvaSociete(res.data)
      }
    });

  }, []);
  useEffect(() => {
    let societedata
    getEntrepriseByCode().then((res) => {
      societedata = res.data
      setSociete(res.data)

      setdatadevis(prev => ({
        ...prev,
        timbre: res.data.timbre_active === 1 ? res.data.timbre_fiscale : 0,

      }));
    });
    getAllDevise().then((res) => {
      if (res.data) {

        setlistdevise(res.data.data)

        const deviseid = societedata?.devise || 0;
        setdeviseclient(res.data.data.find(item => item.id === deviseid))


      }
    });
  }, []);

  useEffect(() => {
    let listtva = []
    inputList.forEach((element, index) => {
      const data_tva = {
        index: index,
        tva: element.taux_tva,
        prix: element.total_net_ht,

      };
      listtva.push(data_tva)
    });
    setlistTva(listtva)

  }, [inputList]);

  useEffect(() => {

    const new_total_brut = inputList.reduce((ac, val) => ac + parseFloat(val.total_brut_ht), 0);
    const new_net_ht = inputList.reduce((ac, val) => ac + parseFloat(val.total_net_ht), 0);
    let new_montant_total_tva = 0
    inputList.forEach(element => {
      //const remise = calculateRemise(element.total_net_ht, entetfacture.remise_pied, entetfacture.remise_action);
      const montattva = element.total_net_ht * (element.taux_tva / 100)
      new_montant_total_tva = new_montant_total_tva + montattva
    });
    const montant_ttc = new_net_ht + new_montant_total_tva;
    const net_a_payer = montant_ttc;
    setdatadevis(prev => ({
      ...prev,
      montant_total_ht: parseFloat(new_total_brut).toFixed(deviseclient?.devision || 3),
      net_a_payer_ht: parseFloat(new_net_ht).toFixed(deviseclient?.devision || 3),
      remise_total: (parseFloat(new_total_brut) - parseFloat(new_net_ht)).toFixed(deviseclient?.devision || 3),

      montant_total_ttc: parseFloat(montant_ttc).toFixed(deviseclient?.devision || 3),
      net_a_payer: parseFloat(net_a_payer).toFixed(deviseclient?.devision || 3),
      montant_total_tva: parseFloat(new_montant_total_tva).toFixed(deviseclient?.devision || 3)

    }));
  }, [inputList]);

  // fetch data
  useEffect(() => {
    // TODO : change to getFacturationFournisseurByCode
    getClientsByCodeS().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
        remise_client: d.remise_client,
        exonere: d.exonere,
        rs_id: d.rs_id,
        devise_id: d.devise_id,
        adresse_facturation: d.adresse_facturation,
        matricule_fiscale: d.matricule_fiscale,
        email: d.email,
        image: d.image
      }));

      setDataFournisseur(options);
    });

    // TODO : change to prix_achat_ht
    getAllProductVente().then((res) => {
      const result = res.data;
      const newlist = result.map(element => {
        if (element.listnumseries && element.listnumseries.length !== 0) {
          element.options = element.listnumseries.map((d, index) => ({
            value: d.numero_serie,
            label: d.numero_serie,
          }));
        }
        return element;
      });

      setProducts(newlist);
    });


  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      GetEntete_ligne(props.match.params.id).then((res) => {

        if (res?.data) {
          const entetdata = res.data.entet;
          console.log(res.data.entet)
          const lignesdata = res.data.lignes;
          const entet_data = {

            code: entetdata.code,
            type: 'bl',
            document: 'vente',
            facture_code: entetdata.facture_code,
            code_fournisseur: entetdata.code_fournisseur,
            converted: entetdata.converted,
            code_commande: entetdata.code_commande,
            societe_code: localStorage.getItem('code_generated'),
            user_code: localStorage.getItem('code'),
            commercial_code: localStorage.getItem('code'),
            client_code: entetdata.client_code,
            client_nom: entetdata.client_nom,
            date: entetdata.date.split('T')[0],
            timbre: entetdata.timbre,
            montant_total_ht: entetdata.montant_total_ht,
            montant_total_ttc: entetdata.montant_total_ttc,
            montant_total_tva: entetdata.montant_total_tva,
            retenue_source: entetdata.retenue_source,
            net_a_payer: entetdata.net_a_payer,
            net_a_payer_ht: entetdata.net_a_payer_ht,
            remise_total: entetdata.remise_total,
            adresse: entetdata.adresse,
            remise_pied: entetdata.remise_pied,
            remise_action: entetdata.remise_action,
            date_echeance: entetdata.date_echeance !== null ? entetdata.date_echeance.split('T')[0] : null,
            commentaire: entetdata.commentaire,
            retenue_source: entetdata.retenue_source,
            code_piece_pre: entetdata.code_piece_pre,
            type_piece_pre: entetdata.type_piece_pre,
            converted_to: entetdata.converted_to,
            type_converted_to: entetdata.type_converted_to,
            exonere: fournisseur?.find(c => c.value === entetdata.client_code).exonere || '',
            client_mf: entetdata?.client_mf,
            client_mobile: entetdata?.client_mobile,
            client_adresse_facturation: entetdata?.client_adresse_facturation,
            client_email: entetdata?.client_email,

          }

          const findclient = fournisseur?.find(item => item.code === entetdata.client_code)
          if (findclient && findclient.devise_id) {
            setdeviseclient(devises.find(item => item.id === findclient.devise_id))
          }



          setclient(findclient)
          setdatadevis(entet_data)


          let listlignes = []
          const valuelist = [...inputValue]

          lignesdata.forEach((item, index) => {
            const itemdata = {
              id: item.id,
              produit_code: item.produit_code,
              quantite: item.quantite,
              pu_ht: item.pu_ht,
              total_ht_remise:item.total_ht,

              total_brut_ht: item.total_brut_ht,
              total_ht: item.total_ht,
              total_net_ht: item.total_net_ht,
              montant_tva: item.montant_tva,
              pu_ttc: item.pu_ttc,
              taux_tva: item.taux_tva,
              prix_achat_ht: item.prix_achat_ht,
              prix_achat_ttc: item.prix_achat_ttc,
              taux_tva: item.taux_tva,
              num_serie: item.num_serie !== null ? item.num_serie.split(',') : [],
              remise: item.remise,
              remise_client: entetdata.remise_pied,
              sended_email: item.sended_email,
              libelleProduit: ProductList?.find(p => p.code === item.produit_code)?.libelle || '',
              actionRemise: item.actionRemise,
              suivieStock: item.suivieStock,
              code_abonnement: item.code_abonnement,
              exonere: fournisseur?.find(c => c.value === entetdata.client_code).exonere || '',
              has_num_serie: ProductList?.find(p => p.code === item.produit_code)?.num_serie || '',

            }
            valuelist[index] = item.num_serie !== null ? item.num_serie.split(',') : [];

            listlignes.push(itemdata)
          });
          setInputList(listlignes)
          setInputValue(valuelist)
        }
      })
    }
    else {
      getLastBLVenteID().then((res) => {
        if (res?.data?.data) {
          if (res.data.data.last_code) {

            const lastsouche = res.data.data.last_code.split(res.data.data.seq).pop();
            const num = parseInt(lastsouche) + 1;
            if (isNaN(num)) {
              setdatadevis(prev => ({
                ...prev,
                facture_code: `${res.data.data.seq}1`

              }));
            } else {
              setdatadevis(prev => ({
                ...prev,
                facture_code: `${res.data.data.seq}${parseInt(lastsouche) + 1}`,

              }));
            }

          }
          else {
            setdatadevis(prev => ({
              ...prev,
              facture_code: `${res.data.data.seq}1`

            }));
          }
        }
      })

    }

  }, [ProductList, fournisseur]);

  const handlechangeentet = (filed, value) => {

    if (filed === 'client_code') {
      setdatadevis(prev => ({
        ...prev,
        client_code: value.value,
        remise_pied: value.remise_client,
        client_nom: value.label,
        client_mf: value.matricule_fiscale,
        client_mobile: value.mobile,
        client_adresse_facturation: value.adresse_facturation,
        client_email: value.email,
        exonere: value.exonere,
        retenue_source: value.rs_id,
      }));
      const deviseid = value.devise_id || (societe?.devise || 2);
      setclient(value);

      setdeviseclient(devises.find(item => item.id === deviseid))
      const updatedInputList = inputList.map(item => {
        const updatedItem = { ...item };
        updatedItem.remise_client = value.remise_client;
        let remiseclient = calculateRemise(
          updatedItem.total_ht_remise,
          value.remise_client,
          datadevis.remise_action
        );
        updatedItem.total_net_ht = parseFloat(remiseclient);
        updatedItem.sended_email = value.sended_email;
        return updatedItem;
      });

      // Mettez à jour l'état avec le nouveau tableau
      setInputList(updatedInputList);

    } else {
      setdatadevis(prev => ({
        ...prev,
        [filed]: value
      }));
      if (filed === 'remise_action') {
        const updatedInputList = inputList.map(item => {
          const updatedItem = { ...item };
          let remiseclient = calculateRemise(
            updatedItem.total_ht_remise,
            updatedItem.remise_client,
            value
          );

          updatedItem.total_net_ht = parseFloat(remiseclient);
          return updatedItem;
        });
        setInputList(updatedInputList);

      } else if (filed === 'remise_pied') {
        const updatedInputList = inputList.map(item => {
          const updatedItem = { ...item };
          updatedItem.remise_client = value !== '' ? value : 0;

          let remiseclient = calculateRemise(
            updatedItem.total_ht_remise,
            parseFloat(updatedItem.remise_client),
            datadevis.remise_action
          );

          updatedItem.total_net_ht = parseFloat(remiseclient);
          return updatedItem;
        });
        setInputList(updatedInputList);

      }

    }

  }
  const handlenumseriechange = (e, index, index_num) => {
    //const value = inputValue[index][index_num]
    const value = e.label

    const list = [...inputList];
    let test = 1;


    if (list[index]["num_serie"].includes(value)) {
      toast.warning("Ce numéro de série existe déjà dans l'entrée actuelle !");
      test = 0;
    } else {
      const listprodnumserie = ProductList.find(item => item.code === list[index]["produit_code"]);

      if (listprodnumserie) {
        const checkvalueinlist = listprodnumserie.listnumseries.find(item => item.numero_serie === value);
        if (checkvalueinlist) {
          if (checkvalueinlist.document_sortie !== null) {
            toast.warning("Ce numéro de série a déjà été vendu !");
            test = 0;
          }
        } else {
          toast.warning("Ce numéro de série ne correspond à aucun numéro de série de ce produit !");
          test = 0;
        }
      }
    }
    if (test === 1) {
      // alert(test)

      // Remplacer la valeur à l'index donné
      list[index]["num_serie"][index_num] = value;

      // Filtrer les valeurs vides de la liste
      list[index]["num_serie"] = list[index]["num_serie"].filter(val => val !== undefined && val !== null && val !== "");
      // console.log(list[index]["num_serie"])
      setInputList(list);
    }

  }

  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...inputList];
    if ((value < list[index]["quantite"]) && (list[index]["quantite"] === list[index]["num_serie"].length)) {
      //console.log(list[index]["num_serie"])

      list[index]["num_serie"].pop()
      // console.log(list[index]["num_serie"])
    }
    if (name === 'product') {
      let position = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[position].text;

      list[index]["produit_code"] = ProductList[position - 1].code;
      list[index]["libelleProduit"] = ProductList[position - 1].libelle;
      list[index]["pu_ht"] = ProductList[position - 1].prix_vente_ht_1;
      list[index]["taux_tva"] = datadevis.exonere !== 1 ? ProductList[position - 1].tva : 0;
      list[index]["suivieStock"] = ProductList[position - 1].suivie_stock;
      list[index]["prix_achat_ht"] = ProductList[position - 1].prix_achat_ht;
      list[index]["prix_achat_ttc"] = ProductList[position - 1].prix_achat_ttc;
      list[index]["taux_tva"] = datadevis.exonere !== 1 ? ProductList[position - 1].tva : 0;
      list[index]["has_num_serie"] = ProductList[position - 1].num_serie;
      list[index]['total_ht'] = ProductList[position - 1].prix_vente_ht_1 * list[index]["quantite"];
      list[index]['total_ht_remise'] = ProductList[position - 1].prix_vente_ht_1 * list[index]["quantite"];

    }
    if (name === 'libelleProduit') {
      list[index]["libelleProduit"] = value;

    }
    else {
      if (name === "actionRemise") {
        list[index][name] = value;

      } else if (name === 'remise') {
        list[index][name] = value !== '' ? parseFloat(value) : 0;

      } else if (name === 'quantite') {
        list[index][name] = parseFloat(value);
        list[index]['total_ht_remise'] = parseFloat(value) * list[index]["pu_ht"];

        list[index]['total_ht'] = parseFloat(value) * list[index]["pu_ht"];

      } else if(name==='pu_ht'){
        list[index][name] = value;
     
         list[index]['total_ht'] = parseFloat(value)* list[index]["quantite"] ;
  
     }else {
        list[index][name] = parseFloat(value);

      }
      // Mettre à jour les champs dans la liste


    }
    let sum = calculateRemise(
      list[index]['total_ht'],
      list[index]["remise"],
      list[index]["actionRemise"]
    );
    list[index]['total'] = MultiplicateNumber(list[index]["pu_ttc"], list[index]["quantite"]);
    list[index]['total_ht_remise'] = parseFloat(sum).toFixed(deviseclient?.devision || 3);
    list[index]['total_brut_ht'] = MultiplicateNumber(list[index]["pu_ht"], list[index]["quantite"]);
    // list[index]['total_ht'] = parseFloat(sum.toFixed(deviseclient?.devision || 3))
    let remiseclient = calculateRemise(
      sum,
      datadevis.remise_pied,
      datadevis.remise_action
    );
    list[index]['total_net_ht'] = parseFloat(remiseclient).toFixed(deviseclient?.devision || 3);


    // list[index]['total_net_ht'] = parseFloat((sum * ((100 - list[index]["remise_client"]) / 100)).toFixed(deviseclient?.devision || 3))
    setInputList(list);

  }



  // handle add new product
  // create new object
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        id: '',
        produit_code: "",
        quantite: 0,
        pu_ht: 0,
        total_brut_ht: 0,
        total_ht: 0,
        total_net_ht: 0,
        montant_tva: 0,
        pu_ttc: 0,
        taux_tva: 0,
        prix_achat_ht: 0,
        prix_achat_ttc: 0,
        taux_tva: 0,
        num_serie: [],
        remise: 0,
        remise_client: 0,
        sended_email: '',
        libelleProduit: '',
        actionRemise: '1',
        suivieStock: '',
        code_abonnement: '',
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const item = inputList[index]
    if (item.id !== '') {
      setlisttoremove(prev => [...prev, item.id])
    }
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

  };
  const handleClick = () => {
    if (props.match.params.id) {
      handleupdateEntete()
    } else {
      handleSaveEntete()
    }


  }
  const handleSaveEntete = () => {

    if (checkEmptyPrice_Quantity(inputList)) {
      toast.error(t("Veuillez ajouter quantité/prix"));
    } else if (datadevis.client_code === '' || datadevis.date === '') {
      toast.error(t("Veuillez entree tous les champs requis de l'entête"));

    } else {
      const newentet = { ...datadevis }

      delete newentet.exonere;
      newentet.code = generateUniqueCode()

      const updatedInputList = inputList.map(item => {
        const updatedItem = { ...item };

        updatedItem.entetecommercial_code = newentet.code;
        updatedItem.num_serie = updatedItem.num_serie.join(',')

        delete updatedItem.exonere;
        delete updatedItem.has_num_serie;
        delete updatedItem.total_ht_remise;
        delete updatedItem.product;

        delete updatedItem.id;
        updatedItem.societe_code = localStorage.getItem('code_generated');
        return updatedItem;
      });
      const data = { entet: newentet, lignes: updatedInputList }
      //console.log(updatedInputList)

      AjoutEntete_ligne(data).then((res) => {
        if (res.status === 201) {
          //history.push('/admin/blvente')
        }
      }
      )
    }

  }


  const handleupdateEntete = () => {
    if (checkEmptyPrice_Quantity(inputList)) {
      toast.error(t("Veuillez ajouter quantité/prix"));
    } else if (datadevis.client_code === '' || datadevis.date === '') {
      toast.error(t("Veuillez entree tous les champs requis de l'entête"));

    } else {
      const newentet = { ...datadevis }

      delete newentet.exonere;

      const updatedInputList = inputList.map(item => {
        const updatedItem = { ...item };

        updatedItem.entetecommercial_code = newentet.code;
        delete updatedItem.libelleProduit;
        delete updatedItem.has_num_serie;
        delete updatedItem.total_ht_remise;
        delete updatedItem.product;

        updatedItem.num_serie = updatedItem.num_serie.join(',')
        delete updatedItem.exonere;
        updatedItem.societe_code = localStorage.getItem('code_generated');
        return updatedItem;
      });
      const data = { entet: newentet, lignes: updatedInputList, remove: idtoremove }
      UpdateEntete_ligne(data).then((res) => {
        if (res.status === 200) {
         // history.push('/admin/blvente')
        }
      }
      )
    }

  }


  const handelconvert = () => {
    if (checkEmptyPrice_Quantity(inputList)) {
      toast.error(t("Veuillez ajouter quantité/prix"));
    } else if (datadevis.client_code === '' || datadevis.date === '') {
      toast.error(t("Veuillez entree tous les champs requis de l'entête"));

    } else {
      if (props.match.params.id) {
        const newentet = { ...datadevis }

        delete newentet.exonere;

        const updatedInputList = inputList.map(item => {
          const updatedItem = { ...item };

          updatedItem.entetecommercial_code = newentet.code;
          updatedItem.num_serie = updatedItem.num_serie !== null ? updatedItem.num_serie.join(',') : null;
          delete updatedItem.has_num_serie
          delete updatedItem.total_ht_remise;
          delete updatedItem.product;

          delete updatedItem.exonere;
          updatedItem.societe_code = localStorage.getItem('code_generated');
          return updatedItem;
        });
        const data = { entet: newentet, lignes: updatedInputList, remove: idtoremove }
        UpdateEntete_ligne(data).then((res) => {
          if (res.status === 200) {
            data.lignes.forEach(element => {
              delete element.id
            });
            BLToFactureVente(data).then((res) => {
              if (res.status === 200) {
                toast.success('piece est converti en facture')

                const code = res.data.data.newcode;

                history.push('/admin/editerfacturevente/' + code)


              }
            })
          }
        }
        )
      }
      else {


        const newentet = { ...datadevis }

        delete newentet.exonere;
        newentet.code = generateUniqueCode()

        const updatedInputList = inputList.map(item => {
          const updatedItem = { ...item };

          updatedItem.entetecommercial_code = newentet.code;

          delete updatedItem.exonere;
          delete updatedItem.has_num_serie
          delete updatedItem.total_ht_remise;
          delete updatedItem.product;

          delete updatedItem.id
          updatedItem.num_serie = updatedItem.num_serie.join(',')


          updatedItem.societe_code = localStorage.getItem('code_generated');
          return updatedItem;
        });
        const data = { entet: newentet, lignes: updatedInputList }
        AjoutEntete_ligne(data).then((res) => {
          if (res.status === 201) {
            BLToFactureVente(data).then((res) => {
              if (res.status === 200) {
                toast.success('piece est converti en facture')
                const code = res.data.data.newcode;

                history.push('/admin/editerfacturevente/' + code)


              }
            })
            //devistobl
          }
        }
        )
      }
    }

  }

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("BL vente")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addfournisseur" className="btn btn-primary mr-3">
                {t("Ajouter fournisseur")}
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                {t("Ajouter produit")}
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header  border-0">
            <h4 className="card-title">Num° : {datadevis.facture_code}</h4>
          </div>
          <div className="card-body">
            <div className="content m-4">
              <div className="row ">
                <div className="col-md-12">
                  <div className="row ml-5 mb-5">
                    {/*  <div className="col-sm-2">
                      <div className="form-group">
                        <label>
                          {t("code  ")}
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="text"
                            value={datadevis.code_fournisseur}
                            onChange={(e) => handlechangeentet('code_fournisseur', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>*/}
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label>
                          {t("Numéro de commande")}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={datadevis.code_commande}
                          onChange={(e) => handlechangeentet('code_commande', e.target.value)}
                        />
                      </div>
                    </div>
                    {datadevis.type_piece_pre === 'devis' &&
                      <div className="col-sm-2">
                        <div className="form-group">
                          <label>
                            {t("Numéro de devis")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={datadevis.code_piece_pre}
                            onChange={(e) => handlechangeentet('code_piece_pre', e.target.value)}
                          />
                        </div>
                      </div>}
                    <div className="col-sm-2">

                      <div className="form-group">
                        <label>
                          {t("Client")} : {datadevis.client_nom}
                          <span className="text-danger">*</span>
                        </label>
                        <Select onChange={(e) => handlechangeentet('client_code', e)} options={fournisseur} value={datadevis.client_code} />
                      </div>
                    </div>

                    {devises.length !== 0 && deviseclient &&
                      <div className="col-sm-2">

                        <div className="form-group">
                          <label>
                            {t("devise")}
                            <span className="text-danger">*</span>
                          </label>
                          <ReactSelect
                            value={devises && devises.find(item => item.id === deviseclient.id)}
                            options={devises}
                            onChange={(e) => setdeviseclient(devises.find(item => item.id === e.id))}
                            formatOptionLabel={devise => (
                              <div className="country-option">
                                <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                                <span>{devise.libelle}</span>
                              </div>
                            )}
                          />  </div>
                      </div>}


                    <div className="col-sm-2">
                      <label>{t("Remise")}</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <select
                            value={datadevis.actionRemise}
                            className="form-control btn btn-outline-secondary"
                            onChange={(e) => handlechangeentet('remise_action', e.target.value)}
                          >
                            <option value="1">{t("Pourcent")}</option>
                            <option value="2">{t("Montant")}</option>
                          </select>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          value={datadevis.remise_pied}
                          onChange={(e) => handlechangeentet('remise_pied', e.target.value)}
                        />
                      </div>
                    </div>



                    <div className="col-sm-2">
                      <div className="form-group">
                        <label>
                          {t("Date ")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            value={datadevis.date}
                            onChange={(e) => handlechangeentet('date', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label>
                          {t("Date de livraison")}
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            value={datadevis.date_echeance}
                            onChange={(e) => handlechangeentet('date_echeance', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row" style={{ marginTop: 20 }}>
                 {JSON.stringify(inputList)}
               </div> */}
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-responsive" id="mycustomtable">
                        <table className="table table-hover table-white">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t("Choisir produit")}</th>
                              <th>{t("Libelle")}</th>
                              <th>{t("Quantité")}</th>

                              <th>{t("Prix HT")}</th>
                              <th>{t("Total HT")}</th>
                              <th>{t("Remise")}</th>

                              <th>{t("TVA")} %</th>
                              <th>{t('Net_HT')}</th>


                              <th> </th>
                            </tr>
                          </thead>
                          <tbody>


                            {inputList.map((x, i) => (
                              <tr key={i}>
                                <td>{i}</td>
                                <td style={{ minWidth: 200, zIndex: 99 }}>
                                  <select disabled={datadevis.client_code === ''} name='product' className="form-control" value={x.produit_code} onChange={(e) => handleInputChange(e, i)}>

                                    <option value={"default"}>{t("Choisir produit")}</option>
                                    {ProductList.map((option) => (
                                      <option key={option.id} value={option.code}>
                                        {option.libelle}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td style={{ minWidth: 200, width: 350 }}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="libelleProduit"
                                    value={x.libelleProduit}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                  {
                                    x.has_num_serie === "1" ? (
                                      <td style={{ minWidth: 200, zIndex: 1000 }}>
                                        {x.quantite > 0 ? (


                                          Array.from({ length: x.quantite }, (_, j) => (
                                            <>
                                              <div style={{ width: 170, position: "relative", height: "44px" }}>
                                                {/*<input
                                                    type="text"
                                                    name="num_serie"
                                                    onChange={(e) => {
                                                      const value = e.target.value
                                                      const updatedValue = [...inputValue];
                                                      if (!updatedValue[i]) {
                                                        updatedValue[i] = [];
                                                      }

                                                      updatedValue[i][j] = value;

                                                      setInputValue(updatedValue);
                                                    }}
                                                    onBlur={(e) => {
                                                      handlenumseriechange(e, i, j);
                                                    }}
                                                    value={inputValue[i] ? inputValue[i][j] : ''}

                                                  />*/}


                                                <ReactSelect

                                                  options={ProductList.find(item => item.code === x.produit_code).options}

                                                  value={ProductList.find(item => item.code === x.produit_code).options?.find(item => item.label === x.num_serie[j]) ? ProductList.find(item => item.code === x.produit_code).options?.find(item => item.value === x.num_serie[j]) : ''}


                                                  onChange={(e) => {
                                                    handlenumseriechange(e, i, j);

                                                  }}
                                                />
                                              </div>
                                            </>
                                          ))
                                        ) : null}
                                      </td>
                                    ) : <td></td>
                                  }
                                </td>
                                <td style={{ minWidth: 100, width: 100 }}>
                                  <input
                                    type="number"
                                    name="quantite"
                                    value={x.quantite}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>



                                <td style={{ minWidth: 150, width: 200 }}>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="pu_ht"
                                    value={x.pu_ht}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>
                                <td style={{ minWidth: 150, width: 200 }}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    name="total_ht"
                                    value={x.total_ht}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </td>
                                <td style={{ minWidth: 250, width: 200 }}>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <select
                                        defaultValue="1"
                                        className="form-control btn btn-outline-secondary"
                                        name="actionRemise"
                                        value={x.actionRemise}
                                        onChange={(e) => handleInputChange(e, i)}
                                      >
                                        <option value="1">{t("Pourcent")}</option>
                                        <option value="2">{t("Montant")}</option>
                                      </select>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control"
                                      aria-label="Text input with dropdown button"
                                      name="remise"
                                      value={x.remise}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                  </div>
                                </td>
                                <td style={{ minWidth: 100, width: 200 }}>
                                  <select className="form-control" value={x.taux_tva} name='taux_tva' onChange={(e) => handleInputChange(e, i)}>
                                    {listTva_societe.map(item => (
                                      <option value={item.taux}>
                                        {item.libelle}
                                      </option>))}
                                  </select>
                                </td>
                             
                               
                                <td>
                                    <input className="form-control" readOnly type="text" value={x.total_net_ht} style={{ minWidth: 150, width: 150 }} />

                                  </td>

                                <td>
                                  {inputList.length - 1 === i && (
                                    <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                                      <i className="fa fa-plus" />
                                    </Link>
                                  )}
                                  {inputList.length !== 1 && (
                                    <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                                      <i className="fa fa-minus" />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                      <div className="col-7">
                      <Tabletva data={listTva} />
                        </div>
                        <div className="col-5 " style={{ paddingLeft: '150px' }}>
                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Total brut")}</strong>

                            </div>
                            <div className="col-8">
                              <span>      {datadevis.montant_total_ht}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                          {parseInt(datadevis.remise_total) !== 0 && (
                            <div className="row">
                              <div className="col-4">
                                <strong>{t("Remise")}</strong>

                              </div>
                              <div className="col-8">
                              <span>      {datadevis.remise_total}{' '}{deviseclient?.code_devise}</span>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Net HT")}</strong>

                            </div>
                            <div className="col-8">
                              <span>      {datadevis.net_a_payer_ht}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <strong>{t("montant  TVA")}</strong>

                            </div>
                            <div className="col-8">
                              <span>     {datadevis.montant_total_tva}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Total  TTC")}</strong>

                            </div>
                            <div className="col-8">
                              <span>     {datadevis.montant_total_ttc}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Net a payer")}</strong>

                            </div>
                            <div className="col-8">
                              <span>     {datadevis.net_a_payer}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{t("Ajouter note")}</label>
                            <textarea
                              onChange={(e) => handlechangeentet('commentaire', e.target.value)}
                              className="form-control"
                              value={datadevis.commentaire}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row no-print mt-4">
                    <div className="col-12">
                      {/* <a
                   href="invoice-print.html"
                   rel="noopener"
                   target="_blank"
                   className="btn btn-default"
                 >
                   <i className="fas fa-print" /> Print
                 </a> */}
                      {datadevis.converted !== 1 ? (
                        <><button type="button" className="btn btn-primary float-right" onClick={handleClick}>
                          <i className="far fa-credit-card" /> {t("Enregistrer")}
                        </button><button
                          type="button"
                          className="btn btn-warning float-right"
                          style={{ marginRight: 5 }}
                          onClick={handelconvert}
                        >
                            <i className="fas fa-file-invoice" /> {t("Convertir en Facture")}
                          </button></>
                      ) : <strong className="btn btn-primary float-right"> {t('Cette pièce est devenue une facture')}</strong>}

                      <button type="button" className="btn btn-info float-right mr-1" onClick={() => setShowResults(true)}>
                        <i className="fa fa-file-pdf-o" /> {t("Afficher BL")}
                      </button>
                    </div>
                  </div>

                  {/* <button className="btn btn-primary" onClick={handleSaveEntete}>
               Enregister
             </button>
             <button className="btn btn-info">Afficher facture</button> */}

                  <div className="row mt-4">
                    <div className="col-12">
                      {showResults ? (
                        <>
                          <PDFViewer style={{ width: "100%", height: "800px" }}>
                            <Facture entetfacture={datadevis} societe={societe} inputList={inputList} devise={deviseclient} t={t} listTva={listTva} client={client} />
                          </PDFViewer>
                        </>
                      ) : null}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AjoutBlVente;
/*   {
                                  x.has_num_serie === "1" ? (
                                    <td style={{ minWidth: 200, zIndex: 1000 }}>
                                      {x.quantite === 0 ? (
                                        <input
                                          className="form-control"
                                          type="number"
                                          name="num_serie"
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      ) : (
                                        // Render ReactSearchAutocomplete if x.qte is not empty
                                        Array.from({ length: x.quantite }, (_, j) => (
                                          <>
                                            <div style={{ width: 170, zIndex: 1000, position: "relative", height: "44px" }}>
                                              <input
                                                type="text"
                                                name="num_serie"
                                                onChange={(e)=>{
                                                  const value =e.target.value
                                                  const updatedValue = [...inputValue];
                                                  if (!updatedValue[i]) {
                                                    updatedValue[i] = [];
                                                  }
                                                  
                                                  // Update the value at the specified indices
                                                  updatedValue[i][j] = value;
                                                
                                                  // Update the state with the new array
                                                  setInputValue(updatedValue);
                                                }}
                                                onBlur={(e) => {
                                                  handlenumseriechange(e, i, j);
                                                }}
                                                value={inputValue[i]? inputValue[i][j] : ''}
                                              />
                                            </div>
                                          </>
                                        ))
                                      )}
                                    </td>
                                  ) : <td></td>
                                }*/