import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the materiel from the database
 * @returns The response is an array of objects.
 */
export async function getAllMateriel() {
  try {
    const response = await axios.get(api_url + "materiel/code/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the materiel by id
 * @param id - The id of the materiel you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "1",
 *     "name": "Materiel 1",
 *     "description": "Materiel 1 description",
 *     "price": "100.00",
 *     "quantity": "10",
 *     "createdAt": "2020
 */
export async function getAllMaterielById(id) {
  try {
    const response = await axios.get(api_url + "materiel/" + id, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
