import React, { useEffect, useState } from "react";
import { addPointageByUserId, getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { filterPointageByMonth } from "../../Utils/GlobalTableUtils";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import ImageComponent from "../Styles/ImageComponent";

function AddPointageManquant({ userId, currentDate, isOpened, handleOpen, refreshData }) {
  const [settings, setsettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [device, setdevice] = useState(null);
  const [network, setnetwork] = useState("");
  const [users, setusers] = useState(null);
  const [show, setShow] = useState(isOpened);

  /**
   * *This function is used to close the modal.*
   */
  const handleClose = () => {
    setShow(false);
  };
  /**
   * It sets the state of the show variable to true.
   */
  const handleShow = () => setShow(true);

  /**
   * It gets all the settings from the database and stores them in the settings variable.
   */
  const getData = () => {
    setLoading(true);
    getAllSettings()
      .then((res) => {
        setsettings(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getUserById(userId)
      .then((res) => {
        setusers(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(userId);
    setLoading(true);
    getPointageByUserId(userId)
      .then((res) => {
        const filtredPointage = filterPointageByMonth(res.data, currentDate);
        const filterNonSortie = filtredPointage.filter((el) => {
          return el.pointage.split(",")[1] === undefined;
        });
        console.log(filterNonSortie);
        setdata(filterNonSortie);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [userId, currentDate]);

  const getUA = () => {
    let device = "Unknown";
    const ua = {
      "Generic Linux": /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      "Chrome OS": /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
  };

  useEffect(() => {
    setdevice(getUA());
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setlatitude(position.coords.latitude);
          setlongitude(position.coords.longitude);
        },
        (error) => {
          setlatitude(-1);
          setlongitude(-1);
        }
      );
    }
  }, []);

  const handlePointage = (date) => {
    //alert(userId + date);
    const toInsert = date.split(" ")[0] + " " + settings.endHoure;
    addPointageByUserId(userId, toInsert, latitude, longitude, device, network, toInsert).then((res) => {
      console.log(res);
      setShow(false);
      handleOpen();
      refreshData();
    });
  };

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && settings && data && users && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {users.nom} {users.prenom}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 mt-4">
              <div className="col-md-4">
                <div className="pt-5 text-center">
                  <h6 className="mb-1 fs-16 font-weight-semibold">{settings.startHoure}</h6>
                  <small className="text-muted fs-14">Pointage d'entrée</small>
                </div>
              </div>
              <div className="col-md-4">
                <div className="chart-circle chart-circle-md" data-value={100} data-thickness={6} data-color="#0dcd94">
                  <div className="chart-circle-value text-muted">
                    <ImageComponent atr={""} picture={users.img}></ImageComponent>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="pt-5 text-center">
                  <h6 className="mb-1 fs-16 font-weight-semibold"> {settings.endHoure}</h6>
                  <small className="text-muted fs-14">Pointage de sortie</small>
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <label className="form-label">IP Address</label>
              <input type="text" className="form-control" placeholder="225.192.145.1" />
            </div>
            <div className="form-group">
              <label className="form-label">Working Form</label>
              <select name="projects" className="form-control custom-select select2" data-placeholder="Select">
                <option label="Select" />
                <option value={1} selected>
                  Office
                </option>
                <option value={2}>Home</option>
                <option value={3}>Others</option>
              </select>
            </div> */}
            <table className="table text-nowrap mb-0 text-nowrap">
              <tbody>
                {data.map((el, idx) => (
                  <tr key={idx}>
                    <td className="d-flex">{el.pointage}</td>
                    <td className="text-right">
                      {/* <a className="text-success d-block fs-16" href="#">
                          Today
                        </a> */}
                      <Link to="#" className="btn btn-outline-primary mt-1" onClick={() => handlePointage(el.pointage)}>
                        <i className="fa fa-bolt mr-2" />
                        pointage sortie
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Link to="#" className="btn btn-outline-primary" data-dismiss="modal" onClick={handleClose}>
              Fermer
            </Link>
            {/* <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#editmodal1" data-dismiss="modal">
              Edit
            </Link> */}
          </Modal.Footer>
        </Modal>

        // <div className="modal fade" id="presentmodal1">
        //   <div className="modal-dialog" role="document">
        //     <div className="modal-content">
        //       <div className="modal-header">
        //         <h5 className="modal-title">Attendance Details</h5>
        //         <button className="close" data-dismiss="modal" aria-label="Close">
        //           <span aria-hidden="true">×</span>
        //         </button>
        //       </div>
        //       <div className="modal-body">
        //         <div className="row mb-5 mt-4">
        //           <div className="col-md-4">
        //             <div className="pt-5 text-center">
        //               <h6 className="mb-1 fs-16 font-weight-semibold">{settings.startHoure}</h6>
        //               <small className="text-muted fs-14">Pointage d'entrée</small>
        //             </div>
        //           </div>
        //           <div className="col-md-4">
        //             <div
        //               className="chart-circle chart-circle-md"
        //               data-value={100}
        //               data-thickness={6}
        //               data-color="#0dcd94"
        //             >
        //               <div className="chart-circle-value text-muted">{settings.workHoure} hrs</div>
        //             </div>
        //           </div>
        //           <div className="col-md-4">
        //             <div className="pt-5 text-center">
        //               <h6 className="mb-1 fs-16 font-weight-semibold"> {settings.endHoure}</h6>
        //               <small className="text-muted fs-14">Pointage de sortie</small>
        //             </div>
        //           </div>
        //         </div>
        //         <div className="form-group">
        //           <label className="form-label">IP Address</label>
        //           <input type="text" className="form-control" placeholder="225.192.145.1" />
        //         </div>
        //         <div className="form-group">
        //           <label className="form-label">Working Form</label>
        //           <select name="projects" className="form-control custom-select select2" data-placeholder="Select">
        //             <option label="Select" />
        //             <option value={1} selected>
        //               Office
        //             </option>
        //             <option value={2}>Home</option>
        //             <option value={3}>Others</option>
        //           </select>
        //         </div>
        //         <ul>
        //           {data.map((el, idx) => (
        //             <li key={idx}>{el.pointage}</li>
        //           ))}
        //         </ul>
        //       </div>
        //       <div className="modal-footer">
        //         <a href="#" className="btn btn-outline-primary" data-dismiss="modal">
        //           close
        //         </a>
        //         <a
        //           href="#"
        //           className="btn btn-primary"
        //           data-toggle="modal"
        //           data-target="#editmodal1"
        //           data-dismiss="modal"
        //         >
        //           Edit
        //         </a>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
}

export default AddPointageManquant;
