import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Projet by code
 */
export async function getProjetMemberByProjetId(id) {
  try {
    const response = await axios.get(api_url + "projetmember/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// /**
//  * get Projet by id
//  */
// export async function getProjetById(id) {
//   try {
//     const response = await axios.get(api_url + "projets/" + id, config);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }

export async function deleteProjetMembersByProjetId(projetid) {
  try {
    const response = await axios.delete(api_url + "projetmember/" + projetid, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addNewProjetMembers(user_id, projet_id) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_id: user_id,
    projet_id: projet_id,
  };
  try {
    const response = await axios.post(api_url + "projetmember/", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
