import React, { useEffect, useRef, useState } from "react";
//  import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { t } from "i18next";
import { getFacturationClientsByCode, getFacturationFournisseurByCode } from "../../../Services/Facturation/FacturationClientApi";
import { getAllProduct, getProduitNumSerie } from "../../../Services/Facturation/FacturationProduitApi";
import { addSerie, getAllSerie } from "../../../Services/Facturation/FacturationSerieApi";

function AddSerie(props) { 
   const myRefDelete = useRef(null);
  const [fournisseurs, setfournisseurs] = useState(null);
  const [client, setclient] = useState(null);
  const [fournisseurId, setfournisseurId] = useState(null);
  const [clientId, setclientId] = useState(null);
  // const [dossier, setdossier] = useState("");
  //  const [numeroserie2, setnumeroserie2] = useState("");
  const [produitId, setproduitId] = useState("");
  
  const [produits, setProduits] = useState([]);
  const [numero_serie, setnumero_serie] = useState("");
  const [dossier_importation, setdossier] = useState(""); 
   const [filtredProduit, setFiltredProduit] = useState([]);
  const [type, settype] = useState("facture");
  const [fournisseurNom, setFournisseurNom] = useState("");
  const [clientNom, setClientNom] = useState("");
  const [ProduitNom, setProduitNom] = useState("");


  const handleChangeInAddSerie = (e) => {
    // Utilisez props.numeroserie2 comme nécessaire
    console.log(props.numeroserie2);

    // Vous pouvez également appeler la fonction handleChange du composant parent
    props.handleChange(e);
  };


  useEffect(() => {
    getFacturationFournisseurByCode().then((res) => {
       setfournisseurs(res.data);
    });
  }, []);
  useEffect(() => {
    getFacturationClientsByCode().then((res) => {
        setclient(res.data);
    });
  }, []);
  
 const handleChangeFournisseur = (e) => {
  const selectedFournisseur = fournisseurs.find(f => String(f.id) === e.target.value);
   setfournisseurId(e.target.value);
  setFournisseurNom(selectedFournisseur ? `${selectedFournisseur.nom} ${selectedFournisseur.prenom}` : "");
};
const handleChangeClients = (e) => {
  const selectedClient = client.find(c => String(c.id) === e.target.value);
  setclientId(e.target.value);
  setClientNom(selectedClient ? `${selectedClient.nom} ${selectedClient.prenom}` : "");
};

   const handleChangeProduit = (e) => {
    const selectedProduit = produits.find(p => String(p.id) === e.target.value);
    setproduitId(e.target.value);
    // Si vos objets produits ont des propriétés "nom" et "prenom", vous pouvez les utiliser comme suit
    setProduitNom(selectedProduit ? `${selectedProduit.libelle}  ` : "");
  }; 
  const resetForm = () => {
    setfournisseurId(null);
    setclientId(null);
    setproduitId("");
    setnumero_serie("");
    setdossier("");
    settype("facture");
    setFournisseurNom("");
    setClientNom("");
    setProduitNom("");
  };
  useEffect(() => {
    getProduitNumSerie().then((res) => {
       setProduits(res.data);
      //const productAll = res.data.filter((produit) => produit.num_serie === "1");
       setFiltredProduit(res.data);
    });
  }, []);
   
  const handelSavee = async () => {
    // Parse the input values to integers
    const startNum = parseInt(numero_serie);
    const endNum = parseInt(props.numeroserie2);
  
    // Validate input values
    if (isNaN(startNum) || isNaN(endNum) || startNum > endNum) {
      // Handle invalid input
      console.error('Invalid input range');
      return;
    }
  
    // Generate an array of numbers in ascending order
    const range = Array.from({ length: endNum - startNum + 1 }, (_, index) => startNum + index);
     // Iterate through the range and add series
    for (const num of range) {
       try {
        const res = await addSerie(num.toString(), dossier_importation, fournisseurNom, ProduitNom, type);
        console.log(`Added series with numéro_serie: ${num}`, res);
        // You may want to handle success or log the result
      } catch (error) {
        console.error(`Error adding series with numéro_serie: ${num}`, error);
        // Handle error as needed
      }
    } 
    // Récupérer les données mises à jour
  const donnéesMisesÀJour = await getAllSerie();
  props.updateCurrentData(donnéesMisesÀJour.data);
    // Reset the input values
    resetForm();
    // setProduitNom("");
    // setFournisseurNom("");
    // setdossier("");
    // settype("");
    // setnumero_serie("");

     // Close the modal and update data
     myRefDelete.current.click();
     props.getData();
     // Clear the parent component state (optional)
    handleChangeInAddSerie({ target: { value: "" } });
  };
  
  
  return (
    <div
      className="modal fade"
      id="addCongeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="addCongeModalolidayModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {t("Ajouter Numéro de Série")}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
          <div className="form-row">
            {/* Fournisseur, Dossier, Produit en série horizontale */}
            <div className="form-group col-md-12 d-flex">
              <div className="mr-3" style={{ flex: 1 }}>
                <label>{t("Fournisseur")}<span className="text-danger">*</span></label>
                <select onChange={handleChangeFournisseur} className="selectpicker form-control">
                <option value="0" >{t("  Fournisseur")}</option>
                  {fournisseurs &&
                  fournisseurs.map((el, idx) => (
                  <option key={idx} value={el.id}>
                  {el.nom} {el.prenom}
                </option>
                  ))}
                </select>
              </div>
              <div className="mr-3" style={{ flex: 1 }}>
                <label>{t("Dossier")}<span className="text-danger">*</span></label>
                <input type="text" className="form-control" onChange={(e) => setdossier(e.target.value)} />
              </div>
              <div style={{ flex: 1 }}>
                <label>{t("Produit")}<span className="text-danger">*</span></label>
                <select onChange={handleChangeProduit} className="selectpicker form-control">
                <option value="0" >{t("liste Produit")}</option>
                {filtredProduit &&
                filtredProduit.map((el, idx) => (
                <option key={idx} value={el.id}>
                {el.libelle}  
                  </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-row">
            {/* Numéro de série et Numéro de série 2 en série horizontale */}
            <div className="form-group col-md-12 d-flex">
              <div className="mr-3" style={{ flex: 1 }}>
                <label>{t("Numéro de Série")}<span className="text-danger">*</span></label>
                <input type="text" className="form-control" placeholder="Du Numéro de série..." onChange={(e) => setnumero_serie(e.target.value)} />
              </div>
              <div style={{ flex: 1 }}>
                <label>{t("Numéro de Série")}<span className="text-danger">*</span></label>
                <input type="text" className="form-control" placeholder="Au Numéro de série..." value={props.numeroserie2} onChange={handleChangeInAddSerie} />
              </div>
            </div>
          </div>

          <div className="form-row">
            {/* Type en une seule ligne */}
            <div className="form-group col-md-12">
              <label>{t("Client")}</label>
              <select onChange={handleChangeClients} className="selectpicker form-control">
                <option value="0" >{t("  Clients")}</option>
                  {client &&
                  client.map((el, idx) => (
                  <option key={idx} value={el.id}>
                    {el.nom} {el.prenom}
                </option>
                  ))}
                </select>
            </div>
          </div>

          {/* Boutons de modal */}
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                  {t("Annuler")}
                </button>
                <button type="button" className="btn btn-primary" onClick={handelSavee}>
                  {t("Ajouter")}
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
  );
}

export default AddSerie;
