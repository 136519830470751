import React, { useState } from 'react'
import Exercice from './Exercice'
import ParametragePaie from './parametrage_paie/ParametragePaie'
import { t } from "i18next";
import ParamRubriquePaie from './parametrage_rubrique_paie/ParamRubriquePaie';
import ListParamRubrique from './parametrage_rubrique_paie/ListParamRubrique';
import Avantage_nature from './Avantage_nature/Avantage_nature';
import ContratTypeForm from './Contrat_type/ContratTypeForm';
import ContratType from './Contrat_type/ContratType';
import ListJourFerie from './JourFerie/ListJourFerie';
import CongeType from '../Conges/CongeType';
import List_banques from './Banques/List_banques';
import { Entreprise } from './Entreprise';

export default function Societepage() {
    const [refresh ,setrefresh]=useState(false)
    return (
        <div>
            <div className="tab-menu-heading hremp-tabs p-0 ">
                <div className="tabs-menu1">
                    {/* Tabs */}
                    <ul className="nav panel-tabs">
                        {/* <li className="ml-4">
                  <a href="#tab5" className="active" data-toggle="tab">
                    facture
                  </a>
                </li> */}
                        <li  onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab6" className="active" data-toggle="tab" >
                                {t("Entreprise")}
                            </a>
                        </li>
                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab7" data-toggle="tab" >
                                {t("Exercices")}
                            </a>
                        </li>
                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab8" data-toggle="tab">
                                {t("Parametrage paie")}
                            </a>
                        </li>
                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab9" data-toggle="tab">
                                {t("Parametrage rubriques")}
                            </a>
                        </li>
                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab11" data-toggle="tab">
                                {t("Avantages en nature")}
                            </a>
                        </li>
                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab12" data-toggle="tab">
                                {t("Types contrat")}
                            </a>
                        </li>
                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab13" data-toggle="tab">
                                {t("Jour férié")}
                            </a>
                        </li>

                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab14" data-toggle="tab">
                                {t("Type conge")}
                            </a>
                        </li>

                        <li onClick={()=>setrefresh(!refresh)}>
                            <a href="#tab15" data-toggle="tab">
                                {t("list banques")}
                            </a>
                        </li>


                    </ul>
                </div>
            </div>
            <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
                <div className="tab-content">
                    <div className="tab-pane active" id="tab6">
                        <Entreprise  t={t}/>
                    </div>
                    <div className="tab-pane " id="tab7">
                        <Exercice  refresh={refresh} />
                    </div>
                    <div className="tab-pane " id="tab8">
                        <ParametragePaie refresh={refresh}  />
                    </div>
                    <div className="tab-pane " id="tab9">
                        <ListParamRubrique refresh={refresh}  />
                    </div>
                    <div className="tab-pane " id="tab11">
                        <Avantage_nature refresh={refresh}  />
                    </div>
                    <div className="tab-pane " id="tab12">
                        <ContratType refresh={refresh}  />
                    </div>
                    <div className="tab-pane " id="tab13">
                        <ListJourFerie refresh={refresh}  />
                    </div>
                    <div className="tab-pane " id="tab14">
                        <CongeType refresh={refresh}  />
                    </div>
                    <div className="tab-pane " id="tab15">
                        <List_banques refresh={refresh}  />
                    </div>
                </div>
            </div>
        </div>
    )
}
