import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getEnteteByCode, insertfile, uploadDechargeFacuteByCode } from "../../Services/Facturation/FacturationEnteteApi";
import "./UploadDecharge.css";
import { t } from "i18next";
import { Link, useHistory } from "react-router-dom";

function UploadFacture(props) {
  const history = useHistory();

  const [imageUrl, setimageUrl] = useState("");
  const [file, setfile] = useState();
  const [imageObject, setimageObject] = useState("assets/images/icons/add.png");
  const [isLodedImage, setisLodedImage] = useState(false);



  /**
   * It sets the image name, image url, and image object.
   * @param e - The event object.
   */
  const handleChangeImage = (e) => {
    setfile(e.target.files[0]);

   
  };

  const uploadFile = (item, e) => {
   
    const newFileName = props.match.params.id;
    const formData = new FormData();
    formData.append('file', file, newFileName);
    formData.append('code_facture', props.match.params.id);
  

    insertfile(formData).then((resPaiement) => {
     // window.location.reload()
    history.push('/admin/factureachat')
    })
      
  }
  /**
   * Uploads the image to the decharge facute by code
   */

  return (
    <div className="row mt-4">
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">{t("Sélectionnez décharge facture")}</h3>
            <div className="card-tools">
              {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
              <i className="fas fa-minus" />
            </button>
            <button type="button" className="btn btn-tool" data-card-widget="remove">
              <i className="fas fa-times" />
            </button> */}
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body">
            <div className="container">
              <div className="panel panel-default">
                <div className="panel-body">
                  {/* Standar Form */}
                  <h4>{t("Sélectionnez décharge facture")}</h4>
                  <form action method="post" encType="multipart/form-data" id="js-upload-form"></form>
                  {/* Drop Zone */}

                  <div className="form-group files">
                    <label>{t("Téléchargez votre fichier")} </label>
                    <input  accept="pdf/" type="file" className="form-control" onChange={handleChangeImage} />
                  </div>

                  <div className="form-inline">
                    <button type="submit" className="btn btn-sm btn-primary" id="js-upload-submit" onClick={uploadFile}>
                      {t("Ajouter document")}
                    </button>
                  </div>
               
              
                </div>
              </div>
            </div>
          </div>
          {/* /.card-body */}
          <div className="card-footer">
            {/* Visit{" "}
          <a href="https://select2.github.io/">Select2 documentation</a> for
          more examples and information about the plugin. */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadFacture;
