import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from "moment";
import Select from 'react-select';
import { getAllDevise } from '../../../Services/devise/devise';
import ReactSelect from 'react-select';
import { savebanque, updatebanque } from '../../../Services/banque';
import { preventDefault } from '@fullcalendar/react';

export default function AjoutBanque({ data ,getData,deviseState}) {
    const { t } = useTranslation();
    const image_url = process.env.REACT_APP_API_URL;

    const [modif, setmodif] = useState(false)
    const [formData, setFormData] = useState({
        banque: '',
        num_compte: '',
        devise_id: deviseState[0]?.id,
        code_generated:localStorage.getItem('code_generated')
    });

    useEffect(() => {
        if (data && data?.id) {
            setmodif(true)
            setFormData({
                id: data.id,
                banque: data.banque,
                num_compte: data.num_compte,
                devise_id: data.devise_id,
                code_generated:localStorage.getItem('code_generated')

            })
        }
    }, [data])

    const handleInputChange = (name,value) => {
        const copiedata = { ...formData }
        copiedata[name] = value
        setFormData(copiedata)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(formData.id && formData.id !==''){
            updatebanque(formData).then((res)=>{
                if(res.status === 200){
                    toast.success(t("banque  modifiée"))
    
                    setFormData({
                        banque: '',
                        num_compte: '',
                        devise_id: '',
                        code_generated:localStorage.getItem('code_generated')
                    });
                    getData();
                }
            })
        }else{
            savebanque(formData).then((res)=>{
                if(res.status === 201){
                    toast.success(t("banque  enregistrée"))
    
                    setFormData({
                        banque: '',
                        num_compte: '',
                        devise_id: '',
                        code_generated:localStorage.getItem('code_generated')
                    });
                    getData();
                }
            })
        }
       

    }

    return (
        <div>
            <form onSubmit={handleSubmit}>


                <div className="form-group">
                    <label>{t('Banque')}</label>
                    <input type='text' readOnly={modif} name='banque' className='form-control' value={formData.banque} onChange={(e)=>handleInputChange('banque',e.target.value)} required />

                </div>
                <div className="form-group">
                    <label>{t('num_compte')}</label>
                    <input type='text' readOnly={modif} name='num_compte' className='form-control' value={formData.num_compte} onChange={(e)=>handleInputChange('num_compte',e.target.value)} required />

                </div>
                <div className="form-group">
                <label>{t('devise')}</label>

                    <ReactSelect
                        value={deviseState && deviseState.find(item => item.id === formData.devise_id)}
                        options={deviseState}
                        onChange={(e) => handleInputChange('devise_id',e.id)}                      
                          formatOptionLabel={devise => (
                            <div className="country-option">
                                <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                                <span>{devise.libelle}</span>
                            </div>
                        )}
                    />
                </div>
                <div className='row mt-3'>
                    {modif ? (<a onClick={() => setmodif(false)} className="btn btn-primary float-right">
                        <i className="far fa-credit-card" /> {t("Modifier")}
                    </a>
                    ) :
                        <button type="submit" className="btn btn-primary float-right">
                            <i className="far fa-credit-card" /> {t("Enregistrer")}
                        </button>
                    }

                </div>
            </form>
        </div>
    );
}
