export function isCode(value) {
  let myRegularExpression = new RegExp(/var\s*.*;/g);
  if (value.match(/var|const|let|console|print|{}|div class|a href=|<div/g)) {
    return true;
  }
  return false;
}

export const handleKeyPress_number = (event) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf('.') !== -1) {
    event.preventDefault();
  }


}; 
export const handleKeyPress = (
  event
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }

  // Ensure that only one minus sign is allowed at the beginning
  if (charCode === 45 && inputText.indexOf("-") !== -1) {
    event.preventDefault();
  }
};