import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
 
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import AddSerie from "./AddSerie";

function SerieCardHeader(props) {
   const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
 


  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Liste de Série")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#addCongeModal">
                {t("Ajouter Numéro Série")}
              </Link>
             </div>
          </div>
        </div>
      </div> 
      {/* modal */}
      <AddSerie getData={props.getData} handleChange={props.handleChange} numeroserie2={props.numeroserie2} updateCurrentData={props.updateCurrentData} handleChangeNumserie={props.handleChangeNumserie} numeroserie1={props.numeroserie1}/>
    </>
  );
}

export default SerieCardHeader;
