import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the posts from the blog.
 * @returns The response is an array of objects. Each object contains the following properties:
 */
export async function getAllPosts() {
  try {
    const response = await axios.get(api_url + "blog/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a new post to the blog.
 * @param title - The title of the post.
 * @param description - The description of the post.
 * @param image - The image file that you want to upload.
 * @returns The response from the server.
 */
export async function addPost(title, description, image) {
  const frmData = new FormData();

  frmData.append("user_id", getSession("id"));
  frmData.append("code_generated", getSession("code_generated"));
  frmData.append("title", title);
  frmData.append("description", description);
  frmData.append("image", image);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "blog", frmData, options);
    toast.success("Publication ajoutée");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}
