/**
 * Given an array of check-in objects, a value representing a date, and a current date, return a number
 * representing the status of the check-in
 * @param array - The array of objects that contains the pointage data.
 * @param value - the date you want to check
 * @param currentDate - The current date in the format of YYYY-MM-DD
 * @returns The result is a number that represents the status of the checkin.
 */
export function Checkin(array, value, currentDate) {
  //1 non pointage
  //2 non pointage sortie
  //3 pointage entré sortie ok
  // 4 pas encore encours
  //5 weekend
  var result = 1;

  if (array.length === 0) {
    result = 1;
  } else {
    array.forEach((element) => {
      if (checkRunningDay(array, value)) {
        //encours
        result = 4;
      } else {
        if (element.pointage.split("-")[2].split(" ")[0] == value) {
          if (element.pointage.split(",")[1] === undefined) {
            //2 non pointage sortie
            result = 2;
          } else {
            //3 pointage entré sortie ok
            const timePart = element.pointage.split(",")[1].split(' ')[1];
            // Extracting hours, minutes, and seconds
            const [hours, minutes, seconds] = timePart.split(':').map(Number);
            if (hours <= 20) {
              result = 6;
            } else {
              //3 pointage entré sortie ok
              result = 3;
            }
          }
        } else {
          if (showDay(currentDate, value, array)) {
            //wekend
            result = 5;
          }
        }
      }
    });
  }

  return result;
}

export function isCheckin(array, value, currentDate) {
  //1 non pointage
  //2 non pointage sortie
  //3 pointage entré sortie ok
  // 4 pas encore encours
  //5 weekend
  var result = 1;

  if (array.length === 0) {
    result = 1;
  } else {
    array.forEach((element) => {
      if (checkRunningDay(array, value)) {
        //encours
        result = 4;
      } else {
        if (element.pointage.split("-")[2].split(" ")[0] == value) {
          if (element.pointage.split(",")[1] === undefined) {
            //2 non pointage sortie
            result = 2;
          } else {
            result = 3
          }
        } else {
          if (showDay(currentDate, value, array)) {
            //wekend
            result = 5;
          }
        }
      }
    });
  }

  return result;
}
/**
 * Given a date, it checks if the day is a weekend or not
 * @param currentDate - The date of the current month.
 * @param d - The day of the month to show.
 * @param array - the array of objects that contains the pointage data
 * @returns true if the day is a weekend day, false otherwise.
 */
export function showDay(currentDate, d, array) {
  //console.log(currentDate);
  const month = currentDate.split("-")[1];
  const year = currentDate.split("-")[0];
  const fullDate = year + "-" + month + "-" + d;

  var myDate = new Date();
  myDate.setFullYear(year);
  myDate.setMonth(month - 1);
  myDate.setDate(d);
  if (myDate.getDay() == 6 || myDate.getDay() == 0) {
    // if (array.length == 0) {
    //   return true;
    // } else {
    //   const monthPointage = array[0].pointage.split("-")[1];
    //   const yearPointage = array[0].pointage.split("-")[0];
    //   const dayPointage = array[0].pointage.split("-")[2].split(" ")[0];

    //   const monthYearDayPointage = yearPointage + "-" + monthPointage + "-" + dayPointage;
    //   //date params
    //   console.log(fullDate);
    //   //date pointage
    //   console.log(monthYearDayPointage);
    //   if (monthYearDayPointage == fullDate) {
    //     console.log("non week");
    //     return false;
    //   } else {
    //     return true;
    //   }

    //   return true;
    // }
    return true;
  } else return false;
}

/**
 * Check if the current month and year is the same as the month and year of the first pointage in the
 * array
 * @param array - the array of pointage objects
 * @param value - The day of the month you want to check.
 * @returns a boolean value.
 */
export function checkRunningDay(array, value) {
  var dateObj = new Date();
  var result = dateObj.toISOString().split("T")[0];
  var month = result.split("-")[1];
  var year = result.split("-")[0];
  const monthYear = year + "-" + month;

  const monthPointage = array[0].pointage.split("-")[1];
  const yearPointage = array[0].pointage.split("-")[0];
  const monthYearPointage = yearPointage + "-" + monthPointage;
  if (monthYearPointage == monthYear) {
    if (Number(value) > Number(dateObj.getDate())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

