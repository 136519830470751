/**
 * filter by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterUserByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom?.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom?.toLowerCase().includes(string.toLowerCase()) ||
      el.fonction?.toLowerCase().includes(string.toLowerCase()) ||
      el.mobile?.toLowerCase().includes(string.toLowerCase()) ||
      el.email?.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterUser = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.email.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
export const filterUserSatisfaction = (arrayObject, string) => {
   const filtered = arrayObject.filter((el) => {
    const clientString = el.client.toString();
    const supportString = el.support.toString();
    const nomClientString = el.nom_client.toString();
    const dateString = el.date.toString();
    const methodeContactString = el.methode_contact.toString();
    const destinaireNumTelString = el.destinaire_num_tel.toString();
    const remarqueString = el.remarque.toString();
    const visAVisString = el.vis_a_vis.toString();
    const satisfaitString = el.satisfait.toString();
    const anomalieDifficulteString = el.anomalie_difficulte.toString();
    const propositionString = el.proposition.toString();
    const tempsReclamationString = el.temps_reclamation.toString();
    const tempsDemandeString = el.temps_demande.toString();

    return (
      clientString.toLowerCase().includes(string.toLowerCase()) ||
      supportString.toLowerCase().includes(string.toLowerCase()) ||
      nomClientString.toLowerCase().includes(string.toLowerCase()) ||
      dateString.toLowerCase().includes(string.toLowerCase()) ||
      methodeContactString.toLowerCase().includes(string.toLowerCase()) ||
      destinaireNumTelString.toLowerCase().includes(string.toLowerCase()) ||
      remarqueString.toLowerCase().includes(string.toLowerCase()) ||
      visAVisString.toLowerCase().includes(string.toLowerCase()) ||
      satisfaitString.toLowerCase().includes(string.toLowerCase()) ||
      anomalieDifficulteString.toLowerCase().includes(string.toLowerCase()) ||
      propositionString.toLowerCase().includes(string.toLowerCase()) ||
      tempsReclamationString.toLowerCase().includes(string.toLowerCase()) ||
      tempsDemandeString.toLowerCase().includes(string.toLowerCase())
    );
  });

  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter conge by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterCongeByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.motif.toLowerCase().includes(string.toLowerCase()) ||
      el.email.toLowerCase().includes(string.toLowerCase())
  );
};


/**
 * filter conge by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAttestationByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.type.toLowerCase().includes(string.toLowerCase()) ||
      el.fonction.toLowerCase().includes(string.toLowerCase())
  );
};

/**
 * filter project by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterProjectByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.commentaire.toLowerCase().includes(string.toLowerCase()) ||
      el.description.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase())
  );
};
/**
 * filter project by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterProjectByWordSupport = (arrayObject, string) => { 
  return arrayObject.filter((el) => {
    const anneeString = el.annee.toString();
    const nombreUserString = el.nombre_user.toString(); // Convertir en chaîne de caractères
    const janvierString = el.janvier.toString(); // Convertir en chaîne de caractères
    const frvrierString = el.fevrier.toString(); // Convertir en chaîne de caractères
    const marsString = el.mars.toString(); // Convertir en chaîne de caractères
    const avrilString = el.avril.toString(); // Convertir en chaîne de caractères
    const maiString = el.mai.toString(); // Convertir en chaîne de caractères
    const juinString = el.juin.toString(); // Convertir en chaîne de caractères
    const juillettring = el.juillet.toString(); // Convertir en chaîne de caractères
    const aoutString = el.aout.toString(); // Convertir en chaîne de caractères
    const septembreString = el.septembre.toString(); // Convertir en chaîne de caractères
    const octobreString = el.octobre.toString(); // Convertir en chaîne de caractères
    const novembreString = el.novembre.toString(); // Convertir en chaîne de caractères
    const decembreString = el.decembre.toString(); // Convertir en chaîne de caractères

    return (
      el.client.toLowerCase().includes(string.toLowerCase()) ||
      nombreUserString.toLowerCase().includes(string.toLowerCase()) ||
      el.projet.toLowerCase().includes(string.toLowerCase()) ||
      anneeString.toLowerCase().includes(string.toLowerCase()) ||  // Change to anneeString
      janvierString.toLowerCase().includes(string.toLowerCase()) ||
      frvrierString.toLowerCase().includes(string.toLowerCase()) ||
      marsString.toLowerCase().includes(string.toLowerCase()) ||
      avrilString.toLowerCase().includes(string.toLowerCase()) ||
      maiString.toLowerCase().includes(string.toLowerCase()) ||
      juinString.toLowerCase().includes(string.toLowerCase()) ||
      juillettring.toLowerCase().includes(string.toLowerCase()) ||
      aoutString.toLowerCase().includes(string.toLowerCase()) ||
      septembreString.toLowerCase().includes(string.toLowerCase()) ||
      octobreString.toLowerCase().includes(string.toLowerCase()) ||
      novembreString.toLowerCase().includes(string.toLowerCase()) ||
      decembreString.toLowerCase().includes(string.toLowerCase())
    );
  });
};

/**
 * filter project by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAutorisationByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.description.toLowerCase().includes(string.toLowerCase()) ||
      el.objet.toLowerCase().includes(string.toLowerCase()) ||
      el.nb_heure == string
  );
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterTasksByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el?.nom?.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom?.toLowerCase().includes(string.toLowerCase()) ||
      el.description?.toLowerCase().includes(string.toLowerCase()) ||
      el.commentaire?.toLowerCase().includes(string.toLowerCase()) ||
      el.object?.toLowerCase().includes(string.toLowerCase())||
      el.etat?.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAnnoncesByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) => el.title.toLowerCase().includes(string.toLowerCase()) || el.description.toLowerCase().includes(string.toLowerCase())
  );
};

/**
 * filter pointage by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterPointageByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.email.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase())
  );

  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter ligne de paie by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterLignePaieByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.code.toLowerCase().includes(string.toLowerCase()) ||
      el.code_paie == string ||
      el.base == string ||
      el.gain == string ||
      el.retenue == string ||
      el.compte_comptable == string
  );
};

/**
 * filter baremme irpp by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterBaremeIrppByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) => el.code.toLowerCase().includes(string.toLowerCase()) || el.min == string || el.max == string || el.taux == string || el.taux_sup == string
  );
};

/**
 * filter baremme irpp by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterProductByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()) || el.code == string);
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter fournisseur by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterFournisseurByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.email.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase())
  );
};
/**
 * filter serie by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterSerieByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.numero_serie.toLowerCase().includes(string.toLowerCase()) ||
      el.produit.toLowerCase().includes(string.toLowerCase()) ||
      el.type.toLowerCase().includes(string.toLowerCase()) ||
      el.fournisseur.toLowerCase().includes(string.toLowerCase())
  );
};
/**
 * filter tax by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterTaxByWord = (arrayObject, string) => {
  return arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()) || el.taux == string);
};

/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterFactureByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el.client_nom.toLowerCase().includes(string.toLowerCase()) || el.facture_code.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterStockByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el.produit.libelle.toLowerCase().includes(string.toLowerCase()) || el.produit.code.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterFactureByWordAbonnement = (allInitialData, string) => {
 
  const filtered = allInitialData.filter(
    (el) =>
      (el.nom && el.nom.toLowerCase().includes(string.toLowerCase())) ||
      (el.facture_code && el.facture_code.toLowerCase().includes(string.toLowerCase()))
  );

  if (filtered.length === 0) {
    return allInitialData;
  } else {
    return filtered;
  }
};


export const filterCongeTitleByTitle = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.title.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0 || string.length == 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterByLibelle = (arrayObject, string) => {
  return arrayObject.filter((el) => el.libelle.toLowerCase().includes(string.toLowerCase()));
};

/**
 * filter encaissement by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterEncaissement = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      (el?.nom || "").toLowerCase().includes(string.toLowerCase()) ||
      (el?.num_facture || "").toLowerCase().includes(string.toLowerCase()) ||
      el?.code_facture === string ||
      el?.reference_banque === string
  );

  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
/**
* @param {Object} arrayObject
* @param {string} string
*/
export const filterFactureClient = (arrayObject, string) => {
 const filtered = arrayObject.filter(
   (el) =>
     (el?.nom || "").toLowerCase().includes(string.toLowerCase()) ||
     (el?.num_facture || "").toLowerCase().includes(string.toLowerCase()) ||
     (el?.quantite || "").toLowerCase().includes(string.toLowerCase()) ||
     (el?.date || "").toLowerCase().includes(string.toLowerCase()) ||
     (el?.date_prevu_livraison || "").toLowerCase().includes(string.toLowerCase()) ||
     (el?.libelleProduit || "").toLowerCase().includes(string.toLowerCase()) 
 );

 if (filtered.length === 0) {
   return arrayObject;
 } else {
   return filtered;
 }
};
/**
 * filter facture by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterAbonnemntByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.client_code.toLowerCase().includes(string.toLowerCase()) ||
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.code_abonnement.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterVaccinByWord = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.nom_vaccin?.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterTeletravailByWord = (arrayObject, string) => {
  return arrayObject.filter(
    (el) =>
      el.nom.toLowerCase().includes(string.toLowerCase()) ||
      el.prenom.toLowerCase().includes(string.toLowerCase()) ||
      el.motif?.toLowerCase().includes(string.toLowerCase())
  );
};

export const filterVirementByCode = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.code.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const sortObjectByValue = (arrayObject, value) => {
  const res = arrayObject.sort(function (a, b) {
    return b[value] - a[value];
  });
  const filter = res.filter((el) => {
    return parseInt(el.occurrence) != "1";
  });
  return filter;
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterByDocument = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el.nom.toLowerCase().includes(string.toLowerCase()) || el.categorie.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

/**
 * filter task by word
 * @param {Object} arrayObject
 * @param {string} string
 */
export const filterCaisse = (arrayObject, string) => {
  const filtered = arrayObject.filter(
    (el) => el?.description.toLowerCase().includes(string.toLowerCase()) || el?.cente_cout.toLowerCase().includes(string.toLowerCase())
  );
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
