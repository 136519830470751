import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ReactTooltip from "react-tooltip/dist";
import "react-tooltip/dist/index.js";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactLightbox from "simple-react-lightbox";
import * as serviceWorkder from "./serviceWorker";
import { Toaster } from "react-hot-toast";

import "./i18n";
import Preloader from "./Components/shared/Preloader";
import i18next from "i18next";
const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
// Au chargement de votre application
const savedLanguage = localStorage.getItem('selectedLanguage');

// Si une langue est enregistrée dans le stockage local, utilisez-la comme langue par défaut, sinon utilisez la langue par défaut (français).
const defaultLanguage = savedLanguage || 'fr';
i18next.init({
  lng: defaultLanguage,
  // ...
});
ReactDOM.render(
  <React.StrictMode>
    <Provider template={AlertTemplate} {...options}>
      <ReactTooltip />
      <SimpleReactLightbox>
        <Suspense fallback={<Preloader></Preloader>}>
          <App />
          <Toaster position="bottom-right" reverseOrder={false} />
        </Suspense>
      </SimpleReactLightbox>
      <NotificationContainer />
      <ToastContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkder.register();
reportWebVitals();
