// import { CSVLink } from 'react-csv';

import { useRef } from "react";
import { CSVLink } from "react-csv";
import {Link} from "react-router-dom"
function ExportCsvSupport({ data, name }) {
  const csvLinkEl = useRef(null);
   const columnsToExport = data.map(({ client,projet,nombre_user,annee, janvier, fevrier, mars,avril,mai,juin,juillet,aout,septembre,octobre,novembre,decembre}) => ({
    client,
    projet,
    nombre_user,
    annee,
    janvier,
    fevrier,
    mars,
    avril,
    mai,
    juin,
    juillet,
    aout,
    septembre,
    octobre,
    novembre,
    decembre,
  }));

  const headers = [
    { label: 'Client', key: 'client' },
    { label: 'Projet', key: 'projet' },
    { label: 'N° Utilisateurs Totales', key: 'nombre_user' },
    { label: 'Année', key: 'annee' },
    { label: 'Janvier', key: 'janvier' },
    { label: 'Février', key: 'fevrier' },
    { label: 'Mars', key: 'mars' },
    { label: 'Avril', key: 'avril' },
    { label: 'Mai', key: 'mai' },
    { label: 'Juin', key: 'juin' },
    { label: 'Juillet', key: 'juillet' },
    { label: 'Août', key: 'aout' },
    { label: 'Septembre', key: 'septembre' },
    { label: 'Octobre', key: 'octobre' },
    { label: 'Novembre', key: 'novembre' },
    { label: 'Décembre', key: 'decembre' },
  ];

  /**
   * Download a report as a CSV file
   */
  const downloadReport = async () => {
    setTimeout(() => {
      csvLinkEl?.current.link.click();
    });
  };

  return (
    <>
      <Link to="#" className="action-btns" onClick={downloadReport} data-toggle="tooltip" data-placement="top" title="csv">
        <i className="fa fa-file-excel-o text-success" />
      </Link>
      <CSVLink
        data={columnsToExport}
        headers={headers}
        filename={`${name}.csv`}
        ref={csvLinkEl}
      />
    </>
  );
}

export default ExportCsvSupport;
