import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertFloatToDigit } from "../../../Utils/NumberUtils";
import { t } from "i18next";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // borderBottomColor: "#142328",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "65%",
    textAlign: "right",

    paddingRight: 8,
  },
  total: {
    width: "35%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ invoice }) => {
  return (
    <>
      {invoice.remise != "0" && (
        <View style={styles.row}>
          <Text style={styles.description}>{t("Sous total HT")}</Text>
          <Text style={styles.total}>{invoice.sous_total_ht} {t("TND")}</Text>
        </View>
      )}

      {invoice.remise != "0" && (
        <View style={styles.row}>
          <Text style={styles.description}>{t("Remise")}</Text>
          <Text style={styles.total}>
            {invoice.remise}
            {invoice.actionRemiseEntete === "2" ? " TND" : " %"}
          </Text>
        </View>
      )}

      <View style={styles.row}>
        <Text style={styles.description}>{t("Total HT")}</Text>
        <Text style={styles.total}>{invoice.totalHt == "0" ? "0" : invoice.totalHt + " TND"}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>{t("TVA")}</Text>
        <Text style={styles.total}>{invoice.tva == "0" ? "0" : invoice.tva + " TND"}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>{t("Timbre fiscale")}</Text>
        <Text style={styles.total}>{invoice.timbre_fiscale}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>{t("Total TTC")}</Text>
        <Text style={styles.total}>{invoice.totalTTC == "0" ? "0" : invoice.totalTTC + " TND"}</Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
