import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { addTax, editTaxById, getTaxById } from "../../../Services/Facturation/FacturationTvaApi";
import { t } from "i18next";
import { handleKeyPress_number } from "../../../Utils/InputUtils";
import { Form, Modal } from "react-bootstrap";

function AddTax(props) {
  const history = useHistory();
  const [taxe , setTaxes]=useState({
    id:'',
    taux:'',
    type:'',
    libelle:'',
    soussociete_code:''
  })
  const listtaxes = ['tva', 'rs']

  const myRefHide = useRef(null);
  useEffect(() => {
    if(props.idTax !== ''){
      getData();
    }
    
  }, [props.idTax]);
  

  const getData = () => {
    getTaxById(props.idTax).then((res) => {
      setTaxes({
        id:res.data.id,
        taux:res.data.taux,
        type:res.data.type,
        libelle:res.data.libelle,
        soussociete_code:res.data.soussociete_code
      })
      
    });
  };

  const handleSubmitTax=()=>{
    if(taxe.type === '' || taxe.libelle === "" || taxe.taux ===''){
      toast.warning('Entrez toutes les informations requises *')

    }else{
    if(props.idTax!==''){
      editTaxById(taxe).then((res) => {
        if(res.status ===200){
           props.setshowModal(false)
        
      window.location.reload()
        }
       

      });   
     }else{
      const newdata = {
        ...taxe,
        soussociete_code:localStorage.getItem('code_generated')
      };
      addTax(newdata).then((res) => {
        if(res.status ===200){
          props.setshowModal(false)
       
     window.location.reload()
       }
      }); //ajout 
    }}
  }
 
  return (
    <Modal
    show={props.showModal} 
    onHide={()=>props.setshowModal(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>{taxe.id !== '' ? 'Editer tax' : 'Ajouter tax'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group>
          <Form.Label>Type<span className="text-danger">*</span></Form.Label>
          <Form.Control
            as="select"
            value={taxe.type}
            onChange={(e) => setTaxes(prev => ({ ...prev, type: e.target.value }))}
          >
            <option value=''>choisir type</option>
            {listtaxes.map(item => (
              <option key={item} value={item}>{item}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Libelle<span className="text-danger">*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="..."
            required
            value={taxe.libelle}
            onChange={(e) => setTaxes(prev => ({ ...prev, libelle: e.target.value }))}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Taux (%)<span className="text-danger">*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="..."
            required
            value={taxe.taux}
            onKeyPress={handleKeyPress_number}
            onChange={(e) => setTaxes(prev => ({ ...prev, taux: e.target.value }))}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-primary"onClick={()=>props.setshowModal(false)}>Annuler</button>
      <button className="btn btn-primary" onClick={handleSubmitTax}>Enregistrer</button>
    </Modal.Footer>
  </Modal>
  );
}

export default AddTax;
