import React, { useRef, useState } from "react";
import { ajouterNote } from "../../Services/Pointeuse/NoteApi";
import { t } from "i18next";

function AddNote(props) {
  const [pdfUrl, setpdfUrl] = useState(null);
  const [pdfName, setpdfName] = useState(null);
  const hideModal = useRef();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);
  const [color, setColor] = useState("#bbb");

  const handlePdf = (event) => {
    setpdfUrl(event.target.files[0]);
    setpdfName(event.target.files[0].name);
  };

  /**
   * It adds a new note to the database.
   */
  const handleSaveNote = () => {
    ajouterNote(title, description, dateDebut, dateFin, color, pdfUrl).then(() => {
      props.refreshData();
      hideModal.current.click();
      setTitle("");
      setDateDebut("");
      setDateFin("");
      setDescription("");
      setColor(null);
      setpdfUrl(null);
    });
  };
  return (
    <div className="modal fade" id="newNotemodal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("Ajouter note")}</h5>
            <button className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">{t("Titre")}</label>
                  <input className="form-control" placeholder={t("titre note")} value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Task Title</label>
                  <input className="form-control" placeholder="Text" />
                </div>
              </div> */}
            </div>
            {/* <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Département:</label>
                  <select
                    name="attendance"
                    className="form-control custom-select select2"
                    data-placeholder="Select Département"
                  >
                    <option label="Select Département" />
                    <option value={1}>Designing Département</option>
                    <option value={2}>Development Département</option>
                    <option value={3}>Marketing Département</option>
                    <option value={4}>Human Resource Département</option>
                    <option value={5}>Managers Département</option>
                    <option value={6}>Application Département</option>
                    <option value={7}>Support Département</option>
                    <option value={8}>IT Département</option>
                    <option value={9}>Technical Département</option>
                    <option value={10}>Accounts Département</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Assign To:</label>
                  <select
                    name="attendance"
                    className="form-control custom-select select2"
                    data-placeholder="Select Employee"
                  >
                    <option label="Select Employee" />
                    <option value={1}>Faith Harris</option>
                    <option value={2}>Austin Bell</option>
                    <option value={3}>Maria Bower</option>
                    <option value={4}>Peter Hill</option>
                    <option value={5}>Victoria Lyman</option>
                    <option value={6}>Adam Quinn</option>
                    <option value={7}>Melanie Coleman</option>
                    <option value={8}>Max Wilson</option>
                    <option value={9}>Amelia Russell</option>
                    <option value={10}>Justin Metcalfe</option>
                    <option value={11}>Ryan Young</option>
                    <option value={12}>Jennifer Hardacre</option>
                    <option value={13}>Justin Parr</option>
                    <option value={14}>Julia Hodges</option>
                    <option value={15}>Michael Sutherland</option>
                  </select>
                </div>
              </div>
            </div> */}
            {/* <div className="form-group">
              <label className="form-label">Task Priority:</label>
              <select
                name="attendance"
                className="form-control custom-select select2"
                data-placeholder="Select Priority"
              >
                <option label="Select Priority" />
                <option value={1}>High</option>
                <option value={2}>Medium</option>
                <option value={3}>Low</option>
              </select>
            </div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">{t("Date planifié")}:</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="feather feather-calendar" />
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="DD-MM-YYY"
                      type="date"
                      value={dateDebut}
                      onChange={(e) => setDateDebut(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">{t("Date d'échéance")}:</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="feather feather-calendar" />
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="DD-MM-YYY"
                      type="date"
                      value={dateFin}
                      onChange={(e) => setDateFin(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">{t("Description")}</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className="form-label">{t("Pièce jointe")}:</label>
              <div className="input-group mb-5 file-browser">
                <input type="text" className="form-control browse-file" placeholder={pdfName || t("choisir un fichier ...")} />
                <label className="input-group-append">
                  <span className="btn btn-primary">
                    {t("choisir fichier")} <input type="file" style={{ display: "none" }} onChange={handlePdf} />
                  </span>
                </label>
              </div>
            </div>
            <div className="custom-controls-stacked d-md-flex">
              <label className="form-label mt-1 mr-5">{t("Statut")} : </label>
              <label className="custom-control custom-radio success ml-2">
                <input type="radio" className="custom-control-input" name="example-radios1" defaultValue="option1" />
                <span className="custom-control-label">{t("à faire")}</span>
              </label>
              <label className="custom-control custom-radio success ml-6">
                <input type="radio" className="custom-control-input" name="example-radios1" defaultValue="option2" />
                <span className="custom-control-label">{t("terminé")}</span>
              </label>
              {/* <label className="custom-control custom-radio success">
                <input type="radio" className="custom-control-input" name="example-radios1" defaultValue="option3" />
                <span className="custom-control-label">On Progress</span>
              </label> */}
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-primary" data-dismiss="modal" ref={hideModal}>
              {t("Fermer")}
            </button>
            <button className="btn btn-success" onClick={handleSaveNote}>
              {t("Ajouter")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNote;
