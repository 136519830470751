import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getClientById, getFacturationClientByCode } from "../../../../../Services/Facturation/FacturationClientApi";
import { getAllProduct } from "../../../../../Services/Facturation/FacturationProduitApi";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";
import { calculateHt, calculateRemise, calulateTva, convertToDigit, margePercentage, MultiplicateNumber } from "../../../../../Utils/NumberUtils";
import { getGoupedValue } from "../../../../../Utils/ObjectUtils";
import { addLigne } from "../../../../../Services/Facturation/FacturationLigneApi";
import { addMouvement } from "../../../../../Services/Facturation/FacturationMouvementApi";
import { decrementStockQauntity } from "../../../../../Services/Facturation/FacturationStockApi";
import { addEntete, getEnteteCommercialVente, getEnteteCommercialVenteAbonnement } from "../../../../../Services/Facturation/FacturationEnteteApi";
import { getEntrepriseByCode } from "../../../../../Services/Pointeuse/EntrepriseApi";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import MyDocument from "../../../invoiceReports/MyDocument";
import { checkEmptyPriceQuantity } from "../../../../../Utils/ObjectOperation";
import { getEnteteMediaFacture } from "../../../../../Services/Facturation/FacturationEnteteMediaApi";
import { sendFactureBlob } from "../../../../../Services/Facturation/FacturationFileApi";
import { getToken, getSession } from "../../../../../Utils/SessionUtils";
import axios from "axios";
import { t } from "i18next";
import { addAbonnement } from "../../../../../Services/Facturation/FacturationAbonnementApi";
import { handleKeyPress, handleKeyPress_number } from "../../../../../Utils/InputUtils";
import { calculateEndDate, calculateMonthDifference } from "../../../../../Utils/DateUtils";
import { ajoutAbonnement, getLastIDAbonnement } from "../../../../../Services/abonnement/abonnement";
function AjouterAbonnement() {
  const history = useHistory();
  const api_url = process.env.REACT_APP_API_BASE_URL_V1;
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  //show facture
  const [showResults, setShowResults] = React.useState(false);
  const [showResultsEmail, setShowResultsEmail] = React.useState(false);
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [fournisseurCode, setFournisseurCode] = useState("");
  //const [fournisseurId, setFournisseurId] = useState("");
  const [sousTotalHt, setsousTotalht] = useState(0);
  //const [totalProductHt, settotalProductHt] = useState(0);
  const [actionRemiseEntete, setactionRemiseEntete] = useState("1");
  //const [remiseEnteteValue, setRemiseEnteteValue] = useState("");
  const [totalHt, setTotalHt] = useState("");
  const [listTva, setlistTva] = useState([]);
  const [totalTTC, settotalTTC] = useState("");
  const [timbreFiscale, setTimbreFiscal] = useState(0);

  //product detail
  const [products, setProducts] = useState([]);
  const [libelleProduct, setLibelleProduct] = useState("");
  // company details
  const [nomCompany, setNomCompany] = useState("");
  const [adresseCompany, setAdresseCompany] = useState("");
  const [mobileCompany, setMobileCompany] = useState("");
  const [emailCompany, setEmailCompany] = useState("");
  const [logoCompany, setLogoCompany] = useState("");
  const [matriculeFiscal, setmatriculeFiscal] = useState("");
  const [webUrl, setwebUrl] = useState("");
  // client detail
  const [nomClient, setNomClient] = useState("");
  const [prenomClient, setPrenomClient] = useState("");
  const [emailClient, setEmailClient] = useState("");
  const [fixeClient, setFixeClient] = useState("");
  const [adrFacturationClient, setAdrFacturationClient] = useState("");
  // facture detail
  const [numFacture, setnumFacture] = useState("");
  //const [numBl, setnumBl] = useState("");
  const [numCommande, setnumCommande] = useState("");
  const [numero_bl, setnumBL] = useState("");
  const [delais, setdelais] = useState("");
  const [frequence_facture, setfrequence_facture] = useState("");
  const [remise, setRemise] = useState("0");
  // facture footer
  const [noteFacture, setNoteFacture] = useState("");
  const [footerFacture, setFooterFacture] = useState("");
  // set date
  const [selectedDateToday, setSelectedDateToday] = React.useState();
  const [selectedDateDeadline, setSelectedDateDeadline] = React.useState();
  const [objectToPdf, setobjectToPdf] = useState();

  //
  const [imageObjectEntete, setimageObjectEntete] = useState("");
  const [imageObjectPied, setimageObjectPied] = useState("");
  const [LigneFacture1, setLigneFacture1] = useState("");
  const [LigneFacture2, setLigneFacture2] = useState("");
  const [LigneFacture3, setLigneFacture3] = useState("");
  const [LigneFacture4, setLigneFacture4] = useState("");
  const [clientObject, setclientObject] = useState({});
  const [timbre, setTimbre] = useState("");
  const [valeurInput, setValeurInput] = useState('');
  const [societe, setSocoiete] = useState("")
  const [frequance, setfrequance] = useState("")
  const [frequanceFacturation, setfrequanceFacturation] = useState("")
  const [dateDebut, setStartDate] = useState("");
  const [dateFin, setEndDate] = useState("");
  const [nb_users, setNbrUser] = useState(0);
  const [nb_mois, setNbrMonths] = useState(0);
  // État pour stocker les champs d'abonnement pour chaque produit
  const [abonnementFields, setAbonnementFields] = useState([]);
  const [updatedAbonnementFields, setUpdatedAbonnementFields] = useState([]);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [showAbonnementProducts, setShowAbonnementProducts] = useState(false);
  const [dateDebutContrat, setDateDebutContrat] = useState('');
  const [dateFinContrat, setDateFinContrat] = useState('');
  const [codeabonnement, setcodeabonnement] = useState(0)
  // getEntrepriseByCode
  useEffect(() => {
    getLastIDAbonnement().then((res) => {
      if (res?.data?.data) {

        if(res.data.data.last_code          ){

          const lastsouche = res.data.data.last_code.split(res.data.data.seq).pop();

          setnumFacture(`${res.data.data.seq}${parseInt(lastsouche)+1}`)
  
          setcodeabonnement(parseInt(lastsouche)+1)
        }
     

      else{
        setnumFacture(`${res.data.data.seq}1`)

        setcodeabonnement(1)
      }}
    });
  }, []);
  const getAllSociete = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.get(api_url + "societe/" + getSession("code_generated"), config);
      setSocoiete(response)
      return response;
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getAllSociete();
  }, []);
  // dynamic list product
  const [inputList, setInputList] = useState([
    {
      produit_code: "",
      libelleProduit: "",
      quantite: "",
      prixHt: "",
      remise: 0,
      tvaProduct: "",
      actionRemise: "1",
      totalHt: "",
      suivie_stock: "",
      nbr_users: '',
      nbr_mois: '',
      dateDebut: '',
      dateFin: ''

    },
  ]);
  // handle input change
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...inputList];
  //   list[index][name] = value;

  //   let sum = calculateRemise(MultiplicateNumber(list[index]["prixHt"], list[index]["qte"]), list[index]["remise"], list[index]["actionRemise"]);
  //   list[index]["totalHt"] = sum;

  //   setInputList(list);

  //   /**
  //    * detect change block
  //    */
  //   var sumTotal = 0;
  //   for (const [key, value] of Object.entries(list)) {
  //     sumTotal += value.totalHt;
  //   }
  //   setsousTotalht(sumTotal.toFixed(3));
  //   var totalHtValue = calculateRemise(sumTotal, remise, actionRemiseEntete).toFixed(3);
  //   setTotalHt(totalHtValue);

  //   let totalHtPrice = getGoupedValue(list, actionRemiseEntete, remise, sousTotalHt);
  //   setlistTva(totalHtPrice);
  //   let sumTtc = 0;
  //   for (let i = 0; i < totalHtPrice.length; i++) {
  //     sumTtc += Number(totalHtPrice[i].split(" ")[3]);
  //   }
  //   let calculateTotalTtc = Number(sumTtc) + Number(totalHtValue);
  //   //var addTimbreFiscale = Number(calculateTotalTtc) + Number(1.000);
  //   var addTimbreFiscale = Number(calculateTotalTtc) + Number(societe.data.timbre_fiscale);
  //   settotalTTC(addTimbreFiscale.toFixed(3));
  // };
  const handleChangedate = (event) => {
    const newDateDebutContrat = event.target.value;
    setDateDebutContrat(newDateDebutContrat);
    /*setAbonnementFields(prev => prev.map(obj => ({
      ...obj, 
      dateDebut:moment(newDateDebutContrat).format("YYYY-MM-DD")  
    })));*/
    if (frequance !== '') {
      const newDateFinContrat = calculateEndDate(newDateDebutContrat, parseInt(frequance));

      setDateFinContrat(newDateFinContrat);
    }

  };



  const handleChangedateFin = (event) => {
    const newDateFinContrat = event.target.value;
    setDateFinContrat(newDateFinContrat);
    // Vous pouvez faire d'autres traitements ici en fonction des besoins
  };
  const handleChangeFrequance = (e) => {
    const newFrequance = e.target.value;
    setfrequance(newFrequance)
    if (dateDebutContrat !== '') {
      const newDateFinContrat = calculateEndDate(dateDebutContrat, parseInt(newFrequance));

      setDateFinContrat(newDateFinContrat);
    }
  }
  const handleChangeFrequanceFacturation = (e) => {
    const newFrequanceFacturation = e.target.value;
    setfrequanceFacturation(newFrequanceFacturation)
  }
  const handleChangeDelais = (e) => {
    const newDelais = e.target.value;
    setdelais(newDelais)
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    // Mettre à jour les champs dans la liste
    if (name === 'prixHt' || name === 'remise') {

      list[index][name] = parseInt(value);

    }
    else {

      list[index][name] = value;

    }
    // Mettre à jour la quantité en fonction du nombre de user * nombre de mois
    if (name === 'nombreDeUser' || name === 'nombreDeMois') {
      const nombreDeUser = list[index]['nombreDeUser'] || 0;
      const nombreDeMois = list[index]['nombreDeMois'] || 0;
      list[index]['quantite'] = nombreDeUser * nombreDeMois;
    }
    // Calculer le totalHt en utilisant les nouvelles valeurs
    let sum = calculateRemise(
      MultiplicateNumber(list[index]["prixHt"], list[index]["quantite"]),
      list[index]["remise"],
      list[index]["actionRemise"]
    );
    list[index]["totalHt"] = sum;
    console.log("sum :", sum)
    // Mettre à jour l'état avec la nouvelle liste
    setInputList(list);

    /**
     * Bloc de détection des changements
     */
    var sumTotal = 0;
    for (const [key, value] of Object.entries(list)) {
      sumTotal += value.totalHt;
    }
    setsousTotalht(sumTotal.toFixed(3));
    var totalHtValue = calculateRemise(sumTotal, remise, actionRemiseEntete).toFixed(3);
    setTotalHt(totalHtValue);

    let totalHtPrice = getGoupedValue(list, actionRemiseEntete, remise, sousTotalHt);
    setlistTva(totalHtPrice);
    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
    }
    let calculateTotalTtc = Number(sumTtc) + Number(totalHtValue);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(societe.data.timbre_fiscale);
    settotalTTC(addTimbreFiscale.toFixed(3));
  };

  // handle remove product
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    /**
     * detect change block
     */
    var sumTotal = 0;
    for (const [key, value] of Object.entries(list)) {
      sumTotal += value.totalHt;
    }

    setsousTotalht(sumTotal.toFixed(3));
    setTotalHt(calculateRemise(sumTotal, remise, actionRemiseEntete).toFixed(3));

    let totalHtPrice = getGoupedValue(list, actionRemiseEntete, remise, sousTotalHt);
    setlistTva(totalHtPrice);
    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
    }
    let calculateTotalTtc = sumTtc + calculateRemise(sumTotal, remise, actionRemiseEntete);
    //var addTimbreFiscale = Number(calculateTotalTtc) + Number(1.000);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(societe.data.timbre_fiscale);
    settotalTTC(addTimbreFiscale.toFixed(3));
  };

  // handle add new product
  // create new object
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        produit_code: "",
        libelleProduit: "",
        quantite: "",
        prixHt: "",
        remise: 0,
        tvaProduct: "",
        actionRemise: "1",
        totalHt: "",
        suivie_stock: "",
        nbr_users: '',
        nbr_mois: '',
        dateDebut:  moment(dateDebutContrat).format("YYYY-MM-DD"),
        dateFin: ''

      },
    ]);
  };

  // fetch data
  useEffect(() => {
    //TODO remove seq in num facture
    //get entete sequence
    let seq = "";
    getSequenceByCode().then((res) => {
      console.log("sequence data :", res)
      seq = res.data.seq_abonnement;
      getEnteteCommercialVenteAbonnement().then((result) => {
        console.log("result of commercial vente :", result)
        var lastCodeFacture = result.data[0]?.facture_code.split("_").pop();
      //  setnumFacture(seq + "" + (Number(lastCodeFacture) + 1));
      });
    });
    // TODO : change to getFacturationFournisseurByCode
    getFacturationClientByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
      }));
      setDataFournisseur(options);
    });

    // TODO : change to prix_achat_ht
    getAllProduct().then((res) => {
      console.log("All Product :", res)
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
        secondValue: d.id,
        thirdValue: d.prix_vente_ht_1,
        fourthValue: d.tva,
        suivieValue: d.suivie_stock,
        gamme_code: d.gamme_code
      }));
      setProducts(options);
    });

    getEntrepriseByCode().then((res) => {
      setNomCompany(res.data.nom);
      setAdresseCompany(res.data.adresse);
      setMobileCompany(res.data.mobile);
      setEmailCompany(res.data.email);
      //setTimbreFiscal(res.data.timbre_fiscale);
      setLogoCompany(res.data.logo);
      setmatriculeFiscal(res.data.matricule_fiscal);
      setwebUrl(res.data.website_url);
      setLigneFacture1(res.data.ligne_facture_1);
      setLigneFacture2(res.data.ligne_facture_2);
      setLigneFacture3(res.data.ligne_facture_3);
      setLigneFacture4(res.data.ligne_facture_4);
    });
  }, []);


  //select fournisseur
  const handleChangeFournisseur = (event) => {
    setFournisseurCode(event.value);
    setFournisseurFullName(event.label);

    // if client selected get
    // get client by id
    getClientById(event.secondValue).then((res) => {
      //console.log('es.data',res.data);
      setclientObject(res.data);
      setNomClient(res.data.nom);
      setPrenomClient(res.data.prenom);
      setEmailClient(res.data.email);
      setFixeClient(res.data.fixe);
      setAdrFacturationClient(res.data.adresse_facturation);
    });
  };

  const handleSelectChange = (e, index) => {
    const { value } = e.target;
    let position = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[position].text;
    const list = [...inputList];
    list[index]["produit_code"] = value;
    list[index]["libelleProduit"] = label;
    list[index]["prixHt"] = parseFloat(products[position - 1].thirdValue);
    list[index]["tvaProduct"] = products[position - 1].fourthValue;
    list[index]["suivie_stock"] = products[position - 1].suivieValue;
    setInputList(list);
  };
  // change dropdown remise Pourcent - Montant
  const handleChangeRemiseEntete = (event) => {
    setactionRemiseEntete(event.target.value);
    setTotalHt(calculateRemise(sousTotalHt, remise, event.target.value).toFixed(3));
    /**
     * detect change block
     */
    let totalHtPrice = getGoupedValue(inputList, event.target.value, remise, sousTotalHt);
    setlistTva(totalHtPrice);
    let sumTva = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTva += Number(totalHtPrice[i].split(" ")[3]);
    }

    let calculateTotalTtc = Number(sumTva) + Number(calculateRemise(sousTotalHt, remise, event.target.value).toFixed(3));
    //var addTimbreFiscale = Number(calculateTotalTtc) + Number(1.000);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(societe.data.timbre_fiscale);
    settotalTTC(addTimbreFiscale.toFixed(3));
  };

  // change entete remise value
  const handleChangeRemiseValue = (event) => {
    //console.log(event.target.value);
    setRemise(event.target.value);
    setTotalHt(calculateRemise(sousTotalHt, event.target.value, actionRemiseEntete).toFixed(3));

    /**
     * detect change block
     */
    let totalHtPrice = getGoupedValue(inputList, actionRemiseEntete, event.target.value, sousTotalHt);
    setlistTva(totalHtPrice);
    let sumTva = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTva += Number(totalHtPrice[i].split(" ")[3]);
    }
    let calculateTotalTtc = Number(sumTva) + Number(calculateRemise(sousTotalHt, event.target.value, actionRemiseEntete).toFixed(3));
    //var addTimbreFiscale = Number(calculateTotalTtc) + Number(1.000);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(societe.data.timbre_fiscale);
    settotalTTC(addTimbreFiscale.toFixed(3));
  };

  const calculateQuantity = (i) => {
    const nombreDeUser = abonnementFields[i]?.nombreDeUser || 0;
    const nombreDeMois = abonnementFields[i]?.nombreDeMois || 0;
    const newQuantity = nombreDeUser * nombreDeMois;

    // Mettez à jour la quantité dans inputList
    setInputList((prevInputList) => {
      const updatedList = [...prevInputList];
      updatedList[i] = { ...updatedList[i], quantite: newQuantity };

      // Calculer le totalHt en utilisant les nouvelles valeurs
      let sum = calculateRemise(
        MultiplicateNumber(updatedList[i]["prixHt"], newQuantity),
        updatedList[i]["remise"],
        updatedList[i]["actionRemise"]
      );
      updatedList[i]["totalHt"] = sum;

      // Mettre à jour l'état avec la nouvelle liste
      setInputList(updatedList);

      /**
       * Bloc de détection des changements
       */
      var sumTotal = 0;
      for (const [key, value] of Object.entries(updatedList)) {
        sumTotal += value.totalHt;
      }
      setsousTotalht(sumTotal.toFixed(3));
      var totalHtValue = calculateRemise(sumTotal, remise, actionRemiseEntete).toFixed(3);
      setTotalHt(totalHtValue);

      let totalHtPrice = getGoupedValue(updatedList, actionRemiseEntete, remise, sousTotalHt);
      setlistTva(totalHtPrice);
      let sumTtc = 0;
      for (let i = 0; i < totalHtPrice.length; i++) {
        sumTtc += Number(totalHtPrice[i].split(" ")[3]);
      }
      let calculateTotalTtc = Number(sumTtc) + Number(totalHtValue);
      var addTimbreFiscale = Number(calculateTotalTtc) + Number(societe.data.timbre_fiscale);
      settotalTTC(addTimbreFiscale.toFixed(3));

      return updatedList;
    });
  };


  // Fonction pour mettre à jour les champs d'abonnement
  // const updateAbonnementFields = (index, fieldName, value) => {
  //   const updatedAbonnementFields = [...abonnementFields];
  //   const updatedFields = [...updatedAbonnementFields];
  //   if (!updatedAbonnementFields[index]) {
  //     updatedAbonnementFields[index] = {};
  //   }

  //   if (fieldName === 'dateDebut' || fieldName === 'dateFin') {
  //     updatedAbonnementFields[index][fieldName] = moment(value).format("YYYY-MM-DD");
  //     setStartDate(updatedAbonnementFields[index]['dateDebut']);
  //     setEndDate(updatedAbonnementFields[index]['dateFin']);
  //   } else if (fieldName === 'nombreDeUser') {
  //     updatedAbonnementFields[index][fieldName] = value;
  //     setNbrUser(value); // Mettez à jour l'état nb_users
  //   } else if (fieldName === 'nombreDeMois') {
  //     updatedAbonnementFields[index][fieldName] = value;
  //     setNbrMonths(value); // Mettez à jour l'état nb_mois
  //   } else {
  //     updatedAbonnementFields[index][fieldName] = value;
  //     setUpdatedAbonnementFields(updatedFields);
  //   }

  //   setAbonnementFields(updatedAbonnementFields);
  // };

  // Fonction pour mettre à jour les champs d'abonnement
  // const updateAbonnementFields = (index, fieldName, value) => {
  //   const updatedAbonnementFields = [...abonnementFields];
  //   const updatedFields = [...updatedAbonnementFields];
  //   if (!updatedAbonnementFields[index]) {
  //     updatedAbonnementFields[index] = {};
  //   }

  //   if (fieldName === 'dateDebut' || fieldName === 'dateFin') {
  //     updatedAbonnementFields[index][fieldName] = moment(value).format("YYYY-MM-DD");
  //     setStartDate(updatedAbonnementFields[index]['dateDebut']);
  //     setEndDate(updatedAbonnementFields[index]['dateFin']);
  //   } else if (fieldName === 'nombreDeUser') {
  //     updatedAbonnementFields[index][fieldName] = value;
  //     setNbrUser(value); // Mettez à jour l'état nb_users
  //     calculateQuantity(index); // Appelez la fonction pour recalculer la quantité
  //   } else if (fieldName === 'nombreDeMois') {
  //     updatedAbonnementFields[index][fieldName] = value;
  //     setNbrMonths(value); // Mettez à jour l'état nb_mois
  //     calculateQuantity(index); // Appelez la fonction pour recalculer la quantité
  //   } else {
  //     updatedAbonnementFields[index][fieldName] = value;
  //     setUpdatedAbonnementFields(updatedFields);
  //   }

  //   setAbonnementFields(updatedAbonnementFields);
  // };


  const updateAbonnementFields = (index, fieldName, value) => {
    const updatedAbonnementFields = [...abonnementFields];
    const newinput = [...inputList];
    const updatedFields = [...updatedAbonnementFields];
    if (!updatedAbonnementFields[index]) {
      updatedAbonnementFields[index] = {};
    }

    if (fieldName === 'dateDebut' || fieldName === 'dateFin') {
      updatedAbonnementFields[index][fieldName] = moment(value).format("YYYY-MM-DD");
      newinput[index][fieldName] = moment(value).format("YYYY-MM-DD");
      if (fieldName === 'dateFin') {
        //calcul nbr des mois 
        const nbrmois = calculateMonthDifference(abonnementFields[index]?.dateDebut || dateDebutContrat, value)
        updatedAbonnementFields[index]['nombreDeMois'] = nbrmois;
        newinput[index]['nbr_mois'] = nbrmois
      } else {
        const nbr_mois = calculateMonthDifference(value, abonnementFields[index]?.dateFin)
        updatedAbonnementFields[index]['nombreDeMois'] = nbr_mois;
        newinput[index]['nbr_mois'] = nbr_mois

      }
      setStartDate(updatedAbonnementFields[index]['dateDebut']);
      setEndDate(updatedAbonnementFields[index]['dateFin']);
    } else if (fieldName === 'nombreDeUser') {
      updatedAbonnementFields[index][fieldName] = value;
      newinput[index]['nbr_users'] = value

      setNbrUser(value); // Mettez à jour l'état nb_users
      calculateQuantity(index); // Appelez la fonction pour recalculer la quantité
    } else if (fieldName === 'nombreDeMois') {
      updatedAbonnementFields[index][fieldName] = value;
      newinput[index]['nbr_mois'] = value

      setNbrMonths(value); // Mettez à jour l'état nb_mois
      calculateQuantity(index); // Appelez la fonction pour recalculer la quantité
    } else {
      updatedAbonnementFields[index][fieldName] = value;
      newinput[index][fieldName] = value
      setInputList(newinput)
      setUpdatedAbonnementFields(updatedFields);
    }
    setInputList(newinput)
    setAbonnementFields(updatedAbonnementFields);
  };


  const handleSaveEntete = () => {

    let sumRemise = 0;
    for (const [key, value] of Object.entries(inputList)) {
      sumRemise += (Number(value.quantite) * Number(value.prixHt)) - Number(value.totalHt);

    }
    var remise_commande = Number(sousTotalHt) - Number(totalHt);
    var remise_total = Number(sumRemise) + remise_commande;
    var tva1 = 0;
    var tva2 = 0;
    var tva3 = 0;
    var tvaPourcent1 = 0;
    var tvaPourcent2 = 0;
    var tvaPourcent3 = 0;

    if (listTva.length === 1) {
      tva1 = listTva[0].split(" ")[3];
      tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
    } else if (listTva.length === 2) {
      tva1 = listTva[0].split(" ")[3];
      tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
      tva2 = listTva[1].split(" ")[3];
      tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
    } else if (listTva.length === 3) {
      tva1 = listTva[0].split(" ")[3];
      tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
      tva2 = listTva[1].split(" ")[3];
      tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
      tva3 = listTva[2].split(" ")[3];
      tvaPourcent3 = listTva[2].split(" ")[2].slice(0, -1);
    } else {
      console.log(t("empty listTva"));
    }

    let montantTotalTva = Number(tva1) + Number(tva2) + Number(tva3);
    let codeEntete = parseInt(Date.now() * Math.random());
    let net_payer = Number(totalTTC);

    if (fournisseurCode === "") {
      toast.error(t("Veuillez choisir un client"));
    } else if (!inputList[0].produit_code) {
      toast.error(t("Veuillez choisir au moins un produit"));
    } else if (!dateFinContrat) {
      toast.error(t("Veuillez choisir la date Fin de contrat"));
    } else if (!numFacture) {
      toast.error(t("Veuillez choisir numéro de facture"));
    } else if (!numCommande) {
      toast.error(t("Veuillez choisir numéro de commande"));
    } else if (checkEmptyPriceQuantity(inputList)) {

      toast.error(t("Veuillez ajouter quantité/prix"));
    } else {
      const entet_data = {
        
        code_abonnement: `Ab_2024_${parseInt(codeabonnement)}`,
        code_commande: numCommande,
        client_code: clientObject.code,
        societe_code: localStorage.getItem('code_generated'),
        date_debut: dateDebutContrat,
        date_fin: dateFinContrat,
        nb_mois: parseInt(frequance),
        frequence_facturation: parseInt(frequanceFacturation),
        Delais_rappel: parseInt(delais),
        remise: parseFloat(remise),
        sous_total_ht: parseFloat(sousTotalHt),
        totalHt: parseFloat(totalHt),
        created_at: moment(new Date).format("YYYY-MM-DD"),
      }
     console.log('inputListinputList',inputList[0])
      const details = inputList.map(item => {
        delete item.libelleProduit; 
        delete item.suivie_stock
        return {
          ...item,
          code_abonnement: entet_data.code_abonnement,
          created_at: entet_data.created_at,
          actionRemise :  item.actionRemise ,
          dateDebut: item.dateDebut === '' ? entet_data.date_debut : item.dateDebut,
        };
      });
      

      const data = { entet: entet_data, detail: details }
      ajoutAbonnement(data).then((res) => {
        history.push("/admin/listabonnement");
      })
    }

    /*else {
      var seq = "";
      var numFactureValue = "";
      getSequenceByCode().then((res) => {
        seq = res.data.seq_abonnement;
        getEnteteCommercialVenteAbonnement().then((result) => {
          var lastCodeFacture = result.data[0].facture_code.split("_").pop();
          numFactureValue = seq + "" + (Number(lastCodeFacture) + 1);
          console.log("7777777777", numFactureValue)
          setnumFacture(numFactureValue);

          addEntete(
            codeEntete,
            numCommande,
            numFactureValue,
            "vente",
            numFactureValue,
            "abonnement",
            fournisseurCode,
            nomClient + " " + prenomClient,
            moment(dateDebutContrat).format("YYYY-MM-DD"),
            tvaPourcent1,
            tva1,
            tvaPourcent2,
            tva2,
            tvaPourcent3,
            tva3,
            societe.data.timbre_fiscale,
            totalHt,
            totalTTC,
            montantTotalTva,
            net_payer,
            remise_total,
            remise_commande,
            actionRemiseEntete,
            frequance,
            adrFacturationClient,
            moment(dateFinContrat).format("YYYY-MM-DD"),
            noteFacture
          ).then(() => {
            var remise_totale_ligne = 0;

            for (let i = 0; i < inputList.length; i++) {
              (function (index) {
                const value = inputList[index];
                remise_totale_ligne += Number(value.qte) * Number(value.prixHt) - Number(value.totalHt);

                addLigne(
                  codeEntete,
                  value.codeProduit,
                  value.qte,
                  value.prixHt,
                  calulateTva(value.totalHt, value.tvaProduct, actionRemiseEntete, remise, sousTotalHt),
                  value.tvaProduct,
                  calculateHt(value.totalHt, actionRemiseEntete, remise, sousTotalHt),
                  remise_totale_ligne,
                  remise_commande,
                  value.libelleProduit,
                  value.actionRemise,
                  value.remise,
                  value.suivie_stock,
                ).then(() => {
                  addMouvement(
                    value.codeProduit,
                    value.qte,
                    value.prixHt,
                    margePercentage(value.totalHt, value.tvaProduct),
                    value.tvaProduct,
                    "vente",
                    codeEntete
                  ).then(() => {
                    decrementStockQauntity(value.codeProduit, Number(value.qte)).then(() => {
                      var selectedProduct = products.find(product => product.value === value.codeProduit);
                      var gammeCode = selectedProduct ? selectedProduct.gamme_code : null;

                      if (gammeCode === "abonnement") {
                        // var dateDebut = updatedAbonnementFields[index]?.dateDebut;
                        // var dateFin = updatedAbonnementFields[index]?.dateFin;
                        var dateDebut = abonnementFields[index]?.dateDebut;
                        var dateFin = abonnementFields[index]?.dateFin;
                        var nombreDeMois = abonnementFields[index]?.nombreDeMois;
                        var nombreDeUser = abonnementFields[index]?.nombreDeUser;

                        addAbonnement(
                          numCommande,
                          frequanceFacturation,
                          fournisseurCode,
                          moment(dateFin).format("YYYY-MM-DD"),
                          moment(dateDebut).format("YYYY-MM-DD"),
                          nombreDeMois,
                          nombreDeUser,
                          value.codeProduit,
                          numFacture,
                          "abonnement"
                        ).then(() => {
                          history.push("/admin/listabonnement");
                        });
                      } else {
                        history.push("/admin/listabonnement");
                      }
                    });
                  });
                });
              })(i);
            }
          });
        });
      });
    }*/
  };

  const handleShowFacture = () => {
    var remiseLigne = inputList
      .map((o) => o.remise)
      .reduce((a, c) => {
        return a + c;
      });
    var isRemise = false;
    if (remiseLigne > 0) {
      isRemise = true;
    }

    setobjectToPdf({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: numFacture,
      commande_no: numCommande,
      balance: "$2,283.74",
      company: "MANTRIX",
      email: "susanafuentes@mantrix.com",
      phone: "+1 (872) 588-3809",
      address: "922 Campus Road, Drytown, Wisconsin, 1986",
      title: "Facture",
      trans_date: moment(selectedDateToday).format("YYYY-MM-DD"),
      due_date: moment(selectedDateDeadline).format("YYYY-MM-DD"),
      sender_name: nomCompany,
      sender_adr: adresseCompany,
      sender_email: emailCompany,
      sender_mobile: mobileCompany,
      sender_logo: logoCompany,
      matricule_fiscal: matriculeFiscal,
      website_url: webUrl,
      receiver_name: nomClient,
      receiver_prenom: prenomClient,
      receiver_adr: adrFacturationClient,
      receiver_email: emailClient,
      receiver_mobile: fixeClient,
      sous_total_ht: sousTotalHt,
      remise: remise,
      timbre_fiscale: societe.data.timbre_fiscale.toFixed(3),
      //timbreFiscale:"1.000",
      totalHt: totalHt,
      totalTTC: totalTTC,
      note: noteFacture,
      footer: footerFacture,
      items: inputList,
      tva: listTva,
      facutre_media_entete: imageObjectEntete,
      facutre_media_pied: imageObjectPied,
      ligne_facture_1: LigneFacture1,
      ligne_facture_2: LigneFacture2,
      ligne_facture_3: LigneFacture3,
      ligne_facture_4: LigneFacture4,
      isRemise: isRemise,
      client: clientObject,
      actionRemise: actionRemiseEntete,
      dateDebut: dateDebut,
      dateFin: dateFin,
      nb_users: nb_users,
      nb_mois: nb_mois
    });
    setShowResults(!showResults);
  };



  let saidHello = false;
  function sendByEmail(blob) {
    if (blob) {
      sendFactureBlob(blob, emailClient, "Devis").then((res) => {
        console.log(res);
      });
      saidHello = true;
    }
  }

  function handleSendPdf(blob) {
    if (!saidHello) sendByEmail(blob);
  }
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Abonnement vente")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addclient" className="btn btn-primary mr-3">
                {t("Ajouter client")}
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                {t("Ajouter produit")}
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header  border-0">
            <h4 className="card-title">{t("Ajouter abonnement de vente")}</h4>
          </div>
          <div className="card-body">
            <div className="content m-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Client")} : {fournisseurFullName}
                          <span className="text-danger">*</span>
                        </label>
                        <Select onChange={handleChangeFournisseur} options={dataFournisseur} />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <label>
                        {t("Durée de contrat en mois")}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        {/* <div className="input-group-prepend">
                          <select defaultValue="1" className="form-control btn btn-outline-secondary" onChange={handleChangeRemiseEntete}>
                            <option value="1">{t("Pourcent")}</option>
                            <option value="2">{t("Montant")}</option>
                          </select>
                        </div> */}
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          value={frequance}
                          //onChange={handleChangeRemiseValue}
                          onChange={handleChangeFrequance}
                          onKeyPress={handleKeyPress_number}

                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Fréquence de facturation en mois")}<span className="text-danger">*</span>
                        </label>
                        <input className="form-control"
                          type="text"
                          onChange={handleChangeFrequanceFacturation}
                          value={frequanceFacturation}
                          onKeyPress={handleKeyPress_number}

                          required />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Délais de rappel en jours")}<span className="text-danger">*</span>
                        </label>
                        <input className="form-control"
                          type="text"
                          onChange={handleChangeDelais}
                          value={delais}
                          onKeyPress={handleKeyPress_number}

                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Numéro abonnement")}<span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" value={numFacture} readOnly />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Numéro de commande")}<span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" onChange={(e) => setnumCommande(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date début contrat")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={dateDebutContrat}
                            onChange={handleChangedate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date Fin")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={dateFinContrat}
                            onChange={handleChangedateFin}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row" style={{ marginTop: 20 }}>
              {JSON.stringify(inputList)}
            </div> */}
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-responsive" id="mycustomtable">
                        <table className="table table-hover table-white">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t("Choisir produit")}</th>
                              <th>{t("Libelle")}</th>
                              <th>{t("Quantité")}</th>
                              <th>{t("Prix HT")}</th>
                              <th>{t("Remise")}</th>
                              <th>{t("Total HT")}</th>
                              <th>{t("TVA")} %</th>
                              <th> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputList.map((x, i) => {
                              // Recherchez le produit correspondant à la valeur de x dans la liste des produits
                              const selectedProduct = products.find((product) => product.value === x.produit_code);
                              return (
                                <tr key={i}>
                                  <td>{i}</td>
                                  <td style={{ minWidth: 200, zIndex: 99 }}>

                                    <select className="form-control" onChange={(e) => handleSelectChange(e, i)} style={{ marginTop: "-12px" }}>
                                      <option value={"default"}>{t("Choisir produit")}</option>
                                      {products
                                        .filter((product) => (showAllProducts || product.gamme_code === "abonnement") && (showAbonnementProducts || product.type !== "abonnement"))
                                        .map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </td>
                                  <td style={{ minWidth: 500, width: 450 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="libelleProduit"
                                      // value={x.libelleProduit || (selectedProduct ? selectedProduct.label : "")}
                                      value={(showAllProducts || (showAbonnementProducts && selectedProduct && selectedProduct.gamme_code === "abonnement")) ? x.libelleProduit : (selectedProduct ? selectedProduct.label : "")}
                                      onChange={(e) => handleInputChange(e, i)}
                                      style={{ marginTop: "48px" }}
                                    />
                                    <td >
                                      {selectedProduct && selectedProduct.gamme_code === "abonnement" && (
                                        <>
                                          <td>
                                            {/* <label>Date de début:</label> */}
                                            <input
                                              className="form-control"
                                              type="date"
                                              name="dateDebut"
                                              value={abonnementFields[i]?.dateDebut || moment(dateDebutContrat).format("YYYY-MM-DD")}
                                              onChange={(e) => updateAbonnementFields(i, 'dateDebut', moment(e.target.value).format("YYYY-MM-DD"))}
                                              style={{ width: "150px", marginLeft: "-8px" }}
                                            />
                                          </td>
                                          <td>
                                            {/* <label>Date de fin:</label> */}
                                            <input
                                              className="form-control"
                                              type="date"
                                              name="dateFin"
                                              placeholder="date Fin "
                                              value={abonnementFields[i]?.dateFin || ''}
                                              onChange={(e) => updateAbonnementFields(i, 'dateFin', moment(e.target.value).format("YYYY-MM-DD"))}
                                              style={{ width: "150px", marginLeft: "-8px" }}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="nombreDeUser"
                                              value={abonnementFields[i]?.nombreDeUser || ''}
                                              onChange={(e) => updateAbonnementFields(i, 'nombreDeUser', parseInt(e.target.value))}
                                              style={{ width: "150px" }}
                                              placeholder="Nbr user"
                                              onKeyPress={handleKeyPress_number}

                                            />
                                          </td>
                                          <td>
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="nombreDeMois"
                                              value={abonnementFields[i]?.nombreDeMois || ''}
                                              onChange={(e) => updateAbonnementFields(i, 'nombreDeMois', parseInt(e.target.value))}
                                              style={{ width: "150px", marginRight: "-7px" }}
                                              placeholder="Nbr mois"
                                              onKeyPress={handleKeyPress_number}

                                            />
                                          </td>
                                        </>
                                      )}
                                    </td>
                                  </td>
                                  <td style={{ minWidth: 100, width: 100 }}>
                                    <input className="form-control" type="number" name="quantite" value={x.quantite} onChange={(e) => handleInputChange(e, i)} style={{ marginTop: "40px" }} />

                                  </td>

                                  <td style={{ minWidth: 150, width: 200 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="prixHt"
                                      value={x.prixHt}
                                      onChange={(e) => handleInputChange(e, i)}
                                      style={{ marginTop: "40px" }}
                                      onKeyPress={handleKeyPress}
                                    />
                                  </td>
                                  <td style={{ minWidth: 250, width: 200 }}>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <select
                                          defaultValue="1"
                                          className="form-control btn btn-outline-secondary"
                                          name="actionRemise"
                                          value={x.actionRemise}
                                          onChange={(e) => handleInputChange(e, i)}
                                          style={{ marginTop: "40px" }}
                                        >
                                          <option value="1">{t("Pourcent")}</option>
                                          <option value="2">{t("Montant")}</option>
                                        </select>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        aria-label="Text input with dropdown button"
                                        name="remise"
                                        value={x.remise}
                                        onChange={(e) => handleInputChange(e, i)}
                                        style={{ marginTop: "40px" }}
                                        onKeyPress={handleKeyPress}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ minWidth: 150, width: 200 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      name="totalHt"
                                      value={x.totalHt}
                                      onChange={(e) => handleInputChange(e, i)}
                                      style={{ marginTop: "40px" }}
                                    />
                                  </td>
                                  <td style={{ minWidth: 100, width: 200 }}>
                                    <input className="form-control" readOnly type="text" value={x.tvaProduct + " %"} style={{ marginTop: "40px" }} />
                                  </td>
                                  <td>
                                    {inputList.length - 1 === i && (
                                      <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                                        <i className="fa fa-plus" />
                                      </Link>
                                    )}
                                    {inputList.length !== 1 && (
                                      <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                                        <i className="fa fa-minus" />
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>

                        </table>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover table-white">
                          <tbody>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td className="text-right">{t("Sous Total HT")}</td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                {convertToDigit(sousTotalHt)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="text-right">
                                {t("Remise")} {actionRemiseEntete === "1" ? "%" : ""}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                <input readOnly className="form-control text-right" type="text" value={remise} />
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td className="text-right">{t("Total HT")}</td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: 30,
                                  width: 230,
                                }}
                              >
                                {convertToDigit(totalHt)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="text-right"></td>
                              <td colSpan={5} className="text-right">
                                {listTva.map(function (d, idx) {
                                  return (
                                    <li key={idx}>
                                      <strong>
                                        {d.split("%")[0]}% {convertToDigit(d.split("%")[1])}
                                      </strong>
                                    </li>
                                  );
                                })}
                              </td>
                            </tr>


                          </tbody>
                        </table>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{t("Ajouter note")}</label>
                            <textarea onChange={(e) => setNoteFacture(e.target.value)} className="form-control" defaultValue={""} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row no-print mt-4">
                    <div className="col-12">
                      {inputList[0]?.quantite && (
                        <>
                          <button type="button" className="btn btn-primary float-right" onClick={handleSaveEntete}>
                            <i className="fa fa-credit-card" /> {t("Enregistrer")}
                          </button>
                          <button type="button" className="btn btn-info float-right" style={{ marginRight: 5 }} onClick={handleShowFacture}>
                            <i className="fa fa-file-pdf-o" /> {t("Afficher facture")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      {showResults ? (
                        <>
                          <PDFViewer style={{ width: "100%", height: "800px" }}>
                            <MyDocument invoice={objectToPdf} />
                          </PDFViewer>
                        </>
                      ) : null}
                      {showResultsEmail ? (
                        <>
                          <BlobProvider document={<MyDocument invoice={objectToPdf} />}>
                            {({ blob, url, loading, error }) => {
                              // Do whatever you need with blob here
                              return <>{handleSendPdf(blob)}</>;
                            }}
                          </BlobProvider>
                          <PDFViewer style={{ width: "100%", height: "800px" }}>
                            <MyDocument invoice={objectToPdf} />
                          </PDFViewer>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AjouterAbonnement;
