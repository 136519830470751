import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getCongeByUserCodeId } from "../../../Services/Pointeuse/CongeApi";
import { getHolidayByCode } from "../../../Services/Pointeuse/HolidayApi";
import { filterCongeByYear, filterYearAutorisation } from "../../../Utils/userDetaiUtils";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import { getjour_ferire } from "../../../Services/Pointeuse/EntrepriseApi";

function PieApexHoliday() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const arrayFilter = [];
    getjour_ferire()
      .then((res) => {
        if (res) {
          res?.data?.data.map((item) => {
            arrayFilter.push(item.type);
          });
          const obj = arrayFilter.reduce((prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev), {});
          let chartOptions = {
            series: [obj.CNP, obj.CP],
            options: {
              chart: {
                // width: 300,
                type: "polarArea",
                sparkline: {
                  enabled: true,
                },
              },
              labels: ["CNP", "CP"],
              fill: {
                opacity: 1,
                colors: ["#F7284A", "#0DCD94"],
              },
              stroke: {
                width: 0,
                colors: undefined,
              },
              yaxis: {
                show: false,
              },
              legend: {
                position: "bottom",
              },
              plotOptions: {
                polarArea: {
                  rings: {
                    strokeWidth: 0,
                  },
                },
              },
              theme: {
                monochrome: {
                  enabled: true,
                  shadeTo: "light",
                  shadeIntensity: 0.6,
                },
              },
            },
          };
          setData(chartOptions);
          setLoading(false);
        }
      })
      .catch((error) => setError(error));
  }, []);
  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="polarArea"
          height={251}
          // width={300}
        />
      )}
    </>
  );
}

export default PieApexHoliday;
