import React, { useEffect, useState } from "react";


import { t } from "i18next";
import { addDemandeClient } from "../../Services/taches/TachesAPI";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Link, useHistory } from "react-router-dom";


function CreeTaches() {
    let { clientCode } = useParams();
    const history = useHistory()
    const [data, setData] = useState({
        code_client_externe: clientCode,
        societe: '',
        titre: '',
        demande: '',
        priority: 0,
        code_generated: localStorage.getItem('code_generated'),
        file: ''
    });

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleFileChange = (e) => {
        setData({ ...data, file: e.target.files[0] });
    };

    const handleSave = async (e) => {
        e.preventDefault(); // Prevent default form submission
        //console.log("data", data)
        const formData = new FormData();
         alert(data.file)
        if (data.file&&data.file !== '') {
            const fileExtension = data.file.name.slice((data.file.name.lastIndexOf(".") - 1 >>> 0) + 2);
            const newFileName = `demande_${data.societe}_${Date.now()}.${fileExtension}`;
            formData.append('file', data.file, newFileName);
        }
        formData.append('societe', data.societe);
        formData.append('titre', data.titre);
        formData.append('demande', data.demande);
        formData.append('priority', data.priority);
        formData.append('code_generated', data.code_generated);
        formData.append('code_client_externe', data.code_client_externe);




        try {
            const resp = await addDemandeClient(formData);
            if (resp.status === 200) {
                setData({
                    societe: '',
                    titre: '',
                    demande: '',
                    file: ''
                });
                toast.success('données enregistrés ')
                history.push(`/ViewClientTaches/${clientCode}`)
            } else {
                toast.error('un probléme')
            }


        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return (

        <>
        <div className="page-header d-xl-flex d-block">
            <div className="page-leftheader">
                <h4 className="page-title">{t("Créer vos demande")}</h4>
            </div>
            <div className="page-rightheader ml-md-auto">
                <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div className="btn-list">
                        <Link to={`/ViewClientTaches/${clientCode}`} className="btn btn-primary mr-3" data-toggle="modal" data-target="#newtaskmodal">
                            {t("Liste des Demandes")}
                        </Link>

                    </div>
                </div>
            </div>
        </div>
        <section>
                <div className="sptb">
                    <div className="container" style={{ background: "white" }}>
                        <div className="section-title center-block text-center">
                            <form onSubmit={handleSave}>
                                <div className="row" style={{ paddingTop: '50px' }}>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{t("Societe")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={data.societe}
                                                onChange={(e) => handleChange('societe', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t("Titre")}<span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={data.titre}
                                                onChange={(e) => handleChange('titre', e.target.value)}
                                                required // Add required attribute here
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t("Priorité")}:<span className="text-danger">*</span></label>
                                            <select
                                                name="attendance"
                                                className="form-control custom-select select2"
                                                data-placeholder={t("Choisir priorité")}
                                                required
                                                onChange={(e) => handleChange('priority', e.target.value)}
                                                value={data.priority}
                                            >
                                                <option value={0}>0-Aucune priorité</option>
                                                <option value={1}>1-Priorité la plus élevée</option>
                                                <option value={2}>2-Priorité élevée</option>
                                                <option value={3}>3-Priorité moyenne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{t("Demande")}<span className="text-danger">*</span></label>
                                            <textarea
                                                rows={3}
                                                className="form-control"
                                                value={data.demande}
                                                onChange={(e) => handleChange('demande', e.target.value)}
                                                required // Add required attribute here
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="panel panel-default">
                                        <div className="panel-body">
                                            <h4>{t("Sélectionnez fichier")}</h4>
                                            <div className="form-group files">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={handleFileChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <button type="submit" className="btn btn-primary">
                                        {t("Enregistrer")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section></>
    );
}

export default CreeTaches;


