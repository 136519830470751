import { Howl } from "howler";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { getSession, isLogin } from "../../Utils/SessionUtils";
import ImageComponent from "../Styles/ImageComponent";
import socket from "../../Connection/ws";
import toast from "react-hot-toast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

/* This is the sound that will be played when a notification is received. */
var notifSound = new Howl({
  src: ["./assets/sounds/notif.mp3"],
  loop: false,
  preload: true,
});

/**
 * A function that is called when a pointage is generated.
 */
export function PointageNotification() {
  socket.on("pointage", (result) => {
    if (isLogin() && result.code_generated == getSession("code_generated")) {
      notifSound.play();
      getUserById(result.user_code_id).then((res) => {
        res.data.id &&
          toast.custom(
            (t) => (
              <div className="" style={{ width: 500, borderRadius: "60px", backgroundColor: "#333" }}>
                <div className="p-3">
                  <div className="d-flex align-items-center mt-auto" style={{ float: "left" }}>
                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={res.data.img}></ImageComponent>
                    <div>
                      <a href="#" className="font-weight-semibold">
                        {res.data.nom} {res.data.prenom}
                      </a>
                      <small className="d-block text-muted fa-12">Pointage : {result.date}</small>
                    </div>
                  </div>
                  <div className="border-l border-gray-200 mb-4" style={{ float: "right" }}>
                    <a href="#" onClick={() => toast.dismiss(t.id)} class="btn btn-pill btn-light">
                      voir
                    </a>
                  </div>
                </div>
              </div>
            ),
            { duration: 5000 }
          );
      });
    }
  });
}
