import React, { useEffect, useRef } from "react";
import { Page, Text, View, Document, Image, StyleSheet } from "@react-pdf/renderer";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import BillFrom from "./BillFrom";
import InvoiceLettrePrice from "./InvoiceLettrePrice";
import { getAllSettings } from "../../../Services/Pointeuse/SettingsApi";
import BillTo from "./BillTo";
import moment from "moment";
import ReactToPrint from "react-to-print";
// import Logo2 from "./849450.jpg"
// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 20,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
    direction: 'rtl',
  },
  logo2: {
    width: 93,
    //height: 93,
    marginTop: -20,
  },
  logo: {
    width: 113,
    //height: 113,
    marginTop: -20,
  },
  entete: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
  },
  entete1: {
    flexDirection: "row",
  },
  enteteRow: {
    width: 100,
  },
  enteteRow2: {
    marginTop: 30,
    width: 100,
  },
  enteteRow3: {
    marginTop: 26,
    width: 100,
  },
  certImage: { width: 250 },
  invoiceTitle: {
    color: "#000",
    marginTop: 5,
    fontSize: 15,
    fontStyle: "bold",
  },
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 20,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
    direction: "rtl",
  },
});

function MyDocument({ data, inputList, t }) {
  const componentRef = useRef(null);

  return (
    <>
    <ReactToPrint
      trigger={() => (
        <button
          className="btn btn-sm btn-primary btn-primary-app"
        >
          <i className="fas fa-print fa-lg"></i>telecharger
        </button>
      )}
      content={() => componentRef.current} />
      <div className="ml-5 mt-5" ref={componentRef} >

        <div className="row">
          <div className="col-sm-3">
            <div className="form-group">
              <strong>{t("Numéro d'abonnement")}:</strong>
              {' '} {data.code_abonnement}
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <strong>{t("Numéro de commande")}:</strong>
              {' '}  {data.code_commande}

            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <strong>
                {t("Date Début")}:
              </strong>
              <div className="cal-icon">
                {moment(data.date_debut).format("YYYY-MM-DD")}
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <strong>
                {t("Date Fin")}:
              </strong>
              <div className="cal-icon">
                {moment(data.date_fin).format("YYYY-MM-DD")}

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="form-group">
              <strong>{t("Client")} : </strong>{' '}{data.client_name}
            </div>
          </div>

          <div className="col-sm-3">
            <strong>{t("Fréquence")}</strong>{' '}
            {data.nb_mois}
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <strong>
                {t("Fréquence de facturation")}{' '}
              </strong>
              {data.frequence_facturation}
            </div>

          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <strong>
                {t("Délais de rappel ")}{' '}
              </strong>
              {data.Delais_rappel} jours
            </div>
          </div>
        </div>

        <div className="row "style={{ width: "100cm", minHeight: "29.7cm", backgroundColor: "#fff", padding: "2cm" }}>
          <div className="table-responsive" id="mycustomtable">
            <table className="table table-hover table-white">
              <thead>
                <tr>
                  <th>{t(" code  produit")}</th>
                  <th>{t("Libelle")}</th>
                  <th>{t('date debut')}</th>
                  <th>{t('date fin')}</th>
                  <th>{t('nmbr user')}</th>
                  <th>{t('nmbr mois')}</th>
                  <th>{t("Quantité")}</th>
                  <th>{t("Prix HT")}</th>
                  <th>{t("type Remise")}</th>
                  <th>{t("montant Remise")}</th>

                  <th>{t("Total HT")}</th>
                  <th>{t("TVA")} %</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {inputList.map((x, i) => {
                  return (

                    <tr key={i}>
                      <td style={{ minWidth: 100, width: 99 }}>
                        {x.produit_code}
                      </td>
                      <td style={{ minWidth: 200, width: 200 }}>
                        {x.libelleProduit}
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        {moment(x.dateDebut).format("YYYY-MM-DD")}
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        {moment(x.dateFin).format("YYYY-MM-DD")}


                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        {x.nbr_users}
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        {x.nbr_mois}
                      </td>




                      <td style={{ minWidth: 70, width: 70 }}>
                        {x.quantite}
                      </td>
                      {/* <td>
                            <input
                                className="form-control"
                                type="number"
                                name="num_serie"
                                value={x.num_serie}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                               
                            </td> */}
                      <td style={{ minWidth: 100, width: 100 }}>
                        {x.prixHt}
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            {x.actionRemise === 1 ? 'Pourcent' : 'Montant'}

                          </div>
                        </div>
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            {x.remise}

                          </div>
                        </div>
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        {x.totalHt}
                      </td>
                      <td style={{ minWidth: 100, width: 100 }}>
                        <input className="form-control" readOnly type="text" value={x.tvaProduct + " %"} />
                      </td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div></>
  );
}

export default MyDocument;
/*<div className="col-sm-3">
                    <div className="form-group">
                        <strong>
                          {t("Délais de rappel de jours")}{' '}
                        </strong>
                        {data.Delais_rappel}
                      </div>
                    </div>*/