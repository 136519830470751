import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { getPointageByCode } from "../../Services/Pointeuse/PointageApi";
import { Link } from "react-router-dom";

export default function CustumMapLeaflet(props) {
  const [markers, setmarkers] = useState([]);
  const [data, setdata] = useState([]);

  useEffect(() => {
    getPointageByCode().then((res) => {
      setdata(res.data);
      console.log(res.data);
      let listMap = [];
      res.data.forEach((element) => {
        listMap.push([element.latitude_arrive, element.longitude_arrive]);
      });
      setmarkers(listMap);
    });
  }, []);

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div id="mapid_2" className="half-map">
      <MapContainer
        style={{ height: "64vh", width: "100%" }}
        center={[35.794542, 9.452172]}
        zoom={7}
        maxZoom={18}
        minZoom={5}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers.map((position, idx) => (
          <Marker key={`marker-${idx}`} position={position}>
            <Popup>
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-5">
                    <div className="tooltip-img">
                      <Link to="#">
                        <img
                          src={
                            data[idx]?.img == null || data[idx]?.img == ""
                              ? "assets/images/users/avatar.png"
                              : localStorage.getItem("baseUrl") + data[idx]?.img
                          }
                          alt
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="tooltip-content">
                      <h3 className="tooltip-content-title">
                        <Link to={"/admin/users/" + data[idx]?.id} className="title ml-2">
                          {data[idx]?.nom} {data[idx]?.prenom}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}

        {/* <Marker position={position}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker> */}
      </MapContainer>
      ,
    </div>
  );
}
