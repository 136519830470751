import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAllDemandeCongeByCode,
  getAllNonValideCongeByCode,
  getAllValideCongeByCode,
} from "../../Services/Pointeuse/CongeApi";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import AddConge from "./AddConge";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import { getExercice } from "../../Services/Pointeuse/EntrepriseApi";

function CongeCardHeader(props) {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [valides, setvalides] = useState(null);
  const [nonValides, setnonValides] = useState(null);
  const [totales, settotales] = useState(null);
  const [demandes, setdemandes] = useState(null);
  const [exercice, setExercice] = useState([]);
  const [selectedexercice, setselectedExercice] = useState();

  useEffect(() => {
    getExercice().then((res) => {
      if (Array.isArray(res?.data)) {
        setExercice(res.data);
        setselectedExercice(res.data[0].id)
       if (props?.setexercice )  props?.setexercice(res.data[0])

      }
    })
  }, [])



  useEffect(() => {
    
    const findexercice = exercice.find(item=>item.id===Number(selectedexercice))
    if (props?.setexercice )  props?.setexercice(findexercice)

    if(findexercice){
      getAllValideCongeByCode(findexercice.code_exercice).then((res) => {
        setvalides(res.data);
      });
  
      getAllDemandeCongeByCode(findexercice.code_exercice).then((res) => {
        setdemandes(res.data);
        

      });
  
      getAllNonValideCongeByCode(findexercice.code_exercice).then((res) => {
        setnonValides(res.data);
      });
    }
   
  }, [selectedexercice]);

  /**
   * It adds three numbers together.
   * @param value1 - The first value to be added.
   * @param value2 - The second parameter.
   * @param value3 - The third parameter.
   * @returns Nothing.
   */
  const sum = (value1, value2, value3) => {
    return value1 + value2 + value3;
  };

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Congé")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#addCongeModal">
                {t("Ajouter congé")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-0 pb-0 d-flex justify-content-center">
                <div className="col-md-6 col-lg-2 text-center py-5">
                <h5 className="mb-0 mt-3">{t("Exercice")}</h5>

                  <select className="form-control" value={selectedexercice} onChange={(e) => setselectedExercice(e.target.value)}>
                    {exercice && exercice?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.code_exercice}
                      </option>
                    ))}
                    </select>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">
                    {sum(valides?.length, nonValides?.length, demandes?.length)}
                  </span>
                  <h5 className="mb-0 mt-3">{t("Total congés")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">{valides?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total validés")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">{nonValides?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total non validés")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">{demandes?.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total demandes")}</h5>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">From:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">To:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Job Type:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Job Type"
                        >
                          <option label="Select Job Type" />
                          <option value={1}>Full-Time</option>
                          <option value={2}>Part-Time</option>
                          <option value={3}>Freelancer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Status:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Status"
                        >
                          <option label="Select Status" />
                          <option value={1}>Active</option>
                          <option value={2}>InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <AddConge getData={props.getData} exercice={selectedexercice} />
    </>
  );
}

export default CongeCardHeader;
