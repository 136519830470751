import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Link } from "react-router-dom";

function CustomOption({ handleSendMsg, handleChangeWy }) {
  return (
    <>
      <Link className="main-msg-send mt-1 text-success" data-toggle="tooltip" title="Envoyer message" to="#" onClick={() => handleSendMsg()}>
        <span className="feather feather-send" />
      </Link>
      <Link className="main-msg-send  ml-2 text-danger" to="#" data-toggle="tooltip" title="simple message" onClick={() => handleChangeWy()}>
        <span className="fa fa-pencil" />
      </Link>
    </>
  );
}

export default CustomOption;
