import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";

function SectionThree() {
  return (
    <>
      <div className="home__hero-section darkBg">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">POURQOUI POINTEUSE ?</div>
                <h1 className="heading">Pointage basé sur la reconnaissance faciale</h1>
                <p className="home__hero-subtitle">
                  L’application Checkin doit être capable d’apprendre des visages des collaborateurs par le biais d’une
                  caméra d'un smartphone et de les stocker dans sa base pour pouvoir les reconnaître à tout moment.À la
                  reconnaissance d’un visage d’un employé elle doit tout de suite l’associer à son identitée personnelle
                  et professionnelle. Elle doit aussi comptabiliser l’heure de son passage à l’occasion d’une entrée ou
                  d’une sortie
                </p>
                {/* <Link to="/sign-up">
                  <Button buttonSize="btn--wide" buttonColor="blue">
                    ext
                  </Button>
                </Link> */}
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src="assets/images/welcome/svg-5.svg" alt="pointeuse" className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionThree;
