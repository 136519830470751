import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    direction: 'rtl',
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  invoiceDate: {
    fontSize: 11,
    fontStyle: "bold",
    marginLeft: 30,
  },

  label: {
    width: 120,
  },
  ToStyle: {
    marginLeft: 30,
    justifyContent: "center",
    alignItems: "center",
  },
});

const InvoiceNo = ({ invoice,dateDebut }) => {
    return(
  <Fragment>
    {invoice.title==="Facture" && 
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>{t("Numéro de facture")}:</Text>
      <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
      
    </View>
    }
   {invoice.title==="Bon de livraison" && <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>{t("N° de Bon de Commande")}:</Text>
      <Text style={styles.invoiceDate}>{invoice.commande_no}</Text>
      {/* <Text style={styles.ToStyle}>{invoice.receiver_adr}</Text> */}
    </View>}
   {invoice.title==="Devis" && <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>{t("N° de Devis")}:</Text>
      <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
      {/* <Text style={styles.ToStyle}>{invoice.receiver_adr}</Text> */}
    </View>}
    <View style={styles.invoiceDateContainer}>
  {invoice.title === "Facture" ? (
    <Text style={styles.label}>{t("Date Facture")}: </Text>
  ) : invoice.title === "Devis" ? (
    <Text style={styles.label}>{t("Date Devis")}: </Text>
  ) : (
    <Text style={styles.label}>{t("Date BL")}: </Text>
  )}
  <Text style={styles.invoiceDate}>{invoice.trans_date}</Text>
</View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>{t("Date d'echéance")}: </Text>
      <Text style={styles.invoiceDate}>{invoice.due_date}</Text>
      {/* <Text style={styles.ToStyle}>{invoice.receiver_email}</Text> */}
    </View>
  </Fragment>
  );
};

export default InvoiceNo;
