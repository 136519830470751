import React, { useEffect, useState } from 'react';
import Card from './Card';
import './DepartementSupport.css'; // Importez le fichier CSS pour les styles
import Table from "./Table";
import { addSupport, getSupportByProjet } from '../../Services/SupportDepartment/SupportApi';
import { toast } from 'react-toastify';
import ClientModal from './ClientModal';

function DepartementSupport() {
  const [activeCard, setActiveCard] = useState(null);
  
  const [savedRows, setSavedRows] = useState({
    Nomadis: [],
    Merbiss: [],
    Clegess: [],
  });
  
  const [cardData, setCardData] = useState({
    Nomadis: [
      {
        client: '',
        nombre_user: '',
        projet: '',
        janvier: '',
        fevrier: '',
        mars: '',
        avril: '',
        mai: '',
        juin: '',
        juillet: '',
        aout: '',
        septembre: '',
        octobre: '',
        novembre: '',
        decembre: '',
        annee: '',
      },
    ],
    Merbiss: [
      {
        client: '',
        nombre_user: '',
        projet: '',
        janvier: '',
        fevrier: '',
        mars: '',
        avril: '',
        mai: '',
        juin: '',
        juillet: '',
        aout: '',
        septembre: '',
        octobre: '',
        novembre: '',
        decembre: '',
        annee: '',
      },
    ],
    Clegess: [
      {
        client: '',
        nombre_user: '',
        projet: '',
        janvier: '',
        fevrier: '',
        mars: '',
        avril: '',
        mai: '',
        juin: '',
        juillet: '',
        aout: '',
        septembre: '',
        octobre: '',
        novembre: '',
        decembre: '',
        annee: '',
      },
    ],
  });

 
  
  useEffect(() => {
    const fetchData = async () => {
      try {

        if(activeCard){
          var data = await getSupportByProjet(activeCard);
         data = data.length===0 ? [
          {
            client: '',
            nombre_user: '',
            projet: '',
            janvier: '',
            fevrier: '',
            mars: '',
            avril: '',
            mai: '',
            juin: '',
            juillet: '',
            aout: '',
            septembre: '',
            octobre: '',
            novembre: '',
            decembre: '',
            annee: '',
          }
         ] : data;
        setCardData({ ...cardData, [activeCard]: data });

        }
        

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [activeCard]); // Fetch data whenever activeCard changes
  const handleDataChange = (card, field, value, rowIndex) => {
     setCardData((prevData) => ({
      ...prevData,
      [card]: prevData[card].map((row, index) =>
        index === rowIndex ? { ...row, [field]: value } : row
      ),
    }));
  };
 
  const handleAddRow = (card) => {
    setCardData((prevData) => ({
      ...prevData,
      [card]: [
        ...prevData[card], // Keep existing rows
        {
          client: '',
          nombre_user: '',
          projet: '',
          janvier: '',
          fevrier: '',
          mars: '',
          avril: '',
          mai: '',
          juin: '',
          juillet: '',
          aout: '',
          septembre: '',
          octobre: '',
          novembre: '',
          decembre: '',
          annee: '',
        },
      ],
    }));
  };
  
  // const handleAddRow = (card) => {
  //   const existingClients = cardData[card].map((row) => row.client.trim().toLowerCase());
  
  //   // Check if the client already exists in the list
  //   if (existingClients.includes('')) {
  //     toast.error("Vous ne pouvez pas ajouter un nouveau client car un client existe déjà.");
  //     return;
  //   }
  
  //   setCardData((prevData) => ({
  //     ...prevData,
  //     [card]: [
  //       ...prevData[card], // Keep existing rows
  //       {
  //         client: '',
  //         nombre_user: '',
  //         projet: '',
  //         janvier: '',
  //         fevrier: '',
  //         mars: '',
  //         avril: '',
  //         mai: '',
  //         juin: '',
  //         juillet: '',
  //         aout: '',
  //         septembre: '',
  //         octobre: '',
  //         novembre: '',
  //         decembre: '',
  //         annee: '',
  //       },
  //     ],
  //   }));
  // };
  
  
 
  const handleCardClick = (card) => {
    setActiveCard(card);
  };
 
  const handleSaveRow = async (card) => {
    const cardRows = cardData[card];
  
    // Ensure that cardRows is defined and has at least one row
    if (cardRows && cardRows.length > 0) {
      const newRowData = cardRows[cardRows.length - 1]; // Get the last added row
  
      // Check if any required field is empty
      const requiredFields = ['client', 'nombre_user', 'projet', 'janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre', 'annee'];
  
      if (requiredFields.some(field => !newRowData[field])) {
        toast.error("Veuillez remplir tous les champs obligatoires.");
        return;
      }
  
      try {
        await addSupport(
          newRowData.client,
          newRowData.nombre_user,
          newRowData.projet,
          newRowData.janvier,
          newRowData.fevrier,
          newRowData.mars,
          newRowData.avril,
          newRowData.mai,
          newRowData.juin,
          newRowData.juillet,
          newRowData.aout,
          newRowData.septembre,
          newRowData.octobre,
          newRowData.novembre,
          newRowData.decembre,
          newRowData.annee
        );
  
        // Fetch the saved rows for the current project after saving
        const savedRowsResponse = await getSupportByProjet(card);
  
        // Update the saved rows state
        setSavedRows((prevSavedRows) => ({
          ...prevSavedRows,
          [card]: savedRowsResponse,
        }));
  
        // Replace the last row with the newly saved row
        setCardData((prevData) => ({
          ...prevData,
          [card]: prevData[card].map((row, index) =>
            index === prevData[card].length - 1 ? newRowData : row
          ),
        }));
  
        console.log("Successfully saved row:", card);
        // You may want to handle additional logic or feedback after a successful save
      } catch (error) {
        // Handle error
        console.error("Error saving row:", error);
      }
    } else {
      console.error("Invalid card or no rows:", card);
    }
  };
  

  // const handleSaveRow = async (card) => {
  //   const cardRows = cardData[card];
  
  //   // Ensure that cardRows is defined and has at least one row
  //   if (cardRows && cardRows.length > 0) {
  //     const newRowData = cardRows[cardRows.length - 1]; // Get the last added row
  
  //     try {
  //       await addSupport(
  //         newRowData.client,
  //         newRowData.nombre_user,
  //         newRowData.projet,
  //         newRowData.janvier,
  //         newRowData.fevrier,
  //         newRowData.mars,
  //         newRowData.avril,
  //         newRowData.mai,
  //         newRowData.juin,
  //         newRowData.juillet,
  //         newRowData.aout,
  //         newRowData.septembre,
  //         newRowData.octobre,
  //         newRowData.novembre,
  //         newRowData.decembre,
  //         newRowData.annee
  //       );
  
  //       // Fetch the saved rows for the current project after saving
  //       const savedRowsResponse = await getSupportByProjet(card);
  
  //       // Update the saved rows state
  //       setSavedRows((prevSavedRows) => ({
  //         ...prevSavedRows,
  //         [card]: savedRowsResponse,
  //       }));
  
  //       // Replace the last row with the newly saved row
  //       setCardData((prevData) => ({
  //         ...prevData,
  //         [card]: prevData[card].map((row, index) =>
  //           index === prevData[card].length - 1 ? newRowData : row
  //         ),
  //       }));
  
  //       console.log("Successfully saved row:", card);
  //       // You may want to handle additional logic or feedback after a successful save
  //     } catch (error) {
  //       // Handle error
  //       console.error("Error saving row:", error);
  //     }
  //   } else {
  //     console.error("Invalid card or no rows:", card);
  //   }
  // };
  
    
  
  return (
    <div className="departement-support-container">
      <div className="card-container1 d-flex">
        {Object.keys(cardData).map((card) => (
          <Card
            key={card}
            title={card}
            active={activeCard === card}
            data={cardData[card]}
            // onDataChange={(field, value, rowIndex) => handleDataChange(card, field, value, rowIndex)}
            onClick={() => handleCardClick(card)}
            onAddRow={() => handleAddRow(card)}
            onSaveRow={() => handleSaveRow(card)}
          />
        ))}
      </div>
      
      {/* Afficher le tableau de la carte active en dessous des cartes */}
      {activeCard && (
        <div className="table-container1">
          <Table
            data={cardData[activeCard]}
            onDataChange={(field, value, rowIndex) => handleDataChange(activeCard, field, value, rowIndex)}
            onAddRow={() => handleAddRow(activeCard)}
            onSaveRow={() => handleSaveRow(activeCard)}
            title={activeCard}
          />
        </div>
      )}

    </div>
  );
}

export default DepartementSupport;

