import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 1,
  },
  billTo: {
    marginTop: 10,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  socTitle: {
    color: "#000",
    fontSize: 15,
    fontStyle: "bold",
  },
});

const BillFrom = ({ invoice }) => (
 
  <View style={styles.headerContainer}>
    <Text style={styles.socTitle}>{t("Societé")} {invoice.sender_name}</Text>
    <Text>{invoice.matricule_fiscal}</Text>
    <Text>{invoice.website_url}</Text>
    <Text>{invoice.sender_mobile}</Text>
    <Text>{invoice.sender_email}
    </Text>
  </View>
);

export default BillFrom;
