export function getSearchData() {
  return [
    { path: "/", keyword: "acceuil dashboard home", description: "" },
    { path: "/admin/users", keyword: "list users collaborateurs", description: "" },
    { path: "/admin/vaccin", keyword: "list vaccination vaccin", description: "" },
    { path: "/admin/addvaccin", keyword: "ajouter vaccin vaccination", description: "" },
    { path: "/admin/holiday", keyword: "jour férié chomé non payé", description: "" },
    { path: "/admin/calendarconge", keyword: "calendrier conge", description: "" },
    { path: "/admin/demandeconge", keyword: "demande conge", description: "" },
    { path: "/admin/congevalide", keyword: "conge validé validate", description: "" },
    { path: "/admin/congenonvalide", keyword: "conge non valide", description: "" },
    { path: "/admin/jourpointage", keyword: "pointage jour", description: "" },
    { path: "/admin/moispointage", keyword: "pointage moins", description: "" },
    { path: "/admin/teletravail", keyword: "list teletravail", description: "" },
    { path: "/admin/autorisation", keyword: "list autorisation", description: "" },
    { path: "/admin/acomptesalaire", keyword: "acompte salaire", description: "" },
    { path: "/admin/annonce", keyword: "annonce note de service", description: "" },
    { path: "/admin/support", keyword: "support", description: "" },
    { path: "/admin/blog", keyword: "blog", description: "" },
    { path: "/admin/fileconge", keyword: "file conge", description: "" },
    { path: "/admin/produit", keyword: "produit", description: "" },
    { path: "/admin/addproduct", keyword: "ajouter produit", description: "" },
    { path: "/admin/entreprise", keyword: "entreprise", description: "" },
    { path: "/admin/baremes", keyword: "bareme", description: "" },
    { path: "/admin/salaire", keyword: "salaire", description: "" },
    { path: "/admin/virementsalaire", keyword: "virement salaire", description: "" },
    { path: "/admin/objectif", keyword: "objectif objectiv", description: "" },
    { path: "/admin/departements", keyword: "departement", description: "" },
    { path: "/admin/kanban", keyword: "task kanban scrum", description: "" },
    { path: "/admin/task", keyword: "task list", description: "" },
    { path: "/admin/projects", keyword: "projects", description: "" },
    { path: "/admin/todo", keyword: "todo note", description: "" },
    { path: "/admin/maintenance", keyword: "maintenance", description: "" },
    { path: "/admin/materiel", keyword: "materiel", description: "" },
    { path: "/admin/client", keyword: "client", description: "" },
    { path: "/admin/addclient", keyword: "ajouter client", description: "" },
    { path: "/admin/fournisseur", keyword: "fournisseur", description: "" },
    { path: "/admin/addfournisseur", keyword: "ajout fournisseur", description: "" },
    { path: "/admin/tax", keyword: "tax", description: "" },
    { path: "/admin/prefix", keyword: "prefix", description: "" },
    { path: "/admin/encaissement", keyword: "encaissement", description: "" },
    { path: "/admin/addencaissement", keyword: "ajout encaissement", description: "" },
    { path: "/admin/addpaiement", keyword: "ajout paiement", description: "" },
    { path: "/admin/paiement", keyword: "paiement", description: "" },
  ];
}
