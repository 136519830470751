import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getProjetById } from "../../Services/Pointeuse/ProjetApi";
import { getProjetMemberByProjetId } from "../../Services/Pointeuse/ProjetMemberApi";
import ImageComponent from "../Styles/ImageComponent";
import AddProject from "./AddProject.js";
import CommentProjet from "./CommentProjet";
import FilesProject from "./FilesProject";
import MembersProject from "./MembersProject";
import ViewProject from "./ViewProject";

function EditProjet(props) {
  const [data, setdata] = useState(null);
  const [members, setmembers] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getProjetById(props.match.params.id).then((res) => {
      setdata(res.data);
      getProjetMemberByProjetId(res.data.id).then((res) => {
        console.log(res.data);
        setmembers(res.data);
      });
    });
  };

  return (
    <div>
      {/*Page header*/}
      <AddProject />
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">
            <span className="font-weight-normal text-muted mr-2">Project #{props.match.params.id}</span>
          </h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary " data-toggle="modal" data-target="#newprojectmodal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                Ajouter projet
              </Link>

              <Link to="/admin/projects" className="btn btn-warning ">
                List projets
              </Link>

              <a href="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </a>
              <a href="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </a>
              <a href="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {data && (
        <div className="row">
          <div className="col-xl-3 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header  border-0">
                <div className="card-title">Détails projet</div>
              </div>
              <div className="card-body pt-2 pl-3 pr-3">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <span className="w-50">Responsable Id</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">{data?.responsable_id}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Client</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">{data?.user_code_id}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Department</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">{data?.departement_id}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Date début</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">{data?.date_debut}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Date fin</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">{data?.date_fin}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Budget</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">{data?.budget}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Duration</span>
                        </td>
                        <td>:</td>
                        <td>
                          <span className="font-weight-semibold">5 Months</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Priorité</span>
                        </td>
                        <td>:</td>
                        <td>
                          {data.priority == "0" && <span className="badge badge-danger-light">0</span>}
                          {data.priority == "1" && <span className="badge badge-secondary-light">1</span>}
                          {data.priority == "2" && <span className="badge badge-warning-light">2</span>}
                          {data.priority == "3" && <span className="badge badge-success-light">3</span>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="w-50">Status</span>
                        </td>
                        <td>:</td>
                        <td>
                          {data.etat == "afaire" && <span className="badge badge-primary">En cours</span>}
                          {data.etat == "encours" && <span className="badge badge-warning">En cours</span>}
                          {data.etat == "terminer" && <span className="badge badge-success">En cours</span>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="p-3">
                  <div className="d-flex align-items-end justify-content-between">
                    <h6 className="fs-12">Status</h6>
                    <h6 className="fs-12">100%</h6>
                  </div>
                  <div className="progress h-2">
                    <div className="progress-bar bg-success w-100" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header  border-0">
                <div className="card-title">Équipe assignée</div>
                {/* <div className="ml-auto">
                  <a href="#" className="btn btn-success btn-sm" data-toggle="modal" data-target="#addteammodal">
                    Add
                  </a>
                </div> */}
              </div>
              <div className="card-body p-1 pt-2">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                      {members &&
                        members.map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              <div className="d-flex">
                                {/* <span className="avatar avatar-md brround mr-3" style={{ backgroundImage: "url(assets/images/users/4.jpg)" }} /> */}
                                <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.imguser}></ImageComponent>
                                <div className="mr-3 mt-0 mt-sm-1 d-block">
                                  <h6 className="mb-1 fs-14">
                                    {el.nom} {el.prenom}
                                  </h6>
                                  <p className="text-muted mb-0 fs-12">{el.fonction}</p>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <div className="d-flex mt-1 text-right">
                                <Link
                                  to={"/admin/users/" + el.user_id}
                                  className="action-btns1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View"
                                >
                                  <i className="feather feather-eye text-primary" />
                                </Link>
                                {/* <a href="#" className="action-btns1  mr-0" data-toggle="tooltip" data-placement="top" title="Delete">
                                  <i className="feather feather-trash-2 text-danger" />
                                </a> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-md-12 col-lg-12">
            <div className="tab-menu-heading hremp-tabs p-0 ">
              <div className="tabs-menu1">
                {/* Tabs */}
                <ul className="nav panel-tabs">
                  <li className="ml-4">
                    <a href="#tab5" className="active" data-toggle="tab">
                      Détail
                    </a>
                  </li>
                  <li>
                    <a href="#tab6" data-toggle="tab">
                      Membres
                    </a>
                  </li>
                  <li>
                    <a href="#tab7" data-toggle="tab">
                      Pièces jointes
                    </a>
                  </li>

                  <li>
                    <a href="#tab8" data-toggle="tab">
                      Commentaire
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
              <div className="tab-content">
                <div className="tab-pane active" id="tab5">
                  <ViewProject projetId={props.match.params.id} />
                </div>
                <div className="tab-pane" id="tab6">
                  <MembersProject projetId={props.match.params.id} getData={getData} />
                </div>
                <div className="tab-pane" id="tab7">
                  <FilesProject projetId={props.match.params.id} />
                </div>
                <div className="tab-pane" id="tab8">
                  <CommentProjet projetId={props.match.params.id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProjet;
