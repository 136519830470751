import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { getToken } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all departements from the API
 * @returns The response is an array of objects.
 */
export async function getAllDevise() {
  try {
    const response = await axios.get(api_url + "devise/getDevise/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

