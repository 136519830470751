import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { getRetardByCode, getRetardByUserIdAndMonth, updateRetardById } from "../../Services/Pointeuse/RetardApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getCompareTwoTime } from "../../Utils/DateUtils";
import { convertSecondToTime, convertTimeToSecond, filterPointageByMonth } from "../../Utils/GlobalTableUtils";
import { getfilterLastMonth, getfilterLastYear, getfilterMonth, getfilterToday, getfilterYear, getfilterYesterday } from "../../Utils/ObjectFilter";
import { filterUser } from "../../Utils/SortObject";
import BarSparklineRetard from "../Charts/CustumSparkline/BarSparklineRetard";
import Retard from "../Charts/CustumSparkline/BarSparklineRetard";
import CopieContent from "../Exports/CopieContent";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import { getQuestionnaireByCode } from "../../Services/Pointeuse/QuestionnaireApi";
import moment from "moment"; 
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import 'moment-duration-format'; // Importez le module de formatage pour moment
const getInitialSelectedMonth = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two-digit month
  return `${year}-${month}`;
};

function ListRetard() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]); 
  const [size, setsize] = useState(8);
  const [listToday, setlistToday] = useState(null);
   const [listMonth, setlistMonth] = useState(null);
  const [listLastMonth, setlistLastMonth] = useState(null);
  const [listYear, setlistYear] = useState(null);
  const [listLastYear, setlistLastYear] = useState(null);
  const [listYesterday, setlistYesterday] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [retard, setRetard] = useState("00:00:00");
  const [breakTime, setbreakTime] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(getInitialSelectedMonth()); // Utilisez une fonction pour définir la valeur initiale
  const [selectedUser, setSelectedUser] = useState(""); // Add selectedUser state

  /**
   * Given an array of objects, return the average of the time values
   * @param arr - an array of objects that contain the time and the name of the person who sent the
   * message.
   * @returns The average time in seconds.
   */
  const average = (arr) => {
    let list = [];
    if (arr.length > 1) {
      arr.forEach((element) => {
        list.push(convertTimeToSecond(element.retardTime));
      });
      return list.reduce((p, c) => p + c, 0) / arr.length;
    } else {
      return 0;
    }
    
  };
  var dateObj = new Date();
var result = dateObj.toISOString().split("T")[0];
var month = result.split("-")[1];
var year = result.split("-")[0];
var newdate = year + "-" + month;
  const [date, setdate] = useState(newdate);

  
  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * This function is used to get the data from the API and then set the data to the state
   */
  const getData = () => {
    setLoading(true);
    // getRetardByCode()
    getQuestionnaireByCode(selectedMonth)
      .then((res) => {
        setdata(res.data);
        console.log(res.data);
        setallInitialData(res.data);
        setlistYear(getfilterYear(res.data, "createdAt"));
        setlistMonth(getfilterMonth(res.data, "createdAt"));
        setlistToday(getfilterToday(res.data, "createdAt"));
        setlistYesterday(getfilterYesterday(res.data, "createdAt"));

        setlistLastMonth(getfilterLastMonth(res.data, "createdAt"));
        setlistLastYear(getfilterLastYear(res.data, "createdAt"));
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

 
useEffect(() => {
  // Assuming currentData is an array of objects
  const userIds = currentData.map(dataPoint => dataPoint.user_id);

  // Fetch additional data for each user_id
  const fetchUserData = async () => {
    const userDataPromises = userIds.map(userId => getPointageByUserId(userId));
    const userDataArray = await Promise.all(userDataPromises);
    
    // userDataArray is an array of responses for each user_id
    console.log(userDataArray);
  };

  // Call the function to fetch additional data
  fetchUserData();
}, [currentData]);

  /**
   * It updates the retard table in the database.
   * @param q - The query to be executed.
   * @param id - the id of the row to update
   */
  const handleUpdateRetard = (q, id) => {
    updateRetardById(q, id).then(() => {
      getData();
    });
  };
  const handleChangeMonth = (selectedDate) => {
    setSelectedMonth(selectedDate);
    // Vous pouvez également ajouter d'autres logiques ici, par exemple, récupérer de nouvelles données pour le mois sélectionné.
 };
 
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllSettings()
      .then((res) => {
        setRetard(res.data.dayRetard);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data based on the search input.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    const dataSearch = filterUser(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  /**
   * It changes the data that is displayed in the table.
   * @param expr - The expression to be evaluated.
   */
  const handleChange = (expr) => {
    if (expr == 1) {
      setdata(listToday);
    } else if (expr == 2) {
      setdata(listYesterday);
    } else if (expr == 3) {
      setdata(listMonth);
    } else {
      setdata(listYear);
    }
  };
  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  // Supposons que currentData soit votre tableau de données
// Groupement par mois
const groupedData = currentData.reduce((acc, el) => {
  // Créer une clé basée sur le mois (par exemple, "2023-05" pour mai 2023)
  const monthKey = moment(el.createdAt).format('YYYY-MM');

  // Créer un groupe s'il n'existe pas encore
  if (!acc[monthKey]) {
    acc[monthKey] = [];
  }

  // Ajouter l'élément au groupe
  acc[monthKey].push(el);

  return acc;
}, {});

// Addition des valeurs pour chaque groupe
const summedData = Object.keys(groupedData).map((monthKey) => {
  const sum = groupedData[monthKey].reduce((total, el) => total + el.retardTime, 0);
  return { month: monthKey, sum };
});
 
// Fonction pour calculer le cumul du temps de retard par mois pour chaque utilisateur
const calculateCumulativeTimeByMonth = (data) => {
  const cumulativeTimeByMonth = {};

  data.forEach((el) => {
    const month = moment(el.createdAt).format('MMMM YYYY');
    const cumulativeTime = cumulativeTimeByMonth[month] || moment.duration(0);

    // Ajouter le temps de retard actuel au cumul
    const currentTime = moment.duration(el.retardTime);
    cumulativeTimeByMonth[month] = cumulativeTime.add(currentTime);
  });

  return cumulativeTimeByMonth;
};
const [cumulativeTimeTotal, setCumulativeTimeTotal] = useState({});

useEffect(() => {
  // Calculer le cumul total du temps de retard pour chaque utilisateur
  const calculateCumulativeTimeTotal = (data) => {
    const cumulativeTimeTotal = {};
  
    data.forEach((el) => {
      const monthKey = moment(el.createdAt).format('YYYY-MM');
      const cumulativeTime = cumulativeTimeTotal[el.user_id] || {};
      cumulativeTime[monthKey] = (cumulativeTime[monthKey] || moment.duration(0)).add(moment.duration(el.retardTime));
      cumulativeTimeTotal[el.user_id] = cumulativeTime;
    });
  
    return cumulativeTimeTotal;
  };
  
  
   // Mettre à jour le state cumulativeTimeTotal
  setCumulativeTimeTotal(calculateCumulativeTimeTotal(currentData));
}, [currentData]);

const formatDuration = (duration) => {
  const totalSeconds = duration.asSeconds();
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};


const handleChangeDate = (date) => {
  setLoading(true);
  setdate(date);
  setLoading(false);
};
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Retard")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              {/* <Link to="hr-addemployee.html" className="btn btn-primary mr-3">
            Add New Employee
          </Link> */}
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

       <div className="row">
        {listToday && (
          <div className="col-xl-2 col-lg-6 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body pb-0">
                <div className="text-left mb-4">
                  <p className=" mb-1 ">
                    <i className="fa fa-line-chart mr-1" />
                    {t("Total aujourd'hui")}
                  </p>
                  <h2 className="mb-0 font-weight-semibold">
                    {listToday?.length}
                    <span className="fs-12 text-muted">
                      <span className="text-danger mr-1">
                        <i className="fe fe-arrow-up ml-1 " /> {convertSecondToTime(average(listToday))}
                      </span>{" "}
                      {t("moyenne aujourd'hui")}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="chart-wrapper overflow-hidden">{listToday && <BarSparklineRetard retards={listToday} />}</div>
            </div>
          </div>
        )}
        {listYesterday && (
          <div className="col-xl-2 col-lg-6 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body pb-0">
                <div className="text-left mb-4">
                  <p className=" mb-1 ">
                    <i className="fa fa-line-chart  mr-1" />
                    {t("Total hier")}
                  </p>
                  <h2 className="mb-0 font-weight-semibold">
                    {listYesterday?.length}
                    <span className="fs-12 text-muted">
                      <span className="text-danger mr-1">
                        <i className="fe fe-arrow-up ml-1 " /> {convertSecondToTime(average(listYesterday))}
                      </span>{" "}
                      {t("moyenne hier")}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="chart-wrapper overflow-hidden">{listYesterday && <BarSparklineRetard retards={listYesterday} />}</div>
            </div>
          </div>
        )}
        {listMonth && (
          <div className="col-xl-2 col-lg-6 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body pb-0">
                <div className="text-left mb-4">
                  <p className=" mb-1 ">
                    <i className="fa fa-line-chart  mr-1" />
                    {t("Total ce mois")}
                  </p>
                  <h2 className="mb-0 font-weight-semibold">
                    {listMonth?.length}
                    <span className="fs-12 text-muted">
                      <span className="text-danger mr-1">
                        <i className="fe fe-arrow-up ml-1 " /> {convertSecondToTime(average(listMonth))}
                      </span>{" "}
                      {t("moyenne du mois")}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="chart-wrapper overflow-hidden">{listMonth && <BarSparklineRetard retards={listMonth} />}</div>
            </div>
          </div>
        )}

        {listLastMonth && (
          <div className="col-xl-2 col-lg-6 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body pb-0">
                <div className="text-left mb-4">
                  <p className=" mb-1 ">
                    <i className="fa fa-line-chart  mr-1" />
                    {t("Total mois dernier")}
                  </p>
                  <h2 className="mb-0 font-weight-semibold">
                    {listLastMonth?.length}
                    <span className="fs-12 text-muted">
                      <span className="text-danger mr-1">
                        <i className="fe fe-arrow-up ml-1 " /> {convertSecondToTime(average(listLastMonth))}
                      </span>{" "}
                      {t("moyenne du mois dernier")}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="chart-wrapper overflow-hidden">{listLastMonth && <BarSparklineRetard retards={listLastMonth} />}</div>
            </div>
          </div>
        )}

        {listYear && (
          <div className="col-xl-2 col-lg-6 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body pb-0">
                <div className="text-left mb-4">
                  <p className=" mb-1">
                    <i className="fa fa-line-chart mr-1" />
                    {t("Total année")}
                  </p>
                  <h2 className="mb-0 font-weight-semibold">
                    {listYear?.length}
                    <span className="fs-12 text-muted">
                      <span className="text-danger mr-1">
                        <i className="fe fe-arrow-up ml-1" />
                        {convertSecondToTime(average(listYear))}
                      </span>{" "}
                      {t("moyenne")} {new Date().getFullYear()}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="chart-wrapper overflow-hidden">{listYear && <BarSparklineRetard retards={listYear} />}</div>
            </div>
          </div>
        )}

        {listLastYear && (
          <div className="col-xl-2 col-lg-6 col-md-12">
            <div className="card overflow-hidden">
              <div className="card-body pb-0">
                <div className="text-left mb-4">
                  <p className=" mb-1">
                    <i className="fa fa-line-chart mr-1" />
                    {t("Total année dernière")}
                  </p>
                  <h2 className="mb-0 font-weight-semibold">
                    {listLastYear?.length}
                    <span className="fs-12 text-muted">
                      <span className="text-danger mr-1">
                        <i className="fe fe-arrow-up ml-1" />
                        {convertSecondToTime(average(listLastYear))}
                      </span>{" "}
                      {t("moyenne")} {new Date().getFullYear() - 1}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="chart-wrapper overflow-hidden">{listLastYear && <BarSparklineRetard retards={listLastYear} />}</div>
            </div>
          </div>
        )}
      </div>    
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List Retard")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-orange-light mr-2">
                    <i className="feather feather-check-circle text-orange" /> {t("Durée de retard journalier")} : {retard}
                  </span>
                  {/* <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> non activé
                  </span> */}
                  {/* <span className="badge badge-warning-light mr-2">
                <i className="fa fa-star text-warning" /> ---&gt; Holiday
              </span>
              <span className="badge badge-orange-light mr-2">
                <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
              </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <select
                            name="attendance"
                            className="action-btns"
                            data-placeholder={t("Choisir période")}
                            onChange={(e) => handleChange(e.target.value)}
                          >
                            <option label={t("Choisir période")} />
                            <option value={1}>{t("Aujourd'hui")}</option>
                            <option value={2}>{t("Hier")}</option>
                            <option value={3}>{t("Ce mois")}</option>
                            <option value={4}>{t("Cette année")}</option>
                          </select>
                          {/* <label className="mr-2">
                            <input className="form-control floating" type="month" 
                            onChange={(e) => handleChangeDate(e.target.value)} 
                            />
                          </label> */}
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_retard_collaborateurs"} />
                          <ExportPdf data={data} name={"List_retard_collaborateurs"} columns={["nom", "prenom", "time", "createdAt"]} />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link> */}
                          {/* <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">#{t("ID")}</th>
                              <th className="border-bottom-0">{t("nom/prénom")}</th>
                              <th className="border-bottom-0">{t("Durée de retard")}</th>
                              <th className="border-bottom-0">{t("Cumull mois")}</th>
                              <th className="border-bottom-0">{t("Date")}</th>
                              <th className="border-bottom-0">{t("Questionnaire")}</th>
                              <th className="border-bottom-0">{t("Motif")}</th>
                              <th className="border-bottom-0">{t("Calendrier")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              
                              <tr key={idx} role="row" className="odd">
                                 {console.log("current date :",currentData)}
                                <td>#{el.id}</td>
                                <td>
                                  <div className="d-flex">
                                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                                    <div className="mr-8 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.nom} {el.prenom}
                                      </h6>
                                      <p className="text-muted mb-0 fs-12">{el.email}</p>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <span className="badge badge-orange-light">{el.retardTime}</span>
                                </td>
                  
                              {/* <td className="text-left" style={{ fontWeight: "bold" }}>
                                <span className="badge badge-orange-light">{cumulativeTimeTotal[el.user_id] &&
                                  cumulativeTimeTotal[el.user_id][moment(el.createdAt).format('YYYY-MM')] &&
                                  moment
                                    .duration(cumulativeTimeTotal[el.user_id][moment(el.createdAt).format('YYYY-MM')])
                                    .format("hh:mm:ss")}
                              </span>
                              </td> */}
                              <td className="text-left" style={{ fontWeight: "bold" }}>
                                <span className="badge badge-orange-light">{cumulativeTimeTotal[el.user_id] &&
                                  cumulativeTimeTotal[el.user_id][moment(el.createdAt).format('YYYY-MM')] &&
                                  formatDuration(cumulativeTimeTotal[el.user_id][moment(el.createdAt).format('YYYY-MM')])}
                                </span>
                              </td>

                                <td className="text-left" style={{ fontWeight: "bold" }}>
                                  {el.createdAt}
                                </td> 
                               <td className="text-left" style={{ fontWeight: "bold" }}>
                                  {el.objet}
                                </td>
                               <td className="text-left" style={{ fontWeight: "bold" }}>
                                  {el.motif}
                                </td>

                                <td className="text-left" style={{ fontWeight: "bold" }}>
                                  <Link
                                    to={"/admin/retardcalendar/" + el.user_id}
                                    className="action-btns"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Calendrier retard"
                                  >
                                    <i className="fa fa-calendar text-secondary" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListRetard;
