import React, { Component, useEffect, useState } from "react";
import { editEntreprise, getEntrepriseByCode, updateLogo } from "../../Services/Pointeuse/EntrepriseApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillParams from "../Facturation/invoiceReports/BillParams";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { t } from "i18next";
import Form from 'react-bootstrap/Form';
import { getAllDevise } from "../../Services/devise/devise";
import ReactSelect from "react-select";
import { code_CAT, codetva, getLettreAssociee } from "../../Utils/Data/Matriculefiscale";


export function Entreprise({ t }) {
  const [state, setState] = useState({
    adresse: "",
    banque: "",
    code_generated: "",
    email: "",
    fax: "",
    id: "",
    logo: "",
    matriculeFiscal: { num_MF: '', cle_MF: '', codetva: '', codecat: '', codeetab: '000' },
    mobile: "",
    nb_employe: "",
    nom: "",
    numero_cnss: "",
    rib: "",
    secteur_activite: "",
    start_year: "",
    website_url: "",
    latitude: "",
    longitude: "",
    timbre_active: 0,
    timbre: "",
    devise: "",
    avantage_cnss: 0,
  });

  const [deviseState, setDeviseState] = useState([]);

  useEffect(() => {
    getAllDevise().then((res) => {
      if (res?.status === 200) {
        setDeviseState(res?.data);
      }
    });

    getEntrepriseByCode().then((res) => {
      if (res?.data) {
        const logoUrl = res.data.logo ? localStorage.getItem("baseUrl") + res.data.logo : "/assets/images/company/company.png";

        setState(prevState => ({
          ...prevState,
          adresse: res.data.adresse,
          banque: res.data.banque,
          code_generated: res.data.code_generated,
          email: res.data.email,
          fax: res.data.fax,
          id: res.data.id,
          avantage_cnss: res.data.avantage_cnss,
          logo: logoUrl,
          matriculeFiscal: {
            num_MF: res.data.matricule_fiscal?.split('/')[0],
            cle_MF: res.data.matricule_fiscal?.split('/')[1],
            codetva: res.data.matricule_fiscal?.split('/')[2],
            codecat: res.data.matricule_fiscal?.split('/')[3],
            codeetab: res.data.matricule_fiscal?.split('/')[4]
          },
          devise: res.data.devise,
          mobile: res.data.mobile,
          nb_employe: res.data.nb_employe,
          nom: res.data.nom,
          numero_cnss: res.data.numero_cnss,
          rib: res.data.rib,
          secteur_activite: res.data.secteur_activite,
          start_year: res.data.start_year,
          website_url: res.data.website_url,
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          timbre: res.data.timbre_fiscale,
          timbre_active: res.data.timbre_active,
        }));
      }
    });
  }, []);

  const handleChange = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleMatriculeFiscalePart1Blur = () => {
    const value = state.matriculeFiscal.num_MF.padStart(7, "0");
    const lettreAssociee = getLettreAssociee(value);

    setState(prevState => ({
      ...prevState,
      matriculeFiscal: {
        ...prevState.matriculeFiscal,
        num_MF: value,
        cle_MF: lettreAssociee
      }
    }));
  };

  const handleSave = () => {
    const matricule = `${state.matriculeFiscal.num_MF}/${state.matriculeFiscal.cle_MF}/${state.matriculeFiscal.codetva}/${state.matriculeFiscal.codecat}/${state.matriculeFiscal.codeetab}`;

    editEntreprise(
      state.nom,
      state.adresse,
      matricule,
      state.numero_cnss,
      state.rib,
      state.banque,
      state.mobile,
      state.fax,
      state.website_url,
      state.secteur_activite,
      state.email,
      state.nb_employe,
      state.start_year,
      state.latitude,
      state.longitude,
      state.timbre,
      state.timbre_active,
      state.devise,
      localStorage.getItem("code_generated"),
      state.avantage_cnss
    ).then((res) => {
      console.log(res);
      toast.success("Information saved successfully!");
    }).catch(err => {
      console.error(err);
      toast.error("Error saving information.");
    });
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setState(prevState => ({ ...prevState, logo: imageUrl }));

    updateLogo(file, state.code_generated)
      .then(() => {
        toast.success("Image added successfully!");
      })
      .catch(() => {
        toast.error("Error adding image.");
      });
  };

  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Entreprise")}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content adminsetting-content" id="setting-tabContent">
            <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="card-title">{t("Modifier information entreprise")}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <form>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <img className="m-4" src={state.logo} alt="Company Logo" style={{ width: 100, height: 100 }} />
                        </div>
                        <div className="row">
                          <div className="col-sm-10">
                            <div className="form-group">
                              <label>{t("Nom")} <span className="text-danger">*</span></label>
                              <input className="form-control" type="text" onChange={(e) => handleChange('nom', e.target.value)} value={state.nom} required />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group">
                              <label>{t("Avantage CNSS")}</label>
                              <input className="form-control" type="checkbox" onChange={() => handleChange('avantage_cnss', state.avantage_cnss === 0 ? 1 : 0)} checked={state.avantage_cnss} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>{t("Adresse")}</label>
                              <input className="form-control" type="text" value={state.adresse} onChange={(e) => handleChange('adresse', e.target.value)} required />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>{t("Latitude")}</label>
                              <input className="form-control" type="text" value={state.latitude} onChange={(e) => handleChange('latitude', e.target.value)} />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>{t("Longitude")}</label>
                              <input className="form-control" type="text" value={state.longitude} onChange={(e) => handleChange('longitude', e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-3">
                            <label>{t("Num MF")}</label>
                            <input className="form-control" type="text" onBlur={handleMatriculeFiscalePart1Blur} onChange={(e) => handleChange('matriculeFiscal', { ...state.matriculeFiscal, num_MF: e.target.value })} value={state.matriculeFiscal.num_MF} required />
                          </div>
                          <div className="col-2">
                            <label>{t("Cle MF")}</label>
                            <input className="form-control" type="text" value={state.matriculeFiscal.cle_MF} readOnly required />
                          </div>
                          <div className="col-2">
                            <label>{t("Code TVA")}</label>
                            <select className="form-control" value={state.matriculeFiscal.codetva} onChange={(e) => handleChange('matriculeFiscal', { ...state.matriculeFiscal, codetva: e.target.value })}>
                            {codetva.map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-2">
                            <label>{t("Code Cat")}</label>
                            <select className="form-control" value={state.matriculeFiscal.codecat} onChange={(e) => handleChange('matriculeFiscal', { ...state.matriculeFiscal, codecat: e.target.value })}>
                            {code_CAT.map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-2">
                            <label>{t("N° Etab")}</label>
                            <input className="form-control" value={state.matriculeFiscal.codeetab} readOnly />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>{t("Fax")}</label>
                              <input className="form-control" type="text" value={state.fax} onChange={(e) => handleChange('fax', e.target.value)} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>{t("Site web")}</label>
                              <input className="form-control" type="text" value={state.website_url} onChange={(e) => handleChange('website_url', e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>{t("Email de contact")}</label>
                              <input className="form-control" type="email" value={state.email} onChange={(e) => handleChange('email', e.target.value)} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>{t("Mobile")}</label>
                              <input className="form-control" type="text" value={state.mobile} onChange={(e) => handleChange('mobile', e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>{t("Nombre Employees")}</label>
                              <input className="form-control" type="text" value={state.nb_employe} onChange={(e) => handleChange('nb_employe', e.target.value)} />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>{t("Date de lancement")}</label>
                              <input className="form-control" type="text" value={state.start_year} onChange={(e) => handleChange('start_year', e.target.value)} />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-footer float-md-right">
                  <Link to="#" className="btn btn-primary float-md-right" onClick={handleSave}>
                    {t("Modifier")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-2" role="tabpanel">
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="card-title">{t("Modification paramètre facture")}</h4>
                </div>
                <div className="card-body">
                  <BillParams />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
