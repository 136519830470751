import axios from "axios";
import { type } from "os";
import { getCurrentDateTime } from "../../Utils/DateUtils";
import { getToken, getSession } from "../../Utils/SessionUtils";
import moment from "moment";
import { t } from "i18next";

const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function getTicketById(id) {
  try {
    const response = await axios.get(api_url_v2 + "ticket/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getTicketByUserId() {
  try {
    const response = await axios.get(api_url_v2 + "ticket/userid/" + getSession("id"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateTicketById(sujet, description, priority, category, date, id) {
  const credentiel = {
    sujet: sujet,
    description: description,
    priority: priority,
    category: category,
    date: date,
    id: id,
  };
  try {
    const response = await axios.patch(api_url_v2 + "ticket", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function updateTicketByStatus(status, id) {
  const credentiel = {
    status: status,
    id: id,
  };
  try {
    const response = await axios.patch(api_url_v2 + "ticket", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function addTicket(sujet, description, priority, category, date) {
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    user_id: getSession("id"),
    sujet: sujet,
    description: description,
    status: "open",
    priority: priority,
    category: category,
    date: date,
    isRead: "0",
  };

  try {
    const response = await axios.post(api_url_v2 + "ticket", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteTicketById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "ticket/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// export async function upadateTaskById(
//   userId,
//   object,
//   description,
//   etat,
//   commentaire,
//   dateDebut,
//   dateFin,
//   color,
//   projectId,
//   type,
//   priority,
//   id
// ) {
//   const credentiel = {
//     code_generated: getSession("code_generated"),
//     user_code_id: userId,
//     object: object,
//     description: description || "",
//     etat: etat,
//     commentaire: commentaire || "",
//     paragraph: "",
//     date_debut: dateDebut,
//     type: type,
//     priority: priority,
//     date_fin: dateFin,
//     date: getCurrentDateTime(),
//     color: color || "#3366FF",
//     tag: "",
//     createdBy: getSession("id"),
//     projet_id: projectId,
//     projet: "",
//     id: id,
//   };

//   try {
//     const response = await axios.patch(api_url + "taches", credentiel, config);
//     console.log(response);
//     //toast.success("Congé ajouter");
//     return response;
//   } catch (error) {
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       //toast.error("error server");
//       console.log(error.response.data);
//       console.log(error.response.message);
//       console.log(error.response.status);
//       console.log(error.response.headers);
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the
//       // browser and an instance of
//       // http.ClientRequest in node.js
//       // toast.error("error request");
//       console.log(error.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       console.log("Error", error.message);
//     }
//     console.log(error.config);
//     return error;
//   }
// }

// export async function senFcmTask(text, receiver) {
//   const notification = {
//     data: {
//       title: "Nouvelle Tâche",
//       message: text,
//     },
//     to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
//   };
//   const fcm_server_key =
//     "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

//   const g = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `key=${fcm_server_key}`,
//     },
//   };
//   try {
//     const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
//     console.log(response);

//     return response;
//   } catch (error) {
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx

//       console.log(error.response.data);
//       console.log(error.response.message);
//       console.log(error.response.status);
//       console.log(error.response.headers);
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the
//       // browser and an instance of
//       // http.ClientRequest in node.js

//       console.log(error.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       console.log("Error", error.message);
//     }
//     console.log(error.config);
//     return error;
//   }
// }
