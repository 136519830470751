import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Pointage by code (Today pointage)
 */
export async function getPointageByCode() {
  try {
    const response = await axios.get(api_url + "pointage/imagepointeuse/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Pointage by code and date
 */
export async function getAllPointages(date) {
  try {
    const response = await axios.get(api_url + "pointage/getallpointages/" + getSession("code_generated") + "/" + date, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Pointage by code
 */
export async function getPointageByUserId(id) {
  try {
    const response = await axios.get(api_url + "pointage/historymonth/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function updatePointage(data){
  try {
    const response = await axios.put(api_url + "pointage/updatePointage/" , data ,config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * get Pointage by code
 */
export async function getPointageByUserIdAndMonth(id, month) {
  try {
    const response = await axios.get(api_url + `pointeuse/${id}?month=${month}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * It gets all the pointage history for the current month.
 * @returns The response is an array of objects.
 */
export async function getAllPointageHistory() {
  try {
    const response = await axios.get(api_url + "pointage/historymonthcode/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * It gets all the pointage history for the current month.
 * @param date - The date for which to get pointage history (format: 'YYYY-MM').
 * @returns The response is an array of objects.
 */
export async function getAllPointageHistorydate(date) {
  try {
    const response = await axios.get(api_url + "pointage/historymonthcode/" + getSession("code_generated") +"/"+ date, config);
    return response;
  } catch (error) {
    console.error(error);
    throw error; // Ajoutez cette ligne pour propager l'erreur
  }
}

// /**
//  * It gets all the pointage history for the current month.
//  *  * @param {date}
//  * @returns The response is an array of objects.
//  */
// export async function getAllPointageHistorydate(date) {
//   try {
//     const response = await axios.get(api_url + "pointage/historymonthcode/" + getSession("code_generated") + date, config);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }
// /**
//  * Gets all the pointage history for the current month.
//  * @param {string} date - The date in the format 'YYYY-MM'.
//  * @returns {Promise} The response is an array of objects.
//  */
// export async function getAllPointageHistorydate(date) {
//   try {
//     const response = await axios.get(api_url + "pointage/historymonthcode/" + getSession("code_generated") + "/" + date, config);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }

// Update the frontend API function to include both user and date parameters
export async function getAllPointageHistoryByDateAndUser( date, userId = null) {
  const codeGenerated = getSession("code_generated"); // Get the code_generated value

  try {
    const url = userId
      ? `${api_url}pointage/historydateuser/${codeGenerated}/${userId}/${date}`
      : `${api_url}pointage/historydate/${codeGenerated}/${date}`;

    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.error(error);
  }
} 

// Update the frontend API function to fetch pointages based on user ID
export async function getPointageHistoryMonthByUserCode(userCodeId) {
  try {
    const response = await axios.get(`${api_url}pointage/historymonth/${userCodeId}`, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a pointage by user id.
 * @param userId - The ID of the user.
 * @param date - Date of the pointage
 * @param latitude - latitude of the pointage
 * @param longitude - The longitude of the point of arrival.
 * @param device - The device that the user is using to record the pointage.
 * @param network - The network type of the device.
 * @param createdAt - Date.now()
 * @returns The response is an object that contains the status code, the response data, the response
 * headers, and the request.
 */
export async function addPointageByUserId(userId, date, latitude, longitude, device, network, createdAt) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_code_id: userId,
    date: date,
    longitude_arrive: latitude,
    latitude_arrive: longitude,
    device: device,
    network: network,
    web: "1",
    created_at: createdAt,
  };
  try {
    const response = await axios.post(api_url + "pointage/pointeuse", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function sendFcmPointage(receiver) {
  const notification = {
    data: {
      title: "Pointage",
      message: t("vous êtes invité à effectuer le pointage"),
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);

    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js

      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}


/**
 * copie pointage
 */
export async function copiePointage(data) {
  try {
  

    const response = await axios.post(api_url + "pointage/copiepointage" , data, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}



/**
 * correction pointage
 */
export async function correctionPointage(data) {
  try {
  

    const response = await axios.post(api_url + "pointage/correctionPointage" , data, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 *  get correction pointage
 */
export async function getcorrectionPointage(date) {
  try {
      const y = date.split('-')[0]
      const m = date.split('-')[1]


    const response = await axios.get(api_url + `pointage/getcorrectionPointage/${m}/${y}/`+localStorage.getItem('code_generated') , config);
    return response;
  } catch (error) {
    console.error(error);
  }
}