import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayListPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";

import { addTeletravail, deleteTeletravailById, getTeletravaiByCodeAndDate } from "../../Services/Pointeuse/TeletravailApi";
import { t, use } from "i18next";

const Event = styled.div`
  display: inline-flex;
  font-weight: bold;
  vertical-align: top;
`;

const ImageEvent = styled.div`
  flex: 1;
  height: 100%;
`;

const TextEvent = styled.div`
  flex: 1;
  text-align: center;
  margin-left: 5px;
  margin-top: 4px;
`;

function TeleTravailCalendar() {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  const handleEventClick = (e) => {
    // Handle event click logic if needed
    const idEvent = e.event._def.publicId
    const userid = localStorage.getItem('id')
    const findEvent = events.find(item => item.id === parseInt(idEvent))
    if (findEvent) {
      if ((parseInt(findEvent.user_id) === parseInt(userid)) && findEvent.valide === 0) {

        swal({
          title: "Êtes-vous sûr ",
          text: "Voulez-vous supprimer la réservation !",
          icon: "warning",
          buttons: {
            cancel: t("Cancel"),
            confirm: {
              text: t("Supprimer"),
              value: true,
            },
          },
          dangerMode: true,
        }).then((res) => {
          if (res) {
            deleteTeletravailById(parseInt(idEvent)).then(() => {
              setEvents(events.filter(item => item.id !== idEvent))
              swal({
                title: "Supprimée!",
                text: "Votre réservation a été supprimée.",
                icon: "success"

              });

            });
          }
        });



      } else {
        const fonctionuser = localStorage.getItem('fonction')
        if ((fonctionuser.toLowerCase() === findEvent.fonction.toLowerCase()) && findEvent.valide !== -1) {

          toast.warning('Vous ne pouvez pas réserver ce jour')
        } else {
          handleDateClick({ dateStr: findEvent.date_debut })

        }
      }

    }
  };

  const handleDateClick = async (e) => {
    const findEvent = events.find(event => {
      const eventDate = new Date(event.start).toISOString().split('T')[0];
      return eventDate === e.dateStr;
    });
    const userid = localStorage.getItem('id')

    if (findEvent) {
      const idEvent = parseInt(findEvent.id)

      if ((parseInt(findEvent.user_id) === parseInt(userid)) && findEvent.valide === 0) {

        swal({
          title: "Êtes-vous sûr ",
          text: "Voulez-vous supprimer la réservation !",
          icon: "warning",
          buttons: {
            cancel: t("Cancel"),
            confirm: {
              text: t("Supprimer"),
              value: true,
            },
          },
          dangerMode: true,
        }).then((res) => {
          if (res) {
            deleteTeletravailById(parseInt(idEvent)).then(() => {
              setEvents(events.filter(item => item.id !== idEvent))
              swal({
                title: "Supprimée!",
                text: "Votre réservation a été supprimée.",
                icon: "success"

              });

            });
          }
        });



      } else {
        const fonctionuser = localStorage.getItem('fonction')
        if ((fonctionuser.toLowerCase() === findEvent.fonction.toLowerCase()) && findEvent.valide !== -1) {

          toast.warning('Vous ne pouvez pas réserver ce jour')
        } else {
          handleDateClick({ dateStr: findEvent.date_debut })

        }
      }
      return;
    }

    setLoading(true);
    const date = new Date(e.dateStr);
    date.setDate(date.getDate() + 1);

    const newTT = {
      code_generated: localStorage.getItem('code_generated'),
      user_id: localStorage.getItem('id'),
      motif: 'TT',
      type: 'teletravail',
      date_debut: e.dateStr,
      date_fin: date.toISOString().split('T')[0],
    };

    try {
      const res = await addTeletravail(newTT);
      if (res && res.status === 200) {
        toast.success("Télétravail ajouté");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      toast.error("Erreur: " + error.message);
    } finally {
      setLoading(false);
    }
  };



  const handleDatesSet = (e) => {
    const datedebut = e.startStr.split('T')[0]
    const dateFin = e.endStr.split('T')[0]
    getTeletravaiByCodeAndDate(datedebut, dateFin)
      .then((res) => {
        console.log('API response:', res);
        const newEvents = res.data.map((d) => ({
          title: `${d.nom} ${d.prenom}`,
          start: d.date_debut,
          end: d.date_fin,
          color: "#36f",
          allDay: true,
          id: d.id,
          valide: d.valide,
          img: d.img,
          fonction: d.fonction,
          user_id: d.user_id,
          image: d.img == null || d.img === "" ? "assets/images/users/avatar.png" : localStorage.getItem("baseUrl") + d.img,
        }));
        setEvents(newEvents);
      })

  };


useEffect(()=>{
  console.log("events",events)
},[events])


  const renderEventContent = (eventInfo) => (
    <Event>
      <ImageEvent>
        <img className="avatar" src={eventInfo.event._def.extendedProps.image} alt={eventInfo.event.title} />
      </ImageEvent>
      <TextEvent>
        <h5>{eventInfo.event.title}</h5>
      </TextEvent>
    </Event>
  );

  return (
    <div className="row">
      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header border-0">
            <h4 className="card-title">{t("Calendrier Tele travail")}</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="row">
                  <div className="col-sm-12">

                    <FullCalendar
                      defaultView="dayGridMonth"
                      header={{
                        left: t("prev,next today"),
                        center: t("title"),
                        right: t("dayGridMonth,timeGridWeek,timeGridDay,listWeek"),
                      }}
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Remove unused plugins
                      weekends={false}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      events={events} // Consider optimizing the data here
                      eventContent={renderEventContent} // Ensure this is optimized
                      datesSet={handleDatesSet} // Use debounce if necessary
                    />


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeleTravailCalendar;
