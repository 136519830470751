import React from "react";
import {
  Sparklines,
  SparklinesBars,
  SparklinesReferenceLine,
  SparklinesNormalBand,
  SparklinesSpots,
  SparklinesLine,
} from "react-sparklines";
import { convertTimeToSecond } from "../../../Utils/GlobalTableUtils";

function SpotsSparklineFacture({ factures }) {
  let listValues = [];
  factures.forEach((element) => {
    listValues.push(element.montant_total_ttc);
  });
  // listValues.sort(function (a, b) {
  //   return b - a;
  // });

  return (
    <Sparklines data={listValues.length > 10 ? listValues.slice(0, 20) : listValues}>
      <SparklinesLine
        size={4}
        style={{
          stroke: "#43DC80",
          strokeWidth: 3,
          fill: "white",
        }}
      />
    </Sparklines>
  );
}

export default SpotsSparklineFacture;
