import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import dayListPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router";
import { getRetardByUserId } from "../../Services/Pointeuse/RetardApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";

const Event = styled.div`
  display: inline-flex;
  font-weight: bold;
  vertical-align: top;
`;
const ImageEvent = styled.div`
  flex: 1;
  height: 100%;
`;

const TextEvent = styled.div`
  flex: 1;
  text-align: center;
  margin-left: 5px;
  margin-top: 4px;
`;
function EventRetard({ userId }) {
  const calendarComponentRef = useRef();
  const modalRef = useRef();
  const closeModal = useRef();
  const history = useHistory();
  const [initialEvent, setinitialEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [events, setevents] = useState([]);

  const getData = () => {
    setLoading(true);
    getRetardByUserId(userId)
      .then((res) => {
        console.log(res.data);
        const newEvents = res.data.map((d) => ({
          title: d.time,
          date: d.createdAt.split(" ")[0],
          color: "#f6d7d2",
          allDay: true,
          type: "holiday",
        }));
        setevents(newEvents);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, [userId]);

  function renderEventContent(eventInfo) {
    console.log(eventInfo);
    return (
      <Event>
        {/* <ImageEvent>
          <img className="avatar" src={eventInfo.event._def.extendedProps.image} />
        </ImageEvent> */}
        <TextEvent>
          <h5>{eventInfo.event.title}</h5>
        </TextEvent>
      </Event>
    );
  }

  return (
    <div key={userId} className="card">
      <div className="card-body">
        <div className="hrevent-calender">
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          {!loading && error && <ErrorCustum></ErrorCustum>}
          {!loading && !error && data && (
            <div className="table-responsive">
              <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="hr-table_length">
                      {/* <label>
          Afficher{" "}
          <select
            value={size}
            className="form-control"
            id="exampleFormControlSelect1"
            onChange={handleChangeSize}
          >
            <option value={8}>8</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={100}>100</option>
          </select>{" "}
          entrées
        </label> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div id="hr-table_filter" className="dataTables_filter">
                      {/* <Link to="#" className="action-btns" data-tip="télécharger">
                            <i className="feather feather-download  text-secondary" />
                          </Link>

                          <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <FullCalendar
                      defaultView="dayGridMonth"
                      header={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                      }}
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, dayListPlugin]}
                      weekends={false}
                      //   dateClick={handleDateClick}
                      //   eventClick={handleEventClick}
                      events={events}
                      ref={calendarComponentRef}
                      firstDay={1}
                      selectable={true}
                      themeSystem="bootstrap"
                      displayEventTime={true}
                      locale="fr"
                      aspectRatio={1.9}
                      longPressDelay={1}
                      editable={false}
                      eventContent={renderEventContent}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventRetard;
