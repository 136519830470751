import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageComponent from "../../Components/Styles/ImageComponent";
import { getAutorisationByUserId } from "../../Services/Pointeuse/AutorisationApi";
import { getCongeByUserCodeId, getCountCongeByUserCodeId } from "../../Services/Pointeuse/CongeApi";
import { getDepalacementByUserId } from "../../Services/Pointeuse/DeplacementApi";
import { getPersonnelById } from "../../Services/Pointeuse/PersonnelApi";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { getQuestionnaireByUserId } from "../../Services/Pointeuse/QuestionnaireApi";
import { getRetardByUserId } from "../../Services/Pointeuse/RetardApi";
import { compareDate } from "../../Utils/DateUtils";
import {
  calcBusinessDays,
  calculateAbsentHoure,
  calculateSuppTime,
  calculateSuppTimePositive,
  filterAutorisationByMonth,
  filterCongesByMonth,
  filterDeplacementByMonth,
  filterPointageByMonth,
  filterQuestionnaireByMonth,
  filterRetardByMonth,
  getAverageHoure,
  getCalculateAbsente,
  getLastPointage,
  getTotalHoure,
  getTotalHoureWithoutBreak,
  sumRetardTimeByMonth,
} from "../../Utils/GlobalTableUtils";

function SingleTable({
  user,
  currentDate,
  totalConge,
  cp,
  workDay,
  monthRetard,
  breakTime,
  dataRetards,
  dataPointages,
  dataDeplacements,
  dataConges,
  dataPersonnels,
  dataAutorisation,
  dataQuestionnaires,
}) {
  const [retards, setretards] = useState(null);
  const [pointages, setpointages] = useState(null);
  const [conges, setConges] = useState(null);
  const [deplacements, setdeplacements] = useState(null);
  const [autorisations, setautorisation] = useState(null);
  const [questionnaire, setquestionnaire] = useState(null);
  const [contConge, setcontConge] = useState("0");
  const [dateFinContrat, setdateFinContrat] = useState(null);

  //TODO change here
  useEffect(() => {
    // const filterById = dataPointages.filter((el) => el.user_code_id == user.id);
    // setpointages(filterPointageByMonth(filterById, currentDate));

    getPointageByUserId(user.id).then((res) => {
      const filtredPointage = filterPointageByMonth(res.data, currentDate);
      setpointages(filtredPointage);
      // console.log(filterPointageByMonth(res.data, currentDate));
    });
  }, [user, currentDate, totalConge, cp, breakTime]);
  useEffect(() => {
    getCountCongeByUserCodeId(user.id).then((res) => {
      var key = Object.entries(res.data[0]);
      key[0][1] && setcontConge(key[0][1]);
    });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataRetards.filter((el) => el.user_id == user.id);
    setretards(filterRetardByMonth(filterById, currentDate));
    // getRetardByUserId(user.id).then((res) => {
    //   setretards(filterRetardByMonth(res.data, currentDate));
    // });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataConges.filter((el) => el.user_code_id == user.id);
    setConges(filterCongesByMonth(filterById, currentDate));

    // getCongeByUserCodeId(user.id).then((res) => {
    //   setConges(filterCongesByMonth(res.data, currentDate));
    // });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataDeplacements.filter((el) => el.user_code_id == user.id);
    setdeplacements(filterDeplacementByMonth(filterById, currentDate));
    // getDepalacementByUserId(user.id).then((res) => {
    //   setdeplacements(filterDeplacementByMonth(res.data, currentDate));
    // });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataAutorisation.filter((el) => el.user_code_id == user.id);
    setautorisation(filterAutorisationByMonth(filterById, currentDate));

    // getAutorisationByUserId(user.id).then((res) => {
    //   setautorisation(filterAutorisationByMonth(res.data, currentDate));
    // });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataQuestionnaires.filter((el) => el.user_id == user.id);
    setquestionnaire(filterQuestionnaireByMonth(filterById, currentDate));

    // getQuestionnaireByUserId(user.id).then((res) => {
    //   setquestionnaire(filterQuestionnaireByMonth(res.data, currentDate));
    // });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataPersonnels.filter((el) => el.user_code_id == user.id);
    setdateFinContrat(filterById[0]?.date_fincontrat);
    // getPersonnelById(user.id).then((res) => {
    //   setdateFinContrat(res.data.date_fincontrat);
    // });
  }, [user]);
   return (
    <tr key={user.id}>
      <td>
        {dateFinContrat && compareDate(dateFinContrat) && (
          <Link to={"/admin/users/" + user.id}>
            <div className="mt-1 ml-1">
              <span className="feather feather-info text-danger" data-toggle="tooltip" data-placement="top" title="Contrat expiré" />
            </div>
          </Link>
        )}
      </td>
      <td style={{ minWidth: 80 }}>
        <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={user.img}></ImageComponent>
        {/* <img
          src={
            user.img == null || user.img == "" ? "dist/img/user/avatar.png" : localStorage.getItem("baseUrl") + user.img
          }
          alt="Avatar"
          className="avatar"
        ></img> */}
      </td>
      <td>
        <Link style={{ fontSize: 13 }} to={"/admin/users/" + user.id}>
          {user.nom} {user.prenom}
        </Link>
      </td>
      <td style={{ fontSize: 13 }}>{user.fonction}</td>
      {/* vaccination */}
      {/* à corriger */}
      {/* <td>
        {user.vaccin == "1" ? (
          <span className="badge badge-success ml-1">oui</span>
        ) : (
          <span className="badge badge-danger ml-1">non</span>
        )}
      </td> */}
      {/* jour de travail en cours */}
      {/* {pointages && <td>{calcBusinessDays(currentDate)}</td>} */}
      {/* jour de travail du mois */}
      {/* {pointages && <td>{getDaysInMonth(currentDate)}</td>} */}
      {/* reste jour de travail du mois */}
      {/* {pointages && <td>{getDaysInMonth(currentDate) - calcBusinessDays(currentDate)}</td>} */}
      {/* pointages */}
      {pointages && (
        <td>
          <Link to="/admin/jourpointage">
            <span className="badge badge-success-light">{pointages.length}</span>
          </Link>
        </td>
      )}
      {/* absent */}
      {/* à corriger */}
      {pointages && conges && deplacements && (
        <td>
          <Link to="/admin/jourpointage">
            <span className="badge badge-danger-light">{getCalculateAbsente(pointages, currentDate, conges.length, cp, deplacements.length)}</span>
          </Link>
        </td>
      )}
      {/* retard */}
      {retards && (
        <td>
          <Link to="/admin/retard"></Link>
          <span className="badge badge-warning-light">{retards?.length}</span>
        </td>
      )}
      {/* conge */}
      {conges && (
        <td>
          <Link to="/admin/congevalide">{conges.length}</Link>
        </td>
      )}
      {/* teletravail */}
      <td>0</td>
      {/* questionnaire */}
      {questionnaire && (
        <td>
          <Link to="/admin/questionnaire"> {questionnaire.length}</Link>
        </td>
      )}
      {/* pointage sortie */}
      {pointages && (
        <td>
          <Link to="/admin/jourpointage">
            <span className="badge badge-primary-light">{getLastPointage(pointages)}</span>
          </Link>

          {/* /{pointages.length} */}
        </td>
      )}
      {/* cumul heure */}
      {/* {pointages && <td>{getTotalHoure(pointages)}</td>} */}
      {/* cumul heure sans pause */}
      {pointages && <td>{getTotalHoureWithoutBreak(pointages, breakTime)}</td>}
      {/*  heure par mois */}
      {/* {pointages && <td>{workDay * getDaysInMonth(currentDate)}:00:00</td>} */}

      {/* cumule heure supplimentaire */}
      {pointages && <td>{calculateSuppTime(workDay, currentDate, getTotalHoureWithoutBreak(pointages, breakTime))}</td>}

      {/* cumule heure supplimentaire */}
      {pointages && <td>{calculateSuppTimePositive(workDay, currentDate, getTotalHoureWithoutBreak(pointages, breakTime))}</td>}

      {/* moyene par jour */}
      {pointages && <td>{getAverageHoure(pointages, breakTime) == "0-1:00:00" ? "00:00:00" : getAverageHoure(pointages, breakTime)}</td>}
      {/* heure d'absence*/}
      {/* {pointages && <td>{calculateAbsentHoure(workDay, calcBusinessDays(currentDate), getTotalHoure(pointages))}</td>} */}
      {/* cumul retard */}
      {retards && (
        <td>
          <Link to="/admin/retard">
            <small>{sumRetardTimeByMonth(retards, currentDate)}</small>
          </Link>
        </td>
      )}
      {/* {questionnaire && <td>{questionnaire.length}</td>} */}
      {/* pointage entre */}
      {/* {pointages && (
        <td>
          {getAbsentByMonth(pointages, currentDate)}/{getDaysInMonth(currentDate)}
        </td>
      )} */}
      {/* consommé conge */}
      {contConge && (
        <td>
          <Link to="/admin/congevalide">{contConge}</Link>
        </td>
      )}
      {/* reste conge */}
      {totalConge && (
        <td>
          {" "}
          <Link to="/admin/congevalide">{totalConge - contConge}</Link>
        </td>
      )}
      {/* autorisation */}
      {autorisations && (
        <td>
          {" "}
          <Link to="/admin/autorisation">{autorisations.length}</Link>
        </td>
      )}
      {/* deplacement */}
      {deplacements && <td>{deplacements.length}</td>}
      {/* status */}
      {/* <td>
        {user.isactif == 0 ? (
          <span className="badge badge-warning">Inactif</span>
        ) : (
          <span className="badge badge-primary">actif</span>
        )}
      </td> */}
    </tr>
  );
}

export default SingleTable;
