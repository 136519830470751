import axios from "axios";
import { getSession, getToken } from "../../Utils/SessionUtils";
import moment from "moment";
import { t } from "i18next";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets a user by id.
 * @param id - The id of the user you want to get.
 * @returns The response object contains the data, headers, and status code.
 */
export async function getUserById(id) {
  try {
    const response = await axios.get(api_url + "users/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * It gets a user by fonction.
 * @param id - The id of the user you want to get.
 * @returns The response object contains the data, headers, and status code.
 */
export async function getUsersupport() {
  try {
    const code_generated=localStorage.getItem('code_generated')
    const response = await axios.get(api_url + "users/getUsersupport/" +code_generated, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Get all users by code
 * @returns The response is an array of users.
 */
export async function getUsersByCode() {
  try {
    const response = await axios.get(api_url + "users/codealluseractif/" + localStorage.getItem("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Get all users actif by code
 * @returns The response is an array of objects.
 */

export async function getUsersActifByCode() {
  try {
    const response = await axios.get(api_url + "users/codealluseractif/" + localStorage.getItem("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all users + personnel+totaux actif by code
 * @returns The response is an array of objects.
 */

export async function getuser_personnel_actif(date) {
  try {
    const response = await axios.get(api_url + "users/getuser_personnel_actif/" + localStorage.getItem("code_generated")+'/'+date, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all users + personnel+totaux  by code
 * @returns The response is an array of objects.
 */

export async function getuser_personnel(date) {
  try {
    const response = await axios.get(api_url + "users/getuser_personnel/" + localStorage.getItem("code_generated")+'/'+date, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Get the user's personnel information by their code
 * @returns The response is an array of objects.
 */
export async function getUsersPersonnelByCode() {
  try {
    const response = await axios.get(api_url + "users/codeuserpersonnel/" + localStorage.getItem("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It logs in a user.
 * @param email - The email address of the user.
 * @param password - The password of the user.
 * @returns The response is an object with a status code, data, and a message.
 */
export async function userLogin(email, password) {
  const credentiel = { email: email, password: password };
  console.log(api_url);
  try {
    const response = await axios.post(api_url + "users/login", credentiel);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Send an email to the user to confirm his/her registration
 * @param email - The email address of the user.
 * @param nom - The user's last name.
 * @param prenom - The user's first name.
 * @returns The response is an object with a status code and a message.
 */
export async function sendEmailSuccessRegister(email, nom, prenom) {
  const credentiel = { email: email, nom: nom, prenom: prenom };
  try {
    const response = await axios.post(api_url + "registeremail", credentiel);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Edit a user profile
 * @param id - The id of the user you want to update.
 * @param nom - The user's last name.
 * @param prenom - The user's first name.
 * @param fonction - The user's job title.
 * @param mobile - The user's mobile number.
 * @returns The response is an object with a status code and a message.
 */
export async function editUserProfile(id, nom, prenom, fonction, mobile, coeffConge,nationalite,adresse,longitude,latitude) {
  const credentiel = {
    id: id,
    nom: nom,
    adresse:adresse,
    prenom: prenom,
    fonction: fonction,
    mobile: mobile,
    coefficient_conge: coeffConge,
    nationalite:nationalite,
    longitude:longitude,
    latitude:latitude
  };
  try {
    const response = await axios.patch(api_url + "users/updateprofile", credentiel, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Edit the status of a user
 * @param id - The id of the user you want to update.
 * @param isactif - Boolean value that indicates if the user is active or not.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": "string",
 *   "isactif": "boolean",
 *   "createdAt": "string",
 *   "updatedAt": "string"
 * }
 * ```
 */
export async function editUserStatus(id, isactif) {
  const credentiel = {
    id: id,
    isactif: isactif,
  };
  try {
    const response = await axios.patch(api_url + "users/updatestatus", credentiel, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the documents from the collection where the userid is equal to the userid passed in
 * @param userid - The userid of the user you want to get the documents for.
 * @returns The response is an array of objects.
 */
export async function getDocumentByUserId(userid) {
  try {
    const response = await axios.get(api_url + "annonce/userid/" + userid, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It registers a user in the database.
 * @param nom - The last name of the user.
 * @param prenom - The user's first name.
 * @param mobile - the phone number of the user
 * @param date - the date of birth of the user
 * @param email - The email address of the user.
 * @param password - The password of the user.
 * @param code - The code generated by the API
 * @returns The response from the server.
 */
export async function registerUser(nom, prenom, mobile, date, email, password, code) {
  const credentiel = {
    email: email,
    nom: nom,
    prenom: prenom,
    code_generated: code,
    password: password,
    birth_date: date,
    role_code: "user",
    isactif: "1",
    mobile: mobile,
    fonction: "dev",
    solde_conge: "21",
  };
  try {
    const response = await axios.post(api_url + "users/register", credentiel);
    //toast.success("Utilisateur ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It adds an admin user to the database.
 * @param nom - The last name of the user.
 * @param prenom - The first name of the user.
 * @param mobile - the phone number of the user
 * @param date - the date of birth of the user
 * @param gender - "M" or "F"
 * @param email - The email address of the user.
 * @param password - The password of the user.
 * @param fin - date of the end of the contract
 * @param nbuser - number of users to be created
 * @returns The response from the server.
 */
export async function addAdminAfterPaiement(nom, prenom, mobile, date, gender, email, password, fin, nbuser) {
  const credentiel = {
    email: email,
    nom: nom,
    prenom: prenom,
    code_generated: "",
    password: password,
    birth_date: date,
    gender: gender,
    role_code: "admin",
    isactif: "1",
    mobile: mobile,
    fonction: "",
    solde_conge: "21",
    debut_contrat: moment(new Date()).format("YYYY-MM-DD"),
    fin_contrat: fin,
    nb_users: nbuser,
  };
  try {
    const response = await axios.post(api_url + "users/register", credentiel);
    //toast.success("Utilisateur ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It gets all users from the database.
 * @returns The response is an array of objects. Each object contains the user's name, email, and id.
 */
export async function getAllUsers() {
  try {
    const response = await axios.get(api_url + "users/getAllUsers/"+localStorage.getItem('code_generated'), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * It gets all users from the database.
 * @returns The response is an array of objects. Each object contains the user's name, email, and id.
 */
export async function getUsersPointage() {
  try {
    const response = await axios.get(api_url + "users/getAllUsersPointage/", config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It register a new user in the database
 * @param email - The email address of the user.
 * @param nom - The last name of the user.
 * @param prenom - The user's first name.
 * @param password - The password of the user.
 * @param birth - date of birth
 * @param mobile - the user's mobile number
 * @returns The response from the server.
 */
export async function registerNewUser(email, nom, prenom,gender, password, birth, mobile , user_copie) {
  const credentiel = {
    email: email,
    nom: nom,
    prenom: prenom,
    gender:gender,
    code_generated: getSession("code_generated"),
    password: password,
    birth_date: birth,
    role_code: "user",
    isactif: "1",
    mobile: mobile,
    fonction: "dev",
    solde_conge: "21",
    Otheruser:user_copie
  };
  try {
    const response = await axios.post(api_url + "users/register", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data)
      toast.error(t(error.response.data.message?.sqlMessage  ));

    } else if (error.request) {

      toast.error(t("error request"));
      console.log(error.request);
    } else {
      toast.error(t("error.message"));

      console.log(t("Error"), error.message);
    }
    return error;
  }
}

/**
 * Send a code to the user's email address
 * @param code - The code that was sent to the user's email address.
 * @param email - The email address of the user who is requesting a code.
 * @returns The response from the server.
 */
export async function sendCodeEmail(code, email) {
  // const credentiel = {
  //   email: getSession("email"),
  //   code: code,
  // };

  const credentiel = {
    email: email,
    code: code,
  };
  try {
    const response = await axios.post(api_url + "logincode", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteUserById` is a function that takes in an id and deletes the user with that id
 * @param id - The id of the user you want to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteUserById(id) {
  try {
    const response = await axios.delete(api_url + "users/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add the code generated by the user to the database
 * @param code - The code generated by the user.
 * @returns The response from the server.
 */
export async function addCode(code) {
  const credentiel = {
    id: getSession("id"),
    code_generated: code,
  };
  try {
    const response = await axios.patch(api_url + "users/", credentiel, config);
    console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
