import React from "react";

/**
 * This function returns a set of buttons that can be used to display tooltips
 * @returns The JSX code above is being returned.
 */
function ButtonHeaderComponent() {
  return (
    <>
      <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
        <i className="feather feather-mail" />
      </button>
      <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
        <i className="feather feather-phone-call" />
      </button>
      <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
        <i className="feather feather-info" />
      </button>
    </>
  );
}

export default ButtonHeaderComponent;
