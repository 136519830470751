import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { getPersonnelByCode } from "../../Services/Pointeuse/PersonnelApi";
import { addSalaire } from "../../Services/Pointeuse/salaireApi";
import { sumMontantVirement, sumSalaire } from "../../Utils/ObjectOperation";
import { convertAmount } from "../../Utils/ConvertToCurrency_third";
import { compareDate } from "../../Utils/DateUtils";
import "./Virement.css";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function VirementSalaire() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const history = useHistory();
  const [date, setdate] = useState(new Date().toISOString().slice(0, 10));
  const [data, setData] = useState([]);
  const [inputList, setInputList] = useState([{ code: "", cin: "", nom: "", rib: "", banque: "", montant: "", userId: "" }]);
  const [entrepriseData, setEntrepriseData] = useState({
    adresse: "",
    banque: "",
    code_generated: "",
    email: "",
    fax: "",
    id: "",
    logo: "",
    matricule_fiscal: "",
    mobile: "",
    nb_employe: "",
    nom: "",
    numero_cnss: "",
    rib: "",
    secteur_activite: "",
    start_year: "",
    website_url: "",
    reference: "",
    nom: "",
    prenom: "",
  });
  const [sequence, setsequence] = useState(parseInt(Date.now() * Math.random()));

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      Object.keys(entrepriseData).map(function (key, index) {
        entrepriseData[key] = res.data[key];
      });
    });
  }, []);
  useEffect(() => {
    getPersonnelByCode().then((res) => {
      const finalResult = res.data.filter((el) => {
        return !compareDate(el.date_fincontrat);
      });

      const options = finalResult.map((d) => ({
        code: sequence,
        cin: d.cin,
        nom: d.nom + " " + d.prenom,
        rib: d.rib,
        banque: d.bank,
        montant: d.salaire_base,
        userId: d.user_code_id,
      }));

      setInputList(options);
    });
  }, []);

  // handle input change
  /**
   * It takes in an event and an index and sets the name and value of the input at that index to the
   * value of the event's target.
   * @param e - The event object that contains the data of the event that triggered the function.
   * @param index - The index of the input in the list.
   */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  /**
   * *Remove the item at the given index from the input list.*
   * @param index - The index of the item to remove.
   */
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  /**
   * Add a new input line to the list of inputs
   */
  const handleAddClick = () => {
    setInputList([...inputList, { code: "", cin: "", nom: "", rib: "", banque: "", montant: "", userId: "" }]);
  };

  /**
   * It adds the salaire to the database.
   */
  const handleSaveSalaire = () => {
    //const sequence = parseInt(Date.now() * Math.random());
    // inputList.forEach((element) => {
    //   addSalaire(element, date, sequence);
    // });

    for (const item of inputList) {
      //do something
      addSalaire(item, date, sequence);
    }
    history.push("/admin/salaire");
  };

  /**
   * Prints the current page
   */
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="card p-4 mt-4">
      <h4 className="page-title mb-4">{t("Virement Salaire")}</h4>
      <div className="container-fluid">
        <>
          <div className="row">
            <div className="image-fluid border">
              <img
                src={entrepriseData.logo == null || entrepriseData.logo == "" ? "" : localStorage.getItem("baseUrl") + entrepriseData.logo}
                alt=""
              ></img>
            </div>
            <div className="ml-4 border">
              <p>{t("SOCIETE")} {entrepriseData.nom}</p>
              <p>
                {t("ADRESSE")} : {entrepriseData.adresse}
                -Ariana
              </p>
              <p>{t("MF")}: {entrepriseData.matricule_fiscal}</p>
              <p>{t("TEL")}: {entrepriseData.mobile}</p>
              <p>{t("Mail")} :{entrepriseData.email}</p>
            </div>
            <div className="ml-4"></div>
          </div>

          <div className="text-right">
            <p>{t("Tunis le")} {new Date().toISOString().slice(0, 10)}</p>
            <p>{t("Notre référence")} : {sequence}</p>
            {/* <p>Notre référence : {entrepriseData.reference}</p> */}
            <div className="noprint" style={{ width: 300, float: "right" }}>
              <input className="form-control" type="month" onChange={(e) => setdate(e.target.value)} />
            </div>
          </div>

          <div>
            <p>{t("Monsieur")}, {t("Madame")} {entrepriseData.banque}</p>
            <p>{t("Par le débit de notre compte n°")} {entrepriseData.rib} {t("ouvert dans nos livres")}.</p>
            <p>{t("Veuillez procéder au(x) virement(s) suivant(s) pour le(s) salaire(s) du mois de choisir")} {date}</p>
          </div>
        </>

        <div>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">{t("Code")}</th>
                <th scope="col">{t("C.I.N")}</th>
                <th scope="col">{t("Nom & Prénom")}</th>
                <th scope="col">{t("RIB")}</th>
                <th scope="col">{t("BANQUE")}</th>
                <th scope="col">{t("Montant")}</th>
              </tr>
            </thead>
            <tbody>
              {inputList.map((x, i) => (
                <tr key={i}>
                  <td style={{ minWidth: "170px" }}>
                    <input
                      className="form-control"
                      type="text"
                      name="code"
                      placeholder="..."
                      value={x.code}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 130 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="cin"
                      placeholder="..."
                      value={x.cin}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 250 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="nom"
                      placeholder="..."
                      value={x.nom}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 320 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="rib"
                      placeholder="..."
                      value={x.rib}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="banque"
                      placeholder="..."
                      value={x.banque}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>
                  <td style={{ minWidth: 100 }}>
                    <input
                      className="form-control"
                      type="text"
                      name="montant"
                      placeholder="..."
                      value={x.montant}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </td>

                  <td className="noprint">
                    {inputList.length - 1 === i && (
                      <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                        <i className="fa fa-plus" />
                      </Link>
                    )}
                    {inputList.length !== 1 && (
                      <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                        <i className="fa fa-minus" />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <p>
            {t("Soit un montant total de")}: {sumMontantVirement(inputList)} {convertAmount(sumMontantVirement(inputList))}
            {t("Millimes")}.
          </p>
        </div>
        <div className="noprint text-right">
          {/* <p>Signature</p> */}

          {/* <button type="button" className="btn btn-default float-right mr-1" onClick={handleSaveSalaire}>
            <i className="fas fa-plus-circle"> Ajouter</i>
          </button> */}
          <Link to="/admin/users" target="_blank" rel="noopener noreferrer">
            <button className="btn btn-default mr-2">
              <i className="fa fa-eye text-secondary"> {t("Détail personnel")} </i>
            </button>
          </Link>

          <button type="button" className="btn btn-default float-right mr-1" onClick={handleSaveSalaire}>
            <i className="feather feather-plus-circle text-default"> {t("Ajouter")}</i>
          </button>
          <button type="button" className="btn btn-default float-right mr-1">
            <i className="feather feather-printer text-success" onClick={handlePrint}>
              {t("Imprimer")}
            </i>
          </button>
        </div>
        <div className="text-center">
          <strong>{t("Avec nos remerciements")} !</strong>
        </div>
        <div className="mt-4 text-center">
          <p>www.clediss.com</p>
        </div>
      </div>
    </div>
  );
}

export default VirementSalaire;
