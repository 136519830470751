import React from "react";
import swal from "sweetalert";
import toast from "react-hot-toast";

import { NotificationManager } from "react-notifications";

// import { toast } from "react-toastify";
import { useAlert } from "react-alert";

const handleS = () => {
  swal("Good job!", "You clicked the button!", "error");
};
const handleN = () => {
  NotificationManager.info("Info message");
};

function Tasks() {
  const alert = useAlert();
  const notify = () => toast("Wow so easy!");

  const notifyy = () =>
    toast.custom((t) => (
      <div className="" style={{ width: 500, borderRadius: "60px", backgroundColor: "#333" }}>
        <div className="p-3 m-2">
          <div className="d-flex align-items-center mt-auto" style={{ float: "left" }}>
            <div
              className="avatar brround avatar-md mr-3"
              style={{ backgroundImage: "url(assets/images/users/16.jpg)" }}
            />
            <div>
              <a className="font-weight-semibold" href="profile.html">
                Anna Ogden
              </a>{" "}
              <small className="d-block text-muted fa-12">2 days ago</small>
            </div>
          </div>
          <div className="border-l border-gray-200 mb-4" style={{ float: "right" }}>
            <a href="#" class="btn btn-pill btn-light">
              Light
            </a>
          </div>
        </div>
      </div>
    ));
  return (
    <>
      <div onClick={handleS}>Sweet</div>

      <div onClick={handleN}>notif</div>

      <button
        onClick={() => {
          alert.success("It's ok now!");
        }}
      >
        Success!
      </button>

      <button onClick={notify}>Notify!</button>

      <div>
        <button onClick={notifyy}>hot-toast</button>
      </div>
    </>
  );
}

export default Tasks;
