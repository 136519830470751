import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  addDocumentType,
  deleteDocumentTypeById,
  getDocumentTypeByCode,
} from "../../Services/Pointeuse/DocumentTypeApi";
import { filterByDocument } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { t } from "i18next";

function ConfigDocument() {
  const refHide = useRef();
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [ids, setids] = useState([]);
  const [nomDocument, setnomDocument] = useState(null);
  const [typeDocument, settypeDocument] = useState(t("recrutement"));

  /**
   * It gets the data from the API and then sorts it by isactif.
   */
  const getData = () => {
    setLoading(true);
    getDocumentTypeByCode()
      .then((res) => {
        const result = res.data;
        const sortActifUser = result.sort((a, b) => b.isactif - a.isactif);
        setdata(sortActifUser);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * Add a new document type to the database
   */
  const handleSave = () => {
    addDocumentType(nomDocument, typeDocument).then((res) => {
      refHide.current.click();
      getData();
    });
  };

  /**
   * It adds or removes the id from the list of ids.
   * @param id - The id of the item you want to add or remove from the list.
   * @returns The return value is a function that takes an id as an argument and returns a function that
   * takes a callback.
   */
  const handleList = (id) => {
    var list = [...ids];
    console.log(list);
    console.log(id);
    if (list.includes(id)) {
      var filteredArray = list.filter(function (e) {
        return e !== id;
      });
      console.log(t("delete"));
      setids(filteredArray);
    } else {
      list.push(id);
      setids(list);
      console.log(t("add"));
    }
  };

  /**
   * It deletes the document type by id.
   */
  const handleDelete = () => {
    if (ids.length > 0) {
      swal({
        title: t("Ëtes-vous sûr ?"),
        text: t("Une fois supprimé, vous ne pourrez pas récupérer les documents appartiennent à cet enregistrement!"),
        icon: "warning",
        // buttons: true,
        buttons: {
          cancel: t("Cancel"), // Traduisez le bouton "Cancel"
          confirm: {
            text: t("OK"), // Traduisez le bouton "OK"
            value: true,
          },
        },
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          for (var i = 0; i < ids.length; i++) {
            deleteDocumentTypeById(ids[i]);
            if (i == ids.length - 1) {
              swal(t("Opération effectuée avec succès!"), {
                icon: "success",
              });
              setTimeout(function () {
                getData();
              }, 2000);
            }
          }
        }
      });
    }
  };

  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    const dataSearch = filterByDocument(allInitialData, e.target.value);
    setdata(dataSearch);
  };

  return (
    <div className="m-2 col-xl-12 col-lg-12">
      <div className="row">
        <div className="col mb-4">
          <Link to="#" className="btn btn-danger mr-2" onClick={handleDelete}>
            <i className="fa fa-trash" /> {t("Supprimer")}
          </Link>
          <Link to="#" className="btn btn-light" data-toggle="modal" data-target="#addDocumentType">
            <i className="fe fe-folder" /> {t("Nouveau document")}
          </Link>
        </div>
        <div className="col col-auto mb-4">
          <div className="form-group w-100">
            <div className="input-icon">
              <span className="input-icon-addon">
                <i className="fe fe-search" />
              </span>
              <input type="text" className="form-control" placeholder={t("Recherche...")} onChange={handleSearch} />
            </div>
          </div>
        </div>
      </div>

      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <table className="table" id="example1">
          <tbody>
            {data.map((el, idx) => (
              <tr key={idx}>
                <td className="align-middle w-5">
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="example-checkbox2"
                      defaultValue="option2"
                      onClick={() => handleList(el.id)}
                    />
                    <span className="custom-control-label" />
                  </label>
                </td>
                <td className="align-middle">
                  <div className="d-flex">
                    <img src="assets/images/files/folder.png" alt="img" className="w-5 h-5 mr-2" />
                    <div className="mt-1">{el.nom}</div>
                  </div>
                </td>
                <td className="text-nowrap align-middle">
                  {el.categorie === "recrutement" && <span>{t("Supports Processus de recrutement")}</span>}
                  {el.categorie === "civil" && <span>{t("Supports Etat Civil")}</span>}
                  {el.categorie === "carriere" && <span>{t("Supports Carrière /Développement")}</span>}
                  {el.categorie === "mouvement" && <span>{t("Supports Mouvements du personnel")}</span>}
                  {el.categorie === "medical" && <span>{t("Supports Dossier Médical")}</span>}
                </td>
                <td className="text-nowrap align-middle"></td>
                <td className="text-nowrap align-middle">{el.createdAt}</td>
                <td className="text-nowrap align-middle">
                  <div className="float-right ml-auto">
                    <div className="btn-group ml-3 mb-0">
                      <Link className="option-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fa fa-ellipsis-v" />
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-edit mr-2" /> {t("éditer")}
                        </a>
                        {/* <a className="dropdown-item" href="#">
                      <i className="fe fe-share mr-2" /> Share
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fe fe-download mr-2" /> Download
                    </a> */}
                        <a className="dropdown-item" href="#">
                          <i className="fe fe-trash mr-2" /> {t("Supprimer")}
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* modal */}
      <div
        className="modal fade"
        id="addDocumentType"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {t("Modal title")}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="inputPassword4">Nom</label>
                  <input type="text" className="form-control" onChange={(e) => setnomDocument(e.target.value)} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    {t("Type document")}<span className="text-danger">*</span>
                  </label>

                  <select className="selectpicker form-control" onChange={(e) => settypeDocument(e.target.value)}>
                    <option value="recrutement">{t("Supports Processus de recrutement")}</option>
                    <option value="civil">{t("Supports Etat Civil")}</option>
                    <option value="carriere">{t("Supports Carrière /Développement")}</option>
                    <option value="mouvement">{t("Supports Mouvements du personnel")}</option>
                    <option value="medical">{t("Supports Dossier Médical")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={refHide}>
                {t("Annuler")}
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSave}>
                {t("Enregistrer")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigDocument;
