import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets the holiday by code.
 * @returns The holiday object.
 */
export async function getHolidayByCode() {
  try {
    const response = await axios.get(api_url + "holiday/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets a holiday by id.
 * @param id - The id of the holiday you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "id": 1,
 *   "name": "New Year's Day",
 *   "date": "2019-01-01",
 *   "country": "US",
 *   "country_code": "US",
 *   "state": "NY",
 *   "state_code": "
 */
export async function getHolidayById(id) {
  try {
    const response = await axios.get(api_url + "holiday/id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * `deleteHolidayById` is a function that deletes a holiday by id
 * @param id - The id of the holiday to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteHolidayById(id) {
  try {
    const response = await axios.delete(api_url + "holiday/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a holiday to the database.
 * @param libelle - the name of the holiday
 * @param date - date of the holiday
 * @param nbJour - number of days
 * @param type - string
 * @returns The response is an object with the following properties:
 */
export async function addHoliday(libelle, date, nbJour, type) {
  const credentiel = {
    code_generated: localStorage.getItem("code_generated"),
    libelle: libelle,
    date: date,
    nbJour: nbJour,
    type: type,
  };
  try {
    const response = await axios.post(api_url + "holiday/", credentiel, config);
    console.log(response);
    toast.success(t("ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It updates a holiday.
 * @param libelle - the name of the holiday
 * @param date - date of the holiday
 * @param nbJour - number of days
 * @param type - type of holiday (ex: public holiday, national holiday, etc.)
 * @param id - the id of the holiday to update
 * @returns The response is an object with the following properties:
 */
export async function updateHoliday(libelle, date, nbJour, type, id) {
  const credentiel = {
    libelle: libelle,
    date: date,
    nbJour: nbJour,
    type: type,
    id: id,
  };
  try {
    const response = await axios.put(api_url + "holiday", credentiel, config);
    console.log(response);
    toast.success(t("mise à jour"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
