import React, { useEffect, useState } from 'react'
import { addjour_ferire, getExercice, updatejour_ferire } from '../../../Services/Pointeuse/EntrepriseApi';

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import Form from 'react-bootstrap/Form';
import ReactSelect from "react-select";
import { LAYER } from 'bizcharts';
import { handleKeyPress_number } from '../../../Utils/InputUtils';
import { toast } from 'react-toastify';
import { IoRefresh } from "react-icons/io5";


export default function AjouterJourFerie({ objdata, getData }) {
  const listmois = ['Janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];
  const list_date = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

  const [modif, setmodif] = useState(false)
  const [mois_selected, setmois_selected] = useState(0)
  const [jours_selected, setjours_selected] = useState('01')
  const [data, setdata] = useState(

    {

      libelle: '',
      date: '01-01',
      nbre_jours: 0,
      type: 'CP',
      code_generated: localStorage.getItem('code_generated')
    }


  )

  useEffect(() => {

    if (objdata && objdata?.id) {
      setmodif(true)
      setdata({
        id: objdata.id,
        libelle: objdata.libelle,
        date: objdata.date,
        nbre_jours: objdata.nbre_jours,
        type: objdata.type,
        code_generated: localStorage.getItem('code_generated')
      })
    }
    const findindex_mois = parseInt(objdata?.date?.split('-')[1])-1
    setmois_selected(findindex_mois)
    setjours_selected(objdata?.date?.split('-')[0])
  }, [objdata])


  const handelchange = (name, value) => {
   
    const copiedata = { ...data }
    if( name === 'jour'){
      setjours_selected(value)
      copiedata.date = value+'-'+copiedata.date.split('-')[1]
    }
    else if(name === 'mois'){
      setmois_selected(value)

      let mois = parseInt(value)+1
      mois = mois < 10 ? '0'+mois : mois
      copiedata.date = copiedata.date.split('-')[0]+'-'+mois


    }else{
      copiedata[name] = value

    }

    setdata(copiedata)

  }
  const handelsave = () => {
    const newdata = { ...data };
    if(newdata.libelle === '' && newdata.nbre_jours === 0){
      toast.warning(' remplir le champs de libelle et du nombre des jours')
    }else {
    if (data && data?.id) {

      updatejour_ferire(newdata).then((res) => {
        if (res.status === 200) {
          toast.success(t("le jour  fériés est modifié"))

          setdata({

            libelle: '',
            date: '01-01',
            nbre_jours: 0,
            type: 'CP',
            code_generated: localStorage.getItem('code_generated')
          });
          setmois_selected(0)
          setjours_selected('01')
          getData();
        }
      });
    } else {
   
      addjour_ferire(newdata).then((res) => {
        if (res.status === 201) {
          toast.success(t("le jour  fériés  est enregistrée"))

          setdata({

            libelle: '',
            date: '01-01',
            nbre_jours: 0,
            type: 'CP',
            code_generated: localStorage.getItem('code_generated')
          });
          setmois_selected(0)
          setjours_selected('01')
          getData();
        }
      });
    }
  }

  }
  const viderobj = () => {
    setdata({

      libelle: '',
      date: '',
      nbre_jours: 0,
      type: 'CP',
      code_generated: localStorage.getItem('code_generated')
    }
    )
    setmois_selected(0)
    setjours_selected('01')
  }
  return (
    <div>


      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content adminsetting-content" id="setting-tabContent">
            <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
              <div className="card">

                <div className="card-body">
                  <div className=" row btn btn-primary float-md-right" onClick={viderobj}><IoRefresh /></div>
                  <div className='row mt-5 '>
                    <div className='col-6'>
                      <label>{t('Libelle')}</label>
                      <input type='text' className="form-control" value={data.libelle} onChange={(e) => handelchange('libelle', e.target.value)} />
                    </div>
                    <div className='col-6'>
                      <label>{t('Type')}</label>
                      <select className="form-control" value={data.type} onChange={(e) => handelchange('type', e.target.value)} >
                        <option value='CP'>
                          {t('CP')}
                        </option>
                        <option value='CNP'>
                          {t('CNP')}
                        </option>
                      </select>
                    </div>


                  </div>
                  <div className='row'>
                  <div className='col-3'>
                      <label>{t('Jours')}</label>
                      <select className="form-control" value={jours_selected} onChange={(e) => handelchange('jour', e.target.value)} >
                        {list_date.map((item ,index)=>(
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-3'>
                      <label>{t('Mois')}</label>
                      <select className="form-control" value={mois_selected} onChange={(e) => handelchange('mois', e.target.value)} >
                        {listmois.map((item ,index)=>(
                          <option key={index} value={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-6'>
                      <label>{t('Nombre de jours')}</label>
                      <input type='text' onKeyPress={handleKeyPress_number} readOnly={modif} className="form-control" value={data.nbre_jours} onChange={(e) => handelchange('nbre_jours', e.target.value)} />
                    </div>
                  </div>



                </div>
                <div className="card-footer float-md-right">
                  {modif ? (
                    <Link to="#" onClick={() => setmodif(false)} className="btn btn-primary float-md-right">
                      {t("Modifier")}
                    </Link>
                  ) :
                    <Link to="#" onClick={handelsave} className="btn btn-primary float-md-right">
                      {t("Enregistrer")}
                    </Link>}

                  {/* <a href="#" className="btn btn-danger">
                    Cancel
                  </a> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
