import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all user-time for the current user
 * @returns The response is an array of objects.
 */
export async function getUserTimeByUserId(userId) {
  try {
    const response = await axios.get(api_url + "user-time/users/" + userId, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function postUserTime(user_id, debut, fin, heures, retard) {
  const credentiel = {
    user_id: user_id,
    debut: debut,
    fin: fin,
    heures: heures,
    daily_retard: retard,
  };
  try {
    const response = await axios.post(api_url + "user-time", credentiel, config);
    //toast.success("Utilisateur ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function updateUserTime(id, debut, fin, heures, retard) {
  const credentiel = {
    id: id,
    debut: debut,
    fin: fin,
    heures: heures,
    daily_retard: retard,
  };
  try {
    const response = await axios.patch(api_url + "user-time", credentiel, config);
    //toast.success("Utilisateur ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
