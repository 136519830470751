import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteTasById, getTasksByCode } from "../../Services/Pointeuse/TasksApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { groupByProject } from "../../Utils/GroupeUtils";
import { reduceObject } from "../../Utils/ObjectOperation";
import { filterTasksByWord } from "../../Utils/SortObject";

import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
//simport AddTask from "./AddTask";
import swal from "sweetalert";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import { t } from "i18next";
import { toast } from "react-toastify";
import { deleteById, getListReunion } from "../../Services/Pointeuse/Reunionclient";
import { getClientSupportByCode, getFacturationClientByCode } from "../../Services/Facturation/FacturationClientApi";
import { convertMinutesToHous } from "../../Utils/helper";
import Select from 'react-select';

function ListReunionClient() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [users, setusers] = useState([]);
  const [size, setsize] = useState(14);
  const [isOpened, setIsOpened] = useState(false);
  const [clientcode,setclientcode]=useState()
  const [date,setdate]=useState()

  const history = useHistory();
  

  useEffect(() => {
    getData();
  }, []);

  const [clients, setclients] = useState([])

  
  useEffect(() => {
    getClientSupportByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom ,
        secondValue: d.id,
      }));
      setclients(options);
    });
  }, [])

  const getData = () => {
    setLoading(true);
    getListReunion()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };



  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the target element.
   */
  const handleSearch = (e) => {
    const dataSearch = filterTasksByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };
  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const handledate = (value) => {setdate(value);setcurrentData(allInitialData.filter((el) => el.date.split("T")[0] == value));}
  const filterclient = (selectedOption) => {setclientcode(selectedOption.value);setcurrentData(allInitialData.filter((el) => el.client_code === selectedOption.value));
    
  }

  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  const navtoReunion= (el) => {
    const roleuser = localStorage.getItem('role_code')
  
    if (roleuser !== 'admin') {
      const codeuser = localStorage.getItem('id')
      if (codeuser !== el.user_id) {
        toast.warning('vous ne pouvez pas accéder a cette tache')
      } else {
        history.push("/admin/updatereunion/" + el.id);

      }
    } else {
      history.push("/user/updatereunion/" + el.id);

    }
  }

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Reunion")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to={localStorage.getItem('role_code') === 'admin' ? '/admin/creereunion' : '/user/creereunion'} className="btn btn-primary mr-3" data-toggle="modal" data-target="#newtaskmodal">
                {t("Ajouter Reunion")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

    

      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
            

              <div className="row">
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">{t("Date")}:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input
                            className="form-control fc-datepicker"
                            placeholder="DD-MM-YYYY"
                            type="date"
                            value={date}
                            onChange={(e) => handledate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">{t("Client")}:</label>
                        <div className="input-group">
                         
                         

                          <Select
                            onChange={filterclient}
                             className=" form-control"
                             value={clients.find(item => item.value === clientcode)||''}

                            options={clients}
                            placeholder={t("Choisir un client")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block" onClick={()=>{setdate('') ; setclientcode('');getData()}}>
                      {t("Réinitialiser")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List réunions")}</h4>
            </div>

            <div className="card-body">


              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>
                          <ExportCsv data={data} name={"List_tâches"} />
                          <ExportPdf data={data} name={"List_tâches"} columns={["object", "description", "etat", "priority", "date_fin"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table  table-vcenter text-nowrap table-bordered border-bottom" id="task-list">
                          <thead>
                            <tr>
                              <th className="border-bottom-0">{t("User")}</th>
                              <th className="border-bottom-0">{t("client")}</th>
                              <th className="border-bottom-0">{t("date")}</th>
                              <th className="border-bottom-0">{t("heure debut")}</th>
                              <th className="border-bottom-0">{t("heure fin")}</th>
                              <th className="border-bottom-0">{t("durée")}</th>
                              <th className="border-bottom-0">{t("Objet")}</th>
                              <th className="border-bottom-0">{t("Actions")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                <td>
                                  <a href="task-profile.html" className="d-flex">
                                    <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                                    <div className="mr-3 mt-0 mt-sm-2 d-block">
                                      <h6 className="mb-1 fs-14">
                                        <Link to={"/admin/users/" + el.user_id}>
                                          {el.usernom} {el.userprenom}
                                        </Link>
                                      </h6>
                                    </div>
                                  </a>
                                </td>
                                <td>
                                  <Link to={el.cc !== null ? "/admin/client/" + el.cc : "#"} className="d-flex sidebarmodal-collpase">
                                    {el.client_nom} {' '}{el.client_prenom}
                                  </Link>
                                </td>

                               
                                <td>{el.date && el.date.split("T")[0]}</td>
                                <td>{el.heure_depart && el.heure_depart}</td>

                                <td>{el.heure_final && el.heure_final}</td>
                                  <td>{convertMinutesToHous(el.duree)}</td>
                                <td>{el.objet}</td>

                                <td>
                                  <div className="d-flex">
                                    
                                    <a onClick={()=>navtoReunion(el)} className="action-btns1">
                                      <i
                                        className="feather feather-edit-2  text-success"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit task"
                                      />
                                    </a>
                                    <Link
                                      to="#"
                                      className="action-btns1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete Task"
                                      onClick={() => handleDelete(el.id)}
                                    >
                                      <i className="feather feather-trash-2 text-danger" />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

      </div>



    </>
  );
}

export default ListReunionClient;
