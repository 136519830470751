// Card.jsx
import React, { useState } from 'react';
import Table from './Table';
import { Link } from "react-router-dom";

function Card({ title, active, data, onDataChange, onClick, onAddRow, onSaveRow }) {
    const [isCollapsed, setisCollapsed] = useState(false);
  
    if (!title && !Array.isArray(data) || data.length === 0) {
      return null;
    }
  
    const handleCollapse = () => {
      setisCollapsed(!isCollapsed);
    };
  
    return (
      <div className={`card1 ${active ? 'active' : ''}`} onClick={onClick}>
        <h3 className='title'>{title}</h3>
      </div>
    );
  }
  
  export default Card;
