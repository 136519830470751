import React, { useEffect, useState } from 'react';
import { getAllDevise } from '../../Services/devise/devise';
import { useTranslation } from 'react-i18next';
import { savebanque, updatebanque } from '../../Services/banque';
import { toast } from 'react-toastify';

export default function AjoutBanque(data) {
    const { t } = useTranslation();

    const [listdevise, setListDevise] = useState([]);
    const [formData, setFormData] = useState({
        
        banque: '',
        num_compte: '',
        devise_id: ''
    });
    useEffect(()=>{
        if(data&&data?.data?.id){
            
            setFormData({
                id:data.data.id,
                banque: data.data.banque,
                num_compte: data.data.num_compte,
                devise_id: data.data.devise_id
            })
        }
    },[data])

    useEffect(() => {
        getAllDevise().then((res) => {
            if (res.status === 200) {
                setListDevise(res.data.data);
            }
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newdata = {...formData}
        newdata.code_generated= localStorage.getItem('code_generated')
        if(data&&data?.data?.id){
            updatebanque(newdata).then((res)=>{
                if(res.status === 200){
                    toast.success(t('La banque est modifié'))
                    window.location.reload()
                }
            });
        }else{
            savebanque(newdata).then((res)=>{
                if(res.status === 201){
                    toast.success(t('La banque est enregistrée'))
                    window.location.reload()
                }
            });
        }
      
    };
    

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className='row mt-2'>
                    <label>Banque</label>
                    <input type='text' name='banque' className='form-control' value={formData.banque} onChange={handleInputChange} required />
                    
                </div>
                <div className='row mt-2'>
                    <label>Numéro de compte</label>
                    <input type='text' name='num_compte' className='form-control' value={formData.num_compte} onChange={handleInputChange} required />
                </div>
                <div className='row mt-2'>
                    <label>Devise</label>
                    <select className='form-control' name='devise_id' value={formData.devise_id} onChange={handleInputChange} required>
                        <option value=''>{t('Select Devise')}</option>
                        {listdevise.map(item => (
                            <option key={item.id} value={item.id}>{item.code_devise}</option>
                        ))}
                    </select>
                </div>
                <div className='row mt-3'>
                    <button type="submit" className="btn btn-primary float-right">
                        <i className="far fa-credit-card" /> {t("Enregistrer")}
                    </button>
                </div>
            </form>
        </div>
    );
}
