/**
 * Find all the unique values in an array and return an array of objects with the value and its
 * occurrence
 * @param arr - The array to be searched.
 * @param key - The key to be searched for in the array.
 * @returns An array of objects with the key and occurrence.
 */
export function findOcc(arr, key) {
  let arr2 = [];
  arr.forEach((x) => {
    // Checking if there is any object in arr2
    // which contains the key value
    if (
      arr2.some((val) => {
        return val[key] == x[key];
      })
    ) {
      // If yes! then increase the occurrence by 1
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      // If not! Then create a new object initialize
      // it with the present iteration key's value and
      // set the occurrence to 1
      let a = {};
      a[key] = x[key];
      x["occurrence"] = 1;
      arr2.push(x);
    }
  });

  return arr2;
}
