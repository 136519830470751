import React from "react";
import HeroSection from "../../HeroSection";
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from "./Data";
import Pricing from "../../Pricing";
import "./Services.css";

function Services() {
  return (
    <div className="container-fluid mb-5">
      <div className="text-center mt-5">
        <h5>
          Allégez votre fardeau de gestion grâce à un système de pointage moderne qui s’adapte parfaitement à votre
          milieu de travail
        </h5>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="box">
            <div className="our-services settings">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/Pointage mobile.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Pointage Arrivées et départs</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="our-services speedup">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/geolocalisation.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Géolocalisation des pointages</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="our-services privacy">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/time.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Gestion du temps de travail</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="our-services privacy">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/3942588.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Suivi des activités & Tâches</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="box">
            <div className="our-services backups">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/calendar.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Demandes d'absences & congés</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="our-services ssl">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/documents.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Demandes documents administratif</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="our-services database">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/chat.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Chat avec les collaborateurs</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="box">
            <div className="our-services privacy">
              <div className="icon">
                {" "}
                <img src="assets/images/welcome/news.svg" style={{ width: 100, height: 100 }} />
              </div>
              <h4>Annonces internes & Actualités du jour</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
