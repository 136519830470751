import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the salaires for the current user
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": [
 *     {
 *       "id": "1",
 *       "code_salaire": "Salaire de base",
 *       "montant": "1000",
 *       "date_debut": "2020-01-01",
 *       "date_fin":
 */
export async function getAllSalaires() {
  try {
    const response = await axios.get(api_url + "salaire/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getSalaireByUserId(id) {
  try {
    return await axios.get(`${api_url}salaire/${id}`);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get all the salaires for a given code virement
 * @param code - The code of the virement.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": [
 *     {
 *       "id": "1",
 *       "code": "1",
 *       "nom": "Dupont",
 *       "prenom": "Jean",
 *       "salaire": "1500"
 *     },
 *     {
 *       "
 */
export async function getAllSalairesVirement(code) {
  try {
    const response = await axios.get(api_url + "salaire/codevirement/" + code, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It adds a new salaire to the database.
 * @param data - the data that will be sent to the server
 * @param date - the date of the salaire
 * @param sequence - the sequence of the salaire
 * @returns The response from the server.
 */
export async function addSalaire(data, date, sequence) {
  const credentiel = {
    code_generated: localStorage.getItem("code_generated"),
    user_id: data.userId,
    code: sequence,
    cin: data.cin,
    nom: data.nom,
    rib: data.rib,
    banque: data.banque,
    salaire: data.montant,
    mois: date.split("-")[1],
    annee: date.split("-")[0],
  };
  try {
    const response = await axios.post(api_url + "salaire/", credentiel, config);
    console.log(response);
    //toast.success("ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a salaire by its code.
 * @param code - The code of the salaire to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "1",
 *     "code": "Salaire1",
 *     "montant": "1000",
 *     "date": "2020-01-01",
 *     "employe": {
 *       "id": "1",
 *       "
 */
export async function deleteSalaireByCode(code) {
  try {
    const response = await axios.delete(api_url + "salaire/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateNumBanque(num_banque, valide, code) {
  const obj = { num_banque: num_banque, valide: valide, code: code };
  try {
    const response = await axios.patch(api_url + "salaire", obj, config);
    console.log(response);
    //toast.success("ajouté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
