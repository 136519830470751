import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { deleteClientById, getAllClientsFactures, getFacturationClientByCode, getFacturationClientsByCode } from "../../../Services/Facturation/FacturationClientApi";
import { filterFournisseurByWord, filterSerieByWord } from "../../../Utils/SortObject";
import CopieContent from "../../Exports/CopieContent";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import Pagination from "../../Others/Pagination"; 
import { t } from "i18next";
import { getAllSerie } from "../../../Services/Facturation/FacturationSerieApi";
import SerieCardHeader from "./SerieCardHeader";
import ExportCsvSeries from "../../Exports/ExportCsvSeries";
import moment from "moment";
import { error_message } from "../../alerte/AlerteMessage";


function NumeroSerie() {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(100);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [numeroserie2, setnumeroserie2] = useState("");
  const [numeroserie1, setnumeroserie1] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate,   setEndDate]   =   useState(moment().endOf('month').format('YYYY-MM-DD'));
  const handleChange = (e) => {
    setnumeroserie2(e.target.value);
  };
  const handleChangeNumserie = (e) => {
    setnumeroserie1(e.target.value);
  };
 
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  const getData = () => {
    setLoading(true);
    getAllSerie()
      .then((res) => {
         setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);
 
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterSerieByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
   

  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
   
 // In your parent component
const updateCurrentData = (newData) => {
  setcurrentData(newData);
};
const onChangeDate = () => {
  const filteredData = filterByDate(allInitialData, startDate, endDate);
  updateCurrentData(filteredData);
};

const filterByDate = (allInitialData, startDate, endDate) => {
   if (startDate === '' || endDate === '') {
    // Si l'une des dates est vide, ne filtre pas et affiche toutes les données.
    return allInitialData;
  } else {
    const filteredDate = allInitialData.filter((listed) => {
      const momentDate = moment(listed.created_at.split(" ")[0]);
       if (moment(startDate).isAfter(endDate)) {
        error_message(t("Attention, la date de début doit être antérieure à la date de fin !"));
      } else {
        return (
          momentDate.isSameOrAfter(moment(startDate)) &&
          momentDate.isSameOrBefore(moment(endDate).endOf('day')) // Ensure to include the end date
        );
      }
    });
    return filteredDate;
  }
}; 


return (
    <>
    <SerieCardHeader getData={getData}  handleChange={handleChange} numeroserie2={numeroserie2} updateCurrentData={updateCurrentData} handleChangeNumserie={handleChangeNumserie} numeroserie1={numeroserie1}/>
    <div>
      <div className="page-header d-xl-flex d-block">
        
         
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"  
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                           />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                           onChange={(e) => setEndDate(e.target.value)} 
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Année</option>
                          <option value={1}>{new Date().getFullYear()}</option>
                          <option value={2}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div> */}
      
                  </div>
                </div>
                {/* <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Liste des Numéro Série")}</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div> 
            <div className="card-body">
              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>
                           <ExportCsvSeries data={data} name={"List_series"} />
                          <ExportPdf
                            data={data}
                            name={"List_series"}
                            columns={["id", "numero_serie", "produit", "type", "fournisseur"]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">#{t("ID")}</th>
                              <th className="border-bottom-0">{t("Numéro de Série")}</th>
                              <th className="border-bottom-0">{t("Produit")}</th>
                              <th className="border-bottom-0">{t("Fournisseur")}  </th> 
                              <th className="border-bottom-0">{t("Date")}</th>
                              </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx}>
                                 <td># {el.id}</td>
                                  <td> 
                                    <div className="mr-3 mt-0 mt-sm-2 d-block">
                                      <h6 className="mb-1 fs-14">
                                        {el.numero_serie}  
                                      </h6>
                                    </div>
                                 </td>
                                <td>{el.produit}</td>
                                <td>
                                  <span>{el.fournisseur}</span>
                                </td>
                                <td>{moment(el.created_at).format("DD-MM-YYYY")}</td>
                                  
                              </tr>
                            ))}
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default NumeroSerie;
