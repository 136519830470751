import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import moment from "moment";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get Projet by code
 */
export async function getProjetByCode() {
  try {
    const response = await axios.get(api_url + "projets/projet/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * get Projet by id
 */
export async function getProjetById(id) {
  try {
    const response = await axios.get(api_url + "projets/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteProjetById(id) {
  try {
    const response = await axios.delete(api_url + "projets/projet/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addNewProjet(nom) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_code_id: getSession("id"),
    nom: nom,
    etat: "afaire",
    priority: "0",
    budget: "0",
  };

  try {
    const response = await axios.post(api_url + "projets/projet/", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function upadateProjetById(nom, description, etat, commentaire, dateDebut, dateFin, responsable_id, priority, budget, id) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_code_id: getSession("id"),
    nom: nom,
    description: description || "",
    etat: etat,
    commentaire: commentaire || "",
    date_debut: dateDebut || moment(new Date()).format("YYYY-MM-DD"),
    priority: priority,
    date_fin: dateFin || moment(new Date()).format("YYYY-MM-DD"),
    responsable_id: responsable_id || getSession("id"),
    budget: budget,
    id: id,
  };
  console.log(credentiel);

  try {
    const response = await axios.patch(api_url + "projets/projet/", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
