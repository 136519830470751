import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { editUserStatus, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { filterAutorisationByWord, filterUserByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import { acceptAutorisation, getAllAutorisation, senFcmAutorisation } from "../../Services/Pointeuse/AutorisationApi";
import PieChartJsAutorisationHoure from "../Charts/CustumChartJs/PieChartJsAutorisationHoure";
import PieChartJsAutorisationStatus from "../Charts/CustumChartJs/PieChartJsAutorisationStatus";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import firebase from "../../firebase";
import { senFcmConge } from "../../Services/Pointeuse/CongeApi";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import moment from "moment";
import { error_message } from "../alerte/AlerteMessage";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
function Autorisation() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  /**
   * It gets all the autorisation data from the API and sets it to the data variable.
   */
  const getData = () => {
    setLoading(true);
    getAllAutorisation()
      .then((res) => {
        setdata(res.data);
        console.log(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that to update the currentData state
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterAutorisationByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
  /**
   * It filters the data based on the selected value.
   * @param e - The event object.
   */
  const handleSelectAutorisation = (e) => {
    if (e.target.value == 1) {
      setdata(allInitialData.filter((el) => el.type === "demande"));
    } else if (e.target.value == 2) {
      setdata(allInitialData.filter((el) => el.type === "autorisation" && el.etat === 1));
    } else if (e.target.value == 3) {
      setdata(allInitialData.filter((el) => el.type === "autorisation" && el.etat === 0));
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * It accepts the autorisation.
   * @param id - The id of the autorisation you want to accept.
   * @param userid - The userid of the user you want to send a notification to.
   */
  const handleAccept = (id, userid) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté autorisation!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAutorisation(id, "1").then(() => {
          //toast.success("Congé accepté");
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
          sendFirebaseNotification(t("accepté"), userid);
        });
      }
    });
  };

  /**
   * It sends a notification to the user.
   * @param type - The type of notification to send.
   * @param receiver - The user's device token.
   */
  const sendFirebaseNotification = (type, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmAutorisation(type, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It allows the user to accept the autorisation.
   * @param id - The id of the autorisation you want to accept.
   * @param userid - The userid of the user who is being notified.
   */
  const handleDenied = (id, userid) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé autorisation!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAutorisation(id, "0").then(() => {
          //toast.success("Congé accepté");
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
          sendFirebaseNotification("refusé", userid);
        });
      }
    });
  };
  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  const onChangeDate = () => {
 
    const filteredDate = currentData.filter((listed) => {
      const momentDate = moment(listed.date);
      if(startDate > endDate){
        error_message("Attention La date début doit être inférieur à la date fin !!")
      }else{
      return (
        momentDate.isSameOrAfter(moment(startDate)) &&
        momentDate.isSameOrBefore(moment(endDate))
      )};
    });
    setcurrentData(filteredDate);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Autorisations")}</h4>
        </div>
        {/* <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
             
              <ButtonHeaderComponent />
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-8 col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        {/* <label className="form-label">Date:</label> */}
                        <label className="form-label">{t("Date Debut")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date" 
                         
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">{t("Date Fin")}</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control floating" type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                           />
                          <div className="input-group-append">
                    <button
                      onClick={() => onChangeDate()}
                      
                      className="btn btn-success input-group-text"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </div>
                        </div>
                      </div>
                    </div>       
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("List autorisations")}</h4>
              <div className="card-options">
                <Link
                  to="#"
                  className="card-options-fullscreen mr-2"
                  data-toggle="card-fullscreen"
                  onClick={handleCollapse}
                >
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("accepté")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("refusé")}
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> {t("en cours")}
                  </span>
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select
                              value={size}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleChangeSize}
                            >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_autorisations"} />
                          <ExportPdf
                            data={data}
                            name={"List_autorisations"}
                            columns={["nom", "prenom", "objet", "heure_debut", "nb_heure", "type"]}
                          />
                          <CopieContent />

                          {/* <Link to="#" className="action-btns" data-tip="imprimer">
                            <i className="feather feather-printer text-success" />
                          </Link>
                          <Link to="#" className="action-btns" data-tip="partager">
                            <i className="feather feather-share-2 text-warning" />
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5"></th>
                              <th className="border-bottom-0">{t("Motif")}</th>
                              <th className="border-bottom-0">{t("Date")}</th>
                              <th className="border-bottom-0">{t("Heure début")}</th>
                              <th className="border-bottom-0">{t("Nombre heures")} {t("H")}</th>
                              <th className="border-bottom-0">{t("Status")}</th>
                              <th className="border-bottom-0">{t("Actions")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el) => (
                              <tr key={el.id}>
                                <td>
                                  <div className="d-flex">
                                    <ImageComponent
                                      atr={"avatar avatar-md brround mr-3"}
                                      picture={el.img}
                                    ></ImageComponent>
                                    <div className="mr-8 mt-0 mt-sm-1 d-block">
                                      <h6 className="mb-1 fs-14">
                                        <Link to={"/admin/clientactivity/" + el.nom}>{el.nom} {el.prenom}</Link> 
                                        
                                      </h6>
                                      <p className="text-muted mb-0 fs-12">{el.email}</p>
                                    </div>
                                  </div>
                                </td>

                                {/* <td>
                                  <Link to={"/admin/users/" + el.user_code_id}>
                                    {el.nom} {el.prenom}
                                  </Link>
                                </td> */}
                                <td>{el.objet}</td>
                                <td className="text-center">{el.date}</td>
                                <td className="text-center">{el.heure_debut}</td>
                                <td className="text-center">{el.nb_heure}</td>
                                <td className="text-center">
                                  {el.type === "demande" ? (
                                    <span className="badge badge-warning">{t("demande")}</span>
                                  ) : el.etat === 1 ? (
                                    <span className="badge badge-success">{t("accepté")}</span>
                                  ) : (
                                    <span className="badge badge-danger">{t("refusé")}</span>
                                  )}
                                </td>
                                <td className="text-left">
                                  {el.type === "demande" ? (
                                    <>
                                      <Link
                                        to="#"
                                        className="action-btns"
                                        data-tip="accepter"
                                        onClick={() => handleAccept(el.id, el.user_code_id)}
                                      >
                                        {/* <i className="feather feather-edit text-info" /> */}
                                        <i className="fa fa-check-circle text-success"></i>
                                      </Link>
                                      <Link
                                        to="#"
                                        className="action-btns"
                                        data-tip="refuser"
                                        onClick={() => handleDenied(el.id, el.user_code_id)}
                                      >
                                        {/* <i className="feather feather-edit text-info" /> */}
                                        <i className="fa fa-ban text-danger"></i>
                                      </Link>
                                    </>
                                  ) : (
                                    <p></p>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-md-12 col-lg-6">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Status par autorisation")}</h4>
              {/* <div className="card-options">
                <div className="dropdown">
                  {" "}
                  <a href="#" className="btn btn-outline-light" data-toggle="dropdown" aria-expanded="false">
                    {" "}
                    Week <i className="feather feather-chevron-down" />{" "}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li>
                      <a href="#">Monthly</a>
                    </li>
                    <li>
                      <a href="#">Yearly</a>
                    </li>
                    <li>
                      <a href="#">Weekly</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="card-body">
              <div className="mt-5">
                <PieChartJsAutorisationStatus />
              </div>
              <div className="sales-chart mt-4 row text-center">
                {/* <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  On progress
                </div> */}
                {/* <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-secondary mr-2 my-auto" />
                  male
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-light4  mr-2 my-auto" />
                  femelle
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12 col-lg-6">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="card-title">{t("Nombre d'heure par autorisation")}</h4>
              {/* <div className="card-options">
                <div className="dropdown">
                  {" "}
                  <a href="#" className="btn btn-outline-light" data-toggle="dropdown" aria-expanded="false">
                    {" "}
                    View All <i className="feather feather-chevron-down" />{" "}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li>
                      <a href="#">Monthly</a>
                    </li>
                    <li>
                      <a href="#">Yearly</a>
                    </li>
                    <li>
                      <a href="#">Weekly</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="card-body">
              <div className="mt-5">
                <PieChartJsAutorisationHoure />
              </div>
              {/* <div className="sales-chart mt-4 row text-center">
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-primary mr-2 my-auto" />
                  On progress
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-secondary mr-2 my-auto" />
                  male
                </div>
                <div className="d-flex my-auto col-sm-4 mx-auto text-center justify-content-center">
                  <span className="dot-label bg-light4  mr-2 my-auto" />
                  femelle
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Autorisation;
