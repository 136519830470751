var numberFinal;
var iWords = ["zero", "un ", "deux ", "trois ", "quatre ", "cinq ", "six ", "sept ", "huit ", "neuf "];
var ePlace = [
  "dix ",
  "onze ",
  "douze ",
  "treize ",
  "quatorz ",
  "quinze ",
  "seize ",
  "dix-sept ",
  "dix-huit ",
  "dix-neuf ",
];
var tensPlace = [
  "",
  "dix ",
  "vingt ",
  "trente ",
  "quarante ",
  "cinquante ",
  "soixante ",
  "soixante-dix ",
  "quatre-vingts ",
  "quatre-vingt-dix ",
];
var inWords = [];

var numReversed, inWords, actnumber, i, j;

/**
 * If the number is 0, then the word is empty. If the number is 1, then the word is the word for the
 * previous number. If the number is not 0 or 1, then the word is the word for the number
 */
export function tensComplication() {
  if (actnumber[i] == 0) {
    inWords[j] = "";
  } else if (actnumber[i] == 1) {
    inWords[j] = ePlace[actnumber[i - 1]];
  } else {
    inWords[j] = tensPlace[actnumber[i]];
  }
}

/**
 * Convert the amount to taka and paisa
 * @param totalRent - The total rent amount.
 * @returns a string.
 */
export function convertAmount(totalRent) {
  // var numericValue = Number(totalRent.replace(/[\s.]+/g, "").trim());

  var numericValue = parseFloat(totalRent).toFixed(3);

  var amount = numericValue.toString().split(".");
  var taka = amount[0];
  var paisa = amount[1];
  numberFinal = convert(taka) + " dinars et " + convert(paisa) + " millimes";
  return numberFinal;
  //document.getElementById('container').innerHTML = convert(taka) +" taka and "+ convert(paisa)+" paisa only";
}
/**
 * The function takes a numeric value and converts it to words
 * @param numericValue - The numeric value of the amount to be converted.
 * @returns the word equivalent of the numeric value.
 */
export function convert(numericValue) {
  inWords = [];
  if (numericValue == "00" || numericValue == "0") {
    return "zero";
  }
  var obStr = numericValue.toString();
  numReversed = obStr.split("");
  actnumber = numReversed.reverse();

  if (Number(numericValue) == 0) {
    numberFinal = "BDT Zero";
    //document.getElementById('container').innerHTML = 'BDT Zero';
    return "zéro";
  }

  var iWordsLength = numReversed.length;
  var finalWord = "";
  j = 0;
  for (i = 0; i < iWordsLength; i++) {
    switch (i) {
      case 0:
        if (actnumber[i] == "0" || actnumber[i + 1] == "1") {
          inWords[j] = "";
        } else {
          inWords[j] = iWords[actnumber[i]];
        }
        inWords[j] = inWords[j] + "";
        break;
      case 1:
        tensComplication();
        break;
      case 2:
        if (actnumber[i] == "0") {
          inWords[j] = "";
        } else if (actnumber[i - 1] !== "0" && actnumber[i - 2] !== "0") {
          inWords[j] = iWords[actnumber[i]] + " cents ";
        } else {
          inWords[j] = iWords[actnumber[i]] + " cents ";
        }
        break;
      case 3:
        if (actnumber[i] == "0" || actnumber[i + 1] == "1") {
          inWords[j] = "";
        } else {
          inWords[j] = iWords[actnumber[i]];
        }
        if (actnumber[i + 1] !== "0" || actnumber[i] > "0") {
          inWords[j] = inWords[j] + " mille et ";
        }
        break;
      case 4:
        tensComplication();
        break;
      case 5:
        if (actnumber[i] == "0" || actnumber[i + 1] == "1") {
          inWords[j] = "";
        } else {
          inWords[j] = iWords[actnumber[i]];
        }
        if (actnumber[i + 1] !== "0" || actnumber[i] > "0") {
          inWords[j] = inWords[j] + " cent ";
        }
        break;
      case 6:
        tensComplication();
        break;
      case 7:
        if (actnumber[i] == "0" || actnumber[i + 1] == "1") {
          inWords[j] = "";
        } else {
          inWords[j] = iWords[actnumber[i]];
        }
        inWords[j] = inWords[j] + " crore";
        break;
      case 8:
        tensComplication();
        break;
      default:
        break;
    }
    j++;
  }

  inWords.reverse();
  for (i = 0; i < inWords.length; i++) {
    finalWord += inWords[i];
  }
  return finalWord;
}
