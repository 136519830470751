import React, { useEffect, useState } from "react";
// import AddConge from "./AddConge";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import { getAllAttestationByCode } from "../../Services/Pointeuse/AttestationApi";

function AttestationCardHeader({data,allInitialData,setdata}) {

  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t

  useEffect(() => {
    getAllAttestationByCode().then((res) => {
      setdata(res.data);
    });
  }, []);
// Filtrer les attestations valides (valid === 1)
const attestationsValides = data.filter(data => data.valid === 1);

// Filtrer les attestations valides (valid === 1)
const attestationsNonValides = data.filter(data => data.valid === 0);

  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Attestation de Travail")}</h4>
        </div>
        {/* <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#addCongeModal">
                {t("Ajouter congé")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-0 pb-0 d-flex justify-content-center">
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">
                    {data.length}
                  </span>
                  <h5 className="mb-0 mt-3">{t("Total attestation")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">{attestationsValides.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total validés")}</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">{attestationsNonValides.length}</span>
                  <h5 className="mb-0 mt-3">{t("Total non validés")}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      {/* <AddConge getData={props.getData} /> */}
    </>
  );
}

export default AttestationCardHeader;
