import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomSyncLoader from "../../Components/Others/CustomSyncLoader";
import ErrorCustum from "../../Components/Others/ErrorCustum";
import ImageComponent from "../../Components/Styles/ImageComponent";
import { getRetardByCode, sendFcmRetardWargn } from "../../Services/Pointeuse/RetardApi";
import { getfilterMonth } from "../../Utils/ObjectFilter";
import { findOcc } from "../../Utils/OccurenceUtils";
import { sortObjectByValue } from "../../Utils/SortObject";
import firebase from "../../firebase";
import swal from "sweetalert";

function RetardCard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  const getData = () => {
    setLoading(true);
    getRetardByCode()
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 20) {
          const retardMonth = getfilterMonth(res.data, "createdAt");
          const result = findOcc(retardMonth, "user_id");
          setdata(sortObjectByValue(result, "occurrence"));
        } else {
          const retardMonth = getfilterMonth(res.data, "createdAt");
          const result = findOcc(retardMonth, "user_id");
          setdata(sortObjectByValue(result, "occurrence"));
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const sendFirebase = (occurrence, receiver) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment envoyé un avertissement !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const messaging = firebase.messaging();
        messaging
          .requestPermission()
          .then(() => {
            return messaging.getToken();
          })
          .then(() => {
            sendFcmRetardWargn(occurrence, receiver).then((res) => {
              swal(t("Opération effectuée avec succès!"), {
                icon: "success",
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="card">
          <div className="card-header border-bottom-0">
            <h4 className="card-title">{t("Retard consécutif")}</h4>
            <div className="card-options pr-3">
              <div className="dropdown">
                <Link to="#" className="btn ripple btn-outline-light dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                  {t("Ce mois")}
                </Link>
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">{t("Ce mois")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            {data.map((el, idx) => (
              <div key={idx} className="mb-5">
                <div className="list-group-item d-flex p-0  align-items-center border-0">
                  <div className="d-flex">
                    {/* <span
                      className="avatar avatar-lg bradius mr-3"
                      style={{ backgroundImage: "url(assets/images/users/4.jpg)" }}
                    /> */}
                    <ImageComponent atr={"avatar avatar-lg bradius mr-3"} picture={el.img}></ImageComponent>
                    <div className="mt-1">
                      <h6 className="mb-1 font-weight-normal fs-16">
                        {el.nom} {el.prenom}
                        <span className="font-weight-semibold text-muted ml-4 mr-4">
                          <Link
                            to={"/admin/retardcalendar/" + el.user_id}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Voir retard sur calendrier"
                          >
                            {el.occurrence} {t("Retard")}
                          </Link>
                        </span>
                      </h6>
                      <span className="clearfix" />
                      {/* <span className="fs-14 text-muted">Just Now</span> */}
                    </div>
                    <p className="ml-4 float-right">
                      <Link
                        to="#"
                        className="btn btn-outline-warning"
                        onClick={() => sendFirebase(el.occurrence, el.user_id)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Envyer un avertissement"
                      >
                        <i className="fa fa-exclamation mr-2" />
                        Avertir
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default RetardCard;
