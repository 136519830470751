import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPointageByUserId, getcorrectionPointage, updatePointage } from "../../Services/Pointeuse/PointageApi";
import { calcBusinessDays, filterPointageByMonth } from "../../Utils/GlobalTableUtils";
import { Checkin, showDay } from "../../Utils/PointageMonthUtils";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ImageComponent from "../Styles/ImageComponent";
import { toast } from "react-toastify";
import swal from "sweetalert";
import _ from "lodash";

// var dateObj = new Date();
// var result = dateObj.toISOString().split("T")[0];
// var month = result.split("-")[1];
// var year = result.split("-")[0];
// var newdate = year + "-" + month;

function PaieSingleMonthPointage({ user, data, setlist_correction, list_correction, setlist_id_remove, list_id_remove, determineEtat, total, setTotal, hide, t, check_user, setuser_selected, user_selected }) {
  const [pointages, setpointages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const list_date = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']



  /*useEffect(() => {
    if (total && list_correction.length !== 0) {
      const groupedByUser = _.groupBy(list_correction, "user");
    //  setlistcopiepointage(groupedByUser)

       const newtotal = [...total]
       const findindex = total.findIndex(t=>t.user === user.id)
       newtotal[findindex].total = (newtotal[findindex].total ||0)+groupedByUser[user.id]
       setTotal(newtotal)
    
    }
  }, [total ,list_correction]);*/
  const correctPointage = (jr, user, etat) => {
    //console.log('llll',list_correction)

    if (etat !== 'AB' && etat !== 'CR') {
      toast.warning('Vous ne pouvez pas corriger ce jour.');
    } else {
      const newlist = [...list_correction];
      const findIndex = list_correction.findIndex((i) => parseInt(i.jr) === parseInt(jr) && i.user === user.id);
      const finduserindex = total.findIndex((i) => i.user === user.id);
      const newtotal = [...total];

      if (findIndex === -1) {
        swal({
          title: "Corriger pointage !",
          text: "Vous voulez Corriger la date " + jr + " de l'utilisateur " + user.nom + " " + user.prenom,
          icon: "warning",
          buttons: {
            cancel: "Cancel",
            confirm: {
              text: "Confirmer",
              value: true,
            },
          },
          dangerMode: true,
          content: {
            element: "div",
            attributes: {
              innerHTML: `
                      <input id="motifInput" placeholder="Motif" type="text" style="width:100%;" required>
                      <div class='row mt-5'>
                          <label class='col-3' for="conge">Congé</label>
                          <input class='col-3' id="conge" name="presence" type="radio" value="cg" required>
                          <label class='col-3' for="present">Présent</label>
                          <input class='col-3' id="present" name="presence" type="radio" value="p" required>
                      </div>
                      <div class='row mt-5'>
                          <label class='col-3' for="jrs">journée</label>
                          <input class='col-3' id="jrs" name="jrs" type="radio" value="1" required>
                          <label class='col-3' for="d_jrs">demi_journé</label>
                          <input class='col-3' id="d_jrs" name="jrs" type="radio" value="0.5" required>
                      </div>
                  `,
            }
          }
        }).then((value) => {
          if (value) {
            // Vérifie si une option radio est sélectionnée dans chaque groupe
            const motif = document.getElementById('motifInput')?.value;
            const presence = document.querySelector('input[name="presence"]:checked');
            const jrs = document.querySelector('input[name="jrs"]:checked');

            if (presence && jrs) {
              // Toutes les options requises sont sélectionnées
              const presenceValue = presence.value;
              const jrsValue = jrs.value;

              const newData = {
                jr: jr,
                user: user.id,
                obj: motif,
                type_correction: presenceValue,
                duree: jrsValue
              };

              newlist.push(newData);
              setlist_correction(newlist);

              // Ajouter les détails à total
              if (finduserindex !== -1) {
                newtotal[finduserindex].total = parseFloat(newtotal[finduserindex].total) + parseInt(jrsValue);

                newtotal[finduserindex].total_Ab = parseFloat(newtotal[finduserindex].total_Ab) - 0.5;

                setTotal(newtotal);
              } else {
                newtotal.push({ user: user.id, total: parseInt(jrsValue), totalconge: 0, totalP: 0, totalCR: parseInt(jrsValue), totalJF: 0, total_Ab: 0 });
                setTotal(newtotal);
              }
            }
            else {
              // Afficher un message indiquant à l'utilisateur de sélectionner toutes les options nécessaires
              swal("Veuillez sélectionner une option dans chaque groupe", "", "warning");
            }
          }
        });




      }
      else {
        const updatedList = [...list_correction];
        const elementremove = updatedList[findIndex]
        const correction = list_correction[findIndex]
        updatedList.splice(findIndex, 1);
        setlist_correction(updatedList);
        if (elementremove.id) {
          setlist_id_remove((prev) => [...prev, elementremove.id])
        }


        if (finduserindex !== -1) {
          newtotal[finduserindex].total = newtotal[finduserindex].total - parseFloat(correction.duree)
        }
        setTotal(newtotal)
      }

    }
  };

  const select_user = (userid) => {
    const findindexuser = user_selected.findIndex(item => item === userid);
    if (findindexuser !== -1) {
      const list = [...user_selected];
      list.splice(findindexuser, 1);
      setuser_selected(list);
    } else {

      setuser_selected((prev) => [...prev, userid])

    }
  }

  return (
    <>
      {data && (
        <tr key={user.id}>
          <td >
            <div className="d-flex">
              {check_user && <input type='checkbox' onClick={() => select_user(user.id)} checked={user_selected.includes(user.id)} />}
              <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={user.img}></ImageComponent>
              <div className="mr-3 mt-0 mt-sm-2 d-block">
                <h6 className="mb-1 fs-14">
                  {user.nom} {user.prenom}
                </h6>
              </div>
            </div>
          </td>

          {!hide && list_date.map((item) => (
            <td key={item} onClick={(event) => correctPointage(item, user, determineEtat(item, user.id).res)}>
              {determineEtat(item, user).res === 'TR' && <span className="badge present_tag mr-2">
                <i className="feather feather-check-circle " /> {t("P")}
              </span>}
              {determineEtat(item, user).res === 'AB' && <span className="badge Ab_tag mr-2">
                <i className="feather feather-x-circle " /> {t("AB")}
              </span>}
              {determineEtat(item, user).res === 'PM2' && <span className="badge SNP_tag mr-2">
                <i className="feather feather-x-circle " /> {t("SNP")}
              </span>}

              {determineEtat(item, user).res === 'PM1' &&
                <span className="badge ENP_tag mr-2" >
                  <i className="feather feather-x-circle " />  {t("ENP")}
                </span>}
              {determineEtat(item, user).res === 'weekend' &&
               <span className="badge Weekend_tag mr-2">
               <i className="fa fa-calendar " /> {t("WE")}
             </span>}
              {determineEtat(item, user).res === 'C' &&
                 <span className="badge Cg_tag  mr-2">
                 <i className="fa fa-adjust " /> {t("Cg")}
               </span>}
              {determineEtat(item, user).res === 'CR' &&
                <span className="badge coriger_tag mr-2" style={{ color: 'black' }}>
                  <i className="feather feather-check-circle " /> {t("CR")}
                </span>}
              {determineEtat(item, user).res === 'jf' &&

                <span className="badge JF_tag mr-2" >
                  <i className="fa fa-adjust  " /> {t("JF")}
                </span>}
              {determineEtat(item, user).res === 'AB/CR' &&

                <span className="badge Demi_tag mr-2" style={{ color: 'black' }}>
                  <i className="fa fa-adjust  "  /> {t("AB/CR")}
                </span>}
            </td>
          ))}
          <td>{total?.find(t => t.user === user.id)?.totalCR}</td>
          <td>{total?.find(t => t.user === user.id)?.totalJF}</td>
          <td>{total?.find(t => t.user === user.id)?.totalP}</td>
          <td>{total?.find(t => t.user === user.id)?.totalconge}</td>
          <td>{total?.find(t => t.user === user.id)?.total}</td>





        </tr>
      )}
    </>
  );
}

export default PaieSingleMonthPointage;
/* <td onClick={(event) => showPopover(res, item, user.id, event)}>
                <button type="button" class="btn btn-secondary">
                  {res}
                </button>
                <div class="popover" id="popoverContent">
                  <textarea id="editableText" onChange={(e) => setobj(e.target.value)} ></textarea>
                  <button id="saveButton" onClick={() => correctPointage(item, user.id)}>Save</button>
                  <button id="annullerButton" onClick={() => annuler()}>Annuler</button>
                </div>
              </td>*/


/* swal({
      title: "Corriger pointage !",
      text: "Vous voulez Corriger la date " + jr + " de l'utilisateur " + user.nom + " " + user.prenom,
      icon: "warning",
      buttons: {
        cancel: "Cancel", // Bouton "Cancel"
        confirm: {
          text: "Confirmer", // Bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
      content: {
        element: "div", // Utilisation d'un div pour contenir l'input et les boutons radio
        attributes: {
          innerHTML: `
            <input id="motifInput" placeholder="Motif" type="text"  style="width:100% ;" >
            <div  class='row mt-5'>
          
            <label class='col-3 ' for="conge">Congé</label>
            <input class='col-3' id="conge" name="presence" type="radio" value="cg">
            <label class='col-3'  for="present">Présent</label>
            <input  class='col-3' id="present" name="presence" type="radio" value="p">
          
            </div>
            <div  class='row mt-5'>
          
            <label class='col-3 ' for="jrs">journée</label>
            <input class='col-3' id="jrs" name="jrs" type="radio" value="j">
            <label class='col-3'  for="d_jrs">demi_journé</label>
            <input  class='col-3' id="d_jrs" name="d_jrs" type="radio" value="d_j">
          
            </div>
          
          `,      

        }
      }
    }).then((value) => {
        // Traitement lorsque l'utilisateur clique sur OK
        if (value) {
          

          const newData = {
            jr: jr,
            user: user.id,
            obj: value
          };
          newlist.push(newData);
          setlist_correction(newlist);
          //add 1 finduser
          if (finduserindex !== -1) {
            newtotal[finduserindex].total += 1
            setTotal(newtotal)

          } else {
            const newd = {
              user: user.id,
              total: 1
            }
            newtotal.push(newd);
            setTotal(newtotal)


          }



        }
      });*/
