import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { ajouterDeplacement } from "../../Services/Pointeuse/DeplacementApi";

function AddModalDeplacement(props) {
    const [destination, setdestination] = useState("");
    const [date_debut, setdateDebut] = useState("");
    const [date_fin, setdateFin] = useState("");
    const [depart, setdepart] = useState("");
    const myRefDelete = useRef(null);
    const [users, setusers] = useState(null);
    const [userId, setuserId] = useState(null);
  
    useEffect(() => {
      getUsersByCode().then((res) => {
        setusers(res.data);
      });
    }, []);
   const handleChangeDepart =(e)=>{
    setdepart(e.target.value)
   }
   const handleChangeDestination =(e)=>{
    setdestination(e.target.value)
   }
   const handleChangeDateDebut =(e)=>{
    setdateDebut(e.target.value)
   }
   const handleChangeDateFin =(e)=>{
    setdateFin(e.target.value)
   }
    /**
     * It adds a new conge to the database.
     */
    const handelSave = () => {
      ajouterDeplacement(userId, date_debut, date_fin, destination,depart).then((res) => {
        console.log(res);
        myRefDelete.current.click();
        props.getData();
      });
    };
    return (
      <div
        className="modal fade"
        id="addDeplacementModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addDeplacementModalolidayModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {t("Ajouter Déplacement")}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    {t("Collaborateur")}<span className="text-danger">*</span>
                  </label>
  
                  <select onChange={(e) => setuserId(e.target.value)} className="selectpicker form-control">
                    <option value="0">{t("Choisir un collaborateur")}</option>
                    {users &&
                      users.map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.nom} {el.prenom}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
  
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{t("Date debut")}</label>
                  <input type="date" className="form-control" onChange={handleChangeDateDebut} />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{t("Date Fin")}</label>
                  <input type="date" className="form-control" onChange={handleChangeDateFin} />
                </div>
              </div>
              {/* <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    {t("Motif")}<span className="text-danger">*</span>
                  </label>
  
                  <select onChange={(e) => setmotif(e.target.value)} className="selectpicker form-control">
                    <option value={"CONGÉ PAYÉ"}>{t("CONGÉ PAYÉ")}</option>
                    <option value={"CONGÉ SANS SOLDE"}>{t("CONGÉ SANS SOLDE")}</option>
                    <option value={"CONGÉ ANNUEL"}>{t("CONGÉ ANNUEL")}</option>
                    <option value={"CONGÉ D'EXAMEN"}>{t("CONGÉ D'EXAMEN")}</option>
                    <option value={"CONGÉ INDIVIDUEL DE FORMATION"}>{t("CONGÉ INDIVIDUEL DE FORMATION")}</option>
                    <option value={"CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE"}>
                      {t("CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE")}
                    </option>
                    <option value={"CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE"}>{t("CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE")}</option>
                    <option value={"CONGÉ MALADIE"}>{t("CONGÉ MALADIE")}</option>
                    <option value={"CONGÉ MATERNITÉ"}>{t("CONGÉ MATERNITÉ")}</option>
                    <option value={"CONGÉ PATERNITÉ"}>{t("CONGÉ PATERNITÉ")}</option>
                    <option value={"CONGÉ POUR CATASTROPHE NATURELLE"}>{t("CONGÉ POUR CATASTROPHE NATURELLE")}</option>
                    <option value={"CONGÉ SABBATIQUE"}>{t("CONGÉ SABBATIQUE")}</option>
                    <option value={"CONGÉ POUR RAISON FAMILIALE"}>{t("CONGÉ POUR RAISON FAMILIALE")}</option>
                    <option value={"Autre"}>{t("Autre")}</option>
                  </select>
                </div>
              </div> */}
                <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{t("depart")}</label>
                  <input type="text" className="form-control" onChange={handleChangeDepart}/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">{t("destination")}</label>
                  <input type="text" className="form-control" onChange={handleChangeDestination}/>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                {t("Annuler")}
              </button>
              <button type="button" className="btn btn-primary" 
              onClick={handelSave}
              >
                {t("Ajouter")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default AddModalDeplacement;