import React from "react";
import { Link } from "react-router-dom";

function SuccessPage({ title, description }) {
  return (
    <div className="page message-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-6 justify-content-center mx-auto text-center">
            <div className="card">
              <div className="card-body p-8 text-center">
                <img src="assets/images/svgs/check.svg" alt="img" className="w-15" />
                <h3 className="mt-5">Inscription Terminée</h3>
                <p className="mt-3 mb-5"> Well Done!! You access this page which is available.. </p>
                <Link to="login" className="btn ripple btn-primary">
                  Se connecter
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
