import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import StripeScriptLoader from "react-stripe-script-loader";
import PaymentRequestForm from "./PaymentRequestForm";

const PUBLIC_KEY =
  "pk_test_51KVYoGHgmGKXAKwnofa8b2FAW2FoER20303h94iR8t5G2I0s72iuNroWm1f42OBD7ptj8Bot3symQOXuHh2IJCvg00s2sBFawu";

/**
 * This function is a React component that will display a Stripe payment form
 * @returns The StripeScriptLoader component is being returned.
 */
function StripeContainerCard({ amount, months, handleHideNbCollab, nbUsers }) {
  // console.log(amount);
  // console.log(months);
  const stripePromise = loadStripe(PUBLIC_KEY);

  return (
    <StripeScriptLoader uniqueId="myUniqueId" script="https://js.stripe.com/v3/" loader="Chargement...">
      <Elements stripe={stripePromise}>
        <PaymentForm amount={amount} months={months} handleHideNbCollab={handleHideNbCollab} nbUsers={nbUsers} />
      </Elements>
    </StripeScriptLoader>
  );
}

export default StripeContainerCard;
