import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  getClientFournisseurByid,
  updateClientById,
  updateImageClientById,
} from "../../../Services/Facturation/FacturationClientApi";
import moment from "moment";
import { t } from "i18next";
import { handleKeyPress_number } from "../../../Utils/InputUtils";
import { useHistory } from "react-router-dom"
import { getAllDevise } from "../../../Services/devise/devise";
import ReactSelect from "react-select";
import { code_CAT, codetva, getLettreAssociee } from "../../../Utils/Data/Matriculefiscale";
import { getUsersByCode } from "../../../Services/Pointeuse/UsersApi";
import { Form } from "react-bootstrap";

function EditerClient(props) {
  const [stat, setStat] = React.useState("Client");
  const [tarif, setTarif] = React.useState("tarif");
  const [selectedDate, setSelectedDate] = React.useState();
  const [code, setCode] = useState("");
  const [codeBare, setCodeBare] = useState("");
  const [codeActivity, setCodeActivity] = useState("");
  const [magasin, setMagasin] = useState("");
  const [tva, setTva] = useState("");
  const [potentiel, setPotentiel] = useState("");
  const [potentielSoc, setPotentielSoc] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [fixe, setFixe] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [rib, setRib] = useState("");
  const [soldeReleve, setSoldeReleve] = useState("");
  const [codeErp, setCodeErp] = useState("");
  const [tax, setTax] = useState("");
  const [adrFacturation, setAdrFacturation] = useState("");
  const [localite, setLocalite] = useState("");
  const [region, setRegion] = useState("");
  const [gouvernorat, setGouvernorat] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [categorie, setCategorie] = useState("");
  const [codeCommercialCategorie, setCodeCommercialCategorie] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [regimeFiscal, setRegimeFiscal] = useState("");
  const [matriculeFiscal, setMatriculeFiscal] = useState({ num_MF: '', cle_MF: '', codetva: '', codecat: '', codeetab: '000' });
  const [commentaire, setCommentaire] = useState("");
  const [image, setImage] = useState("/dist/img/user/user.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [remise, setRemise] = useState("");
  const [delaiPaiement, setDelaiPaiement] = useState("");
  const [plafondCredit, setPlafondCredit] = useState("");
  const [rc, setRc] = useState("");
  const [deviseselected, setdevise] = useState("");
  const [exonere, setexonere] = useState(0);
  const history = useHistory();
  const [deviseState, setdeviseState] = useState([])
  const image_url = process.env.REACT_APP_API_URL;
  const [users, setusers] = useState(null);
  const [prospect, setProspect] = useState("");

  const [dataProspect, setDataProspect] = useState({
    user_id: '0',
    Promesse: null,
    date: moment(new Date()).format("YYYY-MM-DD"),
  })
  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);
  useEffect(() => {
    getAllDevise().then((res) => {
      if (res.status === 200) {
        setdeviseState(res.data.data);
      }
    });
  }, []);
  useEffect(() => {
    getClientFournisseurByid(props.match.params.id).then((res) => {
      setStat(res.data.statut);
      setTarif(res.data.tarification);
      setSelectedDate(moment(res.data.anniversaire).format("YYYY-MM-DD"));
      setCode(res.data.code);
      setCodeActivity(res.data.activite_code);
      setMagasin(res.data.magasin);
      setNom(res.data.nom);
      setPrenom(res.data.prenom);
      setEmail(res.data.email);
      setFixe(res.data.fixe);
      setMobile(res.data.mobile);
      setDescription(res.data.description);
      setRib(res.data.rib);
      setCodeErp(res.data.code_erp);
      setAdrFacturation(res.data.adresse_facturation);
      setLocalite(res.data.localite);
      setRegion(res.data.region);
      setGouvernorat(res.data.gouvernorat);
      setSecteur(res.data.secteur);
      setZone(res.data.zone);
      setCategorie(res.data.categorie);
      setSpeciality(res.data.specialite);
      setRegimeFiscal(res.data.regime_fiscale);
      setMatriculeFiscal(
        {
          num_MF: res.data.matricule_fiscale?.split('/')[0],
          cle_MF: res.data.matricule_fiscale?.split('/')[1],
          codetva: res.data.matricule_fiscale?.split('/')[2],
          codecat: res.data.matricule_fiscale?.split('/')[3],
          codeetab: res.data.matricule_fiscale?.split('/')[4]
        }
      );
      setCommentaire(res.data.commentaire);
      setRemise(res.data.remise_client);
      setDelaiPaiement(res.data.delai_paiement);
      setPlafondCredit(res.data.plafond_credit);
      setexonere(res.data.exonere)
      setdevise(res.data.devise_id)
      setProspect(res.data.Prospect === 1 ? true : false)
      setDataProspect({
        user_id: res.data.up_user_id,
        client_id: res.data.up_client_id,
        Promesse: res.data.up_Promesse,
        date: moment(new Date(res.data.up_date)).format("YYYY-MM-DD")
      })
      setRc(res.data.rc);
      if (res.data.image) {
        setImage(localStorage.getItem("baseUrl") + res.data.image);
      }
    });
  }, []);

  const handleChangeTarification = (event) => {
    setTarif(event.target.value);
  };

  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
    updateImageClientById(event.target.files[0], props.match.params.id);
  };

  const handleSubmit = () => {

    if (code === '' || deviseselected === '' || codeActivity === '' || adrFacturation === '' || nom === '') {
      toast.warning('Entrez toutes les informations requises *')
    } else {
      if (prospect && (dataProspect.user_id === '0')) {
        toast.warning('Choisir un Collaborateur')

      }
      else {
        const matricule = matriculeFiscal.num_MF + '/' + matriculeFiscal.cle_MF + '/' + matriculeFiscal.codetva + '/' + matriculeFiscal.codecat + '/' + matriculeFiscal.codeetab

        updateClientById(

          dataProspect,
          prospect ? 1 : 0,
          code,
          deviseselected,
          exonere,
          null,
          codeActivity,
          "Client",
          tarif,
          magasin,
          tva,
          potentiel,
          potentielSoc,
          nom,
          prenom,
          cin,
          email,
          selectedDate,
          fixe,
          mobile,
          description,
          rib,
          soldeReleve,
          codeErp,
          tax,
          adrFacturation,
          localite,
          region,
          gouvernorat,
          secteur,
          zone,
          categorie,
          codeCommercialCategorie,
          speciality,
          regimeFiscal,
          matricule,
          commentaire,
          remise,
          delaiPaiement,
          plafondCredit,
          rc,
          props.match.params.id
        ).then((res) => {
          history.push("/admin/client");
        });
      }
    }
  };

  const handleMatriculeFiscalePart1Blur = () => {
    const value = matriculeFiscal.num_MF.padStart(7, "0");
    const lettreAssociee = getLettreAssociee(value);
    setMatriculeFiscal((prev) => ({
      ...prev,
      num_MF: value,
      cle_MF: lettreAssociee

    }));

  };

  const changeNumMf = (name, value) => {
    if (name === 'num_MF') {
      if (value.length <= 7) {
        setMatriculeFiscal((prev) => ({ ...prev, num_MF: value }))
      }
    } else {

      setMatriculeFiscal((prev) => ({ ...prev, [name]: value }))

    }


  }
  const changeProspect = (name, value) => {
    setDataProspect((prev) => ({
      ...prev,
      [name]: value
    }));

  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Détail client")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Code")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCode(e.target.value)}
                  required
                  value={code || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Code Activité")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeActivity(e.target.value)}
                  required
                  value={codeActivity || ""}
                />
              </div>
              {/*
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">{t("Tarification")}</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={handleChangeTarification}
                  value={tarif || ""}
                >
                  <option value="tarif">{t("tarif")}</option>
                  <option value="tarif1">{t("tarif")}1</option>
                  <option value="tarif2">{t("tarif")}2</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  {t("Magasin")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMagasin(e.target.value)}
                  required
                  value={magasin || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Code ERP")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeErp(e.target.value)}
                  required
                  value={codeErp || ""}
                />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Adresse de facturation")} <span className="text-danger">*</span>
                  <small> {t("maximum 40 caractères")}</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  value={adrFacturation || ""}
                  maxLength="40"
                />
              </div>
              <div className="form-group">
                <label>{t("Devise")}</label><span className="text-danger">*</span>
                <ReactSelect
                  value={deviseState && deviseState.find(item => item.id === deviseselected)}
                  options={deviseState}
                  onChange={(e) => setdevise(e.id)}
                  formatOptionLabel={devise => (
                    <div className="country-option">
                      <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                      <span>{devise.libelle}</span>
                    </div>
                  )}
                />
              </div>
              <div className="form-group">
                <label>{t("exonéré")}</label>
                <select className="form-control"

                  value={exonere}
                  onChange={(e) => setexonere(parseInt(e.target.value))}>
                  <option value={0}>Non</option>
                  <option value={1}>Oui</option>
                </select>
              </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Image client")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <div className="d-flex justify-content-center">
                  <img alt="image produit" src={image} className="avatar" />
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      accept="image/*"
                      type="file"
                      id="exampleInputFile"
                      onChange={handleImgClient}
                    />
                    <label className="custom-file-label" htmlFor="exampleInputFile">
                      {imageName}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Information de contact")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Nom")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setNom(e.target.value)}
                  required
                  value={nom || ""}
                />
              </div>
              {/*  <div className="form-group">
                <label>
                  {t("Prénom")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                  value={prenom || ""}
                />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Email")}
                </label>
                <input
                  className="form-control"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  value={email || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Anniversaire")}
                </label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                  value={selectedDate || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Fixe")}
                </label>
                <input className="form-control" type="text" onKeyPress={handleKeyPress_number} onChange={(e) => setFixe(e.target.value)} value={fixe} />
              </div>
              <div className="form-group">
                <label>
                  {t("Mobile")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setMobile(e.target.value)}
                  required
                  onKeyPress={handleKeyPress_number}
                  value={mobile || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Description")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  value={description || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Commentaire")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCommentaire(e.target.value)}
                  required
                  value={commentaire || ""}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>


          </div>
          <div className="card card-primary mt-7">
            <div className="card-header row">
                <div className="col-3">
                  <div className="form-row">
                    <div className="form-group ">
          

                      <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label={t("Prospect")}
                          onChange={() => setProspect(!prospect)}
                          checked={prospect} 
                        />
                      </Form>
                    </div>
                  </div>
                </div>
           
              <div className="col-3">
                <div className="form-row">
                  <div className="form-group ">


                    <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label={t("Changer en client")}
                        onChange={() => setStat(stat === 'Prospect' ? 'Client' : 'Prospect')}
                        checked={stat === 'Client'}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            {prospect && stat==='Prospect' &&
              <div className="card-body row">
                <div className="col-4">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("date")}
                      </label>

                      <input
                        type="date"
                        onChange={(e) => changeProspect('date', e.target.value)}
                        value={dataProspect.date}



                      />
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("Collaborateur")}
                      </label>

                      <select className="selectpicker form-control"
                        onChange={(e) => changeProspect('user_id', e.target.value)}
                        value={dataProspect.user_id} >
                        <option value="0">{t("Choisir un collaborateur")}</option>
                        {users &&
                          users.map((el, idx) => (
                            <option key={idx} value={el.id}>
                              {el.nom} {el.prenom}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("Promesse")}
                      </label>

                      <textarea
                        onChange={(e) => changeProspect('Promesse', e.target.value)}
                        value={dataProspect.Promesse}
                        className="form-control" cols={110} rows={3} />
                    </div>
                  </div>
                </div>

              </div>
            }


          </div>
        </div>
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Information complémentaire")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              {/*  <div className="form-group">
                <label>
                  {t("Catégorie")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCategorie(e.target.value)}
                  required
                  value={categorie || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Spécialité")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setSpeciality(e.target.value)}
                  required
                  value={speciality || ""}
                />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Régime fiscal")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRegimeFiscal(e.target.value)}
                  required
                  value={regimeFiscal || ""}
                />
              </div>
              <div className="form-group row">
                <div className="col-3">
                  <label>{t("Num MF")}</label>
                  <input
                    className="form-control"
                    type="text"
                    onBlur={handleMatriculeFiscalePart1Blur}
                    onChange={(e) => changeNumMf('num_MF', e.target.value)}
                    value={matriculeFiscal.num_MF}
                    required
                  />
                </div>
                <div className="col-2">
                  <label>{t("Cle MF")}</label>
                  <input
                    className="form-control"
                    type="text"
                    value={matriculeFiscal.cle_MF}
                    readOnly
                    required
                  />
                </div>
                <div className="col-2">
                  <label>{t("Code  TVA")}</label>
                  <select className="form-control" value={matriculeFiscal.codetva} onChange={(e) => changeNumMf('codetva', e.target.value)}>
                    {codetva.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-2">
                  <label>{t("Cle MF")}</label>
                  <select className="form-control" value={matriculeFiscal.codecat} onChange={(e) => changeNumMf('codecat', e.target.value)}>
                    {code_CAT.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="col-2">
                  <label>{t("N° Etab")}</label>
                  <input className="form-control" value={matriculeFiscal.codeetab} readOnly />


                </div>


              </div>
              <div className="form-group">
                <label>
                  {t("Remise client")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRemise(e.target.value)}
                  required
                  value={remise || ""}
                />
              </div>
              {/*
              <div className="form-group">
                <label>
                  {t("Delai de paiement")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDelaiPaiement(e.target.value)}
                  required
                  value={delaiPaiement || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Plafond crédit")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setPlafondCredit(e.target.value)}
                  required
                  value={plafondCredit || ""}
                />
            </div>*/}
              <div className="form-group">
                <label>
                  {t("Registre de commerce")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRc(e.target.value)}
                  required
                  value={rc || ""}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("RIB")}
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRib(e.target.value)}
                  required
                  value={rib || ""}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. */}
            </div>
          </div>
        </div>
        {/*<div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Adresse")}</h3>
              <div className="card-tools">*/}
        {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
        {/*</div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Localité")}<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={localite}
                  onChange={(e) => setLocalite(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Région")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>
                  {t("Gouvernorat")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={gouvernorat}
                  onChange={(e) => setGouvernorat(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Secteur")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={secteur}
                  onChange={(e) => setSecteur(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Zone")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="card-footer">
              {/* Visit{" "}
                <a href="https://select2.github.io/">Select2 documentation</a> for
                more examples and information about the plugin. 
            </div>
          </div>
          <div>*
            
          </div>
              </div>*/}
      </div>

      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> {t("Annuler")}
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> {t("Enregistrer")}
          </button>
        </div>
      </div>
    </>
  );
}

export default EditerClient;
