import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * get all tva by code
 */
/**
 * It gets all the tva from the database.
 * @returns The response is an array of objects.
 */
export async function devisToCommande(data) {
  try {
    const response = await axios.put(api_url + "devis/devisToCommande" ,data, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


export async function devisToCommandeVente(data) {
  try {
    const response = await axios.put(api_url + "devisvente/devisToCommande" ,data, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}



export async function CommandeToBL(data) {
  try {
    const response = await axios.put(api_url + "commande/CommandeToBL" ,data, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function CommandeToBLVente(data) {
  try {
    const response = await axios.put(api_url + "commandevente/CommandeToBL" ,data, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


export async function BLToFacture(data) {
  try {
   
    const response = await axios.put(api_url + "bl/BLToFacture" ,data, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function BLToFactureVente(data) {
  try {
   
    const response = await axios.put(api_url + "blvente/BLToFacture" ,data, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}