import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets the ligne paie by code.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code_generated": "string",
 *   "code_paie": "string",
 *   "date_debut": "string",
 *   "date_fin": "string",
 *   "date_fin_saisie": "string",
 *   "date_fin_valid
 */
export async function getLignePaieByCode() {
  try {
    const response = await axios.get(api_url + "lignepaie/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a new ligne de paie to the database
 * @param code - The code of the ligne de paie
 * @param code_paie - The code of the paie
 * @param base - base de paie
 * @param gain - The amount of money that the employee will receive.
 * @param retenue - retenue
 * @param compte_comptable - The compte comptable of the ligne de paie
 * @returns The response is an object that contains the data from the server.
 */
export async function addLignePaie(code, code_paie, base, gain, retenue, compte_comptable) {
  const credentiel = {
    code: code,
    code_generated: localStorage.getItem("code_generated"),
    code_paie: code_paie,
    base: base,
    gain: gain,
    retenue: retenue,
    compte_comptable: compte_comptable,
  };
  try {
    const response = await axios.post(api_url + "lignepaie/", credentiel, config);
    console.log(response);
    toast.success(t("Ligne de paie ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It edits a ligne paie.
 * @param data - The data to be sent to the server.
 * @returns The response is an object with the following properties:
 */
export async function editLignePaie(data) {
  const credentiel = data;
  try {
    const response = await axios.put(api_url + "lignepaie/id/", credentiel, config);
    console.log(response);
    toast.success(t("mise à jour"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
