import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getChequeById, updateChequeById } from "../../Services/Pointeuse/ChequeApi";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import moment from "moment";
import cron from "node-cron";
import { t } from "i18next";
// import { toast } from 'react-toastify';
// import { ToastContainer } from 'react-toastify';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function UpdateCheque(props,dueDate  ) {
  const history = useHistory();
  const alert = useAlert();
  const [showDiv, setShowDiv] = useState(false);

  const [nom, setnom] = useState(null);
  const [montant, setmontant] = useState(null);
  const [numero, setnumero] = useState(null);
  const [date, setdate] = useState(null);
  const [image, setimage] = useState(null);
  const [files, setFiles] = useState(null);
   const [isDueSoon, setIsDueSoon] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  // const [dateEmissionCheque, setDateEmissionCheque] = useState(null);
  const joursAvantEmission = 7; // Nombre de jours avant l'émission pour afficher la notification
  const frequenceNotification = 1000 * 60 * 60 * 24; // 1 jour en millisecondes
  const [dateCourante, setDateCourante]=useState()
  // const [showReminder, setShowReminder] = useState(false);
 
  const [isMessageVisible, setIsMessageVisible] = useState(true);
  const [isSecondMessageVisible, setIsSecondMessageVisible] = useState(false);


  var dateCourantee = new Date().toISOString().split('T')[0];
  useEffect(() => {
    getChequeById(props.match.params.id).then((res) => {
      setnom(res.data.nom);
      setmontant(res.data.montant);
      setdate(res.data.date);
      setnumero(res.data.numero);
      setimage(res.data.image);
    });
  }, []);

 

  // useEffect(() => {
  //   const today = new Date();
  //   const diffTime = Math.abs(deadline - today);
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   if (diffDays <= 7) {
  //     setShowReminder(true);
  //   }
  // }, [deadline]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowDiv(prevShowDiv => !prevShowDiv);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmitEncaissement = (e) => {
    e.preventDefault();
    if (files) {
      updateChequeById(props.match.params.id, date, nom, montant, numero, files[0].file).then((res) => {
        history.push("/admin/cheque");
      });
    } else {
      updateChequeById(props.match.params.id, date, nom, montant, numero, null).then((res) => {
        history.push("/admin/cheque");
      });
    }
  };

  

  const notificationAlerte = () =>{
      // Vérifie si la date d'émission du chèque est proche toutes les 24 heures
      const intervalId = setInterval(() => {
         const dateCourante = new Date().toISOString().split('T')[0];     
        if (date) {
           const dateNotification = new Date(date);
          dateNotification.setDate(dateNotification.getDate() - joursAvantEmission);
          const dateNotificationISO = dateNotification.toISOString().split('T')[0];
          if (dateCourante >= dateNotificationISO && dateCourante < date) {
            setShowNotification(true);
          }
        }
      }, frequenceNotification);
  
      // Nettoie l'intervalle lors du démontage du composant
      return () => {
        clearInterval(intervalId);
      };
  }

  function handleClick() {
    setShowNotification(false);
  }

  useEffect(() => {
    notificationAlerte();
  }, []);

  useEffect(() => {
    const today = moment();
    const due = moment(date );
    const diff = due.diff(today, 'days');
    setIsDueSoon(diff <= 7);
  }, [date ]);

  const handleButtonClick = () => {
    setIsMessageVisible(!isMessageVisible);
    setIsSecondMessageVisible(!isSecondMessageVisible);
  }

  // CRON
  // function showNotificationCorn() {
  //   toast('Task executed!', {
  //     position: toast.POSITION.BOTTOM_RIGHT,
  //     autoClose: 3000,
  //     hideProgressBar: true,
  //     closeOnClick: false,
  //     pauseOnHover: false,
  //     draggable: true,
  //   });
  // }

  // const cronPattern = '* * * * *';
  // const job = cron.schedule(cronPattern, showNotificationCorn);
  // job.start();

  return (
    <>
      {isMessageVisible && (date >= dateCourantee )  ? 
         <div className="notification text-danger" >     
         <span style={{ display: showDiv ? 'block' : 'none' ,fontSize:"20px", fontWeight:"bolder"}}>
           {t("La date limite de votre chèque est bientôt atteinte !")}
         </span>
       </div>
        :
        ("")
     } 
     {isSecondMessageVisible &&<div className="notification text-danger" >
         <span style={{fontSize:"20px", fontWeight:"bolder"}}>
         {t("Chèque versé")}
         </span>
       </div>}

     <button type="button" className="btn btn-primary"
        onClick={handleButtonClick}
        style={{marginTop: "20px",
        width: "180px",
        marginLeft: "80%",
        fontWeight:"bolder"}}
      >
        {isMessageVisible ? t("Chèque n'est pas Versé") :  t("Chéque Versé")}
      </button>
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">{t("Modifié Chèque")} # {props.match.params.id}</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
            <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
          </div> */}
              <div class="col-sm-12 col-lg-12">
                <form style={{ marginTop: 50 }} onSubmit={handleSubmitEncaissement}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label>{t("Nom")}</label>
                        <input type="text" className="form-control" value={nom} required onChange={(e) => setnom(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Montant")}</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="ex : 100.100"
                          value={montant}
                          onChange={(e) => setmontant(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Numéro")}</label>
                        <input type="text" value={numero} className="form-control" required onChange={(e) => setnumero(e.target.value)} />
                      </div>
                    </div>
              
               <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                 <div className="form-group">
                   <label htmlFor="exampleInputPassword1">{t("Date")}</label>
                   <input type="date" value={date} className="form-control"
                    required onChange={(e) => setdate(e.target.value)} 
                    />
                 </div>
               </div>
              
                  </div>
                  {image && <img src={localStorage.getItem("baseUrl") + image} alt=""></img>}
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={10}
                    name="files"
                    labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
                  />
                  <button type="submit" className="btn btn-primary">
                    {t("Enregistrer")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default UpdateCheque;
