/**
 * Returns true if the string contains an uppercase character
 * @param str - The string to check.
 * @returns a boolean value.
 */
export function hasUperCase(str) {
  return str.toLowerCase() != str;
}

/**
 * Returns true if the string contains a number
 * @param myString - The string to be tested.
 * @returns A boolean value.
 */
export function hasNumber(myString) {
  return /\d/.test(myString);
}

/**
 * Check if a string contains a symbol
 * @param myString - The string to be tested.
 * @returns a boolean value.
 */
export function hasSymbole(myString) {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (format.test(myString)) {
    return true;
  } else {
    return false;
  }
}
