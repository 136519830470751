import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

/**
 * * This function is used to render the error page when an error occurs
 * @returns The ErrorCustum function is returning a div element with a class of text-center.
 */
function ErrorCustum() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t

  return (
    <div className="text-center">
      <div className="fs-100  mb-5 text-primary h1">{t("oops")}!</div>
      <h1 className="h3  mb-3 font-weight-semibold">{t("Error 500: Internal Server Error")}</h1>
      <p className="h5 font-weight-normal mb-7 leading-normal">
        {t("You may have mistyped the address or the page may have moved")}.
      </p>
      <Link className="btn btn-primary" to="#" onClick={() => window.location.reload(false)}>
        <i className="fe fe-rotate-ccw mr-1" />
        {t("Rafraîchir")}
      </Link>
    </div>
  );
}

export default ErrorCustum;
