// PDFDocument.js

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Table, TableBody, TableCell, TableHeader, TableRow } from '@react-pdf/renderer';

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 8,
    color: 'black',
  },
  section: {
    marginBottom: 15,
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginTop: 5,
  },
  tableHeader: {
    backgroundColor: '#CDCECF',
    flexDirection: 'row',
  },
  tableHeaderCell: {
    flex: 4,
    padding: 5,
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',

    fontWeight: 'bold',
  },
  tableHeaderCellright: {
    flex: 4,
    padding: 5,
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',
    borderRight: '1px solid #000',

    fontWeight: 'bold',
  },
  tableHeaderCellnom: {
    flex: 5,
    padding: 5,
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',

    fontWeight: 'bold',
  },
  tableCell: {
    flex: 4,
    padding: 5,
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',

  },
  tableCellright: {
    flex: 4,
    padding: 5,
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',
    borderRight: '1px solid #000',


  },
  tableCellnom: {
    flex: 5,
    padding: 5,
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',

  },
  row: {
    flexDirection: 'row',
  },
  col: {
    flex: 1,
  },
});

const PDFDocument = ({ societe, mois, year, list ,total ,prime}) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>{societe?.nom}</Text>
        <Text>Journal de Paie</Text>
        <Text>{societe?.adresse}</Text>
        <Text> { prime?.moisplus   ? `mois : ${prime.libelle}` : prime?.prime ? `du prime ${prime.libelle} du mois ${mois} ${year}`:`du mois ${mois} ${year}`}   </Text>
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderCell}>Mat</Text>
            <Text style={styles.tableHeaderCellnom}>Nom </Text>
            <Text style={styles.tableHeaderCellnom}> Prénom</Text>

            <Text style={styles.tableHeaderCell}>Sal brut</Text>
            <Text style={styles.tableHeaderCell}>CNSS</Text>
            <Text style={styles.tableHeaderCell}>Imposable</Text>
            <Text style={styles.tableHeaderCell}>IRPP</Text>
            <Text style={styles.tableHeaderCell}>CSS</Text>
            <Text style={styles.tableHeaderCell}>avance / pret</Text>

            <Text style={styles.tableHeaderCell}>Net à payer</Text>
            <Text style={styles.tableHeaderCellright}>Net à payer arrondi</Text>

          </View>
          {list.map((item, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.tableCell}>{item.matricule_user}</Text>
              <Text style={styles.tableCellnom}>{item.nom }</Text>
              <Text style={styles.tableCellnom}>{ item.prenom}</Text>

              <Text style={styles.tableCell}>{item.salbrut}</Text>
              <Text style={styles.tableCell}>{item.cnss}</Text>
              <Text style={styles.tableCell}>{item.imposable}</Text>
              <Text style={styles.tableCell}>{item.irpp}</Text>
              <Text style={styles.tableCell}>{item.css}</Text>
              <Text style={styles.tableCell}>{item.totalretenu}</Text>

              <Text style={styles.tableCell}>{item.netapayer}</Text>
              <Text style={styles.tableCellright}>{item.netapayerarrondi}</Text>

            </View>
          ))}
           <View  style={styles.row}>
              <Text style={styles.tableCell}>{total.matricule_user}</Text>
              <Text style={styles.tableCellnom}>{total.nom }</Text>
              <Text style={styles.tableCellnom}>{total.prenom }</Text>

              <Text style={styles.tableCell}>{total.salbrut}</Text>
              <Text style={styles.tableCell}>{total.cnss}</Text>
              <Text style={styles.tableCell}>{total.imposable}</Text>
              <Text style={styles.tableCell}>{total.irpp}</Text>
              <Text style={styles.tableCell}>{total.css}</Text>
              <Text style={styles.tableCell}>{total.totalretenu}</Text>

              <Text style={styles.tableCell}>{total.netapayer}</Text>
              <Text style={styles.tableCellright}>{total.netapayerarrondi}</Text>

            </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default PDFDocument;
