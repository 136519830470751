import React, { useEffect, useState } from "react";
import { addFacturationClient } from "../../../Services/Facturation/FacturationClientApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { useHistory } from "react-router-dom"
import { handleKeyPress_number } from "../../../Utils/InputUtils";
import { getAllDevise } from "../../../Services/devise/devise";
import ReactSelect from "react-select";
import { code_CAT, codetva, getLettreAssociee } from "../../../Utils/Data/Matriculefiscale";
import { getUsersByCode } from "../../../Services/Pointeuse/UsersApi";
import moment from "moment";
function AddClient() {
  const [stat, setStat] = React.useState("Client");
  const [tarif, setTarif] = React.useState("tarif");
  const [selectedDate, setSelectedDate] = React.useState();

  const [code, setCode] = useState("");
  const [codeBare, setCodeBare] = useState("");
  const [codeActivity, setCodeActivity] = useState("");
  const [magasin, setMagasin] = useState("");
  const [tva, setTva] = useState("");
  const [potentiel, setPotentiel] = useState("");
  const [potentielSoc, setPotentielSoc] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [email, setEmail] = useState("");
  const [fixe, setFixe] = useState("");
  const [Mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [rib, setRib] = useState("");
  const [soldeReleve, setSoldeReleve] = useState("");
  const [codeErp, setCodeErp] = useState("");
  const [tax, setTax] = useState("");
  const [adrFacturation, setAdrFacturation] = useState("");
  const [deviseselected, setdevise] = useState("");
  const [exonere, setexonere] = useState(0);

  const [localite, setLocalite] = useState("");
  const [region, setRegion] = useState("");
  const [gouvernorat, setGouvernorat] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [categorie, setCategorie] = useState("");
  const [codeCommercialCategorie, setCodeCommercialCategorie] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [regimeFiscal, setRegimeFiscal] = useState("Réel");
  const [statut, setStatut] = useState("");
  const [matriculeFiscal, setMatriculeFiscal] = useState({ num_MF: '', cle_MF: '', codetva: '', codecat: '', codeetab: '000' });
  const [isReel, setisReel] = useState(false);
  const [commentaire, setCommentaire] = useState("");
  const [image, setImage] = useState("/dist/img/user/user.jpg");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("");
  const [reel, setReel] = useState(0);
  const [client, setClient] = useState("");
  const [fournisseur, setFournisseur] = useState("");
  const [prospect, setProspect] = useState("");
  const [forfitaire, setForfitaire] = useState(0);
  const [remise, setRemise] = useState(0);
  const [delaiPaiement, setDelaiPaiement] = useState(0);
  const [plafontCredit, setPlafontCredit] = useState(0);
  const [plafondCredit, setPlafondCredit] = useState(0);
  const [rc, setRc] = useState("");
  const [deviseState, setdeviseState] = useState([])
  const [users, setusers] = useState(null);
  const [dataProspect,setDataProspect]=useState({
    user_id:'0',
    Promesse:null,
    date:moment(new Date()).format("YYYY-MM-DD"),
  })

  const history = useHistory();
  const image_url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getUsersByCode().then((res) => {
      setusers(res.data);
    });
  }, []);
  useEffect(() => {
    getAllDevise().then((res) => {
      if (res.status === 200) {
        setdeviseState(res.data.data);
      }
    });
  }, []);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeStatus = (event) => {
    setStat(event.target.value);
  };

  const handleChangeTarification = (event) => {
    setTarif(event.target.value);
  };
  const handleChangeStatut = (event) => {
    setTarif(event.target.value);
  };

  const handleImgClient = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  const handleSubmit = () => {


    if (code === '' || deviseselected === '' || codeActivity === '' || adrFacturation === '' || nom === '') {
      toast.warning('Entrez toutes les informations requises *')
    } else {
      if(prospect &&(dataProspect.user_id==='0' ) ){
        toast.warning('Choisir un Collaborateur')

      }
      else{
      const matricule = matriculeFiscal.num_MF + '/' + matriculeFiscal.cle_MF + '/' + matriculeFiscal.codetva + '/' + matriculeFiscal.codecat + '/' + matriculeFiscal.codeetab
        const code_client= code.length === 0 ? `code_${nom}` : code
        
      addFacturationClient(
        dataProspect,
        prospect ? 1 :0,
        code_client,
        deviseselected,
        exonere,
        codeActivity,
        plafontCredit,
        prospect ? 'Prospect' :"Client",
        tarif,
        magasin,
        tva,
        potentiel,
        potentielSoc,
        nom,
        prenom,
        cin,
        email,
        selectedDate,
        fixe,
        Mobile,
        description,
        rib,
        soldeReleve,
        codeErp,
        tax,
        adrFacturation,
        localite,
        region,
        gouvernorat,
        secteur,
        zone,
        categorie,
        codeCommercialCategorie,
        speciality,
        regimeFiscal,
        matricule,
        commentaire,
        imageUrl,
        remise,
        delaiPaiement,
        plafondCredit,
        rc
      ).then((res) => {
        if (res.status === 200) {
          history.push("/admin/client");

        }

      });
    }
  }
  };

  const handleChangeRegimeFiscal = (e) => {
    const value = e.target.value;
    setRegimeFiscal(value);

    if (value === "Réel") {
      setReel(0);
      setisReel(false);
    } else {
      setForfitaire(0);
      setisReel(true);
    }
  };
  const handleStatut = (e) => {
    const value = e.target.value;
    setStatut(value);

    if (value === "client") {
      setClient(true);
      setFournisseur(false);
      setProspect(false);
    } else if (value === "fournisseur") {
      setFournisseur(true)
      setProspect(false);
      setClient(false);
    } else {
      setProspect(true);
      setFournisseur(false)
      setClient(false);
    }
  };


  const handleMatriculeFiscalePart1Blur = () => {
    const value = matriculeFiscal.num_MF.padStart(7, "0");
    const lettreAssociee = getLettreAssociee(value);
    setMatriculeFiscal((prev) => ({
      ...prev,
      num_MF: value,
      cle_MF: lettreAssociee

    }));

  };

  const changeNumMf = (name, value) => {
    if (name === 'num_MF') {
      if (value.length <= 7) {
        setMatriculeFiscal((prev) => ({ ...prev, num_MF: value }))
      }
    } else {

      setMatriculeFiscal((prev) => ({ ...prev, [name]: value }))

    }


  }
  const changeProspect=(name,value)=>{
    setDataProspect((prev) => ({
      ...prev,
      [name]: value
    }));
    
  }

  return (
    <>
      <div className="row mt-4" >
        <div className="col-md-6"  >
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Détail client")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Code")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setCode(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  {t("Code Activité")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setCodeActivity(e.target.value)}
                  required
                />
              </div>
              {/*<div className="form-group">
                <label>
                  {t("plafond credit")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setPlafontCredit(e.target.value)}
                  required
                />
              </div>*/}
              {/*
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">{t("Tarification")}</label>
                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChangeTarification}>
                  <option value={0}>{t("tarif")}</option>
                  <option value={1}>{t("tarif")}1</option>
                  <option value={2}>{t("tarif")}2</option>
                </select>
            </div>*/}
              {/* <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">{t("Statut")}</label>
                <select className="form-control" id="exampleFormControlSelect1" 
                onChange={handleChangeStatut}
                >
                  <option value="fournisseur">{t("fournisseur")}</option>
                  <option value="prospect">{t("Prospect")}</option>
                  <option value="client">{t("Client")}</option>
                </select>
              </div> */}
              {/*
              <div className="form-group">
                <label>
                  {t("Magasin")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setMagasin(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  {t("Code ERP")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setCodeErp(e.target.value)} required />
            </div>*/}
              <div className="form-group">
                <label>
                  {t("Adresse de facturation")} <span className="text-danger">*</span>
                  <small> {t("maximum 40 caractères")}</small>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setAdrFacturation(e.target.value)}
                  required
                  maxLength="40"
                />
              </div>
              <div className="form-group">
                <label>{t("Devise")}</label><span className="text-danger">*</span>
                <ReactSelect
                  value={deviseState && deviseState.find(item => item.id === deviseselected)}
                  options={deviseState}
                  onChange={(e) => setdevise(e.id)}
                  formatOptionLabel={devise => (
                    <div className="country-option">
                      <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                      <span>{devise.libelle}</span>
                    </div>
                  )}
                />
              </div>
              <div className="form-group">
                <label>{t("exonéré")}</label>
                <select className="form-control"

                  value={exonere}
                  onChange={(e) => setexonere(parseInt(e.target.value))}>
                  <option value={0}>Non</option>
                  <option value={1}>Oui</option>
                </select>
              </div>

            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
          <div>
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">{t("Image client")}</h3>
                <div className="card-tools">
                  {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button> */}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="form-group">
                  <div className="d-flex justify-content-center">
                    <img alt="image produit" src={image} className="avatar" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        accept="image/*"
                        type="file"
                        id="exampleInputFile"
                        onChange={handleImgClient}
                      />
                      <label className="custom-file-label" htmlFor="exampleInputFile">
                        {imageName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
              </div>
            </div>
          </div>


        </div>
        <div className="col-md-6" >
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Information de contact")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Raison social")} {t(' Nom/Prénom')} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setNom(e.target.value)} required />
              </div>
              {/* <div className="form-group">
                <label>
                  Prénom <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setPrenom(e.target.value)} required />
              </div> */}
              <div className="form-group">
                <label>
                  {t("Email")}
                </label>
                <input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} required />
              </div>
              {/* <div className="form-group">
                <label>
                  {t("Anniversaire")}
                </label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                />
              </div>*/}
              <div className="form-group ">
                <label>
                  {t("Mobile / Fixe")} <span className="text-danger"></span>
                </label>
                <input className="form-control" type="text" onKeyPress={handleKeyPress_number} onChange={(e) => setMobile(e.target.value)} />
              </div>
              {/*<div className="form-group">
                <label>
                  {t("Mobile")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onKeyPress={handleKeyPress_number} onChange={(e) => setMobile(e.target.value)} required />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("Description")} <span className="text-danger"></span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setDescription(e.target.value)} />
              </div>
              <div className="form-group">
                <label>
                  {t("Commentaire")} <span className="text-danger"></span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setCommentaire(e.target.value)} />
              </div>
              {/* <div className="form-group">
                <label>
                  {t("Statut")} <span className="text-danger"></span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setStatut(e.target.value)}  />
              </div> */}
            </div>
            {/* /.card-body */}

          </div>
          <div className="card card-primary mt-7">
            <div className="card-header">
              <h3 className="card-title row"> <input type="checkbox" className="mr-2" value={prospect} onChange={() => setProspect(!prospect)} />{' '}{t("  Prospect")}</h3>

            </div>
            {prospect &&
              <div className="card-body row">
                <div className="col-4">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("date")}
                      </label>

                    <input 
                        type="date" 
                        onChange={(e)=>changeProspect('date',e.target.value)}
                      value={dataProspect.date}


                     />
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("Collaborateur")}
                      </label>

                      <select  className="selectpicker form-control" 
                      onChange={(e)=>changeProspect('user_id',e.target.value)}
                      value={dataProspect.user_id} >
                        <option value="0">{t("Choisir un collaborateur")}</option>
                        {users &&
                          users.map((el, idx) => (
                            <option key={idx} value={el.id}>
                              {el.nom} {el.prenom}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        {t("Promesse")}
                      </label>

                      <textarea   
                      onChange={(e)=>changeProspect('Promesse',e.target.value)}
                      value={dataProspect.Promesse}
                       className="form-control"  cols={110}  rows={3}/>
                    </div>
                  </div>
                </div>
              </div>
            }


          </div>
        </div>
        <div className="col-md-12" >
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Information complémentaire")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              {/* <div className="form-group">
                <label>
                  {t("Catégorie")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setCategorie(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>
                  {t("Spécialité")} <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setSpeciality(e.target.value)} required />
              </div>*/}
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">{t("Régime fiscal")}</label>
                <select className="form-control"
                  value={regimeFiscal}
                  required
                  onChange={handleChangeRegimeFiscal}
                >
                  <option value="réel">{t("Réel")}</option>
                  <option value="forfaitaire">{t("Forfaitaire")}</option>
                </select>
                {/* <label>
                  Régime fiscal <span className="text-danger"></span>
                </label> */}
                {/* <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setRegimeFiscal(e.target.value)}
                
                /> */}
              </div>
              <div className="form-group row">
                <div className="col-3">
                  <label>{t("Num MF")}</label>
                  <input
                    className="form-control"
                    type="text"
                    onBlur={handleMatriculeFiscalePart1Blur}
                    onChange={(e) => changeNumMf('num_MF', e.target.value)}
                    value={matriculeFiscal.num_MF}
                    required
                  />
                </div>
                <div className="col-2">
                  <label>{t("Cle MF")}</label>
                  <input
                    className="form-control"
                    type="text"
                    value={matriculeFiscal.cle_MF}
                    readOnly
                    required
                  />
                </div>
                <div className="col-2">
                  <label>{t("Code  TVA")}</label>
                  <select className="form-control" onChange={(e) => changeNumMf('codetva', e.target.value)}>
                    {codetva.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-2">
                  <label>{t("Cle MF")}</label>
                  <select className="form-control" onChange={(e) => changeNumMf('codecat', e.target.value)}>
                    {code_CAT.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="col-2">
                  <label>{t("N° Etab")}</label>
                  <input className="form-control" value={matriculeFiscal.codeetab} readOnly />


                </div>


              </div>
              <div className="form-group">
                <label>
                  {t("Remise client")} <span className="text-danger"></span>
                </label>
                <input className="form-control" type="text" onChange={(e) => setRemise(e.target.value)} />
              </div>
              {/*<div className="form-group">
                <label>
                  {t("Delai de paiement")} <span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setDelaiPaiement(e.target.value)}
                  
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Plafond crédit")} <span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => setPlafondCredit(e.target.value)}
                  
                />
              </div>*/}
              {/*<div className="form-group">
                <label>
                  {t("Registre de commerce")}
                </label>
                <input className="form-control" type="text" onChange={(e) => setRc(e.target.value)} required />
              </div>*/}
              <div className="form-group">
                <label>
                  {t("RIB")}
                </label>
                <input className="form-control" type="text" onChange={(e) => setRib(e.target.value)} required />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* Visit{" "}
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. */}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          {/*<div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Adresse")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}{/*
              </div>
            </div>
            <div className="card-body">
              {/* <div className="form-group">
                <label>
                  Localité<span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={localite}
                  onChange={(e) => setLocalite(e.target.value)}
                  
                />
              </div> 
              <div className="form-group">
                <label>
                  {t("Région")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>
                  {t("Gouvernorat")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={gouvernorat}
                  onChange={(e) => setGouvernorat(e.target.value)}
                  required
                />
              </div>
              {/* <div className="form-group">
                <label>
                  Secteur <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={secteur}
                  onChange={(e) => setSecteur(e.target.value)}
                  required
                />
              </div> 
              <div className="form-group">
                <label>
                  {t("Zone")} <span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  
                />
              </div>
            </div>
            <div className="card-footer">
              <a href="https://select2.github.io/">Select2 documentation</a> for
              more examples and information about the plugin. 
            </div>
          </div>*/}

        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> {t("Annuler")}
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={handleSubmit}>
            <i className="far fa-credit-card" /> {t("Enregistrer")}
          </button>
        </div>
      </div>
    </>
  );
}

export default AddClient;
