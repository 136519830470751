import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};
// /**
//  * It adds a new salaire to the database.
//  * @param client - the data that will be sent to the server
//  * @param nombre_user - the data that will be sent to the server
//  * @param projet - the data that will be sent to the server
//  * @param janvier - the data that will be sent to the server
//  * @param fevrier - the data that will be sent to the server
//  * @param mars - the data that will be sent to the server
//  * @param avril - the data that will be sent to the server
//  * @param mai - the data that will be sent to the server
//  * @param juin - the data that will be sent to the server
//  * @param juillet - the data that will be sent to the server
//  * @param aout - the data that will be sent to the server
//  * @param septembre - the data that will be sent to the server
//  * @param octobre - the data that will be sent to the server
//  * @param novembre - the data that will be sent to the server
//  * @param decembre - the data that will be sent to the server
//  * @param annee - the data that will be sent to the server
//    * @returns The response from the server.
//  */
// export async function addSupport(
//     client,
//     nombre_user,
//     projet,
//     janvier,
//     fevrier,
//     mars,
//     avril,
//     mai,
//     juin,
//     juillet,
//     aout,
//     septembre,
//     octobre,
//     novembre,
//     decembre,
//     annee
   
//     ) { 
//     const credentiel = {
//        client: client,
//       nombre_user: nombre_user,
//       projet: projet,
//       janvier: janvier,
//       fevrier: fevrier,
//       mars: mars,
//       avril: avril,
//       mai: mai,
//       juin: juin,
//       juillet: juillet,
//       aout:aout,
//       septembre: septembre,
//       octobre: octobre,
//       novembre:novembre,
//       decembre:decembre,
//       annee,
//     };
//      try {
//       const response = await axios.post(api_url + "support/", credentiel, config);
//       console.log(response);
//       toast.success("ajouté avec succée");
//       return response;
//     } catch (error) {
//         if (error.response) {
//             // The request was made and the server responded with a status code
//             // that falls out of the range of 2xx
//             toast.error(t("error server"));
//             console.log(error.response.data);
//             console.log(error.response.message);
//             console.log(error.response.status);
//             console.log(error.response.headers);
//           } else if (error.request) {
//             // The request was made but no response was received
//             // `error.request` is an instance of XMLHttpRequest in the
//             // browser and an instance of
//             // http.ClientRequest in node.js
//             toast.error(t("error request"));
//             console.log(error.request);
//           } else {
//             // Something happened in setting up the request that triggered an Error
//             console.log(t("Error"), error.message);
//           }
//           console.log(error.config);
//           return error;
//     }
//   }
  

/**
 * It adds a new salaire to the database.
 * @param client - the data that will be sent to the server
 * @param nombre_user - the data that will be sent to the server (should be a number)
 * @param projet - the data that will be sent to the server
 * @param janvier - the data that will be sent to the server (should be a number)
 * @param fevrier - the data that will be sent to the server (should be a number)
 * @param mars - the data that will be sent to the server (should be a number)
 * @param avril - the data that will be sent to the server (should be a number)
 * @param mai - the data that will be sent to the server (should be a number)
 * @param juin - the data that will be sent to the server (should be a number)
 * @param juillet - the data that will be sent to the server (should be a number)
 * @param aout - the data that will be sent to the server (should be a number)
 * @param septembre - the data that will be sent to the server (should be a number)
 * @param octobre - the data that will be sent to the server (should be a number)
 * @param novembre - the data that will be sent to the server (should be a number)
 * @param decembre - the data that will be sent to the server (should be a number)
 * @param annee - the data that will be sent to the server
 * @returns The response from the server.
 */
export async function addSupport(
   code_generated,
    client,
    nombre_user,
    projet,
    janvier,
    fevrier,
    mars,
    avril,
    mai,
    juin,
    juillet,
    aout,
    septembre,
    octobre,
    novembre,
    decembre,
    annee
  ) {
    // Define a utility function to parse values as integers or leave them as null
    const parseAsIntOrNull = (value) => {
      if (value === null) {
        return null;
      }
      const parsedValue = parseInt(value, 10);
      return isNaN(parsedValue) ? null : parsedValue;
    };
  
    // Apply the utility function to the values
    const nombre_user_number = parseAsIntOrNull(nombre_user);
    const janvier_number = parseAsIntOrNull(janvier);
    const fevrier_number = parseAsIntOrNull(fevrier);
    const mars_number = parseAsIntOrNull(mars);
    const avril_number = parseAsIntOrNull(avril);
    const mai_number = parseAsIntOrNull(mai);
    const juin_number = parseAsIntOrNull(juin);
    const juillet_number = parseAsIntOrNull(juillet);
    const aout_number = parseAsIntOrNull(aout);
    const septembre_number = parseAsIntOrNull(septembre);
    const octobre_number = parseAsIntOrNull(octobre);
    const novembre_number = parseAsIntOrNull(novembre);
    const decembre_number = parseAsIntOrNull(decembre);
  
    const credentiel = {
      code_generated:code_generated,
      client: client,
      nombre_user: nombre_user_number,
      projet: projet,
      janvier: janvier_number,
      fevrier: fevrier_number,
      mars: mars_number,
      avril: avril_number,
      mai: mai_number,
      juin: juin_number,
      juillet: juillet_number,
      aout: aout_number,
      septembre: septembre_number,
      octobre: octobre_number,
      novembre: novembre_number,
      decembre: decembre_number,
      annee: annee,
    };
  
    try {
      const response = await axios.post(api_url + "support/", credentiel, config);
      console.log(response);
      toast.success("ajouté avec succès");
      return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast.error(t("error server"));
            console.log(error.response.data);
            console.log(error.response.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) { 
            toast.error(t("error request"));
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(t("Error"), error.message);
          }
          console.log(error.config);
          return error;
    }
  }
  /**
 * Get all the support by project
 * @param projet - The project code
 * @returns The response is an array of objects.
 */
export async function getSupportByProjet(projet) {
  try {
    const response = await axios.get(`${api_url}support/all/${projet}`, config);

    return response.data; // Assuming the data is in the 'data' property of the response
  } catch (error) {
    console.error(error);
  }
}

  
  /**
 * Get all the vaccin by code
 * @param projet - The project code
 * @returns The response is an array of objects.
 */

  export async function getAllSupports() {
    try {
      const code_generated = localStorage.getItem('code_generated')
      const response = await axios.get(api_url + "support/allsupport/"+code_generated, config);
  
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

   /**
 * Get all the support by code client
 * @param projet - The project code
 * @returns The response is an array of objects.
 */

   export async function getAllSupportclient(id) {
    try {
      const response = await axios.get(api_url + "support/all/client/"+id, config);
  
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }


    /**
 * Delete support by id
 * @param id - The support id
 * @returns The response is a message with code 0 or 1.
 */

    export async function deleteSupporById(id) {
      try {
        const response = await axios.delete(api_url + `support/${id}`, config);
    
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }

  /**
 * Update support
 * @param support - The support object
 * @returns The response is a message with code 0 or 1.
 */

  export async function updateSupport(support) {
    try {
      const response = await axios.put(api_url + `support/`, support, config);
  
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  

   /**
 * Update / save support
 * @param obj - The  object {save:listsave ,update:listupdate}
 * @returns The response is a message with code 0 or 1.
 */

   export async function support_save_update(data) {
    try {
      const response = await axios.post(api_url + `support/support_save_update`, data, config);
  
      return response;
    } catch (error) {
      console.error(error);
    }
  }
  
    