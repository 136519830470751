import React, { useState } from "react";

/**
 * It creates a dashboard that shows the number of students by state.
 * @returns The `<div>` element is being returned.
 */
function EvaxInfo() {
  const [url] = useState(
    "https://opendata.evax.tn/s/evax/app/dashboards#/view/08e0d2a0-d2d9-11eb-8ad5-e7a9759adb3e?embed=true&_g=(filters:!(('$state':(store:globalState),meta:(alias:!n,disabled:!f,index:'5bacd4b0-d2bc-11eb-8ad5-e7a9759adb3e',key:id_etat_inscrit,negate:!f,params:(query:1),type:phrase),query:(match_phrase:(id_etat_inscrit:1)))),refreshInterval:(pause:!t,value:0),time:(from:now-15y,to:now))&hide-filter-bar=true"
  );
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <>
      <div class="elfsight-app-f62c9d5b-b745-4ec9-bacd-6537ba06805e"></div>
    </>
  );
}

export default EvaxInfo;
