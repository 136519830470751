import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/***  parametrage paie */
/**add */
export async function addParametragePaie(data) {

    try {
      const response = await axios.post(api_url + "param_paie/param_paie", data, config);
      console.log(response);
      //toast.success("ajouté");
      return response;
    } catch (error) {
     
      console.log(error.config);
      return error;
    }
  }
/***update */
export async function updateparam_paie(data) {

    try {
      const response = await axios.put(api_url + "param_paie/param_paie", data, config);
      console.log(response);
      //toast.success("ajouté");
      return response;
    } catch (error) {
     
      console.log(error.config);
      return error;
    }
  }
/** get */
export async function getparam_paie(exercice) {

    try {
      const response = await axios.get(api_url + "param_paie/param_paie/"+localStorage.getItem('code_generated')+"/"+exercice, config);
      //toast.success("ajouté");
      return response;
    } catch (error) {
     
      console.log(error.config);
      return error;
    }
  }
  /****parametrage de l'exercice active */
  
  export async function getparam_paie_active() {

    try {
     
      const response = await axios.get(api_url + "param_paie/active/"+localStorage.getItem('code_generated'), config);
    
      //toast.success("ajouté");
      return response;
    } catch (error) {
     
      console.log(error.config);
      return error;
    }
  }
  /********************rubrique */
  export async function addparam_rub_paie(data) {

    try {
      const response = await axios.post(api_url + "param_paie/param_rub_paie", data, config);
      console.log(response);
      //toast.success("ajouté");
      return response;
    } catch (error) {
     
      console.log(error.config);
      return error;
    }
  }

  /** get */
export async function getparam_rub_paie() {

  try {
    const response = await axios.get(api_url + "param_paie/param_rub_paie/"+localStorage.getItem('code_generated'), config);
    //toast.success("ajouté");
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}
export async function getparam_rub_paie_active() {

  try {
    const response = await axios.get(api_url + "param_paie/param_rub_paie_active/"+localStorage.getItem('code_generated'), config);
    //toast.success("ajouté");
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}
export async function getuser_rubcode(code) {

  try {
    const response = await axios.get(api_url + "param_paie/param_rub_paie/getuser_rubcode/"+code+'/'+localStorage.getItem('code_generated'), config);
    //toast.success("ajouté");
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}


/**delete */
export async function changeactive(data) {

  try {
    const response = await axios.put(api_url + "param_paie/param_rub_paie/changeactive",data, config);
   
    //toast.success("ajouté");
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}
/**update */
export async function updateparam_rub_paie(data) {

  try {
    const response = await axios.put(api_url + "param_paie/param_rub_paie/",data, config);
   
    //toast.success("ajouté");
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}
/*** get rubrique par user */
export async function get_rub_user(usercode) {

  try {
    const response = await axios.get(api_url + "param_paie/param_rub_paie/user/"+usercode, config);
    //toast.success("ajouté");
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}
/**********get souche rubrique */
export async function getsouche_rub() {

  try {
    const response = await axios.get(api_url + "param_paie/param_rub_paie/souche/"+localStorage.getItem('code_generated'), config);
  
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}

export async function getuser_rub_codesociete() {

  try {
    const response = await axios.get(api_url + "param_paie/param_rub_paie/getuser_rub_codesociete/"+localStorage.getItem('code_generated'), config);
  
    return response;
  } catch (error) {
   
    console.log(error.config);
    return error;
  }
}
