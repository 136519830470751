import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import {Link } from "react-router-dom"
import ClientModal from "./ClientModal";
function AjouterClient({onSelect} ) {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
 
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#addDeplacementModal">
                {t("Ajouter Client")}
              </Link>
             </div>
          </div>
        </div>
      </div> 
      {/* modal */}
      <ClientModal   onSelect={onSelect}/>
    </>
  );
}

export default AjouterClient;