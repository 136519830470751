import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the messages from the database.
 * @returns The response is an array of objects. Each object contains the message and the time it was
 * created.
 */
export async function getAllMessages() {
  try {
    const response = await axios.get(api_url + "message/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Send a message to a user
 * @param id_sender - the id of the sender
 * @param id_receiver - the id of the user you want to send a message to
 * @param msg - the message you want to send
 * @returns The response of the server.
 */

export async function sendMessage(id_sender, id_receiver, msg, file) {
  // const credentiel = {
  //   code_generated: localStorage.getItem("code_generated"),
  //   id_sender: id_sender,
  //   id_receiver: id_receiver,
  //   msg: msg,
  // };

  const frmData = new FormData();
  frmData.append("code_generated", getSession("code_generated"));
  frmData.append("id_sender", id_sender);
  frmData.append("id_receiver", id_receiver);
  frmData.append("msg", msg);
  frmData.append("file", file || "");

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url_v2 + "messages", frmData, options);
    console.log(response);
    //toast.success("Message envoyer");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("message non envoyé"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      //toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Send a notification to a topic
 * @param senderName - The name of the sender.
 * @param text - The message you want to send.
 * @param receiver - the receiver of the message
 * @returns The response from the server.
 */
export async function senFcmTopics(senderName, text, receiver) {
  const notification = {
    data: {
      title: senderName,
      message: t("Nouveau message : ") + text,
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
    console.log(response);
    //toast.success("Notification envoyée");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
