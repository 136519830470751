import React from "react";
import Activity from "../Components/Activity/Activity";
import Footer from "../Components/shared/Footer";
import Header from "../Components/shared/Header";
import Preloader from "../Components/shared/Preloader";
import Sidebar from "../Components/shared/Sidebar";
import SidebarUser from "../Components/shared/SidebarUser";

function DashboardLayoutUser(props) {
  return (
    <div>
      {/* Switcher */}
      
      {/* End Switcher */}
      {/*-Global-loader*/}
      {/* <Preloader></Preloader> */}
      <div className="page">
        <div className="page-main">
          {/*aside open*/}
          <SidebarUser/>
          {/*aside closed*/}
          <div className="app-content main-content">
            <div className="side-app">
              {/*app header*/}
              <Header/>
              {/*/app header*/}
              {/*Page header*/}
              {/* <div className="m-2"> */}
              <React.Fragment>{props.children}</React.Fragment>
              {/* </div> */}
            </div>
          </div>
          {/* end app-content*/}
        </div>
        {/*Footer*/}
        <Footer/>
        {/* End Footer*/}
        {/*Sidebar-right*/}
        <Activity />
        {/*/Sidebar-right*/}
        {/*Clock-IN Modal */}
        <div className="modal fade" id="clockinmodal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <span className="feather feather-clock  mr-1" />
                  Clock In
                </h5>
                <button className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="countdowntimer">
                  <span id="clocktimer" className="border-0" />
                </div>
                <div className="form-group">
                  <label className="form-label">Note:</label>
                  <textarea className="form-control" rows={3} defaultValue={"Some text here..."} />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-outline-primary" data-dismiss="modal">
                  Close
                </button>
                <button className="btn btn-primary">Clock In</button>
              </div>
            </div>
          </div>
        </div>
        {/* End Clock-IN Modal  */}
        {/*Change password Modal */}
        <div className="modal fade" id="changepasswordnmodal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Password</h5>
                <button className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label">New Password</label>
                  <input type="password" className="form-control" placeholder="password" defaultValue />
                </div>
                <div className="form-group">
                  <label className="form-label">Confirm New Password</label>
                  <input type="password" className="form-control" placeholder="password" defaultValue />
                </div>
              </div>
              <div className="modal-footer">
                <a href="#" className="btn btn-outline-primary" data-dismiss="modal">
                  Close
                </a>
                <a href="#" className="btn btn-primary">
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End Change password Modal  */}
      </div>
    </div>
  );
}

export default DashboardLayoutUser;
