import React from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

function CopieContent() {
  const alert = useAlert();
  /**
   * It copies the table to the clipboard.
   */
  const copyTable = () => {
    alert.success("Copié");
    const elTable = document.querySelector("table");

    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();

    console.log("Element Copied! Paste it in a file");
  };

  return (
    <Link to="#" className="action-btns" data-toggle="tooltip" data-placement="top" title="copier" onClick={copyTable}>
      <i className="feather feather-copy text-warning" />
    </Link>
  );
}

export default CopieContent;
