import React, { useEffect, useState } from "react";
import Pagination from "../Others/Pagination";
import { acceptConge, getAllCongeByCode, getAllDemandeCongeByCode, senFcmConge, sentCongeEmail } from "../../Services/Pointeuse/CongeApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import TableConges from "./TableConges";
import { filterCongeByWord } from "../../Utils/SortObject";
import { dateBetweenTwoDates } from "../../Utils/DateUtils";
import ImageComponent from "../Styles/ImageComponent";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import CongeCardHeader from "./CongeCardHeader";
import swal from "sweetalert";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function DemandeConges() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [dateStart, setdateStart] = useState("");
  const [dateEnd, setdateEnd] = useState("");
  const [size, setsize] = useState(8);
  const [logo, setlogo] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);
  const [exercice, setexercice] = useState();

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setlogo(res.data.logo);
    });
  }, []);

  /**
   * It gets all the data from the API and sets it to the data variable.
   */
  const getData = () => {
    setLoading(true);
    getAllDemandeCongeByCode(exercice?.code_exercice)
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, [exercice]);

  const removeObject = (id) => {
    const updatedData = allInitialData.filter((item) => item.id != id);
    setdata(updatedData);
    setallInitialData(updatedData);
  };

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search word.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {

    if (e.target.value !=="") {
      const dataSearch = filterCongeByWord(allInitialData, e.target.value);
      setcurrentData(dataSearch);
    } else {
      setcurrentData(allInitialData);
    }
  };
  /**
   * filter conges by date
   */
  const handleSearchBydate = () => {
    const filtredDate = dateBetweenTwoDates(dateStart, dateEnd, allInitialData);
    setdata(filtredDate);
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * Accept a leave request
   * @param receiver - The email address of the user who is going to receive the notification.
   * @param nom - The name of the user who sent the request.
   * @param prenom - The first name of the user who sent the request.
   * @param nbjour - Number of days of the leave
   * @param motif - The reason for the leave
   * @param id - The id of the conge you want to accept.
   * @param type - The type of the notification.
   * @param date - The date of the conge.
   * @param status - The status of the leave request.
   * @param userid - The id of the user who sent the request.
   */
  const handleAccept = (receiver, nom, prenom, nbjour, motif, id, type, date, status, userid) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté congé!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptConge(id, nbjour, "1").then(() => {
          //toast.success("Congé accepté");
          sentCongeEmail(receiver, nom, prenom, nbjour, motif, logo, id, type, date, status).then(() => {
            swal(t("Opération effectuée avec succès!"), {
              icon: "success",
            });
            //remove from list
            const updatedData = allInitialData.filter((item) => item.id != id);
            setdata(updatedData);
            setallInitialData(updatedData);
            //getData();
            sendFirebaseNotification(status, userid);
          });
        });
      }
    });
  };

  /**
   * It sends a notification to the user.
   * @param type - The type of notification to send.
   * @param receiver - The user's device token.
   */
  const sendFirebaseNotification = (type, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmConge(type, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * It sends a notification to the user who requested the leave.
   * @param id - The id of the leave request
   * @param nbjour - number of days of the leave
   * @param receiver - The user who will receive the notification.
   */

  const handleReject = (id, nbjour, receiver) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé congé!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptConge(id, nbjour, "0").then(() => {
          //toast.success("Congé accepté");
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          const updatedData = allInitialData.filter((item) => item.id != id);
          setdata(updatedData);
          setallInitialData(updatedData);
          sendFirebaseNotification(t("refusé"), receiver);
        });
      }
    });
  };

  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };

  return (
    <>
      <CongeCardHeader getData={getData}  setexercice={setexercice}/>
      <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Liste des congés")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-warning-light mr-2">
                    <i className="feather feather-check-circle text-warning" /> {t("demande")}
                  </span>
                  {/* <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> ---&gt; Absent
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> ---&gt; Holiday
                  </span>
                  <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_demandes_congés"} />
                          <ExportPdf data={data} name={"List_demandes_congés"} columns={["nom", "prenom", "nbr_jour", "date"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <TableConges onUpdate={getData} arrayObject={currentData} status="demande" onRemoveobject={removeObject}></TableConges>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Aperçu congé")}</h4>
            </div>
            <div className="card-body">
              <div id="leavesoverview" className="mx-auto pt-2" />
              <div className="row mx-auto text-center">
                <div className="col-md-12 mx-auto d-block">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex font-weight-semibold">
                        {/* /* */}

                        <div className="card-body">
                          <div id="carousel-controls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                              {currentData.map((el, idx) => (
                                <>
                                  {idx == 0 ? (
                                    <div key={idx} className="carousel-item active">
                                      {console.log("pppppppp",currentData)}
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="card border p-0 shadow-none">
                                          <div className="card-header border-0">
                                            <h3 className="card-title">{t("Demande congé")}</h3>
                                            <div className="ml-auto">
                                              <span className="badge badge-md badge-warning-light">{el.nbr_jour}</span>
                                            </div>
                                          </div>
                                          <div className="d-flex p-4">
                                            <ImageComponent
                                              picture={el.imguser}
                                              atr={"avatar avatar-lg brround d-block cover-image"}
                                            ></ImageComponent>
                                            {/* <div>
                                     <div
                                       className="avatar avatar-lg brround d-block cover-image"
                                       data-image-src="assets/images/users/1.jpg"
                                     />
                                   </div> */}
                                            <div className="pl-3">
                                              <h5 className="mb-0 mt-2 text-dark fs-18">
                                                {el.nom} {el.prenom}
                                              </h5>
                                              <p className="text-muted fs-12 mt-1 mb-0">{el.email}</p>
                                            </div>
                                          </div>
                                          <div className="card-body pt-2 bg-light">
                                            <div className="mt-3 mb-3">
                                              <div className="h5 mb-1">
                                                <span className="feather feather-calendar" /> : {el.date}
                                                <span className="badge badge-md badge-primary-light ml-1">{el.nbr_jour} Jour</span>
                                              </div>
                                              <small className="text-muted fs-11">
                                                {t("Ajouté le")} : {el.date}
                                                {/* On<span className="font-weight-semibold"> 5 weeks ago</span> */}
                                              </small>
                                            </div>
                                            <div className="progress progress-sm mb-2">
                                              <div className="progress-bar bg-success w-100" />
                                            </div>
                                            {/* <div className="d-flex align-items-end justify-content-between mb-0">
                                     <h6 className="fs-12 mb-0">Remaining Leaves</h6>
                                     <h6 className="font-weight-bold fs-12 mb-0">15</h6>
                                   </div> */}
                                          </div>
                                          <div className="p-4">
                                            <label className="form-label">{t("Raison")}:</label>
                                            <p className="text-muted leave-text">{el.motif}</p>
                                          </div>
                                          <div className="card-footer p-0 border-top-0">
                                            <div className="btn-group w-100 leaves-btns">
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  handleAccept(
                                                    el.email,
                                                    el.nom,
                                                    el.prenom,
                                                    el.nbr_jour,
                                                    el.motif,
                                                    el.id,
                                                    el.type,
                                                    el.date,
                                                    "accepté",
                                                    el.user_code_id
                                                  )
                                                }
                                                className="btn btn-lg btn-outline-light w-50 text-success"
                                              >
                                                {t("Accepter")}
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={() => handleReject(el.id, el.nbr_jour, el.user_code_id)}
                                                className="btn btn-lg btn-outline-light w-55 text-danger"
                                              >
                                                {t("Refuser")}
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div key={idx} className="carousel-item">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="card border p-0 shadow-none">
                                          <div className="card-header border-0">
                                            <h3 className="card-title">{t("Demande congé")}</h3>
                                            <div className="ml-auto">
                                              <span className="badge badge-md badge-warning-light">{el.nbr_jour}</span>
                                            </div>
                                          </div>
                                          <div className="d-flex p-4">
                                            <ImageComponent
                                              picture={el.imguser}
                                              atr={"avatar avatar-lg brround d-block cover-image"}
                                            ></ImageComponent>
                                            {/* <div>
                                 <div
                                   className="avatar avatar-lg brround d-block cover-image"
                                   data-image-src="assets/images/users/1.jpg"
                                 />
                               </div> */}
                                            <div className="pl-3">
                                              <h5 className="mb-0 mt-2 text-dark fs-18">
                                                {el.nom} {el.prenom}
                                              </h5>
                                              <p className="text-muted fs-12 mt-1 mb-0">{el.email}</p>
                                            </div>
                                          </div>
                                          <div className="card-body pt-2 bg-light">
                                            <div className="mt-3 mb-3">
                                              <div className="h5 mb-1">
                                                <span className="feather feather-calendar" /> : {el.date}
                                                <span className="badge badge-md badge-primary-light ml-1">{el.nbr_jour} Jour</span>
                                              </div>
                                              <small className="text-muted fs-11">
                                                {t("Ajouté le")} : {el.date}
                                                {/* On<span className="font-weight-semibold"> 5 weeks ago</span> */}
                                              </small>
                                            </div>
                                            <div className="progress progress-sm mb-2">
                                              <div className="progress-bar bg-success w-100" />
                                            </div>
                                            {/* <div className="d-flex align-items-end justify-content-between mb-0">
                                 <h6 className="fs-12 mb-0">Remaining Leaves</h6>
                                 <h6 className="font-weight-bold fs-12 mb-0">15</h6>
                               </div> */}
                                          </div>
                                          <div className="p-4">
                                            <label className="form-label">{t("Raison")}:</label>
                                            <p className="text-muted leave-text">{el.motif}</p>
                                          </div>
                                          <div className="card-footer p-0 border-top-0">
                                            <div className="btn-group w-100 leaves-btns">
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  handleAccept(
                                                    el.email,
                                                    el.nom,
                                                    el.prenom,
                                                    el.nbr_jour,
                                                    el.motif,
                                                    el.id,
                                                    el.type,
                                                    el.date,
                                                    "accepté",
                                                    el.user_code_id
                                                  )
                                                }
                                                className="btn btn-lg btn-outline-light w-50 text-success"
                                              >
                                                {t("Accepter")}
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={() => handleReject(el.id, el.nbr_jour, el.user_code_id)}
                                                className="btn btn-lg btn-outline-light w-55 text-danger"
                                              >
                                                {t("Refuser")}
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                            <a className="carousel-control-prev" href="#carousel-controls" role="button" data-slide="prev">
                              <span className="carousel-control-prev-icon" aria-hidden="true" />
                              <span className="sr-only">{t("Previous")}</span>
                            </a>
                            <a className="carousel-control-next" href="#carousel-controls" role="button" data-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true" />
                              <span className="sr-only">{("Next")}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemandeConges;
