import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all departements from the API
 * @returns The response is an array of objects.
 */
export async function getAllDepartements() {
  try {
    const response = await axios.get(api_url + "departement/code/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all departements by usercode
 * @returns The response is an array of objects.
 */
export async function getDepartementByUser(usercode) {
  try {
    const response = await axios.get(api_url + "departement/user/" + usercode, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Get a departement by its code
 * @param code - The code of the departement you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "01",
 *   "name": "Ain",
 *   "region": {
 *     "code": "84",
 *     "name": "Auvergne-Rhône-Alpes"
 *   }
 * }
 * ```
 */
export async function getDepartementByCode(code) {
  try {
    const response = await axios.get(api_url + "departement/" + code, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a departement to the database
 * @param code - the code of the departement
 * @param nom - the name of the departement
 * @returns The response from the server.
 */
export async function addDepartement(code, nom) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    code: code,
    nom: nom,
  };

  try {
    const response = await axios.post(api_url + "departement", credentiel, config);
    console.log(response);
    toast.success(t("département ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a departement by code.
 * @param code - The code of the departement to delete.
 * @returns The response object contains the status code, the response headers, and the response body.
 */
export async function deleteDepartementByCode(code) {
  try {
    const response = await axios.delete(api_url + "departement/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
