import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function getChequeEmisEnteteByCode() {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-entete/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getChequeEmisEnteteById(id) {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-entete/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addChequeEmisEntete(date, client_code, description, cente_cout, operation, caisse, debit, credit, type) {
  const credentiel = {
    code_generated: getSession(t("code_generated")),
    date: date,
    client_code: client_code,
    description: description,
    cente_cout: cente_cout,
    operation: operation,
    caisse: caisse,
    montant_entrant: debit,
    montant_sortant: credit,
    type: type,
  };

  try {
    const response = await axios.post(api_url_v2 + "cheque-emis-entete", credentiel, config);
    console.log(response);
    //toast.success("Congé ajouter");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      //toast.error("error server");
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // toast.error("error request");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

// export async function updateCaisse(date, client_code, description, cente_cout, operation, debit, credit, type, id) {
//   const credentiel = {
//     date: date,
//     client_code: client_code,
//     description: description,
//     cente_cout: cente_cout,
//     operation: operation,
//     montant_entrant: debit,
//     montant_sortant: credit,
//     type: type,
//     id: id,
//   };

//   try {
//     const response = await axios.patch(api_url_v2 + "caisses", credentiel, config);
//     console.log(response);
//     //toast.success("Congé ajouter");
//     return response;
//   } catch (error) {
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       //toast.error("error server");
//       console.log(error.response.data);
//       console.log(error.response.message);
//       console.log(error.response.status);
//       console.log(error.response.headers);
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the
//       // browser and an instance of
//       // http.ClientRequest in node.js
//       // toast.error("error request");
//       console.log(error.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       console.log("Error", error.message);
//     }
//     console.log(error.config);
//     return error;
//   }
// }

export async function deleteChequeEmisEnteteById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "cheque-emis-entete/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
