import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 14,
    // borderWidth: 1,
    // borderColor: '#142328',
  },
});

const InvoiceItemsTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader items={invoice} />
    <InvoiceTableRow items={invoice.items} isremise={invoice.isRemise} invoice={invoice}/>
    {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} /> */}
    <InvoiceTableBlankSpace rowsCount={0} />
    <InvoiceTableFooter invoice={invoice} />
  </View>
);

export default InvoiceItemsTable;
