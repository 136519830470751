import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { getToken } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};
const code_generated = localStorage.getItem('code_generated')

/**
 * Get all user declaration cnss from the API
 * @returns The response is an array of objects.
 */
export async function getuserdeclaration(trimestre,exercice) {
  try {
  const response = await axios.get(api_url + `declaration/getuserdeclaration/${trimestre}/${code_generated}/${exercice}`, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 *  Genere declaration 
 * @returns The response is an array of objects.
 */
export async function Generedeclaration(trimestre,exercice,user_selected) {
    try {
        const data ={trimestre:trimestre,exercice:exercice,code_generated:code_generated,user_selected:user_selected}
    const response = await axios.post(api_url + `declaration/`,data, config);
  
      return response;
    } catch (error) {
      console.error(error);
    }
  }
  

  /**
 * Get  declarations
 * @returns The response is an array of objects.
 */
export async function getdeclaration() {
  try {
  const response = await axios.get(api_url + `declaration/getdeclaration/${code_generated}`, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
