import React, { useEffect, useState } from 'react'
import { getProduitInventaire, insertInventare } from '../../../../../Services/Facturation/FacturationStockApi';
import Pagination from '../../../../Others/Pagination';
import { t } from "i18next";
import { filterFactureByWord, filterStockByWord } from '../../../../../Utils/SortObject';
import CustomSyncLoader from '../../../../Others/CustomSyncLoader';
import ErrorCustum from '../../../../Others/ErrorCustum';
import { Link, useHistory } from "react-router-dom";
import { handleKeyPress_number } from '../../../../../Utils/InputUtils';
import { generateUniqueCode } from '../../../../../Utils/helper';
import { toast } from 'react-toastify';
import moment from "moment";

export default function Inventaire() {
    const [currentData, setCurrentData] = useState([]);
    const [allInitialData, setAllInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const history = useHistory();



    useEffect(() => {
        setLoading(true);
        getProduitInventaire()
            .then((res) => {

                setData(res.data);
                setAllInitialData(res.data);
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, []);
    const onChangePage = (pageOfItems) => {
        setCurrentData(pageOfItems);
    };
    const handleSearch = (e) => {
        const searchTerm = e.target.value.trim();
        if (searchTerm !== "") {
            const dataSearch = filterStockByWord(allInitialData, searchTerm);
            setData(dataSearch);
        } else {
            setData(allInitialData);
        }
    };
    const handlchangesaisie = (value, idproduit) => {
        const list = [...allInitialData]; 

        const findindex =   allInitialData.findIndex(item => item.produit.id === idproduit);


        list[findindex].quantite_saisie = value ;
        setAllInitialData(list)
    }
    const handleClick = ()=>{

        const filtredlist = allInitialData.filter(item=> item.quantite_saisie !== '') ;
        const entet_data ={
            code: generateUniqueCode(),
            user_code:localStorage.getItem('id'),
            type : 'stock',
            date: moment(new Date()).format("YYYY-MM-DD"),
            code_generated: localStorage.getItem('code_generated')
        }
        const data ={entetInventaire:entet_data,lignes:filtredlist}
        insertInventare(data)
        .then((res) => {
            if(res.status === 201){
                toast.success('inventaire saved with success')
                history.push('/admin/listinventaire')

            }else{
                toast.error(res.data.message)

            }

            
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    }
    return (
        <>
            <div className="page-header d-xl-flex d-block">
                <div className="page-leftheader"></div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("Inventaire  ")}{" "}
                                <span title="3 New Messages" className="badge badge-warning">
                                    {currentData.length}
                                </span>
                            </h3>
                            <div className="card-tools">
                                <div className="row">
                                    <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                                        <input
                                            type="text"
                                            name="table_search"
                                            className="form-control floating"
                                            placeholder={t("Rechercher...")}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0 ml-5 mr-5">
                            {loading && <CustomSyncLoader />}
                            {!loading && error && <ErrorCustum />}
                            {!loading && !error && data && (
                                <div className="table-responsive-sm">
                                    <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                            <tr>
                                                <th>{t(" Produit")}</th>
                                                <th>{t("Code Produit")}</th>
                                                <th>{t("Quantite theorique")}</th>
                                                <th>{t("Nouvelle Quantite ")}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((el, index) => (
                                                <tr key={index}>

                                                    <td>
                                                        <Link to={"/admin/produit/" + el.produit.code}>{el.produit.libelle}</Link>
                                                    </td>
                                                    <td>
                                                        <Link to={"/admin/produit/" + el.produit.code}>{el.produit.code}</Link>
                                                    </td>
                                                    <td>{el.quantite_theo}</td>
                                                    <td>
                                                        <input type='text'
                                                            onKeyPress={handleKeyPress_number}
                                                            value={allInitialData.find(item => item.produit.id === el.produit.id).quantite_saisie|| ''}
                                                            onChange={(e) => handlchangesaisie(parseInt(e.target.value), el.produit.id)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="card-footer clearfix">
                            <Pagination items={data} onChangePage={onChangePage} pageSize={4} />
                            <button type="button" className="btn btn-primary float-right" onClick={handleClick}>
                          <i className="far fa-credit-card" /> {t("Enregistrer")}
                        </button>
                        </div>
                      
                   
                    
                    </div>
                </div>
            </div>
        </>
    )
}
