import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";

const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function getChequeEmisLigneById(id) {
  try {
    const response = await axios.get(api_url_v2 + "cheque-emis-ligne/cheque-emis-entete/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
