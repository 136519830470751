import axios from "axios";
import { type } from "os";
import { getCurrentDateTime } from "../../Utils/DateUtils";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import moment from "moment";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets all the tasks that have the same code as the one generated by the previous function.
 * @returns The response is an array of objects.
 */
export async function creeReunion(data) {
  try {

     const response = await axios.post(api_url + "reunionclient/poste" , data,config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getListReunion() {
  try {
      const code_generated = localStorage.getItem('code_generated')
     const response = await axios.get(api_url + "reunionclient/getlist/"+code_generated  ,config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


export async function getReunionbyid(id) {
  try {
     const response = await axios.get(api_url + "reunionclient/reunion/"+id  ,config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateReunionbyid(data) {
  try {
     const response = await axios.put(api_url + "reunionclient/updatereunion",data  ,config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function deleteById(id) {
  try {
    const response = await axios.delete(api_url + "reunionclient/delete" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}export async function geltclientreunion(id) {
  try {
    const response = await axios.get(api_url + "reunionclient/geltclientreunion/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}