import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { editVaccinById, getVaccinById, updateCertificatPdf } from "../../Services/Pointeuse/VaccinApi";
// import PDFViewer from "pdf-viewer-reactjs";
import { PDFReader } from "reactjs-pdf-reader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { t } from "i18next";

function EditerVaccin(props) {
  const [users, setusers] = useState(null);
  const [userId, setuserId] = useState(null);
  const [numDose, setnumDose] = useState(null);
  const [dateDose1, setdateDose1] = useState(null);
  const [dateDose2, setdateDose2] = useState(null);
  const [dateDose3, setdateDose3] = useState(null);
  const [numInscri, setnumInscri] = useState(null);
  const [centreVaccin, setcentreVaccin] = useState(null);
  const [nomVaccin, setnomVaccin] = useState(null);
  const [pdf] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
  );
  const [pdfUrl, setpdfUrl] = useState(null);
  const [pdfName, setpdfName] = useState(null);

  const [image] = useState(
    "https://w7.pngwing.com/pngs/619/184/png-transparent-qr-code-barcode-scanners-scanner-q-text-rectangle-logo.png"
  );

  useEffect(() => {
    getVaccinById(props.match.params.id).then((res) => {
      console.log(res.data);
      setnumInscri(res.data[0].num_inscrit);
      setnomVaccin(res.data[0].nom_vaccin);
      setnumDose(res.data[0].dose_vaccin);
      setdateDose1(moment(res.data[0].date_dose_1).format("YYYY-MM-DD"));
      setdateDose2(moment(res.data[0].date_dose_2).format("YYYY-MM-DD"));
      setdateDose3(moment(res.data[0].date_dose_3).format("YYYY-MM-DD"));
      setcentreVaccin(res.data[0].centre_vaccination);
      setuserId(res.data[0].user_id);
      setpdfUrl(res.data[0].certificat_pdf);
    });
  }, []);

  /**
   * It updates the pdf file of the certificate of vaccination of the pet
   * @param event - The event that triggered the function.
   */
  const handlePdf = (event) => {
    setpdfUrl(event.target.files[0]);
    setpdfName(event.target.files[0].name);

    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment ajouter un certificat de vaccination!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        updateCertificatPdf(event.target.files[0], props.match.params.id).then(() => {
          swal(t("Document modifié!"), {
            icon: "success",
          });
        });
      }
    });
  };

  // const handleChangeCollab = (e) => {
  //   setuserId(e.target.value);
  // };
  /**
   * *Edit a vaccin by id.*
   */
  const submitVaccin = () => {
    editVaccinById(nomVaccin, numDose, dateDose1, dateDose2, dateDose3, numInscri, centreVaccin, props.match.params.id);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Vaccination")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addvaccin" className="btn btn-primary mr-3">
                {t("Ajouter vaccination")}
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Détail vaccin")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                <label>
                  {t("Nom de vaccin")}<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => setnomVaccin(e.target.value)}
                  value={nomVaccin}
                >
                  <option value={"default"}>{t("Choisir Vaccin")}</option>
                  <option value={"Pfizer/BioNTech (USA, Allemagne)"}>{t("Pfizer/BioNTech (USA, Allemagne)")}</option>
                  <option value={"Sanofi (France, Royaume-Uni)"}>{t("Sanofi (France, Royaume-Uni)")}</option>
                  <option value={"Moderna (USA)"}>{t("Moderna (USA)")}</option>
                  <option value={"AstraZeneka/Université d''Oxford (Royaume-Uni)"}>
                    {t("AstraZeneka/Université d''Oxford (Royaume-Uni)")}
                  </option>
                  <option value={"Johson&Johson - Janssen (USA)"}>{t("Johson&Johson - Janssen (USA)")}</option>
                  <option value={"Gameleya Research Institute, Spoutnik V (Russie)"}>
                    {t("Gameleya Research Institute, Spoutnik V (Russie)")}
                  </option>
                  <option value={"CureVac (Allemagne)"}>{t("CureVac (Allemagne)")}</option>
                  <option value={"Novavax (USA)"}>{t("Novavax (USA)")}</option>
                  <option value={"Sinovac (China)"}>{t("Sinovac (China)")}</option>
                  <option value={"Biotech (China)"}>{t("Biotech (China)")}</option>
                  <option value={"CanSino (China)"}>{t("CanSino(China)")}</option>
                  <option value={"CanSino (China)"}>{t("CanSino(China)")}</option>
                  <option value={"China Biotics (China)"}>{t("China Biotics (China)")}</option>
                  <option value={"Sinopharm (China)"}>{t("Sinopharm (China)")}</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  {t("Nombre des doses")} <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => setnumDose(e.target.value)}
                  value={numDose}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
              <div className="form-group">
                <label>
                  {t("Date dose")} 1 <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dateDose1}
                  required
                  onChange={(e) => setdateDose1(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Date dose")} 2<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dateDose2}
                  required
                  onChange={(e) => setdateDose2(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Date dose")} 3 <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dateDose3}
                  required
                  onChange={(e) => setdateDose3(e.target.value)}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{t("Détail vaccin")}</h3>
              <div className="card-tools">
                {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus" />
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times" />
                </button> */}
              </div>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="form-group">
                {/* <label>
                    Collaborateur <span className="text-danger">*</span>
                  </label> */}
                {/* {users && (
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={userId}
                    onChange={handleChangeCollab}
                  >
                    <option value={"default"}>Choisir Collaborateurs</option>
                    {users.map((option) => (
                      <option value={option.id}>
                        {option.nom} {option.prenom}
                      </option>
                    ))}
                  </select>
                )} */}
              </div>
              <div className="form-group">
                <label>
                  {t("Numéro d'inscription")}<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={numInscri}
                  required
                  onChange={(e) => setnumInscri(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  {t("Centre de vaccination")}<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={centreVaccin}
                  onChange={(e) => setcentreVaccin(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">{t("Certificat PDF")}</label>
                <div className="d-flex justify-content-center">
                  <img alt="image produit" src={pdf} style={{ width: 60 }} />
                  {/* <PDFViewer
                    document={{
                      url: localStorage.getItem("baseUrl") + pdfUrl,
                    }}
                  /> */}
                </div>
              </div>
              {/* <div className="form-group">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      accept="application/pdf"
                      type="file"
                      id="exampleInputFile"
                      onChange={handlePdf}
                    />
                    <label className="custom-file-label" htmlFor="exampleInputFile">
                      {pdfName}
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="input-group mb-5 file-browser">
                <input
                  type="text"
                  className="form-control browse-file"
                  placeholder={pdfName || "choisir un fichier ..."}
                />
                <label className="input-group-append">
                  <span className="btn btn-primary">
                    {t("choisir fichier")} <input type="file" style={{ display: "none" }} onChange={handlePdf} />
                  </span>
                </label>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn-danger text-center mr-2">
            <i className="fas fa-trash-alt" /> {t("Annuler")}
          </button>
          <button type="button" className="btn btn-primary text-center" onClick={submitVaccin}>
            {/* <i className="far fa-credit-card" /> */}
            {t("Enregistrer")}
          </button>
        </div>
      </div>
      <div className="row m-4">
        <div className="col-12 d-flex justify-content-center">
          {pdfUrl && <PDFReader url={localStorage.getItem("baseUrl") + pdfUrl} />}
        </div>
      </div>
    </>
  );
}

export default EditerVaccin;
