import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Add a new line to the invoice
 * @param numero_serie - The code of the header.
 * @param dossier_importation - The product code
 * @param fournisseur - The quantity of the product
 * @param produit - unit price without taxes  
 * @returns The response is an object that contains the data that was returned by the API.
 */
export async function addSerie(
    numero_serie,
    dossier_importation,
    fournisseur,
    produit, 
    type,
  ) {
  
    const credentiel = {
      numero_serie: numero_serie,
       dossier_importation: dossier_importation, 
      fournisseur: fournisseur,
      produit: produit,  
      type:type 
    };
     try {
      const response = await axios.post(api_url + "facturation/facturationSerie/", credentiel, config);
      toast.success(t("Numéro Série ajouter"));
      return response;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast.error(t("error server"));
        console.log(error.response.data);
        console.log(error.response.message);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        toast.error(t("error request"));
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(t("Error"), error.message);
      }
      return error;
    }
  }

  /**
 * It gets the ligne by code.
  * @returns The response is an object with a data property that contains an array of objects.
 */
export async function getAllSerie( ) {
    try {
      const response = await axios.get(api_url + "facturation/facturationSerie/allserie/" , config);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
/**
  * It gets all series by product name.
  * @returns The response is an object with a data property that contains an array of objects.
 */
export async function getAllSeriesByProductName(productName) {
  try {
    const response = await axios.get(api_url + `facturation/facturationSerie/series/${productName}` , config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
  * It updates the BL of a given serie with id.
  * @returns The response is an object with a data property that contains an array of objects.
 */
export async function updateBLOfSerieById(updatedSerie) {
  try {
    const response = await axios.put(api_url + `facturation/facturationSerie/`, updatedSerie , config);
    return response;
  } catch (error) {
    console.error(error);
  }
}