import React, { useEffect, useState } from "react";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import { Link } from "react-router-dom";
import { getBaremeIrppByCode } from "../../Services/Pointeuse/BaremeIrppApi";
import { filterBaremeIrppByWord } from "../../Utils/SortObject";
import AddBaremeIrpp from "./AddBaremeIrpp";
import ImageComponent from "../Styles/ImageComponent";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function BaremeIrpp() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [size, setsize] = useState(8);

  useEffect(() => {
    getData();
  }, []);

  /**
   * It retrieves the data from the API and stores it in the data variable.
   */
  const getData = () => {
    setLoading(true);
    getBaremeIrppByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * The onChangePage function is called when the user clicks on the page numbers.
   * It takes the page of items returned by the paginate function as a parameter and
   * uses that parameter to update the currentData state
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the word that the user types in the search bar.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value != "") {
      const dataSearch = filterBaremeIrppByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object that contains the data we want to use.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-md-12 col-lg-12">
        {/* <div className="card-header  border-0">
            <h4 className="card-title">Liste des collaborateurs</h4>
          </div> */}

        <div className="card-body">
          <div className="d-flex mb-6 mt-5">
            <div className="mr-3">
              <label className="form-label">{t("Note")}:</label>
            </div>
            <div>
              <span className="badge badge-success-light mr-2">
                <i className="feather feather-check-circle text-success" /> ---&gt; {t("Présent")}
              </span>
              <span className="badge badge-danger-light mr-2">
                <i className="feather feather-x-circle text-danger" /> ---&gt; {t("Absent")}
              </span>
              <span className="badge badge-warning-light mr-2">
                <i className="fa fa-star text-warning" /> ---&gt; {t("Holiday")}
              </span>
              <span className="badge badge-orange-light mr-2">
                <i className="fa fa-adjust text-orange" /> ---&gt; {t("Half Day")}
              </span>
            </div>
          </div>

          {loading && <CustomSyncLoader></CustomSyncLoader>}
          {!loading && error && <ErrorCustum></ErrorCustum>}
          {!loading && !error && data && (
            <div className="table-responsive">
              <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="hr-table_length">
                      <label>
                        {t("Afficher")}{" "}
                        <select
                          value={size}
                          className="form-control"
                          id="exampleFormControlSelect1"
                          onChange={handleChangeSize}
                        >
                          <option value={8}>8</option>
                          <option value={20}>20</option>
                          <option value={40}>40</option>
                          <option value={60}>60</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        {t("entrées")}
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div id="hr-table_filter" className="dataTables_filter">
                      <a href="#" className="btn btn-primary" data-toggle="modal" data-target="#baremIrppModal">
                        <i className="feather feather-plus fs-15 my-auto mr-2" />
                        {t("Ajouter")}
                      </a>
                      <label className="mr-2">
                        <input
                          type="search"
                          className="form-control"
                          placeholder={t("Recherche...")}
                          aria-controls="hr-table"
                          onChange={handleSearch}
                        />
                      </label>

                      <Link to="#" className="action-btns" data-tip="télécharger">
                        <i className="feather feather-download  text-secondary" />
                      </Link>

                      <Link to="#" className="action-btns" data-tip="imprimer">
                        <i className="feather feather-printer text-success" />
                      </Link>
                      <Link to="#" className="action-btns" data-tip="partager">
                        <i className="feather feather-share-2 text-warning" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                      id="hr-table"
                      role="grid"
                      aria-describedby="hr-table_info"
                    >
                      <thead>
                        <tr>
                          <th className="border-bottom-0 w-5">{t("Code")}</th>
                          <th className="border-bottom-0 w-5">{t("Min")} </th>
                          <th className="border-bottom-0 w-5">{t("Max")} </th>
                          <th className="border-bottom-0 w-5">{t("Taux")}</th>
                          <th className="border-bottom-0 w-5">{t("Taux SUP")}</th>
                          <th className="border-bottom-0 w-5">{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((el) => (
                          <tr key={el.id}>
                            <td>{el.code}</td>
                            <td>{el.min}</td>
                            <td>{el.max}</td>
                            <td>{el.taux}</td>
                            <td>{el.taux_sup}</td>

                            <td className="text-left">
                              <Link to={"/admin/users/" + el.id} className="action-btns" data-tip="editer">
                                <i className="feather feather-edit text-info" />
                              </Link>

                              <Link to="#" className="action-btns" data-tip="supprimer">
                                <i className="feather feather-x text-danger" />
                              </Link>
                              {/* <div className="btn-group">
                                <button type="button" className="btn btn-secondary">
                                  Autres
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle dropdown-icon"
                                  data-toggle="dropdown"
                                >
                                  <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                <div className="dropdown-menu" role="menu">
                                  <Link to={"/users/" + el.id} className="dropdown-item">
                                    Éditer
                                  </Link>

                                  <div className="dropdown-divider" />
                                  <a className="dropdown-item" href="#">
                                    Supprimer
                                  </a>
                                </div>
                              </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                      {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AddBaremeIrpp></AddBaremeIrpp>
    </div>
  );
}

export default BaremeIrpp;
