import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const borderColor = "#142328";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    // borderBottomColor: "#142328",
    // backgroundColor: "#142328",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    fontSize: 12,
    fontStyle: "bold",
    width: "70%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  qty: {
    fontSize: 12,
    fontStyle: "bold",
    width: "10%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  rate: {
    fontSize: 12,
    fontStyle: "bold",
    width: "15%",
    textAlign: "right",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  amount: {
    fontSize: 12,
    fontStyle: "bold",
    width: "15%",
  },
});

const InvoiceTableHeader = ({ items }) => (
  <View style={styles.container}>
    <Text style={styles.description}>{t("Description")}</Text>
    <Text style={styles.rate}>{t("PU.HT")}</Text>
    <Text style={styles.qty}>{t("QTE")}</Text>
    {items.isRemise == true && <Text style={styles.amount}>{t("Remise")}</Text>}
    <Text style={styles.amount}>{t("Total")}</Text>
  </View>
);

export default InvoiceTableHeader;
