import React from "react";
import "./Fonctionnallity.css";

function Fonctionnallity() {
  return (
    <div className="m-4">
      <section id="what-we-do">
        <div className="container-fluid">
          <h2 className="section-title mb-2 h1">Fonctionalités</h2>
          <p className="text-center text-muted h5">
            Allégez votre fardeau de gestion grâce à un système de pointage moderne qui s’adapte parfaitement à votre
            milieu de travail.
          </p>
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-1">
                  <h3 className="card-title">Géolocalisation</h3>
                  <p className="card-text">
                    L'application permet d'effectuer une géolocalisation des employés lors de chaque pointage.
                  </p>
                  {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" className="read-more">
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-2">
                  <h3 className="card-title">Suivi des activités</h3>
                  <p className="card-text">
                    Toutes les informations sur les projets et les tâches à exécuter peuvent être consultées en temps
                    réel via l'application Nomateuse Pointeuse.
                  </p>
                  {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" className="read-more">
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-3">
                  <h3 className="card-title">Demandes des absences</h3>
                  <p className="card-text">
                    La solution inclut la traçabilité et la validation des demandes. Les employés peuvent demander : des
                    congés, des autorisations d'absence, des déplacements, directement à partir d'un smartphone.
                  </p>
                  {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" className="read-more">
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-4">
                  <h3 className="card-title">Annonces et actualités</h3>
                  <p className="card-text">
                    Les employés peuvent consulter toutes les annonces et événements publiés par l'administration et
                    suivre toutes les actualités à la une via l'application mobile Nomateuse Pointeuse.
                  </p>
                  {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" className="read-more">
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-5">
                  <h3 className="card-title">Chat</h3>
                  <p className="card-text">
                    Communication directe et partage d'informations avec tous les collaborateurs via la partie chat
                    intégrée à l'application.
                  </p>
                  {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" className="read-more">
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-6">
                  <h3 className="card-title">Sécurité et comptabilité</h3>
                  <p className="card-text">
                    Solution sécurisée composée de la partie web dédiée aux administrateurs et de l'application mobile
                    dédiée aux employés qui est compatible avec tous les terminaux mobiles..
                  </p>
                  {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" className="read-more">
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Fonctionnallity;
