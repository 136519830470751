import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getUsersByCode } from "../../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import { useTranslation } from "react-i18next";

function PieApexGender() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const arrayFilter = [];
    
    getUsersByCode()
      .then((res) => {
        // Extract gender data
        res.data.forEach((item) => {
          if (item?.gender) {
            arrayFilter.push(item.gender);
          }
        });
  
        // Count occurrences of each gender
        const obj = arrayFilter.reduce((prev, curr) => {
          prev[curr] = (prev[curr] || 0) + 1;
          return prev;
        }, {});
  
        console.log('obj', obj);
  
        // Prepare chart options
        const chartOptions = {
          series: [
            (obj.male || 0), 
            (obj.female || 0) // Corrected typo: "femele" to "female"
          ],
          options: {
            chart: {
              type: "polarArea",
              sparkline: {
                enabled: true,
              },
            },
            labels: [t("Homme"), t("Femme")], // Assuming t() handles translation correctly
            fill: {
              opacity: 1,
              colors: ["#22BBFF", "#E0148D"],
            },
            stroke: {
              width: 0,
              colors: undefined,
            },
            yaxis: {
              show: false,
            },
            legend: {
              show: true,
              fontSize: "12px",
              fontWeight: 300,
              labels: {
                colors: "black",
              },
              position: "bottom",
              horizontalAlign: "center",
              markers: {
                width: 19,
                height: 19,
                strokeWidth: 0,
                radius: 19,
                strokeColor: "#fff",
                fillColors: ["#22BBFF", "#E0148D"],
                offsetX: 0,
                offsetY: 0,
              },
            },
            plotOptions: {
              polarArea: {
                rings: {
                  strokeWidth: 0,
                },
              },
            },
            theme: {
              monochrome: {
                enabled: true,
                shadeTo: "light",
                shadeIntensity: 0.6,
              },
            },
          },
        };
  
        console.log('chartOptions', chartOptions);
        setData(chartOptions);
      })
      .catch((error) => {
        console.error('Error fetching users:', error); // Added console error logging
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="polarArea"
          height={251}
          // width={300}
        />
      )}
    </>
  );
}

export default PieApexGender;
