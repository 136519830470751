import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addNewProjetMembers, deleteProjetMembersByProjetId, getProjetMemberByProjetId } from "../../Services/Pointeuse/ProjetMemberApi";
import { getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import { filterUserByWord } from "../../Utils/SortObject";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import ImageComponent from "../Styles/ImageComponent";

function MembersProject({ projetId, getData }) {
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  /**
   * It fetches all the users from the database and then fetches all the members of the departement.
   */
  const fetchAllUser = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        const newArr = res.data.map((v) => ({ ...v, isSelected: false }));
        setdata(newArr);
        setallInitialData(newArr);
        getProjetMemberByProjetId(projetId).then((members) => {
          console.log(members);
          const resultSelected = newArr.filter(({ id: id1 }) => members.data.some(({ user_id: id2 }) => id1 === id2));
          const newSelected = resultSelected.map((v) => ({ ...v, isSelected: true }));
          const resultNotSelected = newArr.filter(({ id: id1 }) => !members.data.some(({ user_id: id2 }) => id1 === id2));
          const mergedObje = newSelected.concat(resultNotSelected);
          setdata(mergedObje);
          setallInitialData(mergedObje);
        });
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the search term.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterUserByWord(data, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * *This function takes in an id and a boolean value and returns a new array with the isSelected
   * property of the object with the matching id set to the opposite of the boolean value.*
   * @param id - The id of the object you want to select.
   * @param isSelected - This is the current state of the checkbox.
   */
  const handleSelect = (id, isSelected) => {
    const objIndex = data.findIndex((obj) => obj.id == id);
    const list = [...data];
    list[objIndex]["isSelected"] = !isSelected;
    setdata(list);
    //setdata((data[objIndex].isSelected = !isSelected));
  };

  /**
   * It adds a new departement with the given name and the users selected.
   */
  const handleAdd = () => {
    // delete projetmember by projet id
    deleteProjetMembersByProjetId(projetId).then((res) => {
      const userSelected = data.filter((el) => {
        return el.isSelected == true;
      });
      for (let i = 0; i < userSelected.length; i++) {
        addNewProjetMembers(userSelected[i].id, projetId).then(() => {
          //TODO
          //will send firebase to userss
          getData();
        });
      }
    });
  };
  return (
    <div className="card-body">
      <h4 className="card-title">Collaborateurs</h4>
      <div className="card-body table-responsive p-0">
        {loading && <CustomSyncLoader></CustomSyncLoader>}
        {!loading && error && <ErrorCustum></ErrorCustum>}
        {!loading && !error && data && (
          <div className=" mt-5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center"></th>
                    <th></th>
                    <th>Nom/Prénonm</th>
                    <th>Rôle</th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((el) => (
                    <tr key={el.id}>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          onChange={() => {
                            handleSelect(el.id, el.isSelected);
                          }}
                          checked={el.isSelected}
                        />
                      </td>
                      <td>
                        <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                      </td>
                      <td>
                        <Link to={"/admin/users/" + el.id}>
                          {el.nom} <span>{el.prenom}</span>
                        </Link>
                      </td>

                      <td>{el.fonction}</td>
                      <td>
                        {el.isactif == 0 ? <span className="badge badge-warning">Inactif</span> : <span className="badge badge-success">actif</span>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {/* /.card-body */}
      <div className="card-footer clearfix">
        <Pagination items={data} onChangePage={onChangePage} pageSize={5} />
      </div>
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="text-right">
            <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handleAdd}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembersProject;
