import React, { useEffect, useState } from "react";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import { getChequeEmisEnteteByCode } from "../../Services/Pointeuse/ChequeEmisEnteteApi";
import ListChequeLigne from "./ListChequeLigne";
import { t } from "i18next";

function ListChequeEmis() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [idCheque, setidCheque] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getChequeEmisEnteteByCode()
      .then((res) => {
        setdata(res.data);
        setidCheque(res.data[0].id);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="page-header d-lg-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Chèque émis")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className=" btn-list">
            <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
              <i className="feather feather-mail" />
            </button>
            <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
              <i className="feather feather-phone-call" />
            </button>
            <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
              <i className="feather feather-info" />
            </button>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row*/}
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row m-0 no-gutters">
                  <div className="col-lg-4">
                    <div className="border border-right-0 p-0 invoicelist" id="invoicelist">
                      <div className="card-body p-0">
                        <div className="tab-menu-heading sub-panel-heading p-0 border-0">
                          <div className="tabs-menu">
                            {/* Tabs */}
                            <ul className="nav panel-tabs">
                              {data.map((el, idx) => (
                                <li key={idx} className="active" onClick={() => setidCheque(el.id)}>
                                  <a className="d-flex" data-toggle="tab" href="#tab41" aria-expanded="true">
                                    <div className="mt-1">
                                      <h5 className="mb-1 font-weight-semibold mt-0">{t("numéro de chequier")} #{el.id}</h5>
                                      <p className="mb-0 text-muted">
                                        {el.premier_cheque} - {el.dernier_cheque}
                                      </p>
                                    </div>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 p-md-0">
                    <div className="panel-body tabs-menu-body p-6 invoice-print d-block invoicedetailspage" id="invoicedetailspage">
                      <div className="tab-content">
                        <div className="tab-pane active text-center" id="tab01">
                          <div className="row my-7">
                            <div className="my-8 col-md-9 d-block mx-auto">
                              <img src="assets/images/files/file.png" alt="img" className="w-30 mb-4" />
                              <h4 className="font-weight-semibold">{t("Chèque émis")}</h4>
                              <p className="text-muted">{t("Vous pouvez choisir un chequier à partir de la liste pour voir la liste des chèques")}.</p>
                              {/* <div className>
                                <button className="btn btn-light mt-1 mr-2">
                                  <i className="fe fe-eye" /> Voir
                                </button>
                                <button className="btn btn-light mt-1 mr-2">
                                  <i className="fe fe-download" /> Télécharger
                                </button>
                                <button className="btn btn-primary mt-1 mr-2">
                                  <i className="fe fe-printer" /> Imprimer
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab41">
                          <div className="card-body p-0">
                            <div className="invoice-header text-right d-block mb-5">
                              <h1 className="invoice-title font-weight-semibold text-uppercase mb-1">{t("Chèque émis")}</h1>
                            </div>
                            {/* invoice-header */}
                            {idCheque && <ListChequeLigne idCheque={idCheque}></ListChequeLigne>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListChequeEmis;
