import React, { useEffect, useState } from 'react'
import { getEntetInventaire, getProduitInventaire, insertInventare } from '../../../../../Services/Facturation/FacturationStockApi';
import Pagination from '../../../../Others/Pagination';
import { t } from "i18next";
import { filterFactureByWord, filterStockByWord } from '../../../../../Utils/SortObject';
import CustomSyncLoader from '../../../../Others/CustomSyncLoader';
import ErrorCustum from '../../../../Others/ErrorCustum';
import { Link } from "react-router-dom";
import { handleKeyPress_number } from '../../../../../Utils/InputUtils';
import { generateUniqueCode } from '../../../../../Utils/helper';
import { toast } from 'react-toastify';
import moment from "moment";

export default function ListInventaire() {
    const [currentData, setCurrentData] = useState([]);
    const [allInitialData, setAllInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);



    useEffect(() => {
        setLoading(true);
        getEntetInventaire()
            .then((res) => {
                setData(res.data.data);
                setAllInitialData(res.data.data);
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, []);
    const onChangePage = (pageOfItems) => {
        setCurrentData(pageOfItems);
    };
    const handleSearch = (e) => {
        const searchTerm = e.target.value.trim();
        if (searchTerm !== "") {
            const dataSearch = filterStockByWord(allInitialData, searchTerm);
            setData(dataSearch);
        } else {
            setData(allInitialData);
        }
    };
   
  return (

    <>
         <div className=" page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Inventaire ") }</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
            <Link to="inventaire" className="btn btn-primary mr-3">
                      {t("Ajouter Inventaire")}
                    </Link>
              
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                {t("Ajouter produit")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>
            <div className="page-header d-xl-flex d-block">
                <div className="page-leftheader"></div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("Inventaire  ")}{" "}
                                <span title="3 New Messages" className="badge badge-warning">
                                    {currentData.length}
                                </span>
                            </h3>
                            <div className="card-tools">
                                <div className="row">
                                    <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                                        <input
                                            type="text"
                                            name="table_search"
                                            className="form-control floating"
                                            placeholder={t("Rechercher...")}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0 ml-5 mr-5">
                            {loading && <CustomSyncLoader />}
                            {!loading && error && <ErrorCustum />}
                            {!loading && !error && data && (
                                <div className="table-responsive-sm">
                                    <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                            <tr>
                                                <th>{t(" Etat")}</th>
                                                <th>{t(" Code inventaire")}</th>
                                                <th>{t("User")}</th>
                                                <th>{t("Date Time")}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((el, index) => (
                                                <tr key={index}>
                                                    <td>{el.validation === 0 ? 'non validé' : 'validé'}</td>
                                                    <td>
                                                        <Link to={"/admin/lignesinventaire/" + el.code +'/'+ el.validation}>{el.code}</Link>
                                                    </td>
                                                    <td>
                                                        <Link to={"/admin/users/" + el.user_code}>{el.nom+' '+el.prenom}</Link>
                                                    </td>
                                                    <td>{el.date_Inv}</td>
                                                 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="card-footer clearfix">
                            <Pagination items={data} onChangePage={onChangePage} pageSize={4} />
                           
                        </div>
                      
                   
                    
                    </div>
                </div>
            </div>
        </>
  )
}
