import React from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ExportPdf({ data, name, columns }) {
  /**
   * It exports the data to a pdf file.
   */
  const exportToPdf = () => {
    //alert("ddddd");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = name;
    const headers = columns;

    var datas = [];
    for (let i = 0; i < data.length; i++) {
      // console.log(data[i]);
      var dataValue = [];
      for (let j = 0; j < headers.length; j++) {
        console.log();
        dataValue.push(data[i][headers[j]]);
      }
      datas.push(dataValue);
    }

    let content = {
      startY: 50,
      head: [headers],
      body: datas,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${name}.pdf`);
  };

  return (
    <Link
      to="#"
      className="action-btns"
      onClick={() => exportToPdf()}
      data-toggle="tooltip"
      data-placement="top"
      title="pdf"
    >
      <i className="fa fa-file-pdf-o text-danger" />
      {/* <img src="assets/images/files/file.png" alt="img" className="w-5 h-5" /> */}
    </Link>
  );
}

export default ExportPdf;
