import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import { editTaxById, getTaxById } from "../../../Services/Facturation/FacturationTvaApi";
import { handleKeyPress_number } from "../../../Utils/InputUtils";

function EditTax(props) {
  const history = useHistory();
  const [taxe , settaxes]=useState({
    id:'',
    taux:'',
    type:'',
    libelle:''
  })

  const myRefHide = useRef(null);
  const listtaxes = ['tva', 'rs']
  useEffect(() => {
    getData();
    props.setisEditing(false);
  }, [props.isEditing]);

  const getData = () => {
    getTaxById(props.idTax).then((res) => {
      settaxes({
        id:res.data.id,
        taux:res.data.taux,
        type:res.data.type,
        libelle:res.data.libelle
      })
      
    });
  };

  const handleSubmitTax = () => {
    editTaxById(taxe).then(() => {
      props.getData();
      myRefHide.current.click();
    });
  };
  return (
    <div
      className="modal fade"
      id="taxModalEdit"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="taxModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
             {taxe.id !=='' ? ' Editer tax' : 'Ajouter tax'}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    Type<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={taxe.type}
                    onChange={(e) => settaxes(prev => ({...prev, type: e.target.value}))}
                    >
                    <option value=''>choisir type</option>
                    {listtaxes.map(item => (
                      
                      <option value={item}>
                        {item}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Libelle<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="..."
                    required
                    value={taxe.libelle}
                    onChange={(e) => settaxes(prev => ({...prev, libelle: e.target.value}))}
                    />
                  {/* <small id="emailHelp" className="form-text text-muted">
          We'll never share your email with anyone else.
        </small> */}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">
                    Taux (%)<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="..."
                    required
                    value={taxe.taux}
                    onKeyPress={handleKeyPress_number}
                    onChange={(e) => settaxes(prev => ({...prev, taux: e.target.value}))}
                    />
                </div>

                <div className="form-check"></div>
                {/* <button type="submit" className="btn btn-primary">
              Modifier
            </button> */}
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefHide}>
              Annuler
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmitTax}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTax;
