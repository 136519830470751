import React, { useState } from "react";
import { Link } from "react-router-dom";
import BillParams from "../Facturation/invoiceReports/BillParams";
import ConfigDocument from "./ConfigDocument";
import ConfigPointage from "./ConfigPointage";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

/**
 * This function is used to configure the company's settings
 * @returns The return is the configuration page.
 */
function Configuration() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Entreprise")}</h4>
        </div>
        <div className="page-rightheader ml-xl-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/*Row*/}
      <div className="row">
        <div className="col-xl-3">
          <div className="card">
            <div className="nav flex-column admisetting-tabs" id="settings-tab" role="tablist" aria-orientation="vertical">
              <a className="nav-link active" data-toggle="pill" href="#tab-1" role="tab">
                <i className="nav-icon las la-share-alt" /> {t("Régime Horaire")}
              </a>
              <a className="nav-link" data-toggle="pill" href="#tab-2" role="tab">
                <i className="nav-icon lar la-credit-card" /> {t("Configuration facture")}
              </a>
              <a className="nav-link" data-toggle="pill" href="#tab-3" role="tab">
                <i className="nav-icon las la-file" /> {t("Configuration des documents")}
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          <div className="tab-content adminsetting-content" id="setting-tabContent">
            <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">{t("Modifier information entreprise")}</h4>
                </div>
                <div className="card-body">
                  <ConfigPointage />
                </div>
                {/* <div className="card-footer">
                  <Link to="#" className="btn btn-primary">
                    Modifier
                  </Link>

                </div> */}
              </div>
            </div>
            <div className="tab-pane fade" id="tab-2" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">{t("Modification paramètre facture")}</h4>
                </div>
                <div className="card-body">
                  <BillParams></BillParams>
                </div>
                {/* <div className="card-footer">
                <a href="#" className="btn btn-success">
                  Save Changes
                </a>
                <a href="#" className="btn btn-danger">
                  Cancel
                </a>
              </div> */}
              </div>
            </div>
            <div className="tab-pane fade" id="tab-3" role="tabpanel">
              <div className="card">
                <div className="card-header  border-0">
                  <h4 className="card-title">{t("Ajouter type document collaborateur")}</h4>
                </div>
                <div className="card-body">
                  <ConfigDocument />
                </div>
                {/* <div className="card-footer">
                <a href="#" className="btn btn-success">
                  Save Changes
                </a>
                <a href="#" className="btn btn-danger">
                  Cancel
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configuration;
