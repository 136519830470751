import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import {  editCongeType, senFcmConge,  } from "../../Services/Pointeuse/CongeApi";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import ImageComponent from "../Styles/ImageComponent";
import firebase from "../../firebase";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import { acceptAttestation, sentAttestationEmail } from "../../Services/Pointeuse/AttestationApi";

function TableAttestation({ onUpdate, arrayObject, valid, onRemoveobject }) {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const history = useHistory();
  const [logo, setlogo] = useState(null);
  const [motif, setmotif] = useState(null);
  const [userId, setuserId] = useState(null);
  const [data, setdata] = useState(arrayObject);
  console.log("dataaaaa",data)
  useEffect(() => {
    setdata(arrayObject);
  }, [arrayObject]);

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setlogo(res.data.logo);
    });
  }, []);
  /**
   * Accept a leave request
   * @param receiver - The email address of the user who is receiving the conge.
   * @param nom - The name of the user who sent the request.
   * @param prenom - The first name of the user who sent the request.
   * @param id - The id of the conge you want to accept.
   * @param type - The type of the conge.
   * @param date - The date of the conge.
   * @param valid - The valid of the leave request.
   * @param user_id - The id of the user who sent the request.
   */
  const handleAccept = (receiver, nom, prenom, id, type, date, valid, user_id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté l'attestation de Travail !"),
      icon: "warning",
      // buttons: true,
      buttons: {
      cancel: t("Cancel"), // Traduisez le bouton "Cancel"
      confirm: {
        text: t("OK"), // Traduisez le bouton "OK"
        value: true,
      },
    },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAttestation(id, "1").then(() => {
          //toast.success("Congé accepté");
          sentAttestationEmail(receiver, nom, prenom, logo, id, type, date, valid).then(() => {
            swal(t("Opération effectuée avec succès!"), {
              icon: "success",
            });
            const updatedData = arrayObject.filter((item) => item.id != id);
            setdata(updatedData);
            onRemoveobject(id);

            sendFirebaseNotification(valid, user_id);
          });
        });
      }
    });
  };

  /**
   * It sends a notification to the user.
   * @param type - The type of notification to send.
   * @param receiver - The user's token.
   */
  const sendFirebaseNotification = (type, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmConge(type, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It sends a notification to the user who requested the leave.
   * @param id - The id of the leave request.
   * @param receiver - The user who will receive the notification.
   */
  const handleReject = (id, receiver) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé cette Attestation !"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAttestation(id, "0").then(() => {
          //toast.success("Congé accepté");
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          const updatedData = arrayObject.filter((item) => item.id != id);
         
          setdata(updatedData);
          sendFirebaseNotification(t("refusé"), receiver);
        });
      }
    });
  };

  /**
   * It sets the motif to the type of motif and sets the userId to the id of the user.
   * @param type - The type of motif to be displayed.
   * @param id - The id of the user you want to get the motifs for.
   */
  const handleMotif = (type, id) => {
    setmotif(type);
    setuserId(id);
  };



  /**
   * It navigates to the attestation page with the id of the conge as a parameter.
   * @param id - The id of the attestation to print.
   * @param obj - The object that you want to pass to the next page.
    */
  const navigateToAttestation = (id, obj) => {
    console.log("kkkkkkkkkk",obj)
    // console.log(" el before the table :")
    history.push("/admin/print_attestationt/" + id, { state: obj });
   };






  return (
    <>
      <table className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer">
        <thead>
          <tr>
            <th className="border-bottom-0 w-5"></th>
            {/* <th>Collaborateur</th> */}
            <th className="border-bottom-0 w-5">{t("Fonction")}</th>
            <th className="border-bottom-0 w-5">{t("date")}</th>
            <th className="border-bottom-0 w-5">{t("Type")}</th>
            <th className="border-bottom-0 w-5">{t("Statut")}</th>
            <th className="border-bottom-0 w-5"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <tr key={el.id}>
               <td>
                <div className="d-flex">
                  <ImageComponent picture={el.imguser} atr={"avatar avatar-md brround mr-3"}></ImageComponent>
                  <div className="mr-8 mt-0 mt-sm-1 d-block">
                    <h6 className="mb-1 fs-14">
                      {el.nom} {el.prenom}
                    </h6>
                    <p className="text-muted mb-0 fs-12">{el.email}</p>
                  </div>
                </div>
              </td>
              <td>{el.fonction}</td>
              <td>{el.createdAt}</td>
              <td>
              {t(el.type)}
              </td>
              <td className="text-center">
                {el.valid == 0 ? (
                  <span className="badge badge-danger">{t("refusé")}</span>
                // ) : el.valid == 1 ? (
                //   <span className="badge badge-success">{t("accepté")}</span>
                ) : (
                  <span className="badge badge-success">{t("accepté")}</span>
                )}
              </td>

              {/* file */}
              {/* <td className="text-center">
                {el.type === "attestation" && el.valid === 1 && (
                  <Link to="#" data-toggle="tooltip" data-placement="top" title="titre attestation" 
                  onClick={() => navigateToAttestation(el.id, el)}
                  >
                    <i className="feather feather-file text-info"></i>
                  </Link>
                )}
              </td> */}
               <td className="text-center">
                {el.type === "attestation" && el.valid === 1 && (
                  <Link 
                    to={{ 
                      pathname: "/admin/print_attestationt/" + el.id, 
                      state: { obj: el.user_id } 
                    }} 
                    data-toggle="tooltip" 
                    data-placement="top" 
                    title="titre attestation"
                  >
                    <i className="feather feather-file text-info"></i>
                  </Link>
                )}
              </td>
              {/* action */}
              <td className="text-left">
                {el.valid === 1 ? (
                  <>
                    <Link
                      to="#"
                      onClick={() =>
                        handleAccept(el.email, el.nom, el.prenom, el.id, el.type, el.createdAt, "accepté", el.user_id)
                      }
                      className="action-btns"
                      data-tip="editer"
                    >
                      {/* <i className="feather feather-edit text-info" /> */}
                      <i className="fa fa-check-circle text-success"></i>
                    </Link>
                    <Link to="#" onClick={() => handleReject(el.id,el.nom, el.prenom, el.user_id)} className="action-btns" data-tip="editer">
                      {/* <i className="feather feather-edit text-info" /> */}
                      <i className="fa fa-ban text-danger"></i>
                    </Link>
                  </>
                ):(
                  <>
                  <Link
                    to="#"
                    onClick={() =>
                      handleAccept(el.email, el.nom, el.prenom, el.id, el.type, el.createdAt, "accepté", el.user_id)
                    }
                    className="action-btns"
                    data-tip="editer"
                  >
                    {/* <i className="feather feather-edit text-info" /> */}
                    <i className="fa fa-check-circle text-success"></i>
                  </Link>
                  <Link to="#" onClick={() => handleReject(el.id,el.nom, el.prenom, el.user_id)} className="action-btns" data-tip="editer">
                    {/* <i className="feather feather-edit text-info" /> */}
                    <i className="fa fa-ban text-danger"></i>
                  </Link>
                </>
                )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal */}
  
    </>
  );
}

export default TableAttestation;
