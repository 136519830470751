import React, { useEffect, useState } from 'react'
import { getEntetInventaireNonvalide, getProduitInventaire, insertInventare, updateInventaire, valideInventaire } from '../../../../../Services/Facturation/FacturationStockApi';
import Pagination from '../../../../Others/Pagination';
import { t } from "i18next";
import { filterFactureByWord, filterStockByWord } from '../../../../../Utils/SortObject';
import CustomSyncLoader from '../../../../Others/CustomSyncLoader';
import ErrorCustum from '../../../../Others/ErrorCustum';
import { handleKeyPress_number } from '../../../../../Utils/InputUtils';
import { generateUniqueCode } from '../../../../../Utils/helper';
import { toast } from 'react-toastify';
import moment from "moment";
import { getLignesInventaire } from '../../../../../Services/Facturation/FacturationStockApi';
import { Link, useHistory } from "react-router-dom";

export default function LigneInventaire(props) {
    const [currentData, setCurrentData] = useState([]);
    const [allInitialData, setAllInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const history = useHistory();

    const [update, setUpdate] = useState(false);


    useEffect(() => {
        setLoading(true);


        getLignesInventaire(props.match.params.id)
            .then((res) => {
                setData(res.data.data);
                setAllInitialData(res.data.data);
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }, []);
    const handlchangesaisie = (value, id) => {
        const list = [...allInitialData]; 

        const findindex =   allInitialData.findIndex(item => item.id === id);


        list[findindex].quantite_saisie = value ;
        setAllInitialData(list)
    }
    const onChangePage = (pageOfItems) => {
        setCurrentData(pageOfItems);
    };
    const handleSearch = (e) => {
        const searchTerm = e.target.value.trim();
        if (searchTerm !== "") {
            const dataSearch = filterStockByWord(allInitialData, searchTerm);
            setData(dataSearch);
        } else {
            setData(allInitialData);
        }
    };
    const handleClick = () => {
        const data = { code: props.match.params.id }
        valideInventaire(data)
            .then((res) => {
                if (res.status === 201) {
                    toast.success('Inventaire validé')
                    history.push('/admin/listinventaire')

                } else {
                    toast.error(res.data.message)
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    }
    const handleupdate = ()=>{
        const filtredlist = allInitialData.filter(item=> item.quantite_saisie !== '') ;
        const entet_data ={
           
            user_code:localStorage.getItem('id'),
            type : 'stock',
            date:moment(new Date()).format("YYYY-MM-DD"),
            code_generated: localStorage.getItem('code_generated')
        }
        filtredlist.forEach(element => {
            if(!element.quantite_saisie){
                element.quantite_saisie=element.quantite
            }
        });
        const data ={entetInventaire:entet_data,lignes:filtredlist}
       
        updateInventaire(data)
        .then((res) => {
            if(res.status === 200){
                toast.success('inventaire saved with success')
                window.location.reload()
            }else{
                toast.error(res.data.message)

            }

            
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    }
    return (
        <>

            <div className="page-header d-xl-flex d-block">
                <div className="page-leftheader"></div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {t("Inventaire  ")}{props.match.params.id}
                                <span title="3 New Messages" className="badge badge-warning">
                                    {currentData.length}
                                </span>
                            </h3>
                            <div className="card-tools">
                                <div className="row">
                                    <div className="input-group input-group-sm" style={{ width: 130, margin: 4 }}>
                                        <input
                                            type="text"
                                            name="table_search"
                                            className="form-control floating"
                                            placeholder={t("Rechercher...")}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive p-0 ml-5 mr-5">
                            {loading && <CustomSyncLoader />}
                            {!loading && error && <ErrorCustum />}
                            {!loading && !error && data && (
                                <div className="table-responsive-sm">
                                    <table className="table table-striped custom-table m-b-0">
                                        <thead>
                                            <tr>
                                                <th>{t(" Produit")}</th>
                                                <th>{t("Code Produit")}</th>
                                                <th>{t("Quantite theorique")}</th>
                                                <th>{t("Nouvelle Quantite ")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((el, index) => (
                                                <tr key={index}>

                                                    <td>
                                                        <Link to={"/admin/produit/" + el.produit_code}>{el.libelle}</Link>
                                                    </td>
                                                    <td>
                                                        <Link to={"/admin/produit/" + el.produit_code}>{el.produit_code}</Link>
                                                    </td>
                                                    <td>{el.old_quantite}</td>
                                                    <td>{!update ? el.quantite : <input type='text'
                                                        onKeyPress={handleKeyPress_number}
                                                        value={allInitialData.find(item => item.id === el.id).quantite_saisie || el.quantite}
                                                        onChange={(e) => handlchangesaisie(parseInt(e.target.value), el.id)} />}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="card-footer clearfix">
                            <Pagination items={data} onChangePage={onChangePage} pageSize={4} />
                            {props.match.params.validation === '0' ? (
                                <>
                                    {!update && (<button type="button" className="btn btn-primary float-right" onClick={handleClick}>
                                        <i className="far fa-credit-card" /> {t("Valider")}
                                    </button>)}
                                    <button type="button" className="btn btn-primary " onClick={() => {!update ? setUpdate(true): handleupdate()}}>
                                        <i className="far fa-credit-card" /> {t("Modifier")}
                                    </button></>
                            ) : null}

                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}
