import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getHolidayByCode } from "../../Services/Pointeuse/HolidayApi";
import { compareDate, compareTwoDate, getMonthName } from "../../Utils/DateUtils";

function UpcommingHoliday() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getHolidayByCode()
      .then((res) => {
        let result = res.data;

        const commingHoliday = result.filter((el) => {
          return !compareDate(el.date);
        });
        if (commingHoliday.length > 6) {
          setdata(commingHoliday.slice(0, 7));
        } else {
          setdata(commingHoliday);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  return (
    <div className="card">
      <div className="card-header border-0">
        <h4 className="card-title">{t("Jour férié à venir")}</h4>
        <div className="card-options pr-3">
          <div className="dropdown">
            <Link to="admin/holiday" className="btn ripple btn-outline-light dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
              {t("Ajouter")}
            </Link>
            {/* <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li>
                <Link to="#">{t("Ce mois")}</Link>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="card-body mt-1">
        {data.map((el, idx) => (
          <div key={idx} className="mb-5">
            <div className="d-flex comming_holidays calendar-icon icons">
              {el.type == "CNP" ? (
                <span className="date_time bg-danger-transparent bradius mr-3">
                  <span className="date fs-20">{el.date?.split("-")[2]}</span>
                  <span className="month fs-13">{getMonthName(el.date?.split("-")[1])}</span>
                </span>
              ) : (
                <span className="date_time bg-success-transparent bradius mr-3">
                  <span className="date fs-20">{el.date?.split("-")[2]}</span>
                  <span className="month fs-13">{getMonthName(el.date?.split("-")[1])}</span>
                </span>
              )}

              <div className="mr-3 mt-0 mt-sm-1 d-block">
                <h6 className="mb-1 font-weight-semibold">{el.libelle}</h6>
                <span className="clearfix" />
                <small>{el.nbJour} J</small>
              </div>
              <p className="float-right text-muted  mb-0 fs-13 ml-auto bradius my-auto">{el.type}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UpcommingHoliday;
