import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};
/**
 * It adds a new salaire to the database.
 * @param client - the data that will be sent to the server
 * @param support - the data that will be sent to the server
 * @param nom_client - the data that will be sent to the server
 * @param methode_contact - the data that will be sent to the server
 * @param destinaire_num_tel - the data that will be sent to the server
 * @param remarque - the data that will be sent to the server
 * @param vis_a_vis - the data that will be sent to the server
 * @param satisfait - the data that will be sent to the server
 * @param anomalie_difficulte - the data that will be sent to the server
 * @param temps_reclamation - the data that will be sent to the server
 * @param temps_demande - the data that will be sent to the server
 * @param date - the data that will be sent to the server
   * @returns The response from the server.
 */
export async function addSatisfaction(data ) { 
   
     try {
      const response = await axios.post(api_url + "satisfaction/", data, config);
     
      return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast.error(t("error server"));
            console.log(error.response.data);
            console.log(error.response.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            toast.error(t("error request"));
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(t("Error"), error.message);
          }
          console.log(error.config);
          return error;
    }
  }
  

  export async function updatesatisfaction(data ) { 
   
    try {
     const response = await axios.put(api_url + "satisfaction/updatesatisfaction", data, config);
    
     return response;
   } catch (error) {
       if (error.response) {
           // The request was made and the server responded with a status code
           // that falls out of the range of 2xx
           toast.error(t("error server"));
           console.log(error.response.data);
           console.log(error.response.message);
           console.log(error.response.status);
           console.log(error.response.headers);
         } else if (error.request) {
           // The request was made but no response was received
           // `error.request` is an instance of XMLHttpRequest in the
           // browser and an instance of
           // http.ClientRequest in node.js
           toast.error(t("error request"));
           console.log(error.request);
         } else {
           // Something happened in setting up the request that triggered an Error
           console.log(t("Error"), error.message);
         }
         console.log(error.config);
         return error;
   }
 }
  export async function getAllSatisfaction() {
    try {
      const code_generated = localStorage.getItem('code_generated')
      const response = await axios.get(api_url + "satisfaction/all/"+code_generated, config);
  
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  export async function getAllSatisfactionbyclient(code) {
    try {
      const response = await axios.get(api_url + "satisfaction/getAllSatisfactionbyclient/"+code, config);
  
      return response;
    } catch (error) {
      console.error(error);
    }
  }
  // export async function getAllSatisfactionByDate(startDate,endDate) {
  //   try {
  //     const response = await axios.get(api_url + "satisfaction/date/",startDate,endDate, config);
  
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  export async function getAllSatisfactionByDate(startDate, endDate) {
    try {
      const response = await axios.get(api_url + "satisfaction/date/", {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        config,
      });
  
      return response;
    } catch (error) {
      console.error(error);
    }
  }
  