import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin, isLoginWithoutTime } from "../Utils/SessionUtils";

function PrivateRouteLogin({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>{isLoginWithoutTime() ? <Component {...props}></Component> : <Redirect to="/login" />}</Layout>
      )}
    ></Route>
  );
}

export default PrivateRouteLogin;
