import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";
import moment from "moment";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all abonnement by code
 * @returns The response is an array of objects.
 */
export async function getAllAbonnementByCode() {
  try {
    const response = await axios.get(api_url + "abonnement/" + getSession(t("code_generated")), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all abonnement by code and entete
 * @returns The response is an array of objects.
 */
export async function getAllAbonnementByCodeAndEntete() {
  try {
    const response = await axios.get(api_url + "abonnement/entete/" + getSession(t("code_generated")), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
// /**
//  * Get all abonnement by code and entete
//  * @returns The response is an array of objects.
//  */
// export async function getAllAbonnementByCodeAndEntetedate() {
//   try {
//     const response = await axios.get(api_url + "abonnement/date/entete/" + getSession(t("code_generated")), config);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }

// /**
//  * Get all abonnement by code and entete for a specific date range
//  * @param {string} startDate - The start date of the range
//  * @param {string} endDate - The end date of the range
//  * @returns The response is an array of objects.
//  */
// export async function getAllAbonnementByCodeAndEntetedate(startDate, endDate) {
//   try {
//     const response = await axios.get(
//       `${api_url}abonnement/date/entete/${getSession(t("code_generated"))}`,
//       {
//         params: {
//           date_debut: moment(startDate).format("YYYY-MM-DD"),
//           date_fin: moment(endDate).format("YYYY-MM-DD"),
//         },
//         headers: config.headers,
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// }
/**
 * Get all abonnement by code and entete for a specific date range
 * @param {string} date_debut - The start date of the range
 * @param {string} date_fin - The end date of the range
 * @returns The response is an array of objects.
 */
export async function getAllAbonnementByCodeAndEntetedate(date_debut, date_fin) {
  const credentials = {
    date_debut: date_debut,
    date_fin: date_fin
  };

  try {
    const response = await axios.get(api_url + "abonnement/date/entete", credentials, config);
    return response.data; // Assuming the data you need is in the response's data property
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error so that the calling code can handle it
  }
}

/**
 * Get all abonnement by code and entete for a specific date range
 * @param {string} date_debut - The start date of the range
 * @param {string} date_fin - The end date of the range
 * @returns The response is an array of objects.
 */
export async function getEnteteCommerciauxByDateRange(date_debut, date_fin) {
  const credentials = {
    date_debut: date_debut,
    date_fin: date_fin
  };

  try {
    const response = await axios.get(api_url + "abonnement/date/entete", credentials, config);
    return response.data; // Assuming the data you need is in the response's data property
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error so that the calling code can handle it
  }
}

/**
 * Get all abonnement by code
 * @param code - The code of the abonnement you want to get.
 * @returns The response is an array of objects.
 */
export async function getAllAbonnementByCodeAbonnement(code) {
  try {
    const response = await axios.get(api_url + "abonnement/code/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all abonnement by code
 * @param facture - The code of the abonnement you want to get.
  * @returns The response is an array of objects.
 */
export async function getAbonnementAndEnteteByProduitFacture(facture) {
  try {
    const response = await axios.get(api_url + "abonnement/check/" + facture , config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add an abonnement to the database
 * @param code_abonnement - The code of the abonnement.
 * @param frequence_facturation - The frequency of the billing.
 * @param client_code - The client code of the client
 * @param date_fin - Date of the end of the subscription
 * @param date_debut - Date of the first subscription
 * @param nb_mois - number of months
 * @param nb_users - number of users
 * @param produit_code - number of users
 * @param numFacture - number of users 
 * @param echeance1 - echeance1,
 * @param echeance2 - echeance2,
 * @param echeance3 - echeance3,
 * @param echeance4 - echeance4,
 * @param echeance5 - echeance5,
 * @param echeance6 - echeance6,
 * @param echeance7 - echeance7,
 * @param echeance8 - echeance8,
 * @param echeance9 - echeance9,
 * @param echeance10 - echeance_10
 * @param echeance11 - echeance_11
 * @param echeance12 - echeance_12
 * @returns The response is an object that contains the data that was returned by the API.
 */
export async function addAbonnement(
  code_abonnement,
  frequence_facturation,
  client_code,
  date_fin,
  date_debut,
  nb_mois,
  nb_users,
  produit_code,
  facture_code, 
  echeance1,
  echeance2,
  echeance3,
  echeance4,
  echeance5,
  echeance6,
  echeance7,
  echeance8,
  echeance9,
  echeance10,
  echeance11,
  echeance12
) {
  const credentiel = {
    societe_code: localStorage.getItem("code_generated"),
    code_abonnement: code_abonnement,
    frequence_facturation: frequence_facturation,
    client_code: client_code,
    date_fin: moment(date_fin).format("YYYY-MM-DD"),
    date_debut: moment(date_debut).format("YYYY-MM-DD"),
    nb_mois: nb_mois,
    nb_users: nb_users,
    facture_code:facture_code, 
    produit_code:produit_code,
    echeance_1: echeance1,
    echeance_2: echeance2,
    echeance_3: echeance3,
    echeance_4: echeance4,
    echeance_5: echeance5,
    echeance_6: echeance6,
    echeance_7: echeance7,
    echeance_8: echeance8,
    echeance_9: echeance9,
    echeance_10: echeance10,
    echeance_11: echeance11,
    echeance_12: echeance12,
  };
console.log("cridentiale",credentiel )
  try {
    const response = await axios.post(api_url + "abonnement/", credentiel, config);
    console.log(response);
    toast.success(t("abonnement ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Delete an abonnement by code
 * @param code - The code of the subscription to delete.
 * @returns The response is an object with a status code and a body. The body is an object with a
 * message property.
 */
export async function deleteAbonnementByCode(code) {
  try {
    const response = await axios.delete(api_url + "abonnement/code/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Delete an abonnement by id
 * @param id - The id of the abonnement you want to delete.
 * @returns The response object contains the status code, the response body, and the response headers.
 */
export async function deleteAbonnementById(id) {
  try {
    const response = await axios.delete(api_url + "abonnement/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Edit an abonnement by its id
 * @param dateFin - Date of the end of the subscription
 * @param dateDebut - Date of the beginning of the subscription
 * @param nbMois - number of months
 * @param nbUsers - number of users
 * @param frequence - the frequency of the billing.
 * @param id - id of the abonnement
 * @returns The response is an object with the following structure:
 */
export async function editAbonnementById(produitCode, dateFin, dateDebut, nbMois, nbUsers, frequence, id) {
  console.log("data of abonnement :", produitCode, dateFin, dateDebut, nbMois, nbUsers, frequence, id)
  const credentiel = {
    produit_code: produitCode,
    date_fin: dateFin,
    date_debut: dateDebut,
    nb_mois: nbMois,
    nb_users: nbUsers,
    frequence_facturation: frequence,
    id: id,
  };

   try {
    const response = await axios.patch(api_url + "abonnement/", credentiel, config);
    console.log(response);
    toast.success(t("Abonnement modifié"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}



