import React from 'react'
import { useState } from 'react';
import { t } from 'i18next';
import axios from 'axios';
import ImageComponent from '../Styles/ImageComponent';
import Pagination from '../Others/Pagination';
import ExportCsv from '../Exports/ExportCsv';
import ExportPdf from '../Exports/ExportPdf';
import CopieContent from '../Exports/CopieContent';
import CustomSyncLoader from '../Others/CustomSyncLoader';
import ErrorCustum from '../Others/ErrorCustum';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import TableAttestation from './TableAttestation';
import { acceptAttestation, getAllAttestationByCode, senFcmAttestation, sentAttestationEmail } from '../../Services/Pointeuse/AttestationApi';
import swal from 'sweetalert';
import firebase from '../../firebase';
import { getEntrepriseByCode } from '../../Services/Pointeuse/EntrepriseApi';
import { filterAttestationByWord } from '../../Utils/SortObject';
import AttestationCardHeader from './AttestationCardHeader';
export default function Attestation_Travail() {
  const [isCollapsed, setisCollapsed] = useState(false);
  const [size, setsize] = useState(8);
 const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);	
  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [logo, setlogo] = useState(null);
  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setlogo(res.data.logo);
    });
  }, []);

 
  

  /**
   * It filters the data by the search word.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterAttestationByWord(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };
    /**
   * It sends a notification to the user.
  //  * @param valid - The type of notification to send.
  //  * @param receiver - The user's device token.
  //  */
  //   const sendFirebaseNotification = (valid, receiver) => {
  //     const messaging = firebase.messaging();
  //     messaging
  //       .requestPermission()
  //       .then(() => {
  //         return messaging.getToken();
  //       })
  //       .then(() => {
  //         senFcmAttestation(valid, receiver);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  /**
 * @param valid - The type of notification to send.
 * @param receiver - The user's device token.
 */
const sendFirebaseNotification = (valid, receiver) => {
  // Demander la permission d'envoyer des notifications
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        const messaging = firebase.messaging();
        return messaging.getToken();
      } else {
        // Gérer le cas où la permission a été refusée
        console.log("Permission de notification refusée par l'utilisateur.");
        throw new Error("Permission de notification refusée");
      }
    })
    .then((token) => {
      if (token) {
        senFcmAttestation(valid, receiver);
      } else {
        // Gérer le cas où le token n'a pas été obtenu
        console.log("Impossible d'obtenir le token de l'appareil.");
      }
    })
    .catch((err) => {
      console.log(err);
      // Gérer d'autres erreurs liées à la demande de permission ou à l'obtention du token
    });
};


    useEffect(() => {
      // Récupérez les données depuis localStorage lors du chargement de la page
      const savedData = localStorage.getItem('data');
      if (savedData) {
        setcurrentData(JSON.parse(savedData));
      }
  
      // ... (autres effets ou chargement de données initiales)
    }, []);
  /**
   * Accept a leave request
   * @param receiver - The email address of the user who is receiving the attestation.
   * @param nom - The name of the user who sent the request.
   * @param prenom - The first name of the user who sent the request.
   * @param id - The id of the attestation you want to accept.
   * @param type - The type of the attestation.
   * @param createdAt - The date of the attestation.
   * @param valid - The valid of the leave request.
   * @param user_id - The id of the user who sent the request.
   */
  const handleAccept = (receiver, nom, prenom, id, type, createdAt, valid, user_id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté l'attestation!"),
      icon: "warning",
      // buttons: true,
      buttons: {
      cancel: t("Cancel"), // Traduisez le bouton "Cancel"
      confirm: {
        text: t("OK"), // Traduisez le bouton "OK"
        value: true,
      },
    },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptAttestation(id, 1).then(() => {
          //toast.success("Congé accepté");
          sentAttestationEmail(receiver, nom, prenom, logo, id, type, createdAt, valid).then(() => {
            swal(t("Opération effectuée avec succès!"), {
              icon: "success",
            });
            const updatedData = allInitialData.filter((item) => item.id != id);
            setdata(updatedData);
            // Mettez à jour l'état avec la nouvelle liste
            setcurrentData(updatedData);
            setallInitialData(updatedData);

            sendFirebaseNotification(valid, user_id,receiver);
            // Sauvegardez les données mises à jour dans localStorage
            localStorage.setItem('data', JSON.stringify(updatedData));
          });
        });
      }
    });
  };
  const removeObject = (id) => {
    const updatedData = allInitialData.filter((item) => item.id != id);
    setdata(updatedData);
    setallInitialData(updatedData);
  };



  /**
   * It gets all the data from the API and sets it to the data variable.
   */
  const getData = () => {
    setLoading(true);
    getAllAttestationByCode()
      .then((res) => {
        setdata(res.data);
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);
 
  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
    /**
   * It sends a notification to the user who requested the leave.
   * @param id - The id of the leave request
   * @param valid - number of days of the leave
   * @param receiver - The user who will receive the notification.
   */

    const handleReject = (id, valid, receiver) => {
      swal({
        title: t("Ëtes-vous sûr ?"),
        text: t("Voulez vous vraiment refusé le demande de l'attestation!"),
        icon: "warning",
        // buttons: true,
        buttons: {
          cancel: t("Cancel"), // Traduisez le bouton "Cancel"
          confirm: {
            text: t("OK"), // Traduisez le bouton "OK"
            value: true,
          },
        },
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          acceptAttestation(id, "0").then(() => {
            //toast.success("Congé accepté");
            swal(t("Opération effectuée avec succès!"), {
              icon: "success",
            });
            const updatedData = allInitialData.filter((item) => item.id != id);
            setdata(updatedData);
            setcurrentData(updatedData);
            setallInitialData(updatedData);
            sendFirebaseNotification(t("refusé"), receiver,valid);
          });
        }
      });
    };
  return (
    <>
     <AttestationCardHeader getData={getData} setdata={setdata} data={data} allInitialData={allInitialData}/>
    <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Liste des attestations de travail")}</h4>
              <div className="card-options">
                {/* <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link> */}
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("accepter")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("refusé")}
                  </span>
                  {/* <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> ---&gt; Absent
                  </span>
                  <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> ---&gt; Holiday
                  </span>
                  <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1"
                            //  onChange={handleChangeSize}
                             >
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_demandes_congés"} />
                          <ExportPdf data={data} name={"List_demandes_congés"} columns={["nom", "prenom", "objet", "date"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <TableAttestation 
                        onUpdate={getData}
                         arrayObject={currentData} valid="demande" 
                        onRemoveobject={removeObject}
                        >
                        </TableAttestation>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="valid" aria-live="polite">
                          {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Aperçu demande Attestaion de travail")}</h4>
            </div>
            <div className="card-body">
              <div id="leavesoverview" className="mx-auto pt-2" />
              <div className="row mx-auto text-center">
                <div className="col-md-12 mx-auto d-block">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex font-weight-semibold">
                        {/* /* */}

                        <div className="card-body">
                          <div id="carousel-controls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                              {currentData.map((el, idx) => (
                                <>
                                  {idx == 0 ? (
                                    <div key={idx} className="carousel-item active">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="card border p-0 shadow-none">
                                          <div className="card-header border-0">
                                            <h3 className="card-title">{t("Demande de l'attestation")}</h3>
                                            <div className="ml-auto">
                                              <span className="badge badge-md badge-warning-light">{el.objet}</span>
                                            </div>
                                          </div>
                                          <div className="d-flex p-4">
                                            <ImageComponent
                                              picture={el.img}
                                              atr={"avatar avatar-lg brround d-block cover-image"}
                                            ></ImageComponent>
                                            <div className="pl-3">
                                              <h5 className="mb-0 mt-2 text-dark fs-18">
                                                {el.nom} {el.prenom}
                                              </h5>
                                              <span className="badge badge-md badge-primary-light ml-1">{el.fonction}</span>
                                              <p className="text-muted fs-12 mt-1 mb-0">{el.email}</p>
                                            </div>
                                          </div>
                                          <div className="card-body pt-2 bg-light">
                                            <div className="mt-3 mb-3">
                                              <div className="h5 mb-1">
                                                <span className="feather feather-calendar" /> : {el.createdAt}
                                              </div>
                                              <small className="text-muted fs-11">
                                                {t("Ajouté le")} : {el.createdAt}
                                                {/* On<span className="font-weight-semibold"> 5 weeks ago</span> */}
                                              </small>
                                            </div>
                                            <div className="progress progress-sm mb-2">
                                              <div className="progress-bar bg-success w-100" />
                                            </div>
                                          </div>
                                          <div className="p-4">
                                            <label className="form-label">{t("Raison")}:</label>
                                            <p className="text-muted leave-text">{el.type}</p>
                                          </div>
                                          <div className="card-footer p-0 border-top-0">
                                            <div className="btn-group w-100 leaves-btns">
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  handleAccept(
                                                    el.nom,
                                                    el.prenom,
                                                    el.valid,
                                                    el.id,
                                                    el.type,
                                                    el.createdAt,
                                                    "accepté",
                                                    el.user_id
                                                  )
                                                }
                                                className="btn btn-lg btn-outline-light w-50 text-success"
                                              >
                                                {t("Accepter")}
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={() => handleReject(
                                                  el.nom,
                                                  el.prenom,
                                                  el.id,
                                                  el.valid,
                                                  el.type,
                                                  el.createdAt,
                                                  "refuser",
                                                  el.user_id)}
                                                className="btn btn-lg btn-outline-light w-55 text-danger"
                                              >
                                                {t("Refuser")}
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div key={idx} className="carousel-item">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="card border p-0 shadow-none">
                                          <div className="card-header border-0">
                                            <h3 className="card-title">{t("Demande de l'attestation")}</h3>
                                            <div className="ml-auto">
                                            </div>
                                          </div>
                                          <div className="d-flex p-4">
                                            <ImageComponent
                                              picture={el.img}
                                              atr={"avatar avatar-lg brround d-block cover-image"}
                                            ></ImageComponent>
                                            <div className="pl-3">
                                              <h5 className="mb-0 mt-2 text-dark fs-18">
                                                {el.nom} {el.prenom}
                                              </h5>
                                              <p className="text-muted fs-12 mt-1 mb-0">{el.email}</p>
                                              <span className="badge badge-md badge-warning-light">{el.fonction}</span>
                                            </div>
                                          </div>
                                          <div className="card-body pt-2 bg-light">
                                            <div className="mt-3 mb-3">
                                              <div className="h5 mb-1">
                                                <span className="feather feather-calendar" /> : {el.createdAt}
                                                <span className="badge badge-md badge-primary-light ml-1">{el.objet} {("Jour")}</span>
                                              </div>
                                              <small className="text-muted fs-11">
                                                {t("Ajouté le")} : {el.createdAt}
                                                {/* On<span className="font-weight-semibold"> 5 weeks ago</span> */}
                                              </small>
                                            </div>
                                            <div className="progress progress-sm mb-2">
                                              <div className="progress-bar bg-success w-100" />
                                            </div>
                                            {/* <div className="d-flex align-items-end justify-content-between mb-0">
                                 <h6 className="fs-12 mb-0">Remaining Leaves</h6>
                                 <h6 className="font-weight-bold fs-12 mb-0">15</h6>
                               </div> */}
                                          </div>
                                          <div className="p-4">
                                            <label className="form-label">{t("Raison")}:</label>
                                            <p className="text-muted leave-text">{el.type}</p>
                                          </div>
                                          <div className="card-footer p-0 border-top-0">
                                            <div className="btn-group w-100 leaves-btns">
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  handleAccept(
                                                    el.email,
                                                    el.nom,
                                                    el.prenom,
                                                    el.objet,
                                                    el.id,
                                                    el.type,
                                                    el.date,
                                                    "accepté",
                                                    el.user_id
                                                  )
                                                }
                                                className="btn btn-lg btn-outline-light w-50 text-success"
                                              >
                                                {t("Accepter")}
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={() => handleReject(el.id, el.nbr_jour, el.user_code_id)}
                                                className="btn btn-lg btn-outline-light w-55 text-danger"
                                              >
                                                {t("Refuser")}
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                            <a className="carousel-control-prev" href="#carousel-controls" role="button" data-slide="prev">
                              <span className="carousel-control-prev-icon" aria-hidden="true" />
                              <span className="sr-only">{t("Previous")}</span>
                            </a>
                            <a className="carousel-control-next" href="#carousel-controls" role="button" data-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true" />
                              <span className="sr-only">{t("Next")}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
