import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { getToken } from "../../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};


/**
 * Get devise achat last seq

 */
export async function getLastDevisAchatID() {
  try {
    const response = await axios.get(api_url + "devise/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}



export async function getLastDevisVenteID() {
  try {
    const response = await axios.get(api_url + "devisvente/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getLastBLVenteID() {
  try {
    const response = await axios.get(api_url + "blvente/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getLastCommandeVenteID() {
  try {
    const response = await axios.get(api_url + "commandevente/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getLastfactureVenteID() {
  try {
    const response = await axios.get(api_url + "facturevente/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * Get commande achat last seq

 */
export async function getLastCommandeAchatID() {
  try {
    const response = await axios.get(api_url + "commande/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * Get commande achat avoir last seq

 */
export async function getLastAvoirAchatID() {
  try {
    const response = await axios.get(api_url + "avoirachat/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * Get commande achat avoir last seq

 */
export async function getLastAvoirVenteID() {
  try {
    const response = await axios.get(api_url + "avoirvente/getLastVenteID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get commande achat last seq

 */
export async function getLastBLAchatID() {
  try {
    const response = await axios.get(api_url + "bl/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * Get facture achat last seq

 */
export async function getLastFactureAchatID() {
  try {
    const response = await axios.get(api_url + "facture/getLastAchatID/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}