import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useHistory } from "react-router-dom";
import {
  addAdminAfterPaiement,
  getAllUsers,
  registerNewUser,
  sendEmailSuccessRegister,
} from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";

function RegisterForm({ amount, months, nbUsers }) {
  const history = useHistory();
  const alert = useAlert();
  const [name, setname] = useState(null);
  const [prename, setprename] = useState(null);
  const [gender, setgender] = useState("male");
  const [password, setpassword] = useState(null);
  const [verifPassword, setverifPassword] = useState(null);
  const [birth, setbirth] = useState(null);
  const [mobile, setmobile] = useState(null);
  const [email, setemail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState(null);
  const [isExiste, setisExiste] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then((res) => {
        const result = res.data.data;
        var listEmails = [];
        result.forEach((element) => {
          listEmails.push(element.email);
        });

        setdata(listEmails);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  /**
   * It sends an email to the user after he has successfully registered.
   * @param e - The event object.
   */
  const handelSave = (e) => {
    e.preventDefault();
    setloadingSubmit(true);
    //console.log(userId, object, description, etat, commentaire, dateDebut, dateFin, color, projectId);
    //console.log(nbUsers);
    var myDate = new Date();
    var dd = myDate.setMonth(myDate.getMonth() + months);
    var datefin = moment(dd).format("YYYY-MM-DD");

    console.log(password);
    console.log(verifPassword);

    if (
      name === null ||
      prename === null ||
      password === null ||
      verifPassword === null ||
      mobile === null ||
      birth === null ||
      email === null
    ) {
      alert.error("vérifié les champs vides");
      setloadingSubmit(false);
    } else if (isExiste) {
      alert.error("Email existe déja");
      setloadingSubmit(false);
    } else {
      if (password === verifPassword) {
        addAdminAfterPaiement(name, prename, mobile, birth, gender, email, password, datefin, nbUsers).then((res) => {
          sendEmailSuccessRegister(email, name, prename).then((res) => {
            setloadingSubmit(false);
            history.push("/success");
            setloadingSubmit(false);
          });
        });
      } else {
        alert.error("vérifié votre mot de passe");
      }
    }
  };

  /**
   * *This function checks if the email is already in the database. If it is, it sets the isExiste
   * variable to true. If it isn't, it sets the isExiste variable to false.*
   * @param value - The value of the input.
   */
  const handleCheckEmail = (value) => {
    setemail(value);
    data.includes(value) ? setisExiste(true) : setisExiste(false);
  };
  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && data && (
        <form onSubmit={handelSave}>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">
                  Nom<span className="text-danger"> *</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom"
                  required
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">
                  Prénom<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Prénom"
                  required
                  value={prename}
                  onChange={(e) => setprename(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">
                  Genre<span className="text-danger"> *</span>
                </label>
                <select
                  name="country"
                  className="form-control custom-select select2"
                  data-placeholder="Select Country"
                  value={gender}
                  onChange={(e) => setgender(e.target.value)}
                >
                  <option value="male">Male</option>
                  <option value="femele">Femelle</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="form-label">
                  Mobile<span className="text-danger"> *</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="mobile"
                    required
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-label">
                  Date de naissance<span className="text-danger"> *</span>
                </label>
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="date de naissance"
                    required
                    value={birth}
                    onChange={(e) => setbirth(e.target.value)}
                  />
                  <span className="input-group-append">
                    <button className="btn btn-secondary box-shadow-0">
                      <i className="fa fa-calendar" /> &nbsp;
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">
              email<span className="text-danger"> *</span>
            </label>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                placeholder="email..."
                required
                value={email}
                onChange={(e) => handleCheckEmail(e.target.value)}
              />

              <span className="input-group-append">
                <button className="btn btn-secondary box-shadow-0">
                  <i className="fa fa-envelope" /> &nbsp;
                </button>
              </span>
            </div>
            {isExiste && (
              <small className="form-text text-danger mb-5">Email existe déja , veuillez choisir un autre email</small>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Mot de passe<span className="text-danger"> *</span>
                </label>
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="mot de passe"
                    required
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  {/* <input type="number" className="form-control" placeholder="YY" name="Year" /> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Confirmer mot de passe<span className="text-danger"> *</span>
                </label>
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="mot de passe"
                    required
                    value={verifPassword}
                    onChange={(e) => setverifPassword(e.target.value)}
                  />
                  {/* <input type="number" className="form-control" placeholder="YY" name="Year" /> */}
                </div>
              </div>
            </div>
          </div>

          {loadingSubmit ? (
            <button className="btn btn-info btn-loading btn-block">Enregistrer</button>
          ) : (
            <button type="submit" className="btn btn-primary btn-block" onClick={handelSave}>
              Enregistrer
            </button>
          )}

          {/* <Link to="#" className="btn  btn-lg btn-primary">
            Confirm
          </Link> */}
        </form>
      )}
    </>
  );
}

export default RegisterForm;
