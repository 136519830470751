import React from "react";
import "./Footer.css";
import { Button } from "../../Button";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from "react-icons/fa";
import { MdFingerprint } from "react-icons/md";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Contactez-nous dés maintenant pour recevoir un devis personnalisé pour une pointeuse mobile
        </p>
        <p className="footer-subscription-text">Vous pouvez vous désabonner à tout moment.</p>
        <div className="input-areas">
          <form>
            <input className="footer-input" name="email" type="email" placeholder="Votre Email" />
            <Button buttonStyle="btn--outline">S'abonner</Button>
          </form>
        </div>
      </section>
      {/* <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/sign-up">How it works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Investors</Link>
            <Link to="/">Terms of Service</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/">Contact</Link>
            <Link to="/">Support</Link>
            <Link to="/">Destinations</Link>
            <Link to="/">Sponsorships</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Videos</h2>
            <Link to="/">Submit Video</Link>
            <Link to="/">Ambassadors</Link>
            <Link to="/">Agency</Link>
            <Link to="/">Influencer</Link>
          </div>
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <Link to="/">Instagram</Link>
            <Link to="/">Facebook</Link>
            <Link to="/">Youtube</Link>
            <Link to="/">Twitter</Link>
          </div>
        </div>
      </div>
       */}
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              {/* <MdFingerprint className="navbar-icon" /> */}
              <img className="navbar-icon" style={{ width: 50, height: 50 }} src="assets/images/brand/logo.png"></img>
              Pointeuse
            </Link>
          </div>
          <small className="website-rights">
            <Link to="//clediss.com" target="_blank">
              Clediss
            </Link>{" "}
            © 2021
          </small>
          <div className="social-icons">
            <Link
              className="social-icon-link"
              to="//fr-fr.facebook.com/Societe.CLEDISS/"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>
            {/* <Link className="social-icon-link" to="/" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </Link> */}
            <Link
              className="social-icon-link"
              to={"//www.youtube.com/channel/UCjc1V73hO75MlNig_TcZGSA"}
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </Link>
            {/* <Link className="social-icon-link" to="/" target="_blank" aria-label="Twitter">
              <FaTwitter />
            </Link> */}
            <Link
              className="social-icon-link"
              to="//www.linkedin.com/company/clediss/mycompany/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
