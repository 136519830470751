import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyAKa2bLPmMdjd_PEZGrHu2BmTH7Oxx3BvQ",
  authDomain: "ecg-telnet.firebaseapp.com",
  databaseURL: "https://ecg-telnet.firebaseio.com",
  projectId: "ecg-telnet",
  storageBucket: "ecg-telnet.appspot.com",
  messagingSenderId: "860540330866",
  appId: "1:860540330866:web:f3def4ce2cd2f6aee633eb",
  measurementId: "G-47E7HRG82Q",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
