import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllPointageHistory } from "../../Services/Pointeuse/PointageApi";
import { getAllSettings } from "../../Services/Pointeuse/SettingsApi";
import { getUsersActifByCode, getUsersByCode } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ImageComponent from "../Styles/ImageComponent";
import AddPointage from "./AddPointage";
import SingleMonthPointage from "./SingleMonthPointage";
import PointageStaticMonth from "./PointageStaticMonth";
import PointageMonth from "./PointageMonth";

var dateObj = new Date();
var result = dateObj.toISOString().split("T")[0];
var month = result.split("-")[1];
var year = result.split("-")[0];
var newdate = year + "-" + month;

function PointageTest() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [date, setdate] = useState(newdate);

  const [dataPointages, setDataPointages] = useState(null);

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getUsersByCode()
      .then((res) => {
        setdata(res.data);
        console.log(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getData();
  }, []);

  /**
   * The function takes in a date and sets the date state to that date
   * @param date - The date that the user has selected.
   */
  const handleChangeDate = (date) => {
    setLoading(true);
    setdate(date);
    setLoading(false);
  };

  useEffect(() => {
    getAllPointageHistory().then((res) => {
      setDataPointages(res.data);
    });
  }, []);

  return (
    <div>

          <div className="row" style={{marginTop:"22px"}}>
            <div className="col-xl-12 col-md-12 col-lg-12">
              <div className="tab-menu-heading hremp-tabs p-0 ">
                <div className="tabs-menu1">
                  {/* Tabs */}
                  <ul className="nav panel-tabs">
                    <li className="ml-4">
                      <a href="#tab5" className="active" data-toggle="tab">
                        {t("Pointage")}
                      </a>
                    </li>
                    <li>
                      <a href="#tab6" data-toggle="tab">
                        {t("Pointage du mois")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab5">
                    <PointageMonth />
                  </div>
                  <div className="tab-pane" id="tab6">
                    <div className="card-body">
                      <PointageStaticMonth  />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default PointageTest;
