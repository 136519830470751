import crypto from 'crypto';

export const convertMinutesToHous = (minutes) => {
    if (typeof minutes !== 'number' || minutes < 0) {
        return 'Invalid input';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursText = hours > 0 ? hours + ' h' + (hours > 1 ? 's' : '') : '';
    const minutesText = remainingMinutes > 0 ? remainingMinutes + ' min' + (remainingMinutes > 1 ? 's' : '') : '';

    const separator = (hours > 0 && remainingMinutes > 0) ? ' and ' : '';

    return hoursText + separator + minutesText;
}

export const  formatFloat=(number) =>{
    if(number){
     
        if (typeof number === 'string') {
            number = parseFloat(number);
        }
        
        let formattedNumber = number.toString();
        if (formattedNumber.indexOf('.') !== -1) {
            formattedNumber = parseFloat(number).toFixed(3);
        } else {
            formattedNumber += '.000';
        }
    
        return formattedNumber;
    } 
    
}
export function generateUniqueCode() {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const uniqueId = crypto.randomBytes(8).toString('hex');

    // Concaténer la date et l'identifiant unique
    const combinedString = currentDate.toISOString() + uniqueId;

    // Générer le hachage SHA-256
    const hash = crypto.createHash('sha256').update(combinedString).digest('hex');

    // Renvoyer les premiers 8 caractères du hachage comme code unique
    return hash.substring(0, 8);
}

export const arondisnumber = (valeur, chiffre) => {
    return parseFloat(parseFloat(valeur).toFixed(chiffre))
}
export const formatNChiffres = (num, n) => {

    const [entier, decimales] = num?.toString().split('.');

    const formattedDecimales = (decimales || '').substring(0, n).padEnd(n, '0');
    return parseFloat(`${entier}.${formattedDecimales}`);
};

export const float_decimale = (value) => {
   // const v =parseFloat(value).toFixed(3)
    const [entier, decimales] = value.toString().split('.');
    if (decimales === '') {
        return `${entier}.`;
    } else if (decimales === '0') {
        return `${entier}.0`;

    }
    else {
      return  formatNChiffres(value,6)
    }

}
export const arrondi_entier = (num, lastarrondi) => {
    //alert(num)
    const valeur = formatNChiffres((num + lastarrondi), 3)


    const [entier, decimales] = valeur.toString().split('.');
    if (decimales === undefined) {
        const newvaleur = parseInt(entier)

        return { newvaleur: newvaleur, valeur_difference: 0 }


    }
    else {
        const newvaleur = Math.round(valeur)
        const valeur_difference = valeur - newvaleur
        return { newvaleur: newvaleur, valeur_difference: parseFloat(valeur_difference.toFixed(3)) }

    }

}
export const nombreDeMoisEntreDeuxDates = (date1, date2) => {
 //   console.log(date1, date2)
    var dateDebut = new Date(date1);
    var dateFin = new Date(date2);
    var differenceEnMois = (dateFin.getFullYear() - dateDebut.getFullYear()) * 12;
    differenceEnMois -= dateDebut.getMonth() + 1;
    differenceEnMois += dateFin.getMonth();
  //  console.log( differenceEnMois <= 0 ? 0 : differenceEnMois)
    return differenceEnMois <= 0 ? 12 : differenceEnMois;
}
export const calcul_total_rub = (valeur_mensuel , presence ,total,valeur_annuel,nb_mois)=>{
   // console.log(valeur_mensuel , presence ,total,valeur_annuel,nb_mois)
    const new_value = valeur_mensuel ?presence === 1 ? total * parseFloat(valeur_mensuel) : parseFloat(valeur_mensuel) : presence === 1 ? total * (parseFloat(valeur_annuel) / nb_mois) : (parseFloat(valeur_annuel) / nb_mois)
    return formatNChiffres(new_value,3)
}