import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { useTranslation } from "react-i18next";
import { getAllSession, getSessionByCode } from "../../Services/Pointeuse/SessionApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Cookies from 'js-cookie';

function Header() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [data, setdata] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setsearchText] = useState(null);
  const [lng, setlng] = useState(null);
  const [sessions, setsessions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
 // ----- Language global selectionné
 const language = Cookies.get('locale')
 const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    setlng(i18n.language.split("-")[0]);
    // document.body.classList.add("sidebar-collapse");
    getUserById(localStorage.getItem("id")).then((res) => {
      setdata(res?.data);
    });
  }, []);
  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem('selectedLanguage', language);
    setIsDropdownOpen(false); // Masquer le dropdown après avoir changé la langue
  };
  /**
   * If the dark mode is on, it will turn it off and vice versa
   */
  const handleClick = () => {
    if (isOpen) {
      document.body.classList.remove("dark-mode");
      localStorage.setItem("pointeuse_dark", false);
      setIsOpen(!isOpen);
    } else {
      document.body.classList.add("dark-mode");
      localStorage.setItem("pointeuse_dark", true);
      setIsOpen(!isOpen);
    }
  };
  /**
   * It clears the local storage and redirects the user to the login page.
   */
  const handleLogout = () => {
    //localStorage.clear();
    localStorage.setItem("isLoginPointeuse", false);
    //history.push("/login");
    window.location.reload();
  };

  /**
   * When the user clicks the "Add to Home Screen" button, the user is prompted to install the app
   */
  const handleInstall = () => {
    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      console.log(e);
      const addBtn = document.querySelector(".zz");
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = "block";

      addBtn.addEventListener("click", () => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = "none";
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      });
    });
  };
  /**
   * The function `handleChangeL` is used to change the language of the application
   * @param l - The language code to change to.
   */

  const handleChangeL = (l) => {
    i18n.changeLanguage(l);
    setlng(l);
    localStorage.setItem('selectedLanguage', l);
  };

  // Récupérez la langue sélectionnée du stockage local au chargement initial de la composante
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setSelectedLanguage(savedLanguage);
    }
  }, [i18n]);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  /**
   * When the user clicks the search button, the search text is sent to the search page
   * @param e - The event object that contains the data about the event.
   */
  const handleGoToSearch = (e) => {
    e.preventDefault();
    history.push({ pathname: "/admin/search", state: searchText });
    setsearchText("");
  };

  useEffect(() => {
    setLoading(true);
    getAllSession()
      .then((res) => {
        if (res.data?.length > 5) {
          setsessions(res.data?.slice(0, 5));
        } else {
          setsessions(res.data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="app-header header">
      <div className="container-fluid">
        <div className="d-flex">
          <a className="header-brand" href="index.html">
            <img src="assets/images/brand/logo1.png" className="header-brand-img desktop-lgo" style={{ maxWidth: "50px" }} alt="Cleges" />
            <img src="assets/images/brand/logo1.png" className="header-brand-img dark-logo" alt="Cleges" />
            <img src="assets/images/brand/logo1.png" className="header-brand-img mobile-logo" style={{ maxWidth: "50px" }} alt="Cleges" />
            <img src="assets/images/brand/logo1.png" className="header-brand-img darkmobile-logo" alt="Cleges" />
          </a>
          <div className="app-sidebar__toggle" data-toggle="sidebar">
            <a className="open-toggle" href="#">
              <i className="feather feather-x" />
            </a>
            <a className="close-toggle" href="#">
              <i className="feather feather-menu" />
            </a>
          </div>
          <div className="mt-0">
            <form className="form-inline" onSubmit={handleGoToSearch}>
              <div className="search-element">
                <input
                  type="search"
                  className="form-control header-search"
                  placeholder={t("Recherche ...")}
                  aria-label="Search"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                  tabIndex={1}
                />
                <button className="btn btn-primary-color" type="submit">
                  <i className="feather feather-search" />
                </button>
              </div>
            </form>
          </div>
          {/* SEARCH */}
          <div className="d-flex order-lg-2 my-auto ml-auto">
            <a className="nav-link my-auto icon p-0 nav-link-lg d-md-none navsearch" href="#" data-toggle="search">
              <i className="feather feather-search search-icon header-icon" />
            </a>
            <div className={`dropdown header-flags ${isDropdownOpen ? 'show' : ''}`}>
              {selectedLanguage === 'ar' && (
                <a className="nav-link icon" onClick={toggleDropdown}>
                  <img src="assets/images/flags/flag-png/tunisia.png" className="h-24" alt="img" />
                </a>
              )}
              {selectedLanguage === 'fr' && (
                <a className="nav-link icon" onClick={toggleDropdown}>
                  <img src="assets/images/flags/flag-png/france.png" className="h-24" alt="img" />
                </a>
              )}
              {selectedLanguage === 'en' && (
                <a className="nav-link icon" onClick={toggleDropdown}>
                  <img src="assets/images/flags/flag-png/united-kingdom.png" className="h-24" alt="img" />
                </a>
              )}
              <div className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow animated ${isDropdownOpen ? 'show' : ''}`}>
                <Link to="#" className="dropdown-item d-flex " onClick={() => handleChangeLanguage('ar')}>
              <span className="avatar  mr-3 align-self-center bg-transparent">
                <img src="assets/images/flags/flag-png/tunisia.png" alt="img" className="h-24" />
              </span>
              <div className="d-flex">
                <span className="my-auto">{t('arabe')}</span>
              </div>
            </Link>
            <Link to="#" className="dropdown-item d-flex " onClick={() => handleChangeLanguage('fr')}>
              <span className="avatar  mr-3 align-self-center bg-transparent">
                <img src="assets/images/flags/flag-png/france.png" alt="img" className="h-24" />
              </span>
              <div className="d-flex">
                <span className="my-auto">{t('francais')}</span>
              </div>
            </Link>
            <Link to="#" className="dropdown-item d-flex " onClick={() => handleChangeLanguage('en')}>
              <span className="avatar  mr-3 align-self-center bg-transparent">
                <img src="assets/images/flags/flag-png/united-kingdom.png" alt="img" className="h-24" />
              </span>
              <div className="d-flex">
                <span className="my-auto">{t('anglais')}</span>
              </div>
            </Link>
        {/* Ajoutez des liens pour d'autres langues ici */}
      </div>
             </div>

            <div className="dropdown header-fullscreen">
              <a className="nav-link icon full-screen-link">
                <i className="feather feather-maximize fullscreen-button fullscreen header-icons" />
                <i className="feather feather-minimize fullscreen-button exit-fullscreen header-icons" />
              </a>
            </div>
            <div className="dropdown header-fullscreen" >
              <a className="nav-link icon full-screen-link">
                <i className="feather feather-bell header-icon" />
              </a>
            </div>
            <div className="dropdown header-fullscreen" onClick={handleClick}>
              <a className="nav-link icon full-screen-link">
                <i className="feather feather-sun header-icon" />
              </a>
            </div>
           
            <div className="dropdown header-fullscreen zz" onClick={handleInstall}>
              <a className="nav-link icon full-screen-link">
                <i className="feather feather-download header-icon" />
              </a>
            </div>
            

            {loading && <CustomSyncLoader/>}
            {/*!loading && error && <ErrorCustum/>*/}
            {!loading && !error && sessions && (
              <div className="dropdown header-message">
                <a className="nav-link icon" data-toggle="dropdown">
                  <i className="fa fa-key header-icon" />
                  <span className="badge badge-success side-badge">{sessions?.length}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow  animated">
                  <div className="header-dropdown-list message-menu" id="message-menu">
                    {sessions.length > 0 &&
                      sessions.map((el, idx) => (
                        <Link to="#" key={idx} className="dropdown-item border-bottom">
                          <div className="d-flex align-items-center">
                            <div>
                              {/* <span
                   className="avatar avatar-md brround align-self-center cover-image"
                   data-image-src="assets/images/users/1.jpg"
                 /> */}
                              {el.browser.split("/")[0] == "Edge" && (
                                <img
                                  src="/assets/images/session/edge.png"
                                  alt=""
                                  className="avatar avatar-md brround align-self-center cover-image"
                                />
                              )}
                              {el.browser.split("/")[0] == "Chrome" && (
                                <img
                                  src="/assets/images/session/chrome.png"
                                  alt=""
                                  className="avatar avatar-md brround align-self-center cover-image"
                                />
                              )}
                              {el.browser.split("/")[0] == "Firefox" && (
                                <img
                                  src="/assets/images/session/mozilla.png"
                                  alt=""
                                  className="avatar avatar-md brround align-self-center cover-image"
                                />
                              )}
                              {el.browser.split("/")[0] == "Safari" && (
                                <img
                                  src="/assets/images/session/safari.png"
                                  alt=""
                                  className="avatar avatar-md brround align-self-center cover-image"
                                />
                              )}
                            </div>
                            <div className="d-flex">
                              <div className="pl-3">
                                <h6 className="mb-1">{el.browser}</h6>
                                <p className="fs-13 mb-1">
                                  {el.ip} {el.os}
                                </p>
                                <div className="small text-muted">{el.date}</div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                  <div className=" text-center p-2">
                    <Link to="#">{t("Dernières connexions")}</Link>
                  </div>
                </div>
              </div>
            )}

            <div className="dropdown header-notify">
              <a className="nav-link icon" data-toggle="sidebar-right" data-target=".sidebar-right">
                <i className="feather feather-bell header-icon" />
                <span className="bg-dot" />
              </a>
            </div>
            <div className="dropdown profile-dropdown">
              <Link to="#" className="nav-link pr-1 pl-0 leading-none" data-toggle="dropdown">
                <span>
                  <img
                    src={data?.img == null || data?.img == "" ? "assets/images/users/avatar.png" : localStorage.getItem("baseUrl") + data?.img}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "assets/images/users/avatar.png";
                    }}
                    alt="img"
                    className="avatar avatar-md bradius"
                  />
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated">
                <div className="p-3 text-center border-bottom">
                  <Link to="#" className="text-center user pb-0 font-weight-bold">
                    {data?.nom} {data?.prenom}
                  </Link>
                  <p className="text-center user-semi-title">{data?.fonction}</p>
                </div>
                {/* <a className="dropdown-item d-flex" href="#">
                  <i className="feather feather-user mr-3 fs-16 my-auto" />
                  <div className="mt-1">Profile</div>
                </a> */}
                <Link className="dropdown-item d-flex" to="admin/configuration">
                  <i className="feather feather-settings mr-3 fs-16 my-auto" />
                  <div className="mt-1">{t("Paramètres")}</div>
                </Link>
                {/* <a className="dropdown-item d-flex" href="#">
                  <i className="feather feather-mail mr-3 fs-16 my-auto" />
                  <div className="mt-1">Messages</div>
                </a>
                <a className="dropdown-item d-flex" href="#" data-toggle="modal" data-target="#changepasswordnmodal">
                  <i className="feather feather-edit-2 mr-3 fs-16 my-auto" />
                  <div className="mt-1">Change Password</div>
                </a> */}
                <a className="dropdown-item d-flex" href="#">
                  <i className="feather feather-power mr-3 fs-16 my-auto" />
                  <div className="mt-1" onClick={handleLogout}>
                    {t("Se déconnecter")}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
