import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import Pagination from "../Others/Pagination";
import { addHoliday, deleteHolidayById, getHolidayByCode } from "../../Services/Pointeuse/HolidayApi";
import { filterByLibelle } from "../../Utils/SortObject";
import AddHoliday from "./AddHoliday";
import swal from "sweetalert";
import ApexPie4 from "../Charts/apexcharts/Pie4";
import AreaApexHoliday from "../Charts/CustumApex/PieApexHoliday";
import PieApexHoliday from "../Charts/CustumApex/PieApexHoliday";
import { getfilter } from "../../Utils/ObjectFilter";
import ButtonHeaderComponent from "../Styles/ButtonHeaderComponent";
import ExportCsv from "../Exports/ExportCsv";
import ExportPdf from "../Exports/ExportPdf";
import CopieContent from "../Exports/CopieContent";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation
import { addjour_ferire, getjour_ferire } from "../../Services/Pointeuse/EntrepriseApi";

function Holiday() {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t

  const [currentData, setcurrentData] = useState([]);
  const [allInitialData, setallInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const [dateStart, setdateStart] = useState("");
  const [dateEnd, setdateEnd] = useState("");
  const [libelle, setlibelle] = useState("");
  const [date, setdate] = useState("");
  const [nbjour, setnbjour] = useState("");
  const [type, settype] = useState("CP");
  const myRefDelete = useRef(null);
  const myRefAnnule = useRef(null);
  const [actionId, setActionId] = useState(false);
  const [size, setsize] = useState(20);
  const [paye, setpaye] = useState(null);
  const [nonPaye, setnonPaye] = useState(null);
  const [isCollapsed, setisCollapsed] = useState(false);

  /**
   * It calls the addHoliday function from the API and then calls the getData function to update the list
   * of holidays.
   */
  const handelSave = () => {
    const newdata= {

      libelle: libelle,
      date: date,
      nbre_jours: nbjour,
      type: type,
      code_generated: localStorage.getItem('code_generated')
    }
    addjour_ferire(newdata).then((res) => {
      if (res.status === 201) {
        toast.success(t("le jour  fériés  est enregistrée"))

        myRefAnnule.current.click();
        getData();
      }
    });
    /*addHoliday(libelle, date, nbjour, type).then((res) => {
      myRefAnnule.current.click();
      getData();
    });*/
  };

  useEffect(() => {
    getData();
  }, []);

  /**
   * It gets the data from the API and sets the data to the state.
   */
  const getData = () => {
    setLoading(true);
    getjour_ferire()
      .then((res) => {
        setdata(res?.data?.data);
        setallInitialData(res?.data?.data);
        setpaye(getfilter(res?.data?.data, "type", "CP"));
        setnonPaye(getfilter(res?.data?.data, "type", "CNP"));
        //setnonPaye();
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  /**
   * It filters the data by the value of the input.
   * @param e - The event object that contains the value of the search input.
   */
  const handleSearch = (e) => {
    if (e.target.value) {
      const dataSearch = filterByLibelle(allInitialData, e.target.value);
      setdata(dataSearch);
    } else {
      setdata(allInitialData);
    }
  };

  /**
   * It deletes a holiday from the database.
   * @param id - The id of the holiday to delete.
   */
  const handleDeleteVaccin = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: t("warning"),
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteHolidayById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };

  /**
   * The function sets the size of the data to be retrieved from the API.
   * @param e - The event object.
   */
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };

  /**
   * *This function is used to toggle the collapse state of the sidebar.*
   */
  const handleCollapse = () => {
    setisCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Jour férié / non férié")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="#" className="btn btn-primary mr-3" data-toggle="modal" data-target="#holidayModal">
                {t("Ajouter jour férié")}
              </Link>
              <ButtonHeaderComponent />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Total jour férié")}</span>
                    <h3 className="mb-0 mt-1 text-primary">{data.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-primary-transparent my-auto  float-right">
                    <i className="las la-male" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Total chômé payé")}</span>
                    <h3 className="mb-0 mt-1 text-success">{paye?.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-success-transparent my-auto  float-right">
                    <i className="las la-users" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">Total Female Employees</span>
                    <h3 className="mb-0 mt-1 text-secondary">1,396</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-secondary-transparent my-auto  float-right">
                    <i className="las la-female" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-xl-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-7">
                  <div className="mt-0 text-left">
                    <span className="font-weight-semibold">{t("Total chômé non payé")}</span>
                    <h3 className="mb-0 mt-1 text-danger">{nonPaye?.length}</h3>
                  </div>
                </div>
                <div className="col-5">
                  <div className="icon1 bg-danger-transparent my-auto  float-right">
                    <i className="las la-user-friends" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-0 pb-0">
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-primary-transparent">31</span>
                  <h5 className="mb-0 mt-3">Total Working Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-success-transparent">24</span>
                  <h5 className="mb-0 mt-3">Présent Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-danger-transparent">2</span>
                  <h5 className="mb-0 mt-3">Absent Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-warning-transparent">0</span>
                  <h5 className="mb-0 mt-3">Half Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5 ">
                  <span className="avatar avatar-md bradius fs-20 bg-orange-transparent">2</span>
                  <h5 className="mb-0 mt-3">Late Days</h5>
                </div>
                <div className="col-md-6 col-lg-2 text-center py-5">
                  <span className="avatar avatar-md bradius fs-20 bg-pink-transparent">5</span>
                  <h5 className="mb-0 mt-3">Holidays</h5>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">From:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">To:</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="feather feather-calendar" />
                            </div>
                          </div>
                          <input className="form-control fc-datepicker" placeholder="DD-MM-YYYY" type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Job Type:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Job Type"
                        >
                          <option label="Select Job Type" />
                          <option value={1}>Full-Time</option>
                          <option value={2}>Part-Time</option>
                          <option value={3}>Freelancer</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Status:</label>
                        <select
                          name="attendance"
                          className="form-control custom-select select2"
                          data-placeholder="Select Status"
                        >
                          <option label="Select Status" />
                          <option value={1}>Active</option>
                          <option value={2}>InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Jour férié / non férié")}</h4>
              <div className="card-options">
                <Link to="#" className="card-options-fullscreen mr-2" data-toggle="card-fullscreen" onClick={handleCollapse}>
                  <i className="fe fe-maximize" />
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex mb-6 mt-5">
                <div className="mr-3">
                  <label className="form-label">{t("Note")}:</label>
                </div>
                <div>
                  <span className="badge badge-success-light mr-2">
                    <i className="feather feather-check-circle text-success" /> {t("chomé payé")}
                  </span>
                  <span className="badge badge-danger-light mr-2">
                    <i className="feather feather-x-circle text-danger" /> {t("chomé non payé")}
                  </span>
                  {/* <span className="badge badge-warning-light mr-2">
                    <i className="fa fa-star text-warning" /> ---&gt; Holiday
                  </span>
                  <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                </div>
              </div>

              {loading && <CustomSyncLoader></CustomSyncLoader>}
              {!loading && error && <ErrorCustum></ErrorCustum>}
              {!loading && !error && data && (
                <div className="table-responsive">
                  <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="dataTables_length" id="hr-table_length">
                          <label>
                            {t("Afficher")}{" "}
                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                              <option value={8}>8</option>
                              <option value={20}>20</option>
                              <option value={40}>40</option>
                              <option value={60}>60</option>
                              <option value={100}>100</option>
                            </select>{" "}
                            {t("entrées")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div id="hr-table_filter" className="dataTables_filter">
                          <label className="mr-2">
                            <input
                              type="search"
                              className="form-control"
                              placeholder={t("Recherche...")}
                              aria-controls="hr-table"
                              onChange={handleSearch}
                            />
                          </label>

                          <ExportCsv data={data} name={"List_jours_fériés"} />
                          <ExportPdf data={data} name={"List_jours_fériés"} columns={["libelle", "date", "nbre_jours", "type"]} />
                          <CopieContent />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer"
                          id="hr-table"
                          role="grid"
                          aria-describedby="hr-table_info"
                        >
                          <thead>
                            <tr>
                              <th className="border-bottom-0 w-5">#</th>
                              <th className="border-bottom-0 w-5">{t("Libelle")}</th>
                              <th className="border-bottom-0 w-5">{t("Date")}</th>
                              <th className="border-bottom-0 w-5">{t("Nb Jour")}</th>
                              <th className="border-bottom-0 w-5">{t("Type")}</th>
                              {/* <th className="border-bottom-0 w-5">Créé à</th> */}
                              <th className="border-bottom-0 w-5">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentData.map((el, idx) => (
                              <tr key={idx} role="row" className="odd">
                                <td>
                                  <Link to="#">{el.id}</Link>
                                </td>
                                <td>{el.libelle}</td>
                                <td>{el.date.split(" ")[0]}</td>
                                <td>{el.nbre_jours}</td>
                                <td>
                                  {el.type == "CNP" ? (
                                    <span className="badge badge-danger">{t("CNP")}</span>
                                  ) : (
                                    <span className="badge badge-success">{t("CP")}</span>
                                  )}
                                </td>
                                {/* <td className="text-center">{el.created_at}</td> */}

                                <td className="text-left">
                                  <Link to={"/admin/editholiday/" + el.id} className="action-btns" data-tip="editer">
                                    <i className="feather feather-edit text-info" />
                                  </Link>

                                  <Link to="#" className="action-btns" data-tip="supprimer" onClick={() => handleDeleteVaccin(el.id)}>
                                    <i className="feather feather-x text-danger" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                          {t("Affichage de 1 à")} {size} {t("sur")} {data.length} {t("entrées")}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header  border-0">
              <h4 className="card-title">{t("Aperçu jour férié")}</h4>
            </div>
            <div className="card-body">
              <div id="leavesoverview" className="mx-auto pt-2" />
              <div className="row pt-7 pb-5  mx-auto text-center">
                <div className="col-md-12 mx-auto d-block">
                  <div className="row">
                    <div className="col-md-12">
                      <PieApexHoliday />
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label badge-danger mr-2 my-auto" />
                        {t("chomé non payé")}
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-flex font-weight-semibold">
                        <span className="dot-label bg-success mr-2 my-auto" />
                        {t("chomé payé")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddHoliday></AddHoliday> */}
      <div className="modal fade" id="holidayModal" tabIndex={-1} role="dialog" aria-labelledby="holidayModalTitle" aria-hidden="true">
        <ToastContainer />
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {t("Ajouter jour férié")}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="form-group">
                  <label htmlFor="inputAddress">{t("Libelle")}</label>
                  <input type="text" className="form-control" onChange={(e) => setlibelle(e.target.value)} />
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">{t("Date")}</label>
                    <input type="date" className="form-control" onChange={(e) => setdate(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">{t("Nombre des jours")}</label>
                    <input type="number" className="form-control" onChange={(e) => setnbjour(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>
                      {t("Type")}<span className="text-danger">*</span>
                    </label>

                    <select value={type} onChange={(e) => settype(e.target.value)} className="selectpicker form-control">
                      <option value="CP">{t("chomé payé")}</option>
                      <option value="CNP">{t("Chômé non payé")}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefAnnule}>
                {t("Annuler")}
              </button>
              <button type="button" className="btn btn-primary" onClick={handelSave}>
                {t("Enregistrer")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Holiday;
