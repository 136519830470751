import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../Utils/SessionUtils";

function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
  const role  = localStorage.getItem('role_code')
  return (
    <Route
    {...rest}
    render={(props) => (

      <Layout>
        {isLogin() ? (
         
          role === "user" && !props.location.pathname.startsWith("/user") ? (
            <Redirect to="/PageNotFound" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/login" />
        )}
      </Layout>
    )}
  ></Route>
  
  );
}

export default PrivateRoute;
