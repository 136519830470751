import React, { useEffect, useState } from "react";
import { getFacturationClientByCode } from "../../../Services/Facturation/FacturationClientApi";
import { editSoldeEnteteByCode, getEnteteByClientCode } from "../../../Services/Facturation/FacturationEnteteApi";
import { addEncaissement, getEncaissementByFacture } from "../../../Services/Facturation/FacturationEncaissementApi";
import { ToastContainer, toast } from "react-toastify";
import { getEntrepriseByCode } from "../../../Services/Pointeuse/EntrepriseApi";
import { Prompt } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { t } from "i18next";
import { getYear } from "../../../Utils/DateUtils";
import moment from "moment";
import { checkMontanPaiement } from "../../../Utils/FactureUtils";
import { soustractSolde } from "../../../Utils/NumberUtils";
import swal from "sweetalert";
import { handleKeyPress, handleKeyPress_number } from "../../../Utils/InputUtils";
import { getbanques } from "../../../Services/banque";
import { getRStaxes } from "../../../Services/Facturation/FacturationTvaApi";

function AddEncaissement() {
  const history = useHistory();
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurCode, setFournisseurCode] = useState("");
  const [dataFacturation, setDataFacturation] = useState([]);
  const [factureCode, setfactureCode] = useState("");
  const [factureNum, setfactureNum] = useState("");
  const [banque, setbanque] = useState("");
  const [modePaiement, setmodePaiement] = useState("cheque");
  const [numPaiement, setnumPaiement] = useState("");
  const [referenceBanque, setreferenceBanque] = useState("");
  const [montant, setmontant] = useState("");
  const [dateReception, setdateReception] = useState("");
  const [dateVersement, setdateVersement] = useState("");
  const [dateEcheance, setdateEcheance] = useState("2021-01-01");
  const [exercice, setexercice] = useState("");
  const [commentaire, setcommentaire] = useState("");
  const [objectFacture] = useState(JSON.parse(localStorage.getItem("facture")));
  const [avance, setavance] = useState(false);
  const [list_rs, setList_rs] = useState([]);
  const [addrs, setaddrs] = useState(false);

  const [listRs, setlistRs] = useState([{
    id: "",
    taux: 0,
    libelle: '',
    montant: 0,
    montant_base:0,
  }])
  const [entet, setentet] = useState({
    societe_code: localStorage.getItem('code_generated'),
    client: '',
    code_facture: '',
    num_facture: '',
    exercice: '',
    commentaire: '',
    date_reception: moment(new Date()).format("YYYY-MM-DD"),
    montant: 0,
    solde: 0,
    montant_saisie: 0


  })
  const [listbanque, setlistbanques] = useState([])
  const [listEncaissemnt, setlistEncaissement] = useState([
    {
      banque: '',
      mode_paiement: '',
      numero_piece: '',
      reference_banque: '',
      montant: 0,
      date_echeance: '',
      status: '',
      b: 0,

    }
  ])
  useEffect(() => {
    getRStaxes().then((res) => {
      setList_rs(res.data.data);

    });
  }, []);

  useEffect(() => {
    getbanques().then((res) => {

      setlistbanques(res.data)
    });


  }, [])
  useEffect(() => {
    if (objectFacture) {
      getEnteteByClientCode(objectFacture.client_code).then((res) => {
        const result = res.data.filter((el) => {
          //condition problem
          return el.type == "facture" && el.solde <= el.net_a_payer;
        });

        const options = result.map((d) => ({
          value: d.code,
          label: d.facture_code,
          dateValue: d.date.split("-")[0],
          ttcValue: d.net_a_payer,
          numfactureValue: d.facture_code,
          client_code: d.client_code,
          nom_client: d.client_nom,
          net_a_payer: d.net_a_payer,
          solde: d.solde

        }));

        setDataFacturation(options);
        setFournisseurCode(objectFacture?.client_code);
        setentet(prev => ({
          ...prev,
          client: objectFacture.client_code,
          code_facture: objectFacture.code,
          num_facture: objectFacture.facture_code,
          exercice: getYear(objectFacture.date.split('T')[0]),
          montant: objectFacture.net_a_payer,
          solde: parseFloat(objectFacture.solde.toFixed(3)),
          montant_saisie: 0



        }))
      });
    }
  }, [objectFacture]);



  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setbanque(res.data.banque);
    });
  }, []);
  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      setDataFournisseur(res.data);
    });
  }, []);

  const handleChangeFournisseur = (code) => {
    getEnteteByClientCode(code).then((res) => {
      const result = res.data.filter((el) => {
        return el.type == "facture" && Number(el.solde) < Number(el.net_a_payer);
      });


      const options = result.map((d) => ({
        value: d.code,
        label: d.facture_code,
        dateValue: d.date.split("-")[0],
        ttcValue: d.net_a_payer,
        numfactureValue: d.facture_code,
        client_code: d.client_code,
        nom_client: d.client_nom,
        net_a_payer: d.net_a_payer,
        solde: d.solde
      }));
      setDataFacturation(options);
    });
  };


  const handlechangeentet = (name, value) => {

    if (name === 'code_facture') {
      if (value === 'avance') {
        setavance(true)
        setentet(prev => ({
          ...prev,
          num_facture: null,
          code_facture: null,
          exercice: getYear(new Date()),
          montant: 0,
          solde: 0,


        }))
        
      } else {
        const findfacture = dataFacturation.find(item => item.value === value);
        if (findfacture) {
          let totalEcaissement;
          getEncaissementByFacture(findfacture.value).then((res) => {
            console.log('en', res.data[0].total)
            totalEcaissement = res.data[0].total
            setentet(prev => ({
              ...prev,
              client: findfacture.client_code,
              num_facture: findfacture.numfactureValue,
              code_facture: value,
              exercice: getYear(findfacture.dateValue),
              montant: findfacture.net_a_payer,
              solde: findfacture.solde.toFixed(3)


            }))
          });

        }
      }

    }
    else {
      const data = { ...entet }
      data[name] = value
      setentet(data)

      if (name === 'client') {
        handleChangeFournisseur(value)
      }
    }

  }

  const Rschange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listRs];

    if (index === 0) {
      if (name === 'id') {
        // recalcul le montant 
        //set new rs 
        const findrs = list_rs.find(item => item.id === parseInt(value));
        if (findrs) {
          const m = entet.montant_saisie * (findrs.taux / 100)
          list[index] = {
            id: value,
            taux: findrs.taux,
            libelle: findrs.libelle,
            montant: m,
            montant_base: entet.montant_saisie
          }
        }
      }


    }
    setlistRs(list)
  }
  useEffect(() => {
    const m_saisi = listEncaissemnt.reduce((ac, val) => ac + parseFloat(val.montant), 0);
    setentet(prev => ({
      ...prev,
      montant_saisie: m_saisi
    }))
  }, [listEncaissemnt])
  useEffect(() => {
    // find rs 

    if (addrs) {

      const list = [...listRs]
      if (listRs[0].taux !== 0) {
        const montant_rs = parseFloat(entet.montant_saisie) * (listRs[0].taux / 100)
        list[0].montant = montant_rs;
        list[0].montant_base=entet.montant;
        setlistRs(list)
      }


    }

  }, [entet.montant_saisie])

  const handlechangligne = (e, index) => {
    const { name, value } = e.target;
    const list = [...listEncaissemnt];
    if (name === 'montant') {
      list[index][name] = value !== '' ? value : 0

    } else if (name === 'date_echeance') {
      /*if (new Date(value) <= new Date()) {
        toast.warning("la date d'echeance est incorrecte")
      } else {
        list[index][name] = value


      }*/
        list[index][name] = value
    }
    else {
      list[index][name] = value

    }
    if (name === 'mode_paiement') {

      if (value !== 'rs') {
        list[index].b = 1
      } else {
        list[index].b = 0

      }

    }
    setlistEncaissement(list)


  }
  const handleVerif = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const montant_a_payer = parseFloat(entet.montant) - parseFloat(entet.solde);
    if ((!avance && (parseFloat(entet.montant_saisie) > montant_a_payer))) {
      swal({
        title: t("Le montant saisi dépasse le montant restant à payer"),
        icon: "warning",
        buttons: {
          cancel: t("Annuler"),
        },
      }).then((ls) => {
        if (ls !== null) {
          handleSubmitEncaissement();
        }
      });
    } else {
      swal({
        title: t("Êtes-vous sûr ?"),
        text: t("Voulez-vous vraiment enregistrer cet encaissement ?"),
        icon: "warning",
        buttons: {
          cancel: t("Annuler"),
          confirm: {
            text: t("OK"),
          },
        },
      }).then((ls) => {
        if (ls !== null) {
          handleSubmitEncaissement();
        }
      });
    }
  };


  const handleSubmitEncaissement = () => {

    const newentet = { ...entet }
    const s = entet.montant_saisie + listRs[0].montant
    delete newentet.montant_saisie;
    delete newentet.solde;
    delete newentet.montant_saisie;
    const updatedInputList = listEncaissemnt.map(item => {
      const updatedItem = { ...item };
      updatedItem.montant = parseFloat(updatedItem.montant)
      return updatedItem;
    });
    const data = { dataenete: newentet, lignes: updatedInputList, solde: s ,listrs:listRs}
    addEncaissement(data).then((res) => {
      if(res.status===200){
        const test = localStorage.getItem('facture') ? true : false
        localStorage.removeItem('facture');
        if (test) {
          history.push("/admin/facturevente");
  
        } else {
          history.push("/admin/encaissement");
  
        }
      }
     

    });
  };
  const handleAddClick = () => {
    setlistEncaissement([
      ...listEncaissemnt,
      {
        banque: '',
        mode_paiement: '',
        numero_piece: '',
        reference_banque: '',
        montant: 0,
        date_reception: moment(new Date()).format("YYYY-MM-DD"),

        date_echeance: '',
        status: '',
        b: 0,
      }
    ]);
  }
  const handleRemoveClick = (index) => {


    const list = [...listEncaissemnt];
    list.splice(index, 1);
    setlistEncaissement(list);



  };
  const handleAddClickrs = () => {
    setlistRs([
      ...listRs,
      {
        id: "",
        taux: 0,
        libelle: '',
        montant: 0,
        montant_base: 0 ,
      }
    ]);
  }
  const handleRemoveClickrs = (index) => {
    const list = [...listRs];
    list.splice(index, 1);
    setlistRs(list);
  };
  const openrs = () => {
    if (!addrs) {



      setlistRs([{
        id: "",
        taux: 0,
        libelle: '',
        montant: 0,
        montant_base: 0 ,
      }])
    }
    setaddrs(!addrs)

  }
  return (
    <div className="col-xl-12 col-md-12 col-lg-12 mt-4">
      <div className="card">
        <div className="card-header  border-0">
          <h4 className="card-title">{t("Ajouter encaissement")}</h4>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div class="row">
              {/* <div class="col-sm-12 col-lg-6">
          <img style={{ marginTop: 130 }} src="/dist/img/encaissement/encaissement.svg"></img>
        </div> */}
              <div class="col-sm-12 col-lg-12">
                <form onSubmit={handleVerif}>
                  {/* <Prompt
                    message={(location, action) => {
                      console.log(action);
                      if (action === "POP") {
                        console.log("Backing up...");
                        localStorage.removeItem("facture");
                      }

                      return "Voulez vous vraiment continuer ?";
                    }}
                  />*/}
                  <div className="row">

                    <div className="col-2">
                      <div className="form-group">
                        <label>{t("Client")}</label>
                        {objectFacture?.client_code ? (
                          <>
                            {dataFournisseur && (
                              <select
                                className="form-control"
                                id="exampleFormControlSelect0"
                                value={entet.client}
                                disabled
                              >
                                <option>{t("Choisir Client")}</option>
                                {dataFournisseur.map((option) => (
                                  <option value={option.code}>
                                    {option.nom} {option.prenom}
                                  </option>
                                ))}
                              </select>
                            )}
                          </>
                        ) : (
                          <>
                            {dataFournisseur && (
                              <select className="form-control" name="client" onChange={(e) => handlechangeentet('client', e.target.value)}
                              >
                                <option>{t("Choisir Client")}</option>
                                {dataFournisseur.map((option) => (
                                  <option value={option.code}>
                                    {option.nom} {option.prenom}
                                  </option>
                                ))}
                              </select>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="form-group">
                        <label>Facture :  </label>{avance && t('  Avance')}

                        {dataFacturation && (
                          <select className="form-control" value={entet.code_facture} onChange={(e) => handlechangeentet('code_facture', e.target.value)}>

                            <option value={"default"}>{t("Choisir Facture")}</option>
                            <option value={"avance"}>{t("avance")}</option>

                            {dataFacturation.map((option) => (
                              <option value={option.value}>{option.label}</option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>


                    <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">{t("Date d'effet")}</label>
                        <input type="date" className="form-control" value={entet.date_reception} required onChange={(e) => handlechangeentet('date_reception', e.target.value)} />
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="form-group">
                        <strong className="ml-3" >{t("Montant")} : {entet.montant}</strong>

                      </div>


                    </div>
                    <div className="mt-5">
                      <div className="form-group">
                        <strong className="ml-3" >{t("Solde")} : {entet.solde}</strong>

                      </div>


                    </div>
                    <div className="mt-5">
                      <div className="form-group">
                        <strong className="ml-3" >{t("Montant saisie")} : {entet.montant_saisie}</strong>

                      </div>

                    </div>
                    <div className="mt-5">
                      <div className="form-group">
                        <strong className="ml-3" >{t("Reste a payer")} : {parseFloat(entet.montant - entet.solde - entet.montant_saisie).toFixed(3)}</strong>

                      </div>

                    </div>
                  </div>
                  {(avance || (parseFloat(entet.montant) !== parseFloat(entet.solde))) &&
                    <>
                      <div className="row">

                        <div className="col-2">
                          <div className="form-group">
                            <label>{t("Mode de paiement")}</label>

                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label>{t("Banque")}</label>

                          </div>
                        </div>


                        <div className="col-2">
                          <div className="form-group">
                            <label>{t("Référence banque")}</label>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">{t("Numéro paiement")}</label>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">{t("Montant")}</label>

                          </div>
                        </div>
                        <div className="col-2 row">
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">{t("Date d'échéance")}</label>
                          </div>
                          <div className=" mt-6 mr-3">

                          </div>

                        </div>


                      </div>
                      {listEncaissemnt.map((item, i) => (
                        <div className="row">

                          <div className="col-2">
                            <div className="form-group">
                              <select className="form-control" value={item.mode_paiement} required name='mode_paiement' onChange={(e) => handlechangligne(e, i)}>
                                <option value="">{t("Mode de paiement")}</option>

                                <option value="cheque">{t("Chèque")}</option>
                                <option value="virement">{t("Virement")}</option>
                                <option value="traite">{t("Traite")}</option>
                                <option value="espece">{t("Espèce")}</option>
                                <option value="rs">{t("RS")}</option>
                                <option value="autre">{t("Autre")}</option>
                              </select>
                            </div>
                          </div>
                          {item.b === 1 ? <div className="col-2">
                            <div className="form-group">
                              <select className="form-control" name='banque' value={item.banque} onChange={(e) => handlechangligne(e, i)}>
                                <option value={''} > choisir</option>
                                {listbanque.map((item, index) => (

                                  <option key={item.id} value={item.id}>{item.banque}-{item.num_compte}</option>
                                ))}
                                <option value={'tiroire'}>Tiroire</option>
                              </select>
                            </div>
                          </div> :
                            <div className="col-2">
                              <div className="form-group">
                                <select className="form-control" name='banque' readOnly >
                                </select>
                              </div>
                            </div>}

                          <div className="col-2">
                            <div className="form-group">
                              <input type="text" className="form-control" readOnly={item.banque === 'tiroire' || item.mode_paiement === 'rs'} required name='reference_banque' value={item.reference_banque} onChange={(e) => handlechangligne(e, i)} />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <input type="text" className="form-control" required name='numero_piece' value={item.numero_piece} onChange={(e) => handlechangligne(e, i)} />
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                onKeyPress={handleKeyPress_number}
                                required
                                placeholder="ex : 100.100"
                                value={item.montant}
                                name='montant' onChange={(e) => handlechangligne(e, i)}
                              />
                            </div>
                          </div>
                          <div className="col-2 row">
                            <div className="form-group">
                              <input type="date" className="form-control" required name='date_echeance' value={item.date_echeance} onChange={(e) => handlechangligne(e, i)} />
                            </div>
                            <div className=" mt-3 mr-4">
                              {listEncaissemnt.length - 1 === i && (
                                <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                                  <i className="fa fa-plus" />
                                </Link>
                              )}
                              {listEncaissemnt.length !== 1 && (
                                <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                                  <i className="fa fa-minus" />
                                </Link>
                              )}
                            </div>

                          </div>


                        </div>
                      ))}
                        <div className="row">
                  <label className="col-1">RS</label>
                  <div className=" col-1">

                    <input className="form-control" type='checkbox' onClick={() => { openrs() }} />
                  </div>
                  {addrs &&
                    <><div className="col-2">
                      <label>Montant</label>
                    </div><div className="col-2">
                        <label>Taux</label>
                      </div><div className="col-2">
                        <label>Montant de base</label>
                      </div></>
                  }
                </div>

                {addrs &&
                  listRs.map((item, i) => (
                    <div className="row ">

                      <div className="col-2">
                        <select
                          name="id"
                          className="form-control"
                          id="exampleFormControlSelect0"
                          onChange={(e) => Rschange(e, i)}
                          value={item.id}
                          required
                        >
                          <option>{t("Choisir RS")}</option>
                          {list_rs?.map((option) => (
                            <option value={option.id}>
                              {option.libelle}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-2">
                        <input className="form-control" readOnly type='text' value={item.montant} />
                      </div>
                      <div className="col-2">
                      <input className="form-control" readOnly type='text' value={item.taux} />
                      </div>
                      <div className="col-2">
                      <input className="form-control" readOnly type='text' value={item.montant_base} />

                      </div>
                      <div className=" col-1">

                        {listRs.length - 1 === i && (
                          <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClickrs}>
                            <i className="fa fa-plus" />
                          </Link>
                        )}
                        {listRs.length !== 1 && (
                          <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClickrs(i)}>
                            <i className="fa fa-minus" />
                          </Link>
                        )}
                      </div>
                    </div>
                  ))
                }

                      <div className="row">

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">{t("Note")}</label>
                            <input type="text" className="form-control" onChange={(e) => handlechangeentet('commentaire', e.target.value)} />
                          </div>
                        </div>
                      </div>
                      {/*  <div className="row">
<div className="col-4">
  <div className="form-group">
    <label htmlFor="exampleInputPassword1">{t("Date versement")}</label>
    <input type="date" className="form-control" required onChange={(e) => setdateVersement(e.target.value)} />
  </div>
</div>

</div>*/}

                      {(avance || entet.code_facture) && (
                        <button type='submit' className="btn btn-primary float-right" >
                          {t("Enregistrer")}
                        </button>
                      )}
                    </>
                  }




                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEncaissement;
/*const getAllFactures = () => {
   getEnteteByClientCode(objectFacture.client_code).then((res) => {
     const result = res.data.filter((el) => {
       //condition problem
       return el.type == "facture";
     });

     const options = result.map((d) => ({
       value: d.code,
       label: d.date.split("-")[0] + "_" + d.facture_code + "_" + d.montant_total_ttc,
       dateValue: d.date.split("-")[0],
       ttcValue: d.montant_total_ttc,
       numfactureValue: d.facture_code,
     }));
     setDataFacturation(options);
     setFournisseurCode(objectFacture?.client_code);
   });
 };*/