import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { t } from "i18next";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "column",
    marginTop: 6,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "left",
  },
});

const InvoiceThankYouMsg = ({ invoice }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{t("Note")} : {invoice.note}</Text>
  </View>
);

export default InvoiceThankYouMsg;
