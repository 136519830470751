import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url_v2 = process.env.REACT_APP_API_BASE_URL_V2;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export async function getTicketMediaCommentByCommentId(commentId) {
  try {
    const response = await axios.get(api_url_v2 + "ticketcommentmedia/" + commentId, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function addTicketMediaComment(ticketId, files, commentId) {
  console.log(files);
  const frmData = new FormData();
  for (let i = 0; i < files.length; i++) {
    console.log(files[i].file);
    //newArr.push(files[i]);
    frmData.append("ticketId", ticketId);
    frmData.append("commentId", commentId);
    frmData.append("file", files[i].file);
    frmData.append("userId", getSession("id"));
  }

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getSession("token")}`,
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url_v2 + "ticketcommentmedia", frmData, options);
    toast.success(t("Fichier ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function addTicketMediaCommentWithouFilePont(ticketId, files, commentId) {
  console.log(files);
  const frmData = new FormData();
  for (let i = 0; i < files.length; i++) {
    frmData.append("ticketId", ticketId);
    frmData.append("commentId", commentId);
    frmData.append("file", files[i]);
    frmData.append("userId", getSession("id"));
  }

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getSession("token")}`,
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url_v2 + "ticketcommentmedia", frmData, options);
    toast.success(t("Fichier ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteTicketCommentMediaById(id) {
  try {
    const response = await axios.delete(api_url_v2 + "ticketcommentmedia/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
