/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import { SRLWrapper } from "simple-react-lightbox";

export class ImageComponent extends Component {
  state = { isOpen: false };

  /* This is a function that is called when the user clicks on the image. It is used to open the
lightbox. */
  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <SRLWrapper>
        <img
          style={{ minWidth: 25, marginRight: 20 }}
          className={this.props.atr ? this.props.atr : "avatar avatar-md brround mr-3"}
          src={
            this.props.picture ? localStorage.getItem("baseUrl") + this.props.picture : "assets/images/users/avatar.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "assets/images/users/avatar.png";
          }}
          loading="lazy"
          onClick={this.handleShowDialog}
          alt="no image"
        />
      </SRLWrapper>
      // <>
      //   <img
      //     className={this.props.atr}
      //     src={
      //       this.props.picture == null || this.props.picture == ""
      //         ? "assets/images/users/avatar.png"
      //         : localStorage.getItem("baseUrl") + this.props.picture
      //     }
      //     onError={(e) => {
      //       e.target.onerror = null;
      //       e.target.src = "assets/images/users/avatar.png";
      //     }}
      //     loading="lazy"
      //     onClick={this.handleShowDialog}
      //     alt="no image"
      //   />
      //   {this.state.isOpen && (
      //     <Modal fullscreen={"xl-down"} show={true}>
      //       <Modal.Body>
      //         <img
      //           className="image"
      //           src={
      //             this.props.picture == null || this.props.picture == ""
      //               ? "assets/images/users/avatar.png"
      //               : localStorage.getItem("baseUrl") + this.props.picture
      //           }
      //           onError={(e) => {
      //             e.target.onerror = null;
      //             e.target.src = "assets/images/users/avatar.png";
      //           }}
      //           loading="lazy"
      //           onClick={this.handleShowDialog}
      //           alt="no image"
      //         />
      //       </Modal.Body>
      //     </Modal>
      //   )}
      // </>
    );
  }
}

export default ImageComponent;
