import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get a document user by user id
 * @param userid - The userid of the user you want to get.
 * @returns The response is an array of objects.
 */
export async function getDocumentUserByUserId(userid) {
  try {
    const response = await axios.get(api_url + "documentuser/userid/" + userid, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a document to a user
 * @param id - The id of the user
 * @param docId - The id of the document type
 * @param file - The file that you want to upload.
 * @returns The response from the server.
 */
export async function addDocumentUser(id, docId, file) {
  const frmData = new FormData();
  frmData.append("code_generated", getSession("code_generated"));
  frmData.append("user_id", id);
  frmData.append("document_type_id", docId);
  frmData.append("file", file);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "documentuser", frmData, options);
    toast.success(t("Fichier ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * `deleteDocumentUserById` deletes a document user by id
 * @param id - The id of the document user to delete.
 * @returns The response is an object that contains the status code and the response data.
 */
export async function deleteDocumentUserById(id) {
  try {
    const response = await axios.delete(api_url + "documentuser/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
