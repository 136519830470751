import React, { useEffect, useState } from 'react'

export default function Tabletva({ data }) {
    const [newlisttva, setNewlisttva] = useState([]);

    useEffect(() => {
        const updatedList = [];

        data.forEach(element => {
            const findIndex = updatedList.findIndex(item => item.tauxtva === element.tva);
            if (findIndex !== -1) {
                updatedList[findIndex].total_montant += parseFloat(element.prix);
                updatedList[findIndex].count += 1;
            } else {
                updatedList.push({
                    tauxtva: element.tva,
                    total_montant: parseFloat(element.prix),
                    count: 1
                });
            }
        });

        setNewlisttva(updatedList);
    }, [data]);

    return (
        <div style={{width:'50%'}}>
            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th> TVA </th>
                        <th>base</th>
                        <th>Montant</th>

                    </tr>
                </thead>
                <tbody>
                    {newlisttva.map((item, index) => (
                        <tr key={index}>
                            <td>{item.count}</td>
                            <td>{item.tauxtva}%</td>
                            <td>{item.total_montant.toFixed(3)}</td>
                            <td>{((item.total_montant.toFixed(3))*(item.tauxtva/100)).toFixed(3)}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
