import React from "react";
import { Redirect, Route } from "react-router";
import { isAdmin, isLogin } from "../Utils/SessionUtils";

function PublicRoute({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => <Layout>{isLogin() ? <Redirect to="/" /> : <Component {...props}></Component>}</Layout>}
    ></Route>
  );
}

export default PublicRoute;
