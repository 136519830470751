import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all the conges valide today
 * @returns The response is an array of conges.
 */
export async function getAllValideCongeToday() {
  try {
    const response = await axios.get(api_url + "conges/congetoday/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets all the conges by code.
 * @returns The response is an array of conges.
 */
export async function getAllCongeByCode() {
  try {
    const response = await axios.get(api_url + "conges/codeconge/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the valide conges by code
 * @returns The response is an array of conges.
 */
export async function getAllValideCongeByCode(code_exercice) {
  try {
    const response = await axios.get(api_url + "conges/allvalide/"+code_exercice +'/'+ getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all demande conge by code
 * @returns The response is an array of objects.
 */
export async function getAllDemandeCongeByCode(code_exercice) {
  try {
    const response = await axios.get(api_url + "conges/alldemande/" +code_exercice +'/'+  getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all non valide conges by code
 * @returns The response is an array of objects.
 */
export async function getAllNonValideCongeByCode(code_exercice) {
  try {
    const response = await axios.get(api_url + "conges/allnonvalide/"  +code_exercice +'/'+  getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Accept a leave request
 * @param id - id of the conge
 * @param nbr_jour - number of days of the leave
 * @param valide - true or false
 * @returns The response from the server.
 */
export async function acceptConge(id,affect_solde,userid, nbr_jour, valide) {
  const credentiel = {
    affect_solde:affect_solde,
    userid:userid,
    code_societe:localStorage.getItem('code_generated'),
    nbr_jour: nbr_jour,
    valide: valide,
    type: "conge",
    id: id,
  };
  try {
    const response = await axios.put(api_url + "conges/demande_conge", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Send an email to a user with a link to confirm the creation of a new conge
 * @param receiver - the email address of the receiver
 * @param nom - the name of the person who sent the email
 * @param prenom - the first name of the user
 * @param nbjour - number of days
 * @param motif - the reason of the leave
 * @param logo - the logo of the company
 * @param id - id of the conge
 * @param type - type of conge (ex: conge, maladie, etc.)
 * @param date - date of the conge
 * @param status - status of the conge
 * @returns The response is an object that contains the response from the server.
 */
export async function sentCongeEmail(receiver, nom, prenom, nbjour, motif, logo, id, type, date, status) {
  const credentiel = {
    receiver: receiver,
    nom: nom,
    prenom: prenom,
    nbjour: nbjour,
    motif: motif,
    img: logo,
    id: id,
    type: type,
    date: date,
    status: status,
  };
  try {
    const response = await axios.post(api_url + "sendconge", credentiel, config);
    toast.success(t("Titre congé envoyé par email"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It gets the conge of a user by his id.
 * @param id - The id of the user.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "status": 200,
 *   "data": {
 *     "id": 1,
 *     "iduser": 1,
 *     "start": "2020-04-01T00:00:00.000Z",
 *     "end": "2020-04-10T00:00
 */
export async function getCongeById(id) {
  try {
    const response = await axios.get(api_url + "conges/iduser/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all the conges for a user
 * @param userId - The user's ID.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": [
 *     {
 *       "id": "1",
 *       "userCode": "1",
 *       "dateDebut": "2020-01-01",
 *       "dateFin": "2020-01-02",
 *       "nbJours": 1,
 */
export async function getCongeByUserCodeId(userId) {
  try {
    const response = await axios.get(api_url + "conges/usercodeconge/" + userId+'/'+ localStorage.getItem("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It returns the number of days of conge for a user.
 * @param userId - The userId of the user you want to get the count of conges for.
 * @returns The response is an object with a status code and a data property. The data property is an
 * object with a count property.
 */
export async function getCountCongeByUserCodeId(userId) {
  try {
    const response = await axios.get(api_url + "conges/countdays/" + userId, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Edit a conge type
 * @param motif - the new value of the field
 * @param id - id of the conge type to be edited
 * @returns The response is an object with the following properties:
 */
export async function editCongeType(motif, id) {
  const credentiel = {
    motif: motif,
    id: id,
  };

  try {
    const response = await axios.patch(api_url + "conges/type", credentiel, config);
    console.log(response);
    toast.success(t("mise à jour"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It adds a new conge to the database.
 * @param userId - The id of the user who is requesting a leave
 * @param nbJour - number of days
 * @param date - Date of the leave
 * @param motif - The reason for the leave.
 * @returns The response is an object with the following structure:
 */
export async function ajouterConge(userId, nbJour, date, motif,code_motif,affect_solde) {
  const credentiel = {
    code_generated: getSession("code_generated"),
    user_code_id: userId,
    nbr_jour: nbJour,
    type: "demande",
    valide: "0",
    date: date,
    motif: motif,
    code_motif:code_motif,
    affect_solde:affect_solde
  };
  try {
    const response = await axios.post(api_url + "conges/addConge", credentiel, config);
    console.log(response);
    toast.success(t("Congé ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Send a notification to a specific user
 * @param text - The message you want to send.
 * @param receiver - The receiver of the notification.
 * @returns The response from the server.
 */
export async function senFcmConge(text, receiver) {
  const notification = {
    data: {
      title: "Congé",
      message: text,
    },
    to: "/topics/" + localStorage.getItem("code_generated") + "user" + receiver,
  };
  const fcm_server_key =
    "AAAAyFwzY3I:APA91bGIPThuQusFacH13cip7zdnDNY81mhV1xJV5pQXjqoHaIUwcibidgFahbxnjAxWODcIR0pew1a3tYlVUQNPLpx7nIanisZieGr0ZaMcwSqDvmcvyr9YjJK3yfoEbwu82sWDhL7E";

  const g = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${fcm_server_key}`,
    },
  };
  try {
    const response = await axios.post("https://fcm.googleapis.com/fcm/send", notification, g);
   
    toast.success(t("Notification envoyée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error sending notification"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It updates a decharge.
 * @param id - the id of the decharge to update
 * @param decharge - the decharge to be added
 * @returns The response from the server.
 */
export async function updateDechargeById(id, decharge) {
  const frmData = new FormData();
  frmData.append("id", id);
  frmData.append("decharge", decharge);
  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.patch(api_url + "conges/updatedecharge", frmData, options);
    toast.success(t("décharge ajoutée"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a decharge by id.
 * @param id - id of the decharge
 * @returns The response is an object that contains the data, a status code, a status message, and a
 * header.
 */
export async function deleteDechargeById(id) {
  const credentiel = {
    id: id,
  };
  try {
    const response = await axios.patch(api_url + "conges/deletedecharge", credentiel, config);
    console.log(response);
    toast.success(t("mise à jour"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}


/**
 * get solde conge 
 */
export async function getsoldecongeuser(exercice,usercode) {
 
  try {
    const response = await axios.get(api_url + `conges/getsoldecongeuser/${usercode}/${exercice}`, config);
   
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**
 * post solde conge 
 */
export async function updatesoldecongeuser(data) {
 
  try {
    const response = await axios.post(api_url + `conges/updatesoldecongeuser`,data, config);
   
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}
/*************************************type conge  **********************/
/**
 * get type conge 
 */
export async function getTypeCongeByCode() {
 
  try {
    const response = await axios.get(api_url + `conges/getTypeCongeByCode/${localStorage.getItem('code_generated')}`, config);
   
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**
 * post type conge 
 */
export async function addTypeConge(data) {
 
  try {
    const response = await axios.post(api_url + `conges/addTypeConge`,data, config);
   
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**
 * put type conge 
 */
export async function updateTypeConge(data) {
 
  try {
    const response = await axios.put(api_url + `conges/updateTypeConge`,data, config);
   
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**
 * put type conge 
 */
export async function getsouche_Type_conge() {
 
  try {
    const response = await axios.get(api_url + `conges/getsouche_Type_conge/${localStorage.getItem('code_generated')}`, config);
   
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}