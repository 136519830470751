import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It adds a paiement to the database.
 * @param obj - The object that will be sent to the server.
 * @returns The response from the server.
 */
export async function addPaiement(obj) {
  try {
    const response = await axios.post(api_url + "facturation/paiement", obj, config);
    toast.success(t("paiement ajouter"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It gets the payment information for the code generated.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "",
 *   "date": "",
 *   "montant": "",
 *   "mode": "",
 *   "numero": "",
 *   "reference": "",
 *   "statut": ""
 * }
 * ```
 */
export async function getPaiementByCode() {
  try {
    const response = await axios.get(api_url + "facturation/paiement/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets a paiement by id.
 * @param id - The id of the paiement you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": 1,
 *     "date": "2020-04-01T00:00:00.000Z",
 *     "montant": "1000.00",
 *     "facture": {
 *       "id": 1,
 *       "date":
 */
export async function getPaiementById(id) {
  try {
    const response = await axios.get(api_url + "facturation/paiement/id/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It deletes a paiement by id.
 * @param id - The id of the paiement to delete.
 * @returns The response is an object that contains the data, a status code, and a status message.
 */
export async function deletePaiementById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/paiement/" + id, config);
    console.log(response);
    toast.success("paiement supprimé");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error);
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It edits a payment.
 * @param obj - Object
 * @returns The response is an object that contains the data that was returned by the server.
 */
export async function editerPaiementById(obj) {
  const credentiel = {
    banque: obj.banque,
    mode_paiement: obj.mode_paiement,
    numero_paiement: obj.numero_paiement,
    reference_banque: obj.reference_banque,
    montant: obj.montant,
    rs: obj.rs,
    date_reception: obj.date_reception,
    date_echeance: obj.date_echeance,
    fournisseur: obj.client_code,
    num_piece: obj.num_piece,
    exercice: obj.exercice,
    commentaire: obj.commentaire,
    emplacement: obj.emplacement,
    status: obj.status,
    compte: obj.compte,
    sous_compte: obj.sous_compte,
    id: obj.id,
  };
  console.log(credentiel);
  try {
    const response = await axios.put(api_url + "facturation/paiement/", credentiel, config);
    console.log(response);
    toast.success(t("paiement modifié"));
    console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}
