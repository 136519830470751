import React, { useEffect, useState } from "react";
import { getClientByCodeId, getFacturationClientByCode } from "../../../Services/Facturation/FacturationClientApi";
import { getEncaissementByClientCode } from "../../../Services/Facturation/FacturationEncaissementApi";
import { getEnteteByClientCode } from "../../../Services/Facturation/FacturationEnteteApi";
import ClientFacture from "./ClientFacture";
import ClientEncaissement from "./ClientEncaissement";
import { sumMontant, sumMontantHT, sumMontantTTC, sumMontantTVA } from "../../../Utils/ObjectOperation";
import { convertToDigit, soustractMontant } from "../../../Utils/NumberUtils";
import ClientChart from "./ClientChart";
import ClientChartEncaissement from "./ClientChart copy";
import { Link } from "react-router-dom";
import ButtonHeaderComponent from "../../Styles/ButtonHeaderComponent";
import SpotsSparklineFacture from "../../Charts/CustumSparkline/SpotsSparklineFacture";
import SpotsSparklineEncaissement from "../../Charts/CustumSparkline/SpotsSparklineEncaissement";
import moment from "moment";
import { success_message, error_message } from "../../../Components/alerte/AlerteMessage";
import { t } from "i18next";
import ListReunionClient from "../../ReunionCLient/ListReunionClient";
import {  useHistory } from "react-router-dom";
import { deleteById, geltclientreunion, getListReunion } from "../../../Services/Pointeuse/Reunionclient";
import CustomSyncLoader from "../../Others/CustomSyncLoader";
import ErrorCustum from "../../Others/ErrorCustum";
import ExportCsv from "../../Exports/ExportCsv";
import ExportPdf from "../../Exports/ExportPdf";
import CopieContent from "../../Exports/CopieContent";
import ImageComponent from "../../Styles/ImageComponent";
import { convertMinutesToHous } from "../../../Utils/helper";
import { filterTasksByWord } from "../../../Utils/SortObject";
import swal from "sweetalert";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import Listsatisfactionclient from "./Listsatisfactionclient";
import Listsupportclient from "./Listsupportclient";
import ViewTaches from "../../Tache/ViewTaches";
import ListTasks from "../../Tasks/ListTasks";

// import moment from "moment";
function ClientActivity(props) {
  const [user, setUser] = useState(null);
  const [factures, setFactures] = useState([]);
  const [encaissements, setEncaissements] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allInitialData, setallInitialData] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentData, setcurrentData] = useState([]);
  const [error, setError] = useState(null);
  const [users, setusers] = useState([]);
  const [size, setsize] = useState(14);
  const [isOpened, setIsOpened] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getEnteteByClientCode(props.match.params.id).then((res) => {
      setFactures(res.data);

      const newFiltredList = res.data.filter((el) => {
        return el.type === "facture";
      });
      let list = []
      newFiltredList.forEach(element => {
        const finditem = list.find(item=>item.code === element.code); 
        if(!finditem){
          list.push(element)
        }
      })
      setFactures(list);
    });
    getEncaissementByClientCode(props.match.params.id).then((res) => {
      setEncaissements(res.data);
    //  console.log('object',res.data)
    });
    getClientByCodeId(props.match.params.id).then((res) => {
      setUser(res.data);
    });
  }, []);

 
  const getData = () => {
    setLoading(true);
    geltclientreunion(props.match.params.id)
      .then((res) => {
        setdata(res.data);
        console.log('data',data)
        setallInitialData(res.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
   
    getData()
  }, []);
  const handleSelectStaus = (e) => {
    // switch (e.target.value) {
    //   case "1":
    //     setFactures(allInitialData.filter((el) => !checkMontanPaiement(el?.montant_encaissement)));
    //     break;
    //   case "2":
    //     setFactures(allInitialData.filter((el) => checkMontanPaiement(el?.montant_encaissement) == el.net_a_payer));
    //     break;
    //   case "3":
    //     setFactures(
    //       allInitialData.filter(
    //         (el) => checkMontanPaiement(el?.montant_encaissement) < el.net_a_payer && checkMontanPaiement(el?.montant_encaissement) > 0
    //       )
    //     );
    //     break;
    //   default:
    //     setdata(allInitialData);
    // }
  };

  const onChangeDateFacture = () => {

    const filteredDate = factures.filter((listed) => {
      const momentDate = moment(listed.date);
      if (startDate > endDate) {
        error_message(t("Attention La date début doit être inférieur à la date fin !!"))
      } else {
        return (
          momentDate.isSameOrAfter(moment(startDate)) &&
          momentDate.isSameOrBefore(moment(endDate))
        )
      };
    });
    setFactures(filteredDate);
  };
  const handleChangeSize = (e) => {
    setsize(e.target.value);
    getData();
  };
  const onChangeDateEncaissememnt = () => {

    const filteredDate = encaissements.filter((listed) => {
      const momentDate = moment(listed.date);
      if (startDate > endDate) {
        error_message("Attention La date début doit être inférieur à la date fin !!")
      } else {
        return (
          momentDate.isSameOrAfter(moment(startDate)) &&
          momentDate.isSameOrBefore(moment(endDate))
        )
      };
    });
    setEncaissements(filteredDate);
  };
  const handleSearch = (e) => {
    const dataSearch = filterTasksByWord(allInitialData, e.target.value);
    setdata(dataSearch);
  };
  const navtoReunion= (el) => {
    const roleuser = localStorage.getItem('id')
    if (roleuser !== 'admin') {
      const codeuser = localStorage.getItem('id')
      if (codeuser !== el.user_id) {
        toast.warning('vous ne pouvez pas accéder a cette tache')
      } else {
        history.push("/user/updatereunion/" + el.id);

      }
    } else {
      history.push("/user/updatereunion/" + el.id);

    }
  }
  const handleDelete = (id) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          getData();
        });
      }
    });
  };
  /**
   * The function takes in a page of items and sets the currentData state to that page of items
   * @param pageOfItems - an array of items on the current page.
   */
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">
            <span className="font-weight-normal text-muted mr-2">{t("Client")} #{props.match.params.id}</span> {t("Activités Client")}
          </h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addclient" className="btn btn-primary " data-toggle="modal" data-target="#newprojectmodal">
                <i className="feather feather-plus fs-15 my-auto mr-2" />
                {t("Ajouter client")}
              </Link>
              {/* <ButtonHeaderComponent /> */}
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <svg
                class="card-custom-icon text-danger icon-dropshadow-danger"
                x="1008"
                y="1248"
                viewBox="0 0 24 24"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M17.65,6.35 C16.2,4.9 14.21,4 12,4 C7.58,4 4.01,7.58 4.01,12 C4.01,16.42 7.58,20 12,20 C15.73,20 18.84,17.45 19.73,14 L17.65,14 C16.83,16.33 14.61,18 12,18 C8.69,18 6,15.31 6,12 C6,8.69 8.69,6 12,6 C13.66,6 15.14,6.69 16.22,7.78 L13,11 L20,11 L20,4 L17.65,6.35 Z"></path>
              </svg>
              <p class=" mb-1 ">{t("Impayé")}</p>
              <h2 className="mb-1 font-weight-bold">{soustractMontant(sumMontantTTC(factures), sumMontant(encaissements))}</h2>
              {/* <span className="mb-1 text-muted">
                <span className="text-danger">
                  <i className="fa fa-caret-down  mr-1" /> 100
                </span>{" "}
                than last month
              </span> */}
              <div className="progress progress-sm mt-3 bg-success-transparent">
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={{ width: "100%" }} />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">{t("Client détail")}</div>
            </div>
            <div className="card-body pt-2 pl-3 pr-3">
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <span className="w-50">{t("Nom et prénom")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">
                          {user?.nom}
                          {user?.prenom}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("Email")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.email}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("Mobile")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.mobile}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("Zone")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.zone}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("Région")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.region}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("gouvernorat")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.gouvernorat}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("adresse facturation")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.adresse_facturation}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("matricule fiscale")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="font-weight-semibold">{user?.matricule_fiscale}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="w-50">{t("Statut")}</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span className="badge badge-primary">{user?.statut}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="p-3">
                <div className="d-flex align-items-end justify-content-between">
                  <h6 className="fs-12">Project Status</h6>
                  <h6 className="fs-12">80%</h6>
                </div>
                <div className="progress h-2">
                  <div className="progress-bar bg-success w-80" />
                </div>
              </div> */}
            </div>
          </div>

        </div>
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-xl-6 col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <p className="mb-1">{t("Revenu total")} ({factures.length})</p>
                      <h2 className="mb-0 font-weight-semibold">{sumMontantTTC(factures)}</h2>
                    </div>
                    <div className="col">
                      <SpotsSparklineFacture factures={factures} />

                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <p className="mb-1">{t("Total des dépenses")} ({encaissements.length})</p>
                      <h2 className="mb-0 font-weight-semibold">{sumMontant(encaissements)}</h2>
                    </div>
                    <div className="col">
                      <SpotsSparklineEncaissement encaissement={encaissements} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="tab-menu-heading hremp-tabs p-0 ">
            <div className="tabs-menu1">
              {/* Tabs */}
              <ul className="nav panel-tabs">
                {/* <li className="ml-4">
                  <a href="#tab5" className="active" data-toggle="tab">
                    facture
                  </a>
                </li> */}
                <li>
                  <a href="#tab6" className="active" data-toggle="tab">
                    {t("Facture")}
                  </a>
                </li>
                <li>
                  <a href="#tab7" data-toggle="tab">
                    {t("Encaissement")}
                  </a>
                </li>
                <li>
                  <a href="#tab8" data-toggle="tab">
                    {t("Reunion")}
                  </a>
                </li>
                <li>
                  <a href="#tab9" data-toggle="tab">
                    {t("satisfaction")}
                  </a>
                </li>
                <li>
                  <a href="#tab10" data-toggle="tab">
                    {t("support")}
                  </a>
                </li>
                <li>
                  <a href="#tab11" data-toggle="tab">
                    {t("Demandes client")}
                  </a>
                </li>
                <li>
                  <a href="#tab12" data-toggle="tab">
                    {t("Taches")}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
            <div className="tab-content">
              {/* <div className="tab-pane active" id="tab5">
                <div className="card-body">
                  <ClientChart encaissement={encaissements} factures={factures} />
                  <SpotsSparklineEncaissement encaissement={encaissements} />
                </div>
              </div> */}

              <div className="tab-pane active" id="tab6">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row d-flex justify-content-center">
                          <div className="col-xl-8 col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  {/* <label className="form-label">Date:</label> */}
                                  <label className="form-label">{t("Date Debut")}</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="feather feather-calendar" />
                                      </div>
                                    </div>
                                    <input className="form-control floating" type="date"
                                      value={startDate}
                                      onChange={(e) => setStartDate(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label">{t("Date Fin")}</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="feather feather-calendar" />
                                      </div>
                                    </div>
                                    <input className="form-control floating" type="date"
                                      onChange={(e) => setEndDate(e.target.value)}
                                      value={endDate}
                                    />
                                    <div className="input-group-append">
                                      <button
                                        onClick={() => onChangeDateFacture()}

                                        className="btn btn-success input-group-text"
                                        id="basic-addon2"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={16}
                                          height={16}
                                          fill="currentColor"
                                          className="bi bi-search"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Année</option>
                          <option value={1}>{new Date().getFullYear()}</option>
                          <option value={2}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div> */}
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label">{t("Choisir Statut")}:</label>

                                  <select className="form-control custom-select select2"
                                  //  onChange={handleSelectStaus}
                                  >
                                    <option value={0}>{t("Tous")}</option>
                                    <option value={1}>{t("En cours")}</option>
                                    <option value={2}>{t("Payée")}</option>
                                    <option value={3}>{t("Partiel")}</option>
                                    <option value={4}>{t("échu")}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* <ClientFacture factures={factures}></ClientFacture> */}
                  <div className="table-responsive">
                    {/* <Link to="#" className="btn btn-primary btn-tableview">
                      Add Invoice
                    </Link> */}
                    <table className="table  table-vcenter text-nowrap table-bordered border-bottom border-top" id="invoice-tables">
                      <thead>
                        <tr>
                          <th>{t("N° Facture")}</th>
                          <th>
                            {t("Montant HT")} <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontantHT(factures)}</span>
                          </th>
                          <th>
                            {t("Montant TVA")} <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontantTVA(factures)}</span>
                          </th>
                          <th>
                            {t("Montant TTC")} <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontantTTC(factures)}</span>
                          </th>

                          <th>{t("Date")}</th>
                          <th>{t("status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {factures.map((el, idx) => (
                          <tr key={idx}>
                            <td>
                              {" "}

                              <div className="d-flex">
                                <img src="assets/images/files/file.png" alt="img" className="w-5 h-6" />
                                <div className="mt-1 mr-4">
                                  <Link to={"/admin/editerfacturevente/" + el.code}>{el.facture_code}</Link>
                                </div>
                              </div>

                            </td>
                            <td>{convertToDigit(el.montant_total_ht)}</td>
                            <td>{convertToDigit(el.montant_total_tva)}</td>
                            <td>{convertToDigit(el.montant_total_ttc)}</td>
                            <td>{el.date.split(" ")[0]}</td>
                            <td>
                              {el.solde == el.net_a_payer && <span className="badge badge-success">payée</span>}
                              {el.solde < el.net_a_payer && el.solde > 0 && <span className="badge badge-warning">partiel</span>}
                              {el.solde == 0 && <></>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* <tbody>
                        <tr>
                          <td>
                            <Link to="#">INV-0478</Link>
                          </td>
                          <td>$345.00</td>
                          <td>12-01-2021</td>
                          <td>14-02-2021</td>
                          <td>
                            <span className="text-primary">$345.000</span>
                          </td>
                          <td>
                            <span className="badge badge-success-light">Paid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-1245</Link>
                          </td>
                          <td>$834.00</td>
                          <td>12-01-2021</td>
                          <td>14-02-2021</td>
                          <td>
                            <span className="text-primary">$834.000</span>
                          </td>
                          <td>
                            <span className="badge badge-danger-light">UnPaid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-5280</Link>
                          </td>
                          <td>$16,753.00</td>
                          <td>21-01-2021</td>
                          <td>15-01-2021</td>
                          <td>
                            <span className="text-primary">$16,753.000</span>
                          </td>
                          <td>
                            <span className="badge badge-success-light">Paid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-2876</Link>
                          </td>
                          <td>$297.00</td>
                          <td>05-02-2021</td>
                          <td>21-02-2021</td>
                          <td>
                            <span className="text-primary">$297.000</span>
                          </td>
                          <td>
                            <span className="badge badge-success-light">Paid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="#">INV-1986</Link>
                          </td>
                          <td>$12,897.00</td>
                          <td>01-01-2021</td>
                          <td>24-02-2021</td>
                          <td>
                            <span className="text-primary">$12,897.00</span>
                          </td>
                          <td>
                            <span className="badge badge-danger-light">UnPaid</span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="View"
                              >
                                <i className="feather feather-eye  text-primary" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Download"
                              >
                                <i className="feather feather-download   text-success" />
                              </a>
                              <a
                                href="#"
                                className="action-btns1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title
                                data-original-title="Delete"
                              >
                                <i className="feather feather-trash-2 text-danger" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab7">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row d-flex justify-content-center">
                          <div className="col-xl-8 col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  {/* <label className="form-label">Date:</label> */}
                                  <label className="form-label">{t("Date Debut")}</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="feather feather-calendar" />
                                      </div>
                                    </div>
                                    <input className="form-control floating" type="date"
                                      value={startDate}
                                      onChange={(e) => setStartDate(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label">{t("Date Fin")}</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="feather feather-calendar" />
                                      </div>
                                    </div>
                                    <input className="form-control floating" type="date"
                                      onChange={(e) => setEndDate(e.target.value)}
                                      value={endDate}
                                    />
                                    <div className="input-group-append">
                                      <button
                                        onClick={() => onChangeDateFacture()}

                                        className="btn btn-success input-group-text"
                                        id="basic-addon2"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={16}
                                          height={16}
                                          fill="currentColor"
                                          className="bi bi-search"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Choisir année:</label>
                        <select className="form-control custom-select select2" onChange={handleSelectDate}>
                          <option value={0}>Année</option>
                          <option value={1}>{new Date().getFullYear()}</option>
                          <option value={2}>{new Date().getFullYear() - 1}</option>
                        </select>
                      </div>
                    </div> */}
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="form-label">{t("Choisir Statut")}:</label>

                                  <select className="form-control custom-select select2"
                                  //  onChange={handleSelectStaus}
                                  >
                                    <option value={0}>{t("Tous")}</option>
                                    <option value={1}>{t("En cours")}</option>
                                    <option value={2}>{t("Payée")}</option>
                                    <option value={3}>{t("Partiel")}</option>
                                    <option value={4}>{t("échu")}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-xl-2 col-md-12">
                  <div className="form-group mt-5">
                    <Link to="#" className="btn btn-primary btn-block">
                      Recherche
                    </Link>
                  </div>
                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {/* <ClientEncaissement encaissement={encaissements}></ClientEncaissement> */}
                  <div className="table-responsive">
                    {/* <Link to="#" className="btn btn-primary btn-tableview">
                      Add Invoice
                    </Link> */}
                    <table className="table  table-vcenter text-nowrap table-bordered border-bottom border-top" id="invoice-tables">
                      <thead>
                        <tr>
                          <th>{t("N° Facture")}</th>
                          <th>{t("Référence")}</th>
                          <th>{t("Méthode")}</th>
                          <th>
                            {t("Montant HT")} <br></br>
                            <span className="badge badge-pill badge-danger">{sumMontant(encaissements)}</span>
                          </th>
                          <th>{t("Date")}</th>
                          <th>{t("status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {encaissements.map((el, idx) => (
                          <tr key={idx}>
                            <td>{el.num_facture}</td>
                     

                            <td>
                              {el.type === "facture" && <Link to={"/admin/editerfacturevente/" + el.code_facture}>{el.num_facture}</Link>}
                              {el.type === "devis" && <Link to={"/admin/editerdevisvente/" + el.code_facture}>{el.num_facture}</Link>}
                              {el.type === "bl" && <Link to={"/admin/editerblvente/" + el.code_facture}>{el.num_facture}</Link>}
                              {el.type === "commande" && <Link to={"/admin/editercommandevente/" + el.code_facture}>{el.num_facture}</Link>}
                            </td>
                            <td>{el.reference_banque}</td>
                            <td>{el.mode_paiement}</td>
                            <td>{convertToDigit(el.montant)}</td>
                            <td>{el.created_at}</td>
                            <td className="text-center">
                              {el.status === "payer" && <span className="badge badge-success">{t("Payé")}</span>}
                              {el.status === "partiel" && <span className="badge badge-warning">{t("Partiel")}</span>}
                              {el.status === "" && <span className="badge badge-danger"></span>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab8">
                <div className="card-body">


                  {loading && <CustomSyncLoader></CustomSyncLoader>}
                  {!loading && error && <ErrorCustum></ErrorCustum>}
                  {!loading && !error && data && (
                    <div className="table-responsive">
                      <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="dataTables_length" id="hr-table_length">
                              <label>
                                {t("Afficher")}{" "}
                                <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                  <option value={8}>8</option>
                                  <option value={20}>20</option>
                                  <option value={40}>40</option>
                                  <option value={60}>60</option>
                                  <option value={100}>100</option>
                                </select>{" "}
                                {t("entrées")}
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div id="hr-table_filter" className="dataTables_filter">
                              <label className="mr-2">
                                <input
                                  type="search"
                                  className="form-control"
                                  placeholder={t("Recherche...")}
                                  aria-controls="hr-table"
                                  onChange={handleSearch}
                                />
                              </label>
                              <ExportCsv data={data} name={"List_tâches"} />
                              <ExportPdf data={data} name={"List_tâches"} columns={["object", "description", "etat", "priority", "date_fin"]} />
                              <CopieContent />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <table className="table  table-vcenter text-nowrap table-bordered border-bottom" id="task-list">
                              <thead>
                                <tr>
                                  <th className="border-bottom-0">{t("User")}</th>
                                  <th className="border-bottom-0">{t("client")}</th>
                                  <th className="border-bottom-0">{t("date")}</th>
                                  <th className="border-bottom-0">{t("heure debut")}</th>
                                  <th className="border-bottom-0">{t("heure fin")}</th>
                                  <th className="border-bottom-0">{t("durée")}</th>
                                  <th className="border-bottom-0">{t("Objet")}</th>
                                  <th className="border-bottom-0">{t("Actions")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((el, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      <a href="task-profile.html" className="d-flex">
                                        <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={el.img}></ImageComponent>
                                        <div className="mr-3 mt-0 mt-sm-2 d-block">
                                          <h6 className="mb-1 fs-14">
                                            <Link to={"/admin/users/" + el.user_code_id}>
                                              {el.usernom} {el.userprenom}
                                            </Link>
                                          </h6>
                                        </div>
                                      </a>
                                    </td>
                                    <td>
                                      <Link to={"/admin/client/" + el.client_code} className="d-flex sidebarmodal-collpase">
                                        {el.client_nom} {' '}{el.client_prenom}
                                      </Link>
                                    </td>


                                    <td>{el.date && el.date.split("T")[0]}</td>
                                    <td>{el.heure_depart && el.heure_depart}</td>

                                    <td>{el.heure_final && el.heure_final}</td>
                                    <td>{convertMinutesToHous(el.duree)}</td>
                                    <td>{el.objet}</td>

                                    <td>
                                      <div className="d-flex">

                                        <a onClick={() => navtoReunion(el)} className="action-btns1">
                                          <i
                                            className="feather feather-edit-2  text-success"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit task"
                                          />
                                        </a>
                                        <Link
                                          to="#"
                                          className="action-btns1"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Task"
                                          onClick={() => handleDelete(el.id)}
                                        >
                                          <i className="feather feather-trash-2 text-danger" />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                              {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <Pagination items={data} onChangePage={onChangePage} pageSize={size} />

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="tab-pane" id="tab9">
                <div className="card-body">
                <Listsatisfactionclient code_client={props.match.params.id}   />

              
                </div>
              </div>
              <div className="tab-pane" id="tab10">
                <div className="card-body">
                <Listsupportclient code_client={props.match.params.id}   />

              
                </div>
              </div>
              <div className="tab-pane" id="tab11">
                <div className="card-body">
                <ViewTaches codeclient={props.match.params.id}   />

              
                </div>
              </div>
              <div className="tab-pane" id="tab12">
                <div className="card-body">
                <ListTasks clientcode={props.match.params.id}   />

              
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientActivity;
